import { IDropdownMenuProps } from '@/common/components/chartdropdownMenu';
import { Status } from '@/common/interfaces';

export const controlChartSegments = ['all_segments', 'new', 'infrequent', 'occasional', 'regular'] as const;

export type ControlChartSegments = typeof controlChartSegments[number];
type CustomerDemographic = 'all_customers' | 'existing' | 'new';

type ControlData = {
  metrics: {
    incremental_gallons_total: number;
    incremental_sales_total: number;
    sales_increase_ratio: number;
    transaction_increase_ratio: number;
  };
  sales: {
    [key in ControlChartSegments]: {
      customer: {
        pre: number;
        post: number;
      };
      control: {
        pre: number;
        post: number;
      };
    };
  };
  transactions: {
    [key in ControlChartSegments]: {
      customer: {
        pre: number;
        post: number;
      };
      control: {
        pre: number;
        post: number;
      };
    };
  };
};

type OnOffControlData = {
  metrics: {
    upside_launch_date: string;
    new_and_existing_sales_total: number;
    non_promo_sales_total: number;
  };
  sales: {
    [key in CustomerDemographic]: {
      upside_sales: {
        pre: number;
        post: number;
      };
      non_upside_sales: {
        pre: number;
        post: number;
      };
    };
  };
};

type IncrementalImpactData = {
  sales: {
    calendar_months: {
      control: Array<{ key: string; value: number; index: number }>;
      customer: Array<{ key: string; value: number; index: number }>;
    };
    relative_months: {
      control: Array<{ key: string; value: number; index: number }>;
      customer: Array<{ key: string; value: number; index: number }>;
    };
  };
  transactions: {
    calendar_months: {
      control: Array<{ key: string; value: number; index: number }>;
      customer: Array<{ key: string; value: number; index: number }>;
    };
    relative_months: {
      control: Array<{ key: string; value: number; index: number }>;
      customer: Array<{ key: string; value: number; index: number }>;
    };
  };
};

export interface IBehaviorRawData {
  pre_post_vs_control: ControlData;
  pre_post_on_off: OnOffControlData;
  incremental_impact: IncrementalImpactData;
}

export interface IBehavior {
  prePostChart: {
    metrics: {
      incremental_gallons_total: number;
      incremental_sales_total: number;
      sales_increase_ratio: number;
      transaction_increase_ratio: number;
    };
    sales: {
      [key in ControlChartSegments]: {
        id: string;
        data: Array<{ [key: string]: number | string }>;
      };
    };
    transactions: {
      [key in ControlChartSegments]: {
        id: string;
        data: Array<{ [key: string]: number | string }>;
      };
    };
  };
  onOffChart: {
    metrics: {
      upside_launch_date: string;
      new_and_existing_sales_total: number;
      non_promo_sales_total: number;
    };
    sales: {
      [key in CustomerDemographic]: {
        id: string;
        data: Array<{ [key: string]: number }>;
      };
    };
  };
  incrementalImpactChart: {
    sales: {
      relativeMonths: Array<{
        id: string;
        data: Array<{ x: number; y: number; key: string | number }>;
      }>;
      calendarMonths: Array<{
        id: string;
        data: Array<{ x: number; y: number; key: string | number }>;
      }>;
    };
    transactions: {
      relativeMonths: Array<{
        id: string;
        data: Array<{ x: number; y: number; key: string | number }>;
      }>;
      calendarMonths: Array<{
        id: string;
        data: Array<{ x: number; y: number; key: string | number }>;
      }>;
    };
    walkthrough: {
      relativeMonths: Array<{
        id: string;
        data: Array<{ x: number; y: number; key: string | number }>;
      }>;
    };
  };
}

export interface IncrementalImpactChartProps {
  data: IncrementalImpactSeries[];
  dataType: 'sales' | 'transactions';
  dateFormat: 'relativeMonths' | 'calendarMonths';
  loadingStatus: Status;
  dropdownMenuProps?: IDropdownMenuProps[];
}

export interface IncrementalImpactSeries {
  id: string;
  data: Array<{
    x: number;
    y: number;
    key: string | number;
  }>;
}

export interface IncrementalImpactPoint {
  id: string | number;
  index: number;
  serieId: string | number;
  datum: {
    x: number | string;
    y: number | string;
    key: string | number;
    [key: string]: number | string;
  };
  color: string;
}

export interface IncrementalImpactTick {
  value: number | string;
  index: number;
  x: number;
  y: number;
  key: number;
  tickIndex: number;
}

export interface IncrementalImpactChartProps {
  data: IncrementalImpactSeries[];
  loadingStatus: Status;
  dropdownMenuProps?: IDropdownMenuProps[];
}

export interface IncrementalImpactSeries {
  id: string;
  data: Array<{
    x: number;
    y: number;
    key: string | number;
  }>;
}

export interface IncrementalImpactPoint {
  id: string | number;
  index: number;
  serieId: string | number;
  datum: {
    x: number | string;
    y: number | string;
    key: string | number;
    [key: string]: number | string;
  };
  color: string;
}

export interface IncrementalImpactTick {
  value: number | string;
  index: number;
  x: number;
  y: number;
  key: number;
}
