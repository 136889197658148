import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormControl, Heading, Radio, RadioGroup, Text, VStack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { merge } from 'lodash';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { Status } from '@/common/interfaces';
import { selectActiveVertical, selectUserOrg, selectUserRoles } from '@/features/auth/userSlice';
import {
  addNewLocationRequest,
  selectFuelLocationFormData,
  selectLocationsSettingsStatus,
} from '@/features/settings/locations/slice';
import {
  AddFuelLocationAdditionalOffersInputFormData,
  AddFuelLocationAdditionalOffersOutputFormData,
  LocationSettingsData,
} from '@/features/settings/locations/types';
import { addFuelLocationAdditionalOfferSchema } from '@/features/settings/locations/validators';

export function AdditionalOfferForm() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const loadingStatus = useAppSelector(selectLocationsSettingsStatus);
  const fuelLocationFormData = useAppSelector(selectFuelLocationFormData);
  const orgId = useAppSelector(selectUserOrg);
  const activeVertical = useAppSelector(selectActiveVertical);
  const roles = useAppSelector(selectUserRoles);

  const { handleSubmit, register } = useForm<AddFuelLocationAdditionalOffersInputFormData>({
    resolver: zodResolver(addFuelLocationAdditionalOfferSchema),
    defaultValues: {
      hasConvenienceStore: !!fuelLocationFormData?.additionalProperties.margins?.CONVENIENCE_STORE?.percent || false,
    },
  });

  const onSubmit = (data: unknown) => {
    const formattedData = data as AddFuelLocationAdditionalOffersOutputFormData;
    const updatedData = merge({}, fuelLocationFormData, formattedData) as LocationSettingsData;

    dispatch(
      addNewLocationRequest({
        orgId,
        activeVertical,
        roles,
        location: updatedData,
      }),
    );
  };

  return (
    <Box mt='8'>
      <Heading as='h2' size='lg' mb={4}>
        {t('locationsSettingsPage.fuelForm.titles.additionalOffers')}
      </Heading>

      <Heading as='h3' size='sm' mb={4}>
        {t('locationsSettingsPage.fuelForm.fields.additionalOffers.helper')}
      </Heading>

      <Text>
        {t('locationsSettingsPage.fuelForm.forSite', {
          siteName: fuelLocationFormData?.name,
          siteAddress: fuelLocationFormData?.address.address1,
        })}
      </Text>

      <FormControl as='form' onSubmit={handleSubmit(onSubmit)}>
        <RadioGroup my={5} defaultValue='false'>
          <VStack alignItems='flex-start'>
            <Radio color='neutral.900' value='true' {...register('hasConvenienceStore')}>
              <Text>{t('locationsSettingsPage.fuelForm.fields.additionalOffers.options.true')}</Text>
            </Radio>
            <Radio color='neutral.900' value='false' {...register('hasConvenienceStore')}>
              <Text>{t('locationsSettingsPage.fuelForm.fields.additionalOffers.options.false')}</Text>
            </Radio>
          </VStack>
        </RadioGroup>
        <Button
          type='submit'
          variant='brandPrimary'
          size='md'
          px={8}
          mt={5}
          isLoading={loadingStatus === Status.Loading}
        >
          {t('locationsSettingsPage.fuelForm.actions.continue')}
        </Button>
      </FormControl>
    </Box>
  );
}
