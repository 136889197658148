import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PlaidLinkOnSuccess } from 'react-plaid-link';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Checkbox,
  Flex,
  FormControl,
  Heading,
  Input,
  Select,
  Text,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { CustomFormLabel } from '@/common/components/form';
import { Section } from '@/common/components/section';
import { usePermissions } from '@/common/hooks';
import { Status } from '@/common/interfaces';
import states from '@/common/utils/states.json';
import { selectActiveVertical, selectIsUpsideManager, selectUserOrg, selectUserRoles } from '@/features/auth/userSlice';
import { usePlaidLinkFunctions } from '@/features/settings/payment/hooks';

import {
  addNewPayoutRequest,
  plaidTokenFetchRequest,
  selectPayoutLoadingStatus,
  selectPlaidToken,
  setNewPayoutData,
} from '../../slice';
import { AddPayoutFormType } from '../../types';
import { addPayoutFormSchema } from '../../validators';

export const AddPayoutAccount = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const plaidToken = useAppSelector(selectPlaidToken);
  const orgId = useAppSelector(selectUserOrg);
  const roles = useAppSelector(selectUserRoles);
  const vertical = useAppSelector(selectActiveVertical);
  const loadingStatus = useAppSelector(selectPayoutLoadingStatus);
  const toast = useToast();
  const isUpsideManager = useAppSelector(selectIsUpsideManager);

  const { hasPaymentsManagerAccess, hasPayoutManagerAccess } = usePermissions();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<AddPayoutFormType>({
    resolver: zodResolver(addPayoutFormSchema),
    mode: 'onChange',
  });

  const payoutRole = useMemo(() => {
    let role = '';
    if (hasPaymentsManagerAccess) {
      role = 'PaymentsManager';
    }
    if (hasPayoutManagerAccess) {
      role = 'PayoutManager';
    }
    if (isUpsideManager) {
      return 'UpsideManager';
    }
    return role;
  }, [hasPaymentsManagerAccess, hasPayoutManagerAccess, isUpsideManager]);

  useEffect(() => {
    dispatch(plaidTokenFetchRequest({ orgId, role: payoutRole, vertical }));
  }, [dispatch, orgId, payoutRole, roles, vertical]);

  const isTokenExpired = () => {
    if (!plaidToken || !plaidToken.link_token || !plaidToken.expiration) {
      return true;
    }

    const now = new Date();

    return plaidToken.expiration < now.getTime();
  };

  const onSubmit = (data: AddPayoutFormType) => {
    if (isTokenExpired()) {
      dispatch(plaidTokenFetchRequest({ orgId, role: payoutRole, vertical }));
    }
    dispatch(setNewPayoutData(data));
    open();
  };

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    (publicToken, metadata) => {
      // send public_token to your server
      // https://plaid.com/docs/api/tokens/#token-exchange-flow
      toast({
        title: 'Saving payout method...',
        status: 'info',
        duration: 2000,
        isClosable: true,
      });

      dispatch(addNewPayoutRequest({ orgId, role: payoutRole, vertical, metadata }));
    },
    [dispatch, orgId, payoutRole, toast, vertical],
  );

  const { open } = usePlaidLinkFunctions({ onSuccess, token: plaidToken.link_token });
  return (
    <>
      <Breadcrumb separator={<ChevronRightIcon color='gray.500' />}>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to='/settings/payments'>
            {t('payment.billingPaymentMethods.addBankAccount.breadcrumbs.payments')}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink as={Link} isCurrentPage to='#'>
            {t('payment.addPayout.breadcrumbs.addPayout')}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Section name={t('payment.addPayout.addPayoutAccount.title')}>
        <Box
          opacity={loadingStatus !== Status.Loading ? 1 : 0.3}
          pointerEvents={loadingStatus === Status.Loading ? 'none' : 'all'}
        >
          <Box mt={6} maxWidth={'600px'}>
            <Heading size='md'>{t('payment.addBank')}</Heading>
            <Text>{t('payment.addPayout.info')}</Text>
          </Box>
        </Box>
        <Box maxWidth={'600px'}>
          <Box mt={6}>
            <FormControl as='form' mt={3} onSubmit={handleSubmit(onSubmit)}>
              <Text mt={9} size='md' textStyle={'heading.md'}>
                {t('payment.addPayout.billingAddress')}
              </Text>
              <CustomFormLabel htmlFor='address' pt={3} isInvalid={errors.street_1 && errors.street_1?.message !== ''}>
                {t('payment.addPayout.address')}
                <Input
                  type='text'
                  id='address'
                  {...register('street_1', { required: true })}
                  isInvalid={errors.street_1 && errors.street_1?.message !== ''}
                />
              </CustomFormLabel>
              {errors.street_1 && <Text variant={'error'}>{errors.street_1?.message}</Text>}

              <CustomFormLabel htmlFor='city' mt={'12px'} isInvalid={errors.city && errors.city?.message !== ''}>
                {t('payment.addPayout.city')}
                <Input
                  type='text'
                  id='city'
                  {...register('city')}
                  isInvalid={errors.city && errors.city?.message !== ''}
                />
              </CustomFormLabel>
              {errors.city && <Text variant={'error'}>{errors.city?.message}</Text>}

              <Flex mt={3} gap={2}>
                <Box w='full'>
                  <CustomFormLabel htmlFor='state' isInvalid={errors.state && errors.state?.message !== ''}>
                    {t('payment.addPayout.state')}
                    <Select
                      id='state'
                      placeholder='Choose a state'
                      {...register('state', { required: true })}
                      isInvalid={errors.state && errors.state?.message !== ''}
                    >
                      {states.states.map((state: { name: string; code: string }) => (
                        <option key={state.code} value={state.code}>
                          {state.name}
                        </option>
                      ))}
                    </Select>
                  </CustomFormLabel>
                  {errors.state && <Text variant={'error'}>{errors.state?.message}</Text>}
                </Box>
                <Box w='full'>
                  <CustomFormLabel htmlFor='zip' isInvalid={errors.zip && errors.zip?.message !== ''}>
                    {t('payment.addPayout.zipCode')}

                    <Input
                      id='zip'
                      {...register('zip', { required: true })}
                      isInvalid={errors.zip && errors.zip?.message !== ''}
                    />
                  </CustomFormLabel>
                  {errors.zip && <Text variant={'error'}>{errors.zip?.message}</Text>}
                </Box>
              </Flex>

              <Text mt={6} size='md' textStyle={'heading.md'}>
                {t('payment.addBankForm.bankAccountDetails')}
              </Text>

              <CustomFormLabel
                htmlFor='account_nickname'
                pt={3}
                isInvalid={errors.account_nickname && errors.account_nickname?.message !== ''}
              >
                {t('payment.addBankForm.accountNickname')}
                <Input
                  type='text'
                  id='account_nickname'
                  {...register('account_nickname', { required: true })}
                  isInvalid={errors.account_nickname && errors.account_nickname?.message !== ''}
                />
              </CustomFormLabel>
              {errors.account_nickname && <Text variant={'error'}>{errors.account_nickname?.message}</Text>}

              <CustomFormLabel
                htmlFor='account_holder_name'
                pt={3}
                isInvalid={errors.account_holder_name && errors.account_holder_name?.message !== ''}
              >
                {t('payment.addBankForm.accountHolderName')}
                <Input
                  type='text'
                  id='account_holder_name'
                  {...register('account_holder_name', { required: true })}
                  isInvalid={errors.account_holder_name && errors.account_holder_name?.message !== ''}
                />
              </CustomFormLabel>
              {errors.account_holder_name && <Text variant={'error'}>{errors.account_holder_name?.message}</Text>}

              <CustomFormLabel
                htmlFor='account_holder_type'
                pt={3}
                isInvalid={errors.account_holder_type && errors.account_holder_type?.message !== ''}
              >
                {t('payment.addBankForm.accountHolderType')}
                <Select
                  id='account_holder_type'
                  placeholder='Choose account holder type'
                  {...register('account_holder_type', { required: true })}
                  isInvalid={errors.account_holder_type && errors.account_holder_type?.message !== ''}
                >
                  <option value='company'>{t('payment.addBankForm.company')}</option>
                  <option value='individual'>{t('payment.addBankForm.individual')}</option>
                </Select>
              </CustomFormLabel>
              {errors.account_holder_type && <Text variant={'error'}>{errors.account_holder_type?.message}</Text>}

              <Checkbox
                id='user_accept_terms'
                mt={3}
                alignItems={'baseline'}
                {...register('user_accept_terms', { required: true })}
                data-testid='acceptTerms'
              >
                {t('payment.addPayout.authorization')}
              </Checkbox>
              {errors.user_accept_terms && <Text variant={'error'}>{errors.user_accept_terms?.message}</Text>}

              <Flex mt={'24px'}>
                <Button as={Link} to='/settings/payments' variant='accentSecondary' size='md' px={8} mr={'12px'}>
                  {t('payment.addPayout.back')}
                </Button>
                <Button
                  type='submit'
                  variant='brandPrimary'
                  size='md'
                  px={8}
                  isDisabled={!plaidToken && !isDirty}
                  isLoading={loadingStatus === Status.Loading}
                >
                  {t('payment.addPayout.continue')}
                </Button>
              </Flex>
            </FormControl>
          </Box>
        </Box>
      </Section>
    </>
  );
};
