import * as Sentry from '@sentry/react';

import { axiosInstance } from '@/common/utils/axios';

import { CustomReportOptions } from './type';

export async function fetchColumnsList({
  authRequestPath,
  transactionType,
}: {
  authRequestPath: string;
  transactionType: string;
}) {
  try {
    const result = await axiosInstance.post(`/transaction_exports/report_fields/${authRequestPath}`, {
      transaction_type: transactionType,
    });

    return result.data;
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
    throw error;
  }
}

export async function fetchCustomReports({
  authRequestPath,
  options,
  locations,
  networkNames,
  transactionType,
}: {
  authRequestPath: string;
  options: CustomReportOptions;
  locations: string[];
  networkNames: string[];
  transactionType: string;
}) {
  try {
    const result = await axiosInstance.post(
      `/transaction_exports/${authRequestPath}`,
      {
        locations,
        networkNames,
        transactionType,
        ...options,
      },
      {
        params: {
          startDate: options.startDate,
          endDate: options.endDate,
        },
      },
    );

    return result.data;
  } catch (error) {
    Sentry.captureException(error);
    console.error(JSON.stringify(error));
    throw error;
  }
}
