import { useCallback } from 'react';
import {
  PlaidLinkOnEvent,
  PlaidLinkOnExit,
  PlaidLinkOnSuccess,
  PlaidLinkOptionsWithLinkToken,
  usePlaidLink,
} from 'react-plaid-link';

export const usePlaidLinkFunctions = ({
  token,
  onSuccess,
}: {
  token: string | null;
  onSuccess: PlaidLinkOnSuccess;
}) => {
  const onEvent = useCallback<PlaidLinkOnEvent>((eventName, metadata) => {
    // https://plaid.com/docs/link/web/#onevent
    console.log(eventName, metadata);
  }, []);

  const onExit = useCallback<PlaidLinkOnExit>((error, metadata) => {
    // https://plaid.com/docs/link/web/#onexit
    console.error(error, metadata);
  }, []);

  const config: PlaidLinkOptionsWithLinkToken = {
    token: token,
    onSuccess,
    onEvent,
    onExit,
  };

  const { open, ready, error, exit } = usePlaidLink(config);

  return { open, ready, error, exit };
};
