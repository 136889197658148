// Specifications: https://upside-services.atlassian.net/wiki/spaces/ENG/pages/2498134036/MV+Dashboard+Permissions+-+RBAC+Auth0
import * as Sentry from '@sentry/react';

import { ManagerRoles, OtherRoles, ReadOnlyRoles, UserRoles } from '@/common/interfaces';

//    Manager Roles
export const { UpsideManager, PaymentsManager, PayoutManager, BillingManager, LocationsSettingsManager } = ManagerRoles;

//    Read Only Roles
export const { StatementsInvoicesReadOnly, PayoutsReadOnly, LocationsSettingsReadOnly, TransactionsExporter } =
  ReadOnlyRoles;

//    Feature Specific Roles
export const { GreenGreyAccess } = OtherRoles;

export const checkAPIRequestPermissions = (userRoles: string[], allowedRoles: string[], requestType = 'unknown') => {
  const hasPermission = userRoles.some((role) => allowedRoles.includes(role));
  try {
    if (!hasPermission) {
      throw new Error(`User does not have API permissions - ${requestType}`);
    }
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
  }
};

export const checkBillingPermissions = (roles: UserRoles[]) =>
  checkAPIRequestPermissions(roles, [PaymentsManager, BillingManager, UpsideManager], 'billing');

export function checkPayoutPermissions(role: string, accessType?: 'manage' | 'read'): void;
export function checkPayoutPermissions(roles: UserRoles[], accessType?: 'manage' | 'read'): void;
export function checkPayoutPermissions(rolesOrRole: string | string[], accessType: 'manage' | 'read' = 'read'): void {
  const manageRoles = [PayoutManager, PaymentsManager, UpsideManager];
  const readRoles = [PayoutsReadOnly, ...manageRoles];

  const roles = Array.isArray(rolesOrRole) ? rolesOrRole : [rolesOrRole];

  checkAPIRequestPermissions(roles, accessType === 'manage' ? manageRoles : readRoles, 'payouts');
}

export const checkStatementsInvoicesPermissions = (roles: UserRoles[], accessType = 'read') => {
  const manageRoles = [PaymentsManager, BillingManager, PayoutManager, UpsideManager];
  const readRoles = [StatementsInvoicesReadOnly, PayoutsReadOnly, ...manageRoles];
  checkAPIRequestPermissions(roles, accessType === 'manage' ? manageRoles : readRoles, 'statements-invoices');
};

export const checkLocationsPermissions = (roles: UserRoles[], accessType = 'read') => {
  const manageRoles = [LocationsSettingsManager, UpsideManager];
  const readRoles = [LocationsSettingsReadOnly, ...manageRoles];
  checkAPIRequestPermissions(roles, accessType === 'manage' ? manageRoles : readRoles, 'locations');
};

export const checkLocationsSettingsPermissions = (roles: UserRoles[], accessType = 'read') => {
  const manageRoles = [LocationsSettingsManager, UpsideManager];
  const readRoles = [LocationsSettingsReadOnly, ...manageRoles];
  checkAPIRequestPermissions(roles, accessType === 'manage' ? manageRoles : readRoles, 'locations-settings');
};
