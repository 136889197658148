import * as Sentry from '@sentry/react';

import { UserRoles } from '@/common/interfaces';
import { axiosInstance } from '@/common/utils';
import { checkPayoutPermissions } from '@/features/auth/permissions';

export interface IPayoutsRequest {
  startDate: string;
  endDate: string;
  page: number;
  pageSize: number;
  entity_display_names: string[];
  authRequestPath: string;
  roles: UserRoles[];
}

export const fetchPayouts = async ({
  startDate,
  endDate,
  page,
  pageSize,
  entity_display_names,
  authRequestPath,
  roles,
}: IPayoutsRequest) => {
  checkPayoutPermissions(roles);

  try {
    const result = await axiosInstance.post(
      `billing/settlement/${authRequestPath}`,
      { accountNames: entity_display_names },
      {
        params: {
          startDate,
          endDate,
          page,
          pageSize,
        },
      },
    );

    return result.data;
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
    throw error;
  }
};
