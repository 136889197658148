import React, { useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { Props as DayzedHookProps } from 'dayzed';

import { isSameDay } from '@/common/utils/dates';

import { CalendarConfigs, PropsConfigs } from '../types';
import { DatePositionInRange } from '../types';

import { CalendarPanel } from './CalendarPanel';

interface RangeCalendarPanelProps {
  dayzedHookProps: DayzedHookProps;
  configs: CalendarConfigs;
  propsConfigs?: PropsConfigs;
  selected?: Date | Date[];
}

export const RangeCalendarPanel: React.FC<RangeCalendarPanelProps> = ({
  dayzedHookProps,
  configs,
  propsConfigs,
  selected,
}) => {
  const [hoveredDate, setHoveredDate] = useState<Date | null>(null);

  // Calendar level
  const onMouseLeave = () => {
    setHoveredDate(null);
  };

  // Date level
  const onMouseEnterHighlight = (date: Date) => {
    if (!Array.isArray(selected) || !selected?.length) {
      return;
    }
    setHoveredDate(date);
  };

  const getDateOfMonthState = (date: Date): DatePositionInRange => {
    const result: DatePositionInRange = { isInRange: false, isFirst: false, isLast: false };
    if (!Array.isArray(selected) || !selected?.length) {
      return result;
    }
    const firstSelected = selected[0];

    if (selected.length === 1 && configs.minDaysNumber) {
      const minSelectable = new Date(selected[0].getTime());
      minSelectable.setDate(selected[0].getDate() - configs.minDaysNumber);
      const maxSelectable = new Date(selected[0].getTime());
      maxSelectable.setDate(selected[0].getDate() + configs.minDaysNumber);
      result.disabled = (date > minSelectable && date < selected[0]) || (date < maxSelectable && date > selected[0]);
    }
    if (selected.length === 2) {
      const secondSelected = selected[1];
      result.isInRange = firstSelected < date && secondSelected > date;
      result.isFirst = isSameDay(date, firstSelected);
      result.isLast = isSameDay(date, secondSelected);
    } else if (hoveredDate) {
      result.isInRange = (firstSelected < date && hoveredDate >= date) || (date < firstSelected && date >= hoveredDate);
      if (isSameDay(hoveredDate, firstSelected)) {
        result.isFirst = isSameDay(date, firstSelected);
        result.isLast = isSameDay(date, firstSelected);
      } else if (hoveredDate > firstSelected) {
        result.isFirst = isSameDay(date, firstSelected);
        result.isLast = isSameDay(date, hoveredDate);
      } else {
        result.isFirst = isSameDay(date, hoveredDate);
        result.isLast = isSameDay(date, firstSelected);
      }
    }

    return result;
  };

  return (
    <Flex onMouseLeave={onMouseLeave}>
      <CalendarPanel
        dayzedHookProps={dayzedHookProps}
        configs={configs}
        propsConfigs={propsConfigs}
        getDateOfMonthState={getDateOfMonthState}
        onMouseEnterHighlight={onMouseEnterHighlight}
      />
    </Flex>
  );
};
