export enum Verticals {
  Grocery = 'grocery',
  Fuel = 'fuel',
  CStore = 'c-store',
  Restaurant = 'restaurant',
  Unknown = 'unknown',
}

export enum VerticalBasedRoles {
  CStoreReadOnly = 'CStoreReadOnly',
  FuelReadOnly = 'FuelReadOnly',
  GroceryReadOnly = 'GroceryReadOnly',
  RestaurantReadOnly = 'RestaurantReadOnly',
  UpsideReadOnly = 'UpsideReadOnly',
  UpsideManager = 'UpsideManager',
}

export enum ManagerRoles {
  BillingManager = 'BillingManager',
  PayoutManager = 'PayoutManager',
  PaymentsManager = 'PaymentsManager',
  LocationsSettingsManager = 'LocationsSettingsManager',
  UpsideManager = 'UpsideManager',
}

export enum ReadOnlyRoles {
  PayoutsReadOnly = 'PayoutsReadOnly',
  StatementsInvoicesReadOnly = 'StatementsInvoicesReadOnly',
  LocationsSettingsReadOnly = 'LocationsSettingsReadOnly',
  TransactionsExporter = 'TransactionsExporter',
}

export enum OtherRoles {
  UpsideReadOnly = 'UpsideReadOnly',
  GreenGreyAccess = 'GreenGreyAccess',
}

export type TVerticals = 'grocery' | 'fuel' | 'c-store' | 'restaurant' | 'unknown';

export type TVerticalBasedRoles =
  | 'CStoreReadOnly'
  | 'FuelReadOnly'
  | 'GroceryReadOnly'
  | 'RestaurantReadOnly'
  | 'UpsideReadOnly';

export type TManagerRoles = 'BillingManager' | 'PayoutManager' | 'PaymentsManager' | 'LocationsSettingsManager';
export type TReadOnlyRoles = 'PayoutsReadOnly' | 'StatementsInvoicesReadOnly' | 'LocationsSettingsReadOnly';
export type TOtherRoles = 'UpsideReadOnly' | 'GreenGreyAccess';

export type UserRoles = VerticalBasedRoles | ManagerRoles | ReadOnlyRoles | OtherRoles;
