/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { getVerticalReadRole } from '@/config/mvMapping';

import * as api from './api';
import { formatData } from './dataFormatting';
import {
  generalConfigsFetchFailed,
  generalConfigsFetchRequest,
  generalConfigsFetchSuccess,
  headerDataFetchFailed,
  headerDataFetchRequest,
  headerDataFetchSuccess,
  masqueradingListsFetchFailure,
  masqueradingListsFetchRequest,
  masqueradingListsFetchSuccess,
  parentOrgConfigFetchFailure,
  parentOrgConfigFetchRequest,
  parentOrgConfigFetchSuccess,
  performanceSummaryFailed,
  performanceSummaryFetch,
  performanceSummarySucceeded,
} from './slice';

/*****************************************************************************/
/******************************* WORKERS *************************************/
/*****************************************************************************/
export function* fetchHeaderData() {
  try {
    console.debug('fetching header data via API request');
    const state = yield* select();
    const { org_id: orgId, roles, activeVertical } = state.user;
    const role = getVerticalReadRole(roles, activeVertical);
    const payload = {
      authRequestPath: `${orgId}/${role}/${activeVertical}`,
    };

    const result = yield* call(api.fetchHeaderData, payload);

    if (result) {
      // TODO another time: move this to BE - low priority
      const sortedNetworks = result.data.networks.sort((a, b) => {
        if (a.live_sites < b.live_sites) {
          return 1;
        }
        if (a.live_sites > b.live_sites) {
          return -1;
        }

        return 0;
      });

      result.data.networks = sortedNetworks;
      yield* put(headerDataFetchSuccess({ ...result.data, activeVertical }));
    }
  } catch (error: any) {
    yield* put(headerDataFetchFailed(error.message));
  }
}

export function* fetchGeneralConfigs(action: any) {
  try {
    console.debug('fetching general configs...');
    const result = yield* call(api.fetchGeneralConfigs, action.payload);

    if (result) {
      yield* put(generalConfigsFetchSuccess(result.data));
    }
  } catch (error: any) {
    yield* put(generalConfigsFetchFailed(error.message));
  }
}

export function* fetchMasqueradingLists(action: any) {
  try {
    console.debug('fetching masquerading lists...');
    const result = yield* call(api.fetchMasqueradingLists, action.payload);

    yield* put(masqueradingListsFetchSuccess(result.data));
  } catch (error: any) {
    yield* put(masqueradingListsFetchFailure(error.message));
  }
}

export function* fetchParentOrgConfig(action: any) {
  try {
    console.debug('fetching masquerading lists...');
    const result = yield* call(api.fetchParentOrgConfig, action.payload);

    yield* put(parentOrgConfigFetchSuccess(result.data));
  } catch (error: any) {
    yield* put(parentOrgConfigFetchFailure(error.message));
  }
}

export function* fetchPerformanceSummary(action: any) {
  try {
    const state = yield* select();
    const { activeVertical } = state.user;
    const selectedTransactionType = state.dashboard.selectedTransactionType;

    const result = yield* call(api.fetchPerformanceSummaryData, action.payload);
    const refinedResult = formatData(result, action.payload.temporalUnit, activeVertical, selectedTransactionType);

    yield* put(performanceSummarySucceeded(refinedResult));
  } catch (error: any) {
    yield* put(performanceSummaryFailed(error.message));
  }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/
export function* watchHeaderData() {
  yield* takeLatest(headerDataFetchRequest, fetchHeaderData);
}

export function* watchGeneralConfigs() {
  yield* takeLatest(generalConfigsFetchRequest, fetchGeneralConfigs);
}

export function* watchMasqueradingLists() {
  yield* takeLatest(masqueradingListsFetchRequest, fetchMasqueradingLists);
}

export function* watchParentOrgConfig() {
  yield* takeLatest(parentOrgConfigFetchRequest, fetchParentOrgConfig);
}

export function* watchPerformanceSummary() {
  yield* takeLatest(performanceSummaryFetch, fetchPerformanceSummary);
}
