import * as Sentry from '@sentry/react';

import { UserRoles } from '@/common/interfaces';
import { axiosInstance } from '@/common/utils';
import { checkStatementsInvoicesPermissions } from '@/features/auth/permissions';

import { buildMockInvoicesList } from './mockInvoicesResponse';
import { IInvoicesRawData } from './types';

export const fetchInvoices = async ({
  startDate,
  endDate,
  page,
  pageSize,
  entity_display_names,
  authRequestPath,
  roles,
}: {
  startDate: string;
  endDate: string;
  page: number;
  pageSize: number;
  entity_display_names: string[];
  authRequestPath: string;
  roles: UserRoles[];
}) => {
  checkStatementsInvoicesPermissions(roles, 'read');

  try {
    const result = await axiosInstance.post(
      `/billing/invoice/${authRequestPath}`,
      {
        accountNames: entity_display_names,
      },
      {
        params: {
          startDate,
          endDate,
          page,
          pageSize,
        },
      },
    );

    return result.data;
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
    throw error;
  }
};

export const downloadInvoice = async ({
  invoice_uuid,
  format,
  authRequestPath,
  roles,
}: {
  invoice_uuid: string;
  format: string;
  authRequestPath: string;
  roles: UserRoles[];
}) => {
  checkStatementsInvoicesPermissions(roles, 'read');

  try {
    const response = await axiosInstance.post(`/billing/invoice/${invoice_uuid}/${format}/${authRequestPath}`, {}, {});

    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
    throw error;
  }
};

// A mock function to mimic making an async request for data
export const mockFetchInvoices = ({
  startDate,
  endDate,
  page,
  pageSize,
  account_type,
  authRequestPath,
}: {
  startDate: string;
  endDate: string;
  page: number;
  pageSize: number;
  account_type: string;
  authRequestPath: string;
}) => {
  console.log(`startDate: ${startDate}`);
  console.log(`endDate: ${endDate}`);
  console.log(`pageSize: ${pageSize}`);
  console.log(`authRequestPath: ${authRequestPath}`);
  console.log(`account_type: ${account_type}`);
  const mockInvoicesList = buildMockInvoicesList(page);

  return new Promise<IInvoicesRawData>((resolve) => setTimeout(() => resolve(mockInvoicesList), 1500));
};
