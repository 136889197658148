import { defineStyleConfig } from '@chakra-ui/react';

const CheckboxStyles = defineStyleConfig({
  baseStyle: {
    control: {
      borderColor: 'border.dark',
      borderRadius: '2px',
      bg: 'transparent',
      margin: 1,
      iconColor: 'icon.on-primary',

      _checked: {
        backgroundColor: 'background.primary.default',
        borderColor: 'border.primary',
        _hover: {
          backgroundColor: 'background.primary.default',
          borderColor: 'border.primary',
        },
      },

      _disabled: {
        borderRadius: '2px',
        borderColor: 'border.disabled',
        bg: 'transparent',
      },

      _indeterminate: {
        backgroundColor: 'background.primary.default',
        borderColor: 'border.primary',
      },
    },
  },
});

export default CheckboxStyles;
