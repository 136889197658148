import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/react';

import { PaginationProps } from './types';

export type PaginationButtonType = 'previous' | 'next';

export const PaginationButton = (props: PaginationProps & { type: PaginationButtonType }) => {
  const { t } = useTranslation();
  return props.type === 'previous' ? (
    <Button
      data-testid='pagination-previous-button'
      isDisabled={!props.hasPrev || props.isLoading === true}
      size={'sm'}
      variant={'accentSecondary'}
      px={6}
      lineHeight={0}
      onClick={props.goBackward}
    >
      {t('components.pagination.previous')}
    </Button>
  ) : (
    <Button
      data-testid='pagination-forward-button'
      isDisabled={!props.hasNext || props.isLoading === true}
      size={'sm'}
      variant={'accentSecondary'}
      px={6}
      lineHeight={0}
      onClick={props.goForward}
    >
      {t('components.pagination.next')}
    </Button>
  );
};
