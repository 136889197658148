import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Flex, FormControl, Input, Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { DataLoadingErrorPanel } from '@/common/components/errorAlert';
import { CustomFormLabel } from '@/common/components/form';
import { LoadingSpinner } from '@/common/components/loadingSpinner';
import { Status } from '@/common/interfaces';
import { selectAuthRequestPath } from '@/features/auth/userSlice';

import { createUserProfileRequest, selectUserProfileStatus, updateUserProfileRequest } from '../slice';
import { ContactInfoFormData, Profile } from '../types';

import { contactInfoSchema } from './validators';

interface ContactInfoProps {
  userProfile: Profile;
  onClose?: () => void;
}

export const ContactInfo: React.FC<ContactInfoProps> = ({ userProfile, onClose }) => {
  const { t } = useTranslation();
  const { user } = useAuth0();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const authRequestPath = useAppSelector(selectAuthRequestPath);
  const loadingStatus = useAppSelector(selectUserProfileStatus);

  const isProfilesPage = location.pathname.includes('profiles');

  const isNewUserProfile = !userProfile || !userProfile.firstName;

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<ContactInfoFormData>({
    resolver: zodResolver(contactInfoSchema),
    defaultValues: {
      form_data: {
        ...userProfile,
        email: isNewUserProfile ? user?.email : userProfile.email,
      },
    },
  });

  useEffect(() => {
    if (userProfile) {
      reset({
        form_data: {
          ...userProfile,
          email: isNewUserProfile ? user?.email : userProfile.email,
        },
      });
    }
  }, [userProfile, reset, user?.email, isNewUserProfile]);

  const onSubmit = (data: ContactInfoFormData) => {
    isNewUserProfile
      ? dispatch(createUserProfileRequest({ authRequestPath, data }))
      : dispatch(updateUserProfileRequest({ authRequestPath, data }));

    if (onClose) {
      onClose();
    }
  };

  return (
    <Box padding={0} margin={0} maxW='100%'>
      {loadingStatus === Status.Loading && (
        <Flex alignItems='center' justifyContent='center' mt={10}>
          <LoadingSpinner />
        </Flex>
      )}
      {loadingStatus === Status.Failed && <DataLoadingErrorPanel />}
      {loadingStatus === Status.Idle && (
        <FormControl as='form' mt={3} onSubmit={handleSubmit(onSubmit)}>
          <CustomFormLabel htmlFor='address' pt={3} maxW={'400px'}>
            {t('profilePage.contactInfo.fields.email')}
            <Input
              type='text'
              id='email'
              data-testid='email'
              isDisabled
              mt='3'
              size='lg'
              {...register('form_data.email')}
            />
          </CustomFormLabel>
          <Text color='text.subdued'>
            {t(`${isProfilesPage ? 'profilesPage' : 'profilePage'}.contactInfo.cantChangeEmail`)}
          </Text>
          <Flex mt={3} gap={2}>
            <CustomFormLabel htmlFor='firstName' pt={3} width='100%' maxW={'350px'}>
              {t('profilePage.contactInfo.fields.firstName')}
              <Input
                type='text'
                id='firstName'
                data-testid='firstName'
                mt='3'
                size='lg'
                {...register('form_data.firstName')}
              />
              {errors.form_data?.firstName && <Text variant={'error'}>{errors.form_data.firstName.message}</Text>}
            </CustomFormLabel>
            <CustomFormLabel htmlFor='lastName' pt={3} width='100%' maxW={'350px'}>
              {t('profilePage.contactInfo.fields.lastName')}
              <Input
                type='text'
                id='lastName'
                data-testid='lastName'
                mt='3'
                size='lg'
                {...register('form_data.lastName')}
              />
              {errors.form_data?.lastName && <Text variant={'error'}>{errors.form_data.lastName.message}</Text>}
            </CustomFormLabel>
          </Flex>
          <CustomFormLabel htmlFor='phone' pt={3} maxW={'350px'}>
            {t('profilePage.contactInfo.fields.phone')}
            <Input type='text' id='phone' data-testid='phone' mt='3' size='lg' {...register('form_data.phone')} />
            {errors.form_data?.phone && <Text variant={'error'}>{errors.form_data.phone.message}</Text>}
          </CustomFormLabel>

          <Button type='submit' variant='brandPrimary' size='md' px={12} mt='4'>
            {isNewUserProfile
              ? t('profilePage.contactInfo.actions.create')
              : t('profilePage.contactInfo.actions.update')}
          </Button>
        </FormControl>
      )}
    </Box>
  );
};
