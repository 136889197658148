import { createStandaloneToast } from '@chakra-ui/react';
import { PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { call, put, takeLatest } from 'typed-redux-saga';

import i18n from '@/i18n';

import * as api from './api';
import {
  columnsListFetchFailed,
  columnsListFetchRequested,
  columnsListFetchSucceed,
  customReportsFailed,
  customReportsFetch,
  customReportsSucceeded,
} from './slice';
import { CustomReportOptions } from './type';
import { downloadFile } from './utils';

const { toast } = createStandaloneToast();

export function* fetchCustomReportColumns(
  action: PayloadAction<{
    authRequestPath: string;
    transactionType: string;
  }>,
) {
  try {
    const result = yield* call(api.fetchColumnsList, action.payload);

    yield* put(columnsListFetchSucceed(result));
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield* put(columnsListFetchFailed('Error fetching columns list'));
    }
    if (error instanceof Error) {
      yield* put(columnsListFetchFailed(error.message));
    }
  }
}

export function* fetchCustomReport(
  action: PayloadAction<{
    authRequestPath: string;
    options: CustomReportOptions;
    locations: string[];
    networkNames: string[];
    transactionType: string;
  }>,
) {
  try {
    toast({
      title: i18n.t('customReportsPage.toast.preparingData') as string,
      status: 'info',
      duration: 3000,
      isClosable: true,
    });
    const data = yield* call(api.fetchCustomReports, action.payload);
    if (action.payload.options.deliveryMethod === 'direct') {
      downloadFile(
        data,
        {
          startDate: action.payload.options.startDate,
          endDate: action.payload.options.endDate,
          granularity: action.payload.options.granularity,
        },
        action.payload.options.fileFormat,
      );
      toast({
        title: i18n.t('customReportsPage.toast.downloading') as string,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    }

    if (action.payload.options.deliveryMethod === 'email') {
      const email = action.payload.options.recipientEmail;
      toast({
        title: i18n.t('customReportsPage.toast.emailing', { email }) as string,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    }

    yield* put(customReportsSucceeded());
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield* put(customReportsFailed('Error fetching custom report'));

      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 504) {
        toast({
          title: i18n.t('customReportsPage.toast.timeout') as string,
          description: i18n.t('customReportsPage.toast.timeoutDescription1') as string,
          status: 'warning',
          duration: 15000,
          isClosable: true,
        });
        return;
      }

      toast({
        title: i18n.t('customReportsPage.toast.error') as string,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (error instanceof Error) {
      yield* put(customReportsFailed(error.message));
      toast({
        title: i18n.t('customReportsPage.toast.error') as string,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
  }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchCustomReportColumns() {
  yield* takeLatest(columnsListFetchRequested, fetchCustomReportColumns);
}

export function* watchCustomReport() {
  yield* takeLatest(customReportsFetch, fetchCustomReport);
}
