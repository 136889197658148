import { Box } from '@chakra-ui/react';

import { StatContent } from './StatContent';

export interface IStat {
  label?: string | undefined;
  value?: string | number | undefined;
  help?: string;
  delta?: {
    value: string;
    isUpwardsTrend: boolean;
  };
}

export const Stat = ({ label, value, help, delta, ...boxProps }: IStat) => {
  return (
    <Box w='100%' minWidth={'200px'} px='4' py='4' bg='background.whitecap.light' borderRadius='2xl' {...boxProps}>
      <StatContent label={label} value={value} help={help} delta={delta} {...boxProps} />
    </Box>
  );
};
