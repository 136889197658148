import { Box } from '@chakra-ui/react';
import { useDecision } from '@optimizely/react-sdk';

import { useAppSelector } from '@/app/hooks';
import { usePermissions } from '@/common/hooks';
import { selectIsUpsideManager } from '@/features/auth/userSlice';

import { PayoutMethod } from './payoutMethod/PayoutMethod';
import { BillingPaymentMethods } from './billingPaymentMethods';

export const Payment = () => {
  const isUpsideManager = useAppSelector(selectIsUpsideManager);
  const { hasBillingManagerAccess, hasPayoutManagerAccess, hasPaymentsManagerAccess } = usePermissions();

  const [billingFlag] = useDecision('md-809', { autoUpdate: true });
  const [paymentFlag] = useDecision('md-796', { autoUpdate: true });

  return (
    <Box padding={0} margin={0} maxW='100%'>
      {billingFlag.enabled && (isUpsideManager || hasBillingManagerAccess || hasPaymentsManagerAccess) && (
        <BillingPaymentMethods />
      )}
      {paymentFlag.enabled && (isUpsideManager || hasPayoutManagerAccess || hasPaymentsManagerAccess) && (
        <PayoutMethod />
      )}
    </Box>
  );
};
