import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Box, Flex } from '@chakra-ui/react';
import { Card } from '@components/card';
import { DataLoadingErrorPanel } from '@components/errorAlert';
import { EmptyDataAlertPanel } from '@components/errorAlert';
import { Pagination } from '@components/pagination';
import { AsyncPaginationContainer } from '@components/pagination/AsyncPaginationContainer';
import { PaginationProps } from '@components/pagination/types';
import { Table } from '@components/table';
import { ITableData } from '@components/table/Table';
import { format } from 'date-fns';

import { Status } from '@/common/interfaces';
import { selectDashboardStatus } from '@/features/dashboard/slice';

import { FinancialReportsFilters } from '../components/FinancialReportsFilter';

import {
  selectAvailableAccounts,
  selectPayoutsEndDate,
  selectPayoutsList,
  selectPayoutsListStatus,
  selectPayoutsPagination,
  selectPayoutsStartDate,
  setPayoutsDateRange,
  setPayoutsListPage,
  setPayoutsListPaginationOptions,
  setSelectedAccounts,
} from './slice';

export const Payouts = () => {
  const dispatch = useAppDispatch();

  const dashboardStatus = useAppSelector(selectDashboardStatus);
  const payoutsData = useAppSelector(selectPayoutsList);
  const loadingStatus = useAppSelector(selectPayoutsListStatus);
  const startPayoutsDate = useAppSelector(selectPayoutsStartDate);
  const endPayoutsDate = useAppSelector(selectPayoutsEndDate);
  const payoutsPagination = useAppSelector(selectPayoutsPagination);
  const availableAccounts = useAppSelector(selectAvailableAccounts);

  const handleDateDropdownChange = (dates: Date[]) => {
    const [start, end] = dates;
    const startDate = format(start, 'yyyy-MM-dd');
    const endDate = format(end, 'yyyy-MM-dd');
    dispatch(setPayoutsListPage(1));
    dispatch(setPayoutsDateRange({ startDate, endDate }));
  };

  const handleFilterAccount = (accounts: string[]) => {
    dispatch(setSelectedAccounts(accounts));
  };

  function handlePageChange(page: number): void {
    dispatch(setPayoutsListPage(page));
  }

  function handlePageSizeChange(pageSize: number): void {
    dispatch(setPayoutsListPaginationOptions(Object.assign({}, payoutsPagination.options, { pageSize: pageSize })));
  }

  if (dashboardStatus === Status.Loading) {
    return null;
  }

  return (
    <Box padding={4}>
      {loadingStatus === Status.Failed && <DataLoadingErrorPanel />}
      {loadingStatus !== Status.Failed && (
        <>
          <Card variant='borderless' loadingStatus={loadingStatus}>
            <Flex justifyContent={'flex-end'}>
              <FinancialReportsFilters
                startDate={startPayoutsDate}
                endDate={endPayoutsDate}
                availableAccounts={availableAccounts}
                handleChangeDate={handleDateDropdownChange}
                handleFilterAccount={handleFilterAccount}
              />
            </Flex>

            <AsyncPaginationContainer
              data={payoutsData.table?.rows || []}
              page={payoutsPagination.page}
              total={payoutsData?.count}
              prevPage={payoutsData?.prevPage}
              nextPage={payoutsData?.nextPage}
              isLoading={loadingStatus === Status.Loading}
              options={payoutsPagination.options}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            >
              {(props: PaginationProps) => {
                const { data } = props;
                const tableData: ITableData = Object.assign({}, payoutsData?.table, {
                  rows: data,
                });

                let content = <Box height='400px' />;
                if (data.length > 0) {
                  content = (
                    <>
                      <Table data={tableData} />
                      <Pagination {...props} />
                    </>
                  );
                } else {
                  content = <EmptyDataAlertPanel />;
                }

                return content;
              }}
            </AsyncPaginationContainer>
          </Card>
        </>
      )}
    </Box>
  );
};
