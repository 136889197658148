import { RadialBarSerie } from '@nivo/radial-bar';
import * as Sentry from '@sentry/react';
import { format } from 'date-fns';
import _ from 'lodash';

import { store } from '@/app/store';
import { IStat } from '@/common/components/stat';
import { displayPercent } from '@/common/utils';
import { IBarGraph } from '@/features/dashboard/types';
import i18n from '@/i18n';

import {
  ICapacityByHour,
  ICapacityByLocationRaw,
  ICapacityData,
  ICapacityGauge,
  ICapacityRaw,
  ICapacityStats,
} from './types';

export const formatData = (rawData: ICapacityRaw) => {
  let refinedData: ICapacityData = {};

  if (rawData) {
    try {
      refinedData = {
        statCards: buildCapacityStats(rawData.capacity_stats),
        capacityByLocation: {
          operating: {
            gauge: buildRadialGaugeData(rawData.operating_gauge),
            // barChart: buildByLocationsBarChartData(rawData.operating_hours_by_location),
            barChart: rawData.operating_hours_by_location,
          },
          peak: {
            gauge: buildRadialGaugeData(rawData.peak_gauge),
            // barChart: buildByLocationsBarChartData(rawData.peak_hours_by_location),
            barChart: rawData.peak_hours_by_location,
          },
        },
        capacityByHourBarChart: buildByHourBarChartData(rawData.capacity_by_hour),
      };
    } catch (error) {
      Sentry.captureException(error);
      console.error('Error: ', error);
    }
  } else {
    throw Error('empty capacity result');
  }
  return refinedData;
};

export const buildRadialGaugeData = (byLocationData: ICapacityGauge): Array<RadialBarSerie> => {
  return [
    {
      id: '1',
      data: [
        {
          x: 'non_upside_percentage',
          y: Number(byLocationData.non_upside_percentage),
        },
        {
          x: 'upside_percentage',
          y: Number(byLocationData.upside_percentage),
        },
      ],
    },
  ];
};

export const buildCapacityStats = (capacityStats: ICapacityStats): Array<IStat> => {
  const state = store.getState();
  const roi = state.dashboard.content.performanceSummary.profitabilityWalkthroughData?.returnOnInvestment as string;

  const statCards: Array<IStat> = [
    {
      label: i18n.t('capacityPage.metrics.left.title') as string,
      value: `${displayPercent(capacityStats.open_capacity)}`,
      help: i18n.t('capacityPage.metrics.left.help') as string,
    },
    {
      label: i18n.t('capacityPage.metrics.middle.title') as string,
      value: `${displayPercent(capacityStats.open_capacity_upside_utilized)}`,
      help: i18n.t('capacityPage.metrics.middle.help') as string,
    },
    {
      label: i18n.t('capacityPage.metrics.right.title') as string,
      value: `${displayPercent(roi)}`,
      help: i18n.t('capacityPage.metrics.right.help') as string,
    },
  ];

  return statCards;
};

export const buildByLocationsBarChartData = (locationsData: Array<ICapacityByLocationRaw>): IBarGraph => {
  const refinedData: Array<IBarGraph> = locationsData.map((item: ICapacityByLocationRaw) => {
    const labelMaxLength = 25;
    const siteLabelLength = item.site_name.length + item.site_address_1.length;
    return {
      label:
        siteLabelLength > labelMaxLength - 3
          ? (item.site_name + ', ' + item.site_address_1).slice(0, labelMaxLength - 3) + '...'
          : item.site_name + ', ' + item.site_address_1,
      nonUpsideTrxn: item.non_upside_percentage,
      upsideTrxn: item.upside_percentage,
    };
  });

  const result = [
    {
      data: refinedData,
    },
  ];
  return result;
};

export const buildByHourBarChartData = (data: Array<ICapacityByHour> | undefined): IBarGraph => {
  data = _.orderBy(data, (row) => row);

  const refinedData: Array<IBarGraph> = data.map((item: ICapacityByHour) => {
    const hours = new Date();
    hours.setHours(Number(item.hour));
    const time = format(hours, 'h aaa');
    return {
      label: time,
      nonUpsideTrxn: (item.non_upside_percentage as number) * 100,
      upsideTrxn: (item.upside_percentage as number) * 100,
    };
  });

  const result: Array<IBarGraph> = [
    {
      id: i18n.t('capacityPage.byHourSection.graph.dataTitle'),
      data: refinedData,
    },
  ];
  return result;
};
