/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoIcon } from '@chakra-ui/icons';
import { Flex, Text, Tooltip, useToken } from '@chakra-ui/react';
import { IPieProps, PieChart } from '@components/pieChart';
import { DefaultRawDatum } from '@nivo/pie';
import { animated } from '@react-spring/web';
import _ from 'lodash';

import { getColorBrightness } from '@/common/utils';
import { displayNumber } from '@/common/utils';

const CenteredMetric = ({ dataWithArc, centerX, centerY }: { dataWithArc: any; centerX: any; centerY: any }) => {
  const { t } = useTranslation();
  const [neutral1200] = useToken('colors', ['neutral.1200']);

  let total = 0;

  dataWithArc.forEach((datum: DefaultRawDatum) => {
    total += datum.value;
  });

  return (
    <text
      x={centerX}
      y={centerY}
      textAnchor='middle'
      dominantBaseline='central'
      style={{
        fontSize: '12px',
        color: neutral1200,
      }}
    >
      <tspan
        x={centerX}
        dy='-.6em'
        style={{
          fontWeight: 700,
        }}
      >
        {t('customersPage.customerAnalytics.customerSegmentationChart.total')}
      </tspan>
      <tspan x={centerX} dy='1.2em'>
        {displayNumber(total)}
      </tspan>
    </text>
  );
};

export const CustomerSegmentBreakdownChart = ({ data, loadingStatus, title, width = '100%' }: IPieProps) => {
  const { t } = useTranslation();

  const [neutral1200, neutral50] = useToken('colors', ['neutral.1200', 'neutral.50']);

  const segmentedCustomers = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.filter((datum: { id: string; value: number }) => datum.id !== 'Unsegmented');
  }, [data]);

  const onePercent = _.sumBy(segmentedCustomers, 'value') / 100;

  const unsegmentedCustomers = useMemo(() => {
    if (!data) {
      return null;
    }

    return data.find((datum: { id: string; value: number }) => datum.id === 'Unsegmented');
  }, [data]);

  const chartProps = {
    data: segmentedCustomers,
    margin: { top: 80, bottom: 80, left: 80, right: 80 },
    activeOuterRadiusOffset: 8,
    innerRadius: 0.55,
    colors: (value: any) => value.data.color || neutral1200,
    enableArcLabels: true,
    padAngle: 0.8,
    arcLinkLabelsSkipAngle: 10,
    arcLinkLabelsTextColor: '#333333',
    arcLinkLabelsThickness: 3,
    arcLinkLabelsColor: { from: 'color' },
    arcLabelsSkipAngle: 20,
    arcLabelsTextColor: (value: any) => {
      const brightness = value.data.color ? getColorBrightness(value.data.color) : 0;
      return brightness > 100 ? neutral1200 : neutral50;
    },
    arcLinkLabelsOffset: 2,
    arcLabelsComponent: (props: any) => {
      const { datum, style } = props;
      const numValue = Number(datum.value);
      const percent = onePercent > 0 ? Math.round(numValue / onePercent) : 0;
      return (
        <animated.g transform={style.transform} style={{ pointerEvents: 'none' }}>
          <text textAnchor='middle' dominantBaseline='central' fill={style.textColor}>
            <tspan
              x='0'
              dy='-.6em'
              style={{
                fontSize: 10,
                fontWeight: 800,
              }}
            >
              {percent}%
            </tspan>
            <tspan
              x='0'
              dy='1.2em'
              style={{
                fontSize: 10,
              }}
            >
              {displayNumber(numValue)}
            </tspan>
          </text>
        </animated.g>
      );
    },
    layers: ['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredMetric] as any[],
  };

  return (
    <>
      <Flex direction='column' alignItems='center' mb={5}>
        <PieChart
          data={segmentedCustomers}
          loadingStatus={loadingStatus}
          title={title}
          width={width}
          customProps={chartProps}
        />
      </Flex>
      {unsegmentedCustomers && (
        <Flex alignItems='center' justifyContent='center' gap={2} mb={5} zIndex={5}>
          <Tooltip
            label={t('customersPage.customerAnalytics.customerSegmentationChart.unsegmentedCustomersTooltip')}
            placement='top'
          >
            <InfoIcon color='neutral.400' />
          </Tooltip>
          <Text>
            {t('customersPage.customerAnalytics.customerSegmentationChart.unsegmentedCustomers', {
              unsegmentedCustomers: displayNumber(unsegmentedCustomers.value),
            })}
          </Text>
        </Flex>
      )}
    </>
  );
};
