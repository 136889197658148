import React, { useMemo } from 'react';
import { Button, Tooltip } from '@chakra-ui/react';
import { DateObj, RenderProps } from 'dayzed';

import { CalendarConfigs, DatePickerProps, DayOfMonthBtnStyleProps } from '../types';

interface DayOfMonthProps extends DatePickerProps {
  renderProps: RenderProps;
  isInRange?: boolean | null;
  isFirst?: boolean | null;
  isLast?: boolean | null;
  isFirstNonSelectable?: boolean | null;
  isLastNonSelectable?: boolean | null;
  disabled?: boolean | null;
  tooltip?: string;
  configs?: CalendarConfigs;
  dateObj: DateObj;
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const halfGap = 0.125; //default Chakra-gap-space-1 is 0.25rem

export const DayOfMonth: React.FC<DayOfMonthProps> = ({
  dateObj,
  propsConfigs,
  isInRange,
  isFirst,
  isLast,
  isFirstNonSelectable,
  isLastNonSelectable,
  minDate,
  maxDate,
  disabled,
  configs,
  renderProps,
  onMouseEnter,
}) => {
  const { date, selected, selectable, today } = dateObj;
  const { getDateProps } = renderProps;
  const { defaultBtnProps, isInRangeBtnProps, selectedBtnProps, todayBtnProps } =
    propsConfigs?.dayOfMonthBtnProps || {};

  const styleBtnProps: DayOfMonthBtnStyleProps = useMemo(
    () => ({
      defaultBtnProps: {
        width: '48px',
        height: '48px',
        variant: 'outline',
        color: 'neutral.1200',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        background: 'transparent',
        borderColor: 'transparent',
        borderRadius: 0,
        // this intends to fill the visual gap from Grid to improve the UX
        // so the button active area is actually larger than what it's seen
        _after: {
          content: "''",
          position: 'absolute',
          top: `-${halfGap}rem`,
          left: `-${halfGap}rem`,
          bottom: `-${halfGap}rem`,
          right: `-${halfGap}rem`,
          borderWidth: `${halfGap}rem`,
          borderColor: 'transparent',
        },
        ...defaultBtnProps,
        _hover: selectable
          ? {
              bg: 'blue.50',
              borderRadius: '99px',
              ...defaultBtnProps?._hover,
            }
          : undefined,
        _active: {
          background: 'blue.50',
        },
      },
      isInRangeBtnProps: {
        color: 'neutral.1200',
        background: 'blue.50',
        _hover: {
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        },
        _disabled: {
          background: 'blue.50',
        },
        ...isInRangeBtnProps,
      },
      startDateBtnProps: {
        fontWeight: 500,
        color: 'neutral.1200',
        background: 'blue.50',
        borderTopLeftRadius: '99px',
        borderBottomLeftRadius: '99px',
        _hover: {
          borderTopLeftRadius: '99px',
          borderBottomLeftRadius: '99px',
          borderTopRightRadius: '0px',
          borderBottomRightRadius: '0px',
        },
      },
      endDateBtnProps: {
        fontWeight: 500,
        color: 'neutral.1200',
        background: 'blue.50',
        borderTopRightRadius: '99px',
        borderBottomRightRadius: '99px',
        _hover: {
          borderTopLeftRadius: '0px',
          borderBottomLeftRadius: '0px',
          borderTopRightRadius: '99px',
          borderBottomRightRadius: '99px',
        },
      },
      singleSelectedDateBtnProps: {
        fontWeight: 500,
        borderRadius: '99px',
        _hover: {
          borderRadius: '99px',
        },
      },
      selectedBtnProps: {
        color: 'background.primary.active',
        fontWeight: 500,
        background: 'blue.50',
        ...selectedBtnProps,
      },
      todayBtnProps: {
        background: 'blue.100',
        borderRadius: '99px',
        ...todayBtnProps,
      },
      disabledBtnProps: {
        background: 'transparent',
        borderRadius: '0px',
        color: 'neutral.800',
        _hover: {
          borderRadius: '99px',
          border: '1px solid',
          borderColor: 'neutral.100',
        },
      },
    }),
    [defaultBtnProps, isInRangeBtnProps, selectedBtnProps, todayBtnProps, selectable],
  );

  const btnProps = Object.assign(
    getDateProps({
      dateObj,
      onMouseEnter,
    }),
    { isDisabled: !selectable || Boolean(disabled) },
    styleBtnProps.defaultBtnProps,
  );

  if (isInRange && selectable && styleBtnProps.isInRangeBtnProps) {
    Object.assign(btnProps, styleBtnProps.isInRangeBtnProps);
  }

  if (selected && selectable && styleBtnProps.selectedBtnProps) {
    Object.assign(btnProps, styleBtnProps.selectedBtnProps);
  }

  if (isFirst && isLast && styleBtnProps.singleSelectedDateBtnProps) {
    Object.assign(btnProps, styleBtnProps.singleSelectedDateBtnProps);
  } else if (isFirst && styleBtnProps.startDateBtnProps) {
    Object.assign(btnProps, styleBtnProps.startDateBtnProps);
  } else if (isLast && styleBtnProps.endDateBtnProps) {
    Object.assign(btnProps, styleBtnProps.endDateBtnProps);
  }

  if (disabled) {
    Object.assign(btnProps, styleBtnProps.disabledBtnProps);
  }
  if (today) {
    if (styleBtnProps.todayBtnProps) {
      Object.assign(btnProps, styleBtnProps.todayBtnProps);
    }
  } else if (!selectable) {
    btnProps.background = 'neutral.100';
    if (isFirstNonSelectable) {
      btnProps.borderTopLeftRadius = '99px';
      btnProps.borderBottomLeftRadius = '99px';
    }
    if (isLastNonSelectable) {
      btnProps.borderTopRightRadius = '99px';
      btnProps.borderBottomRightRadius = '99px';
    }
    btnProps._hover = {
      background: 'neutral.100',
    };
  }

  const testId = `${date.getMonth() + 1}-${date.getDate()}`;

  const button = (
    <Button {...btnProps} data-testid={testId}>
      {date.getDate()}
    </Button>
  );

  let tooltip;
  if (disabled && configs?.minDaysNumber && configs?.tooltipsTexts.minDaysNumber) {
    tooltip = configs.tooltipsTexts.minDaysNumber;
  } else if (maxDate && dateObj.date > maxDate && configs?.tooltipsTexts.moreThanMax) {
    tooltip = configs.tooltipsTexts.moreThanMax;
  } else if (minDate && dateObj.date < minDate && configs?.tooltipsTexts.lessThanMin) {
    tooltip = configs.tooltipsTexts.lessThanMin;
  }

  return tooltip ? (
    <Tooltip label={tooltip} placement='top'>
      {button}
    </Tooltip>
  ) : (
    button
  );
};
