import { useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  Input,
  Select,
  Text,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import axios, { AxiosError } from 'axios';
import { z } from 'zod';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { DataLoadingErrorPanel } from '@/common/components/errorAlert';
import { CustomFormLabel } from '@/common/components/form';
import { LoadingSpinner } from '@/common/components/loadingSpinner';
import { Section } from '@/common/components/section';
import { usePermissions } from '@/common/hooks';
import { Status } from '@/common/interfaces';
import states from '@/common/utils/states.json';
import { selectActiveVertical, selectIsUpsideManager, selectUserOrg } from '@/features/auth/userSlice';

import { selectPayoutLoadingStatus, updatePayoutRequest } from './slice';
import { managePayoutBankAccountSchema } from './validators';

type FormSchemaType = z.infer<typeof managePayoutBankAccountSchema>;

export const ManagePayoutBankAccount = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const toast = useToast();

  const orgId = useAppSelector(selectUserOrg);
  const vertical = useAppSelector(selectActiveVertical);
  const loadingStatus = useAppSelector(selectPayoutLoadingStatus);
  const isUpsideManager = useAppSelector(selectIsUpsideManager);

  const payoutMethod = useAppSelector((state) => state.payment.payoutMethods.find((item) => item.uuid === id));

  const { hasPaymentsManagerAccess, hasPayoutManagerAccess } = usePermissions();

  const payoutRole = useMemo(() => {
    let role = '';
    if (hasPaymentsManagerAccess) {
      role = 'PaymentsManager';
    }
    if (hasPayoutManagerAccess) {
      role = 'PayoutManager';
    }
    if (isUpsideManager) {
      return 'UpsideManager';
    }
    return role;
  }, [hasPaymentsManagerAccess, hasPayoutManagerAccess, isUpsideManager]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<FormSchemaType>({
    resolver: zodResolver(managePayoutBankAccountSchema),
  });

  const onSubmit: SubmitHandler<FormSchemaType> = async (data) => {
    try {
      if (payoutMethod && payoutRole !== '') {
        const uuid = payoutMethod.uuid;
        dispatch(updatePayoutRequest({ orgId, role: payoutRole, vertical, uuid, data }));
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<{ message?: string }>;
        toast({
          title: 'Error',
          description: axiosError.response?.data?.message ?? 'Something went wrong',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  useEffect(() => {
    if (payoutMethod) {
      setValue('account_nickname', payoutMethod.account_nickname);
      setValue('account_holder_name', payoutMethod.account_holder_name);
      setValue('account_holder_type', payoutMethod.account_holder_type);
      setValue('street_1', payoutMethod.billing_address.street_1);
      setValue('city', payoutMethod.billing_address.city);
      setValue('state', payoutMethod.billing_address.state);
      setValue('zip', payoutMethod.billing_address.zip);
    }
  }, [payoutMethod, setValue]);

  return (
    <>
      <Breadcrumb separator={<ChevronRightIcon color='gray.500' />}>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to='/settings/payments'>
            {t('payment.billingPaymentMethods.addBankAccount.breadcrumbs.payments')}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink as={Link} isCurrentPage to='#'>
            {t('payment.billingPaymentMethods.addBankAccount.breadcrumbs.manage')}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Section name={`Manage ${payoutMethod?.plaid_metadata.name} ending in ${payoutMethod?.plaid_metadata.mask}`}>
        {loadingStatus === Status.Loading && (
          <Flex alignItems='center' justifyContent='center' mt={10}>
            <LoadingSpinner />
          </Flex>
        )}

        {loadingStatus === Status.Failed && (
          <Flex alignItems='center' justifyContent='center'>
            <DataLoadingErrorPanel />
          </Flex>
        )}

        {loadingStatus === Status.Idle && (
          <FormControl as='form' onSubmit={handleSubmit(onSubmit)} mt={6} maxWidth={'600px'}>
            <Flex direction='column' gap={3}>
              <CustomFormLabel htmlFor='account_nickname'>
                {t('payment.managePayment.nickname')}
                <Input id='account_nickname' type='text' {...register('account_nickname')} />
              </CustomFormLabel>
              {errors.account_nickname && <Text variant={'error'}>{errors.account_nickname.message}</Text>}

              <CustomFormLabel htmlFor='account_holder_name'>
                {t('payment.managePayment.holderName')}
                <Input id='account_holder_name' type='text' {...register('account_holder_name')} />
              </CustomFormLabel>
              {errors.account_holder_name && <Text variant={'error'}>{errors.account_holder_name.message}</Text>}

              <CustomFormLabel htmlFor='account_holder_type'>
                {t('payment.managePayment.holderType')}
                <Select
                  id='account_holder_type'
                  placeholder='Choose account holder type'
                  {...register('account_holder_type', { required: true })}
                  isInvalid={errors.account_holder_type && errors.account_holder_type?.message !== ''}
                >
                  <option value='company'>{t('payment.addBankForm.company')}</option>
                  <option value='individual'>{t('payment.addBankForm.individual')}</option>
                </Select>
              </CustomFormLabel>
              {errors.account_holder_type && <Text variant={'error'}>{errors.account_holder_type.message}</Text>}

              <CustomFormLabel htmlFor='street_1'>
                {t('payment.addPayout.billingAddress')}
                <Input id='street_1' type='text' {...register('street_1')} />
              </CustomFormLabel>
              {errors.street_1 && <Text variant={'error'}>{errors.street_1.message}</Text>}

              <CustomFormLabel htmlFor='city'>
                {t('payment.addPayout.city')}
                <Input id='city' type='text' {...register('city')} />
              </CustomFormLabel>
              {errors.city && <Text variant={'error'}>{errors.city.message}</Text>}

              <Flex mt={3} gap={2}>
                <Box w='full'>
                  <CustomFormLabel htmlFor='state' isInvalid={errors.state && errors.state?.message !== ''}>
                    {t('payment.addPayout.state')}
                    <Select
                      id='state'
                      placeholder='Choose a state'
                      {...register('state', { required: true })}
                      isInvalid={errors.state && errors.state?.message !== ''}
                    >
                      {states.states.map((state: { name: string; code: string }) => (
                        <option key={state.code} value={state.code}>
                          {state.name}
                        </option>
                      ))}
                    </Select>
                  </CustomFormLabel>
                  {errors.state && <Text variant={'error'}>{errors.state?.message}</Text>}
                </Box>
                <Box w='full'>
                  <CustomFormLabel htmlFor='zip' isInvalid={errors.zip && errors.zip?.message !== ''}>
                    {t('payment.addPayout.zipCode')}

                    <Input
                      id='zip'
                      {...register('zip', { required: true })}
                      isInvalid={errors.zip && errors.zip?.message !== ''}
                    />
                  </CustomFormLabel>
                  {errors.zip && <Text variant={'error'}>{errors.zip?.message}</Text>}
                </Box>
              </Flex>
            </Flex>

            <Flex mt={6} gap={3}>
              <Button as={Link} to='/settings/payments' variant='accentSecondary' size='md' px={12}>
                {t('payment.addPayout.back')}
              </Button>
              <Button variant='brandPrimary' size='md' type='submit' isDisabled={isSubmitting} px={12}>
                {t('payment.addPayout.save')}
              </Button>
            </Flex>
          </FormControl>
        )}
      </Section>
    </>
  );
};
