import { Card } from '../card';

import { EmptyDataAlert } from './EmptyDataAlert';

export interface AltMessage {
  title: string;
  message: string;
}

export interface EmptyDataAlertPanelProps {
  altMessage?: AltMessage | null;
}

export const EmptyDataAlertPanel: React.FC<EmptyDataAlertPanelProps> = ({ altMessage = null }) => {
  const alertProps = {
    height: '100%',
  };

  return (
    <Card width='613px' height='347px' margin='50px auto'>
      <EmptyDataAlert boxProps={alertProps} altMessage={altMessage} />
    </Card>
  );
};
