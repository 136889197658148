import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '@chakra-ui/react';

interface ISectionProps {
  name?: string | undefined;
  description?: string;
  children?: ReactNode[] | ReactNode;
}

export const Section = ({ name, description, children }: ISectionProps) => {
  const { t } = useTranslation();

  return (
    <>
      {name && (
        <Heading size='lg' mt='8'>
          {t(name)}
        </Heading>
      )}
      {description && (
        <Heading size='md' mt={4}>
          {t(description)}
        </Heading>
      )}
      {children && children}
    </>
  );
};
