/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Circle, Flex, Grid, GridItem } from '@chakra-ui/react';

import { displayPercent } from '@/common/utils';

export const CapacityByLocationPopover = ({ point, data }: { point: any; data: any }) => {
  const { t } = useTranslation();

  const upsideShare = useMemo(() => {
    if (!point.data['upsideTrxn'] && !point.data['nonUpsideTrxn']) {
      return displayPercent('0.0');
    }

    const upsideTxns = point.data['upsideTrxn'] || 0.0;
    const nonUpsideTxns = point.data['nonUpsideTrxn'] || 0.0;

    const shareTransactions = upsideTxns / (upsideTxns + nonUpsideTxns);

    return displayPercent(String(shareTransactions));
  }, [point]);

  return (
    <>
      <Grid
        templateColumns='1fr'
        rowGap={1}
        columnGap={2}
        bgColor='white'
        shadow='base'
        borderRadius={10}
        padding={4}
        width={'100%'}
        zIndex={100}
      >
        <Box width={'350px'} lineHeight={'12px'}>
          <GridItem fontSize='18px' padding='8px' fontWeight={500}>
            {data[point.index].site_name}
          </GridItem>
          <GridItem fontSize='14px' padding='8px' fontWeight={500}>
            {data[point.index].site_address_1}
          </GridItem>
          <Flex justifyContent={'space-between'}>
            <GridItem fontSize='14px' padding='8px' fontWeight={400}>
              <Flex>
                <Circle size='12px' bg='background.primary.default' alignSelf={'center'} mr={'8px'} />
                {t('capacityPage.byLocationSection.graph.popover.nonUpsideTxns')}
              </Flex>
            </GridItem>
            <GridItem fontSize='14px' padding='8px' fontWeight={400}>
              {displayPercent(point.data['nonUpsideTrxn']) === ''
                ? '0.0%'
                : displayPercent(point.data['nonUpsideTrxn'])}
            </GridItem>
          </Flex>
          <Flex justifyContent={'space-between'}>
            <GridItem fontSize='14px' padding='8px'>
              <Flex>
                <Circle size='12px' bg='data.green' alignSelf={'center'} mr={'8px'} fontWeight={400} />
                {t('capacityPage.byLocationSection.graph.popover.upsideTxns')}
              </Flex>
            </GridItem>
            <GridItem fontSize='14px' padding='8px' fontWeight={400}>
              {displayPercent(point.data['upsideTrxn']) === '' ? '0.0%' : displayPercent(point.data['upsideTrxn'])}
            </GridItem>
          </Flex>
          <Flex justifyContent={'space-between'}>
            <GridItem fontSize='14px' padding='8px' lineHeight={'12px'}>
              <Flex>{t('capacityPage.byHourSection.graph.popover.upsideShare')}</Flex>
            </GridItem>
            <GridItem fontSize='14px' padding='8px' lineHeight={'12px'}>
              {upsideShare}
            </GridItem>
          </Flex>

          <Flex justifyContent={'space-between'}>
            <GridItem fontSize='14px' padding='8px' fontWeight={500}>
              {t('capacityPage.byLocationSection.graph.popover.maxCapacity')}
            </GridItem>
            <GridItem fontSize='14px' padding='8px' fontWeight={500}>
              {data[point.index].max_trxns_per_hour}
            </GridItem>
          </Flex>
        </Box>
      </Grid>
    </>
  );
};

export const CapacityByHourPopover = (props: any) => {
  const { t } = useTranslation();
  const { point, segment } = props;

  const upsideShare = useMemo(() => {
    if (point.data['upsideTrxn'] === undefined || point.data['nonUpsideTrxn'] === undefined) {
      return displayPercent(0);
    }

    const upsideTxns = point.data['upsideTrxn'];
    const nonUpsideTxns = point.data['nonUpsideTrxn'];

    const shareTransactions = upsideTxns / (upsideTxns + nonUpsideTxns);

    return displayPercent(shareTransactions);
  }, [point]);

  return (
    <>
      <Grid
        templateColumns='1fr'
        rowGap={1}
        columnGap={2}
        bgColor='white'
        shadow='base'
        borderRadius={10}
        padding={4}
        width={'100%'}
        zIndex={100}
      >
        <Box width={'350px'}>
          <GridItem fontSize='16px' padding='8px' lineHeight={'24px'}>
            {`${t('capacityPage.byHourSection.graph.popover.title')} ${segment}`}
          </GridItem>
          <Flex justifyContent={'space-between'}>
            <GridItem fontSize='14px' padding='8px' lineHeight={'12px'}>
              <Flex>
                <Circle size='12px' bg='background.primary.default' alignSelf={'center'} mr={'8px'} />
                {t('capacityPage.byHourSection.graph.popover.nonUpsideTxns')}
              </Flex>
            </GridItem>
            <GridItem fontSize='14px' padding='8px' lineHeight={'12px'}>
              {point.data['nonUpsideTrxn'] === undefined ? '0.0%' : `${point.data['nonUpsideTrxn'].toFixed(1)}%`}
            </GridItem>
          </Flex>
          <Flex justifyContent={'space-between'}>
            <GridItem fontSize='14px' padding='8px' lineHeight={'12px'}>
              <Flex>
                <Circle size='12px' bg='data.green' alignSelf={'center'} mr={'8px'} />
                {t('capacityPage.byHourSection.graph.popover.upsideTxns')}
              </Flex>
            </GridItem>
            <GridItem fontSize='14px' padding='8px' lineHeight={'12px'}>
              {point.data['upsideTrxn'] === undefined ? '0.0%' : `${point.data['upsideTrxn'].toFixed(1)}%`}
            </GridItem>
          </Flex>
          <Flex justifyContent={'space-between'}>
            <GridItem fontSize='14px' padding='8px' lineHeight={'12px'}>
              <Flex>{t('capacityPage.byHourSection.graph.popover.upsideShare')}</Flex>
            </GridItem>
            <GridItem fontSize='14px' padding='8px' lineHeight={'12px'}>
              {upsideShare}
            </GridItem>
          </Flex>
        </Box>
      </Grid>
    </>
  );
};
