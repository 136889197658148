import { Card } from '../card';

import { DataLoadingErrorAlert } from './DataLoadingErrorAlert';

export const DataLoadingErrorPanel = () => {
  const alertProps = {
    height: '100%',
  };

  return (
    <Card width='613px' height='347px' margin='50px auto'>
      <DataLoadingErrorAlert boxProps={alertProps} />
    </Card>
  );
};
