import { z } from 'zod';

export const addPayoutFormSchema = z.object({
  street_1: z
    .string()
    .min(1, 'Address is required')
    .max(100)
    .regex(/^(?=.*\d)(?=.*[a-zA-Z].*[a-zA-Z].*[a-zA-Z])[a-zA-Z\d\s\W]+$/, 'Invalid street address'),
  city: z.string().min(1, 'City is required').max(100),
  state: z.string().min(1, 'Please select a state'),
  zip: z
    .string()
    .min(5, 'Please enter valid zip code')
    .regex(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'Invalid zip code'),
  account_nickname: z.string().min(1, 'Account nickname is required '),
  account_holder_name: z.string().min(1, 'Account holder name is required '),
  account_holder_type: z.string().min(1, 'Please select account holder type'),
  user_accept_terms: z.literal(true, {
    errorMap: () => ({ message: 'You must authorize Upside access to your account in order to proceed ' }),
  }),
});

export const managePayoutBankAccountSchema = z.object({
  account_nickname: z.string().min(1, 'Nickname cannot be empty'),
  account_holder_name: z.string().min(1, 'Account holder name cannot be empty'),
  account_holder_type: z.string().min(1, 'Account holder type cannot be empty'),
  street_1: z
    .string()
    .min(1, 'Street cannot be empty')
    .regex(/^(?=.*\d)(?=.*[a-zA-Z].*[a-zA-Z].*[a-zA-Z])[a-zA-Z\d\s\W]+$/, 'Invalid street address'),
  city: z.string().min(1, 'City cannot be empty'),
  state: z.string().min(1, 'State cannot be empty'),
  zip: z
    .string()
    .min(1, 'Zip cannot be empty')
    .regex(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'Invalid zip code'),
});
