import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button, FormControl, Heading, Input, Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { merge } from 'lodash';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { CustomFormLabel } from '@/common/components/form';
import { ContactInfoFormData } from '@/features/registration/types';
import { contactInfoSchema } from '@/features/registration/validators';

import { selectRegistrationData, updateRegistrationFormData } from '../slice';

export function ContactInfo() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const registrationFormData = useAppSelector(selectRegistrationData);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ContactInfoFormData>({
    resolver: zodResolver(contactInfoSchema),
    defaultValues: {
      ...registrationFormData,
    },
  });

  const onSubmit = (data: ContactInfoFormData) => {
    const updatedData = merge({ ...registrationFormData, ...data });
    dispatch(updateRegistrationFormData(updatedData));
    navigate('/registration/3');
  };

  return (
    <Box mt={4}>
      <Heading as='h2' size='md' mb={4}>
        {t('registrationPage.titles.contactInfo')}
      </Heading>

      <FormControl as='form' onSubmit={handleSubmit(onSubmit)}>
        <Text fontWeight='bold' mt='6' mb='6'>
          {t('registrationPage.titles.contactInfoHelp')}
        </Text>

        <Text> {t('registrationPage.fields.firstName')}</Text>
        <CustomFormLabel htmlFor='firstName'>
          <Input
            id='firstName'
            mt={2}
            {...register('formData.firstName')}
            isInvalid={!!errors.formData?.firstName}
            autoComplete='given-name'
          />
          {errors.formData?.firstName && <Text variant='error'>{errors.formData.firstName.message}</Text>}
        </CustomFormLabel>

        <Text mt={2}>{t('registrationPage.fields.lastName')}</Text>
        <CustomFormLabel htmlFor='lastName'>
          <Input
            id='lastName'
            mt={2}
            {...register('formData.lastName')}
            isInvalid={!!errors.formData?.lastName}
            autoComplete='family-name'
          />
          {errors.formData?.lastName && <Text variant='error'>{errors.formData.lastName.message}</Text>}
        </CustomFormLabel>

        <Text>{t('registrationPage.fields.phone')}</Text>
        <CustomFormLabel htmlFor='phone'>
          <Input
            id='phone'
            mt={2}
            {...register('formData.phone')}
            isInvalid={!!errors.formData?.phone}
            autoComplete='phone'
          />
          {errors.formData?.phone && <Text variant='error'>{errors.formData.phone.message}</Text>}
        </CustomFormLabel>

        <Button mt={5} type='submit' variant='brandPrimary' size='md' px={8}>
          {t('registrationPage.actions.continue')}
        </Button>
      </FormControl>
    </Box>
  );
}
