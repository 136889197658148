import { useTranslation } from 'react-i18next';
import { Heading, Stack } from '@chakra-ui/react';
import { AxisProps } from '@nivo/axes';
import { linearGradientDef } from '@nivo/core';
import { LegendProps } from '@nivo/legends';
import { Layer, LineProps, LineSvgProps, ResponsiveLine, Serie } from '@nivo/line';
import { ScaleLinearSpec } from '@nivo/scales';

import { TemporalUnit } from '@/features/dashboard/slice';

import { Status } from '../../interfaces';
import { findMinMaxValuesFromSeries } from '../../utils/graphing';
import { formatterSwitch } from '../../utils/utils';
import { LoadingSpinner } from '../loadingSpinner/LoadingSpinner';

interface ILineGraphProps {
  loadingStatus?: Status;
  title?: string;
  data: Array<Serie> | undefined;
  colors?: Array<string> | null;
  isCurrency?: boolean;
  isPercentage?: boolean;
  yScaleStacked?: boolean;
  isArea?: boolean;
  width?: string | number;
  customProps?: Partial<LineProps>;
  temporalUnit?: TemporalUnit;
  // markers?: Array<CartesianMarkerProps>;
}

export const LineGraph = ({
  title,
  data,
  // markers,
  loadingStatus,
  customProps = {},
  isCurrency = false,
  isPercentage = false,
  yScaleStacked = false,
  isArea = false,
  width = '100%',
  temporalUnit = 'week',
  colors = [],
}: ILineGraphProps) => {
  const { t } = useTranslation();
  const formatter = formatterSwitch({ isCurrency, isPercentage });
  const isLoading = loadingStatus === Status.Loading;

  const axisLeftSwitch = () => {
    if (isPercentage || isCurrency) {
      return { format: (value: string | number) => formatter.format(Number(value)) };
    }
    return undefined;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const bottomAxisGenerator = (): AxisProps<any> | null => {
    const legend = temporalUnit === 'week' ? t('axisLabelWeekly') : t('axisLabelMonthly');
    return { legend: legend, legendPosition: 'middle', legendOffset: 32 };
  };

  const generateMinMaxYScale = (): object => {
    if (data) {
      const { min, max } = findMinMaxValuesFromSeries(data, 'y');
      return {
        min: min * 0.9,
        max: max * 1.1,
      };
    }
    return {
      min: 'auto',
      max: 'auto',
    };
  };

  const yScaleFormatter = (): ScaleLinearSpec | undefined => {
    if (isPercentage) {
      return {
        type: 'linear',
        stacked: yScaleStacked,
        ...generateMinMaxYScale(),
      };
    }
    return {
      type: 'linear',
      stacked: yScaleStacked,
    };
  };

  const legendsFormatter = (): LegendProps[] | undefined => {
    return [
      {
        anchor: 'bottom',
        direction: 'row',
        justify: false,
        translateX: -30,
        translateY: 60,
        itemsSpacing: 100,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 12,
        symbolShape: 'circle',
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1,
            },
          },
        ],
      },
    ];
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const DashedSolidLine = ({ series, lineGenerator, xScale, yScale }: any) => {
    const isStacked = !!yScaleFormatter()?.stacked;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return series.map(({ id, data: lineData, color, customProps }: any) => {
      return (
        <path
          key={id}
          d={lineGenerator(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            lineData.map((d: any) => ({
              x: xScale(d.data.x),
              y: yScale(isStacked ? d.data.yStacked : d.data.y),
            })),
          )}
          fill='none'
          stroke={color}
          style={
            customProps
              ? { ...customProps }
              : {
                  strokeWidth: 2,
                }
          }
        />
      );
    });
  };

  const buildCustomLayers = (): Layer[] => {
    return ['grid', 'markers', 'areas', 'slices', 'axes', 'legends', 'crosshair', 'mesh', DashedSolidLine, 'points'];
  };

  const commonProperties: LineSvgProps = {
    colors: colors ? colors : { scheme: 'category10' },
    margin: { top: 40, right: 40, bottom: 60, left: 50 },
    data: data !== undefined ? data : [{ id: 0, data: [] }],
    animate: true,
    fill: [{ match: '*', id: 'gradientA' }],
    defs: [
      linearGradientDef('gradientA', [
        { offset: 0, color: 'inherit' },
        { offset: 100, color: 'inherit', opacity: 0 },
      ]),
    ],
    axisBottom: bottomAxisGenerator(),
    axisLeft: axisLeftSwitch(),
    layers: buildCustomLayers(),
    isInteractive: true,
    enableSlices: 'x',
    enablePoints: true,
    yScale: yScaleFormatter(),
    yFormat: function (y) {
      return formatter.format(Number(y));
    },
    legends: legendsFormatter(),
    enableArea: isArea,
    pointSize: 10,
    pointColor: 'white',
    pointBorderColor: { from: 'serieColor' },
    pointBorderWidth: 2,
    // markers: markers,
    ...customProps,
  };

  return (
    <>
      <Stack h='450px' w={width}>
        <Heading size='md' opacity={isLoading ? '20%' : '100%'}>
          {t(title as string)}
        </Heading>
        <Stack
          h='100%'
          w='100%'
          maxW={width}
          pb={8}
          align={isLoading ? 'center' : 'default'}
          justify={isLoading ? 'center' : 'default'}
        >
          {isLoading ? <LoadingSpinner /> : <ResponsiveLine {...commonProperties} />}
        </Stack>
      </Stack>
    </>
  );
};
