/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStandaloneToast } from '@chakra-ui/react';
import { push } from 'redux-first-history';
import { call, delay, put, select, takeLatest } from 'typed-redux-saga';

import { RootState } from '@/app/store';
import { theme } from '@/theme';

import * as api from './api';
import { formatData } from './dataFormatting';
import {
  addNewPayoutFailed,
  addNewPayoutRequest,
  addNewPayoutSucceeded,
  deletePayoutRequest,
  deletePayoutSucceeded,
  paymentsFailed,
  paymentsFetchRequest,
  paymentsSucceeded,
  plaidTokenFailed,
  plaidTokenFetchRequest,
  plaidTokenSucceeded,
  updatePayoutRequest,
  updatePayoutSucceeded,
} from './slice';

const { toast } = createStandaloneToast({ theme });

/*****************************************************************************/
/******************************* WORKERS *************************************/
/*****************************************************************************/

export function* fetchPayments(action: any) {
  try {
    const result = yield* call(api.fetchPayments, action.payload);
    const refinedResults = formatData(result);

    yield* put(paymentsSucceeded(refinedResults));
    return;
  } catch (error: any) {
    yield* put(paymentsFailed(error.message));
    return;
  }
}

export function* fetchPlaidToken(action: any) {
  try {
    const result = yield* call(api.fetchPlaidToken, action.payload);

    yield* put(plaidTokenSucceeded({ token_data: result }));
  } catch (error: any) {
    yield* put(plaidTokenFailed(error.message));
  }
}

export function* addNewPayout(action: any) {
  try {
    const state = yield* select((state: RootState) => state.payment.newPayout);

    const { metadata, ...payload } = action.payload;

    const status = yield* call(api.addNewPayout, {
      ...payload,
      data: {
        metadata: metadata,
        form_data: state,
      },
    });

    if (status === 201) {
      yield* put(addNewPayoutSucceeded());
      yield* put(paymentsFetchRequest(payload));

      toast({
        title: 'Payout saved successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      yield* delay(1000);
      yield* put(push('/settings/payments'));
    } else {
      yield* put(addNewPayoutFailed(status?.toString() ?? 'error'));

      toast({
        title: 'Failed to create payout',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  } catch (error: any) {
    yield* put(addNewPayoutFailed(error.message));

    toast({
      title: 'Failed to create payout',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
  }
}

export function* updatePayout(action: any) {
  try {
    const { metadata, data, uuid, ...payload } = action.payload;

    // data is used for existing payment user input update
    // metadata is used for verifying a pending verification payment method
    // either submit sub-action enum, decide based on data coming in, or
    // make a complete new set of actions :(

    // if data is null, then it must be a verification update
    const submittedData = data ? { form_data: data } : { metadata: metadata };

    const result = yield* call(api.updatePayout, {
      ...payload,
      uuid,
      data: submittedData,
    });

    if (result === 200) {
      yield* put(updatePayoutSucceeded());
      yield* put(paymentsFetchRequest(payload));

      toast({
        title: 'Success',
        description: 'Payout method updated',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      yield* delay(1000);
      yield* put(push('/settings/payments'));
    } else {
      toast({
        title: 'Failed to update payout method',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      yield* put(addNewPayoutFailed(`error: ${result}`));
    }
  } catch (error: any) {
    toast({
      title: 'Failed to update payout method',
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
    yield* put(addNewPayoutFailed(error.message));
  }
}

export function* deletePayout(action: any) {
  try {
    const { uuid, ...payload } = action.payload;

    yield* call(api.deletePayout, {
      ...payload,
      uuid,
    });

    yield* put(deletePayoutSucceeded());
    yield* put(paymentsFetchRequest(payload));

    toast({
      title: 'Success',
      description: 'Payout method deleted',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });

    yield* delay(1000);
    yield* put(push('/settings/payments'));
  } catch (error: any) {
    yield* put(addNewPayoutFailed(error.message));
  }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchPlaidToken() {
  yield* takeLatest(plaidTokenFetchRequest, fetchPlaidToken);
}

export function* watchPayments() {
  yield* takeLatest(paymentsFetchRequest, fetchPayments);
}

export function* watchAddNewPayout() {
  yield* takeLatest(addNewPayoutRequest, addNewPayout);
}

export function* watchUpdatePayout() {
  yield* takeLatest(updatePayoutRequest, updatePayout);
}

export function* watchDeletePayout() {
  yield* takeLatest(deletePayoutRequest, deletePayout);
}
