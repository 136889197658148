import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Badge, Center, Divider, Flex, Heading } from '@chakra-ui/react';

import { Filter } from '@/features/filter';

interface IHeaderProps {
  enableFilter?: boolean;
}

// awaiting Design to lock in v1 for P0 before refactoring this code further
export const ContentHeader = ({ enableFilter = true }: IHeaderProps) => {
  const location = useLocation();
  const { t } = useTranslation();
  const isNested =
    location.pathname.includes('customerDeepDive') ||
    location.pathname.includes('payments') ||
    location.pathname.includes('locations') ||
    location.pathname.includes('customReports') ||
    location.pathname.includes('manage');
  const parentPage = t(location.pathname.split('/')[1]);
  const path = t(location.pathname.split('/')[isNested ? 2 : location.pathname.split('/').length - 1]);
  const isSupport = path === 'Support';
  const isSetting = parentPage === 'Settings';
  const isBeta = location.pathname.includes('customReports');

  return (
    <>
      <Flex w='full' justify='space-between' px={{ base: 4, lg: 8 }} flexWrap='wrap' py={4} gap={4}>
        <Flex alignItems={'center'} gap={2}>
          <Heading size='xl' data-testid='contentHeader-heading'>
            {path}
          </Heading>
          {isBeta && (
            <Badge fontSize='16px' borderRadius='full' px='2' bg='neutral.300' data-testid='beta-badge'>
              {t('beta')}
            </Badge>
          )}
        </Flex>

        {enableFilter && !isSupport && !isSetting ? <Filter /> : null}
      </Flex>
      <Center>
        <Divider w='95%' />
      </Center>
    </>
  );
};
