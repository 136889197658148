import * as Sentry from '@sentry/react';

import { axiosInstance } from '@/common/utils';

import { mockNotificationsData } from './mockNotificationsResponse';
import { NotificationPreference, NotificationPreferences } from './types';

export const mockFetchNotifications = () => {
  return new Promise<{ data: NotificationPreferences }>((resolve) =>
    setTimeout(() => resolve({ data: mockNotificationsData }), 500),
  );
};

export const fetchNotifications = async ({ authRequestPath }: { authRequestPath: string }) => {
  try {
    const result = await axiosInstance.post(`/merchant/settings/${authRequestPath}`, {});

    const updatedData: NotificationPreferences = {
      ...result.data,
      notificationPreferences: result.data.notificationPreferences.map((item: NotificationPreference) => {
        return {
          ...item,
          communications: item.communications.filter((item: string) => item !== 'UPCOMING_RELOAD_REMINDER'),
        };
      }),
    };
    return updatedData;
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
    throw error;
  }
};

export const updateNotifications = async ({
  authRequestPath,
  data,
}: {
  authRequestPath: string;
  data: NotificationPreferences;
}) => {
  try {
    const result = await axiosInstance.put(`/merchant/settings/${authRequestPath}`, {
      notificationPreferences: data.notificationPreferences,
    });
    return result.status;
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
    throw error;
  }
};
