import upsideLogo from '@assets/images/upsideLogo.svg';
import { Box, Drawer, DrawerContent, DrawerOverlay, Flex, Image, useDisclosure } from '@chakra-ui/react';

import { SideBar } from './SideBar';
import { ToggleButton } from './ToggleButton';

export const NavBar = ({ hideMainNav }: { hideMainNav?: boolean }) => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <Box width='full' py='4' px={{ base: '4', md: '4' }} bg='bg-accent'>
      <Flex justify='space-between'>
        <Image width='100px' src={upsideLogo} alt='Upside Logo' />

        <ToggleButton isOpen={isOpen} aria-label='Open Menu' onClick={onToggle} />

        <Drawer
          isOpen={isOpen}
          placement='left'
          onClose={onClose}
          isFullHeight
          preserveScrollBarGap
          // Only disabled for showcase
          trapFocus={false}
        >
          <DrawerOverlay />

          <DrawerContent>
            <SideBar hideMainNav={hideMainNav} />
          </DrawerContent>
        </Drawer>
      </Flex>
    </Box>
  );
};
