import { createStandaloneToast } from '@chakra-ui/react';
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { push } from 'redux-first-history';
import { call, put, takeLatest } from 'typed-redux-saga';

import { UserRoles } from '@/common/interfaces';
import i18n from '@/i18n';
import { theme } from '@/theme';

import * as api from './api';
import { parseLocationsSettings } from './dataFormatting';
import {
  addNewLocationRequest,
  addNewLocationSucceeded,
  cleanFuelLocationFormData,
  locationsSettingsFailed,
  locationsSettingsFetchRequest,
  locationsSettingsSucceeded,
  updateLocationFailed,
  updateLocationRequest,
  updateLocationSucceeded,
} from './slice';
import { LocationSettingsData } from './types';

const { toast } = createStandaloneToast({ theme });

/*****************************************************************************/
/******************************* WORKERS *************************************/
/*****************************************************************************/

export function* fetchLocationsSettingsList(
  action: PayloadAction<{
    orgId: string;
    activeVertical: string;
    roles: UserRoles[];
  }>,
) {
  try {
    const result = yield* call(api.fetchLocationsSettings, action.payload);

    const parsedData = parseLocationsSettings(result);

    yield* put(locationsSettingsSucceeded(parsedData));
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield* put(locationsSettingsFailed('Error fetching payments'));
    }
    if (error instanceof Error) {
      yield* put(locationsSettingsFailed(error.message));
    }
  }
}

export function* addNewLocation({
  payload: { orgId, activeVertical, location, roles },
}: PayloadAction<{
  orgId: string;
  activeVertical: string;
  roles: UserRoles[];
  location: LocationSettingsData;
}>) {
  try {
    yield* call(api.addNewLocation, { orgId, activeVertical, location, roles });

    yield* put(addNewLocationSucceeded());
    toast({
      title: i18n.t('locationsSettingsPage.toast.add.success') as string,
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

    yield* put(cleanFuelLocationFormData());

    yield* put(push('/settings/locations'));
  } catch (error) {
    toast({
      title: i18n.t('locationsSettingsPage.toast.add.error') as string,
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
    if (axios.isAxiosError(error)) {
      yield* put(locationsSettingsFailed('Error adding new location'));
    }
    if (error instanceof Error) {
      yield* put(locationsSettingsFailed(error.message));
    }
  }
}

export function* updateLocation({
  payload: { orgId, activeVertical, roles, location, locationId },
}: PayloadAction<{
  orgId: string;
  activeVertical: string;
  roles: UserRoles[];
  location: LocationSettingsData;
  locationId: string;
}>) {
  try {
    yield* call(api.updateLocationSettings, { orgId, activeVertical, location, locationId, roles });

    yield* put(updateLocationSucceeded());
    toast({
      title: i18n.t('locationsSettingsPage.toast.update.success') as string,
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

    yield* put(push('/settings/locations'));
  } catch (error) {
    toast({
      title: i18n.t('locationsSettingsPage.toast.update.error') as string,
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
    if (axios.isAxiosError(error)) {
      yield* put(updateLocationFailed('Error fetching payments'));
    }
    if (error instanceof Error) {
      yield* put(updateLocationFailed(error.message));
    }
  }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchLocationsSettingsList() {
  yield* takeLatest(locationsSettingsFetchRequest, fetchLocationsSettingsList);
}

export function* watchAddNewLocation() {
  yield* takeLatest(addNewLocationRequest, addNewLocation);
}

export function* watchUpdateLocation() {
  yield* takeLatest(updateLocationRequest, updateLocation);
}
