import english from './translations/english.json';
import french from './translations/french.json';
import spanish from './translations/spanish.json';
import { languages, namespaces } from './i18n.constants';

/* English */
export const en = {
  [namespaces.translation]: english,
};

/* Spanish */
export const es = {
  [namespaces.translation]: spanish,
};

/* French */
export const fr = {
  [namespaces.translation]: french,
};

export const resources = {
  [languages.en]: en,
  [languages.es]: es,
  [languages.fr]: fr,
};
