import { useCallback, useEffect } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { format } from 'date-fns';
import _ from 'lodash';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { Card } from '@/common/components/card';
import { DataLoadingErrorPanel, EmptyDataAlertPanel } from '@/common/components/errorAlert';
import { LoadingSpinner } from '@/common/components/loadingSpinner';
import { Pagination, PaginationContainer, PaginationProps } from '@/common/components/pagination';
import { ITableData, Table } from '@/common/components/table';
import { Status, UserRoles, Verticals } from '@/common/interfaces';
import { selectActiveVertical, selectAuthRequestPath, selectUserOrg, selectUserRoles } from '@/features/auth/userSlice';

import { FinancialReportsFilters } from '../components/FinancialReportsFilter';

import {
  selectStatementsEndDate,
  selectStatementsList,
  selectStatementsListStatus,
  selectStatementsPaginationOptions,
  selectStatementsStartDate,
  setStatementsDateRange,
  statementsFetchRequest,
} from './slice';

export const Statements = () => {
  const dispatch = useAppDispatch();
  const statementsData = useAppSelector(selectStatementsList);
  const loadingStatus = useAppSelector(selectStatementsListStatus);
  const orgId = useAppSelector(selectUserOrg);
  const roles = useAppSelector(selectUserRoles);
  const authRequestPath = useAppSelector(selectAuthRequestPath);
  const activeVertical = useAppSelector(selectActiveVertical);
  const startStatementsDate = useAppSelector(selectStatementsStartDate);
  const endStatementsDate = useAppSelector(selectStatementsEndDate);
  const statementsPagination = useAppSelector(selectStatementsPaginationOptions);

  const handleDateDropdownChange = (dates: Date[]) => {
    const [start, end] = dates;
    const startDate = format(start, 'yyyy-MM-dd');
    const endDate = format(end, 'yyyy-MM-dd');
    dispatch(setStatementsDateRange({ startDate, endDate }));
  };

  const fetchStatementsList = useCallback(
    ({
      orgId,
      roles,
      authRequestPath,
      activeVertical,
      startDate,
      endDate,
    }: {
      orgId: string;
      roles: UserRoles[];
      authRequestPath: string;
      activeVertical: Verticals;
      startDate: string;
      endDate: string;
    }) => {
      if (
        orgId === '' ||
        _.isEmpty(roles) ||
        activeVertical === Verticals.Unknown ||
        _.isEmpty(startDate) ||
        _.isEmpty(endDate)
      ) {
        return;
      }

      const payload = {
        authRequestPath,
        startDate,
        endDate,
        activeVertical,
        roles,
      };
      dispatch(statementsFetchRequest(payload));
    },
    [dispatch],
  );

  useEffect(() => {
    if (orgId !== '' && !_.isEmpty(roles) && startStatementsDate && endStatementsDate) {
      fetchStatementsList({
        orgId,
        roles,
        authRequestPath,
        activeVertical,
        startDate: startStatementsDate.split('T')[0],
        endDate: endStatementsDate.split('T')[0],
      });
    }
  }, [orgId, roles, fetchStatementsList, authRequestPath, activeVertical, startStatementsDate, endStatementsDate]);

  return (
    <Box padding={4}>
      {loadingStatus === Status.Failed && <DataLoadingErrorPanel />}
      {loadingStatus !== Status.Failed && (
        <Card variant='borderless' loadingStatus={loadingStatus}>
          <Flex justifyContent={'flex-end'}>
            <FinancialReportsFilters
              startDate={startStatementsDate}
              endDate={endStatementsDate}
              handleChangeDate={handleDateDropdownChange}
            />
          </Flex>

          {loadingStatus === Status.Loading && (
            <Box position='relative' w={'100%'} h={400}>
              <Flex
                position='absolute'
                left={0}
                top={0}
                right={0}
                bottom={0}
                bgColor='rgba(255, 255, 255, 0.75)'
                justifyContent='center'
                alignItems='center'
              >
                <LoadingSpinner />
              </Flex>
            </Box>
          )}

          {loadingStatus !== Status.Loading && (
            <PaginationContainer data={statementsData.table?.rows || []} options={statementsPagination}>
              {(props: PaginationProps) => {
                const { data } = props;
                const tableData: ITableData = Object.assign({}, statementsData?.table, {
                  rows: data,
                });

                if (data.length > 0) {
                  return (
                    <>
                      <Table data={tableData} />
                      <Pagination {...props} />
                    </>
                  );
                } else {
                  return <EmptyDataAlertPanel />;
                }
              }}
            </PaginationContainer>
          )}
        </Card>
      )}
    </Box>
  );
};
