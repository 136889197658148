import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Button, Flex, Table as ChakraTable, TableContainer, Tbody, Td, Text, Tr } from '@chakra-ui/react';

import { useAppSelector } from '@/app/hooks';
import { DataLoadingErrorPanel } from '@/common/components/errorAlert';
import { LoadingSpinner } from '@/common/components/loadingSpinner';
import { Section } from '@/common/components/section';
import { useFetchPayoutsMethods } from '@/common/hooks/fetchDashboardData';
import { Status } from '@/common/interfaces';

import { PayoutTableCell } from '../components/PayoutTableCell';

import { selectPayoutLoadingStatus, selectPayoutMethods } from './slice';

export const PayoutMethod = () => {
  useFetchPayoutsMethods();
  const { t } = useTranslation();
  const payoutMethods = useAppSelector(selectPayoutMethods);
  const loadingStatus = useAppSelector(selectPayoutLoadingStatus);

  const unverified = useMemo(() => {
    if (!payoutMethods) return false;
    const result = payoutMethods.filter((payoutMethod: { verified: boolean }) => payoutMethod.verified === false);
    return result.length >= 1 ? true : false;
  }, [payoutMethods]);

  const displaySubHeader = () => {
    if (unverified) {
      return <Text>{t('payment.payoutMethod.nonVerifiedAccount.description')}</Text>;
    }
    if (payoutMethods.length) {
      return <Text>{t('payment.payoutMethod.hasAccounts.info')}</Text>;
    } else {
      return <Text>{t('payment.payoutMethod.info')}</Text>;
    }
  };

  return (
    <Box>
      <Section name={t('payment.payoutMethod.title')}>
        <Box>
          {loadingStatus === Status.Loading && (
            <Flex alignItems='center' justifyContent='center' mt={10}>
              <LoadingSpinner />
            </Flex>
          )}

          {loadingStatus === Status.Failed && (
            <Flex alignItems='center' justifyContent='center'>
              <DataLoadingErrorPanel />
            </Flex>
          )}

          {loadingStatus === Status.Idle && (
            <>
              <Box mt={3}>{displaySubHeader()}</Box>

              {!!payoutMethods.length && (
                <Flex justifyContent={'end'} mt={4}>
                  <Button
                    as={Link}
                    to='payout/add-bank-account'
                    variant='accentTertiary'
                    p={0}
                    data-testid='add-payout-btn'
                  >
                    {t('payment.payoutMethod.hasAccounts.addBankBtn')}
                  </Button>
                </Flex>
              )}

              <TableContainer width={'100%'} overflowX={'hidden'} mt={2}>
                <ChakraTable variant='striped'>
                  <Tbody>
                    {!payoutMethods.length ? (
                      <Tr>
                        <Td w={'30%'}>{t('payment.payoutMethod.noAccounts.info')}</Td>
                        <Td textAlign={'right'} w={'70%'}>
                          <Button
                            as={Link}
                            to='payout/add-bank-account'
                            size='md'
                            variant='brandPrimary'
                            data-testid='add-payout-btn'
                          >
                            {t('payment.payoutMethod.noAccounts.addPayout')}
                          </Button>
                        </Td>
                      </Tr>
                    ) : (
                      <>
                        {payoutMethods.map((payoutMethod) => {
                          return (
                            <PayoutTableCell
                              key={payoutMethod.uuid}
                              method={payoutMethod}
                              methodsLength={payoutMethods.length}
                              sectionName='payout'
                            />
                          );
                        })}
                      </>
                    )}
                  </Tbody>
                </ChakraTable>
              </TableContainer>
            </>
          )}
        </Box>
      </Section>
    </Box>
  );
};
