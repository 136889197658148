import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaDollarSign } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Radio,
  RadioGroup,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { merge } from 'lodash';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { ExpansionPanel } from '@/common/components/expansionPanel';
import { CustomFormLabel } from '@/common/components/form';
import { NEIGHBOR_STATES, OPIS_RACK_MAPPING } from '@/features/settings/locations/constants';
import { selectFuelLocationFormData, updateFuelLocationFormData } from '@/features/settings/locations/slice';
import {
  AddFuelLocationFixedMarginsFormData,
  AddFuelLocationOpisRackMarginsInputFormData,
  AddFuelLocationOpisRackMarginsOutputFormData,
  LocationSettingsData,
} from '@/features/settings/locations/types';
import {
  addFuelLocationFixedMarginsSchema,
  addFuelLocationOpisRackMarginsSchema,
} from '@/features/settings/locations/validators';

export function MarginsForm() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const fuelLocationFormData = useAppSelector(selectFuelLocationFormData);

  const [agreementType, setAgreementType] = useState<'opisRack' | 'tankWagon'>(() => {
    if (
      !fuelLocationFormData?.additionalProperties?.opisRackTerminal?.locality &&
      fuelLocationFormData?.additionalProperties.margins?.REGULAR
    )
      return 'tankWagon';

    return 'opisRack';
  });

  const handleChangeAgreementType = (type: 'opisRack' | 'tankWagon') => {
    setAgreementType(type);

    if (type === 'tankWagon') {
      const updatedData = JSON.parse(JSON.stringify(fuelLocationFormData));

      if (updatedData?.additionalProperties?.opisRackTerminal) {
        updatedData.additionalProperties.opisRackTerminal = undefined;
      }

      if (updatedData?.settings?.marginSettings) {
        updatedData.settings.marginSettings = undefined;
      }

      dispatch(updateFuelLocationFormData(updatedData as LocationSettingsData));
    }

    if (type === 'opisRack') {
      const updatedData = JSON.parse(JSON.stringify(fuelLocationFormData));

      if (updatedData?.additionalProperties?.margins) {
        updatedData.additionalProperties.margins = undefined;
      }
      dispatch(updateFuelLocationFormData(updatedData as LocationSettingsData));
    }
  };

  return (
    <Flex gap={2} direction={['column', 'row']}>
      <Box w={['100%', '75%']} mt='8'>
        <Heading as='h2' size='lg' mb={4}>
          {t('locationsSettingsPage.fuelForm.titles.margins')}
        </Heading>

        <Heading as='h3' size='sm' mb={4}>
          {t('locationsSettingsPage.fuelForm.fields.margins.supplyAgreement.title')}
        </Heading>

        <Text>
          {t('locationsSettingsPage.fuelForm.forSite', {
            siteName: String(fuelLocationFormData?.name),
            siteAddress: fuelLocationFormData?.address.address1,
          })}
        </Text>

        <Box mt='4'>
          <RadioGroup
            my={5}
            defaultValue={String(agreementType)}
            onChange={(type) => handleChangeAgreementType(type as 'tankWagon' | 'opisRack')}
          >
            <VStack alignItems='flex-start'>
              <Radio color='neutral.900' value='opisRack'>
                <Text>{t('locationsSettingsPage.fuelForm.fields.margins.supplyAgreement.options.opisRack')}</Text>
              </Radio>
              <Radio color='neutral.900' value='tankWagon'>
                <Text>{t('locationsSettingsPage.fuelForm.fields.margins.supplyAgreement.options.tankWagon')}</Text>
              </Radio>
            </VStack>
          </RadioGroup>
        </Box>

        {agreementType === 'opisRack' && <OpisRackForm />}

        {agreementType === 'tankWagon' && <TankWagonForm />}
      </Box>
      <Box w={['100%', '25%']}>
        <ExpansionPanel header={t('locationsSettingsPage.fuelForm.fields.margins.helper.title')}>
          <Text>{t('locationsSettingsPage.fuelForm.fields.margins.helper.content')}</Text>
        </ExpansionPanel>
      </Box>
    </Flex>
  );
}

function OpisRackForm() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const fuelLocationFormData = useAppSelector(selectFuelLocationFormData);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<AddFuelLocationOpisRackMarginsInputFormData>({
    resolver: zodResolver(addFuelLocationOpisRackMarginsSchema),
    defaultValues: {
      ...fuelLocationFormData,
      additionalProperties: {
        ...fuelLocationFormData?.additionalProperties,
        opisRackTerminal: JSON.stringify(fuelLocationFormData?.additionalProperties?.opisRackTerminal),
      },
    },
  });

  const onSubmit = (data: unknown) => {
    const parsedData = data as AddFuelLocationOpisRackMarginsOutputFormData;
    const updatedData = merge({}, fuelLocationFormData, parsedData);
    dispatch(updateFuelLocationFormData(updatedData));

    navigate('/settings/locations/new/3');
  };

  const terminals = useMemo(() => {
    const state = fuelLocationFormData?.address.region;

    if (!state) return [];

    const neighborStates = NEIGHBOR_STATES[state as keyof typeof NEIGHBOR_STATES];

    if (neighborStates) {
      const searchStates = [...neighborStates, state];

      return OPIS_RACK_MAPPING.filter((terminal) => searchStates.includes(terminal.region))
        .map((terminal) => terminal.localities)
        .flat();
    }

    return OPIS_RACK_MAPPING.filter((terminal) => [state].includes(terminal.region))
      .map((terminal) => terminal.localities)
      .flat();
  }, [fuelLocationFormData?.address.region]);

  return (
    <FormControl as='form' onSubmit={handleSubmit(onSubmit)}>
      <Text fontWeight='bold'>{t('locationsSettingsPage.fuelForm.fields.margins.additionalCostsTitle')}</Text>
      <Text>{t('locationsSettingsPage.fuelForm.fields.margins.additionalCostsHelp')}</Text>
      <CustomFormLabel htmlFor='additionalCosts' mt='4'>
        <InputGroup borderWidth='thin' borderRadius='lg' borderColor='border.default' maxW='250px'>
          <InputLeftAddon bg='none' border='none' pr={0}>
            <FaDollarSign />
          </InputLeftAddon>
          <Input
            id='additionalCosts'
            type='number'
            border='none'
            placeholder='0.01'
            step='0.001'
            min='0'
            max='0.15'
            required
            {...register('settings.marginSettings.outsideGasFee.amount', { required: true })}
          />
        </InputGroup>
      </CustomFormLabel>
      {errors.settings?.marginSettings?.outsideGasFee?.amount && (
        <Text variant='error'>{errors.settings?.marginSettings?.outsideGasFee?.amount.message}</Text>
      )}

      <Text fontWeight='bold' mt='6'>
        {t('locationsSettingsPage.fuelForm.fields.margins.opisRackTitle')}
      </Text>
      <Text>{t('locationsSettingsPage.fuelForm.fields.margins.opisRackHelp')}</Text>

      <CustomFormLabel htmlFor='opisRackTerminal' mt='4'>
        <Select
          id='opisRackTerminal'
          placeholder='Select a terminal'
          mt={2}
          maxW='250px'
          required
          {...register('additionalProperties.opisRackTerminal', { required: true })}
        >
          {terminals.map((terminal) => (
            <option key={terminal.locality} value={JSON.stringify(terminal)}>
              {terminal.locality}
            </option>
          ))}
        </Select>
      </CustomFormLabel>
      {errors.additionalProperties?.opisRackTerminal && (
        <Text variant='error'>{errors.additionalProperties?.opisRackTerminal.message}</Text>
      )}

      <Button type='submit' variant='brandPrimary' size='md' px={8}>
        {t('locationsSettingsPage.fuelForm.actions.continue')}
      </Button>
    </FormControl>
  );
}

function TankWagonForm() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const fuelLocationFormData = useAppSelector(selectFuelLocationFormData);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<AddFuelLocationFixedMarginsFormData>({
    resolver: zodResolver(addFuelLocationFixedMarginsSchema),
    defaultValues: {
      ...fuelLocationFormData,
    },
  });

  const onSubmit = useCallback(
    (data: AddFuelLocationFixedMarginsFormData) => {
      const updatedData = merge({}, fuelLocationFormData, data);
      dispatch(updateFuelLocationFormData(updatedData));

      navigate('/settings/locations/new/3');
    },
    [dispatch, navigate, fuelLocationFormData],
  );

  return (
    <FormControl as='form' onSubmit={handleSubmit(onSubmit)}>
      <Text fontWeight='bold'>{t('locationsSettingsPage.fuelForm.fields.margins.tankWagonTitle')}</Text>
      <Flex mt={5} mb={8} gap={1}>
        <Box>
          <CustomFormLabel htmlFor='regularGrade'>
            {t('locationsSettingsPage.fuelForm.fields.margins.grades.regular')}
            <InputGroup borderWidth='thin' borderRadius='lg' borderColor='border.default' maxW='250px' mt={2}>
              <InputLeftAddon bg='none' border='none' pr={0}>
                <FaDollarSign />
              </InputLeftAddon>

              <Input
                type='number'
                id='regularGrade'
                border='none'
                placeholder='0.01'
                step='0.001'
                min='0'
                required
                {...register('additionalProperties.margins.REGULAR.amount', { required: true })}
                isInvalid={
                  errors.additionalProperties?.margins?.REGULAR &&
                  errors.additionalProperties?.margins?.REGULAR.message !== ''
                }
              />
            </InputGroup>
          </CustomFormLabel>
          {errors.additionalProperties?.margins?.REGULAR && (
            <Text variant='error'>{errors.additionalProperties?.margins?.REGULAR.message}</Text>
          )}
        </Box>
        <Box>
          <CustomFormLabel htmlFor='midGrade'>
            {t('locationsSettingsPage.fuelForm.fields.margins.grades.midgrade')}
            <InputGroup borderWidth='thin' borderRadius='lg' borderColor='border.default' maxW='250px' mt={2}>
              <InputLeftAddon bg='none' border='none' pr={0}>
                <FaDollarSign />
              </InputLeftAddon>

              <Input
                type='number'
                id='midGrade'
                border='none'
                placeholder='0.01'
                step='0.001'
                min='0'
                required
                {...register('additionalProperties.margins.MIDGRADE.amount', { required: true })}
                isInvalid={
                  errors.additionalProperties?.margins?.MIDGRADE &&
                  errors.additionalProperties?.margins?.MIDGRADE.message !== ''
                }
              />
            </InputGroup>
          </CustomFormLabel>
          {errors.additionalProperties?.margins?.MIDGRADE && (
            <Text variant='error'>{errors.additionalProperties?.margins?.MIDGRADE.message}</Text>
          )}
        </Box>
        <Box>
          <CustomFormLabel htmlFor='premiumGrade'>
            {t('locationsSettingsPage.fuelForm.fields.margins.grades.premium')}
            <InputGroup borderWidth='thin' borderRadius='lg' borderColor='border.default' maxW='250px' mt={2}>
              <InputLeftAddon bg='none' border='none' pr={0}>
                <FaDollarSign />
              </InputLeftAddon>

              <Input
                type='number'
                id='premiumGrade'
                border='none'
                placeholder='0.01'
                step='0.001'
                min='0'
                required
                {...register('additionalProperties.margins.PREMIUM.amount', { required: true })}
                isInvalid={
                  errors.additionalProperties?.margins?.PREMIUM &&
                  errors.additionalProperties?.margins?.PREMIUM.message !== ''
                }
              />
            </InputGroup>
          </CustomFormLabel>
          {errors.additionalProperties?.margins?.PREMIUM && (
            <Text variant='error'>{errors.additionalProperties?.margins?.PREMIUM.message}</Text>
          )}
        </Box>
        <Box>
          <CustomFormLabel htmlFor='dieselGrade'>
            {t('locationsSettingsPage.fuelForm.fields.margins.grades.diesel')}
            <InputGroup borderWidth='thin' borderRadius='lg' borderColor='border.default' maxW='250px' mt={2}>
              <InputLeftAddon bg='none' border='none' pr={0}>
                <FaDollarSign />
              </InputLeftAddon>

              <Input
                type='number'
                id='diesel'
                border='none'
                placeholder='0.01'
                step='0.001'
                min='0'
                required
                {...register('additionalProperties.margins.DIESEL.amount', { required: true })}
                isInvalid={
                  errors.additionalProperties?.margins?.DIESEL &&
                  errors.additionalProperties?.margins?.DIESEL.message !== ''
                }
              />
            </InputGroup>
          </CustomFormLabel>
          {errors.additionalProperties?.margins?.DIESEL && (
            <Text variant='error'>{errors.additionalProperties?.margins?.DIESEL.message}</Text>
          )}
        </Box>
      </Flex>
      <Button type='submit' variant='brandPrimary' size='md' px={8}>
        {t('locationsSettingsPage.fuelForm.actions.continue')}
      </Button>
    </FormControl>
  );
}
