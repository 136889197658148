/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, takeLatest } from 'typed-redux-saga';

import * as api from './api';
import {
  buildCustomerSegmentationSummary,
  buildCustomersTable,
  buildCustomerSummary,
  buildCustomerTransactionsTable,
  buildRecentTransactionsTable,
} from './dataFormatting';
import {
  allCustomersSummaryFailed,
  allCustomersSummaryFetch,
  allCustomersSummarySucceeded,
  customerSegmentationSummaryFailed,
  customerSegmentationSummaryFetch,
  customerSegmentationSummarySucceeded,
  customersFailed,
  customersFetch,
  customersSucceeded,
  customerSummaryFailed,
  customerSummaryFetch,
  customerSummarySucceeded,
  customerTransactionsFailed,
  customerTransactionsFetch,
  customerTransactionsSucceeded,
  recentTransactionsListFailed,
  recentTransactionsListFetch,
  recentTransactionsListSucceeded,
} from './slice';
import { IAllCustomersSummary, IRefinedCustomersList, IRefinedCustomerTransactions } from './types';

/*****************************************************************************/
/******************************* WORKERS *************************************/
/*****************************************************************************/
export function* fetchCustomers(action: any) {
  try {
    const result = yield* call(api.fetchCustomers, action.payload);
    const table = buildCustomersTable(result, action.payload.activeVertical);
    const data: IRefinedCustomersList = {
      table,
      prevPage: result.prev_page,
      nextPage: result.next_page,
      count: result.count,
    };
    yield* put(customersSucceeded(data));
  } catch (error: any) {
    yield* put(customersFailed(error.message));
  }
}

export function* fetchRecentTransactionsList(action: any) {
  try {
    const result = yield* call(api.fetchRecentTransactionsList, action.payload);
    const table = buildRecentTransactionsTable(result, action.payload.activeVertical);
    const data: IRefinedCustomerTransactions = {
      table,
      prevPage: result.prev_page,
      nextPage: result.next_page,
      count: result.count,
    };
    yield* put(recentTransactionsListSucceeded(data));
  } catch (error: any) {
    yield* put(recentTransactionsListFailed(error.message));
  }
}

export function* fetchAllCustomersSummary(action: any) {
  try {
    const result = yield* call(api.fetchAllCustomersSummary, action.payload);
    const summary: IAllCustomersSummary = {
      totalUpsideCustomers: parseInt(result.total_customers),
      totalUpsideCustomerTxns: parseInt(result.total_transactions),
    };
    yield* put(allCustomersSummarySucceeded(summary));
  } catch (error: any) {
    yield* put(allCustomersSummaryFailed(error.message));
  }
}

export function* fetchCustomerSummary(action: any) {
  try {
    const result = yield* call(api.fetchCustomerSummary, action.payload);
    const summary = buildCustomerSummary(result, action.payload.activeVertical, action.payload.transactionType);
    yield* put(customerSummarySucceeded(summary));
  } catch (error: any) {
    yield* put(customerSummaryFailed(error.message));
  }
}

export function* fetchCustomerTransactions(action: any) {
  try {
    const result = yield* call(api.fetchCustomerTransactions, action.payload);
    const table = buildCustomerTransactionsTable(result, action.payload.activeVertical, action.payload.transactionType);

    const data: IRefinedCustomerTransactions = {
      table,
      prevPage: result.prev_page,
      nextPage: result.next_page,
      count: result.count,
    };
    yield* put(customerTransactionsSucceeded(data));
  } catch (error: any) {
    yield* put(customerTransactionsFailed(error.message));
  }
}

export function* fetchCustomerSegmentationSummary(action: any) {
  try {
    const result = yield* call(api.fetchCustomerSegmentationSummary, action.payload);
    const summary = buildCustomerSegmentationSummary(
      result,
      action.payload.temporalUnit,
      action.payload.activeVertical,
    );
    yield* put(customerSegmentationSummarySucceeded(summary));
  } catch (error: any) {
    yield* put(customerSegmentationSummaryFailed(error.message));
  }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/
export function* watchCustomers() {
  yield* takeLatest(customersFetch, fetchCustomers);
  yield* takeLatest(recentTransactionsListFetch, fetchRecentTransactionsList);
  yield* takeLatest(customerSummaryFetch, fetchCustomerSummary);
  yield* takeLatest(allCustomersSummaryFetch, fetchAllCustomersSummary);
  yield* takeLatest(customerTransactionsFetch, fetchCustomerTransactions);
  yield* takeLatest(customerSegmentationSummaryFetch, fetchCustomerSegmentationSummary);
}
