import { FormLabel } from '@chakra-ui/react';

import { IFormLabelProps } from './types';

export const CustomFormLabel = ({ isInvalid, ...props }: IFormLabelProps) => {
  return (
    <FormLabel
      textStyle='heading.sm'
      fontFamily='heading'
      color={(isInvalid && 'text.danger.default') || 'text.default'}
      _disabled={{
        color: 'text.disabled',
      }}
      {...props}
    />
  );
};
