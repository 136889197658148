import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Circle, Flex, Grid, GridItem, Text, useToken } from '@chakra-ui/react';
import { CartesianMarkerProps } from '@nivo/core';
import { LineSvgProps, PointTooltipProps, ResponsiveLine } from '@nivo/line';
import _ from 'lodash';

import { useAppSelector } from '@/app/hooks';
import { ChartDropdownMenu } from '@/common/components/chartdropdownMenu';
import { Status } from '@/common/interfaces';
import { display2Decimals, displayCurrency } from '@/common/utils';
import { getAmountOfTicksToRender } from '@/common/utils/utils';
import { selectBehaviorWalkthrough } from '@/features/dashboard/slice';

import { IncrementalImpactChartProps, IncrementalImpactTick } from './types';

export const TOTAL_CUSTOMER_SALES_SERIES_ID = 'totalCustomerSales';
export const TOTAL_CONTROL_SALES_SERIES_ID = 'totalControlSales';

export const IncrementalImpactGraph = ({
  data,
  loadingStatus,
  dataType,
  dateFormat,
  dropdownMenuProps,
}: IncrementalImpactChartProps) => {
  const { t } = useTranslation();
  const [dataBlue, dataGreen, dataNeutral] = useToken('colors', ['blue.800', 'green.400', 'neutral.800']);

  const isLoading = loadingStatus === Status.Loading;
  const LEFT_LEGEND_WIDTH = 110;
  const TICK_THRESHOLD_MIN = -12;
  const chartRef = useRef<HTMLDivElement>(null);

  const behaviorWalkthroughState = useAppSelector(selectBehaviorWalkthrough);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [markerOffset, setMarkerOffset] = useState(0);

  useEffect(() => {
    const getDimensions = () => ({
      width: chartRef.current ? chartRef.current.offsetWidth : 0,
      height: chartRef.current ? chartRef.current.offsetHeight : 0,
    });

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (chartRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize-incremental-graph', handleResize);

    return () => {
      window.removeEventListener('resize-incremental-graph', handleResize);
    };
  }, [chartRef, data]);

  useEffect(() => {
    if (data.length) {
      const getMarkerOffset = () => (dimensions.width - LEFT_LEGEND_WIDTH) / data[0].data.length / 1.625;
      setMarkerOffset(getMarkerOffset());
    }
  }, [data, dimensions]);

  const marker: CartesianMarkerProps = useMemo(
    () => ({
      axis: 'x',
      value: -1,
      lineStyle: { stroke: dataBlue, strokeWidth: 2 },
    }),
    [dataBlue],
  );

  const commonProperties: LineSvgProps = useMemo(
    () =>
      _.defaultsDeep(
        { data },
        {
          margin: { top: 40, right: 20, bottom: 24, left: 30 },
          gridYValues: 4,
          xScale: { type: 'point' },
          yScale: {
            type: 'linear',
            stacked: false,
            reverse: false,
          },
          yFormat: ' >-.2f',
          curve: 'stepBefore',
          axisTop: null,
          axisRight: null,
          axisBottom: {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendOffset: 46,
            legendPosition: 'middle',
            renderTick: (tick: IncrementalImpactTick) => {
              if (Number(tick.x) >= TICK_THRESHOLD_MIN) {
                const dataLength = data[0].data.length;

                const DIVIDE_VALUE = getAmountOfTicksToRender(dataLength);
                const shouldRender = tick.tickIndex % DIVIDE_VALUE <= 0;

                if (dataLength < 12 || (dataLength > 12 && shouldRender)) {
                  const values: { [key: string]: string | number } = _.reduce(
                    data,
                    (accum, entry) => {
                      const column = entry.data.find((dataEntry) => Number(dataEntry.x) === tick.value);
                      accum[entry.id] = column ? column.key : 0;
                      return accum;
                    },
                    {} as { [key: string]: string | number },
                  );

                  return (
                    <g transform={`translate(${tick.x - markerOffset},${tick.y + 15})`}>
                      <text
                        x={0}
                        y={0}
                        textAnchor='middle'
                        dominantBaseline='middle'
                        style={{
                          fill: 'rgb(51, 51, 51)',
                          fontSize: 12,
                          fontFamily: 'sans-serif',
                        }}
                      >
                        {values[`${dateFormat}Customer`]}
                      </text>
                    </g>
                  );
                }
              }

              return null;
            },
          },
          areaOpacity: 0.6,
          axisLeft: {
            tickValues: 4,
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendOffset: -80,
            legendPosition: 'middle',
          },
          enableArea: true,
          useMesh: true,
          enableGridX: false,
          areaBaselineValue: 0,
          markers: [marker],
          layers: ['grid', 'crosshair', 'axes', 'areas', 'slices', 'mesh', 'markers', 'legends'],
          colors: [dataGreen, dataNeutral],

          tooltip: (point: PointTooltipProps) => {
            const x: number =
              Number(point.point.data.x) >= TICK_THRESHOLD_MIN ? Number(point.point.data.x) : TICK_THRESHOLD_MIN;

            const values: { [key: string]: string | number } = _.reduce(
              data,
              (accum, entry) => {
                const column = entry.data.find((dataEntry) => Number(dataEntry.x) === x);
                accum[entry.id] = column ? column.y : 0;
                return accum;
              },
              {} as { [key: string]: string | number },
            );

            const keyValue: { [key: string]: string | number } = _.reduce(
              data,
              (accum, entry) => {
                const column = entry.data.find((dataEntry) => Number(dataEntry.x) === x);
                accum[entry.id] = column ? column.key : 0;
                return accum;
              },
              {} as { [key: string]: string | number },
            );

            const avgUpside =
              dataType === 'sales'
                ? displayCurrency(values[`${dateFormat}Customer`], 0)
                : display2Decimals(values[`${dateFormat}Customer`], 2);

            const avgSimilar =
              dataType === 'sales'
                ? displayCurrency(values[`${dateFormat}Control`], 0)
                : display2Decimals(values[`${dateFormat}Control`], 2);

            return (
              behaviorWalkthroughState &&
              behaviorWalkthroughState.step === 0 && (
                <Grid
                  templateColumns='1fr auto'
                  rowGap={1}
                  columnGap={2}
                  bgColor='white'
                  shadow='base'
                  borderRadius={10}
                  padding={2}
                  marginRight={150}
                >
                  <GridItem>{t(`behaviorPage.incrementalImpactGraph.graph.tooltip.${dataType}.month`)}</GridItem>
                  <GridItem>{keyValue[`${dateFormat}Control`]}</GridItem>
                  <GridItem>{t(`behaviorPage.incrementalImpactGraph.graph.tooltip.${dataType}.avgUpside`)}</GridItem>
                  <GridItem>{avgUpside}</GridItem>
                  <GridItem>{t(`behaviorPage.incrementalImpactGraph.graph.tooltip.${dataType}.avgSimilar`)}</GridItem>
                  <GridItem>{avgSimilar}</GridItem>
                </Grid>
              )
            );
          },
        },
      ),
    [
      data,
      TICK_THRESHOLD_MIN,
      marker,
      markerOffset,
      dataType,
      dateFormat,
      t,
      behaviorWalkthroughState,
      dataGreen,
      dataNeutral,
    ],
  );

  if (commonProperties.axisLeft?.format === 'currency') {
    commonProperties.axisLeft.format = displayCurrency;
  }
  if (commonProperties.axisBottom?.format === 'currency') {
    commonProperties.axisBottom.format = displayCurrency;
  }

  if (behaviorWalkthroughState && (behaviorWalkthroughState.step <= 9 || behaviorWalkthroughState.step === 13)) {
    commonProperties.colors = [dataGreen, dataNeutral, dataGreen, dataNeutral];
  }

  if (behaviorWalkthroughState && behaviorWalkthroughState.step === 10) {
    commonProperties.colors = [dataGreen, dataNeutral, 'rgba(61, 184, 136, 0.15)', 'rgba(115, 115, 116, 0.15)'];
  } else if (behaviorWalkthroughState && behaviorWalkthroughState.step === 11) {
    commonProperties.colors = ['rgba(61, 184, 136, 0.15)', 'rgba(115, 115, 116, 0.15)', dataGreen, dataNeutral];
  } else if (behaviorWalkthroughState && behaviorWalkthroughState.step === 12) {
    commonProperties.colors = [
      'rgba(61, 184, 136, 0.15)',
      'rgba(115, 115, 116, 0.15)',
      dataGreen,
      'rgba(115, 115, 116, 0.15)',
    ];
  }

  return (
    <>
      {!isLoading && !!data.length && (
        <Box px={3} pt={3}>
          <Flex justifyContent='flex-end' gap={3}>
            {dropdownMenuProps &&
              !!dropdownMenuProps.length &&
              dropdownMenuProps.map((props) => <ChartDropdownMenu key={props.selectedItem} {...props} />)}
          </Flex>

          <Box h={'400px'} width='100%'>
            <ResponsiveLine {...commonProperties} />
          </Box>
          <Flex w='100%'>
            <Flex mr={'40px'} alignSelf={'center'}>
              <Circle size='12px' bg='data.green' alignSelf={'center'} mr={'8px'} />
              <Text fontSize={'11px'}>{t('behaviorPage.incrementalImpactGraph.legend.upsideCustomer')}</Text>
            </Flex>
            <Flex alignSelf={'center'} mr={'40px'}>
              <Circle size='12px' bg='data.neutral' alignSelf={'center'} mr={'8px'} />
              <Text fontSize={'11px'}>{t('behaviorPage.incrementalImpactGraph.legend.similarCustomer')}</Text>
            </Flex>
            <Flex alignSelf={'center'}>
              <Circle size='12px' bg='data.blue' alignSelf={'center'} mr={'8px'} />
              <Text fontSize={'11px'}>{t('behaviorPage.incrementalImpactGraph.legend.firstUpsideTrxn')}</Text>
            </Flex>
          </Flex>
        </Box>
      )}
    </>
  );
};
