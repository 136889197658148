import * as Sentry from '@sentry/react';

import { UserRoles } from '@/common/interfaces';
import { axiosInstance } from '@/common/utils';
import { checkStatementsInvoicesPermissions } from '@/features/auth/permissions';

export const fetchStatements = async ({ authRequestPath, roles }: { authRequestPath: string; roles: UserRoles[] }) => {
  checkStatementsInvoicesPermissions(roles, 'read');
  try {
    const result = await axiosInstance.post(`/billing/statement/${authRequestPath}`, {});

    return result.data;
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
    throw error;
  }
};

export const downloadStatement = async ({
  period,
  format,
  authRequestPath,
  roles,
}: {
  period: string;
  format: string;
  authRequestPath: string;
  roles: UserRoles[];
}) => {
  checkStatementsInvoicesPermissions(roles, 'read');

  try {
    const response = await axiosInstance.post(
      `/billing/statement/${period.replace('-', '/')}/${format}/${authRequestPath}`,
      {},
    );

    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
    throw error;
  }
};
