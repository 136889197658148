/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStandaloneToast } from '@chakra-ui/react';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { push } from 'redux-first-history';
import { call, put, takeLatest } from 'typed-redux-saga';

import i18n from '@/i18n';
import { theme } from '@/theme';

import * as api from './api';
import {
  resetRegistrationForm,
  submitRegistrationFailure,
  submitRegistrationRequest,
  submitRegistrationSuccess,
} from './slice';
import { RegistrationFormData } from './types';

const { toast } = createStandaloneToast({ theme });

/*****************************************************************************/
/******************************* WORKERS *************************************/
/*****************************************************************************/

export function* registrationRequest({ payload: { formData } }: PayloadAction<{ formData: RegistrationFormData }>) {
  try {
    yield* call(api.submitRegistration, { formData });

    yield* put(submitRegistrationSuccess());
    toast({
      title: i18n.t('registrationPage.toast.successTitle') as string,
      description: i18n.t('registrationPage.toast.successDesc') as string,
      status: 'success',
      duration: 7000,
      isClosable: true,
    });

    yield* put(resetRegistrationForm());

    yield* put(push('/login'));
  } catch (error: any) {
    toast({
      title: i18n.t('registrationPage.toast.failureTitle') as string,
      description: error.response.data.message || (i18n.t('registrationPage.toast.failureDesc') as string),
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

    const axiosError = error as AxiosError;
    yield* put(submitRegistrationFailure(axiosError.message));
  }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchRegistration() {
  yield* takeLatest(submitRegistrationRequest, registrationRequest);
}
