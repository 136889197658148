import { Box, BoxProps, SimpleGrid } from '@chakra-ui/react';

import { Status } from '../../interfaces';
import { IStat, Stat } from '../stat';

export interface IStats extends BoxProps {
  status?: Status;
  stats?: Array<IStat> | null;
}

export const Stats = ({ stats, ...boxProps }: IStats) => {
  const calculateStatColumns = () => {
    if (stats) {
      return { base: 1, md: 2, lg: stats.length };
    } else {
      return { base: 1, md: 2, lg: 4 };
    }
  };

  return (
    <Box as='section' py={{ base: '4', md: '8' }} {...boxProps}>
      <SimpleGrid columns={calculateStatColumns()} gap={{ base: '2', md: '4' }} data-testid='stats-grid'>
        {stats ? stats.map((stat: IStat, index) => <Stat key={index} {...stat} />) : null}
      </SimpleGrid>
    </Box>
  );
};
