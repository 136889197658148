import { useAppSelector } from '@app/hooks';

import { ManagerRoles } from '@/common/interfaces';
import {
  BillingManager,
  GreenGreyAccess,
  LocationsSettingsManager,
  LocationsSettingsReadOnly,
  PaymentsManager,
  PayoutManager,
  PayoutsReadOnly,
  StatementsInvoicesReadOnly,
  TransactionsExporter,
} from '@/features/auth/permissions';
import { selectUserRoles } from '@/features/auth/userSlice';

export const usePermissions = () => {
  const userRoles = useAppSelector(selectUserRoles);

  return {
    hasBillingManagerAccess: userRoles.includes(BillingManager),
    hasPayoutManagerAccess: userRoles.includes(PayoutManager),
    hasPaymentsManagerAccess: userRoles.includes(PaymentsManager),
    hasPayoutsReadOnlyAccess: userRoles.includes(PayoutsReadOnly),
    hasStatementsInvoicesReadOnlyAccess: userRoles.includes(StatementsInvoicesReadOnly),
    hasSettingsAccess: userRoles.some((role) => [...Object.keys(ManagerRoles)].includes(role)),
    hasLocationsSettingsReadOnlyAccess: userRoles.includes(LocationsSettingsReadOnly),
    hasLocationsSettingsManagerAccess: userRoles.includes(LocationsSettingsManager),
    hasGreenGreyAccess: userRoles.includes(GreenGreyAccess),
    hasTxnsExporter: userRoles.includes(TransactionsExporter),
  };
};
