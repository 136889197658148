import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const primaryDefault = defineStyle({
  bgColor: 'background.primary.default',
  border: 'none',
});

const secondaryDefault = defineStyle({
  bgColor: 'background.secondary.default',
  border: 'none',
});

const successDefault = defineStyle({
  bgColor: 'background.success.default',
  border: 'none',
});

const dangerDefault = defineStyle({
  bgColor: 'background.danger.default',
  border: 'none',
});

const borderDefault = defineStyle({
  bgColor: 'transparent',
  color: 'text.subdued',
  borderColor: 'border.default',
  borderWidth: '2px',
});

const borderPrimary = defineStyle({
  bgColor: 'transparent',
  color: 'text.primary.default',
  borderColor: 'border.primary',
  borderWidth: '2px',
});

const borderSuccess = defineStyle({
  bgColor: 'transparent',
  color: 'text.success.default',
  borderColor: 'border.success',
  borderWidth: '2px',
});

const borderError = defineStyle({
  bgColor: 'transparent',
  color: 'text.danger.default',
  borderColor: 'border.error',
  borderWidth: '2px',
});

export const BadgeStyles = defineStyleConfig({
  baseStyle: {
    padding: '4px 4px 2px 4px',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '124%' /* 14.88px */,
    letterSpacing: '0.432px',
    textTransform: 'uppercase',
    color: 'text.on-secondary',
    bgColor: 'background.secondary',
    borderRadius: '2px',
    textStyle: 'overline.sm',
  },

  variants: {
    primaryDefault,
    secondaryDefault,
    successDefault,
    dangerDefault,
    borderDefault,
    borderPrimary,
    borderSuccess,
    borderError,
  },
});

export default BadgeStyles;
