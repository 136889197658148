/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@app/hooks';
import { Box } from '@chakra-ui/react';
import { BarGraph, IBarProps } from '@components/barGraph';
import { BarItem } from '@nivo/bar';
import _ from 'lodash';

import { displayCurrency, displayNumber } from '@/common/utils';
import { SEGMENT_COLORS } from '@/common/utils';
import { getAmountOfTicksToRender } from '@/common/utils/utils';
import colors from '@/theme/foundations/colors';

import { selectDateRange } from '../../dashboard/slice';
import { getCustomerAcquisitionMetricKeys, getCustomerAcquisitionMetricType } from '../../explore/customerDeepDive';

// returns a list of total value labels for stacked bars
export const TotalLabels = (props: any) => {
  const { bars, yScale, formatter } = props;
  const labelMargin = 20;
  const groupedBars = _.groupBy(_.orderBy(bars, 'x'), 'x');

  return _.map(_.values(groupedBars), (values: any) => {
    const firstBar = _.first(values) as any;
    const total = _.sumBy(values, (innerProps: any) => innerProps.data.value) as number;
    const {
      data: { indexValue },
      x,
      width,
    } = firstBar;

    return (
      <g transform={`translate(${x}, ${yScale(total) - labelMargin})`} key={`${indexValue}`}>
        <text
          x={width / 2}
          y={labelMargin / 2}
          textAnchor='middle'
          alignmentBaseline='central'
          style={{
            fill: 'rgb(51, 51, 51)',
            fontSize: '12px',
          }}
        >
          {formatter ? formatter(total) : total}
        </text>
      </g>
    );
  });
};

/**
 * The purpose of the custom bar component is to render bars
 * without labels inside.
 */
const CustomBarComponent = (props: any) => {
  const newProps = Object.assign({}, props, { shouldRenderLabel: false });
  return <BarItem {...newProps} />;
};

export const CustomerAcquisitionChart = ({
  data,
  loadingStatus,
  title,
  dropdownMenuProps,
  displayLegend = true,
  width = '100%',
}: IBarProps) => {
  const { t } = useTranslation();

  // If metric is not specified, use id from the first element of data
  const metricLabel = data && data[0].id;
  const metricType = getCustomerAcquisitionMetricType(metricLabel);
  const dropdownItems = getCustomerAcquisitionMetricKeys();
  const { temporalUnit } = useAppSelector(selectDateRange);

  let formatter: (value: string | number) => string;
  if (metricType === 'currency') {
    formatter = (value: string | number) => displayCurrency(value as number, 0);
  } else {
    formatter = (value: string | number) => displayNumber(value as number);
  }

  const FormattedLabels = (props: any) => {
    return TotalLabels({ ...props, ...{ formatter } });
  };

  const fnColor = (value: any) => SEGMENT_COLORS[value.id] || colors.red[600];
  const axisBottomLegend = temporalUnit === 'week' ? t('axisLabelWeekly') : t('axisLabelMonthly');
  const chartProps = {
    margin: { top: 60, right: 40, bottom: 100, left: 70 },
    colors: fnColor,
    groupMode: 'stacked',
    padding: 0.2,
    barComponent: CustomBarComponent,
    tooltipFormat: (value: any) => formatter(value),
    gridYValues: 4,
    axisBottom: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: displayLegend ? axisBottomLegend : null,
      legendPosition: 'middle',
      legendOffset: 45,
      renderTick: (tick: any) => {
        const dataLength = data[0].data.length;

        const MULTIPLY_VALUE = getAmountOfTicksToRender(dataLength);
        const shouldRender = tick.tickIndex % MULTIPLY_VALUE <= 0;

        if (dataLength < 12 || (dataLength > 12 && shouldRender)) {
          return (
            <g transform={`translate(${tick.x},${tick.y + 15})`}>
              <text
                x={0}
                y={0}
                textAnchor='middle'
                dominantBaseline='middle'
                style={{
                  fill: 'rgb(51, 51, 51)',
                  fontSize: 12,
                  fontFamily: 'sans-serif',
                }}
              >
                {tick.value}
              </text>
            </g>
          );
        }

        return '';
      },
    },
    axisLeft: {
      tickValues: 4,
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      format: (value: any) => formatter(value),
    },
    legends: [
      {
        dataFrom: 'keys',
        anchor: 'bottom-left',
        direction: 'row',
        justify: false,
        translateX: -30,
        translateY: 90,
        itemsSpacing: 40,
        itemWidth: 50,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 12,
        symbolShape: 'circle',
      },
    ],
    layers: ['grid', 'axes', 'bars', FormattedLabels, 'markers', 'legends'],
  };

  const dropdownProps = dropdownMenuProps ? dropdownMenuProps[0] : {};

  return (
    <Box mb={8}>
      <BarGraph
        data={data}
        loadingStatus={loadingStatus}
        title={title}
        width={width}
        wideLegend={true}
        customProps={chartProps}
        dropdownMenuProps={[{ items: dropdownItems, ...dropdownProps }]}
      />
    </Box>
  );
};
