import { z } from 'zod';

export const communicationsEnum = z.enum([
  'SITE_LAUNCH',
  'HISTORICAL_TRANSACTION_DATA_ALERTS',
  'BILLING_RECEIPT',
  'WEEKLY_SUMMARY',
  'MONTHLY_SUMMARY',
]);

export const notificationPreferencesFormSchema = z.object({
  notificationPreferences: z.object({
    email: z.string().email(),
    communications: z.array(communicationsEnum),
    isPrimary: z.boolean(),
  }),
});
