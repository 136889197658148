/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStandaloneToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { push } from 'redux-first-history';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { RootState } from '@/app/store';
import { theme } from '@/theme';

import * as api from './api';
import {
  addNewBillingMethodsFailed,
  addNewBillingMethodsRequest,
  addNewBillingMethodsSucceeded,
  billingPaymentMethodsFailed,
  billingPaymentMethodsFetchRequest,
  billingPaymentMethodsSucceeded,
  billingPlaidTokenFailed,
  billingPlaidTokenFetchRequest,
  billingPlaidTokenSucceeded,
  deleteBillingMethodRequest,
  deleteBillingMethodSucceeded,
  updateBillingMethodFailed,
  updateBillingMethodRequest,
  updateBillingMethodSucceeded,
} from './slice';

const { toast } = createStandaloneToast({ theme });

/*****************************************************************************/
/******************************* WORKERS *************************************/
/*****************************************************************************/

export function* fetchBillingMethods(action: any) {
  try {
    const result = yield* call(api.fetchBillingMethods, action.payload);

    yield* put(billingPaymentMethodsSucceeded(result));
  } catch (error) {
    const axiosError = error as AxiosError;
    yield* put(billingPaymentMethodsFailed(axiosError.message));
  }
}

export function* addNewBillingMethod(action: any) {
  try {
    const state = yield* select((state: RootState) => state.billing.newBillingMethod);
    const { isBank, ...payload } = action.payload;
    let result;

    if (isBank) {
      result = yield* call(api.addNewBillingMethod, {
        ...payload,
        data: {
          ...payload.data,
          nickname: state?.account_nickname,
          site_uuid: state?.site_uuid,
          account_holder_name: state?.account_holder_name,
          account_holder_type: state?.account_holder_type,
          is_default: state?.default,
        },
      });
    } else {
      result = yield* call(api.addNewBillingMethod, payload);
    }

    if (result === 200) {
      yield* put(addNewBillingMethodsSucceeded());

      if (isBank) {
        toast({
          title: 'Bank account added',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Credit card added',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }

      yield* put(push('/settings/payments'));
    } else {
      yield* put(addNewBillingMethodsFailed(result?.toString() ?? 'error'));
    }
  } catch (error) {
    const axiosError = error as AxiosError;
    yield* put(addNewBillingMethodsFailed(axiosError.message));
  }
}

export function* updateBillingMethod(action: any) {
  try {
    const { ...payload } = action.payload;
    const result = yield* call(api.updateBillingMethod, payload);
    if (result === 200) {
      toast({
        title: 'Saving billing method...',
        status: 'info',
        duration: 2000,
        isClosable: true,
      });
      yield* put(updateBillingMethodSucceeded());
      toast({
        title: 'Billing method updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      yield* put(push('/settings/payments'));
    } else {
      yield* put(updateBillingMethodFailed(`error: ${result}`));
    }
  } catch (error) {
    toast({
      title: 'Failed to update billing method',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

    const axiosError = error as AxiosError;
    yield* put(updateBillingMethodFailed(axiosError.message));
  }
}

export function* deleteBillingMethod(action: any) {
  try {
    const { ...payload } = action.payload;
    yield* call(api.deleteBillingMethod, payload);

    yield* put(deleteBillingMethodSucceeded());
    yield* put(billingPaymentMethodsFetchRequest(payload));

    toast({
      title: 'Billing method deleted successfully',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

    yield* put(push('/settings/payments'));
  } catch (error) {
    const axiosError = error as AxiosError;
    toast({
      title: 'Failed to delete billing method',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
    yield* put(addNewBillingMethodsFailed(axiosError.message));
  }
}

export function* fetchPlaidToken(action: any) {
  try {
    const result = yield* call(api.fetchPlaidToken, action.payload);

    yield* put(billingPlaidTokenSucceeded({ token_data: result }));
  } catch (error: any) {
    yield* put(billingPlaidTokenFailed(error.message));
  }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchBillingMethods() {
  yield* takeLatest(billingPaymentMethodsFetchRequest, fetchBillingMethods);
}

export function* watchNewBillingMethod() {
  yield* takeLatest(addNewBillingMethodsRequest, addNewBillingMethod);
}

export function* watchUpdateBillingMethod() {
  yield* takeLatest(updateBillingMethodRequest, updateBillingMethod);
}

export function* watchDeleteBillingMethod() {
  yield* takeLatest(deleteBillingMethodRequest, deleteBillingMethod);
}

export function* watchBillingPlaidToken() {
  yield* takeLatest(billingPlaidTokenFetchRequest, fetchPlaidToken);
}
