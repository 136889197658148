import { Divider, useCheckboxGroup, Wrap, WrapItem } from '@chakra-ui/react';

import { CheckboxCard } from './CheckboxCard';
import { TCustomLegendsProps } from './types';

export function CustomLegends({ options, defaultOptions, onChange, disabled = false }: TCustomLegendsProps) {
  const { getCheckboxProps } = useCheckboxGroup({
    value: defaultOptions,
    onChange,
  });

  return (
    <Wrap spacing={4} justify='center' position='relative' data-testid='customLegendsWrapper'>
      {options.map((option) => {
        const checkbox = getCheckboxProps({
          value: option.value ?? option.label,
          isDisabled: option.disabled ?? disabled,
          disabled: option.disabled ?? disabled,
        });

        return (
          <WrapItem key={option.label + option.color}>
            {option.divider && <Divider orientation='vertical' color='neutral.800' mr={'12px'} />}
            <CheckboxCard color={option.color} {...checkbox}>
              {option.label}
            </CheckboxCard>
          </WrapItem>
        );
      })}
    </Wrap>
  );
}
