import { PopoverBody, PopoverCloseButton, PopoverContent, Portal } from '@chakra-ui/react';

export function OutlierInfo({ outlierInfo }: { outlierInfo: string }) {
  return (
    <Portal>
      <PopoverContent>
        <PopoverCloseButton />
        <PopoverBody>{outlierInfo}</PopoverBody>
      </PopoverContent>
    </Portal>
  );
}
