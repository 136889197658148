import { ITableData } from '@components/table/Table';

export interface IPayoutsList {
  table: ITableData;
  nextPage?: number | null;
  prevPage?: number | null;
  count: number;
  rawData: IPayoutsData[];
  billableEntities: string[];
}

export enum PayoutsStatus {
  'STARTED' = 'In process',
  'COMPLETED' = 'Success',
  'RESTATED' = 'Failed',
}

export interface IPayoutsData {
  invoice_uuid: string;
  status: 'STARTED' | 'COMPLETED' | 'RESTATED';
  billable_entity_display_name: string;
  period_start_timestamp: string;
  period_end_timestamp: string;
  invoice_amount: number;
  processing_fee: number;
  gross_sales: number;
  upside_promotion_cost: number;
  upside_profit_share_cost: number;
  misc_adjustments: number;
  net_settlement: number;
  download_enabled: boolean | undefined;
}

export interface IPayoutsRawData {
  records: IPayoutsData[];
  account_names: (string | null)[];
  next_page: number | null;
  prev_page: number | null;
  count: number;
}

export enum SettingsDropdown {
  Last6Months = 'Last 6 months',
  ThisYear = 'This year',
  Last13Months = 'Last 13 months',
  AllPrevYears = 'All previous years',
}

export interface PayoutsRequestPayload {
  authRequestPath: string;
  page: number;
  pageSize: number;
  startDate: string;
  endDate: string;
}
