import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Select, Text } from '@chakra-ui/react';

import { PaginationProps } from './types';

export const PageSizeSelector = (props: PaginationProps) => {
  const { t } = useTranslation();
  const { availablePageSizes, pageSize } = props;
  return availablePageSizes && availablePageSizes.length > 1 ? (
    <Flex as='label' flexDir={'row'} gap={4} alignItems='center'>
      <Text fontWeight={500} whiteSpace={'nowrap'}>
        {t('components.pagination.rowsPerPage')}
      </Text>
      <Select
        fontWeight={500}
        size={'sm'}
        borderRadius={'48px'}
        value={pageSize}
        onChange={(event: ChangeEvent<HTMLSelectElement>) =>
          props.changePageSize(parseInt(event.currentTarget.value, 0))
        }
        data-testid='pagination-selector'
      >
        {availablePageSizes.map((size: number) => {
          return (
            <option data-testid='select-option' key={size} value={size}>
              {size}
            </option>
          );
        })}
      </Select>
    </Flex>
  ) : null;
};
