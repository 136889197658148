import { Trans, useTranslation } from 'react-i18next';
import { useAppSelector } from '@app/hooks';
import { Heading, Text } from '@chakra-ui/react';
import { BannerStat } from '@components/bannerStat';
import { ExpansionPanel } from '@components/expansionPanel';

import { Verticals } from '@/common/interfaces';
import { displayPercent } from '@/common/utils';
import { selectActiveVertical } from '@/features/auth/userSlice';
import { selectSelectedTransactionType } from '@/features/dashboard/slice';

export const IncrementalGrossProfitView = ({
  incrementalRevenue,
  incrementalGrossProfit,
  averageGrossMargin,
  incrementalGallons,
  blendedAvgMarginPerGallon,
}: {
  incrementalRevenue: string;
  incrementalGrossProfit: string;
  averageGrossMargin: string;
  incrementalGallons: string;
  blendedAvgMarginPerGallon: string;
}) => {
  const { t } = useTranslation();
  const activeVertical = useAppSelector(selectActiveVertical);
  const selectedTransactionType = useAppSelector(selectSelectedTransactionType);
  const avgGrossMarginPercentage = displayPercent(averageGrossMargin);

  return (
    <>
      <Heading size='md' mt={6}>
        {t('profitabilityPage.incGrossProfitHeader')}
      </Heading>
      <BannerStat label={t('profitabilityPage.incGrossProfitStatLabel')} value={incrementalGrossProfit} />
      {activeVertical !== Verticals.Restaurant && (
        <>
          <Text>{t(`profitabilityPage.incGrossProfitText1.${activeVertical}`)}</Text>
          <Text>
            <Trans
              i18nKey={`profitabilityPage.incGrossProfitText2.${activeVertical}`}
              components={{ bold: <strong /> }}
            ></Trans>
          </Text>
        </>
      )}
      <Text>
        {activeVertical === Verticals.Fuel && (
          <Trans
            i18nKey={`profitabilityPage.incGrossProfitText3.${activeVertical}.${selectedTransactionType}`}
            components={{ bold: <strong /> }}
            values={{
              avgGrossMarginPercentage,
              blendedAvgMarginPerGallon,
              incrementalGallons,
              incrementalGrossProfit,
              incrementalRevenue,
            }}
          />
        )}
        {activeVertical === Verticals.Grocery && (
          <Trans
            i18nKey={`profitabilityPage.incGrossProfitText3.${activeVertical}`}
            components={{ bold: <strong /> }}
            values={{ incrementalRevenue, avgGrossMarginPercentage, incrementalGrossProfit }}
          />
        )}
        {activeVertical === Verticals.Restaurant && (
          <>
            <Trans
              i18nKey={`profitabilityPage.incGrossProfitText3.${activeVertical}`}
              components={{ bold: <strong /> }}
              values={{ incrementalRevenue, avgGrossMarginPercentage, incrementalGrossProfit }}
            />
          </>
        )}
      </Text>
      {activeVertical === Verticals.Restaurant && <Text>{t('profitabilityPage.incGrossProfitText4')}</Text>}
      {(activeVertical === Verticals.Fuel || activeVertical === Verticals.CStore) && (
        <ExpansionPanel
          header={t(`profitabilityPage.incGrossProfitFaq.${activeVertical}.${selectedTransactionType}.question`)}
        >
          <Text lineHeight={'24px'}>
            {t(`profitabilityPage.incGrossProfitFaq.${activeVertical}.${selectedTransactionType}.answer`)}
          </Text>
        </ExpansionPanel>
      )}
    </>
  );
};
