import { Trans, useTranslation } from 'react-i18next';
import { useAppSelector } from '@app/hooks';
import { Heading, Text } from '@chakra-ui/react';
import { BannerStat } from '@components/bannerStat';

import { TransactionTypes, Verticals } from '@/common/interfaces';
import { displayPercent } from '@/common/utils';
import { selectActiveVertical } from '@/features/auth/userSlice';
import { selectSelectedTransactionType } from '@/features/dashboard/slice';

export const ROIView = ({
  cashBackProvidedToCustomers,
  incrementalNetProfitFromUpside,
  returnOnInvestment,
  roiAmount,
  totalPromotionsAndProfitShare,
  upsideFundedCustomerPromotions,
  upsideProfitShare,
}: {
  cashBackProvidedToCustomers: string;
  incrementalNetProfitFromUpside: string;
  returnOnInvestment: string;
  roiAmount: string;
  totalPromotionsAndProfitShare: string;
  upsideFundedCustomerPromotions: string;
  upsideProfitShare: string;
}) => {
  const { t } = useTranslation();
  const activeVertical = useAppSelector(selectActiveVertical);
  const selectedTransactionType = useAppSelector(selectSelectedTransactionType);

  const isFuelVerticalButCSToreData =
    activeVertical === Verticals.Fuel && selectedTransactionType === TransactionTypes.CStore;
  const roiPercentage = displayPercent(returnOnInvestment);

  return (
    <>
      <Heading size='md' mt={6}>
        <Trans i18nKey='profitabilityPage.roiHeader'>ROI</Trans>
      </Heading>
      <BannerStat label={t('profitabilityPage.roiStatLabel')} value={roiPercentage} />
      {isFuelVerticalButCSToreData ? (
        <Text>
          <Trans i18nKey={`profitabilityPage.roiText1.c-store`} components={{ bold: <strong /> }} />
        </Text>
      ) : (
        <Text>
          <Trans
            i18nKey={`profitabilityPage.roiText1.${activeVertical}`}
            components={{ bold: <strong /> }}
            values={{
              cashBackProvidedToCustomers,
              incrementalNetProfitFromUpside,
              totalPromotionsAndProfitShare,
              upsideFundedCustomerPromotions,
              upsideProfitShare,
            }}
          />
        </Text>
      )}
      {isFuelVerticalButCSToreData ? (
        <Text>
          <Trans
            i18nKey={`profitabilityPage.roiText2.c-store`}
            components={{ bold: <strong /> }}
            values={{ roiPercentage, roiAmount }}
          />
        </Text>
      ) : (
        <Text>
          <Trans
            i18nKey={`profitabilityPage.roiText2.${activeVertical}`}
            components={{ bold: <strong /> }}
            values={{ roiPercentage, roiAmount }}
          />
        </Text>
      )}
      {isFuelVerticalButCSToreData ? (
        <Text>
          <Trans
            i18nKey={`profitabilityPage.roiText3.c-store`}
            components={{ bold: <strong /> }}
            values={{
              incrementalNetProfitFromUpside,
              totalPromotionsAndProfitShare,
              cashBackProvidedToCustomers,
              upsideFundedCustomerPromotions,
              upsideProfitShare,
              roiPercentage,
            }}
          />
        </Text>
      ) : (
        <Text>
          <Trans
            i18nKey={`profitabilityPage.roiText3.${activeVertical}`}
            components={{ bold: <strong /> }}
            values={{
              incrementalNetProfitFromUpside,
              totalPromotionsAndProfitShare,
              cashBackProvidedToCustomers,
              upsideFundedCustomerPromotions,
              upsideProfitShare,
              roiPercentage,
            }}
          />
        </Text>
      )}
      {/* <ExpansionPanel header={t('profitabilityPage.roiFaq1')}>{t('profitabilityPage.roiFaqAnswer1')}</ExpansionPanel>
      <ExpansionPanel header={t('profitabilityPage.roiFaq2')}>{t('profitabilityPage.roiFaqAnswer2')}</ExpansionPanel> */}
    </>
  );
};
