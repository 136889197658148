import { useTranslation } from 'react-i18next';
import {
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  Portal,
  Table,
  Tbody,
  Td,
  Tr,
} from '@chakra-ui/react';

import { displayCurrency } from '@/common/utils/currency';
import { ICustomerTransactionMarginDetail } from '@/features/explore/customerDeepDive/types';

interface PopoverProps {
  data: ICustomerTransactionMarginDetail;
}

export const MarginDetailPopover = ({ data }: PopoverProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Portal>
        <PopoverContent fontWeight={500} w={'100%'} data-testid='popover-marginDetail'>
          <PopoverCloseButton pt={5} />
          <PopoverHeader pl={4} pt={3} textAlign={'left'} w='100%'>
            {t('components.transactionsTable.popover.marginDetailHeader')}
          </PopoverHeader>
          <PopoverBody>
            <Table w={'100%'}>
              <Tbody>
                <Tr>
                  <Td>{t('components.transactionsTable.popover.signPrice')}</Td>
                  <Td> </Td>
                  <Td fontWeight={700} fontStyle={'bold'}>
                    {displayCurrency(data.sign_price, 3)}
                  </Td>
                </Tr>
                <Tr>
                  <Td>{t('components.transactionsTable.popover.rackPrice')}</Td>
                  <Td>{'-'}</Td>
                  <Td fontWeight={700} fontStyle={'bold'}>
                    {displayCurrency(data.rack_price, 3)}
                  </Td>
                </Tr>
                <Tr>
                  <Td>{t('components.transactionsTable.popover.cardFee')}</Td>
                  <Td>{'-'}</Td>
                  <Td fontWeight={700} fontStyle={'bold'}>
                    {displayCurrency(data.cc_fee, 3)}
                  </Td>
                </Tr>
                <Tr>
                  <Td>{t('components.transactionsTable.popover.taxes')}</Td>
                  <Td>{'-'}</Td>
                  <Td fontWeight={700} fontStyle={'bold'}>
                    {displayCurrency(data.tax, 3)}
                  </Td>
                </Tr>
                <Tr>
                  <Td>{t('components.transactionsTable.popover.freightCosts')}</Td>
                  <Td>{'-'}</Td>
                  <Td fontWeight={700} fontStyle={'bold'}>
                    {displayCurrency(data.rack_fee, 3)}
                  </Td>
                </Tr>
                {+data.double_discount_adj !== Number(0) && (
                  <Tr>
                    <Td>{t('components.transactionsTable.popover.doubleDiscount')}</Td>
                    <Td>{'-'}</Td>
                    <Td fontWeight={700} fontStyle={'bold'}>
                      {displayCurrency(data.double_discount_adj, 3)}
                    </Td>
                  </Tr>
                )}
                {+data.misc_adj_per_gallon !== Number(0) && (
                  <Tr>
                    <Td>{t('components.transactionsTable.popover.marginAdjustment')}</Td>
                    <Td>{'-'}</Td>
                    <Td fontWeight={700} fontStyle={'bold'}>
                      {displayCurrency(data.misc_adj_per_gallon, 3)}
                    </Td>
                  </Tr>
                )}
                <Tr borderTop={'2px solid #B8B9C3'} bgColor='surface.default'>
                  <Td>{t('components.transactionsTable.popover.margin')}</Td>
                  <Td>{'='}</Td>
                  <Td fontWeight={700} fontStyle={'bold'}>
                    {displayCurrency(data.margin, 3)}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </>
  );
};
