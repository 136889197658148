import i18n from '@/i18n';

import { NotificationPreference, NotificationPreferences } from './types';

export const buildNotificationsTable = (data: NotificationPreferences) => {
  const isPrimaryIndex = data.notificationPreferences.findIndex((item) => item.isPrimary === true);
  if (isPrimaryIndex !== -1) {
    const [notificationPrefToMove] = data.notificationPreferences.splice(isPrimaryIndex, 1);
    data.notificationPreferences.unshift(notificationPrefToMove);
  }

  const rows = data.notificationPreferences.map((item: NotificationPreference) => {
    return [
      item.email,
      item.communications.length + i18n.t('notificationPage.table.numOfEmails'),
      item.isPrimary ? i18n.t('notificationPage.table.accountOwner') : i18n.t('notificationPage.table.additionalEmail'),
      { value: i18n.t('notificationPage.table.edit'), email: item.email },
    ];
  });

  const tableData = {
    headers: [
      i18n.t('notificationPage.table.email'),
      i18n.t('notificationPage.table.emailPref'),
      i18n.t('notificationPage.table.status'),
      i18n.t('notificationPage.table.actions'),
    ],
    rows: rows,
  };

  return tableData;
};
