import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Box, Button, Text } from '@chakra-ui/react';

import { AdditionalOfferForm } from './steps/AdditionalOfferForm';
import { BasicInformationForm, ClassOfTradeForm, MarginsForm, TransactionDataForm } from './steps';

export function AddFuelLocation({ step }: { step: number }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (step <= 1 || !step) {
      navigate('/settings/locations');
      return;
    }

    navigate(`/settings/locations/new/${step - 1}`);
  };

  return (
    <Box>
      <Button
        leftIcon={<ChevronLeftIcon fontSize={23} />}
        mb={2}
        px={0}
        variant='accentTertiary'
        size='md'
        onClick={handleGoBack}
      >
        {t('locationsSettingsPage.fuelForm.actions.previous')}
      </Button>

      <Text color='text.primary.default'>{t('locationsSettingsPage.fuelForm.stepper', { step })}</Text>
      <Routes>
        <Route path='1' element={<BasicInformationForm />} />
        <Route path='2' element={<MarginsForm />} />
        <Route path='3' element={<ClassOfTradeForm />} />
        <Route path='4/*' element={<TransactionDataForm />} />
        <Route path='5' element={<AdditionalOfferForm />} />
      </Routes>
    </Box>
  );
}
