import { ChakraProvider, Flex } from '@chakra-ui/react';

import { theme } from '@/theme';

import { DataLoadingErrorAlert } from '../errorAlert';

export const ExceptionFallback = () => {
  return (
    <ChakraProvider theme={theme}>
      <Flex
        as='section'
        direction={{ base: 'column', lg: 'row' }}
        height='100vh'
        overflowY='auto'
        justifyContent='center'
        alignItems='center'
        bgColor='neutral.100'
      >
        <DataLoadingErrorAlert />
      </Flex>
    </ChakraProvider>
  );
};
