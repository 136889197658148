/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStandaloneToast } from '@chakra-ui/react';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { push } from 'redux-first-history';
import { call, put, takeLatest } from 'typed-redux-saga';

import { theme } from '@/theme';

import * as api from './api';
import { buildNotificationsTable } from './dataFormatting';
import {
  notificationSettingsFetchFailed,
  notificationSettingsFetchRequest,
  notificationSettingsFetchSucceeded,
  updateNotificationSettingsFailed,
  updateNotificationSettingsRequest,
  updateNotificationSettingsSucceeded,
} from './slice';
import { NotificationPreferences, NotificationPrefsFormattedData } from './types';

const { toast } = createStandaloneToast({ theme });

/*****************************************************************************/
/******************************* WORKERS *************************************/
/*****************************************************************************/

export function* fetchNotifications(action: PayloadAction<{ authRequestPath: string }>) {
  try {
    const result = yield* call(api.fetchNotifications, action.payload);
    const tableData = buildNotificationsTable(result);

    const data: NotificationPrefsFormattedData = {
      tableData,
      rawData: result,
    };
    yield* put(notificationSettingsFetchSucceeded(data));
  } catch (error: any) {
    yield* put(notificationSettingsFetchFailed(error.message));
  }
}

export function* updateNotifications({
  payload: { authRequestPath, data },
}: PayloadAction<{
  authRequestPath: string;
  data: NotificationPreferences;
}>) {
  try {
    const result = yield* call(api.updateNotifications, { authRequestPath, data });
    if (result === 200) {
      yield* put(updateNotificationSettingsSucceeded());
      yield* put(notificationSettingsFetchRequest({ authRequestPath }));
      toast({
        title: 'Notification preferences have been updated',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      yield* put(push('/settings/notification'));
    } else {
      yield* put(updateNotificationSettingsFailed(`error: ${result}`));
    }
  } catch (error) {
    const axiosError = error as AxiosError;
    yield* put(updateNotificationSettingsFailed(axiosError.message));
    toast({
      title: 'Failed updating your notification preferences',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
  }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchNotification() {
  yield* takeLatest(notificationSettingsFetchRequest, fetchNotifications);
}

export function* watchUpdateNotification() {
  yield* takeLatest(updateNotificationSettingsRequest, updateNotifications);
}
