import { defineStyleConfig } from '@chakra-ui/system';

const AccordionStyles = defineStyleConfig({
  baseStyle: {
    button: {
      color: 'text.default',
      textDecoration: 'underline',
      background: 'none',
      _hover: {
        color: 'text.primary.hover',
      },
      '&:hover': {
        bg: 'none',
      },
    },
    root: {
      _hover: {
        backgroud: 'none',
      },
    },
    panel: {
      borderLeftWidth: '3px',
      borderColor: 'border.subtle',
    },
  },
});

export default AccordionStyles;
