import { Flex, useToken } from '@chakra-ui/react';
import { BarDatum, BarSvgProps } from '@nivo/bar';

import { BarGraph } from '@/common/components/barGraph';
import { CustomLegends } from '@/common/components/customLegends';
import { Status } from '@/common/interfaces';
import { IBarGraph } from '@/features/dashboard/types';
import i18n from '@/i18n';

import { CapacityByHourPopover } from './Popover';

type CapacityByHourProps = {
  capacityHourList: IBarGraph | undefined;
  capacityStatus: Status;
};

export const CapacityByHour = ({ capacityHourList, capacityStatus }: CapacityByHourProps) => {
  const [dataBlue, dataGreen] = useToken('colors', ['blue.800', 'green.400']);

  const SEGMENT_COLORS: { [key: string]: string } = {
    [i18n.t('capacityPage.byHourSection.graph.legend.avgTrxnsNoUpside')]: dataBlue,
    [i18n.t('capacityPage.byHourSection.graph.legend.avgTrxnsWithUpside')]: dataGreen,
  };

  const formatter = (value: string | number) => (value === 0 ? 0 : `${value}%`);

  const chartProps: Partial<BarSvgProps<BarDatum>> = {
    margin: { top: 60, right: 40, bottom: 100, left: 70 },
    colors: [dataBlue, dataGreen],
    groupMode: 'stacked',
    padding: 0.2,
    gridYValues: 4,
    axisBottom: {
      tickSize: 0,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Hour of day',
      legendPosition: 'middle',
      legendOffset: 45,
    },
    axisLeft: {
      tickValues: 4,
      tickSize: 0,
      tickPadding: 5,
      tickRotation: 0,
      format: (value: string | number) => formatter(value),
    },
    legends: undefined,
  };

  chartProps.tooltip = (point) => {
    return <CapacityByHourPopover point={point} segment={point.indexValue} />;
  };

  const customLegend = [
    {
      label: i18n.t('capacityPage.byHourSection.graph.legend.avgTrxnsNoUpside'),
      color: SEGMENT_COLORS[i18n.t('capacityPage.byHourSection.graph.legend.avgTrxnsNoUpside')],
    },
    {
      label: i18n.t('capacityPage.byHourSection.graph.legend.avgTrxnsWithUpside'),
      color: SEGMENT_COLORS[i18n.t('capacityPage.byHourSection.graph.legend.avgTrxnsWithUpside')],
    },
  ];

  return (
    <Flex
      w={'100%'}
      direction={'column'}
      alignItems={'flex-start'}
      gap={4}
      pb={8}
      mb={8}
      data-testid='capacity-by-hour-wrapper'
    >
      <BarGraph
        data={capacityHourList}
        loadingStatus={capacityStatus}
        title={'capacityPage.byHourSection.graph.title'}
        width={'100%'}
        wideLegend={true}
        customProps={chartProps}
      />
      <CustomLegends onChange={() => ''} options={customLegend} disabled={true} />
    </Flex>
  );
};

export const cardViewContents = {
  CapacityByHour,
};
