import { TransactionTypes, Verticals } from '@/common/interfaces';
import { display2Decimals, displayCurrency, displayNumber, displayPercent } from '@/common/utils';
import { MILES_TO_METERS } from '@/features/explore/locations/constants';
import { ISitePerformance } from '@/features/explore/locations/types';
import i18n from '@/i18n';

import { LEGEND_COLORS, MAX_MARKER_SCALE, MIN_MARKER_SCALE } from './constants';

/**
 * Formats numeric colors as a rgb(red, green, blue).
 *
 * @param input Object with color values.
 * @returns Formatted color
 */
export function formatColor(input: { r: number; g: number; b: number }): string {
  return `rgb(${input.r}, ${input.g}, ${input.b})`;
}

/**
 * Calculates the color for the percent value in the range from 0% to 100%.
 * It uses the LEGEND_COLORS as the color range.
 *
 * @param percent The number to calculate the color for.
 * @returns The color formatted as rgb(red, green, blue).
 */
export function getColorForPercentage(percent: number): string {
  const pct = percent / 100;
  let i = LEGEND_COLORS.length - 1;
  for (; i > 0; i--) {
    if (pct < LEGEND_COLORS[i].pct) {
      break;
    }
  }
  const lower = LEGEND_COLORS[i + 1];
  const upper = LEGEND_COLORS[i];
  const range = upper.pct - lower.pct;
  const rangePct = (pct - lower.pct) / range;
  const pctLower = 1 - rangePct;
  const pctUpper = rangePct;

  const color = {
    r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
    g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
    b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
  };

  return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
}

/**
 * Calculates the marker scale for the percent value in the range from 0% to 100%.
 *
 * @param percent The number to calculate the color for.
 * @returns The color formatted as rgb(red, green, blue).
 */
export function calculateScale(percent: number): number {
  return Math.round(MIN_MARKER_SCALE + MAX_MARKER_SCALE * (percent / 100));
}

export function buildModalTableRows(
  activeVertical: Verticals,
  site: ISitePerformance,
  transactionType: TransactionTypes,
): Array<Array<string>> {
  const commonStats = [
    [i18n.t('salesOnUpside'), displayCurrency(site.totalRevenue, 0)],
    [i18n.t('transOnUpside'), displayNumber(site.totalTransactions)],
  ];

  if (activeVertical === Verticals.Fuel) {
    const fuelStats =
      transactionType !== TransactionTypes.CStore
        ? [
            [i18n.t('gallonsOnUpside'), displayNumber(Number(site.totalGallonsBought))],
            [i18n.t('incrementalGallons'), display2Decimals(site.incrementalGallons, 0)],
          ]
        : [];

    return [
      ...commonStats,
      ...fuelStats,
      [i18n.t('percentOfLocationSales'), displayPercent(site.percentOfLocationSales)],
      ...(site.competitors && site.competitors > 0
        ? [
            [
              i18n.t('exclusivityZone'),
              i18n.t('miles', { miles: displayNumber(Number(site.exclusivityZone) / MILES_TO_METERS) }),
            ],
          ]
        : []),
      ...((site.competitors &&
        site.competitors > 0 && [[i18n.t('blockedCompetitors'), displayNumber(site.competitors)]]) ||
        []),
    ];
  }

  const additionalStats = [
    [i18n.t('totalCustomerPromos'), displayCurrency(site.totalCustomerPromotions, 0)],
    [i18n.t('percentOfLocationSales'), displayPercent(site.percentOfLocationSales)],
  ];

  if (activeVertical === Verticals.Restaurant) {
    return [
      ...commonStats,
      ...additionalStats,
      [i18n.t('percentOfCardSales'), displayPercent(site.percentOfCardSales)],
    ];
  }

  if (activeVertical === Verticals.Grocery) {
    return [...commonStats, ...additionalStats];
  }

  return [];
}
