import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  ChakraStyledOptions,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { t } from 'i18next';

import { useAppDispatch, useAppSelector } from '@/app/hooks';

import { selectCapacityLocationFilter, setCapacityLocationFilter } from './slice';

export const CapacityFilter = () => {
  const { onOpen, onClose, onToggle, isOpen } = useDisclosure();
  const dispatch = useAppDispatch();
  const selectedFilterOption = useAppSelector(selectCapacityLocationFilter);

  // TODO: fix type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleToggle = (e: any) => {
    dispatch(setCapacityLocationFilter(e.target.textContent));
    onToggle();
  };

  return (
    <Box ml={1}>
      <Popover placement='bottom-start' variant='responsive' isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <PopoverTrigger>
          <Button
            onClick={onToggle}
            variant='accentSecondary'
            borderRadius='lg'
            size='sm'
            data-testid='capacity-filter'
          >
            <Text mr='2'>{selectedFilterOption}</Text>
            {isOpen ? (
              <Icon as={ChevronUpIcon} color='icon.default' fontWeight={500} />
            ) : (
              <Icon as={ChevronDownIcon} color='icon.default' fontWeight={500} />
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent minW='300px' maxW='310px' width='100%' boxShadow='base' borderRadius='lg'>
          <PopoverBody maxH='244px' overflowY='auto'>
            <Box
              pt='5px'
              pb='10px'
              pl='10px'
              pr='20px'
              onClick={(e) => handleToggle(e)}
              {...styles}
              data-testid='operating-hrs-test'
            >
              {t(`capacityPage.byLocationSection.filter.operatingHrs`) as string}
            </Box>
            <Box
              pt='5px'
              pb='10px'
              pl='10px'
              pr='20px'
              onClick={(e) => handleToggle(e)}
              {...styles}
              data-testid='peak-hrs-test'
            >
              {t(`capacityPage.byLocationSection.filter.peakHrs`) as string}
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

const styles: ChakraStyledOptions = {
  cursor: 'pointer',
  color: 'text.default',
  borderRadius: 'full',
  px: '2',
  py: '1',
  w: 'full',
  _hover: {
    bg: 'blue.50',
  },
  _checked: {
    bg: 'blue.75',
  },
  _focus: {
    boxShadow: 'outline',
  },
};
