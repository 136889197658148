import { extendTheme } from '@chakra-ui/react';

import Accordion from './components/accordion';
import Alert from './components/alert';
import Badge from './components/badge';
import Button from './components/button';
import Card from './components/card';
import Checkbox from './components/checkbox';
import FormLabel from './components/formLabel';
import Heading from './components/heading';
import Input from './components/input';
import Link from './components/link';
import Popover from './components/popover';
import { RadioStyles } from './components/radio';
import Select from './components/select';
import Switch from './components/switch';
import Table from './components/table';
import Text from './components/text';
import Tooltip from './components/tooltip';
import colors from './foundations/colors';
import textStyles from './foundations/textStyles';
import semanticTokens from './semanticTokens';

// https://chakra-ui.com/docs/styled-system/th eme
// https://chakra-ui.com/docs/styled-system/customize-theme#scaling-out-your-project

export const theme = extendTheme({
  fonts: {
    body: '"Inter", sans-serif',
    heading: '"Gilmer", sans-serif',
  },

  colors,

  fontSizes: {
    '2xs': '12px',
    xs: '13px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '20px',
    '2xl': '24px',
    '3xl': '28px',
    '4xl': '32px',
    '5xl': '40px',
    '6xl': '48px',
  },
  headingSizes: {
    '2xs': '12px',
    xs: '13px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '20px',
    '2xl': '24px',
    '3xl': '28px',
    '4xl': '32px',
    '5xl': '40px',
    '6xl': '48px',
  },

  lineHeights: {
    '4xl': '112%',
    '5xl': '114%',
    '6xl': '116%',
    '7xl': '120%',
    '8xl': '124%',
    '11xl': '160%',
    '12xl': '172%',
  },

  letterSpacings: {
    '2xs': '-0.028em',
    xs: '-0.02em',
    sm: '-0.012em',
    '2xl': '0.036em',
  },

  textStyles,

  semanticTokens,

  components: {
    Button,
    Table,
    Heading,
    Link,
    Accordion,
    Badge,
    Radio: RadioStyles,
    Checkbox,
    Popover,
    Tooltip,
    Alert,
    Switch,
    Input,
    FormLabel,
    Text,
    Card,
    Select,
  },
});
