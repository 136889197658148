import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const error = defineStyle({
  color: 'text.danger.default',
  fontSize: 'xs',
});

const TextStyles = defineStyleConfig({
  baseStyle: {
    color: 'text.default',
    textStyle: 'body.md',
  },
  variants: {
    error,
  },
});

export default TextStyles;
