import { Fragment, useMemo } from 'react';
import { Checkbox, Flex, Text } from '@chakra-ui/react';

type TNetworkCheckboxProps = {
  value: string;
  highlight: string;
  label: string;
  count: number;
};

export function NetworkCheckbox({ value, highlight, count, label }: TNetworkCheckboxProps) {
  const highlightedText = useMemo(() => {
    const parts = label.split(new RegExp(`(${highlight})`, 'gi'));
    return parts.map((part: string, index: number) => (
      <Fragment key={index}>{part.toLowerCase() === highlight?.toLowerCase() ? <b>{part}</b> : part}</Fragment>
    ));
  }, [label, highlight]);

  return (
    <Flex w='full' align='center' justify='space-between' data-testid='network-checkbox'>
      <Checkbox alignItems='start' value={value} mr='2' lineHeight='shorter'>
        <Text lineHeight='5'>{highlightedText}</Text>
      </Checkbox>
      <Text fontWeight={500} fontSize='md' lineHeight='5'>
        {count}
      </Text>
    </Flex>
  );
}
