import { Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { LoadingScreen } from '@/common/components/loadingScreen';

export function DefaultLayout() {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <LoadingScreen />;
  }

  return <Outlet />;
}
