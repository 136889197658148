import { useTranslation } from 'react-i18next';
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import {
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';

export const CapacityInfoLink = ({ ...popoverProps }) => {
  const { t } = useTranslation();

  return (
    <Popover {...popoverProps} data-testid='customerSegmentInfo'>
      <PopoverTrigger>
        <Link>
          <Text>
            <QuestionOutlineIcon boxSize={4} mr='1.5' />
            {t(`capacityPage.byLocationSection.infoLink.label`)}
          </Text>
        </Link>
      </PopoverTrigger>
      <PopoverContent width={'350px'}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>
          <Text color='text.default-inverse'>{t(`capacityPage.byLocationSection.infoLink.header`)} </Text>
        </PopoverHeader>
        <PopoverBody>
          <Text color='text.default-inverse'>{t('capacityPage.byLocationSection.infoLink.body')} </Text>
          <Text>
            <Link
              color='text.default-inverse'
              href='https://fuelknowledgecenter.upside.com/hc/en-us/articles/10412377780123-You-have-excess-capacity-Upside-has-new-profitable-customers'
              isExternal
            >
              {t('capacityPage.byLocationSection.infoLink.learnMoreLink')}
            </Link>
          </Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
