import { z } from 'zod';

const phoneRegex = /^(\(\d{3}\)|\d{3})[-. ]?\d{3}[-. ]?\d{4}$/;

export const contactInfoSchema = z.object({
  form_data: z.object({
    email: z.string().email(),
    firstName: z.string().min(2),
    lastName: z.string().min(2),
    phone: z.string().regex(phoneRegex, { message: 'Invalid phone number' }),
    sortKey: z.string().optional(),
  }),
});

export const changePasswordSchema = z
  .object({
    newPassword: z
      .string()
      .min(12, 'Must contain at least 12 characters')
      .regex(/^(?=.*[A-Z])/, 'Must contain one upper case letter')
      .regex(/^(?=.*[a-z])/, 'Must contain one lower case letter')
      .regex(/^(?=.*[0-9])/, 'Must contain one number')
      .regex(/^(?=.*[`!@#$%^&*()_\-+=[\]{};':"\\|,.<>/?~ ])/, 'Must contain one special character'),
    confirmNewPassword: z.string().min(12, 'Must contain at least 12 characters'),
  })
  .refine(({ newPassword, confirmNewPassword }) => newPassword === confirmNewPassword, {
    message: 'Passwords must match - cannot copy paste',
    path: ['confirmNewPassword'],
  });
