import { LocationSettingsData, LocationSettingsRawData } from './types';

export const parseLocationsSettings = (data: LocationSettingsRawData[]): LocationSettingsData[] => {
  const sortOrder: { [key: string]: number } = { DEFAULT: 0, PRE_LAUNCH: 1, TEST: 2, DEAD: 3, UNLISTED: 4 };

  const parsedData = data
    .map((item) => {
      const parsedAdditionalProperties = {
        ...item.additionalProperties,
        dataSources: JSON.parse(item.additionalProperties.dataSources ?? '[]'),
        dataStatus: JSON.parse(item.additionalProperties.dataStatus ?? '{}'),
        margins: JSON.parse(item.additionalProperties.margins ?? '{}'),
        services: JSON.parse(item.additionalProperties.services ?? '[]'),
        opisRackTerminal: JSON.parse(item.additionalProperties.opisRackTerminal ?? '{}'),
        processorCredentials: JSON.parse(item.additionalProperties.processorCredentials ?? '{}'),
      };

      const parsedItem = {
        ...item,
        additionalProperties: parsedAdditionalProperties,
      };

      return parsedItem as LocationSettingsData;
    })
    .sort((a, b) => {
      return sortOrder[a.visibility] - sortOrder[b.visibility];
    })
    .sort((a, b) => {
      if (a.relationshipType === 'HISTORICAL') return 1;
      if (b.relationshipType === 'HISTORICAL') return -1;

      return 0;
    });

  return parsedData;
};
