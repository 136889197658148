/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useState } from 'react';
import {
  Box,
  ChakraProps,
  ChakraStyledOptions,
  Heading,
  Table as ChakraTable,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { TableCell } from './TableCell';

const SELECTED_EVEN_COLOR = 'blue.75 !important';
const SELECTED_ODD_COLOR = 'blue.100 !important';
const SELECTED_TOP_LEFT_CELL = {
  borderColor: 'blue.800 !important',
  color: 'neutral.1200 !important',
  borderTopStyle: 'solid !important',
  borderTopWidth: '2px !important',
  borderLeftStyle: 'solid !important',
  borderLeftWidth: '2px !important',
  borderTopLeftRadius: '8px !important',
};
const SELECTED_TOP_MIDDLE_CELL = {
  borderColor: 'blue.800 !important',
  color: 'neutral.1200 !important',
  borderTopStyle: 'solid !important',
  borderTopWidth: '2px !important',
};
const SELECTED_TOP_RIGHT_CELL = {
  borderColor: 'blue.800 !important',
  color: 'neutral.1200 !important',
  borderTopStyle: 'solid !important',
  borderTopWidth: '2px !important',
  borderRightStyle: 'solid !important',
  borderRightWidth: '2px !important',
  borderTopRightRadius: '8px !important',
};

const SELECTED_MIDDLE_LEFT_CELL = {
  borderColor: 'blue.800 !important',
  color: 'neutral.1200 !important',
  borderLeftStyle: 'solid !important',
  borderLeftWidth: '2px !important',
};

const SELECTED_MIDDLE_RIGHT_CELL = {
  borderColor: 'blue.800 !important',
  color: 'neutral.1200 !important',
  borderRightStyle: 'solid !important',
  borderRightWidth: '2px !important',
};

const SELECTED_BOTTOM_LEFT_CELL = {
  borderColor: 'blue.800 !important',
  color: 'neutral.1200 !important',
  borderBottomStyle: 'solid !important',
  borderBottomWidth: '2px !important',
  borderLeftStyle: 'solid !important',
  borderLeftWidth: '2px !important',
  borderBottomLeftRadius: '8px !important',
};

const SELECTED_BOTTOM_MIDDLE_CELL = {
  borderColor: 'blue.800 !important',
  color: 'neutral.1200 !important',
  borderBottomStyle: 'solid !important',
  borderBottomWidth: '2px !important',
};

const SELECTED_BOTTOM_RIGHT_CELL = {
  borderColor: 'blue.800 !important',
  color: 'neutral.1200 !important',
  borderBottomStyle: 'solid !important',
  borderBottomWidth: '2px !important',
  borderRightStyle: 'solid !important',
  borderRightWidth: '2px !important',
  borderBottomRightRadius: '8px !important',
};

export interface ITableCellValue {
  value: string | number;
  rawValue?: string | number;
  isNumeric?: boolean;
  centerEmpty?: boolean;
  popoverData?: any;
  popoverLink?: boolean;
  outlierInfo?: string;
  invoiceUuid?: any;
  tableCellId?: string;
}

export interface ITableColumnHeader {
  columnName: string;
  isNumeric?: boolean;
  help?: string;
}

export interface ITableData {
  title?: string;
  columnWidths?: Array<string | number | undefined>;
  headers?: Array<string | ITableColumnHeader>;
  rows: Array<string | number | ITableCellValue>[];
}

export interface ITableProps {
  data: ITableData;
  highlightedRowStart?: number;
  highlightedRowEnd?: number;
  onClick?(row: Array<string | number | ITableCellValue>): void;
  id?: string;
}

/**
 * Calculate inline styles for the table cell.
 *
 * @param columnsNumber A number of columns in the table.
 * @param rowIdx Index of the row
 * @param colIdx Index of the column
 * @param highlightedRowStart The index where the selection starts.
 * @param highlightedRowEnd The index where the selection ends.
 * @returns Styles to be applied to the cell
 */
function getCellStyle(
  columnsNumber: number,
  rowIdx: number,
  colIdx: number,
  highlightedRowStart?: number,
  highlightedRowEnd?: number,
): ChakraProps {
  const hasHighlight = typeof highlightedRowStart === 'number' && typeof highlightedRowEnd === 'number';
  if (!hasHighlight) {
    return {};
  }

  const isSelected = hasHighlight && rowIdx >= highlightedRowStart && rowIdx <= highlightedRowEnd;
  const isSelectionStart = hasHighlight && rowIdx === highlightedRowStart;
  const isSelectionEnd = hasHighlight && rowIdx === highlightedRowEnd;
  const bgColor = rowIdx % 2 === 0 ? SELECTED_EVEN_COLOR : SELECTED_ODD_COLOR;
  const tdStyles: ChakraProps = {};
  if (isSelected) {
    const lastCellIdx = columnsNumber - 1;

    tdStyles.bg = bgColor;
    tdStyles.borderStyle = 'none';
    tdStyles.borderWidth = 0;
    tdStyles.borderColor = 'blue.800 !important';
    tdStyles.color = 'neutral.1200 !important';

    if (isSelectionStart) {
      if (colIdx === 0) {
        Object.assign(tdStyles, SELECTED_TOP_LEFT_CELL);
      }
      if (colIdx > 0 && colIdx < lastCellIdx) {
        Object.assign(tdStyles, SELECTED_TOP_MIDDLE_CELL);
      }
      if (colIdx === lastCellIdx) {
        Object.assign(tdStyles, SELECTED_TOP_RIGHT_CELL);
      }
    }
    if (isSelectionEnd) {
      if (colIdx === 0) {
        Object.assign(tdStyles, SELECTED_BOTTOM_LEFT_CELL);
      }
      if (colIdx > 0 && colIdx < lastCellIdx) {
        Object.assign(tdStyles, SELECTED_BOTTOM_MIDDLE_CELL);
      }
      if (colIdx === lastCellIdx) {
        Object.assign(tdStyles, SELECTED_BOTTOM_RIGHT_CELL);
      }
    }

    if (!isSelectionStart && !isSelectionEnd) {
      if (colIdx === 0) {
        Object.assign(tdStyles, SELECTED_MIDDLE_LEFT_CELL);
      }
      if (colIdx === lastCellIdx) {
        Object.assign(tdStyles, SELECTED_MIDDLE_RIGHT_CELL);
      }
    }
  }

  return tdStyles;
}

export const Table = ({ data, highlightedRowStart, highlightedRowEnd, onClick, id }: ITableProps) => {
  const [hoverRow, setHoverRow] = useState<number | null>(null);
  const columnWidths: Array<string | number | undefined> = data?.columnWidths || [];

  return (
    <>
      {!_.isEmpty(data) ? (
        <Box pb={4} id={id}>
          {data.title ? <Heading size='sm'>{data.title}</Heading> : null}
          <TableContainer maxW={`100%`} mt={6}>
            <ChakraTable style={{ borderCollapse: 'separate', borderSpacing: '0' }}>
              <Thead>
                <Tr>
                  {data.headers
                    ? data.headers.map((header: string | ITableColumnHeader, headerIdx: number) => {
                        let key = '';
                        let value: string | ReactElement = '';
                        const customStyle: ChakraStyledOptions = {};
                        if (typeof header === 'string') {
                          key = header;
                          value = header;
                        } else {
                          key = header.columnName;
                          value = header.columnName;

                          if (header.isNumeric) {
                            customStyle.textAlign = 'right';
                          }

                          if (header.help) {
                            value = (
                              <Tooltip label={header.help} placement='top'>
                                {value}
                              </Tooltip>
                            );
                          }
                        }

                        if (columnWidths[headerIdx] !== undefined) {
                          customStyle.width = columnWidths[headerIdx];
                        }

                        return (
                          <Th key={key} whiteSpace='break-spaces' verticalAlign='bottom' px={4} {...customStyle}>
                            {value}
                          </Th>
                        );
                      })
                    : null}
                </Tr>
              </Thead>
              <Tbody>
                {data.rows
                  ? data.rows.map((row: Array<string | number | ITableCellValue>, rowIdx: number) => {
                      const rowId = uuidv4();
                      let clickHandler;
                      let clsName = '';
                      if (typeof onClick === 'function') {
                        clickHandler = () => onClick(row);
                        clsName = rowIdx === hoverRow ? 'clickable' : '';
                      }

                      return (
                        <Tr
                          key={rowId}
                          onClick={clickHandler}
                          className={clsName}
                          onMouseEnter={() => setHoverRow(rowIdx)}
                          onMouseLeave={() => setHoverRow(null)}
                          _hover={{
                            svg: {
                              color: 'neutral.500',
                            },
                          }}
                        >
                          {row
                            ? row.map((column, colIdx: number) => {
                                const tdStyles: ChakraProps = {
                                  ...getCellStyle(row.length, rowIdx, colIdx, highlightedRowStart, highlightedRowEnd),
                                };

                                return (
                                  <TableCell
                                    key={colIdx}
                                    column={column}
                                    colIdx={colIdx}
                                    columnWidths={columnWidths}
                                    tdStyles={tdStyles}
                                  />
                                );
                              })
                            : null}
                        </Tr>
                      );
                    })
                  : null}
              </Tbody>
            </ChakraTable>
          </TableContainer>
        </Box>
      ) : null}
    </>
  );
};
