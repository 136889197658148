import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Box, Flex } from '@chakra-ui/react';
import { Card } from '@components/card';
import { DataLoadingErrorPanel } from '@components/errorAlert';
import { EmptyDataAlertPanel } from '@components/errorAlert';
import { Pagination } from '@components/pagination';
import { AsyncPaginationContainer } from '@components/pagination/AsyncPaginationContainer';
import { PaginationProps } from '@components/pagination/types';
import { Table } from '@components/table';
import { ITableData } from '@components/table/Table';
import { format } from 'date-fns';

import { Status } from '@/common/interfaces';
import { selectDashboardStatus } from '@/features/dashboard/slice';

import { FinancialReportsFilters } from '../components/FinancialReportsFilter';

import {
  selectAvailableAccounts,
  selectInvEndDate,
  selectInvoicesList,
  selectInvoicesListStatus,
  selectInvStartDate,
  setInvDateRange,
  setInvoicesListPage,
  setInvoicesListPaginationOptions,
  setSelectedAccounts,
} from './slice';

export const Invoices = () => {
  const dispatch = useAppDispatch();
  const dashboardStatus = useAppSelector(selectDashboardStatus);
  const loadingStatus = useAppSelector(selectInvoicesListStatus);
  const invoicesList = useAppSelector(selectInvoicesList);
  const startInvDate = useAppSelector(selectInvStartDate);
  const endInvDate = useAppSelector(selectInvEndDate);
  const availableAccounts = useAppSelector(selectAvailableAccounts);

  const handleDateDropdownChange = (dates: Date[]) => {
    const [start, end] = dates;
    const startDate = format(start, 'yyyy-MM-dd');
    const endDate = format(end, 'yyyy-MM-dd');
    dispatch(setInvoicesListPage(1));
    dispatch(setInvDateRange({ startDate, endDate }));
  };

  const handleFilterAccount = (accounts: string[]) => {
    dispatch(setSelectedAccounts(accounts));
  };

  function handlePageChange(page: number): void {
    dispatch(setInvoicesListPage(page));
  }

  function handlePageSizeChange(pageSize: number): void {
    dispatch(
      setInvoicesListPaginationOptions(Object.assign({}, invoicesList.paginationOptions, { pageSize: pageSize })),
    );
  }

  if (dashboardStatus === Status.Loading) {
    return null;
  }

  return (
    <Box padding={4}>
      {loadingStatus === Status.Failed && <DataLoadingErrorPanel />}
      {loadingStatus !== Status.Failed && (
        <>
          <Card variant='borderless' loadingStatus={loadingStatus}>
            <Flex justifyContent={'flex-end'}>
              <FinancialReportsFilters
                startDate={startInvDate}
                endDate={endInvDate}
                availableAccounts={availableAccounts}
                handleChangeDate={handleDateDropdownChange}
                handleFilterAccount={handleFilterAccount}
              />
            </Flex>

            <AsyncPaginationContainer
              data={invoicesList.invoices.table?.rows || []}
              page={invoicesList.page}
              total={invoicesList.invoices?.count}
              prevPage={invoicesList.invoices?.prevPage}
              nextPage={invoicesList.invoices?.nextPage}
              isLoading={loadingStatus === Status.Loading}
              options={invoicesList.paginationOptions}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            >
              {(props: PaginationProps) => {
                const { data } = props;
                const tableData: ITableData = Object.assign({}, invoicesList.invoices?.table, {
                  rows: data,
                });

                let content = <Box height='400px' />;
                if (data.length > 0) {
                  content = (
                    <>
                      <Table data={tableData} />
                      <Pagination {...props} />
                    </>
                  );
                } else {
                  content = <EmptyDataAlertPanel />;
                }

                return content;
              }}
            </AsyncPaginationContainer>
          </Card>
        </>
      )}
    </Box>
  );
};
