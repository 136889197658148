/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Button, Divider, Flex, Heading, Text, useToken } from '@chakra-ui/react';
import { BarDatum, BarItem, BarSvgProps } from '@nivo/bar';
import _ from 'lodash';

import { BarGraph } from '@/common/components/barGraph';
import { CustomLegends } from '@/common/components/customLegends';
import { EmptyDataAlert } from '@/common/components/errorAlert/EmptyDataAlert';
import { Status } from '@/common/interfaces';
import { displayPercent } from '@/common/utils';
import { IBarGraph } from '@/features/dashboard/types';
import i18n from '@/i18n';

import { buildByLocationsBarChartData } from './dataFormatting';
import { CapacityByLocationPopover } from './Popover';

/**
 * The purpose of the custom bar component is to render bars
 * without labels inside.
 */
const CustomBarComponent = (props: any) => {
  const newProps = Object.assign({}, props, { shouldRenderLabel: false });
  return <BarItem {...newProps} />;
};

interface ICapacityByLocationBarChart {
  byLocationData: undefined | IBarGraph;
  loadingStatus: Status;
}

export const paginateData = (locationsData: IBarGraph | undefined, start: number, end: number) => {
  let paginatedData = [];

  if (locationsData) {
    const temp = _.orderBy(
      locationsData,
      (row) => {
        return Number(row.non_upside_percentage) + Number(row.upside_percentage);
      },
      'desc',
    );
    paginatedData = temp.slice(start, end + 1).reverse();
  }

  return paginatedData;
};

export const ByLocationPaginatedBarChart = ({ byLocationData, loadingStatus }: ICapacityByLocationBarChart) => {
  const [dataGreen, dataBlue] = useToken('colors', ['green.400', 'blue.800']);
  const { t } = useTranslation();
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(24);

  const total = byLocationData ? byLocationData.length : 0;
  const isSmallData = total && total <= 25;
  const isLoading = loadingStatus === Status.Loading;
  const isEmpty = loadingStatus === Status.Idle && total === 0;

  const paginatedData = paginateData(byLocationData, start, end);

  const paginationPrevHandler = () => {
    setEnd(start - 1);
    setStart(start - 25 < 0 ? 0 : start - 25);
  };

  const paginationNxtHandler = () => {
    setStart(end + 1);
    setEnd(end + 25 > total ? total : end + 25);
  };

  const formatYAxis = (value: any) => {
    const formattedVal = displayPercent(value).split('.')[0];
    if (formattedVal === '') {
      return '';
    } else {
      return `${formattedVal}%`;
    }
  };

  const chartProps: Partial<BarSvgProps<BarDatum>> = {
    axisBottom: {
      format: (value: any) => formatYAxis(value),
      legend: t('capacityPage.byLocationSection.graph.axisBottomLegendLabel'),
      legendOffset: 40,
      legendPosition: 'middle',
      tickPadding: 5,
      tickRotation: 0,
      tickSize: 0,
    },
    axisLeft: {
      legend: t('capacityPage.byLocationSection.graph.axisLeftLegendLabel'),
      legendOffset: -160,
      legendPosition: 'middle',
      tickSize: 0,
      tickPadding: 5,
      tickRotation: 0,
    },
    barComponent: CustomBarComponent,
    colors: [dataBlue, dataGreen],
    enableLabel: false,
    enableGridY: false,
    enableGridX: true,
    groupMode: 'stacked',
    layers: ['legends', 'grid', 'axes', 'bars', 'markers', 'annotations'],
    layout: 'horizontal',
    legends: undefined,
    margin: { top: 0, right: 40, bottom: 70, left: 200 },
    maxValue: 1,
    padding: 0.33,
  };

  chartProps.tooltip = (point) => {
    return <CapacityByLocationPopover point={point} data={paginatedData} />;
  };

  const customLegend = [
    {
      label: i18n.t('capacityPage.byHourSection.graph.legend.avgTrxnsNoUpside'),
      color: dataBlue,
    },
    {
      label: i18n.t('capacityPage.byHourSection.graph.legend.avgTrxnsWithUpside'),
      color: dataGreen,
    },
  ];

  return (
    <>
      {isEmpty && <EmptyDataAlert />}
      {!isEmpty && (
        <Box width='100%'>
          <Heading
            as='h2'
            fontSize={'18px'}
            fontWeight={500}
            lineHeight={'28px'}
            textAlign='left'
            mb='6'
            opacity={isLoading ? '20%' : '100%'}
          >
            {t('capacityPage.byLocationSection.title')}
          </Heading>
          <Flex flexDirection={{ base: 'column', '2xl': 'row' }} gap={{ base: 6, xl: 4 }} w='99%'>
            <Flex
              align={isLoading ? 'center' : 'default'}
              justify={isLoading ? 'center' : 'default'}
              direction='column'
              alignItems={'flex-start'}
              width={{ base: '100%', '2xl': '70%' }}
              flexGrow={1}
            >
              <BarGraph
                data={buildByLocationsBarChartData(paginatedData)}
                loadingStatus={loadingStatus}
                title=''
                wideLegend={true}
                height={paginatedData.length >= 20 ? '650px' : '450px'}
                width='99%'
                customProps={chartProps}
              />
              <CustomLegends onChange={() => ''} options={customLegend} disabled={true} />

              <Divider />

              <Flex alignItems={'center'} justifyContent={'space-between'} w='100%'>
                <Text fontSize='xs'>
                  Showing {start + 1} to {end === total || total < 25 ? total : end + 1} of {total} locations
                </Text>
                <Flex pt={4} gap={3} px={2} opacity={isLoading ? '20%' : '100%'}>
                  <Button
                    isDisabled={isSmallData || start === 0}
                    rightIcon={<ChevronUpIcon boxSize={5} />}
                    onClick={paginationPrevHandler}
                    variant='accentSecondary'
                    size='sm'
                    lineHeight={0}
                  >
                    {t(`capacityPage.byHourSection.pagination.previous`)}
                  </Button>
                  <Button
                    rightIcon={<ChevronDownIcon boxSize={5} />}
                    onClick={paginationNxtHandler}
                    isDisabled={isSmallData || end === total || end + 1 > total}
                    variant='accentSecondary'
                    size='sm'
                    lineHeight={0}
                  >
                    {t(`capacityPage.byHourSection.pagination.next`)}
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      )}
    </>
  );
};
