import { defineStyle, defineStyleConfig } from '@chakra-ui/system';

const numericStyles = defineStyle({
  '&[data-is-numeric=true]': {
    textAlign: 'end',
    fontFeatureSettings: `'ss01'`,
  },
});

const sizes = {
  md: {
    th: {
      lineHeight: '4',
      fontSize: 'xs',
    },
    td: {
      lineHeight: '6',
      fontSize: 'md',
    },
  },
};

const TableStyles = defineStyleConfig({
  baseStyle: {
    thead: {
      tr: {
        th: {
          color: 'text.default',
          fontFamily: 'body',
          fontSize: 'sm',
          letterSpacing: 'sm',
          lineHeight: '8xl',
          paddingInlineEnd: '4',
          paddingInlineStart: '4',
          textTransform: 'none',
          fontWeight: '600',
          py: 3,
          px: 4,
          background: 'background.whitecap.light',
        },
      },
    },
    tbody: {
      tr: {
        td: {
          color: 'text.default',
          fontSize: 'sm',
          letterSpacing: 'sm',
          lineHeight: '12xl',
          paddingInlineEnd: '4',
          paddingInlineStart: '4',
          py: 3,
          px: 4,
        },
      },
    },
  },
  sizes,
  variants: {
    stripedEven: {
      th: {
        borderBottom: '1px',
        borderColor: 'border.subtle',
        ...numericStyles,
      },
      td: {
        ...numericStyles,
      },
      caption: {
        color: 'text.default',
      },
      tbody: {
        tr: {
          '&:nth-of-type(even)': {
            td: {
              background: 'background.whitecap.light',
            },
          },
          '&.clickable': {
            'th, td': {
              cursor: 'pointer',
            },
            td: {
              background: 'neutralwarm.100',
              cursor: 'pointer',
            },
          },
        },
      },
      tfoot: {
        tr: {
          '&:last-of-type': {
            th: { borderBottomWidth: 0 },
          },
        },
      },
    },
  },
  defaultProps: {
    variant: 'stripedEven',
    colorScheme: 'neutralwarm',
  },
});

export default TableStyles;
