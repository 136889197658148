import { useTranslation } from 'react-i18next';
import { Flex, Heading } from '@chakra-ui/react';

type TTableHeaderProps = {
  title: string;
  actionItems?: React.ElementType;
};

export function TableHeader({ title, actionItems: ActionItems }: TTableHeaderProps) {
  const { t } = useTranslation();
  return (
    <Flex w='full' justify='space-between' align='center' pr='3'>
      <Heading as='h2' size='md'>
        {t(title)}
      </Heading>
      {!!ActionItems && <ActionItems />}
    </Flex>
  );
}
