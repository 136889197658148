import { RootState } from '@app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format } from 'date-fns';

import { PaginationOptions } from '@/common/components/pagination';
import { Status } from '@/common/interfaces';
import { startOfMonth } from '@/common/utils/dates';

import { IStatementsList } from './types';

const today = new Date();
const currMonthStart = startOfMonth(today);
const prevMonthStart = new Date(currMonthStart);
prevMonthStart.setMonth(prevMonthStart.getMonth() - 1);
const last6MonthsStart = new Date(prevMonthStart);
last6MonthsStart.setMonth(last6MonthsStart.getMonth() - 5);
const last6MonthsEnd = today;

export interface StatementsState {
  statements: IStatementsList;
  paginationOptions: PaginationOptions;
  status: Status.Idle | Status.Loading | Status.Failed;
  error?: string;
  startDate: string;
  endDate: string;
}

export const initialState: StatementsState = {
  statements: {
    table: {
      rows: [],
    },
    rawData: [],
  },
  paginationOptions: {
    pageSize: 5,
    availablePageSizes: [5, 10, 50, 100, 250],
  },
  status: Status.Idle,
  error: undefined,
  startDate: format(last6MonthsStart, 'yyyy-MM-dd'),
  endDate: format(last6MonthsEnd, 'yyyy-MM-dd'),
};

export const statementsSlice = createSlice({
  name: 'statements',
  initialState,
  reducers: {
    statementsFetchRequest: (state) => {
      state.error = undefined;
      state.status = Status.Loading;
    },
    statementsFetchSucceeded: (state, action: PayloadAction<IStatementsList>) => {
      state.statements = action.payload;
      state.status = Status.Idle;
    },
    statementsFetchFailed: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.status = Status.Failed;
    },
    setStatementsDateRange: (state, action: PayloadAction<{ startDate: string; endDate: string }>) => {
      const { startDate, endDate } = action.payload;
      state.startDate = startDate;
      state.endDate = endDate;
    },
  },
});

export const { statementsFetchFailed, statementsFetchRequest, statementsFetchSucceeded, setStatementsDateRange } =
  statementsSlice.actions;

export const selectStatementsList = (state: RootState) => state.statements.statements;
export const selectStatementsListStatus = (state: RootState) => state.statements.status;
export const selectStatementsStartDate = (state: RootState) => state.statements.startDate;
export const selectStatementsEndDate = (state: RootState) => state.statements.endDate;
export const selectStatementsError = (state: RootState) => state.statements.error;
export const selectStatementsPaginationOptions = (state: RootState) => state.statements.paginationOptions;

export default statementsSlice.reducer;
