import { Box } from '@chakra-ui/react';

import { BannerStatContent } from './BannerStatContent';

export interface IStat {
  label?: string | undefined;
  value?: string | number | undefined;
}

export const BannerStat = ({ label, value, ...boxProps }: IStat) => {
  return (
    <Box
      w='100%'
      px={{ base: '4', md: '6' }}
      py={{ base: '5', md: '6' }}
      bg='background.whitecap.light'
      borderRadius='2xl'
      {...boxProps}
    >
      <BannerStatContent label={label} value={value} {...boxProps} />
    </Box>
  );
};
