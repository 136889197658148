import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';
import { Status, UserRoles } from '@/common/interfaces';

import { LocationSettingsData } from './types';

export interface ILocationsSettingsState {
  locations: LocationSettingsData[];
  fuelLocationFormData?: LocationSettingsData;
  error?: string;
  status: Status;
}

export const initialState: ILocationsSettingsState = {
  locations: [],
  status: Status.Idle,
  error: undefined,
};

export const locationsSettingsSlice = createSlice({
  name: 'locationsSettings',
  initialState,
  reducers: {
    setLoadingState: (state, action: PayloadAction<Status>) => {
      state.status = action.payload;
    },
    locationsSettingsFetchRequest: (
      state,
      _action: PayloadAction<{
        orgId: string;
        activeVertical: string;
        roles: UserRoles[];
      }>,
    ) => {
      state.error = undefined;
      state.status = Status.Loading;
    },
    locationsSettingsSucceeded: (state, action: PayloadAction<LocationSettingsData[]>) => {
      state.locations = action.payload;
      state.status = Status.Idle;
    },
    locationsSettingsFailed: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.status = Status.Failed;
    },
    addNewLocationRequest: (
      state,
      _action: PayloadAction<{
        orgId: string;
        activeVertical: string;
        roles: UserRoles[];
        location: LocationSettingsData;
      }>,
    ) => {
      state.error = undefined;
      state.status = Status.Loading;
    },
    addNewLocationSucceeded: (state) => {
      state.status = Status.Idle;
    },
    addNewLocationFailed: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.status = Status.Failed;
    },
    updateLocationRequest: (
      state,
      _action: PayloadAction<{
        orgId: string;
        activeVertical: string;
        roles: UserRoles[];
        location: LocationSettingsData;
        locationId: string;
      }>,
    ) => {
      state.error = undefined;
      state.status = Status.Loading;
    },
    updateLocationSucceeded: (state) => {
      state.status = Status.Idle;
    },
    updateLocationFailed: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.status = Status.Failed;
    },
    updateFuelLocationFormData: (state, action: PayloadAction<LocationSettingsData>) => {
      state.fuelLocationFormData = action.payload;
    },
    cleanFuelLocationFormData: (state) => {
      state.fuelLocationFormData = undefined;
    },
  },
});

export const {
  setLoadingState,
  locationsSettingsFetchRequest,
  locationsSettingsSucceeded,
  locationsSettingsFailed,
  addNewLocationRequest,
  addNewLocationSucceeded,
  addNewLocationFailed,
  updateLocationRequest,
  updateLocationSucceeded,
  updateLocationFailed,
  updateFuelLocationFormData,
  cleanFuelLocationFormData,
} = locationsSettingsSlice.actions;

export const selectLocationsSettings = (state: RootState) => state.locationsSettings.locations;
export const selectLocationsSettingsStatus = (state: RootState) => state.locationsSettings.status;
export const selectFuelLocationFormData = (state: RootState) => state.locationsSettings.fuelLocationFormData;

export default locationsSettingsSlice.reducer;
