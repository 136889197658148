import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiAlertTriangle } from 'react-icons/fi';
import { PlaidLinkOnSuccess } from 'react-plaid-link';
import { Link as RouterLink } from 'react-router-dom';
import {
  Button,
  Flex,
  Icon,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Td,
  Text,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { usePermissions } from '@/common/hooks';
import { selectActiveVertical, selectIsUpsideManager, selectUserOrg } from '@/features/auth/userSlice';

import { IPlaidToken, PlaidVerificationStatus, usePlaidLinkFunctions } from '../hooks';
import { deletePayoutRequest, updatePayoutRequest } from '../payoutMethod/slice';
import { IPaymentsRawData } from '../payoutMethod/types';

export function PayoutTableCell({
  method,
  methodsLength,
}: {
  method: IPaymentsRawData;
  methodsLength: number;
  sectionName: string;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const toast = useToast();
  const orgId = useAppSelector(selectUserOrg);
  const vertical = useAppSelector(selectActiveVertical);
  const isUpsideManager = useAppSelector(selectIsUpsideManager);

  const { onOpen, onClose, isOpen } = useDisclosure();

  const { hasPaymentsManagerAccess, hasPayoutManagerAccess } = usePermissions();

  const { account_holder_name, account_nickname, plaid_metadata, verified, uuid, link_token } = method;

  const payoutRole = useMemo(() => {
    if (hasPaymentsManagerAccess) {
      return 'PaymentsManager';
    }
    if (hasPayoutManagerAccess) {
      return 'PayoutManager';
    }
    if (isUpsideManager) {
      return 'UpsideManager';
    }
  }, [hasPaymentsManagerAccess, hasPayoutManagerAccess, isUpsideManager]);

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    async (_publicToken, metadata) => {
      // send public_token to your server
      // https://plaid.com/docs/api/tokens/#token-exchange-flow
      toast({
        title: 'Saving payout method...',
        status: 'info',
        duration: 2000,
        isClosable: true,
      });

      if (link_token && uuid) {
        dispatch(updatePayoutRequest({ orgId, role: payoutRole, vertical, uuid, metadata }));
      }
    },
    [dispatch, link_token, orgId, payoutRole, toast, uuid, vertical],
  );

  const { open, ready } = usePlaidLinkFunctions({ token: link_token ? link_token.link_token : null, onSuccess });

  const deletePayoutMethod = () => {
    if (!hasPaymentsManagerAccess && !hasPayoutManagerAccess && !isUpsideManager) {
      toast({
        title: 'Unauthorized',
        description: 'You are not authorized to perform this action',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    dispatch(deletePayoutRequest({ orgId, role: payoutRole, vertical, uuid }));
  };

  const verifyPlaidLink = (link_token?: IPlaidToken) => async () => {
    if (link_token && link_token.link_token && ready) {
      open();
    }
  };

  return (
    <Tr>
      <Td>{`${plaid_metadata.bank.name ? plaid_metadata.bank.name + ':' : ''} ${plaid_metadata.name} ending in ${
        plaid_metadata.mask
      }`}</Td>
      <Td textTransform='capitalize'>{plaid_metadata.subtype}</Td>
      <Td>{account_nickname ?? account_holder_name}</Td>
      <Td w='30%'>
        <Flex alignItems='center' justifyContent='flex-end' gap={4}>
          {!verified && plaid_metadata.verification_status === PlaidVerificationStatus.PENDING_AUTOMATIC_VERIFICATION && (
            <Flex alignItems='center' mr={4} color='text.danger.default'>
              <Icon as={FiAlertTriangle} mr={2} />
              Pending verification
            </Flex>
          )}

          {methodsLength > 1 && (
            <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} isLazy>
              <PopoverTrigger>
                <Link data-testid='remove-popover-anchor'>{t('payment.payoutMethod.hasAccounts.remove')}</Link>
              </PopoverTrigger>
              <Portal>
                <PopoverContent width={'350px'}>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>
                    {t('payment.payoutMethod.hasAccounts.removeModal.title', {
                      count: methodsLength,
                    })}
                  </PopoverHeader>
                  <PopoverBody>
                    <Text color='text.default-inverse'>
                      {t('payment.payoutMethod.hasAccounts.removeModal.description', {
                        count: methodsLength,
                      })}
                    </Text>
                  </PopoverBody>
                  {methodsLength > 1 && (
                    <PopoverFooter>
                      <Flex gap={2} justifyContent='flex-end'>
                        <Button variant='onDarkSecondary' size='sm' onClick={onClose}>
                          Cancel
                        </Button>
                        <Button
                          onClick={deletePayoutMethod}
                          variant='onDarkPrimary'
                          size='sm'
                          data-testid='remove-payout'
                        >
                          Remove
                        </Button>
                      </Flex>
                    </PopoverFooter>
                  )}
                </PopoverContent>
              </Portal>
            </Popover>
          )}

          {!verified && plaid_metadata.verification_status === PlaidVerificationStatus.PENDING_MANUAL_VERIFICATION && (
            <Link as={RouterLink} to={'#'} onClick={verifyPlaidLink(link_token)} color='text.danger.default'>
              {t('payment.payoutMethod.hasAccounts.pendingVerification')}
            </Link>
          )}

          {verified && (
            <Link as={RouterLink} to={`payout/manage/${uuid}`}>
              {t('payment.payoutMethod.hasAccounts.manage')}
            </Link>
          )}
        </Flex>
      </Td>
    </Tr>
  );
}
