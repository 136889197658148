import { Flex, Td, Text } from '@chakra-ui/react';

type TableCellObj = {
  value: string;
};

type TableCell = {
  data: string | TableCellObj;
  index?: number;
};

export const ProfileTableCell = ({ data }: TableCell) => {
  const value = typeof data === 'object' ? data.value : data;

  return (
    <Td>
      <Flex alignItems='center' gap={1}>
        <Text>{value}</Text>
      </Flex>
    </Td>
  );
};
