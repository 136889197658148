/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import { Box, Flex, Tooltip, useToken } from '@chakra-ui/react';
import { BarGraph, IBarProps } from '@components/barGraph';
import { BarItem } from '@nivo/bar';

import { display2Decimals, displayCurrency } from '@/common/utils';
import i18n from '@/i18n';

const formatChartValue = (value: any, isCurrency = true) => {
  if (isCurrency) {
    return displayCurrency(value, 0);
  } else {
    return display2Decimals(value, 2);
  }
};

// returns a list of total value labels for stacked bars
const TotalCurrencyLabels = (props: any) => {
  const { bars, yScale } = props;
  // space between top of stacked bars and total label
  const labelMargin = 20;

  return bars.map((innerProps: any, i: number) => {
    const {
      data: { indexValue },
      x,
      width,
    } = innerProps;
    const total = innerProps.data.value;

    return (
      <g transform={`translate(${x}, ${yScale(total) - labelMargin})`} key={`${indexValue}-${i}`}>
        <text
          x={width / 2}
          y={labelMargin / 2}
          textAnchor='middle'
          alignmentBaseline='central'
          style={{
            fill: 'rgb(51, 51, 51)',
            fontSize: '12px',
          }}
        >
          {displayCurrency(total, 0)}
        </text>
      </g>
    );
  });
};

const TotalTxnLabels = (props: any) => {
  const { bars, yScale } = props;
  // space between top of stacked bars and total label
  const labelMargin = 20;

  return bars.map((innerProps: any, i: number) => {
    const {
      data: { indexValue },
      x,
      width,
    } = innerProps;
    const total = innerProps.data.value;

    return (
      <g transform={`translate(${x}, ${yScale(total) - labelMargin})`} key={`${indexValue}-${i}`}>
        <text
          x={width / 2}
          y={labelMargin / 2}
          textAnchor='middle'
          alignmentBaseline='central'
          style={{
            fill: 'rgb(51, 51, 51)',
            fontSize: '12px',
          }}
        >
          {display2Decimals(total, 2)}
        </text>
      </g>
    );
  });
};

/**
 * The purpose of the custom bar component is to render bars
 * without labels inside.
 */
const CustomBarComponent = (props: any) => {
  const newProps = Object.assign({}, props, { shouldRenderLabel: false });
  return <BarItem {...newProps} />;
};

export const CustomerSegmentationBarChart = ({
  data,
  loadingStatus,
  title,
  width = '100%',
  dropdownMenuProps,
}: IBarProps) => {
  const { t } = useTranslation();
  const [dataBlue, dataGreen] = useToken('colors', ['blue.800', 'green.400']);

  const selectedMetric = dropdownMenuProps && dropdownMenuProps.length && dropdownMenuProps[0].selectedItem;
  const isSales = selectedMetric === 'sales';
  const chartProps = {
    margin: { top: 60, right: 40, bottom: 60, left: 45 },
    groupMode: 'grouped',
    colors: [dataBlue, dataGreen],
    padding: 0.33,
    barComponent: CustomBarComponent,
    tooltipFormat: (value: any) => formatChartValue(value, isSales),
    valueFormat: (value: string | number) => formatChartValue(value, isSales),
    axisBottom: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
    },
    axisLeft: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      format: (value: any) => formatChartValue(value, isSales),
      legendPosition: 'middle',
      legendOffset: -40,
      legend: isSales
        ? i18n.t('customersPage.customerAnalytics.customerSegmentationBarChart.yAxisLabel.salesYAxisLabel')
        : i18n.t('customersPage.customerAnalytics.customerSegmentationBarChart.yAxisLabel.transactionsYAxisLabel'),
    },
    layers: ['grid', 'axes', 'bars', isSales ? TotalCurrencyLabels : TotalTxnLabels, 'markers'],
  };

  return (
    <>
      <BarGraph
        data={data}
        loadingStatus={loadingStatus}
        title={title}
        width={width}
        wideLegend={true}
        customProps={chartProps}
        dropdownMenuProps={dropdownMenuProps}
      />
      {data ? (
        <Flex flexDirection='row' flexWrap='wrap' justifyContent='flex-start' pl={4} gap={8}>
          <Flex as='span' flexDirection='row' alignItems='center' gap={2} fontSize='12px'>
            <Box bgColor='data.blue' width='12px' height='12px' borderRadius='6px'></Box>
            <Tooltip
              label={t(`customersPage.customerAnalytics.customerSegmentationBarChart.beforeUpsideTooltip`)}
              placement='top'
            >
              {t(`customersPage.customerAnalytics.customerSegmentationBarChart.beforeUpside`)}
            </Tooltip>
          </Flex>
          <Flex as='span' flexDirection='row' alignItems='center' gap={2} fontSize='12px'>
            <Box bgColor='data.green' width='12px' height='12px' borderRadius='6px'></Box>
            <Tooltip
              label={t(`customersPage.customerAnalytics.customerSegmentationBarChart.afterUpsideTooltip`)}
              placement='top'
            >
              {t(`customersPage.customerAnalytics.customerSegmentationBarChart.afterUpside`)}
            </Tooltip>
          </Flex>
        </Flex>
      ) : null}
    </>
  );
};
