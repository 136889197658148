import { Global } from '@emotion/react';

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('./fonts/Inter/Inter-VariableFont_wght.ttf');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }

      @font-face {
        font-family: 'Gilmer';
        font-style: normal;
        font-weight: 100;
        font-display: swap;
        src: url('./fonts/Gilmer/Webfont/WOFF2/Gilmer-Thin.woff2'),
             url('./fonts/Gilmer/Webfont/WOFF/Gilmer-Thin.woff'),;
      }

      @font-face {
        font-family: 'Gilmer';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url('./fonts/Gilmer/Webfont/WOFF2/Gilmer-Light.woff2'),
             url('./fonts/Gilmer/Webfont/WOFF/Gilmer-Light.woff'),;
      }

      @font-face {
        font-family: 'Gilmer';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('./fonts/Gilmer/Webfont/WOFF2/Gilmer-Regular.woff2'),
             url('./fonts/Gilmer/Webfont/WOFF/Gilmer-Regular.woff'),;
      }

      @font-face {
        font-family: 'Gilmer';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url('./fonts/Gilmer/Webfont/WOFF2/Gilmer-Medium.woff2'),
             url('./fonts/Gilmer/Webfont/WOFF/Gilmer-Medium.woff'),;
      }

      @font-face {
        font-family: 'Gilmer';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('./fonts/Gilmer/Webfont/WOFF2/Gilmer-Bold.woff2'),
             url('./fonts/Gilmer/Webfont/WOFF/Gilmer-Bold.woff'),;
      }

      @font-face {
        font-family: 'Gilmer';
        font-style: normal;
        font-weight: 900;
        font-display: swap;
        src: url('./fonts/Gilmer/Webfont/WOFF2/Gilmer-Heavy.woff2'),
             url('./fonts/Gilmer/Webfont/WOFF/Gilmer-Heavy.woff'),;
      }
      `}
  />
);

export default Fonts;
