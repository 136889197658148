import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiAlertTriangle } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';
import {
  Box,
  ChakraStyledOptions,
  Icon,
  Link,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  Portal,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { format as formatDate, isBefore } from 'date-fns';

import { useAppSelector } from '@/app/hooks';
import { Verticals } from '@/common/interfaces';
import { selectActiveVertical, selectAuthRequestPath, selectUserRoles } from '@/features/auth/userSlice';

import { parseUTCDate } from '../../../../common/utils/dates';
import { downloadInvoice } from '../invoices/api';
import { downloadStatement } from '../statements/api';

interface DownloadProps {
  uuid: string;
}

export const DownloadInvoicePopover = ({ uuid }: DownloadProps) => {
  const { t } = useTranslation();
  const { onToggle } = useDisclosure();
  const toast = useToast();
  const authRequestPath = useAppSelector(selectAuthRequestPath);
  const activeVertical = useAppSelector(selectActiveVertical);
  const roles = useAppSelector(selectUserRoles);
  const invoiceData = useAppSelector(
    (state) =>
      state.invoices.invoicesList.invoices.rawData.find((i) => i.invoice_uuid === uuid) ??
      state.payouts.payouts.rawData.find((i) => i.invoice_uuid === uuid),
  );

  const statement = useAppSelector((state) => state.statements.statements.rawData.find((i) => i.period === uuid));

  const location = useLocation();
  const path = t(location.pathname.split('/')[location.pathname.split('/').length - 1]).slice(0, -1);

  const prepareFile = ({ base64, format, filename }: { base64: string; format: string; filename: string }) => {
    try {
      if (!base64) {
        throw new Error('No data to download');
      }

      const urlFormat =
        format === 'pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

      toast({
        title: t('invoicesPage.invoicesTbl.downloads.toast.downloadTitle'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      });

      const linkSource = `data:${urlFormat};base64,${base64}`;

      const downloadLink = document.createElement('a');

      downloadLink.href = linkSource;
      downloadLink.download = filename;
      downloadLink.click();

      onToggle();
    } catch (error) {
      toast({
        title: t('invoicesPage.invoicesTbl.downloads.toast.error'),
        status: 'error',
        duration: 3000,
      });
    }
  };

  const handleDownloadInvoiceOrPayout = async (format: string) => {
    if (!invoiceData) {
      toast({
        title: t('invoicesPage.invoicesTbl.downloads.toast.error'),
        status: 'error',
        duration: 3000,
      });

      return;
    }

    toast({
      title: t('invoicesPage.invoicesTbl.downloads.toast.startTitle'),
      status: 'info',
      duration: 3000,
    });

    const base64 = await downloadInvoice({ authRequestPath, invoice_uuid: invoiceData.invoice_uuid, format, roles });

    const date = formatDate(new Date(invoiceData?.period_end_timestamp ?? ''), 'MM_dd_yyyy');
    const filename = `Upside ${path} ${invoiceData?.billable_entity_display_name} - ${date}.${format}`;

    prepareFile({ base64, format, filename });
  };

  const handleStatementsDownload = async (format: string) => {
    if (!statement) {
      toast({
        title: t('statementsPage.statementsTbl.downloads.toast.error'),
        status: 'error',
        duration: 3000,
      });

      return;
    }

    toast({
      title: t('statementsPage.statementsTbl.downloads.toast.startTitle'),
      status: 'info',
      duration: 4000,
    });

    const base64 = await downloadStatement({
      authRequestPath,
      period: statement.period,
      format,
      roles,
    });

    let periodDate = new Date();
    if (statement.period) {
      periodDate = parseUTCDate(`${statement.period}-01T00:00:00Z`);
    }
    const date = formatDate(periodDate, 'MM_yyyy');
    const filename = `Upside ${path} - ${date}.${format}`;

    prepareFile({ base64, format, filename });
  };

  const handleDownload = async (format: string) => {
    if (path === 'Invoice' || path === 'Payout') {
      await handleDownloadInvoiceOrPayout(format);
      return;
    }

    if (path === 'Statement') {
      await handleStatementsDownload(format);
      return;
    }
  };

  const isBeforeHistoricalCutOff = useMemo(() => {
    if (!statement) {
      return false;
    }

    const historicalCutOff = new Date(2020, 0);

    return isBefore(new Date(statement.period), historicalCutOff);
  }, [statement]);

  const isInvoice =
    path === 'Invoice' && (activeVertical === Verticals.Fuel || activeVertical === Verticals.Restaurant);
  const isStatement = path === 'Statement' && activeVertical === Verticals.Fuel;

  const isPayouts = path === 'Payout' && (activeVertical === Verticals.Fuel || activeVertical === Verticals.Restaurant);

  return (
    <Portal>
      <PopoverContent boxShadow='base' borderRadius='lg' bg='background.default'>
        <PopoverCloseButton />
        <PopoverBody pt={6}>
          {invoiceData?.download_enabled === false ? (
            <Text>{t('invoicesPage.invoicesTbl.downloads.popover.notEnabled')}</Text>
          ) : (
            <>
              {!isBeforeHistoricalCutOff && (isInvoice || isStatement || isPayouts) && (
                <Box onClick={() => handleDownload('pdf')} {...styles}>
                  {t(`invoicesPage.invoicesTbl.downloads.pdf`) as string}
                </Box>
              )}

              <Box onClick={() => handleDownload('xlsx')} {...styles}>
                {t(`invoicesPage.invoicesTbl.downloads.excel`) as string}
              </Box>
            </>
          )}
        </PopoverBody>
      </PopoverContent>
    </Portal>
  );
};

export const DownloadInvoiceLink = ({ uuid }: DownloadProps) => {
  const invoiceData = useAppSelector(
    (state) =>
      state.invoices.invoicesList.invoices.rawData.find((i) => i.invoice_uuid === uuid) ??
      state.payouts.payouts.rawData.find((i) => i.invoice_uuid === uuid),
  );

  if (invoiceData?.download_enabled === false) {
    return <Icon as={FiAlertTriangle} color='text.danger.default' mx='auto' boxSize={5} />;
  }

  return <Link>Download</Link>;
};

const styles: ChakraStyledOptions = {
  cursor: 'pointer',
  color: 'text.default',
  borderRadius: 'full',
  px: '2',
  py: '1',
  w: 'full',
  _hover: {
    bg: 'blue.50',
  },
  _checked: {
    bg: 'blue.75',
  },
  _focus: {
    boxShadow: 'outline',
  },
};
