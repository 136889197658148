/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/react';

import { Verticals } from '@/common/interfaces';
import { axiosInstance } from '@/common/utils';
import { ENV_CONFIG } from '@/config';

import { capacityResponse } from './__mocks__/mockCapacity';

export const fetchCapacity = async ({
  startDate,
  endDate,
  authRequestPath,
  networkNames,
  locations,
  activeVertical,
}: {
  startDate: string;
  endDate: string;
  authRequestPath: string;
  networkNames: string[];
  locations: string[];
  activeVertical: Verticals;
}) => {
  if (ENV_CONFIG.TIER === 'alpha' && activeVertical === Verticals.Restaurant) return;

  let result: any = '';
  const url = new URL(`${ENV_CONFIG.API_URL}/capacity/${authRequestPath}`);
  url.searchParams.append('startDate', startDate);
  url.searchParams.append('endDate', endDate);

  try {
    result = await axiosInstance.post(url.toString(), { networkNames, locations });
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
    throw error;
  }

  return result.data;
};

// A mock function to mimic making an async request for data
export const mockFetchCapacity = () => {
  return new Promise<any>((resolve) => setTimeout(() => resolve(capacityResponse), 500));
};
