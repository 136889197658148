import { Box, Text } from '@chakra-ui/react';

import { DateRangePreset } from '../types';

import { DateRangePresetItem } from './DateRangePresetItem';

interface DateRangePresetProps {
  selectedPresetId?: string;
  maxDate?: Date;
  presets: DateRangePreset[];
  wide?: boolean;
  onPresetSelect(id: string, start: Date, end: Date, isCustom: boolean): void;
}

export const DateRangePresetMenu = (props: DateRangePresetProps) => {
  const { maxDate, selectedPresetId, wide } = props;

  const handleSelect = (id: string, start: Date, end: Date, isCustom: boolean) => {
    if (maxDate) {
      if (maxDate < start) {
        return;
      }
      if (maxDate < end) {
        end = maxDate;
      }
    }
    props.onPresetSelect(id, start, end, isCustom);
  };

  return (
    <Box width={wide ? '268px' : '168px'}>
      {props.presets.map((preset, idx: number) => {
        const disabled = Boolean(maxDate && preset.start && maxDate < preset.start);
        const selected = Boolean(selectedPresetId) && selectedPresetId === preset.id;

        return (
          <DateRangePresetItem key={idx} {...preset} selected={selected} onSelect={handleSelect} disabled={disabled}>
            <Text>{preset.label}</Text>
          </DateRangePresetItem>
        );
      })}
    </Box>
  );
};
