import { useAppSelector } from '@app/hooks';
import _ from 'lodash';

import { Verticals } from '@/common/interfaces';
import {
  selectActiveMerchantUuid,
  selectActiveUserUuid,
  selectActiveVertical,
  selectIsParentOrg,
  selectIsUpsider,
  selectUserOrg,
  selectUserRoles,
} from '@/features/auth/userSlice';
import { selectDateRange, selectSelectedTransactionType } from '@/features/dashboard/slice';

export const useFetchDataCheck = (apiName: string, needTxType: boolean, needDatePicker: boolean) => {
  const isUpsider = useAppSelector(selectIsUpsider);
  const isParentOrg = useAppSelector(selectIsParentOrg);
  const activeMerchantUuid = useAppSelector(selectActiveMerchantUuid);
  const activeUserUuid = useAppSelector(selectActiveUserUuid);
  const activeVertical = useAppSelector(selectActiveVertical);
  const orgId = useAppSelector(selectUserOrg);
  const roles = useAppSelector(selectUserRoles);

  const selectedTransactionType = useAppSelector(selectSelectedTransactionType);
  const { startDate, endDate } = useAppSelector(selectDateRange);

  if (needTxType) {
    if (selectedTransactionType === undefined) {
      // console.debug(`${apiName} not ready to fetch data`);
      return false;
    }
  }

  if (needDatePicker) {
    if (_.isEmpty(startDate) || _.isEmpty(endDate)) {
      // console.debug(`${apiName} not ready to fetch data`);
      return false;
    }
  }

  if (
    orgId === '' ||
    _.isEmpty(roles) ||
    activeVertical === Verticals.Unknown ||
    ((isUpsider || isParentOrg) && activeMerchantUuid === '' && activeUserUuid === '')
  ) {
    // console.debug(`${apiName} not ready to fetch data`);
    return false;
  }

  // console.debug(`${apiName} ready to fetch data`);
  return true;
};
