import { ReactText } from 'react';
import { NavLink } from 'react-router-dom';
import { Flex, FlexProps, Icon, Text } from '@chakra-ui/react';

interface NavItemProps extends FlexProps {
  path: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: React.ElementType<any>;
  children: ReactText;
}

export const NavItem = ({ path, icon, children, ...rest }: NavItemProps) => {
  return (
    <NavLink to={path}>
      {({ isActive }) => (
        <Flex
          align='center'
          py='2'
          px='3'
          mx='4'
          role='group'
          borderRadius='99px'
          _hover={
            isActive
              ? {}
              : {
                  bg: 'green.700',
                  color: 'text.default-inverse',
                }
          }
          bg={isActive ? 'green.700' : 'green.1000'}
          color={'text.default-inverse'}
          data-component-name='nav-item'
          {...rest}
        >
          {icon && (
            <Icon
              as={icon}
              sx={
                isActive
                  ? {}
                  : {
                      '[data-component-name="nav-item"]:hover &': {
                        color: 'green.50',
                      },
                    }
              }
              color={'green.50'}
              boxSize='1.5em'
              mr='2'
            />
          )}
          <Text textStyle='subhead.md' color='text.default-inverse' fontWeight={500}>
            {children}
          </Text>
        </Flex>
      )}
    </NavLink>

    // TODO: investigate fix: Type '({ isActive }: { isActive: boolean; }) => JSX.Element' is not assignable to type 'ReactI18NextChild | Iterable<ReactI18NextChild>'.ts(2322)
    // The error above is thrown on the code below. Something with Chakra's Link as NavLink mixed with react-i18next?
    // Awaiting responses on StackOverflow

    // <Link as={NavLink} to={path} style={{ textDecoration: 'none' }} cursor='pointer'>
    //   {({ isActive }) => (
    //     <Flex
    //       align='center'
    //       p='4'
    //       mx='4'
    //       role='group'
    //       borderRadius='99px'
    //       _hover={{
    //         bg: 'purple.50',
    //         color: 'background.primary.active',
    //       }}
    //       bg={isActive ? 'purple.50' : undefined}
    //       color={isActive ? 'background.primary.active' : 'neutral.600'}
    //       {...rest}
    //     >
    //       {icon && <Image src={icon} boxSize='25px' mr='4' />}
    //       <Text fontSize='16px' fontWeight={400}>
    //         {children}
    //       </Text>
    //     </Flex>
    //   )}
    // </Link>
  );
};
