import * as Sentry from '@sentry/react';

import { TransactionTypes } from '@/common/interfaces';
import { addSearchParamIfValueNotNull, transactionTypeForApiCall } from '@/common/utils';
import { axiosInstance } from '@/common/utils';

import { ENV_CONFIG } from '../../config';

import { TMasqueradingResponse, TParentOrgConfigResponse } from './components/merchantSelector/types';
import { TemporalUnit } from './slice';
import { TGeneralConfigs, THeaderData } from './types';

export const fetchHeaderData = async ({ authRequestPath }: { authRequestPath: string }) => {
  try {
    const response = await axiosInstance.post<THeaderData>(`/merchant/filters/${authRequestPath}`, {});

    return response;
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
  }
};

export const fetchGeneralConfigs = async ({ authRequestPath }: { authRequestPath: string }) => {
  try {
    const response = await axiosInstance.get<TGeneralConfigs>(`/merchant/configs/${authRequestPath}`, {});

    return response;
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
  }
};

export const fetchMasqueradingLists = async ({ authRequestPath }: { authRequestPath: string }) => {
  try {
    const response = await axiosInstance.get<TMasqueradingResponse>(
      `/merchant/upside/masquerading/${authRequestPath}`,
      {},
    );

    return response;
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
    throw error;
  }
};

export const fetchParentOrgConfig = async ({ authRequestPath }: { authRequestPath: string }) => {
  try {
    const response = await axiosInstance.get<TParentOrgConfigResponse>(`/merchant/parentOrg/${authRequestPath}`, {});

    return response;
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
    throw error;
  }
};

export const fetchPerformanceSummaryData = async ({
  startDate,
  endDate,
  temporalUnit,
  authRequestPath,
  networkNames,
  transactionType,
  locations,
}: {
  startDate: string;
  endDate: string;
  temporalUnit: TemporalUnit;
  authRequestPath: string;
  networkNames: string[];
  transactionType: TransactionTypes;
  locations: string[];
  isPromotionPage?: boolean;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let result: any = '';

  const url = new URL(`${ENV_CONFIG.API_URL}/performance_summary/${authRequestPath}`);
  url.searchParams.append('startDate', startDate);
  url.searchParams.append('endDate', endDate);
  url.searchParams.append('temporalUnit', temporalUnit);
  addSearchParamIfValueNotNull(url, 'transactionType', transactionTypeForApiCall(transactionType));

  try {
    result = await axiosInstance.post(url.toString(), {
      networkNames,
      locations,
    });
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
    throw error;
  }
  return result.data;
};
