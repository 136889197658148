export function downloadFile(
  base64: string,
  metadata: { startDate: string; endDate: string; granularity: string },
  fileType: string,
) {
  const mimeType = (() => {
    switch (fileType) {
      case 'csv':
        return 'text/csv';
      case 'xlsx':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      default:
        return 'text/csv';
    }
  })();
  const fileName = `upside_${
    metadata.granularity === 'transaction' ? 'transaction' : 'site'
  }_export_${metadata.startDate.replace(/-/g, '')}_${metadata.endDate.replace(/-/g, '')}.${fileType}`;
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  const blob = new Blob([byteArray], { type: mimeType });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
