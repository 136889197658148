import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  container: {
    backgroundColor: 'background.default',
    p: 5,
    my: 4,
    borderRadius: '2xl',
    borderWidth: '1px',
    borderColor: 'border.subtle',
    boxShadow: 'none',
  },
});

const variants = {
  topBorder: definePartsStyle({
    container: {
      borderRadius: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: 'none',
      borderTopWidth: '2px',
      borderTopColor: 'border.subtle',
      px: 1,
    },
  }),
  borderless: definePartsStyle({
    container: {
      borderRadius: 'none',
      border: 'none',
      px: 1,
    },
  }),
};

const cardTheme = defineMultiStyleConfig({ baseStyle, variants });

export default cardTheme;
