import { IStat } from '@components/stat';
import { ITableCellValue, ITableData } from '@components/table/Table';
import { DefaultRawDatum } from '@nivo/pie';
import _ from 'lodash';

import { PaymentMethodTypes, TransactionTypes, Verticals } from '@/common/interfaces';
import {
  display2Decimals,
  displayCurrency,
  displayDate,
  displayGasType,
  displayNumber,
  displayPercent,
  displayRelativeTime,
  displayTransactionType,
  displayUTCDate,
  displayUUID,
  displayUuid,
  EMPTY_CELL_VALUE,
  fnSegmentOrder,
  formatDateByTemporalUnit,
  SEGMENT_COLORS,
} from '@/common/utils';
import i18n from '@/i18n';

import { TemporalUnit } from '../../dashboard/slice';
import { IBarGraph } from '../../dashboard/types';

import { TOTAL_CONTROL_SALES_SERIES_ID, TOTAL_CUSTOMER_SALES_SERIES_ID } from './CustomerVsControlChart';
import {
  CustomerVsControlChartProps,
  ICustomerData,
  ICustomerPaymentMethodRaw,
  ICustomerSegmentationCustomersCountRow,
  ICustomerSegmentationPerformanceSummaryRow,
  ICustomerSegmentationSummary,
  ICustomerSegmentationSummaryRawData,
  ICustomersRawData,
  ICustomerSummary,
  ICustomerSummaryRawData,
  ICustomerSummaryROIFlowthrough,
  ICustomerTransaction,
  ICustomerTransactionsRawData,
  ICustomerVsControlEntryRaw,
  ICustomerVsControlSeries,
  IPerformanceSummaryBySegmentRow,
  ISalesAcrossSegmentsRow,
} from './types';
import { CUSTOMER_ACQUISITION_METRICS } from '.';

export const buildCustomersTable = (data: ICustomersRawData, activeVertical: Verticals): ITableData => {
  const isGroceryUser = activeVertical === Verticals.Grocery;
  const rows: Array<string | number | ITableCellValue>[] = data.records.map((item: ICustomerData) => {
    return [
      {
        value: displayUuid(item.user_uuid),
        rawValue: item.user_uuid,
      },
      ...(isGroceryUser ? [item.code] : []),
      ...(data.has_loyalty_program ? [item.has_loyalty_transaction ? i18n.t('common.yes') : i18n.t('common.no')] : []),
      displayRelativeTime(item.last_transacted_date),
      displayDate(item.first_join_date),
      {
        value: item.num_trxns_total,
        isNumeric: true,
      },
      {
        value: displayCurrency(item.total_revenue_total),
        isNumeric: true,
      },
      {
        value: displayCurrency(item.total_incremental_revenue_total),
        isNumeric: true,
      },
      {
        value: displayCurrency(item.net_profit_total),
        isNumeric: true,
      },
    ];
  });

  const result: ITableData = {
    headers: [
      { columnName: i18n.t('customerDeepDivePage.home.customersTbl.customer') },
      ...(isGroceryUser
        ? [
            {
              columnName: i18n.t('customerDeepDivePage.home.customersTbl.referralCode'),
            },
          ]
        : []),
      ...(data.has_loyalty_program
        ? [{ columnName: i18n.t('customerDeepDivePage.home.customersTbl.matchedLoyaltyUser') }]
        : []),
      {
        columnName: i18n.t('customerDeepDivePage.home.customersTbl.lastUpsideTxn'),
      },
      {
        columnName: i18n.t('customerDeepDivePage.home.customersTbl.firstUpsideTxn'),
      },
      {
        columnName: i18n.t('customerDeepDivePage.home.customersTbl.upsideTxns'),
        isNumeric: true,
      },
      {
        columnName: i18n.t('customerDeepDivePage.home.customersTbl.upsideRevenue'),
        isNumeric: true,
      },
      {
        columnName: i18n.t('customerDeepDivePage.home.customersTbl.incrementalRevenue'),
        isNumeric: true,
      },
      {
        columnName: i18n.t('customerDeepDivePage.home.customersTbl.incrementalNetProfit'),
        isNumeric: true,
      },
    ],
    rows: rows,
  };

  return result;
};

export const buildRecentTransactionsTable = (
  data: ICustomerTransactionsRawData,
  activeVertical: Verticals,
): ITableData => {
  if (activeVertical === Verticals.Fuel) {
    const hasItemDisplayName = data.records.some((item: ICustomerTransaction) => item.item_display_name);
    const gallonCountPrecision = 1; // number of decimals shown in "Gallons" column
    return {
      headers: [
        { columnName: i18n.t('components.transactionsTable.date') },
        { columnName: i18n.t('components.transactionsTable.address') },
        { columnName: i18n.t('components.transactionsTable.customer') },
        { columnName: i18n.t('components.transactionsTable.levelOfIncrementality') },
        { columnName: i18n.t('components.transactionsTable.type') },
        { columnName: i18n.t('components.transactionsTable.grade') },
        ...(hasItemDisplayName ? [{ columnName: i18n.t('components.transactionsTable.itemDisplayName') }] : []),
        {
          columnName: i18n.t('components.transactionsTable.gallons'),
          isNumeric: true,
        },
        {
          columnName: i18n.t('components.transactionsTable.margin'),
          isNumeric: true,
        },
        {
          columnName: i18n.t('components.transactionsTable.totalProfit'),
          isNumeric: true,
        },
        {
          columnName: i18n.t('components.transactionsTable.incrementalProfit'),
          isNumeric: true,
        },
        {
          columnName: i18n.t('components.transactionsTable.promotion'),
          isNumeric: true,
        },
        {
          columnName: i18n.t('components.transactionsTable.fee'),
          isNumeric: true,
        },
        {
          columnName: i18n.t('components.transactionsTable.netProfit'),
          isNumeric: true,
        },
        {
          columnName: i18n.t('components.transactionsTable.roi'),
          isNumeric: true,
        },
      ],
      rows: data.records.map((item: ICustomerTransaction) => {
        const isFuelTransaction = item.transaction_type === 'GAS';
        const isPartiallyIncremental =
          item.total_revenue > item.total_incremental_revenue && Number(item.total_incremental_revenue) > 0;
        const isNotIncremental = item.total_incremental_revenue === 0;
        const isFullyIncremental = item.total_revenue === item.total_incremental_revenue;
        const isOutlierTransaction = item.is_outlier_transaction;

        const displayROI = +item.return_on_investment !== 0 && !isOutlierTransaction;

        const incrementalityText = () => {
          if (isOutlierTransaction) return i18n.t('components.transactionsTable.incrementalityTag.outlier');
          if (isPartiallyIncremental) return i18n.t('components.transactionsTable.incrementalityTag.partial');
          if (isNotIncremental) return i18n.t('components.transactionsTable.incrementalityTag.notIncremental');
          if (isFullyIncremental) return i18n.t('components.transactionsTable.incrementalityTag.full');
          return '';
        };

        return [
          { value: displayUTCDate(item.transacted_at) },
          { value: item.site_address_1 },
          { value: displayUUID(item.user_uuid), rawValue: item.user_uuid },
          {
            value: incrementalityText(),
            outlierInfo: isOutlierTransaction ? i18n.t('components.transactionsTable.outlier.popover') : '',
          },
          { value: displayTransactionType(item.transaction_type) },
          { value: displayGasType(item.gas_type), centerEmpty: true },
          ...(hasItemDisplayName ? [{ value: item.item_display_name ?? EMPTY_CELL_VALUE }] : []),
          {
            value: isFuelTransaction ? display2Decimals(item.gallons_bought, gallonCountPrecision) : EMPTY_CELL_VALUE,
            isNumeric: isFuelTransaction,
            centerEmpty: true,
          },
          {
            value: isFuelTransaction ? displayCurrency(item.margin_detail.margin) : EMPTY_CELL_VALUE,
            isNumeric: isFuelTransaction,
            popoverData: isFuelTransaction ? item.margin_detail : undefined,
            centerEmpty: true,
          },
          { value: displayCurrency(item.total_profit), isNumeric: true },
          {
            value: displayCurrency(item.total_incremental_profit),
            isNumeric: true,
          },
          {
            value: displayCurrency(item.total_promotion_cost),
            isNumeric: true,
          },
          { value: displayCurrency(item.total_fee_cost), isNumeric: true },
          { value: displayCurrency(item.net_profit), isNumeric: true },
          {
            value: displayROI ? displayPercent(item.return_on_investment) : EMPTY_CELL_VALUE,
            isNumeric: isFuelTransaction,
            centerEmpty: true,
          },
        ];
      }),
      columnWidths: [
        '96px',
        '116px',
        '91px',
        '79px',
        '79px',
        '68px',
        ...(hasItemDisplayName ? ['96px'] : []),
        '96px',
        '77px',
        '94px',
        '89px',
        '76px',
        '73px',
        '77px',
      ],
    };
  }

  return {
    headers: [
      { columnName: i18n.t('components.transactionsTable.date') },
      { columnName: i18n.t('components.transactionsTable.address') },
      { columnName: i18n.t('components.transactionsTable.customer') },
      ...(activeVertical === Verticals.Restaurant
        ? [{ columnName: i18n.t('components.transactionsTable.levelOfIncrementality') }]
        : []),
      {
        columnName: i18n.t('components.transactionsTable.totalTransaction'),
        isNumeric: true,
      },
      {
        columnName: i18n.t('components.transactionsTable.totalSales'),
        isNumeric: true,
      },
      {
        columnName: i18n.t('components.transactionsTable.incrementalSales'),
        isNumeric: true,
      },
      {
        columnName: i18n.t('components.transactionsTable.incrementalProfit'),
        isNumeric: true,
      },
      {
        columnName: i18n.t('components.transactionsTable.promotion'),
        isNumeric: true,
      },
      {
        columnName: i18n.t('components.transactionsTable.netProfit'),
        isNumeric: true,
      },
      {
        columnName: i18n.t('components.transactionsTable.roi'),
        isNumeric: true,
      },
    ],
    rows: data.records.map((item: ICustomerTransaction) => {
      const isPartiallyIncremental =
        item.total_revenue > item.total_incremental_revenue && Number(item.total_incremental_revenue) > 0;
      const isNotIncremental = item.total_incremental_revenue === 0;
      const isFullyIncremental = item.total_revenue === item.total_incremental_revenue;

      return [
        { value: displayUTCDate(item.transacted_at) },
        { value: item.site_address_1 },
        { value: displayUUID(item.user_uuid), rawValue: item.user_uuid },
        ...(activeVertical === Verticals.Restaurant
          ? [
              {
                value:
                  (isPartiallyIncremental &&
                    (i18n.t('components.transactionsTable.incrementalityTag.partial') as string)) ||
                  (isNotIncremental &&
                    (i18n.t('components.transactionsTable.incrementalityTag.notIncremental') as string)) ||
                  (isFullyIncremental && (i18n.t('components.transactionsTable.incrementalityTag.full') as string)) ||
                  '',
              },
            ]
          : []),
        {
          value: displayCurrency(item.total_transaction_amount),
          isNumeric: true,
        },
        {
          value: displayCurrency(item.total_revenue),
          isNumeric: true,
        },
        {
          value: displayCurrency(item.total_incremental_revenue),
          isNumeric: true,
        },
        {
          value: displayCurrency(item.total_incremental_profit),
          isNumeric: true,
        },
        { value: displayCurrency(item.total_promotion_cost), isNumeric: true },
        { value: displayCurrency(item.net_profit), isNumeric: true },
        {
          value: +item.return_on_investment !== 0 ? displayPercent(item.return_on_investment) : EMPTY_CELL_VALUE,
          isNumeric: true,
          centerEmpty: true,
        },
      ];
    }),
    columnWidths: ['100px', '139px', '89px', '109px', '104px', '110px', '108px', '104px', '76px', '83px', '77px'],
  };
};

export const buildIndividualUserUsageTable = (
  data: ICustomerSummaryRawData,
  activeVertical: Verticals,
  transactionType?: TransactionTypes,
): ITableData => {
  const isFuelUser =
    activeVertical === Verticals.Fuel &&
    (!transactionType || transactionType === TransactionTypes.Gas || transactionType === TransactionTypes.All);

  const rows: Array<string | number | ITableCellValue>[] = [
    [
      i18n.t('customerDeepDivePage.individualUserUsageTbl.firstTxnDate') as string,
      displayDate(data.individual_user_usage.first_txn_date),
    ],
    [
      i18n.t('customerDeepDivePage.individualUserUsageTbl.lastUpsideTxn') as string,
      displayDate(data.individual_user_usage.most_recent_txn_date),
    ],
    [
      i18n.t('customerDeepDivePage.individualUserUsageTbl.totalUpsideTxns') as string,
      data.individual_user_usage.total_txn,
    ],
    [
      i18n.t('customerDeepDivePage.individualUserUsageTbl.salesOnUpside') as string,
      displayCurrency(data.individual_user_usage.total_revenue),
    ],
    isFuelUser
      ? [
          i18n.t('customerDeepDivePage.individualUserUsageTbl.avgGallonsPurchased') as string,
          displayNumber(+data.individual_user_usage.gallons_per_transaction),
        ]
      : [
          i18n.t('customerDeepDivePage.individualUserUsageTbl.avgUpsideBasketSize') as string,
          displayCurrency(data.individual_user_usage.avg_basket_size),
        ],
    [
      i18n.t('customerDeepDivePage.individualUserUsageTbl.totalCashBack') as string,
      displayCurrency(data.individual_user_usage.total_cash_back),
    ],
    [
      i18n.t('customerDeepDivePage.individualUserUsageTbl.avgPromotion') as string,
      isFuelUser
        ? displayCurrency(data.individual_user_usage.avg_offer_per_gallon)
        : displayPercent(data.individual_user_usage.avg_offer_percentage),
    ],
  ];

  const result: ITableData = {
    title: i18n.t('customerDeepDivePage.individualUserUsageTbl.title'),
    headers: [],
    rows: rows,
  };

  return result;
};

export const buildCustomerWalletTable = (data: ICustomerSummaryRawData, activeVertical: Verticals): ITableData => {
  const isGroceryUser = activeVertical === Verticals.Grocery;
  const result: ITableData = {
    title: i18n.t('customerDeepDivePage.customerWalletTbl.title'),
    headers: isGroceryUser
      ? [
          i18n.t('customerDeepDivePage.customerWalletTbl.paymentId'),
          i18n.t('customerDeepDivePage.customerWalletTbl.paymentIdType'),
        ]
      : [
          i18n.t('customerDeepDivePage.customerWalletTbl.cardType'),
          i18n.t('customerDeepDivePage.customerWalletTbl.lastFour'),
        ],
    rows: data.customer_wallet.payment_methods.map((item: ICustomerPaymentMethodRaw) => {
      const isPaymentCard = item.entity_type === PaymentMethodTypes.CardFirst6Last4;
      return [
        isGroceryUser ? item.entity_id : item.payment_card_type,
        isGroceryUser
          ? isPaymentCard
            ? PaymentMethodTypes.F6L4
            : PaymentMethodTypes.LoyaltyID
          : item.payment_card_last_four,
      ];
    }),
  };
  return result;
};

export const buildUserProfitStatement = (
  customerData: ICustomerSummaryRawData,
  activeVertical: Verticals,
  transactionType?: TransactionTypes,
): ITableData => {
  const data = customerData.profit_statement;
  const hasOutlierTransactions = customerData.individual_user_usage.has_outlier_transactions;
  let rows: Array<string | number | ITableCellValue>[] = [];
  const isEmptyData = Boolean(!data.total_revenue && !data.expected_revenue_from_control && !data.total_cash_back);
  const isFuelUser =
    activeVertical === Verticals.Fuel &&
    (!transactionType || transactionType === TransactionTypes.Gas || transactionType === TransactionTypes.All);

  if (!isEmptyData) {
    rows = [
      isFuelUser
        ? [
            i18n.t('customerDeepDivePage.customerVsControlChart.profitStatementTbl.gallonsOnUpside') as string,
            '',
            {
              value: displayNumber(+data.total_gallons),
              isNumeric: true,
            },
          ]
        : [
            i18n.t('customerDeepDivePage.customerVsControlChart.profitStatementTbl.salesOnUpside') as string,
            '',
            {
              value: displayCurrency(data.total_revenue),
              isNumeric: true,
            },
          ],
      isFuelUser
        ? [
            i18n.t('customerDeepDivePage.customerVsControlChart.profitStatementTbl.nonincrementalGallons') as string,
            '-',
            {
              value: displayNumber(+data.non_incremental_gallons),
              isNumeric: true,
            },
          ]
        : [
            i18n.t('customerDeepDivePage.customerVsControlChart.profitStatementTbl.nonincrementalSales') as string,
            '-',
            {
              value: displayCurrency(data.expected_revenue_from_control),
              isNumeric: true,
            },
          ],
      isFuelUser
        ? [
            i18n.t('customerDeepDivePage.customerVsControlChart.profitStatementTbl.incrementalGallons') as string,
            '=',
            {
              value: displayNumber(+data.incremental_gallons),
              isNumeric: true,
            },
          ]
        : [
            i18n.t('customerDeepDivePage.customerVsControlChart.profitStatementTbl.incrementalSales') as string,
            '=',
            {
              value: displayCurrency(data.incremental_revenue),
              isNumeric: true,
            },
          ],
      [
        i18n.t(
          `customerDeepDivePage.customerVsControlChart.profitStatementTbl.${
            isFuelUser ? 'avgBlendedMargin' : 'avgMargin'
          }`,
        ) as string,
        '\u00D7',
        {
          value: isFuelUser
            ? displayCurrency(data.blended_average_margin_gallon, 4)
            : displayPercent(data.avg_gross_margin),
          isNumeric: true,
        },
      ],
      [
        i18n.t('customerDeepDivePage.customerVsControlChart.profitStatementTbl.incrementalProfit') as string,
        '=',
        {
          value: displayCurrency(data.incremental_profit),
          isNumeric: true,
        },
      ],
      [
        i18n.t('customerDeepDivePage.customerVsControlChart.profitStatementTbl.totalCustomerPromos') as string,
        '-',
        {
          value: displayCurrency(data.total_cash_back),
          isNumeric: true,
        },
      ],
      [
        {
          value: i18n.t(
            'customerDeepDivePage.customerVsControlChart.profitStatementTbl.upsideFundedPromotions',
          ) as string,
          outlierInfo:
            hasOutlierTransactions && activeVertical == Verticals.Grocery
              ? i18n.t('customerDeepDivePage.customerVsControlChart.outlierInfo.grocery')
              : '',
        },
        '+',
        {
          value: displayCurrency(data.promotion_refund),
          isNumeric: true,
        },
      ],
      [
        {
          value: i18n.t('customerDeepDivePage.customerVsControlChart.profitStatementTbl.upsideProfitShare') as string,
          outlierInfo:
            hasOutlierTransactions && activeVertical !== Verticals.Grocery
              ? i18n.t('customerDeepDivePage.customerVsControlChart.outlierInfo.nonGrocery')
              : '',
        },
        '-',
        {
          value: displayCurrency(data.total_fee_cost),
          isNumeric: true,
        },
      ],
      [
        i18n.t('customerDeepDivePage.customerVsControlChart.profitStatementTbl.incrementalNetProfit') as string,
        '=',
        {
          value: displayCurrency(data.incremental_net_profit),
          isNumeric: true,
        },
      ],
    ];
  }

  const result: ITableData = {
    title: i18n.t('customerDeepDivePage.customerVsControlChart.profitStatementTbl.title'),
    headers: [],
    rows: rows,
  };

  return result;
};

export const buildUserRoiFlowthroughTable = (
  data: ICustomerSummaryROIFlowthrough,
  allSitesOutlier: boolean,
): ITableData => {
  const rows: Array<string | number>[] = [
    [
      i18n.t('customerDeepDivePage.customerVsControlChart.roiFlowthroughTbl.incrementalNetProfit') as string,
      '',
      `${displayCurrency(data.incremental_net_profit)}`,
    ],
    [
      i18n.t('customerDeepDivePage.customerVsControlChart.roiFlowthroughTbl.totalPromotionsAndProfitShare') as string,
      '\u00F7',
      `${displayCurrency(data.total_promotion_and_profit_share)}`,
    ],
    [
      i18n.t('customerDeepDivePage.customerVsControlChart.roiFlowthroughTbl.roi') as string,
      '=',
      Number(data.incremental_net_profit) < 0 ||
      Number(data.total_promotion_and_profit_share) < 0 ||
      allSitesOutlier === true
        ? 'N/A'
        : `${displayPercent(data.roi)}`,
    ],
  ];

  const result: ITableData = {
    title: i18n.t('customerDeepDivePage.customerVsControlChart.roiFlowthroughTbl.title') as string,
    headers: [],
    rows: rows,
  };

  return result;
};

export const buildCustomerSummary = (
  data: ICustomerSummaryRawData,
  activeVertical: Verticals,
  transactionType?: TransactionTypes,
): ICustomerSummary => {
  const summary: ICustomerSummary = {
    individualUserUsageTable: buildIndividualUserUsageTable(data, activeVertical, transactionType),
    customerWalletTable: buildCustomerWalletTable(data, activeVertical),
    profitStatementTable: buildUserProfitStatement(data, activeVertical, transactionType),
    roiFlowthroughTable: buildUserRoiFlowthroughTable(
      data.roi_calculation,
      !!data.individual_user_usage.all_sites_outlier,
    ),
    customerVsControlChart: buildCustomerVsControlGroupChart(data),
    individualUserUsage: {
      firstTxnDate: displayDate(data.individual_user_usage.first_txn_date),
      mostRecentTxnDate: displayDate(data.individual_user_usage.most_recent_txn_date),
      totalTxn: data.individual_user_usage.total_txn,
      totalRevenue: parseFloat(data.individual_user_usage.total_revenue),
      avgBasketSize: parseFloat(data.individual_user_usage.avg_basket_size),
      totalCashBack: parseFloat(data.individual_user_usage.total_cash_back),
      avgOfferPercentage: parseFloat(data.individual_user_usage.avg_offer_percentage),
      has_txns_over_45_days: data.individual_user_usage.has_txns_over_45_days,
      hasOutlierTransactions: data.individual_user_usage.has_outlier_transactions,
      allSitesOutlier: data.individual_user_usage.all_sites_outlier,
    },
  };

  return summary;
};

export const buildCustomerSegmentationSummary = (
  data: ICustomerSegmentationSummaryRawData,
  temporalUnit: TemporalUnit,
  activeVertical: Verticals,
): ICustomerSegmentationSummary => {
  const summary: ICustomerSegmentationSummary = {
    totalCustomerStats: buildTotalCustomerStats(data.users_count_by_segment),
    salesAcrossSegments: buildImpactSegmentsChart(data.sales_across_segments),
    transactionsAcrossSegments: buildImpactSegmentsChart(data.sales_across_segments, false),
    segmentBreakdownChart: buildCustomerSegmentationBreakdown(data.users_count_by_segment),
    customerSegmentDeepDiveTable: buildCustomerSegmentationDeepDiveTable(
      data.performance_summary_by_segment,
      activeVertical,
    ),
    acquisition: {
      charts: buildCustomerSegmentationCharts(data.performance_summary, temporalUnit),
      stats: buildCustomerSegmentationStats(data, activeVertical),
    },
  };

  return summary;
};

export const buildCustomerTransactionsTable = (
  data: ICustomerTransactionsRawData,
  activeVertical: Verticals,
  transactionType?: TransactionTypes,
): ITableData => {
  // Is Location/Banner name deprecated? We should remove the flag, if so
  // const shouldDisplayBannerName = activeVertical === Verticals.Grocery;
  const isFuel = activeVertical === Verticals.Fuel;
  const isGrocery = activeVertical === Verticals.Grocery;
  const isRestaurant = activeVertical === Verticals.Restaurant;
  const isFuelOrRestaurant = isFuel || isRestaurant;
  const isFuelOrGrocery = isFuel || isGrocery;

  const gallonCountPrecision = 1; // number of decimals shown in "Gallons" column
  const shouldDisplayGallons =
    isFuel &&
    (!transactionType || transactionType === TransactionTypes.Gas || transactionType === TransactionTypes.All);
  const hasItemDisplayName =
    shouldDisplayGallons && data.records.some((item: ICustomerTransaction) => item.item_display_name);

  const rows: Array<string | number | ITableCellValue>[] = data.records.map((item: ICustomerTransaction) => {
    const isFuelTransaction = item.transaction_type === 'GAS';
    const isPartiallyIncremental =
      item.total_revenue > item.total_incremental_revenue && Number(item.total_incremental_revenue) > 0;
    const isNotIncremental = item.total_incremental_revenue === 0;
    const isFullyIncremental = item.total_revenue === item.total_incremental_revenue;
    const isOutlierTransaction = item.is_outlier_transaction;

    const displayROI = +item.return_on_investment !== 0 && !isOutlierTransaction;

    const incrementalityText = () => {
      if (isOutlierTransaction) return i18n.t('components.transactionsTable.incrementalityTag.outlier');
      if (isPartiallyIncremental) return i18n.t('components.transactionsTable.incrementalityTag.partial');
      if (isNotIncremental) return i18n.t('components.transactionsTable.incrementalityTag.notIncremental');
      if (isFullyIncremental) return i18n.t('components.transactionsTable.incrementalityTag.full');
      return '';
    };

    return [
      displayUTCDate(item.transacted_at),
      ...(isFuelOrRestaurant
        ? [
            {
              value: incrementalityText(),
              outlierInfo: isOutlierTransaction ? i18n.t('components.transactionsTable.outlier.popover') : '',
            },
          ]
        : []),
      ...(isFuelOrGrocery ? [item.site_name] : []),
      item.site_address_1,
      ...(isGrocery ? [{ value: item.payment_card_type }] : []),
      item.payment_card_last_four,
      ...(isFuelOrGrocery ? [{ value: displayCurrency(item.amount_spent), isNumeric: true }] : []),
      ...(shouldDisplayGallons ? [{ value: displayGasType(item.gas_type), centerEmpty: true }] : []),
      ...(hasItemDisplayName ? [{ value: item.item_display_name ?? EMPTY_CELL_VALUE }] : []),
      ...(shouldDisplayGallons
        ? [
            {
              value: isFuelTransaction ? display2Decimals(item.gallons_bought, gallonCountPrecision) : EMPTY_CELL_VALUE,
              isNumeric: isFuelTransaction,
              centerEmpty: true,
            },
          ]
        : []),
      ...(shouldDisplayGallons
        ? [
            {
              value: isFuelTransaction ? displayCurrency(item.margin_detail.margin) : EMPTY_CELL_VALUE,
              isNumeric: isFuelTransaction,
              popoverData: isFuelTransaction ? item.margin_detail : undefined,
              centerEmpty: true,
            },
          ]
        : []),
      ...(isRestaurant
        ? [
            {
              value: displayCurrency(item.total_transaction_amount),
              isNumeric: true,
            },
          ]
        : []),
      ...(isRestaurant ? [{ value: displayCurrency(item.total_revenue), isNumeric: true }] : []),
      ...(isRestaurant
        ? [
            {
              value: displayCurrency(item.total_incremental_revenue),
              isNumeric: true,
            },
          ]
        : []),
      ...(isFuel ? [{ value: displayCurrency(item.total_profit), isNumeric: true }] : []),
      ...(isFuelOrRestaurant
        ? [
            {
              value: displayCurrency(item.total_incremental_profit),
              isNumeric: true,
            },
          ]
        : []),
      ...(isFuelOrRestaurant
        ? [
            {
              value: displayCurrency(item.customer_promotion_cost),
              isNumeric: true,
            },
          ]
        : []),
      ...(isFuelOrRestaurant
        ? [
            {
              value: displayCurrency(item.total_promotion_cost),
              isNumeric: true,
            },
          ]
        : [
            {
              value: displayCurrency(item.customer_promotion_cost),
              isNumeric: true,
            },
          ]),

      ...(isFuelOrRestaurant ? [{ value: displayCurrency(item.total_fee_cost), isNumeric: true }] : []),
      ...(isFuelOrRestaurant ? [{ value: displayCurrency(item.net_profit ?? 0), isNumeric: true }] : []),
      ...(isFuelOrRestaurant
        ? [
            {
              value: displayROI ? displayPercent(item.return_on_investment) : EMPTY_CELL_VALUE,
              isNumeric: isFuelTransaction,
              centerEmpty: true,
            },
          ]
        : []),
    ];
  });

  const result: ITableData = {
    title: i18n.t('components.transactionsTable.title'),
    headers: [
      { columnName: i18n.t('components.transactionsTable.date') },
      ...(isFuelOrRestaurant ? [{ columnName: i18n.t('components.transactionsTable.levelOfIncrementality') }] : []),
      ...(isFuelOrGrocery ? [{ columnName: i18n.t('components.transactionsTable.locationName') }] : []),
      { columnName: i18n.t('components.transactionsTable.locationAddress') },
      ...(isGrocery ? [{ columnName: i18n.t('components.transactionsTable.cardType') }] : []),
      { columnName: i18n.t('components.transactionsTable.last4') },
      ...(isFuelOrGrocery
        ? [
            {
              columnName: i18n.t('components.transactionsTable.amountSpent'),
              isNumeric: true,
            },
          ]
        : []),
      ...(shouldDisplayGallons ? [{ columnName: i18n.t('components.transactionsTable.grade') }] : []),
      ...(hasItemDisplayName ? [{ columnName: i18n.t('components.transactionsTable.itemDisplayName') }] : []),
      ...(shouldDisplayGallons
        ? [
            {
              columnName: i18n.t('components.transactionsTable.gallons'),
              isNumeric: true,
            },
          ]
        : []),
      ...(shouldDisplayGallons
        ? [
            {
              columnName: i18n.t('components.transactionsTable.margin'),
              isNumeric: true,
            },
          ]
        : []),
      ...(isRestaurant
        ? [
            {
              columnName: i18n.t('components.transactionsTable.totalTransaction'),
              isNumeric: true,
            },
          ]
        : []),
      ...(isRestaurant
        ? [
            {
              columnName: i18n.t('components.transactionsTable.totalSales'),
              isNumeric: true,
            },
          ]
        : []),
      ...(isRestaurant
        ? [
            {
              columnName: i18n.t('components.transactionsTable.incrementalSales'),
              isNumeric: true,
            },
          ]
        : []),
      ...(isFuel
        ? [
            {
              columnName: i18n.t('components.transactionsTable.totalProfit'),
              isNumeric: true,
            },
          ]
        : []),
      ...(isFuelOrRestaurant
        ? [
            {
              columnName: i18n.t('components.transactionsTable.incrementalProfit'),
              isNumeric: true,
            },
          ]
        : []),
      ...(isFuelOrRestaurant
        ? [
            {
              columnName: i18n.t('components.transactionsTable.promotionCost'),
              isNumeric: true,
            },
          ]
        : [
            {
              columnName: i18n.t('components.transactionsTable.cashBackEarned'),
              isNumeric: true,
            },
          ]),
      ...(isFuelOrRestaurant
        ? [
            {
              columnName: i18n.t('components.transactionsTable.promotion'),
              isNumeric: true,
            },
          ]
        : []),
      ...(isFuelOrRestaurant
        ? [
            {
              columnName: i18n.t('components.transactionsTable.fee'),
              isNumeric: true,
            },
          ]
        : []),
      ...(isFuelOrRestaurant
        ? [
            {
              columnName: i18n.t('components.transactionsTable.netProfit'),
              isNumeric: true,
            },
          ]
        : []),
      ...(isFuelOrRestaurant
        ? [
            {
              columnName: i18n.t('components.transactionsTable.roi'),
              isNumeric: true,
            },
          ]
        : []),
    ],
    rows,
  };

  return result;
};

export const buildCustomerVsControlGroupChart = (data: ICustomerSummaryRawData): CustomerVsControlChartProps => {
  const userData: ICustomerVsControlSeries = {
    id: TOTAL_CUSTOMER_SALES_SERIES_ID,
    data: [],
  };

  const controlGroupData: ICustomerVsControlSeries = {
    id: TOTAL_CONTROL_SALES_SERIES_ID,
    data: [],
  };

  if (
    data.customer_vs_control_journey &&
    Array.isArray(data.customer_vs_control_journey.relative_months) &&
    data.customer_vs_control_journey.relative_months.length > 0
  ) {
    data.customer_vs_control_journey.relative_months.forEach((item: ICustomerVsControlEntryRaw) => {
      userData.data.push({
        x: item.relative_month,
        y: Math.max(0, parseFloat(item.user_sales)),
      });
      controlGroupData.data.push({
        x: item.relative_month,
        y: Math.max(0, parseFloat(item.adj_shadow_sales)),
      });
    });

    // Add a duplicate extra month to the beginning of the chart to render the area of the first month
    const earliestMonth = data.customer_vs_control_journey.relative_months[0];
    const extraMonth = {
      ...earliestMonth,
      relative_month: earliestMonth.relative_month - 1,
    };
    userData.data.unshift({
      x: extraMonth.relative_month,
      y: Math.max(0, parseFloat(extraMonth.user_sales)),
    });
    controlGroupData.data.unshift({
      x: extraMonth.relative_month,
      y: Math.max(0, parseFloat(extraMonth.adj_shadow_sales)),
    });
  }

  const lineChartData: CustomerVsControlChartProps = {
    title: i18n.t('customerDeepDivePage.customerVsControlChart.title'),
    data: [userData, controlGroupData],
    colors: ['#3DB888', '#737374'],
    margin: { top: 50, right: 20, bottom: 60, left: 90 },
    areaOpacity: 0.6,
    axisBottom: {
      legend: i18n.t('customerDeepDivePage.customerVsControlChart.legendBottom') as string,
    },
    axisLeft: {
      legend: i18n.t('customerDeepDivePage.customerVsControlChart.legendLeft') as string,
      format: 'currency',
    },
    expectedPopulationDays: data.customer_vs_control_journey.expected_population_days,
  };

  return lineChartData;
};

export const buildCustomerSegmentationCharts = (
  data: ICustomerSegmentationPerformanceSummaryRow[],
  temporalUnit: TemporalUnit,
): { [key: string]: IBarGraph[] } => {
  const groupedByDate = _.groupBy(
    _.orderBy(data, (entry: ICustomerSegmentationPerformanceSummaryRow) => fnSegmentOrder(entry.segment)),
    'period_start',
  );
  const result: { [key: string]: IBarGraph[] } = {};
  for (const metricName in CUSTOMER_ACQUISITION_METRICS) {
    const { rawProp } = CUSTOMER_ACQUISITION_METRICS[metricName];
    const refinedData: Array<IBarGraph> = _.orderBy(
      _.map(groupedByDate, (items: ICustomerSegmentationPerformanceSummaryRow[], weekOf: string) => {
        const result: { [key: string]: string | number } = {
          label: formatDateByTemporalUnit(weekOf, temporalUnit),
          date: new Date(weekOf).getTime(),
        };

        for (let i = 0; i < items.length; i++) {
          const segment = i18n.t(
            `customersPage.customersOverTime.customerAcquisitionStats.segments.${items[i].segment}`,
          );
          result[segment] = _.get(items[i], [rawProp], 0);
        }

        return result;
      }),
      'date',
    );

    result[metricName] = [
      {
        id: metricName,
        data: _.map(refinedData, (value: IBarGraph) => _.omit(value, 'date')),
      },
    ];
  }

  return result;
};

export const buildCustomerSegmentationStats = (
  data: ICustomerSegmentationSummaryRawData,
  activeVertical: Verticals,
): { [key: string]: IStat[] } => {
  const result: { [key: string]: IStat[] } = {};
  // const perfSummaryBySegmentAndPeriod = _.groupBy(data.performance_summary, 'segment');
  const perfSummaryBySegment = _.keyBy(data.performance_summary_by_segment, 'segment');
  // const totalCustomersBySegment = _.groupBy(data.users_count_by_segment, 'segment');

  for (const metricName in CUSTOMER_ACQUISITION_METRICS) {
    const { rawProp, type } = CUSTOMER_ACQUISITION_METRICS[metricName];
    let formatter: (value: string | number) => string;
    if (type === 'currency') {
      formatter = (value: string | number) => displayCurrency(value as number, 0);
    } else {
      formatter = (value: string | number) => displayNumber(value as number);
    }

    result[metricName] = _.orderBy(
      _.map(perfSummaryBySegment, (entry: IPerformanceSummaryBySegmentRow, segment: string) => {
        const value = entry[rawProp];
        return {
          label: i18n.t(`customersPage.customersOverTime.customerAcquisitionStats.label.${segment}`, {
            defaultValue: segment,
          }) as string,
          value: formatter(value),
          help: i18n.t(
            `customersPage.customersOverTime.customerAcquisitionStats.tooltip.${activeVertical}.${segment}`,
            {
              defaultValue: '',
            },
          ),
          order: fnSegmentOrder(segment),
        };
      }),
      'order',
    );
  }
  return result;
};

export const buildCustomerSegmentationBreakdown = (
  data: Array<ICustomerSegmentationCustomersCountRow>,
): Array<DefaultRawDatum> => {
  const result: Array<DefaultRawDatum> = _.orderBy(data, (entry: ICustomerSegmentationCustomersCountRow) =>
    fnSegmentOrder(entry.segment),
  ).map((item: ICustomerSegmentationCustomersCountRow) => {
    const color: string | undefined = SEGMENT_COLORS[item.segment];
    return {
      id: i18n.t(`customersPage.customerAnalytics.tooltipSegmentNames.${item.segment}`) as string,
      value: item.total_upside_customers,
      color,
    };
  });

  return result;
};

export const buildTotalCustomerStats = (data: ICustomerSegmentationCustomersCountRow[]): IStat[] => {
  const result: IStat[] = [
    {
      label: i18n.t('customerDeepDivePage.home.totalUpsideCustomers'),
      value: _.sumBy(data, 'total_upside_customers'),
      help: i18n.t('customerDeepDivePage.home.totalUpsideCustomersInfo'),
    },
  ];

  return result;
};

export const buildCustomerSegmentationDeepDiveTable = (
  data: IPerformanceSummaryBySegmentRow[],
  activeVertical: Verticals,
): ITableData => {
  const groupedBySegment = _.keyBy(data, 'segment');
  const rows: {
    rows: Array<string | number>;
    order: number;
  }[] = _.map(groupedBySegment, (items: IPerformanceSummaryBySegmentRow, segment: string) => {
    const totalIncrementalRevenue = items.total_incremental_revenue;
    const totalRevenue = items.total_revenue;
    const totalPromotionCost = items.total_promotion_cost;
    const totalFeeCost = items.total_fee_cost;
    const netProfit = items.net_profit;
    const totalCost = totalPromotionCost + totalFeeCost;

    // if totalGallons is not 0, then we have a fuel merchant
    const totalGallons = items.total_gallons_bought;
    return {
      rows: [
        i18n.t(`customersPage.customerAnalytics.customerSegmentationTable.segmentNames.${segment}`) as string,
        {
          value:
            totalRevenue !== 0
              ? displayPercent(totalIncrementalRevenue / totalRevenue)
              : (i18n.t(`notAvailable`) as string),
          isNumeric: true,
        },
        {
          value:
            totalGallons !== 0
              ? displayCurrency(totalPromotionCost / totalGallons)
              : totalRevenue !== 0
              ? displayPercent(totalPromotionCost / totalRevenue)
              : (i18n.t(`notAvailable`) as string),
          isNumeric: true,
        },
        {
          value: totalCost !== 0 ? displayPercent(netProfit / totalCost) : (i18n.t(`notAvailable`) as string),
          isNumeric: true,
        },
      ] as Array<string | number>,
      order: fnSegmentOrder(segment),
    };
  });

  const result: ITableData = {
    title: i18n.t('customersPage.customerAnalytics.customerSegmentationTable.title'),
    headers: [
      {
        columnName: i18n.t('customersPage.customerAnalytics.customerSegmentationTable.hdrCustomerSegment'),
      },
      {
        columnName: i18n.t('customersPage.customerAnalytics.customerSegmentationTable.hdrIncrementalRate'),
        isNumeric: true,
        help: i18n.t('customersPage.customerAnalytics.customerSegmentationTable.hdrIncrementalRateInfo'),
      },
      {
        columnName: i18n.t('customersPage.customerAnalytics.customerSegmentationTable.hdrAvgPromoCost'),
        isNumeric: true,
        help: i18n.t(`customersPage.customerAnalytics.customerSegmentationTable.${activeVertical}.hdrAvgPromoCostInfo`),
      },
      {
        columnName: i18n.t('customersPage.customerAnalytics.customerSegmentationTable.hdrROI'),
        isNumeric: true,
        help: i18n.t('customersPage.customerAnalytics.customerSegmentationTable.hdrROIInfo'),
      },
    ],
    rows: _.orderBy(rows, 'order').map((entry) => entry.rows),
  };

  return result;
};

export const buildImpactSegmentsChart = (data: Array<ISalesAcrossSegmentsRow>, isSales = true): Array<IBarGraph> => {
  data = _.orderBy(data, (row) => fnSegmentOrder(row.segment));
  const refinedData: Array<IBarGraph> = data.map((item: ISalesAcrossSegmentsRow) => {
    return {
      label: i18n.t(`customersPage.customerAnalytics.tooltipSegmentNames.${item.segment}`) as string,
      [i18n.t('customersPage.customerAnalytics.customerSegmentationBarChart.beforeUpside')]: isSales
        ? item.avg_spend_pre_monthly
        : item.avg_trxns_pre_monthly,
      [i18n.t('customersPage.customerAnalytics.customerSegmentationBarChart.afterUpside')]: isSales
        ? item.avg_spend_post_monthly
        : item.avg_trxns_post_monthly,
    };
  });

  const result: Array<IBarGraph> = [
    {
      id: 'customersPage.customerAnalytics.customerSegmentationBarChart.title',
      data: refinedData,
    },
  ];

  return result;
};
