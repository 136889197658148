import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { DataLoadingErrorPanel } from '@/common/components/errorAlert';
import { CustomFormLabel } from '@/common/components/form';
import { LoadingSpinner } from '@/common/components/loadingSpinner';
import { Status } from '@/common/interfaces';
import { selectAuthRequestPath } from '@/features/auth/userSlice';

import {
  selectNotificationSettingsData,
  selectNotificationSettingsStatus,
  updateNotificationSettingsRequest,
} from './slice';
import { NotificationPreferencesFormData } from './types';
import { communicationsEnum, notificationPreferencesFormSchema } from './validators';

export const ManageNotifications = () => {
  const { email } = useParams<{ email: string }>();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const toast = useToast();
  const authRequestPath = useAppSelector(selectAuthRequestPath);
  const loadingStatus = useAppSelector(selectNotificationSettingsStatus);
  const notificationSettingsData = useAppSelector(selectNotificationSettingsData);
  const selectedUser = useAppSelector((state) =>
    state.notification.notificationSettings.rawData.notificationPreferences.find((item) => item.email === email),
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isNewMerchantSetting = email === 'new';
  const isAccountOwner = selectedUser?.isPrimary;
  const rawData = notificationSettingsData?.notificationSettings?.rawData?.notificationPreferences;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NotificationPreferencesFormData>({
    resolver: zodResolver(notificationPreferencesFormSchema),
    defaultValues: {
      notificationPreferences: {
        email: selectedUser?.email,
        communications: selectedUser?.communications ?? [],
        isPrimary: selectedUser?.isPrimary ?? false,
      },
    },
  });

  const onSubmit = (formData: NotificationPreferencesFormData) => {
    const newFormData = {
      ...formData,
      notificationPreferences: {
        ...formData.notificationPreferences,
        email: formData.notificationPreferences.email.toLowerCase(),
      },
    };
    const emailExists = rawData.some(
      (item) => item.email.toLowerCase() === newFormData.notificationPreferences.email.toLowerCase(),
    );
    if (emailExists && isNewMerchantSetting) {
      toast({
        title: t('notificationPage.manageForm.duplicateEmailWarning') as string,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const notificationIndex = rawData.findIndex(
      (emailObj) => selectedUser?.email.toLowerCase() === emailObj.email.toLowerCase(),
    );
    const copy = [...rawData];
    notificationIndex === -1
      ? copy.push({
          ...newFormData.notificationPreferences,
          communications: newFormData.notificationPreferences.communications ?? [],
        })
      : (copy[notificationIndex] = {
          ...newFormData.notificationPreferences,
          communications: newFormData.notificationPreferences.communications ?? [],
        });
    dispatch(updateNotificationSettingsRequest({ authRequestPath, data: { notificationPreferences: copy } }));
  };

  const deleteNotificationPref = () => {
    const copy = rawData.filter((emailObj) => selectedUser?.email !== emailObj.email);
    dispatch(updateNotificationSettingsRequest({ authRequestPath, data: { notificationPreferences: copy } }));
  };

  return (
    <Box>
      {loadingStatus === Status.Loading && (
        <Flex alignItems='center' justifyContent='center' mt={10}>
          <LoadingSpinner />
        </Flex>
      )}

      {loadingStatus === Status.Failed && (
        <Flex alignItems='center' justifyContent='center'>
          <DataLoadingErrorPanel />
        </Flex>
      )}

      {loadingStatus === Status.Idle && (
        <>
          <FormControl as='form' mt={3} onSubmit={handleSubmit(onSubmit)}>
            <Flex>
              <Flex>
                <CustomFormLabel htmlFor='address' pt={3} maxW={'400px'}>
                  {t('notificationPage.emailLabel')}
                  <Input
                    type='text'
                    id='email'
                    isDisabled={!isNewMerchantSetting && isAccountOwner}
                    mt='3'
                    size='lg'
                    {...register('notificationPreferences.email')}
                    data-testid='email'
                  />
                </CustomFormLabel>
              </Flex>
              {!isNewMerchantSetting && (
                <>
                  <Tooltip
                    label={t('notificationPage.manageForm.deleteWarning')}
                    placement='top'
                    isDisabled={!isAccountOwner}
                  >
                    <Button
                      variant='accentSecondary'
                      size='md'
                      px={8}
                      mt={'42px'}
                      onClick={onOpen}
                      isDisabled={isAccountOwner}
                    >
                      {t('notificationPage.manageForm.button.delete')}
                    </Button>
                  </Tooltip>
                  <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>{t('notificationPage.manageForm.modal.title')}</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>{t('notificationPage.manageForm.modal.subTitle')}</ModalBody>

                      <ModalFooter>
                        <Button variant='accentSecondary' w='100%' mr={'12px'} onClick={onClose}>
                          {t('notificationPage.manageForm.modal.cancel')}
                        </Button>
                        <Button variant='brandPrimary' w='100%' onClick={deleteNotificationPref}>
                          {t('notificationPage.manageForm.modal.confirm')}
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </>
              )}
            </Flex>
            {errors.notificationPreferences?.email?.message && (
              <Text variant={'error'}>{errors.notificationPreferences.email.message}</Text>
            )}
            {isAccountOwner && <Text color='text.subdued'>{t('notificationPage.warning')}</Text>}
            <CheckboxGroup defaultValue={(selectedUser?.communications as string[]) ?? []}>
              <VStack alignItems='flex-start'>
                {communicationsEnum.options.map((td) => (
                  <Checkbox
                    key={td}
                    value={td}
                    color='neutral.900'
                    {...register('notificationPreferences.communications')}
                    data-testid={`${td}`}
                  >
                    <Text fontWeight='bold'>{t(`notificationPage.manageForm.options.${td}`)}</Text>
                    <Text fontSize='sm'>{t(`notificationPage.manageForm.optionHelper.${td}`)}</Text>
                  </Checkbox>
                ))}
              </VStack>
            </CheckboxGroup>

            {errors.notificationPreferences?.communications?.message && (
              <Text data-testid='error' variant='error'>
                {errors.notificationPreferences?.communications?.message}
              </Text>
            )}

            <Flex mt={'24px'} flexDirection={'row'} justifyContent={'space-between'} w={'400px'}>
              <Flex>
                <Button as={Link} to='/settings/notification' variant='accentSecondary' size='md' px={8} mr={'12px'}>
                  {t('notificationPage.manageForm.button.back')}
                </Button>
                <Button type='submit' variant='brandPrimary' size='md' px={8}>
                  {t('notificationPage.manageForm.button.save')}
                </Button>
              </Flex>
            </Flex>
          </FormControl>
        </>
      )}
    </Box>
  );
};
