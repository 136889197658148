import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { FiInfo, FiMapPin } from 'react-icons/fi';
import { VariableSizeList as List } from 'react-window';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  Badge,
  Button,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Switch,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { LoadingSpinner } from '@components/loadingSpinner';
import { t } from 'i18next';
import { orderBy, sortBy } from 'lodash';

import { SearchInput } from '@/common/components/searchInput/SearchInput';
import { Status } from '@/common/interfaces';
import {
  selectLocationsFilter,
  selectLocationsFilterStatus,
  selectSelectedLocations,
  setSelectedLocations,
} from '@/features/dashboard/slice';
import { TLocation } from '@/features/dashboard/types';

import { LocationsCheckbox } from './LocationsCheckbox';

export function LocationsSelector() {
  const dispatch = useAppDispatch();
  const locationsStatus = useAppSelector(selectLocationsFilterStatus);
  const locations = useAppSelector(selectLocationsFilter);
  const selectedLocations = useAppSelector(selectSelectedLocations);

  const { onOpen, onClose, onToggle, isOpen } = useDisclosure();

  const [searchTerm, setSearchTerm] = useState('');
  const [isLiveLocation, setIsLiveLocation] = useState(true);
  const [localSelection, setLocalSelection] = useState<string[]>(() => {
    if (selectedLocations.length) return [];

    return selectedLocations;
  });

  const filteredForLiveLocations = useMemo(() => {
    return locations.filter((location: TLocation) =>
      isLiveLocation
        ? location.site_visibility === 'DEFAULT' && location.relationship_type !== 'HISTORICAL'
        : location.site_visibility !== 'DEAD',
    );
  }, [isLiveLocation, locations]);

  useEffect(() => {
    const filtered = filteredForLiveLocations.map((location) => location.site_uuid);

    dispatch(setSelectedLocations(filtered));
  }, [filteredForLiveLocations, locations, dispatch]);

  const filteredLocations = useMemo(() => {
    if (!locations.length) {
      return {
        list: [],
        count: 0,
      };
    }
    const filtered = filteredForLiveLocations.filter((location: TLocation) => {
      return `${location.site_display_name} - ${location.site_address}, ${location.site_territory}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
    });

    const sorted = sortBy(orderBy(filtered, ['site_display_name']), ({ site_uuid }) =>
      selectedLocations.includes(site_uuid) ? 0 : 1,
    );

    return {
      list: sorted,
      count: sorted.length,
    };
  }, [locations, searchTerm, filteredForLiveLocations, selectedLocations]);

  const selectAllCount = () => {
    return !!filteredLocations.count && !!searchTerm ? filteredLocations.count : 0;
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectLocations = (locations: string[]) => {
    setLocalSelection(locations);
  };

  const handleToggleSelectAll = () => {
    setLocalSelection((previousSelection) => {
      if (previousSelection.length === filteredLocations.count) {
        return [];
      }

      const allLocations = filteredLocations.list.map((location) => location.site_uuid);
      return allLocations;
    });
  };

  const handleFilterByLocations = () => {
    if (!localSelection.length) {
      const filtered = filteredForLiveLocations.map((location) => location.site_uuid);
      dispatch(setSelectedLocations(filtered));
      return;
    }

    dispatch(setSelectedLocations(localSelection));
    onClose();
  };

  const maxLocations = () => {
    if (selectedLocations.length === filteredLocations.list.length) {
      return 'max';
    }
    return 'other';
  };

  const handleClosePopover = () => {
    setSearchTerm('');
    onClose();
  };

  const getItemSize = (index: number) => {
    const location = filteredLocations.list[index];

    const label = `${location.site_name} - ${location.site_address}, ${location.site_locality} ${location.site_region}`;

    return label.length * 1.25;
  };

  return (
    <Popover
      placement='bottom-start'
      variant='responsive'
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={handleClosePopover}
      isLazy
    >
      <PopoverTrigger>
        <Button
          onClick={onToggle}
          variant='accentSecondary'
          borderColor='neutral.400'
          borderRadius='lg'
          data-testid='locations-filter'
          size='md'
        >
          <Icon as={FiMapPin} />
          <Text data-testid='location-selector-title' ml='10px' mr={1} lineHeight={0} fontWeight='400'>
            {t(`common.locationsSelector.title.${isLiveLocation}.${maxLocations()}`) as string}{' '}
          </Text>
          {!!selectedLocations.length && (
            <Badge
              fontSize='xs'
              borderRadius='full'
              fontWeight={400}
              mr={2}
              px='2'
              bg='background.information.default'
              color='text.default'
              data-testid='locations-badge'
            >
              {selectedLocations.length}
            </Badge>
          )}
          {isOpen ? <Icon as={ChevronUpIcon} fontWeight={500} /> : <Icon as={ChevronDownIcon} fontWeight={500} />}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        minW='330px'
        maxW='340px'
        width='100%'
        boxShadow='base'
        borderRadius='lg'
        py='3'
        px='2'
        bg='background.default'
      >
        <PopoverHeader border='none' mb='4'>
          <SearchInput
            searchTerm={searchTerm}
            handleSearch={handleSearch}
            setSearchTerm={setSearchTerm}
            handleClosePopover={handleClosePopover}
            placeholder={t('common.locationsSelector.placeholder')}
          />
          <Flex fontSize='sm' align='center' justify='space-between'>
            <Text>
              {!searchTerm
                ? (t('common.locationsSelector.defaultSearchText') as string)
                : (t('common.locationsSelector.searchText', {
                    count: filteredLocations.count,
                    searchTerm,
                  }) as string)}
            </Text>
            {!!filteredLocations.count && (
              <Link
                fontSize='sm'
                fontWeight={500}
                onClick={() => handleToggleSelectAll()}
                data-testid='select-all-locations'
              >
                {filteredLocations.count === localSelection.length
                  ? (t('common.locationsSelector.deselectAll', { count: selectAllCount() }) as string)
                  : (t('common.locationsSelector.selectAll', {
                      count: selectAllCount(),
                    }) as string)}
              </Link>
            )}
          </Flex>
        </PopoverHeader>
        <PopoverBody maxH='260px' mb='4'>
          {locationsStatus === Status.Loading && (
            <Flex w='full' justify='center'>
              <LoadingSpinner />
            </Flex>
          )}
          <CheckboxGroup value={localSelection} onChange={handleSelectLocations}>
            {locationsStatus !== Status.Loading && !!filteredLocations.list.length && (
              <List height={254} width={315} itemCount={filteredLocations.count} itemSize={getItemSize}>
                {({ index, style }: { index: number; style: CSSProperties }) => {
                  const location = filteredLocations.list[index];
                  return (
                    <LocationsCheckbox
                      key={location.site_uuid}
                      style={style}
                      value={location.site_uuid}
                      label={`${location.site_name} - ${location.site_address}, ${location.site_locality} ${location.site_region}`}
                      highlight={searchTerm}
                    />
                  );
                }}
              </List>
            )}
          </CheckboxGroup>
          {locationsStatus !== Status.Loading && !filteredLocations.list.length && !!searchTerm && (
            <Flex gap={2} align='flex-start' justify='center' data-testid='no-result'>
              <Icon as={FiInfo} />
              <Text fontSize='sm'>{t('common.locationsSelector.noResults', { searchTerm }) as string}</Text>
            </Flex>
          )}
        </PopoverBody>
        <PopoverFooter>
          <FormControl as={Flex} alignItems='center' justifyContent='center'>
            <FormLabel fontSize='md' mt={1} color='neutral.400' htmlFor='live-locations'>
              {t('common.locationsSelector.liveLocationsButton') as string}
            </FormLabel>
            <Switch
              onChange={(event) => setIsLiveLocation(event.target.checked)}
              isChecked={isLiveLocation}
              id='live-locations'
            />
          </FormControl>
          <Button
            fontWeight={400}
            w='full'
            variant='accentSecondary'
            onClick={handleFilterByLocations}
            data-testid='select-locations'
          >
            {t('common.locationsSelector.filterButton', { count: localSelection.length }) as string}
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}
