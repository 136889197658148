import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Flex, FormControl, Heading, Radio, RadioGroup, Text, VStack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { Status } from '@/common/interfaces';
import { selectAuthRequestPath, selectUserEmail } from '@/features/auth/userSlice';
import {
  selectEndDate,
  selectSelectedLocations,
  selectSelectedNetworks,
  selectSelectedTransactionType,
  selectStartDate,
} from '@/features/dashboard/slice';

import { customReportsFetch, selectCustomReportsStatus, selectReportOptions, updateReportOptions } from '../slice';
import { DownloadOptionsForm, downloadOptionsSchema } from '../validators';

export function DownloadTypeSelector() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const userEmail = useAppSelector(selectUserEmail);
  const customReportStatus = useAppSelector(selectCustomReportsStatus);
  const reportOptions = useAppSelector(selectReportOptions);
  const authRequestPath = useAppSelector(selectAuthRequestPath);
  const locations = useAppSelector(selectSelectedLocations);
  const networkNames = useAppSelector(selectSelectedNetworks);
  const transactionType = useAppSelector(selectSelectedTransactionType);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);

  const { handleSubmit, register } = useForm<DownloadOptionsForm>({
    resolver: zodResolver(downloadOptionsSchema),
    defaultValues: reportOptions,
  });

  function onSubmit(data: DownloadOptionsForm) {
    const options = Object.assign({}, reportOptions, data, { recipientEmail: userEmail, startDate, endDate });

    dispatch(updateReportOptions(options));
    dispatch(
      customReportsFetch({
        authRequestPath,
        options,
        locations,
        networkNames,
        transactionType,
      }),
    );
  }

  return (
    <Flex direction='column' gap='2'>
      <Heading as='h3' size='md'>
        {t('customReportsPage.downloadOptions')}
      </Heading>
      <Text>{t('customReportsPage.downloadOptionsDescription1')}</Text>
      <FormControl as='form' onSubmit={handleSubmit(onSubmit)} mt={3}>
        <RadioGroup my={4} defaultValue='direct'>
          {/* <Text fontWeight='bold'>{t('customReportsPage.deliveryType')}</Text>

          <VStack alignItems='flex-start'>
            <Radio value='direct' {...register('deliveryMethod')}>
              {t(`customReportsPage.direct`)}
            </Radio>
            <Radio value='email' {...register('deliveryMethod')}>
              {t(`customReportsPage.email`)}
            </Radio>
          </VStack> */}
        </RadioGroup>

        <RadioGroup my={4} defaultValue='csv'>
          <Text fontWeight='bold'>{t('customReportsPage.fileType')}</Text>

          <VStack alignItems='flex-start'>
            <Radio value='csv' {...register('fileFormat')}>
              {t(`customReportsPage.csv`)}
            </Radio>
            <Radio value='xlsx' {...register('fileFormat')}>
              {t(`customReportsPage.excel`)}
            </Radio>
          </VStack>
        </RadioGroup>

        <Flex gap='4' mt='8'>
          <Button as={Link} to='/reports/customReports/fields' type='submit' variant='brandSecondary' size='md' px={8}>
            {t('customReportsPage.actions.back')}
          </Button>
          <Button
            type='submit'
            variant='brandPrimary'
            size='md'
            px={8}
            isLoading={customReportStatus === Status.Loading}
          >
            {t('customReportsPage.actions.download')}
          </Button>
        </Flex>
      </FormControl>
    </Flex>
  );
}
