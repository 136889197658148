import { Trans, useTranslation } from 'react-i18next';
import { useAppSelector } from '@app/hooks';
import { Heading, Text } from '@chakra-ui/react';

import { Verticals } from '@/common/interfaces';
import { selectActiveVertical } from '@/features/auth/userSlice';
import { selectSelectedTransactionType } from '@/features/dashboard/slice';

export const WalkthroughInitialView = () => {
  const { t } = useTranslation();
  const activeVertical = useAppSelector(selectActiveVertical);
  const selectedTransactionType = useAppSelector(selectSelectedTransactionType);

  return (
    <>
      <Heading size='md' mt={6}>
        {t('profitabilityPage.startHeader')}
      </Heading>
      {activeVertical === Verticals.Fuel || activeVertical === Verticals.Grocery ? (
        activeVertical === Verticals.Fuel ? (
          <>
            <Text>
              <Trans
                i18nKey={`profitabilityPage.startText1.${activeVertical}.${selectedTransactionType}`}
                components={{ bold: <strong /> }}
              />
            </Text>
            <Text>
              <Trans
                i18nKey={`profitabilityPage.startText2.${activeVertical}.${selectedTransactionType}`}
                components={{ bold: <strong /> }}
              />
            </Text>
          </>
        ) : (
          <Text>
            <Trans i18nKey={`profitabilityPage.startText1.${activeVertical}`} components={{ bold: <strong /> }} />
          </Text>
        )
      ) : (
        <>
          <Text>
            <Trans i18nKey={`profitabilityPage.startText1.${activeVertical}`} components={{ bold: <strong /> }} />
          </Text>
          <Text>
            <Trans i18nKey={`profitabilityPage.startText2.${activeVertical}`} components={{ bold: <strong /> }} />
          </Text>
        </>
      )}
    </>
  );
};
