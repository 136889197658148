import { GetTokenSilentlyOptions } from '@auth0/auth0-react';
import { AxiosRequestConfig } from 'axios';

import { axiosInstance } from '@/common/utils';
import { ENV_CONFIG } from '@/config';

const options: GetTokenSilentlyOptions = {
  authorizationParams: {
    audience: ENV_CONFIG.AUTH_0.audience,
    scope: ENV_CONFIG.AUTH_0.scope,
  },
};

class AuthInterceptor {
  getToken?: (options: GetTokenSilentlyOptions) => Promise<string> | null;
  logout?: () => void;

  setAuthGetter(getToken?: () => Promise<string> | null) {
    this.getToken = getToken;
  }

  setLogout(logout?: () => void) {
    this.logout = logout;
  }

  async intercept(config: AxiosRequestConfig) {
    if (!this.getToken) {
      return config;
    }

    if (!config.headers) {
      config.headers = {};
    }

    try {
      const token = await this.getToken(options);
      config.headers['Authorization'] = `Bearer ${token}`;
    } catch (e) {
      this.logout && this.logout();
    }
    return config;
  }
}

export const authInterceptor = new AuthInterceptor();
axiosInstance.interceptors.request.use((config) => {
  return authInterceptor.intercept(config);
});
