import { Trans, useTranslation } from 'react-i18next';
import { ChakraStyledOptions, Flex, Heading, Link, Text } from '@chakra-ui/react';

export const DataLoadingErrorAlert = ({ boxProps }: { boxProps?: ChakraStyledOptions }) => {
  const { t } = useTranslation();

  const refreshPage = () => window.location.reload();

  return (
    <Flex flexDirection='column' justifyContent='center' alignItems='center' height='400px' {...boxProps}>
      <Heading fontSize='18px' lineHeight='28px'>
        {t('components.dataLoadingErrorAlert.dataLoadTitle')}
      </Heading>
      <Text fontSize='14px' lineHeight='17px' width='25rem' textAlign='center' data-testid='test-error'>
        <Trans i18nKey='components.dataLoadingErrorAlert.dataLoadInfo'>
          This could be a temporary issue, try
          <Link onClick={refreshPage} color={'blue.400'} whiteSpace='nowrap' data-testid='dataLoadingErrorAlert-link'>
            refreshing your browser
          </Link>
          after a few moments
        </Trans>
      </Text>
    </Flex>
  );
};
