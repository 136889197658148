import { Trans, useTranslation } from 'react-i18next';
import { Box, Heading, Text } from '@chakra-ui/react';

import { Overlay } from '@/common/components/overlay';

export const ComingSoon = (props: { expectedPoulationDays: number | undefined; isOutlier: boolean }) => {
  const { t } = useTranslation();
  const { expectedPoulationDays, isOutlier } = props;

  let content;
  if (isOutlier) {
    content = (
      <>
        <Heading size={'xl'}>{t('customerDeepDivePage.comingSoon.outlierHeading')}</Heading>
        <Text textStyle={'body.sm'}>
          <Trans i18nKey={t('customerDeepDivePage.comingSoon.outlierInfo')} />
        </Text>
      </>
    );
  } else {
    content = (
      <>
        <Heading size={'xl'}>{t('customerDeepDivePage.comingSoon.heading')}</Heading>
        <Text textStyle={'body.sm'}>
          <Trans
            i18nKey={t('customerDeepDivePage.comingSoon.info')}
            values={{ calcRemainingDays: expectedPoulationDays ?? '---' }}
          />
        </Text>
      </>
    );
  }

  return (
    <Overlay zIndex={2}>
      <Box position={'relative'} textAlign={'center'} top={'50%'}>
        {content}
      </Box>
    </Overlay>
  );
};
