import { useTranslation } from 'react-i18next';
import { FiAlertTriangle } from 'react-icons/fi';
import { Link as RouterLink } from 'react-router-dom';
import {
  Button,
  Flex,
  Icon,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Td,
  Text,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { usePermissions } from '@/common/hooks';
import {
  selectActiveMerchantUuid,
  selectAuthRequestPath,
  selectIsUpsideManager,
  selectUserRoles,
} from '@/features/auth/userSlice';

import { deleteBillingMethodRequest } from '../billingPaymentMethods/slice';
import { IBillingMethodRawBankAccount, IBillingMethodRawCard } from '../billingPaymentMethods/types';

export function BillingTableCell({
  method,
  methodsLength,
  sectionName,
}: {
  method: IBillingMethodRawCard | IBillingMethodRawBankAccount;
  methodsLength: number;
  sectionName: string;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const authRequestPath = useAppSelector(selectAuthRequestPath);
  const roles = useAppSelector(selectUserRoles);
  const merchant_uuid = useAppSelector(selectActiveMerchantUuid);
  const isUpsideManager = useAppSelector(selectIsUpsideManager);

  const { hasPaymentsManagerAccess, hasBillingManagerAccess } = usePermissions();

  const methodType = method.type;
  const payment_method_id = method.id;

  const deletePayoutMethod = () => {
    if (!hasPaymentsManagerAccess && !hasBillingManagerAccess && !isUpsideManager) {
      toast({
        title: 'Unauthorized',
        description: 'You are not authorized to perform this action',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (authRequestPath && payment_method_id && !method.isDefault) {
      dispatch(deleteBillingMethodRequest({ authRequestPath, roles, payment_method_id, merchant_uuid }));
    }

    if (method.isDefault) {
      toast({
        title: 'Error',
        description: 'Cannot delete default payment method. Please set another payment method as default first.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
  };

  const DisplayDefault = () => {
    return method.isDefault ? (
      <Text color={'#89B8FF'} pl={1}>
        {'Default'}
      </Text>
    ) : (
      <></>
    );
  };
  return (
    <Tr backgroundColor={'background.whitecap.light'}>
      <Td>
        <Flex textStyle={'body.md'}>
          {methodType === 'CARD' && 'brand' in method && `${method.brand} ending in ${method.last4}`}
          {methodType === 'BANK_ACCOUNT' && 'bankName' in method && `${method.bankName} ending in ${method.last4}`}
          <DisplayDefault />
        </Flex>
      </Td>
      <Td>
        <Text textStyle={'body.md'}>{methodType === 'CARD' ? 'Credit card' : 'Bank account'}</Text>
      </Td>
      <Td>
        <Text textStyle={'body.md'}>
          {methodType === 'BANK_ACCOUNT' && 'holderName' in method && `${method.holderName || ''}`}
        </Text>
      </Td>
      <Td w='30%' textStyle={'body.md'}>
        <Flex alignItems='center' justifyContent='flex-end' gap={4}>
          {methodType === 'BANK_ACCOUNT' && 'status' in method && method.status !== 'verified' && (
            <Flex alignItems='center' mr={4} color='text.danger.default'>
              <Icon as={FiAlertTriangle} mr={2} />
              Pending verification
            </Flex>
          )}

          {methodsLength > 1 && (
            <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} isLazy>
              <PopoverTrigger>
                <Link data-testid='remove-popover-anchor'>{t('payment.payoutMethod.hasAccounts.remove')}</Link>
              </PopoverTrigger>
              <Portal>
                <PopoverContent width={'350px'}>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>
                    {t('payment.payoutMethod.hasAccounts.removeModal.title', {
                      count: methodsLength,
                    })}
                  </PopoverHeader>
                  <PopoverBody>
                    <Text color='text.default-inverse'>
                      {t('payment.payoutMethod.hasAccounts.removeModal.description', {
                        count: methodsLength,
                      })}
                    </Text>
                  </PopoverBody>
                  {methodsLength > 1 && (
                    <PopoverFooter>
                      <Flex gap={2} justifyContent='flex-end'>
                        <Button variant='onDarkSecondary' size='sm' onClick={onClose}>
                          Cancel
                        </Button>
                        <Button
                          onClick={deletePayoutMethod}
                          variant='onDarkPrimary'
                          size='sm'
                          data-testid='remove-billing-method'
                        >
                          Remove
                        </Button>
                      </Flex>
                    </PopoverFooter>
                  )}
                </PopoverContent>
              </Portal>
            </Popover>
          )}

          {methodType === 'BANK_ACCOUNT' && 'status' in method && method.status !== 'verified' && (
            // onClick={verifyPlaidLink(link_token)}
            <Link as={RouterLink} to={'#'} color='text.danger.default'>
              {t('payment.payoutMethod.hasAccounts.pendingVerification')}
            </Link>
          )}

          {methodType === 'BANK_ACCOUNT' && 'status' in method ? (
            method.status === 'verified' && (
              <Link as={RouterLink} to={`${sectionName}/manage/${method.id}`}>
                {t('payment.payoutMethod.hasAccounts.manage')}
              </Link>
            )
          ) : (
            <Link as={RouterLink} to={`${sectionName}/manage/${method.id}`}>
              {t('payment.payoutMethod.hasAccounts.manage')}
            </Link>
          )}
        </Flex>
      </Td>
    </Tr>
  );
}
