import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@app/hooks';
import { Box, Flex } from '@chakra-ui/react';
import { IDropdownMenuProps } from '@components/chartdropdownMenu';
import { CustomerSegmentsInfo } from '@components/customerSegmentsInfo';
import { DataLoadingErrorPanel, EmptyDataAlertPanel } from '@components/errorAlert';
import { LoadingSpinner } from '@components/loadingSpinner';
import { Section } from '@components/section';
import { Stats } from '@components/stats';
import { StatsGrid } from '@components/stats/StatsGrid';
import { Table } from '@components/table';

import { Card } from '@/common/components/card';
import { Status, Verticals } from '@/common/interfaces';
import { selectActiveVertical } from '@/features/auth/userSlice';

import { selectDashboardStatus } from '../../dashboard/slice';
import {
  selectCustomersSegmentationSummary,
  selectCustomersSegmentationSummaryStatus,
} from '../../explore/customerDeepDive/slice';

import { CustomerAcquisitionChart } from './CustomerAcquisitionChart';
import { CustomerSegmentationBarChart } from './CustomerSegmentBarChart';
import { CustomerSegmentBreakdownChart } from './CustomerSegmentBreakdownChart';

export const Customers = () => {
  const { t } = useTranslation();
  const activeVertical = useAppSelector(selectActiveVertical);
  const dashboardStatus = useAppSelector(selectDashboardStatus);
  const customersSegmentationSummary = useAppSelector(selectCustomersSegmentationSummary);
  const customersSegmentationStatus = useAppSelector(selectCustomersSegmentationSummaryStatus);
  const [selectedAcquisitionMetric, setSelectedAcquisitionMetric] = useState('totalUpsideCustomers');
  const [selectedImpactMetric, setSelectedImpactMetric] = useState(
    activeVertical === Verticals.Fuel ? 'transactions' : 'sales',
  );

  useEffect(() => {
    const defaultImpactChartDropdownValue = activeVertical === Verticals.Fuel ? 'transactions' : 'sales';
    setSelectedImpactMetric(defaultImpactChartDropdownValue);
  }, [activeVertical]);

  if (dashboardStatus === Status.Loading) {
    return null;
  }

  if (customersSegmentationStatus === Status.Failed) {
    return <DataLoadingErrorPanel />;
  }

  const segmentsStats = customersSegmentationSummary?.acquisition?.stats[selectedAcquisitionMetric];
  const segmentsChart = customersSegmentationSummary?.acquisition?.charts[selectedAcquisitionMetric];
  const acquisitionChartTitle = segmentsChart && `customersPage.customerAcquisitionChart.titles.${segmentsChart[0].id}`;
  const acquisitionChartDropdownProps: IDropdownMenuProps = {
    selectedItem: selectedAcquisitionMetric,
    itemSelector: (value) => setSelectedAcquisitionMetric(value),
  };

  const impactChartDropdownProps: IDropdownMenuProps = {
    selectedItem: selectedImpactMetric,
    itemSelector: (value) => setSelectedImpactMetric(value),
    items: [
      { value: 'transactions', label: t(`components.chartDropDownMenu.customerMetricsDropdown.transactions`) },
      { value: 'sales', label: t(`components.chartDropDownMenu.customerMetricsDropdown.sales`) },
    ],
  };

  const isEmptyData =
    customersSegmentationStatus === Status.Idle && customersSegmentationSummary?.segmentBreakdownChart?.length === 0;

  return isEmptyData ? (
    <EmptyDataAlertPanel />
  ) : (
    <Box padding={4}>
      <StatsGrid stats={customersSegmentationSummary?.totalCustomerStats} status={customersSegmentationStatus} />
      <Section name={'customersPage.titleCustomerAnalytics'}>
        <Card>
          <Flex flexDirection='row' flexWrap='wrap'>
            <Box flex={1} minWidth='450px'>
              <CustomerSegmentBreakdownChart
                data={customersSegmentationSummary?.segmentBreakdownChart}
                loadingStatus={customersSegmentationStatus}
                title={'customersPage.customerAnalytics.customerSegmentationChart.title'}
              />
            </Box>
            <Box flex={1} minWidth='450px'>
              <CustomerSegmentationBarChart
                data={
                  selectedImpactMetric === 'sales'
                    ? customersSegmentationSummary?.salesAcrossSegments
                    : customersSegmentationSummary?.transactionsAcrossSegments
                }
                loadingStatus={customersSegmentationStatus}
                title={'customersPage.customerAnalytics.customerSegmentationBarChart.title'}
                dropdownMenuProps={[impactChartDropdownProps]}
              />
            </Box>
          </Flex>
          <Box mt={6} textAlign='center' opacity={customersSegmentationStatus === Status.Loading ? '20%' : '100%'}>
            <CustomerSegmentsInfo />
          </Box>
          <Box mt={6}>
            {customersSegmentationSummary ? (
              <Table
                id='customerSegmentBreakdownTbl'
                data={customersSegmentationSummary.customerSegmentDeepDiveTable}
              />
            ) : null}
          </Box>
        </Card>
      </Section>

      <Section name={'customersPage.titleCustomerOverTime'}>
        <Card>
          {customersSegmentationStatus === Status.Loading ? (
            <Flex height='300px' justifyContent='center' alignItems='center'>
              <LoadingSpinner />
            </Flex>
          ) : (
            <>
              <Stats stats={segmentsStats} />
              <Box>
                <CustomerAcquisitionChart
                  data={segmentsChart}
                  loadingStatus={customersSegmentationStatus}
                  title={acquisitionChartTitle}
                  displayLegend={true}
                  dropdownMenuProps={[acquisitionChartDropdownProps]}
                />
              </Box>
            </>
          )}
        </Card>
      </Section>
    </Box>
  );
};
