import * as Sentry from '@sentry/react';
import axios from 'axios';

import { BasePayload, Verticals } from '@/common/interfaces';
import { axiosInstance } from '@/common/utils';
import { checkPayoutPermissions } from '@/features/auth/permissions';

import { IPlaidToken } from '../hooks';

import {
  IAddPayoutRequestPayload,
  IDeleteRequestPayload,
  IPaymentsRawData,
  IUpdatePayoutRequestPayload,
} from './types';

const PAYMENTS_BASE_URL = `payments`;
const GEN_LINK_TOKEN = `${PAYMENTS_BASE_URL}/plaid/generate_link_token`;
const PLAID_PAY_METHOD = `${PAYMENTS_BASE_URL}/plaid/payment_method`;
const AUTH_URL_PATH_SUFFIX = (orgId: string, role: string, vertical: Verticals) => `${orgId}/${role}/${vertical}`;

export const fetchPayments = async ({ orgId, role, vertical }: BasePayload) => {
  try {
    checkPayoutPermissions(role, 'read');

    const result = await axiosInstance.post<IPaymentsRawData[]>(
      `${PAYMENTS_BASE_URL}/${AUTH_URL_PATH_SUFFIX(orgId, role, vertical)}`,
      {},
    );

    return result.data;
  } catch (error) {
    Sentry.captureException(error);
    console.error('Error fetching payments', error);
    if (axios.isAxiosError(error)) {
      throw new Error('Axios Error fetching payments');
    }
    throw error;
  }
};

export const fetchPlaidToken = async ({ orgId, role, vertical }: BasePayload) => {
  checkPayoutPermissions(role, 'manage');

  try {
    const result = await axiosInstance.post<IPlaidToken>(
      `${GEN_LINK_TOKEN}/${AUTH_URL_PATH_SUFFIX(orgId, role, vertical)}`,
      { payment_url: true },
    );
    return result.data;
  } catch (error) {
    // Sentry.captureException(error);
    console.error(error);
    return { link_token: '', expiration: 0 };
  }
};

export const addNewPayout = async ({ orgId, role, vertical, data }: IAddPayoutRequestPayload) => {
  checkPayoutPermissions(role, 'manage');

  try {
    const result = await axiosInstance.put(`${PLAID_PAY_METHOD}/${AUTH_URL_PATH_SUFFIX(orgId, role, vertical)}`, data);
    return result.status;
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
  }
};

export const updatePayout = async ({ orgId, role, vertical, uuid, data }: IUpdatePayoutRequestPayload) => {
  checkPayoutPermissions(role, 'manage');

  try {
    const result = await axiosInstance.patch(
      `${PLAID_PAY_METHOD}/${AUTH_URL_PATH_SUFFIX(orgId, role, vertical)}?uuid=${uuid}`,
      {
        ...data,
      },
    );
    return result.status;
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
    return 500;
  }
};

export const deletePayout = async ({ orgId, role, vertical, uuid }: IDeleteRequestPayload) => {
  checkPayoutPermissions(role, 'manage');

  try {
    const result = await axiosInstance.delete(
      `${PLAID_PAY_METHOD}/${AUTH_URL_PATH_SUFFIX(orgId, role, vertical)}?uuid=${uuid}`,
      { data: {} },
    );
    return result.data;
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
  }
};
