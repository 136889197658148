import axios from 'axios';

import { UserRoles } from '@/common/interfaces';
import { axiosInstance } from '@/common/utils';
import { checkLocationsSettingsPermissions } from '@/features/auth/permissions';

import { LocationSettingsData, LocationSettingsRawData, ProcessorData } from './types';

export const fetchLocationsSettings = async ({
  orgId,
  activeVertical,
  roles,
}: {
  orgId: string;
  activeVertical: string;
  roles: UserRoles[];
}) => {
  try {
    checkLocationsSettingsPermissions(roles);
    const role = roles.find((role) => role.toUpperCase().includes(activeVertical.toUpperCase()));

    const result = await axiosInstance.post<LocationSettingsRawData[]>(
      `/locations/settings/${orgId}/${role}/${activeVertical}`,
      {},
    );
    return result.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error('Error fetching locations');
    }
    throw error;
  }
};

export const addNewLocation = async ({
  orgId,
  activeVertical,
  roles,
  location,
}: {
  orgId: string;
  activeVertical: string;
  roles: UserRoles[];
  location: LocationSettingsData;
}) => {
  try {
    checkLocationsSettingsPermissions(roles, 'manage');

    const role = roles.find((role) => role.toUpperCase().includes(activeVertical.toUpperCase()));

    await axiosInstance.post(`/locations/settings/add/${orgId}/${role}/${activeVertical}`, {
      ...location,
      offerCategory: activeVertical.toUpperCase(),
    });
    return;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error('Error adding new location');
    }

    throw error;
  }
};

export const updateLocationSettings = async ({
  orgId,
  activeVertical,
  roles,
  location,
  locationId,
}: {
  orgId: string;
  activeVertical: string;
  roles: UserRoles[];
  location: LocationSettingsData;
  locationId: string;
}) => {
  try {
    checkLocationsSettingsPermissions(roles, 'manage');

    const role = roles.find((role) => role.toUpperCase().includes(activeVertical.toUpperCase()));

    await axiosInstance.put(`/locations/settings/${locationId}/${orgId}/${role}/${activeVertical}`, {
      ...location,
      offerCategory: activeVertical.toUpperCase(),
    });

    return;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error('Error updating location');
    }

    throw error;
  }
};

export const getLocationProcessorCredentials = async ({
  authRequestPath,
  roles,
  locationId,
}: {
  authRequestPath: string;
  roles: UserRoles[];
  locationId: string;
}) => {
  try {
    checkLocationsSettingsPermissions(roles, 'manage');

    const result = await axiosInstance.post<{ processors: ProcessorData[] }>(
      `/locations/settings/${locationId}/processors/${authRequestPath}`,
      {},
    );
    return result.data.processors;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error('Error fetching location processor credentials');
    }

    throw error;
  }
};
