import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@app/hooks';
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import {
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Table as ChakraTable,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import { selectActiveVertical } from '@/features/auth/userSlice';

export const CustomerSegmentsInfo = ({ ...popoverProps }) => {
  const { t } = useTranslation();
  const activeVertical = useAppSelector(selectActiveVertical);

  return (
    <Popover {...popoverProps} variant='default' data-testid='customerSegmentInfo'>
      <PopoverTrigger>
        <Link ml='4' textAlign='center'>
          <h3>
            <QuestionOutlineIcon boxSize={4} mr='1.5' />
            {t(`customersPage.customerAnalytics.customerSegmentsInfoPopover.faqLink`)}
          </h3>
        </Link>
      </PopoverTrigger>
      <PopoverContent width={'400px'}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>
          <Text>{t(`customersPage.customerAnalytics.customerSegmentsInfoPopover.header`)} </Text>
        </PopoverHeader>
        <PopoverBody>
          {t('customersPage.customerAnalytics.customerSegmentsInfoPopover.body')}
          <TableContainer width={'100%'} overflowX={'hidden'}>
            <ChakraTable>
              <Thead>
                <Tr>
                  <Th textAlign={'left'} w={'30%'} border='none'>
                    {t('customersPage.customerAnalytics.customerSegmentsInfoPopover.tableHeaderSegment')}
                  </Th>
                  <Th textAlign={'right'} w={'70%'} border='none'>
                    {t('customersPage.customerAnalytics.customerSegmentsInfoPopover.tableHeaderPurchase')}
                    <br />
                    {t('customersPage.customerAnalytics.customerSegmentsInfoPopover.tableHeaderFrequency')}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr bg='background.default'>
                  <Td fontWeight={'500'} w={'30%'} border='none'>
                    {t('customersPage.customerAnalytics.tooltipSegmentNames.New')}
                  </Td>
                  <Td fontWeight={'700'} textAlign={'right'} w={'70%'} border='none'>
                    {t(`customersPage.customerAnalytics.customerSegmentsInfoPopover.${activeVertical}.New`)}
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight={'500'} w={'30%'} border='none'>
                    {t('customersPage.customerAnalytics.tooltipSegmentNames.Infrequent')}
                  </Td>
                  <Td fontWeight={'700'} textAlign={'right'} w={'70%'} border='none'>
                    {t(`customersPage.customerAnalytics.customerSegmentsInfoPopover.${activeVertical}.Infrequent`)}
                  </Td>
                </Tr>
                <Tr bg='background.default'>
                  <Td fontWeight={'500'} w={'30%'} border='none'>
                    {t('customersPage.customerAnalytics.tooltipSegmentNames.Occasional')}
                  </Td>
                  <Td fontWeight={'700'} textAlign={'right'} w={'70%'} border='none'>
                    {t(`customersPage.customerAnalytics.customerSegmentsInfoPopover.${activeVertical}.Occasional`)}
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight={'500'} w={'30%'} border='none'>
                    {t('customersPage.customerAnalytics.tooltipSegmentNames.Regular')}
                  </Td>
                  <Td fontWeight={'700'} textAlign={'right'} w={'70%'} border='none'>
                    {t(`customersPage.customerAnalytics.customerSegmentsInfoPopover.${activeVertical}.Regular`)}
                  </Td>
                </Tr>
              </Tbody>
            </ChakraTable>
          </TableContainer>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
