import { ReactNode } from 'react';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box } from '@chakra-ui/react';

export const ExpansionPanel = (props: { header: ReactNode; children: ReactNode[] | ReactNode }) => {
  return (
    <>
      <Accordion allowMultiple>
        <AccordionItem border={0}>
          <AccordionButton p={0}>
            <AccordionIcon />
            <Box flex='1' textAlign='left'>
              {props.header}
            </Box>
          </AccordionButton>
          <AccordionPanel>{props.children}</AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
};
