import { ITableCellValue, ITableData } from '@components/table/Table';
import { format } from 'date-fns';

import { displayCurrency } from '@/common/utils';
import i18n from '@/i18n';

import { IPayoutsData, IPayoutsRawData, PayoutsStatus } from './types';

export const buildPayoutsTable = (data: IPayoutsRawData): ITableData => {
  const rows: Array<string | number | ITableCellValue>[] = data.records.map((item: IPayoutsData) => {
    const periodStart = format(new Date(item.period_start_timestamp), 'MMM dd, yyyy');
    const periodEnd = format(new Date(item.period_end_timestamp), 'MMM dd, yyyy');

    return [
      `${periodStart} - ${periodEnd}`,
      item.billable_entity_display_name,
      PayoutsStatus[item.status],
      { value: displayCurrency(item.gross_sales), isNumeric: true },
      { value: `(${displayCurrency(item.processing_fee)})`, isNumeric: true },
      { value: `(${displayCurrency(item.upside_promotion_cost)})`, isNumeric: true },
      { value: `(${displayCurrency(item.upside_profit_share_cost)})`, isNumeric: true },
      { value: displayCurrency(item.misc_adjustments), isNumeric: true },
      { value: displayCurrency(item.net_settlement), isNumeric: true },
      {
        value: '',
        invoiceUuid: item.invoice_uuid,
        popoverLink: true,
      },
    ];
  });

  const result: ITableData = {
    headers: [
      { columnName: i18n.t('payoutsPage.payoutsTbl.payoutPeriod') },
      { columnName: i18n.t('payoutsPage.payoutsTbl.payoutAccount') },
      { columnName: i18n.t('payoutsPage.payoutsTbl.payoutStatus') },
      { columnName: i18n.t('payoutsPage.payoutsTbl.payoutGrossSales'), isNumeric: true },
      { columnName: i18n.t('payoutsPage.payoutsTbl.payoutProcessingFee'), isNumeric: true },
      { columnName: i18n.t('payoutsPage.payoutsTbl.payoutUpsidePromotionCost'), isNumeric: true },
      { columnName: i18n.t('payoutsPage.payoutsTbl.payoutUpsideProfitShare'), isNumeric: true },
      { columnName: i18n.t('payoutsPage.payoutsTbl.payoutMiscAdjustments'), isNumeric: true },
      { columnName: i18n.t('payoutsPage.payoutsTbl.payoutNet'), isNumeric: true },
      { columnName: i18n.t('payoutsPage.payoutsTbl.payoutActions') },
    ],
    rows: rows,
  };

  return result;
};
