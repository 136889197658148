import _ from 'lodash';

import { ICSVData } from '@/features/explore/locations/types';

import { INITIAL_TRANSACTION_TYPE } from '../interfaces';

import { formatCurrencyUSD } from './currency';

const DEFAULT_LOCALE = 'en-US';
export const EMPTY_CELL_VALUE = '-';
export const SEGMENT_ORDERING: { [key: string]: number } = {
  New: 1,
  Infrequent: 2,
  Occasional: 3,
  Regular: 4,
  Undetermined: 5,
};

export const displayUUID = (uuid: string) => {
  const [firstSegment] = uuid.split('-');

  return firstSegment;
};

export const toTitleCase = (str: string) =>
  str
    .toLocaleLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const displayTransactionType = (string: string) =>
  string && string.toLowerCase() !== 'none'
    ? toTitleCase(string).replace('_', '-').replace('Convenience', 'C')
    : EMPTY_CELL_VALUE;

export const displayGasType = (string: string) =>
  string && string.toLowerCase() !== 'none' ? toTitleCase(string).replace('_', '-') : EMPTY_CELL_VALUE;

export const formatNumber = (options?: Partial<Intl.NumberFormatOptions>) => {
  return new Intl.NumberFormat(DEFAULT_LOCALE, {
    ...options,
  });
};

export const compactLargeNumber = (num: number) => {
  return `$${formatNumber({ notation: 'compact' }).format(num)}`;
};

export const displayPercent = (percent: number | string | undefined, precision = 1) => {
  if (percent) {
    let value = _.round(typeof percent == 'string' ? parseFloat(percent) : percent, 4);
    if (1 / value === -Infinity) {
      value = 0;
    }
    return `${(Number(value) * 100).toFixed(precision)}%`;
  }
  return '';
};

export const displayNumberWithDecimals = (number: number | string = 0, digits = 2) =>
  formatNumber({ minimumFractionDigits: digits, maximumFractionDigits: digits }).format(Number(number));

export const displayNumber = (value = 0): string => {
  return formatNumber().format(value);
};

export const displayUuid = (value: string): string => {
  return value.split('-')[0];
};

export const formatterSwitch = (options?: { isCurrency?: boolean; isPercentage?: boolean }): Intl.NumberFormat => {
  if (options?.isCurrency) {
    return formatCurrencyUSD();
  }

  if (options?.isPercentage) {
    return formatNumber({ style: 'percent', maximumFractionDigits: 2 });
  }

  return formatNumber({ maximumFractionDigits: 2, notation: 'compact' });
};

/**
 * Sorting function to be used to sort iterables by segment name.
 * This allows to show segments in the same order across all charts
 * and tables.
 */
export const fnSegmentOrder = (segment: string): number => {
  return SEGMENT_ORDERING[segment] || Infinity;
};

export const divideIfNotZero = (numerator: number, denominator: number): number => {
  if (denominator === 0 || isNaN(denominator)) {
    console.error('divide by 0!');
    return 0;
  }

  return numerator / denominator;
};

export const convertArrayToCSV = (objArray: ICSVData[]) => {
  if (objArray.length === 0) {
    return '';
  }

  const headers = Object.keys(objArray[0]);
  const csvRows = [];

  csvRows.push(headers.join(','));

  objArray.forEach((row) => {
    const values = headers.map((header) => {
      const val = row[header];
      return `"${val}"`;
    });

    csvRows.push(values.join(','));
  });

  return csvRows.join('\n');
};

export const downloadCSV = (csv: string, filename: string) => {
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.click();
  link.remove();
};

export const transactionTypeForApiCall = (transactionType: string) => {
  if (transactionType === INITIAL_TRANSACTION_TYPE) {
    return null;
  }

  return transactionType;
};

export const addSearchParamIfValueNotNull = (url: URL, name: string, value: string | null) => {
  if (value) {
    url.searchParams.append(name, value);
  }
};

export const getAmountOfTicksToRender = (length: number) => {
  if (length >= 18 && length <= 24) return 5;

  if (length > 24) return 7;

  return 3;
};

export const generateCopyrightYear = () => {
  const date = new Date();
  const year = date.getFullYear();
  return year;
};
