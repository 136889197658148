import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import { Button, Flex, Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react';

import { ISearchInput } from './types';

export const SearchInput = (props: ISearchInput) => {
  const { searchTerm, handleSearch, setSearchTerm, handleClosePopover, handleSearchInputKeyPress, placeholder } = props;
  return (
    <Flex>
      <InputGroup mb={3}>
        <InputLeftElement pointerEvents='none'>
          <SearchIcon color='text.default' />
        </InputLeftElement>
        <Input
          borderRadius='lg'
          borderColor='border.default'
          bg={'background.default'}
          type='text'
          name='search-location'
          placeholder={placeholder}
          onKeyPress={handleSearchInputKeyPress}
          _placeholder={{
            color: 'text.default',
          }}
          _focus={{ borderColor: 'border.primary', _placeholder: { color: 'text.placeholder' } }}
          value={searchTerm}
          onChange={handleSearch}
        />
        {searchTerm !== '' && (
          <InputRightElement>
            <CloseIcon boxSize={2} color={'icon.default'} onClick={() => setSearchTerm('')} />
          </InputRightElement>
        )}
      </InputGroup>
      {searchTerm !== '' && (
        <Button
          variant='link'
          color='text.primary.default'
          ml={2}
          alignItems={'start'}
          pt={2}
          onClick={handleClosePopover}
        >
          Cancel
        </Button>
      )}
    </Flex>
  );
};
