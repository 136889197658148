export const buildMockCustomerTransactions = (page: number) => {
  return page === 1 ? page1 : page2;
};

const getRandomDate = () => {
  const day = Math.floor(Math.random() * 26) + 1;
  const month = Math.floor(Math.random() * 12) + 1;
  const year = Math.floor(Math.random() * 4) + 2020;
  return `${year}-${month}-${day}T17:08:01Z`;
};

const getRandomTransaction = () => {
  return {
    user_uuid: 'cc18d0b0-ad60-00ac-8006-366cdf000000',
    site_name: '5178 - Marathon',
    site_display_name: 'Marathon',
    site_address_1: '11 Taft Hwy',
    transacted_at: getRandomDate(),
    payment_card_type: 'MASTERCARD',
    payment_card_last_four: '1557',
    gas_type: 'REGULAR',
    transaction_type: 'GAS',
    amount_spent: 107.81,
    total_fee_cost: 2.11,
    total_transaction_amount: 127.81,
    customer_promotion_cost: 4.95,
    total_promotion_cost: 5.95,
    net_profit: 4.1329,
    total_profit: 4.1329,
    total_incremental_profit: 4.1329,
    total_revenue: 4.1329,
    total_incremental_revenue: 4.1329,
    tax_amount: 0.1329,
    return_on_investment: 4.1329,
    gallons_bought: 32.98,
    margin_detail: {
      cc_fee: 0.07306,
      double_discount_adj: 0.0,
      margin: 0.41515,
      misc_adj_per_gallon: 0.0,
      rack_fee: 0.0493,
      rack_price: 2.32254,
      sign_price: 3.479,
      tax: 0.61895,
      opis_rack_details: {
        brand: 'UNBRD LOW',
        date: '2022-10-12',
        index_price: 'rack-prices-gross-cbob',
        rack_type: 'b',
        terminal_locality: 'BALTIMORE',
        terminal_region: 'MD',
      },
    },
    net_profit_total: '4.11',
    item_display_name: null,
    is_outlier_transaction: false,
  };
};

const getRandomTransactions = (numTransactions: number) =>
  Array.from({ length: numTransactions }, () => getRandomTransaction());

const page1 = {
  records: getRandomTransactions(26),
  next_page: 2,
  prev_page: null,
  count: 26,
};

const page2 = {
  records: getRandomTransactions(26),
  next_page: 3,
  prev_page: 1,
  count: 26,
};
