import { RootState } from '@app/store';
import { PaginationOptions } from '@components/pagination/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format } from 'date-fns';

import { Status } from '@/common/interfaces';
import { startOfMonth } from '@/common/utils/dates';

import { IInvoicesList } from './types';

const today = new Date();
const currMonthStart = startOfMonth(today);
const prevMonthStart = new Date(currMonthStart);
prevMonthStart.setMonth(prevMonthStart.getMonth() - 1);
const last6MonthsStart = new Date(prevMonthStart);
last6MonthsStart.setMonth(last6MonthsStart.getMonth() - 5);
const last6MonthsEnd = today;

export interface InvoicesListState {
  invoices: IInvoicesList;
  page: number;
  paginationOptions: PaginationOptions;
  status: Status.Idle | Status.Loading | Status.Failed;
  error?: string;
}

export interface InvoicesState {
  invoicesList: InvoicesListState;
  startDate: string;
  endDate: string;
  selectedEntities: string[];
  hasInvDataOnFirstLoad: boolean | null;
}

export const initialState: InvoicesState = {
  invoicesList: {
    invoices: {
      nextPage: null,
      prevPage: null,
      table: {
        rows: [],
      },
      rawData: [],
      count: 0,
      billableEntities: [],
    },
    page: 1,
    paginationOptions: {
      pageSize: 5,
      availablePageSizes: [5, 10, 50, 100, 250],
    },
    status: Status.Idle,
  },
  selectedEntities: [],
  startDate: format(last6MonthsStart, 'yyyy-MM-dd'),
  endDate: format(last6MonthsEnd, 'yyyy-MM-dd'),
  hasInvDataOnFirstLoad: null,
};

export const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    invoicesFetch: (
      state,
      _action: PayloadAction<{
        startDate: string;
        endDate: string;
        page: number;
        pageSize: number;
        accountType: string[];
        activeVertical: string;
        authRequestPath: string;
        roles: string[];
        isFirstLoad: boolean;
      }>,
    ) => {
      state.invoicesList.error = undefined;
      state.invoicesList.status = Status.Loading;
    },
    invoicesSucceeded: (state, action: PayloadAction<IInvoicesList>) => {
      state.invoicesList.invoices = action.payload;
      state.invoicesList.status = Status.Idle;
      state.hasInvDataOnFirstLoad = true;
    },
    invoicesFailed: (state, action: PayloadAction<string>) => {
      state.invoicesList.error = action.payload;
      state.invoicesList.status = Status.Failed;
    },
    setInvoicesListPaginationOptions: (state, action: PayloadAction<PaginationOptions>) => {
      state.invoicesList.paginationOptions = action.payload;
    },
    setInvoicesListPage: (state, action: PayloadAction<number>) => {
      state.invoicesList.page = action.payload;
    },
    setInvDateRange: (state, action: PayloadAction<{ startDate: string; endDate: string }>) => {
      const { startDate, endDate } = action.payload;
      state.startDate = startDate;
      state.endDate = endDate;
    },
    setSelectedAccounts: (state, action: PayloadAction<string[]>) => {
      state.selectedEntities = action.payload;
    },
    setHasDataOnFirstLoad: (state, action: PayloadAction<boolean | null>) => {
      state.hasInvDataOnFirstLoad = action.payload;
    },
  },
});

export const {
  invoicesFetch,
  invoicesSucceeded,
  invoicesFailed,
  setInvoicesListPaginationOptions,
  setInvoicesListPage,
  setInvDateRange,
  setSelectedAccounts,
  setHasDataOnFirstLoad,
} = invoicesSlice.actions;

export const selectInvoicesList = (state: RootState) => state.invoices.invoicesList;
export const selectInvoicesListStatus = (state: RootState) => state.invoices.invoicesList.status;
export const selectInvStartDate = (state: RootState) => state.invoices.startDate;
export const selectInvEndDate = (state: RootState) => state.invoices.endDate;
export const selectAvailableAccounts = (state: RootState) => state.invoices.invoicesList.invoices.billableEntities;
export const selectSelectedAccounts = (state: RootState) => state.invoices.selectedEntities;
export const selectHasInvDataOnFirstLoad = (state: RootState) => state.invoices.hasInvDataOnFirstLoad;

export default invoicesSlice.reducer;
