import { defineStyleConfig } from '@chakra-ui/react';

const LinkStyles = defineStyleConfig({
  baseStyle: {
    color: 'text.default',
    textDecoration: 'underline',
    textStyle: 'body.md',
    '& svg': {
      color: 'icon.default',
    },
    _hover: {
      color: 'text.primary.hover',

      '& svg': {
        color: 'icon.primary.hover',
      },
    },
  },
});

export default LinkStyles;
