/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@chakra-ui/react';
import { CardElement } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CARD_ELEMENT_OPTIONS: any = {
  iconStyle: 'solid',
  hidePostalCode: true,
  style: {
    base: {
      iconColor: 'icon.subtle',
      color: 'rgb(240, 57, 122)',
      fontSize: '16px',
      fontFamily: '"Open Sans", sans-serif',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#CFD7DF',
      },
    },
    invalid: {
      color: '#e5424d',
      ':focus': {
        color: '#303238',
      },
    },
  },
};

export function CardSection(props: any) {
  const { invalid, setInvalid, setFormDetails } = props;

  const styles = {
    cardBorder: {
      borderRadius: '8px',
      borderColor: invalid.paymentDetails ? '#EB5951' : '#181819',
      borderWidth: invalid.paymentDetails ? '2px' : '1px',
      borderStyle: 'solid',
      paddingTop: '10px',
      paddingBottom: '10px',
      paddingLeft: '14px',
    },
    cardBorderHover: {
      borderColor: '#C9C9C9',
      borderRadius: '8px',
      borderWidth: '1px',
      borderStyle: 'solid',
      paddingTop: '10px',
      paddingBottom: '10px',
      paddingLeft: '14px',
    },
  };
  const handlechange = (e: StripeCardElementChangeEvent) => {
    setFormDetails((prev: any) => {
      return {
        ...prev,
        paymentDetails: e,
      };
    });
    setInvalid((prev: any) => {
      if (e.complete === false) {
        return {
          ...prev,
          paymentDetails: true,
        };
      } else {
        return {
          ...prev,
          paymentDetails: false,
        };
      }
    });
  };

  return (
    <Box _hover={styles.cardBorderHover} style={styles.cardBorder}>
      <CardElement onChange={(e) => handlechange(e)} options={CARD_ELEMENT_OPTIONS} />
    </Box>
  );
}
