import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormControl, Input, Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { CustomFormLabel } from '@/common/components/form';
import { selectAuthRequestPath } from '@/features/auth/userSlice';

import { passwordUpdateRequest } from '../slice';
import { ChangePasswordFormData } from '../types';

import { changePasswordSchema } from './validators';

export function ChangePassword() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const authRequestPath = useAppSelector(selectAuthRequestPath);

  const formRef = useRef<HTMLFormElement>(null);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ChangePasswordFormData>({
    resolver: zodResolver(changePasswordSchema),
    mode: 'onChange',
  });

  const onSubmit = (formData: ChangePasswordFormData) => {
    try {
      const data = {
        authRequestPath,
        pswrd: formData.newPassword,
      };
      dispatch(passwordUpdateRequest({ data }));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box>
      <FormControl ref={formRef} as='form' mt={3}>
        <CustomFormLabel htmlFor='newPassword' pt={3} maxW={'350px'}>
          {t('profilePage.changePassword.fields.newPassword')}
          <Input
            type='password'
            id='newPassword'
            data-testid='newPassword'
            mt='3'
            size='lg'
            placeholder='New password'
            {...register('newPassword', { required: true })}
            isInvalid={errors.newPassword && errors.newPassword?.message !== ''}
          />
        </CustomFormLabel>
        {errors.newPassword && <Text variant={'error'}>{errors.newPassword.message}</Text>}
        <CustomFormLabel htmlFor='confirmNewPassword' pt={3} maxW={'350px'}>
          {t('profilePage.changePassword.fields.confirmNewPassword')}
          <Input
            type='password'
            id='confirmNewPassword'
            data-testid='confirmNewPassword'
            mt='3'
            size='lg'
            placeholder='Confirm new password'
            {...register('confirmNewPassword', { required: true })}
            isInvalid={errors.confirmNewPassword && errors.confirmNewPassword.message !== ''}
          />
        </CustomFormLabel>
        {errors.confirmNewPassword && (
          <Text data-testid='errorMsg' variant={'error'}>
            {errors.confirmNewPassword.message}
          </Text>
        )}

        <Button
          data-testid='updatePasswordBtn'
          variant='brandPrimary'
          onClick={handleSubmit(onSubmit)}
          size='md'
          px={8}
          mt='4'
        >
          {t('profilePage.changePassword.actions.update')}
        </Button>
      </FormControl>
    </Box>
  );
}
