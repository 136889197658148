import { useTranslation } from 'react-i18next';
import { Box, Heading, Stack, useToken } from '@chakra-ui/react';
import { ResponsiveRadialBar } from '@nivo/radial-bar';
import _ from 'lodash';

import { Status } from '../../interfaces';
import { displayPercent } from '../../utils';
import { EmptyDataAlert } from '../errorAlert';
import { LoadingSpinner } from '../loadingSpinner/LoadingSpinner';

import { IRadialBarChartProps } from './types';

export const RadialBarChart = ({
  data,
  loadingStatus,
  title,
  height = '450px',
  width = '100%',
  customProps,
}: IRadialBarChartProps) => {
  const [blue800, green400, neutral1200] = useToken('colors', ['blue.800', 'green.400', 'neutral.1200']);
  const { t } = useTranslation();
  const isLoading = loadingStatus === Status.Loading;
  const isEmptyData = loadingStatus === Status.Idle && _.isEmpty(data);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Metric = ({ center }: any) => {
    return (
      <text
        x={center[0] + 5}
        y={center[1] - 30}
        textAnchor='middle'
        dominantBaseline='central'
        style={{
          fontSize: 60,
          fill: neutral1200,
        }}
      >
        {displayPercent(Number(data ? data[0].data[0].y : 0) + Number(data ? data[0].data[1].y : 0))}
      </text>
    );
  };

  const commonProperties = {
    margin: { bottom: 25 },
    data,
    animate: true,
    activeOuterRadiusOffset: 8,
    valueFormat: (v: number | string) => `${v}%`,
    maxValue: 1,
    startAngle: -90,
    endAngle: 90,
    cornerRadius: 0,
    innerRadius: 0.8,
    colors: [blue800, green400],
    layers: ['tracks', 'bars', Metric],
    ...customProps,
  };

  return (
    <Box data-testid='radial-bar-chart' h={height} w={width} maxW={'100%'}>
      {title && (
        <Heading fontSize={'18px'} fontWeight={500} lineHeight={'28px'} opacity={isLoading ? '20%' : '100%'}>
          {t(`${title}`)}
        </Heading>
      )}
      <Stack
        data-testid='radial-bar-chart-container'
        w={'100%'}
        h={'100%'}
        align={isLoading || isEmptyData ? 'center' : 'default'}
        justify={isLoading || isEmptyData ? 'center' : 'default'}
      >
        {isLoading && <LoadingSpinner />}
        {isEmptyData && (
          <Box data-testid='empty-data-alert'>
            <EmptyDataAlert />
          </Box>
        )}
        {!isEmptyData && !isLoading && (
          <Box data-testid='responsive-radial-bar-chart' w='full' h='full'>
            <ResponsiveRadialBar {...commonProperties} />
          </Box>
        )}
      </Stack>
    </Box>
  );
};
