import React, { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppState, Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';

import { ENV_CONFIG } from '@/config';

interface Auth0ProviderWithNavigateProps {
  children: React.ReactNode;
}

export const Auth0ProviderWithNavigate = ({
  children,
}: PropsWithChildren<Auth0ProviderWithNavigateProps>): JSX.Element | null => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  const auth0ProviderConfig: Auth0ProviderOptions = {
    domain: ENV_CONFIG.AUTH_0.domain,
    clientId: ENV_CONFIG.AUTH_0.clientId,
    ...(ENV_CONFIG.AUTH_0.audience ? { audience: ENV_CONFIG.AUTH_0.audience } : null),
    ...(ENV_CONFIG.AUTH_0.scope ? { scope: ENV_CONFIG.AUTH_0.scope } : null),
    authorizationParams: {
      redirect_uri: window.location.origin,
    },
    useRefreshTokens: true,
    useRefreshTokensFallback: true,
    onRedirectCallback,
  };

  if (!(auth0ProviderConfig.domain && auth0ProviderConfig.clientId)) {
    console.error('Auth0ProviderWithNavigate: Missing required environment variables');
    return null;
  }

  return <Auth0Provider {...auth0ProviderConfig}>{children}</Auth0Provider>;
};
