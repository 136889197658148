import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle({
  control: {
    borderColor: 'border.dark',
    color: 'text.default',
    alignSelf: 'baseline',
    marginTop: 1,

    _checked: {
      background: 'background.primary.default',
      border: 'none',
    },

    _disabled: {
      borderColor: 'border.disabled',
      background: 'background.default',
    },
  },
});

export const RadioStyles = defineMultiStyleConfig({ baseStyle });
