/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'typed-redux-saga';

import * as api from './api';
import { buildInvoicesTable } from './dataFormatting';
import { invoicesFailed, invoicesFetch, invoicesSucceeded, setHasDataOnFirstLoad } from './slice';
import { IInvoicesList, IInvoicesRawData, InvoicesRequestPayload } from './types';

/*****************************************************************************/
/******************************* WORKERS *************************************/
/*****************************************************************************/
export function* fetchInvoices(action: PayloadAction<InvoicesRequestPayload>) {
  try {
    const { startDate, endDate, page, pageSize, authRequestPath, accountType, isFirstLoad, roles } = action.payload;

    const result: IInvoicesRawData = yield* call(api.fetchInvoices, {
      startDate,
      endDate,
      page,
      pageSize,
      entity_display_names: accountType,
      authRequestPath,
      roles,
    });

    if (isFirstLoad && result.count <= 0) {
      yield* put(setHasDataOnFirstLoad(false));
      return;
    }

    const table = buildInvoicesTable(result, action.payload.activeVertical);
    const billableEntities = result.account_names.filter((item) => item !== null) as string[];

    const data: IInvoicesList = {
      table,
      rawData: result.records,
      prevPage: result.prev_page,
      nextPage: result.next_page,
      count: result.count,
      billableEntities,
    };

    yield* put(invoicesSucceeded(data));
  } catch (error: any) {
    yield* put(invoicesFailed(error.message));
  }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/
export function* watchInvoices() {
  yield* takeLatest(invoicesFetch, fetchInvoices);
}
