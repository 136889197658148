import { z } from 'zod';

import {
  addFuelLocationAdditionalOfferSchema,
  addFuelLocationBasicInfoSchema,
  addFuelLocationClassOfTradeSchema,
  addFuelLocationFixedMarginsSchema,
  addFuelLocationLoginCredentialsSchema,
  addFuelLocationOpisRackMarginsSchema,
  addFuelLocationTransactionDataSchema,
  addLocationSettingsSchema,
  editFuelLocationSchema,
} from './validators';

export interface LocationSettingsData {
  id: number;
  uuid: string;
  merchantUuid: string;
  offerCategory: string;
  name: string;
  additionalProperties: Partial<AdditionalProperties>;
  visibility: string;
  settings: Partial<Settings>;
  redemptionMethods: string[];
  timezone: string;
  address: Address;
  relationshipType: string;
}

export interface LocationSettingsRawData {
  id: number;
  uuid: string;
  merchantUuid: string;
  offerCategory: string;
  name: string;
  additionalProperties: AdditionalPropertiesRawData;
  visibility: string;
  settings: Settings;
  redemptionMethods: string[];
  timezone: string;
  address: Address;
  relationshipType: string;
}

export interface AdditionalProperties {
  dataSources?: ('PROCESSOR' | 'LINKED_PROCESSOR' | 'DIRECT' | undefined)[];
  dataStatus: {
    daysOfHistoricalTransactions: number;
    historicalLookBackPeriod: number;
  };
  displayName: string;
  gasBrand?: string;
  gasPrices?: Record<string, number>;
  margins?: GradeMargins;
  opisRackTerminal?: {
    locality?: string;
    region?: string;
  };
  services: string[];
  classOfTrade?: 'COCA' | 'COCO' | 'CODO' | 'DODO' | 'DTW';
  processorCredentials?: ProcessorCredentials;
}

export interface ProcessorCredentials {
  username?: string;
  password?: string;
  website?: string;
}

export interface ProcessorData {
  uuid: string;
  valid: boolean;
  username: string;
  password: string;
  url: string;
}

export interface GradeMargins {
  REGULAR?: GasGrade;
  MIDGRADE?: GasGrade;
  PREMIUM?: GasGrade;
  DIESEL?: GasGrade;
  GLOBAL?: { percent: number };
  CONVENIENCE_STORE?: {
    percent: number;
  };
}

export interface GasGrade {
  amount: number;
  currency: string;
}

export interface AdditionalPropertiesRawData {
  dataSources?: string;
  dataStatus?: string;
  displayName?: string;
  gasBrand?: string;
  gasPrices?: string;
  margins?: string;
  opisRackTerminal?: string;
  services?: string;
  classOfTrade?: 'COCA' | 'COCO' | 'CODO' | 'DODO' | 'DTW';
  processorCredentials?: string;
}

export interface Settings {
  storedValueSettings?: StoredValueSettings;
  defaultOfferSettings?: DefaultOfferSetting[];
  marginSettings: Partial<MarginSettings>;
  offerLimitSettings?: OfferLimitSettings;
  processorSettings?: ProcessorSettings;
  configurationConfirmedAt?: string;
  firstPositionConfiguration?: Record<string, number>;
  baselineVersion: string;
  cardIdFormat: string | null;
  rmpStatus: string;
  subsidized: boolean;
}

export interface StoredValueSettings {
  // templates: any[];
  storedValueEnabled: boolean;
  storePercentage: number;
  fixedUpsideCreditPerGallon: FixedUpsideCreditPerGallon;
}

export interface FixedUpsideCreditPerGallon {
  amount: number;
  currency: string;
}

export interface DefaultOfferSetting {
  type: string;
  distributionMean: DistributionMean;
  distributionStdDev: DistributionStdDev;
  roi: Roi;
  minimumOffer: MinimumOffer;
  maximumOffer: MaximumOffer;
  marginPercent: number;
}

export interface DistributionMean {
  percent: number;
}

export interface DistributionStdDev {
  percent: number;
}

export interface Roi {
  percent: number;
}

export interface MinimumOffer {
  amount?: number;
  currency?: string;
  percent?: number;
}

export interface MaximumOffer {
  amount?: number;
  currency?: string;
  percent?: number;
}

export interface MarginSettings {
  outsideGasFee: OutsideGasFee;
  fixedMargin: number | null;
  incrementalFee: IncrementalFee;
  excludeTaxes: boolean;
  marginAdjustment: number | null;
  miscellaneousAdjustmentPerGradePerGallon: Record<string, number>;
}

export interface OutsideGasFee {
  amount: number;
  currency: string;
}

export interface IncrementalFee {
  percent: number;
}

export interface OfferLimitSettings {
  enabled: boolean;
}

export interface ProcessorSettings {
  expectedIngestLag: number;
}

export interface Address {
  address1: string;
  address2?: string;
  locality: string;
  region: string;
  postCode: string;
  countryCode?: string;
  latitude?: number;
  longitude?: number;
  latitude_1e6?: number;
  longitude_1e6?: number;
}

export enum LocationStatus {
  ANY = 'ANY',
  DEFAULT = 'DEFAULT',
  PRE_LAUNCH = 'PRE_LAUNCH',
  TEST = 'TEST',
  UNLISTED = 'UNLISTED',
  DEAD = 'DEAD',
  HISTORICAL = 'HISTORICAL',
}

export enum DataSources {
  PROCESSOR = 'PROCESSOR',
  LINKED_PROCESSOR = 'LINKED_PROCESSOR',
  DIRECT = 'DIRECT',
}

export type AddLocationSettingsFormData = z.infer<typeof addLocationSettingsSchema>;
export type AddFuelLocationBasicInfoFormData = z.infer<typeof addFuelLocationBasicInfoSchema>;
export type AddFuelLocationOpisRackMarginsInputFormData = z.input<typeof addFuelLocationOpisRackMarginsSchema>;
export type AddFuelLocationOpisRackMarginsOutputFormData = z.infer<typeof addFuelLocationOpisRackMarginsSchema>;
export type AddFuelLocationFixedMarginsFormData = z.infer<typeof addFuelLocationFixedMarginsSchema>;
export type AddFuelLocationClassOfTradeFormData = z.infer<typeof addFuelLocationClassOfTradeSchema>;
export type AddFuelLocationTransactionDataFormData = z.infer<typeof addFuelLocationTransactionDataSchema>;
export type AddFuelLocationLoginCredentialsSchema = z.infer<typeof addFuelLocationLoginCredentialsSchema>;
export type AddFuelLocationAdditionalOffersInputFormData = z.input<typeof addFuelLocationAdditionalOfferSchema>;
export type AddFuelLocationAdditionalOffersOutputFormData = z.infer<typeof addFuelLocationAdditionalOfferSchema>;

export type EditFuelLocationInputFormData = z.input<typeof editFuelLocationSchema>;
export type EditFuelLocationOutputFormData = z.infer<typeof editFuelLocationSchema>;
