export enum GranularityType {
  Transaction = 'transaction',
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Yearly = 'yearly',
}

export enum DeliveryMethodType {
  Email = 'email',
  Direct = 'direct',
}

export enum FileFormatType {
  CSV = 'csv',
  XLSX = 'xlsx',
}

export type CustomReportOptions = {
  granularity: GranularityType;
  startDate: string;
  endDate: string;
  includedFields: string[];
  deliveryMethod: DeliveryMethodType;
  fileFormat: FileFormatType;
  recipientEmail?: string;
};

export type ColumnData = {
  name: string;
  column_name: string;
  display_name: string;
  order_rank: number;
  data_type: string;
  definition: string | null;
  dependencies: string[];
};

export type ColumnsList = {
  transaction: ColumnData[];
  summary: ColumnData[];
};
