import { RootState } from '@app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Status, UserRoles, Verticals } from '@/common/interfaces';
import { setRequestMasquerade } from '@/common/utils';
import { ENV_CONFIG } from '@/config';
import { getVerticalReadRole } from '@/config/mvMapping';

export interface UserState {
  roles: UserRoles[];
  org_id: string;
  activeVertical: Verticals;
  activeMerchantUuid: string;
  activeUserUuid: string;
  activeMerchantName: string;
  activeOrgId: string;
  isUpsider: boolean;
  isUpsideManager: boolean;
  isDealer: boolean;
  isParentOrg: boolean;
  email: string;
  status: Status;
  error: string;
}

export const initialState: UserState = {
  roles: [],
  org_id: '',
  activeVertical: Verticals.Unknown,
  activeMerchantUuid: '',
  activeUserUuid: '',
  activeMerchantName: '',
  activeOrgId: '',
  isUpsider: false,
  isUpsideManager: false,
  isDealer: false,
  isParentOrg: false,
  email: '',
  status: Status.Loading,
  error: '',
};

export const checkIsUpsider = (payload: { org_id: string; roles: UserRoles[] }) => {
  if (
    payload.org_id === ENV_CONFIG.UPSIDE_ORG_ID &&
    (payload.roles.includes(`UpsideReadOnly` as UserRoles) || payload.roles.includes(`UpsideManager` as UserRoles))
  ) {
    return true;
  }
  return false;
};

export const checkIsUpsideManager = (payload: { org_id: string; roles: string[] }) => {
  if (payload.org_id === ENV_CONFIG.UPSIDE_ORG_ID && payload.roles.includes(`UpsideManager`)) {
    return true;
  }
  return false;
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (
      state,
      action: PayloadAction<{
        email: string;
        org_id: string;
        roles: UserRoles[];
        vertical: Verticals;
        isParentOrg: boolean;
      }>,
    ) => {
      state.email = action.payload.email;
      state.roles = action.payload.roles;
      state.org_id = action.payload.org_id;
      state.isUpsider = checkIsUpsider(action.payload);
      state.isUpsideManager = checkIsUpsideManager(action.payload);
      state.isParentOrg = action.payload.isParentOrg;
      state.activeVertical = action.payload.vertical;
      state.status = Status.Idle;
    },
    setActiveVertical: (state, action: PayloadAction<Verticals>) => {
      state.activeVertical = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setUpsideMasquerade: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        if (state.isUpsider || state.isUpsideManager) {
          const msg = state.isUpsider ? `Upsider is masquerading as:` : `Upsider Manager is masquerading as:`;
          console.log(msg);
          const msgPayload = JSON.stringify(action.payload);
          console.log(msg + msgPayload);

          state.activeVertical = action.payload.vertical;
          state.isDealer = action.payload.isDealer;
          state.activeOrgId = action.payload.orgId;

          if (action.payload.isDealer) {
            state.activeUserUuid = action.payload.uuid;
            state.activeMerchantName = action.payload.email;
            state.activeMerchantUuid = '';
          } else {
            state.activeMerchantUuid = action.payload.uuid;
            state.activeMerchantName = action.payload.name;
            state.activeUserUuid = '';
          }

          setRequestMasquerade(true, false, state.activeMerchantUuid, state.activeUserUuid, state.activeOrgId);
        }

        if (state.isParentOrg) {
          const msg = `Parent Org is masquerading as ${JSON.stringify(action.payload)}`;
          console.log(msg);

          state.activeVertical = action.payload.vertical;
          state.activeMerchantName = action.payload.name;
          state.isDealer = action.payload.isDealer;
          state.activeOrgId = action.payload.orgId;

          if (action.payload.isDealer) {
            state.activeUserUuid = action.payload.uuid;
            state.activeMerchantUuid = '';
          } else {
            state.activeMerchantUuid = action.payload.uuid;
            state.activeUserUuid = '';
          }

          setRequestMasquerade(false, true, state.activeMerchantUuid, state.activeUserUuid, state.activeOrgId);
        }
      } else {
        console.error('Masquerade payload is empty');
      }
    },
  },
});

export const { setUser, setActiveVertical, setUpsideMasquerade } = userSlice.actions;

export const selectDashboardUser = (state: RootState) => state.user;
export const selectUserStatus = (state: RootState) => state.user.status;
export const selectUserOrg = (state: RootState) => state.user.org_id;
export const selectUserRoles = (state: RootState) => state.user.roles;
export const selectActiveVertical = (state: RootState) => state.user.activeVertical;
export const selectAuthRequestPath = (state: RootState) => {
  const role = getVerticalReadRole(state.user.roles, state.user.activeVertical);
  return `${state.user.org_id}/${role}/${state.user.activeVertical}`;
};
export const selectIsUpsider = (state: RootState) => state.user.isUpsider;
export const selectIsDealer = (state: RootState) => state.user.isDealer;
export const selectIsUpsideManager = (state: RootState) => state.user.isUpsideManager;
export const selectIsParentOrg = (state: RootState) => state.user.isParentOrg;
export const selectUserEmail = (state: RootState) => state.user.email;
export const selectActiveMerchantUuid = (state: RootState) => state.user.activeMerchantUuid;
export const selectActiveUserUuid = (state: RootState) => state.user.activeUserUuid;
export const selectActiveMerchantName = (state: RootState) => state.user.activeMerchantName;

export default userSlice.reducer;
