import colors from '@/theme/foundations/colors';

import { CompetitorTypes } from './types';

export const MILES_TO_METERS = 1609.34;

export const competitorsLegend = [
  {
    label: 'Your locations',
    value: 'LOCATIONS',
    color: colors.blue['1200'],
    disabled: true,
  },
  {
    label: 'Blocked Competitors',
    value: CompetitorTypes.BLOCKED_COMPETITORS,
    color: colors.orange['400'],
    divider: true,
  },
  {
    label: 'Other Upside locations',
    value: CompetitorTypes.UPSIDE,
    color: colors.cyan['400'],
  },
  {
    label: 'Competitors',
    value: CompetitorTypes.COMPETITORS,
    color: colors.yellow['200'],
  },
  {
    label: 'Exclusivity zone',
    value: 'EXCLUSIVITY_ZONE',
    color: colors.blue['200'],
    divider: true,
  },
];
