/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, select, takeLatest } from 'typed-redux-saga';

import * as api from './api';
import { formatData } from './dataFormatting';
import { promotionsFailed, promotionsFetch, promotionsSucceeded } from './slice';

/*****************************************************************************/
/******************************* WORKERS *************************************/
/*****************************************************************************/
export function* fetchPromotions(action: any) {
  try {
    const state = yield* select();
    const { activeVertical } = state.user;
    const { selectedTransactionType } = state.dashboard;
    const result = yield* call(api.fetchPromotionsData, action.payload);
    const refinedResult = formatData(result, action.payload.temporalUnit, activeVertical, selectedTransactionType);
    yield* put(promotionsSucceeded(refinedResult));
  } catch (error: any) {
    yield* put(promotionsFailed(error.message));
  }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/
export function* watchPromotions() {
  yield* takeLatest(promotionsFetch, fetchPromotions);
}
