import { Box, Flex } from '@chakra-ui/react';

import { LoadingSpinner } from '../loadingSpinner/LoadingSpinner';

import { AsyncPaginationContainerProps } from './types';

export const AsyncPaginationContainer = (props: AsyncPaginationContainerProps) => {
  const { data, page, nextPage, prevPage, total, isLoading, onPageChange, onPageSizeChange, options } = props;
  const { pageSize } = options;

  if (!Array.isArray(data)) {
    return null;
  }

  const pageStartIdx = (page - 1) * pageSize + 1;
  const pageEndIdx = total ? Math.min(total, pageStartIdx + data.length - 1) : pageStartIdx + data.length - 1;

  function goBackward() {
    if (prevPage && typeof onPageChange === 'function') {
      onPageChange(prevPage);
    }
  }

  function goForward() {
    if (nextPage && nextPage > 1 && typeof onPageChange === 'function') {
      onPageChange(nextPage);
    }
  }

  function changePageSize(size: number) {
    if (typeof onPageSizeChange === 'function') {
      onPageSizeChange(size);
    }
  }

  return (
    <Box position='relative'>
      {isLoading === true ? (
        <Flex
          position='absolute'
          left={0}
          top={0}
          right={0}
          bottom={0}
          bgColor='rgba(255, 255, 255, 0.75)'
          justifyContent='center'
          alignItems='center'
        >
          <LoadingSpinner />
        </Flex>
      ) : (
        props.children({
          data,
          pageStartIdx: pageStartIdx,
          pageEndIdx,
          totalItems: total,
          isLoading,
          pageSize,
          availablePageSizes: options.availablePageSizes,
          hasNext: !!nextPage && nextPage > 1,
          hasPrev: !!prevPage && prevPage > 0,
          goBackward,
          goForward,
          changePageSize,
        })
      )}
    </Box>
  );
};
