import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, GridItem, Heading, useToken } from '@chakra-ui/react';
import { LoadingSpinner } from '@components/loadingSpinner';
import { CartesianMarkerProps } from '@nivo/core';
import { LineSvgProps, PointTooltipProps, ResponsiveLine } from '@nivo/line';
import _ from 'lodash';

import { displayCurrency } from '@/common/utils';

import { CustomerVsControlChartProps, CustomerVsControlTick } from './types';

export const TOTAL_CUSTOMER_SALES_SERIES_ID = 'totalCustomerSales';
export const TOTAL_CONTROL_SALES_SERIES_ID = 'totalControlSales';

export const CustomerVsControlChart = ({
  title,
  width = '100%',
  data,
  id,
  ...chartProps
}: CustomerVsControlChartProps) => {
  const [dataBlue] = useToken('colors', ['blue.800']);

  const { t } = useTranslation();

  const LEFT_LEGEND_WIDTH = 110;
  const TICK_THRESHOLD_MIN = -12;

  const chartRef = useRef<HTMLDivElement>(null);

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [markerOffset, setMarkerOffset] = useState(0);

  useEffect(() => {
    const getDimensions = () => ({
      width: chartRef.current ? chartRef.current.offsetWidth : 0,
      height: chartRef.current ? chartRef.current.offsetHeight : 0,
    });

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (chartRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize-cumulative-graph', handleResize);

    return () => {
      window.removeEventListener('resize-cumulative-graph', handleResize);
    };
  }, [chartRef, data]);

  useEffect(() => {
    const getMarkerOffset = () => (dimensions.width - LEFT_LEGEND_WIDTH) / data[0].data.length / 1.625;
    setMarkerOffset(getMarkerOffset());
  }, [data, dimensions]);

  const marker: CartesianMarkerProps = {
    axis: 'x',
    value: -1,
    lineStyle: { stroke: dataBlue, strokeWidth: 4 },
  };

  const commonProperties: LineSvgProps = _.defaultsDeep({ data }, chartProps, {
    margin: { top: 50, right: 20, bottom: 100, left: 90 },
    gridYValues: 4,
    xScale: { type: 'point' },
    yScale: {
      type: 'linear',
      stacked: false,
      reverse: false,
    },
    yFormat: ' >-.2f',
    curve: 'stepBefore',
    axisTop: null,
    axisRight: null,
    axisBottom: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legendOffset: 46,
      legendPosition: 'middle',
      renderTick: (tick: CustomerVsControlTick) => {
        return Number(tick.value) >= TICK_THRESHOLD_MIN ? (
          <g transform={`translate(${tick.x - markerOffset},${tick.y + 15})`}>
            <text
              x={0}
              y={0}
              textAnchor='middle'
              dominantBaseline='middle'
              style={{
                fill: 'rgb(51, 51, 51)',
                fontSize: 12,
                fontFamily: 'sans-serif',
              }}
            >
              {tick.value}
            </text>
          </g>
        ) : null;
      },
    },
    axisLeft: {
      tickValues: 4,
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legendOffset: -80,
      legendPosition: 'middle',
    },
    enablePoints: false,
    enableArea: true,
    lineWidth: 0,
    useMesh: true,
    enableGridX: false,
    areaBaselineValue: 0,
    markers: [marker],
    // All these layers are the default onces and added here just to reorder them
    // and put markers and axes above the lines.
    layers: ['grid', 'lines', 'areas', 'crosshair', 'axes', 'points', 'markers', 'slices', 'mesh', 'legends'],
  });

  if (commonProperties.axisLeft?.format === 'currency') {
    commonProperties.axisLeft.format = displayCurrency;
  }
  if (commonProperties.axisBottom?.format === 'currency') {
    commonProperties.axisBottom.format = displayCurrency;
  }

  commonProperties.tooltip = (point: PointTooltipProps) => {
    const x: number =
      Number(point.point.data.x) >= TICK_THRESHOLD_MIN ? Number(point.point.data.x) : TICK_THRESHOLD_MIN;
    const values: { [key: string]: number } = _.reduce(
      data,
      (accum, entry) => {
        const column = entry.data.find((dataEntry) => dataEntry.x === x);
        accum[entry.id] = column ? column.y : 0;
        return accum;
      },
      {} as { [key: string]: number },
    );
    return (
      <Grid
        templateColumns='1fr auto'
        rowGap={1}
        columnGap={2}
        bgColor='white'
        shadow='base'
        borderRadius={10}
        padding={2}
        marginRight={150}
      >
        <GridItem>{t('customerDeepDivePage.customerVsControlChart.relativeMonth')}</GridItem>
        <GridItem>{x}</GridItem>
        <GridItem>{t('customerDeepDivePage.customerVsControlChart.monthlyCustomerSales')}</GridItem>
        <GridItem>{displayCurrency(values['totalCustomerSales'], 0)}</GridItem>
        <GridItem>{t('customerDeepDivePage.customerVsControlChart.monthlyControlSales')}</GridItem>
        <GridItem>{displayCurrency(values['totalControlSales'], 0)}</GridItem>
      </Grid>
    );
  };

  return (
    <>
      {!_.isEmpty(data) ? (
        <Box width={width}>
          <Heading size='md' p={'.5em'}>
            {title}
          </Heading>
          <Box h={'400px'} width={width} ref={chartRef} zIndex={1} p={'0.5em'} id={id}>
            <ResponsiveLine {...commonProperties} />
          </Box>
        </Box>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};
