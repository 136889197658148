import { ReactNode } from 'react';
import { Box, Button, ChakraStyledOptions } from '@chakra-ui/react';

interface DateRangePresetItemProps {
  id: string;
  start?: Date;
  end?: Date;
  label: string;
  disabled: boolean;
  selected: boolean;
  isCustom?: boolean;
  children: ReactNode[] | ReactNode;
  onSelect(id: string, start: Date | undefined, end: Date | undefined, isCustom: boolean): void;
}

const selectedStyles: ChakraStyledOptions = {
  bg: 'blue.50',
  color: 'neutral.1200',
};

export const DateRangePresetItem = (props: DateRangePresetItemProps) => {
  const testId = `preset-${props.label.toLowerCase().split(' ').join('-')}`;

  const handleClick = () => {
    props.onSelect(props.id, props.start, props.end, Boolean(props.isCustom));
  };

  const styles: ChakraStyledOptions = {
    padding: '8px 16px',
    color: 'neutral.1200',
    cursor: 'pointer',
    borderRadius: '99px',
    textAlign: 'left',
    mt: 1,
    bg: 'none',
    fontWeight: 'normal',
    width: '100%',
    justifyContent: 'start',
    alignItems: 'center',
    isDisabled: props.disabled,
    _hover: {
      bg: 'blue.50',
      color: 'neutral.1200',
    },
    _disabled: {
      color: 'neutral.100',
      cursor: 'auto',
    },
  };

  if (props.selected) {
    Object.assign(styles, selectedStyles);
  }

  return (
    <Box as={Button} {...styles} onClick={handleClick} data-testid={testId}>
      {props.children}
    </Box>
  );
};
