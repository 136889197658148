import { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';

import { PaginationContainerProps } from './types';

export const PaginationContainer = (props: PaginationContainerProps) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(0);
  const { data, options } = props;

  useEffect(() => {
    setPage(1);
    setPageSize(options.pageSize);
  }, [options.pageSize]);

  if (!Array.isArray(data)) {
    return null;
  }

  const maxPage = Math.ceil(data.length / pageSize);
  const offset = (page - 1) * pageSize;
  const pageData = data.slice(offset, offset + pageSize);
  const pageStartIdx = Math.min(data.length, offset + 1);
  const pageEndIdx = Math.min(data.length, pageStartIdx + pageSize - 1);

  function goBackward() {
    if (page > 1) {
      setPage(page - 1);
    }
  }

  function goForward() {
    if (page < maxPage) {
      setPage(page + 1);
    }
  }

  function changePageSize(size: number) {
    setPage(1);
    setPageSize(size);
  }

  return (
    <Box data-testid='pagination-container'>
      {props.children({
        data: pageData,
        pageStartIdx: pageStartIdx,
        pageEndIdx,
        totalItems: data.length,
        pageSize,
        availablePageSizes: options.availablePageSizes,
        hasNext: page < maxPage,
        hasPrev: page > 1,
        goBackward,
        goForward,
        changePageSize,
      })}
    </Box>
  );
};
