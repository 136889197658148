import { alertAnatomy } from '@chakra-ui/anatomy';
import { AlertProps, createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(alertAnatomy.keys);

const baseStyle = definePartsStyle((props: AlertProps) => {
  const { status } = props;

  const successBase = status === 'success' && {
    container: {
      background: 'background.success.default',
    },
  };

  const warningBase = status === 'warning' && {
    container: {
      background: 'background.warning.default',
    },
  };

  const errorBase = status === 'error' && {
    container: {
      background: 'background.danger.default',
    },
  };

  const infoBase = status === 'info' && {
    container: {
      background: 'background.primary.default',
    },
  };

  return {
    title: {
      textStyle: 'heading.sm',
      fontSize: 'md',
      fontWeight: 'bold',
      color: 'text.default-inverse',
    },
    description: {
      textStyle: 'body.sm',
      fontSize: 'md',
      color: 'text.default-inverse',
    },
    ...successBase,
    ...warningBase,
    ...errorBase,
    ...infoBase,
  };
});

const Alert = defineMultiStyleConfig({
  baseStyle,
});

export default Alert;
