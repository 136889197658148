import { Flex, Wrap, WrapItem } from '@chakra-ui/react';

import { RangeDatePicker } from '@/features/filter/components/datePicker';

import { AccountDropdown } from './AccountDropdown';

type FinancialReportsFiltersProps = {
  startDate: string;
  endDate: string;
  availableAccounts?: string[];
  handleChangeDate: (dates: Date[]) => void;
  handleFilterAccount?: (accounts: string[]) => void;
};

export const FinancialReportsFilters = ({
  startDate,
  endDate,
  handleChangeDate,
  availableAccounts,
  handleFilterAccount,
}: FinancialReportsFiltersProps) => {
  let selectedDates: Date[] = [];
  if (startDate && endDate) {
    selectedDates = [new Date(`${startDate}T00:00:00`), new Date(`${endDate}T00:00:00`)];
  }

  return (
    <>
      <Flex>
        <Wrap align='flex-start' justify='flex-end' flexDirection='row'>
          <WrapItem flexDirection='row' gap={2} padding={2} alignItems='flex-end'>
            {availableAccounts && handleFilterAccount && (
              <Flex>
                <AccountDropdown availableAccounts={availableAccounts} handleFilter={handleFilterAccount} />
              </Flex>
            )}
            <Flex>
              <RangeDatePicker selectedRange={selectedDates} onDateChange={handleChangeDate} />
            </Flex>
          </WrapItem>
        </Wrap>
      </Flex>
    </>
  );
};
