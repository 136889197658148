import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'typed-redux-saga';

import * as api from './api';
import { formatData } from './dataFormatting';
import {
  competitorsFailed,
  competitorsFetch,
  competitorsSucceeded,
  locationsFailed,
  locationsFetch,
  locationsSucceeded,
} from './slice';
import { ICompetitorsRequestPayload, ILocationsRequestPayload } from './types';

/*****************************************************************************/
/******************************* WORKERS *************************************/
/*****************************************************************************/

export function* fetchLocations(action: PayloadAction<ILocationsRequestPayload>) {
  try {
    const result = yield* call(api.fetchLocations, action.payload);
    const data = formatData(result);

    yield* put(locationsSucceeded(data));
  } catch (error: unknown) {
    const err = error instanceof Error ? error.message : 'Failed to fetch locations';
    yield* put(locationsFailed(err));
  }
}

export function* fetchCompetitors(action: PayloadAction<ICompetitorsRequestPayload>) {
  try {
    const result = yield* call(api.fetchCompetitors, action.payload);

    yield* put(competitorsSucceeded(result));
  } catch (error: unknown) {
    const err = error instanceof Error ? error.message : 'Failed to fetch competitors';
    yield* put(competitorsFailed(err));
  }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/
export function* watchLocations() {
  yield* takeLatest(locationsFetch, fetchLocations);
}

export function* watchCompetitors() {
  yield* takeLatest(competitorsFetch, fetchCompetitors);
}
