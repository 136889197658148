import { Box, RadioProps, useRadio } from '@chakra-ui/react';

export function TransactionTypeRadio(props: RadioProps) {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as='label' w='full'>
      <input {...input} />
      <Box
        {...checkbox}
        cursor='pointer'
        color='neutral.1200'
        borderRadius='full'
        px='2'
        py='1'
        w='full'
        _hover={{
          bg: 'blue.50',
        }}
        _checked={{
          bg: 'blue.75',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
}
