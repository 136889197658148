import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PlaidLinkOnSuccess } from 'react-plaid-link';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Checkbox,
  Flex,
  FormControl,
  Heading,
  Input,
  Select,
  Text,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { CustomFormLabel } from '@/common/components/form';
import { Section } from '@/common/components/section';
import { Status } from '@/common/interfaces';
import { selectActiveMerchantUuid, selectAuthRequestPath, selectUserRoles } from '@/features/auth/userSlice';

import { usePlaidLinkFunctions } from '../../hooks';
import {
  addNewBillingMethodsRequest,
  billingPlaidTokenFetchRequest,
  selectBillingLoadingStatus,
  selectBillingPlaidToken,
  setNewBillingMethodData,
} from '../slice';
import { AddBillingMethodFormType, IAddBillingMethodDataItem } from '../types';
import { addBillingMethodFormSchema } from '../validators';

export const AddBankAccount = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const toast = useToast();

  const billingPlaidToken = useAppSelector(selectBillingPlaidToken);
  const roles = useAppSelector(selectUserRoles);
  const loadingStatus = useAppSelector(selectBillingLoadingStatus);
  const merchantUUID = useAppSelector(selectActiveMerchantUuid);
  const authRequestPath = useAppSelector(selectAuthRequestPath);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddBillingMethodFormType>({
    resolver: zodResolver(addBillingMethodFormSchema),
  });

  useEffect(() => {
    dispatch(billingPlaidTokenFetchRequest({ authRequestPath, roles }));
  }, [authRequestPath, dispatch, roles]);

  const isTokenExpired = () => {
    if (!billingPlaidToken || !billingPlaidToken.expiration || !billingPlaidToken.link_token) {
      return true;
    }

    const now = new Date();
    return billingPlaidToken.expiration < now.getTime();
  };

  const onSubmit: SubmitHandler<AddBillingMethodFormType> = (data) => {
    if (isTokenExpired()) {
      dispatch(billingPlaidTokenFetchRequest({ authRequestPath, roles }));
    }
    dispatch(setNewBillingMethodData(data));
    open();
  };

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    async (publicToken, metadata) => {
      // send public_token to your server
      // https://plaid.com/docs/api/tokens/#token-exchange-flow
      toast({
        title: 'Saving bank account...',
        status: 'info',
        duration: 2000,
        isClosable: true,
      });

      const isBank = true;
      const data: IAddBillingMethodDataItem = {
        merchant_uuid: merchantUUID,
        is_default: false,
        nickname: 'test',
        plaid_link_account_id: metadata.accounts[0].id,
        plaid_link_public_token: publicToken,
        source_token: '',
        site_uuid: '',
        account_holder_name: '',
        account_holder_type: '',
      };

      dispatch(addNewBillingMethodsRequest({ authRequestPath, roles, data, isBank }));
    },
    [authRequestPath, dispatch, merchantUUID, roles, toast],
  );

  const { open } = usePlaidLinkFunctions({ onSuccess, token: billingPlaidToken.link_token });

  return (
    <>
      <Breadcrumb separator={<ChevronRightIcon color='gray.500' />}>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to='/settings/payments'>
            {t('payment.billingPaymentMethods.addBankAccount.breadcrumbs.payments')}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink as={Link} isCurrentPage to='#'>
            {t('payment.billingPaymentMethods.addBankAccount.breadcrumbs.addBankAccount')}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Section name={t('payment.billingPaymentMethods.addBankAccount.title')}>
        <Box
          opacity={loadingStatus !== Status.Loading ? 1 : 0.3}
          pointerEvents={loadingStatus === Status.Loading ? 'none' : 'all'}
        >
          <Box mt={6} maxWidth={'600px'}>
            <Heading size='md'>{t('payment.addBank')}</Heading>
            <Text>{t('payment.billingPaymentMethods.addBankAccount.subTitle')}</Text>
          </Box>
          <Box maxWidth={'600px'}>
            <FormControl as='form' mt={3} onSubmit={handleSubmit(onSubmit)}>
              <Text mt={6} mb={4} size='md' textStyle={'heading.md'}>
                {t('payment.addBankForm.bankAccountDetails')}
              </Text>

              <CustomFormLabel htmlFor='site_uuid'>
                {t('payment.managePayment.site_uuid')}
                <Input id='site_uuid' type='text' {...register('site_uuid', { required: false })} />
              </CustomFormLabel>

              <CustomFormLabel
                htmlFor='account_nickname'
                pt={3}
                isInvalid={errors.account_nickname && errors.account_nickname?.message !== ''}
              >
                {t('payment.addBankForm.accountNickname')}
                <Input
                  type='text'
                  id='account_nickname'
                  {...register('account_nickname', { required: true })}
                  isInvalid={errors.account_nickname && errors.account_nickname?.message !== ''}
                />
              </CustomFormLabel>
              {errors.account_nickname && <Text variant={'error'}>{errors.account_nickname?.message}</Text>}

              <CustomFormLabel
                htmlFor='account_holder_name'
                pt={3}
                isInvalid={errors.account_holder_name && errors.account_holder_name?.message !== ''}
              >
                {t('payment.addBankForm.accountHolderName')}
                <Input
                  type='text'
                  id='account_holder_name'
                  {...register('account_holder_name', { required: true })}
                  isInvalid={errors.account_holder_name && errors.account_holder_name?.message !== ''}
                />
              </CustomFormLabel>
              {errors.account_holder_name && <Text variant={'error'}>{errors.account_holder_name?.message}</Text>}

              <CustomFormLabel
                htmlFor='account_holder_type'
                pt={3}
                isInvalid={errors.account_holder_type && errors.account_holder_type?.message !== ''}
              >
                {t('payment.addBankForm.accountHolderType')}
                <Select
                  id='account_holder_type'
                  placeholder='Choose account holder type'
                  defaultValue='company'
                  {...register('account_holder_type', { required: true })}
                  isInvalid={errors.account_holder_type && errors.account_holder_type?.message !== ''}
                >
                  <option value='company'>{t('payment.addBankForm.company')}</option>
                  <option value='individual'>{t('payment.addBankForm.individual')}</option>
                </Select>
              </CustomFormLabel>
              {errors.account_holder_type && <Text variant={'error'}>{errors.account_holder_type?.message}</Text>}

              <Checkbox
                id='default'
                mt={3}
                alignItems={'baseline'}
                {...register('default', { required: false })}
                data-testid='default'
              >
                {t('payment.billingPaymentMethods.default')}
              </Checkbox>

              <Checkbox
                id='user_accept_terms'
                mt={3}
                alignItems={'baseline'}
                {...register('user_accept_terms', { required: true })}
                data-testid='acceptTerms'
              >
                {t('payment.addPayout.authorization')}
              </Checkbox>
              {errors.user_accept_terms && <Text variant={'error'}>{errors.user_accept_terms?.message}</Text>}

              <Flex mt={'24px'}>
                <Button as={Link} to='/settings/payments' variant='accentSecondary' size='md' px={8} mr={'12px'}>
                  {t('payment.addPayout.back')}
                </Button>
                <Button
                  type='submit'
                  variant='brandPrimary'
                  size='md'
                  px={8}
                  isDisabled={!billingPlaidToken.link_token}
                  isLoading={loadingStatus === Status.Loading}
                >
                  {t('payment.addPayout.continue')}
                </Button>
              </Flex>
            </FormControl>
          </Box>
        </Box>
      </Section>
    </>
  );
};
