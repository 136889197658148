import { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Flex, FormControl, Heading, Input, Select, Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { merge } from 'lodash';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { CustomFormLabel } from '@/common/components/form';
import { usePlacesAutocomplete } from '@/common/hooks';
import states from '@/common/utils/states.json';
import { BRANDS } from '@/features/settings/locations/constants';
import { selectFuelLocationFormData, updateFuelLocationFormData } from '@/features/settings/locations/slice';
import { AddFuelLocationBasicInfoFormData, Address } from '@/features/settings/locations/types';
import { addFuelLocationBasicInfoSchema } from '@/features/settings/locations/validators';

export function BasicInformationForm() {
  const inputRef = useRef<HTMLInputElement>();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const fuelLocationFormData = useAppSelector(selectFuelLocationFormData);

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,
  } = useForm<AddFuelLocationBasicInfoFormData>({
    resolver: zodResolver(addFuelLocationBasicInfoSchema),
    defaultValues: {
      ...fuelLocationFormData,
    },
  });

  const handlePlaceAutocomplete = (address: Address) => {
    setValue('address.address1', address.address1);
    setValue('address.postCode', address.postCode);
    setValue('address.locality', address.locality);
    setValue('address.region', address.region);
  };

  usePlacesAutocomplete(inputRef, handlePlaceAutocomplete);

  const onSubmit = (data: AddFuelLocationBasicInfoFormData) => {
    const updatedData = merge({}, fuelLocationFormData, data);
    dispatch(updateFuelLocationFormData(updatedData));

    navigate('/settings/locations/new/2');
  };

  return (
    <Box mt='4'>
      <Heading as='h2' size='md' mb={4}>
        {t('locationsSettingsPage.fuelForm.titles.basicInformation')}
      </Heading>

      <FormControl as='form' onSubmit={handleSubmit(onSubmit)}>
        <CustomFormLabel htmlFor='name' mt={5}>
          {t('locationsSettingsPage.fuelForm.fields.basicInformation.name')}
          <Input
            type='text'
            id='name'
            placeholder='Enter a location name'
            mt={2}
            {...register('name', { required: true })}
            isInvalid={errors.name && errors.name?.message !== ''}
          />
        </CustomFormLabel>
        {errors.name && <Text variant='error'>{errors.name?.message}</Text>}

        <CustomFormLabel htmlFor='gasBrand' mt={5} maxW={['100%', '50%', '35%']}>
          {t('locationsSettingsPage.fuelForm.fields.basicInformation.brand')}
          <Select
            id='gasBrand'
            placeholder='Select your gas brand'
            mt={2}
            {...register('additionalProperties.gasBrand', { required: true })}
            isInvalid={errors.additionalProperties?.gasBrand && errors.additionalProperties.gasBrand.message !== ''}
          >
            {BRANDS.map((brand) => (
              <option key={brand.match} value={brand.value}>
                {brand.value}
              </option>
            ))}
          </Select>
        </CustomFormLabel>
        {errors.additionalProperties?.gasBrand && (
          <Text variant='error'>{errors.additionalProperties.gasBrand?.message}</Text>
        )}

        <CustomFormLabel htmlFor='address1' mt={5}>
          {t('locationsSettingsPage.fuelForm.fields.basicInformation.address1')}
          <Controller
            name='address.address1'
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <Input
                type='text'
                id='address1'
                placeholder='Enter a street address'
                mt={2}
                {...rest}
                ref={(e) => {
                  if (!e) return;
                  ref(e);
                  inputRef.current = e;
                }}
                isInvalid={errors.address?.address1 && errors.address?.address1.message !== ''}
              />
            )}
          />
        </CustomFormLabel>
        {errors.address?.address1 && <Text variant='error'>{errors.address.address1?.message}</Text>}

        <CustomFormLabel htmlFor='address2' mt={5}>
          {t('locationsSettingsPage.fuelForm.fields.basicInformation.address2')}
          <Input type='text' id='street_2' placeholder='Address complement' mt={2} {...register('address.address2')} />
        </CustomFormLabel>

        <Flex mt={5} mb={8} gap={2}>
          <Box w='full'>
            <CustomFormLabel htmlFor='locality'>
              {t('locationsSettingsPage.fuelForm.fields.basicInformation.city')}
              <Input
                type='text'
                id='locality'
                placeholder='Enter a city'
                mt={2}
                {...register('address.locality', { required: true })}
                isInvalid={errors.address?.locality && errors.address?.locality.message !== ''}
              />
            </CustomFormLabel>
            {errors.address?.locality && <Text variant='error'>{errors.address.locality?.message}</Text>}
          </Box>

          <Box w='full'>
            <CustomFormLabel htmlFor='region'>
              {t('locationsSettingsPage.fuelForm.fields.basicInformation.state')}
              <Select
                id='region'
                placeholder='Select your state'
                mt={2}
                {...register('address.region', { required: true })}
                isInvalid={errors.address?.region && errors.address?.region.message !== ''}
              >
                {states.states.map((state: { name: string; code: string }) => (
                  <option key={state.code} value={state.code}>
                    {state.name}
                  </option>
                ))}
              </Select>
            </CustomFormLabel>
            {errors.address?.region && <Text variant='error'>{errors.address.region?.message}</Text>}
          </Box>
          <Box w='full'>
            <CustomFormLabel htmlFor='postCode'>
              {t('locationsSettingsPage.fuelForm.fields.basicInformation.zip')}

              <Input
                id='postCode'
                placeholder='Enter a zip code'
                mt={2}
                {...register('address.postCode', { required: true })}
                isInvalid={errors.address?.postCode && errors.address?.postCode.message !== ''}
              />
            </CustomFormLabel>
            {errors.address?.postCode && <Text variant='error'>{errors.address.postCode?.message}</Text>}
          </Box>
        </Flex>
        <Button type='submit' variant='brandPrimary' size='md' px={8}>
          {t('locationsSettingsPage.fuelForm.actions.continue')}
        </Button>
      </FormControl>
    </Box>
  );
}
