import { RootState } from '@app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CapacityByLocationDropdown, Status } from '@/common/interfaces';
import { TemporalUnit } from '@/features/dashboard/slice';

import { ICapacityData } from './types';

export interface ICapacityState {
  capacityData: ICapacityData;
  filter: CapacityByLocationDropdown;
  status: Status.Idle | Status.Loading | Status.Failed;
  error?: string;
}

export const initialState: ICapacityState = {
  capacityData: {},
  filter: CapacityByLocationDropdown.OperatingHours,
  status: Status.Loading,
};

export const capacitySlice = createSlice({
  name: 'capacity',
  initialState,
  reducers: {
    capacityFetch: (
      state,
      _action: PayloadAction<{
        startDate: string;
        endDate: string;
        temporalUnit: TemporalUnit;
        selectedNetworks: string[];
        selectedTransactionType: string;
        authRequestPath: string;
        activeVertical: string;
        networkNames: string[];
        locations: string[];
        transactionType: string;
      }>,
    ) => {
      state.error = undefined;
      state.status = Status.Loading;
    },
    capacitySucceeded: (state, action: PayloadAction<ICapacityData>) => {
      state.capacityData = action.payload;
      state.status = Status.Idle;
    },
    capacityFailed: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.status = Status.Failed;
    },
    setCapacityLocationFilter: (state, action: PayloadAction<CapacityByLocationDropdown>) => {
      state.filter = action.payload;
    },
  },
});

export const { capacityFetch, capacitySucceeded, capacityFailed, setCapacityLocationFilter } = capacitySlice.actions;

export const selectCapacity = (state: RootState) => state.capacity.capacityData;
export const selectCapacityStatus = (state: RootState) => state.capacity.status;
export const selectCapacityLocationFilter = (state: RootState) => {
  return state.capacity.filter;
};

export default capacitySlice.reducer;
