import i18next from 'i18next';

import { IPerformanceSummaryBySegmentRow } from './types';

export { CustomerDeepDive } from './CustomerDeepDive';
export { CustomerSummary } from './CustomerSummary';

export const CUSTOMER_ACQUISITION_METRICS: {
  [key: string]: {
    rawProp: keyof IPerformanceSummaryBySegmentRow;
    type: 'number' | 'currency';
  };
} = {
  totalUpsideCustomers: {
    rawProp: 'total_upside_customers',
    type: 'number',
  },
  upsideTransactions: {
    rawProp: 'upside_transactions',
    type: 'number',
  },
  totalRevenue: {
    rawProp: 'total_revenue',
    type: 'currency',
  },
  totalIncrementalRevenue: {
    rawProp: 'total_incremental_revenue',
    type: 'currency',
  },
  netProfit: {
    rawProp: 'net_profit',
    type: 'currency',
  },
  firstTimeUpsideCustomers: {
    rawProp: 'first_time_users_num',
    type: 'number',
  },
};

export const getCustomerAcquisitionMetricType = (metricLabel?: string) => {
  if (!metricLabel) {
    return '';
  }
  return CUSTOMER_ACQUISITION_METRICS[metricLabel]?.type ?? '';
};

export const getCustomerAcquisitionMetricKeys = () =>
  Object.keys(CUSTOMER_ACQUISITION_METRICS).map((key) => ({
    value: key,
    label: i18next.t(`components.chartDropDownMenu.customerMetricsDropdown.${key}`),
  }));
