import { MutableRefObject, useEffect } from 'react';

import { type Address } from '@/features/settings/locations/types';

export const usePlacesAutocomplete = (
  inputRef: MutableRefObject<HTMLInputElement | undefined>,
  onPlaceChanged: (address: Address) => void,
) => {
  useEffect(() => {
    if (!inputRef || !inputRef.current) return;

    const autoComplete = new google.maps.places.Autocomplete(inputRef.current, {
      fields: ['address_component'],
      componentRestrictions: { country: 'us' },
    });

    autoComplete.addListener('place_changed', () => {
      const place = autoComplete.getPlace();

      if (!place.address_components) return;
      let address1 = '';
      let postalCode = '';
      let locality = '';
      let region = '';

      place.address_components.forEach((component) => {
        const componentType = component.types[0];

        switch (componentType) {
          case 'street_number': {
            address1 = `${component.long_name} ${address1}`;
            break;
          }
          case 'route': {
            address1 += component.long_name;
            break;
          }
          case 'postal_code': {
            postalCode = component.long_name;
            break;
          }
          case 'locality': {
            locality = component.long_name;
            break;
          }
          case 'administrative_area_level_1': {
            region = component.short_name;
            break;
          }
          default:
            break;
        }
      });

      onPlaceChanged({
        address1: address1 ?? '',
        postCode: postalCode ?? '',
        locality: locality ?? '',
        region: region ?? '',
      });
    });
  }, [inputRef, onPlaceChanged]);
};
