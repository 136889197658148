import * as Sentry from '@sentry/react';

import { TransactionTypes } from '@/common/interfaces';
import { addSearchParamIfValueNotNull, transactionTypeForApiCall } from '@/common/utils';
import { axiosInstance } from '@/common/utils';
import { ENV_CONFIG } from '@/config';

import { TemporalUnit } from '../../dashboard/slice';
import { mockSegmentsSummary } from '../../reports/customers/mockSegmentsSummary';

import { mockAllCustomersSummary } from './mockAllCustomersSummary';
import { buildMockCustomers } from './mockCustomers';
import { buildMockCustomerTransactions } from './mockCustomerTransactions';
import { mockIndividualCustomer } from './mockIndividualCustomer';
import {
  IAllCustomersSummaryRawData,
  ICustomerSegmentationSummaryRawData,
  ICustomersRawData,
  ICustomerSummaryRawData,
  ICustomerTransactionsRawData,
} from './types';

// A mock function to mimic making an async request for data
export const mockFetchCustomers = ({
  page,
}: {
  startDate: string;
  endDate: string;
  page: number;
  pageSize: number;
  authRequestPath: string;
}) => {
  const mockCustomers = buildMockCustomers(page);

  return new Promise<ICustomersRawData>((resolve) => setTimeout(() => resolve(mockCustomers), 1500));
};

export const fetchCustomers = async ({
  startDate,
  endDate,
  query,
  page,
  pageSize,
  authRequestPath,
  networkNames,
  transactionType,
  locations,
}: {
  startDate: string;
  endDate: string;
  page: number;
  pageSize: number;
  query: string;
  authRequestPath: string;
  networkNames: string[];
  transactionType: TransactionTypes;
  locations: string[];
  isPromotionPage?: boolean;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let result: any = '';
  const url = new URL(`${ENV_CONFIG.API_URL}/customers/${authRequestPath}`);
  addSearchParamIfValueNotNull(url, 'transactionType', transactionTypeForApiCall(transactionType));

  try {
    result = await axiosInstance.post(
      url.toString(),
      { networkNames, locations },
      {
        params: {
          startDate,
          endDate,
          page,
          pageSize,
          query,
        },
      },
    );
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
    throw error;
  }

  return result.data;
};

export const fetchCustomerSummary = async ({
  userUuid,
  authRequestPath,
  networkNames,
  transactionType,
  locations,
}: {
  userUuid: string;
  authRequestPath: string;
  networkNames: string[];
  transactionType: TransactionTypes;
  locations: string[];
  isPromotionPage?: boolean;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let result: any = '';
  const url = new URL(`${ENV_CONFIG.API_URL}/customers/${userUuid}/${authRequestPath}`);
  addSearchParamIfValueNotNull(url, 'transactionType', transactionTypeForApiCall(transactionType));

  try {
    result = await axiosInstance.post(url.toString(), {
      networkNames,
      locations,
    });
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
    throw error;
  }

  return result.data;
};

export const mockFetchCustomerSummary = async () => {
  return new Promise<ICustomerSummaryRawData>((resolve) => setTimeout(() => resolve(mockIndividualCustomer), 1500));
};

export const fetchAllCustomersSummary = async ({
  authRequestPath,
  startDate,
  endDate,
  networkNames,
  transactionType,
  locations,
}: {
  startDate: string;
  endDate: string;
  authRequestPath: string;
  networkNames: string[];
  transactionType: TransactionTypes;
  locations: string[];
  isPromotionPage?: boolean;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let result: any = '';
  const url = new URL(
    `${ENV_CONFIG.API_URL}/customers/summary/${authRequestPath}?startDate=${startDate}&endDate=${endDate}`,
  );
  addSearchParamIfValueNotNull(url, 'transactionType', transactionTypeForApiCall(transactionType));

  try {
    result = await axiosInstance.post(url.toString(), {
      networkNames,
      locations,
    });
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
    throw error;
  }

  return result.data;
};

export const mockFetchAllCustomersSummary = async () => {
  return new Promise<IAllCustomersSummaryRawData>((resolve) =>
    setTimeout(() => resolve(mockAllCustomersSummary), 1500),
  );
};

export const mockFetchCustomerTransactions = ({
  page,
}: {
  userUuid: string;
  startDate: string;
  endDate: string;
  page: number;
  pageSize: number;
  authRequestPath: string;
}) => {
  const mockCustomerTransactions = buildMockCustomerTransactions(page);

  return new Promise<ICustomerTransactionsRawData>((resolve) =>
    setTimeout(() => resolve(mockCustomerTransactions), 1500),
  );
};

export const fetchCustomerTransactions = async ({
  userUuid,
  page,
  pageSize,
  authRequestPath,
  networkNames,
  transactionType,
  locations,
}: {
  userUuid: string;
  page: number;
  pageSize: number;
  authRequestPath: string;
  activeVertical: string;
  networkNames: string[];
  transactionType: TransactionTypes;
  locations: string[];
  isPromotionPage?: boolean;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let result: any = '';
  const query = `page=${page}&pageSize=${pageSize}`;
  const url = new URL(`${ENV_CONFIG.API_URL}/customers/${userUuid}/transactions/${authRequestPath}?${query}`);
  addSearchParamIfValueNotNull(url, 'transactionType', transactionTypeForApiCall(transactionType));

  try {
    result = await axiosInstance.post(url.toString(), {
      networkNames,
      locations,
    });
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
    throw error;
  }

  return result.data;
};

export const fetchRecentTransactionsList = async ({
  startDate,
  endDate,
  page,
  pageSize,
  authRequestPath,
  networkNames,
  transactionType,
  locations,
}: {
  startDate: string;
  endDate: string;
  page: number;
  pageSize: number;
  authRequestPath: string;
  networkNames: string[];
  transactionType: TransactionTypes;
  locations: string[];
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let result: any = '';
  const query = `startDate=${startDate}&endDate=${endDate}&page=${page}&pageSize=${pageSize}`;
  const url = new URL(`${ENV_CONFIG.API_URL}/customers/transactions/${authRequestPath}?${query}`);
  addSearchParamIfValueNotNull(url, 'transactionType', transactionTypeForApiCall(transactionType));

  try {
    result = await axiosInstance.post(url.toString(), {
      networkNames,
      transactionType,
      locations,
    });
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
    throw error;
  }

  return result.data;
};

export const fetchCustomerSegmentationSummary = async ({
  startDate,
  endDate,
  temporalUnit,
  authRequestPath,
  networkNames,
  transactionType,
  locations,
}: {
  startDate: string;
  endDate: string;
  temporalUnit: TemporalUnit;
  authRequestPath: string;
  networkNames: string[];
  transactionType: TransactionTypes;
  locations: string[];
  isPromotionPage?: boolean;
}): Promise<ICustomerSegmentationSummaryRawData> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let result: any = '';

  const url = new URL(`${ENV_CONFIG.API_URL}/customers/summary_by_segment/${authRequestPath}`);
  url.searchParams.append('startDate', startDate);
  url.searchParams.append('endDate', endDate);
  url.searchParams.append('temporalUnit', temporalUnit);
  addSearchParamIfValueNotNull(url, 'transactionType', transactionTypeForApiCall(transactionType));

  try {
    result = await axiosInstance.post(url.toString(), { networkNames, locations });
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
    throw error;
  }

  return result.data;
};

export const mockFetchCustomerSegmentationSummary = () => {
  return new Promise<ICustomerSegmentationSummaryRawData>((resolve) =>
    setTimeout(() => resolve(mockSegmentsSummary), 1500),
  );
};
