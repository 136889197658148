/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/react';

import { axiosInstanceUnauthenticated } from '@/common/utils';

import { RegistrationFormData } from './types';

export const submitRegistration = async ({ formData }: { formData: RegistrationFormData }) => {
  try {
    const result = await axiosInstanceUnauthenticated.post(`/user/registration`, { formData: formData });
    return { status: result.status, data: result.data };
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};
