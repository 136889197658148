import { z } from 'zod';

import { DeliveryMethodType, FileFormatType, GranularityType } from './type';

export const granularitySchema = z.object({
  granularity: z.nativeEnum(GranularityType, {
    required_error: 'Granularity is required',
  }),
});

export const columnSelectionSchema = z.object({
  includedFields: z.string().array(),
});

export const downloadOptionsSchema = z.object({
  deliveryMethod: z.nativeEnum(DeliveryMethodType, {
    required_error: 'Delivery method is required',
  }),
  fileFormat: z.nativeEnum(FileFormatType, {
    required_error: 'File format is required',
  }),
});

export type GranularityForm = z.infer<typeof granularitySchema>;
export type ColumnSelectionForm = z.infer<typeof columnSelectionSchema>;
export type DownloadOptionsForm = z.infer<typeof downloadOptionsSchema>;
