import { useCallback } from 'react';
import { Trans } from 'react-i18next';
import { FiFilter } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  Button,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
  useRadioGroup,
  VStack,
} from '@chakra-ui/react';

import { INITIAL_TRANSACTION_TYPE, TransactionTypes } from '@/common/interfaces';
import {
  selectSelectedTransactionType,
  selectTransactionTypes,
  setSelectedTransactionType,
} from '@/features/dashboard/slice';

import { TransactionTypeRadio } from './TransactionTypeRadio';

const makeI18nKeyForTransactionType = (transactionType: string): string =>
  `common.transactionTypeSelector.${transactionType}`;

export function TransactionTypeSelector() {
  const dispatch = useAppDispatch();
  const siteLocation = useLocation();
  const { onOpen, onClose, onToggle, isOpen } = useDisclosure();
  const transactionTypes = useAppSelector(selectTransactionTypes);
  const selectedTransactionType = useAppSelector(selectSelectedTransactionType);

  const onChange = useCallback((transactionType: TransactionTypes) => {
    dispatch(setSelectedTransactionType(transactionType));
    onClose();
  }, []);

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'transactions',
    defaultValue: INITIAL_TRANSACTION_TYPE,
    onChange,
  });

  const group = getRootProps();
  const shouldDisableSelector =
    /profitability/.test(siteLocation.pathname) ||
    /capacity/.test(siteLocation.pathname) ||
    /behavior/.test(siteLocation.pathname);

  return transactionTypes.length < 2 ? (
    <></>
  ) : (
    <Popover
      placement='bottom-start'
      variant='responsive'
      isOpen={shouldDisableSelector ? false : isOpen}
      onOpen={onOpen}
      onClose={onClose}
      isLazy
    >
      <PopoverTrigger>
        <Button
          onClick={onToggle}
          variant='accentSecondary'
          borderColor='neutral.400'
          borderRadius='lg'
          aria-disabled={shouldDisableSelector}
          _disabled={{ cursor: 'not-allowed', opacity: 0.4 }}
          data-testid='transaction-type-filter'
          size='md'
        >
          <Icon as={FiFilter} />
          <Text ml='10px' mr='10px' fontWeight='400'>
            <Trans i18nKey={makeI18nKeyForTransactionType(selectedTransactionType)}>{selectedTransactionType}</Trans>
          </Text>
          {isOpen ? <Icon as={ChevronUpIcon} fontWeight={500} /> : <Icon as={ChevronDownIcon} fontWeight={500} />}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        minW='280px'
        maxW='290px'
        width='100%'
        boxShadow='base'
        borderRadius='lg'
        py='3'
        px='2'
        bg='background.default'
      >
        <PopoverBody maxH='385px' overflowY='auto'>
          <VStack w='full' align='start' gap='2' {...group}>
            <TransactionTypeRadio
              value='INITIAL_TRANSACTION_TYPE'
              {...getRadioProps({ value: INITIAL_TRANSACTION_TYPE })}
            >
              <Trans i18nKey={makeI18nKeyForTransactionType(INITIAL_TRANSACTION_TYPE)}>All transactions</Trans>
            </TransactionTypeRadio>
            {transactionTypes.map((transaction: string, index: number) => (
              <TransactionTypeRadio
                key={`trans-radio-${index}`}
                value={transaction}
                data-testid={transaction}
                {...getRadioProps({ value: transaction })}
              >
                <Trans i18nKey={makeI18nKeyForTransactionType(transaction)}>{transaction}</Trans>
              </TransactionTypeRadio>
            ))}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
