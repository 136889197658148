import { useTranslation } from 'react-i18next';
import { FiBarChart2, FiMapPin, FiTrendingUp, FiUser } from 'react-icons/fi';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Flex, Grid, GridItem, Heading, Icon, Text } from '@chakra-ui/react';
import { Card } from '@components/card';
import { useDecision } from '@optimizely/react-sdk';

import { useAppSelector } from '@/app/hooks';
import { Verticals } from '@/common/interfaces';
import { ENV_CONFIG } from '@/config';

import { selectActiveVertical } from '../auth/userSlice';

export const Explore = () => {
  const { t } = useTranslation();
  const [capacityFlag] = useDecision('md-569', { autoUpdate: true });
  const [behaviorFlag] = useDecision('md-640', { autoUpdate: true });

  const activeVertical = useAppSelector(selectActiveVertical);

  const displayCapacity = () => {
    if (
      (ENV_CONFIG.TIER === 'prod' && activeVertical === Verticals.Fuel) ||
      (ENV_CONFIG.TIER === 'alpha' && activeVertical !== Verticals.Restaurant)
    ) {
      return (
        <Card
          height={'100%'}
          button={{ text: t('buttons.view'), to: '/explore/capacity', icon: ArrowForwardIcon }}
          display={capacityFlag.enabled ? 'flex' : 'none'}
        >
          <Flex
            width={'100%'}
            height={'100%'}
            flexDirection={'column'}
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
          >
            <Heading
              fontSize={'18px'}
              lineHeight={'22px'}
              fontWeight={500}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'flex-start'}
              alignItems={'center'}
            >
              <Icon as={FiTrendingUp} boxSize='18px' marginRight={'11px'} />
              {t('exploreHomePage.capacity')}
            </Heading>
            <Box flex={1} mt={'26px'}>
              <Text fontWeight={500} fontSize={'16px'} lineHeight={'24px'}>
                {t('exploreHomePage.capacityText')}
              </Text>
            </Box>
          </Flex>
        </Card>
      );
    } else return;
  };

  return (
    <Grid gridTemplateColumns={'repeat(auto-fill, minmax(365px, 1fr))'} padding={4} gap='4'>
      {behaviorFlag.enabled && (
        <GridItem>
          <Card
            display={'flex'}
            height={'100%'}
            button={{ text: t('buttons.view'), to: '/explore/behavior', icon: ArrowForwardIcon }}
          >
            <Flex
              width={'100%'}
              height={'100%'}
              flexDirection={'column'}
              justifyContent={'flex-start'}
              alignItems={'flex-start'}
            >
              <Heading
                fontSize={'18px'}
                lineHeight={'22px'}
                fontWeight={500}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'flex-start'}
                alignItems={'center'}
              >
                <Icon as={FiBarChart2} boxSize='18px' marginRight={'11px'} />
                {t('exploreHomePage.behavior')}
              </Heading>
              <Box flex={1} mt={'26px'}>
                <Text fontWeight={500} fontSize={'16px'} lineHeight={'24px'}>
                  {t('exploreHomePage.behaviorText')}
                </Text>
              </Box>
            </Flex>
          </Card>
        </GridItem>
      )}

      <GridItem>
        <Card
          display={'flex'}
          height={'100%'}
          button={{ text: t('buttons.view'), to: '/explore/customerDeepDive', icon: ArrowForwardIcon }}
        >
          <Flex
            width={'100%'}
            height={'100%'}
            flexDirection={'column'}
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
          >
            <Heading
              fontSize={'18px'}
              lineHeight={'22px'}
              fontWeight={500}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'flex-start'}
              alignItems={'center'}
            >
              <Icon as={FiUser} boxSize='18px' marginRight={'8px'} />
              {t('exploreHomePage.customerDeepDive')}
            </Heading>
            <Box flex={1} mt={'26px'}>
              <Text fontWeight={500} fontSize={'16px'} lineHeight={'24px'}>
                {t('exploreHomePage.customerDeepDiveText')}
              </Text>
            </Box>
          </Flex>
        </Card>
      </GridItem>

      <GridItem>
        <Card
          display={'flex'}
          height={'100%'}
          button={{ text: t('buttons.view'), to: '/explore/locations', icon: ArrowForwardIcon }}
        >
          <Flex
            width={'100%'}
            height={'100%'}
            flexDirection={'column'}
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
          >
            <Heading
              fontSize={'18px'}
              lineHeight={'22px'}
              fontWeight={500}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'flex-start'}
              alignItems={'center'}
            >
              <Icon as={FiMapPin} boxSize='18px' marginRight={'11px'} />
              {t('exploreHomePage.locations')}
            </Heading>
            <Box flex={1} mt={'26px'}>
              <Text fontWeight={500} fontSize={'16px'} lineHeight={'24px'}>
                {t('exploreHomePage.locationsText')}
              </Text>
            </Box>
          </Flex>
        </Card>
      </GridItem>

      <GridItem>{displayCapacity()}</GridItem>
    </Grid>
  );
};
