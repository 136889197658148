export const DEFAULT_STEPS = '0.001';
export const LEGACY_STEPS = '0.0000001';

export const BRANDS = [
  { value: '7-Eleven', match: '7-eleven' },
  { value: '76', match: '76' },
  { value: 'Amoco', match: 'amoco' },
  { value: 'ARCO', match: 'arco' },
  { value: 'ARCO & ampm', match: 'arco and ampm' },
  { value: "Buc-ee's", match: "buc-ee's" },
  { value: 'BP', match: 'bp' },
  { value: "Casey's", match: 'caseys' },
  { value: 'Cenex', match: 'cenex' },
  { value: 'Chevron', match: 'chevron' },
  { value: 'Circle K', match: 'circle k' },
  { value: 'Citgo', match: 'citgo' },
  { value: 'Clark', match: 'clark' },
  { value: 'Conoco', match: 'conoco' },
  { value: 'CountryMark', match: 'countrymark' },
  { value: 'Crown', match: 'crown' },
  { value: 'Dash In', match: 'dash in' },
  { value: 'Dees Oil', match: 'dees oil' },
  { value: 'Diesel', match: 'diesel' },
  { value: 'Double Quick', match: 'double quick' },
  { value: 'eExpress', match: 'eexpress' },
  { value: 'Exxon', match: 'exxon' },
  { value: 'E-Z Mart', match: 'e-z mart' },
  { value: 'Fas Fuel', match: 'fas fuel' },
  { value: 'Fleetway Market', match: 'fleetway market' },
  { value: 'Gas America', match: 'gas america' },
  { value: 'Git N Split', match: 'git n split' },
  { value: 'Gulf', match: 'gulf' },
  { value: 'Jiffy Mart Truck Diesel', match: 'jiffy mart truck diesel' },
  { value: 'Haffner’s', match: "haffner's" },
  { value: 'Hess', match: 'hess' },
  { value: 'Holiday', match: 'holiday' },
  { value: "Huck's", match: 'hucks' },
  { value: 'Krist', match: 'krist' },
  { value: 'Kum & Go', match: 'kum and go' },
  { value: 'Kwik Trip', match: 'kwik trip' },
  { value: 'Liberty', match: 'liberty' },
  { value: "Love's", match: 'loves' },
  { value: 'Maple Leaf Market', match: 'maple leaf market' },
  { value: 'Marathon', match: 'marathon' },
  { value: 'Mobil', match: 'mobil' },
  { value: 'MotoMart', match: 'motomart' },
  { value: 'NomNom', match: 'nomnom' },
  { value: 'Petro Mart', match: 'petro mart' },
  { value: 'Phillips 66', match: 'phillips' },
  { value: 'Pump & Pantry', match: 'pump and pantry' },
  { value: 'RaceTrac', match: 'racetrac' },
  { value: 'RaceWay', match: 'raceway' },
  { value: 'Rich Oil', match: 'rich oil' },
  { value: 'Road Ranger', match: 'road ranger' },
  { value: 'SavOn', match: 'savon' },
  { value: 'Simonson Station Store', match: 'simonson station store' },
  { value: 'Sheetz', match: 'sheetz' },
  { value: 'Shamrock', match: 'shamrock' },
  { value: 'Shell', match: 'shell' },
  { value: 'Sinclair', match: 'sinclair' },
  { value: 'Speedway', match: 'speedway' },
  { value: 'Stop-A-Minit', match: 'stop-a-minit' },
  { value: 'Sunoco', match: 'sunoco' },
  { value: 'SuperAmerica', match: 'superamerica' },
  { value: 'Tesoro', match: 'tesoro' },
  { value: 'Texaco', match: 'texaco' },
  { value: "Toot'n Totum", match: 'tootn totum' },
  { value: 'Trade Winds', match: 'trade winds' },
  { value: 'Unbranded', match: 'unbranded' },
  { value: 'UnitedPacific', match: 'unitedpacific' },
  { value: 'Valero', match: 'valero' },
];

export const NEIGHBOR_STATES = {
  AL: ['TN', 'GA', 'FL', 'MS'],
  AR: ['MO', 'TN', 'MS', 'LA', 'TX', 'OK'],
  AZ: ['UT', 'CO', 'NM', 'CA', 'NV'],
  CA: ['OR', 'NV', 'AZ'],
  CO: ['WY', 'NE', 'KS', 'OK', 'NM', 'AZ', 'UT'],
  CT: ['MA', 'RI', 'NY'],
  DC: ['MD', 'VA'],
  DE: ['PA', 'NJ', 'MD'],
  FL: ['GA', 'AL'],
  GA: ['NC', 'SC', 'FL', 'AL', 'TN'],
  IA: ['MN', 'WI', 'IL', 'MO', 'NE', 'SD'],
  ID: ['MT', 'WY', 'UT', 'NV', 'OR', 'WA'],
  IL: ['WI', 'IN', 'KY', 'MO', 'IA'],
  IN: ['MI', 'OH', 'KY', 'IL'],
  KS: ['NE', 'MO', 'OK', 'CO'],
  KY: ['OH', 'WV', 'VA', 'TN', 'MO', 'IL', 'IN'],
  LA: ['AR', 'MS', 'TX'],
  MA: ['NH', 'RI', 'CT', 'NY', 'VT'],
  MD: ['PA', 'DE', 'DC', 'VA', 'WV'],
  ME: ['NH'],
  MI: ['OH', 'IN', 'WI'],
  MN: ['WI', 'IA', 'SD', 'ND'],
  MO: ['IA', 'IL', 'KY', 'TN', 'AR', 'OK', 'KS', 'NE'],
  MS: ['TN', 'AL', 'LA', 'AR'],
  MT: ['ND', 'SD', 'WY', 'ID'],
  NC: ['VA', 'SC', 'GA', 'TN'],
  ND: ['MN', 'SD', 'MT'],
  NE: ['SD', 'IA', 'MO', 'KS', 'CO', 'WY'],
  NH: ['ME', 'MA', 'VT'],
  NJ: ['NY', 'DE', 'PA'],
  NM: ['CO', 'OK', 'TX', 'AZ', 'UT'],
  NV: ['ID', 'UT', 'AZ', 'CA', 'OR'],
  NY: ['VT', 'MA', 'CT', 'NJ', 'PA'],
  OH: ['PA', 'WV', 'KY', 'IN', 'MI'],
  OK: ['KS', 'MO', 'AR', 'TX', 'NM', 'CO'],
  OR: ['WA', 'ID', 'NV', 'CA'],
  PA: ['NY', 'NJ', 'DE', 'MD', 'WV', 'OH'],
  RI: ['MA', 'CT'],
  SC: ['NC', 'GA'],
  SD: ['ND', 'MN', 'IA', 'NE', 'WY', 'MT'],
  TN: ['KY', 'VA', 'NC', 'GA', 'AL', 'MS', 'AR', 'MO'],
  TX: ['OK', 'AR', 'LA', 'NM'],
  UT: ['ID', 'WY', 'CO', 'NM', 'AZ', 'NV'],
  VA: ['MD', 'DC', 'NC', 'TN', 'KY', 'WV'],
  VT: ['NH', 'MA', 'NY'],
  WA: ['ID', 'OR'],
  WI: ['MI', 'IL', 'IA', 'MN'],
  WV: ['PA', 'MD', 'VA', 'KY', 'OH'],
  WY: ['MT', 'SD', 'NE', 'CO', 'UT', 'ID'],
};

export const CLASS_OF_TRADE_MAPPING = [
  { label: 'Company Owned Commission Agent (COCA)', value: 'COCA' },
  { label: 'Company Owned Company Operated (COCO)', value: 'COCO' },
  { label: 'Company Owned Dealer Operated (CODO)', value: 'CODO' },
  { label: 'Dealer Owned Dealer Operated (DODO)', value: 'DODO' },
  { label: 'Dealer Tank Wagon (DTW)', value: 'DTW' },
];

export const TRANSACTION_DATA_MAPPING = ['PROCESSOR', 'LINKED_PROCESSOR', 'DIRECT'];

export const OPIS_RACK_MAPPING = [
  {
    label: 'Alabama',
    region: 'AL',
    localities: [
      {
        locality: 'Anniston/Oxford',
        region: 'AL',
      },
      {
        locality: 'Birmingham',
        region: 'AL',
      },
      {
        locality: 'Boligee',
        region: 'AL',
      },
      {
        locality: 'Mobile',
        region: 'AL',
      },
      {
        locality: 'Montgomery',
        region: 'AL',
      },
      {
        locality: 'Sheffield',
        region: 'AL',
      },
    ],
  },
  {
    label: 'Alaska',
    region: 'AK',
    localities: [
      {
        locality: 'Anchorage',
        region: 'AK',
      },
      {
        locality: 'Fairbanks',
        region: 'AK',
      },
    ],
  },
  {
    label: 'Arizona',
    region: 'AZ',
    localities: [
      {
        locality: 'Phoenix',
        region: 'AZ',
      },
      {
        locality: 'Tucson',
        region: 'AZ',
      },
      {
        locality: 'Flagstaff',
        region: 'AZ',
      },
    ],
  },
  {
    label: 'Arkansas',
    region: 'AR',
    localities: [
      {
        locality: 'El Dorado',
        region: 'AR',
      },
      {
        locality: 'Ft. Smith',
        region: 'AR',
      },
      {
        locality: 'Jonesboro',
        region: 'AR',
      },
      {
        locality: 'Little Rock',
        region: 'AR',
      },
      {
        locality: 'Rogers',
        region: 'AR',
      },
      {
        locality: 'Helena',
        region: 'AR',
      },
      {
        locality: 'West Memphis',
        region: 'AR',
      },
    ],
  },
  {
    label: 'California',
    region: 'CA',
    localities: [
      {
        locality: 'Imperial',
        region: 'CA',
      },
      {
        locality: 'Bakersfield',
        region: 'CA',
      },
      {
        locality: 'Barstow',
        region: 'CA',
      },
      {
        locality: 'Brisbane',
        region: 'CA',
      },
      {
        locality: 'Chico',
        region: 'CA',
      },
      {
        locality: 'Colton',
        region: 'CA',
      },
      {
        locality: 'Eureka',
        region: 'CA',
      },
      {
        locality: 'Fresno',
        region: 'CA',
      },
      {
        locality: 'Los Angeles Proper',
        region: 'CA',
      },
      {
        locality: 'L.A. High Sulfur',
        region: 'CA',
      },
      {
        locality: 'Los Angeles',
        region: 'CA',
      },
      {
        locality: 'Carson',
        region: 'CA',
      },
      {
        locality: 'Montebello',
        region: 'CA',
      },
      {
        locality: 'Orange',
        region: 'CA',
      },
      {
        locality: 'Sacramento',
        region: 'CA',
      },
      {
        locality: 'San Diego',
        region: 'CA',
      },
      {
        locality: 'Fremont',
        region: 'CA',
      },
      {
        locality: 'San Francisco',
        region: 'CA',
      },
      {
        locality: 'Richmond',
        region: 'CA',
      },
      {
        locality: 'San Jose',
        region: 'CA',
      },
      {
        locality: 'Stockton',
        region: 'CA',
      },
      {
        locality: 'Van Nuys',
        region: 'CA',
      },
      {
        locality: 'Wilmington',
        region: 'CA',
      },
    ],
  },
  {
    label: 'Colorado',
    region: 'CO',
    localities: [
      {
        locality: 'Denver',
        region: 'CO',
      },
      {
        locality: 'Fountain',
        region: 'CO',
      },
      {
        locality: 'Grand Junction',
        region: 'CO',
      },
      {
        locality: 'La Junta',
        region: 'CO',
      },
      {
        locality: 'Colorado Springs',
        region: 'CO',
      },
    ],
  },
  {
    label: 'Connecticut',
    region: 'CT',
    localities: [
      {
        locality: 'Hartford/Rocky Hill',
        region: 'CT',
      },
      {
        locality: 'New Haven',
        region: 'CT',
      },
    ],
  },
  {
    label: 'Delaware',
    region: 'DE',
    localities: [
      {
        locality: 'Wilmington',
        region: 'DE',
      },
    ],
  },
  {
    label: 'Florida',
    region: 'FL',
    localities: [
      {
        locality: 'Cape Canaveral',
        region: 'FL',
      },
      {
        locality: 'Jacksonville',
        region: 'FL',
      },
      {
        locality: 'Miami',
        region: 'FL',
      },
      {
        locality: 'Niceville',
        region: 'FL',
      },
      {
        locality: 'Orlando',
        region: 'FL',
      },
      {
        locality: 'Panama City',
        region: 'FL',
      },
      {
        locality: 'Pensacola',
        region: 'FL',
      },
      {
        locality: 'Tampa',
        region: 'FL',
      },
      {
        locality: 'Ft. Lauderdale',
        region: 'FL',
      },
      {
        locality: 'Islamorada',
        region: 'FL',
      },
      {
        locality: 'Taft',
        region: 'FL',
      },
      {
        locality: 'Pt. Everglades',
        region: 'FL',
      },
      {
        locality: 'St. Marks',
        region: 'FL',
      },
      {
        locality: 'Lakeland',
        region: 'FL',
      },
      {
        locality: 'West Palm Beach',
        region: 'FL',
      },
    ],
  },
  {
    label: 'Georgia',
    region: 'GA',
    localities: [
      {
        locality: 'Albany',
        region: 'GA',
      },
      {
        locality: 'Athens',
        region: 'GA',
      },
      {
        locality: 'Atlanta',
        region: 'GA',
      },
      {
        locality: 'Bainbridge',
        region: 'GA',
      },
      {
        locality: 'Columbus',
        region: 'GA',
      },
      {
        locality: 'Griffin',
        region: 'GA',
      },
      {
        locality: 'Macon',
        region: 'GA',
      },
      {
        locality: 'Rome',
        region: 'GA',
      },
      {
        locality: 'Savannah',
        region: 'GA',
      },
      {
        locality: 'Americus',
        region: 'GA',
      },
      {
        locality: 'Chattahoochee',
        region: 'GA',
      },
    ],
  },
  {
    label: 'Idaho',
    region: 'ID',
    localities: [
      {
        locality: 'Boise',
        region: 'ID',
      },
      {
        locality: 'Pocatello',
        region: 'ID',
      },
    ],
  },
  {
    label: 'Illinois',
    region: 'IL',
    localities: [
      {
        locality: 'Champaign',
        region: 'IL',
      },
      {
        locality: 'Chicago',
        region: 'IL',
      },
      {
        locality: 'Decatur/Forsythe',
        region: 'IL',
      },
      {
        locality: 'Heyworth',
        region: 'IL',
      },
      {
        locality: 'Kankakee',
        region: 'IL',
      },
      {
        locality: 'Menard',
        region: 'IL',
      },
      {
        locality: 'Robinson',
        region: 'IL',
      },
      {
        locality: 'Rockford',
        region: 'IL',
      },
      {
        locality: 'Wood River',
        region: 'IL',
      },
      {
        locality: 'Mt. Prospect',
        region: 'IL',
      },
      {
        locality: 'Des Plaines',
        region: 'IL',
      },
      {
        locality: 'Amboy',
        region: 'IL',
      },
      {
        locality: 'Argo',
        region: 'IL',
      },
      {
        locality: 'Blue Island',
        region: 'IL',
      },
      {
        locality: 'Lemont',
        region: 'IL',
      },
      {
        locality: 'Lockport',
        region: 'IL',
      },
      {
        locality: 'Forestview',
        region: 'IL',
      },
      {
        locality: 'Norris City',
        region: 'IL',
      },
      {
        locality: 'Peoria',
        region: 'IL',
      },
    ],
  },
  {
    label: 'Indiana',
    region: 'IN',
    localities: [
      {
        locality: 'Evansville',
        region: 'IN',
      },
      {
        locality: 'Hammond',
        region: 'IN',
      },
      {
        locality: 'Huntington',
        region: 'IN',
      },
      {
        locality: 'Indianapolis',
        region: 'IN',
      },
      {
        locality: 'Muncie',
        region: 'IN',
      },
      {
        locality: 'Princeton',
        region: 'IN',
      },
      {
        locality: 'Peru',
        region: 'IN',
      },
      {
        locality: 'Logansport',
        region: 'IN',
      },
      {
        locality: 'Seymour',
        region: 'IN',
      },
      {
        locality: 'Switz City',
        region: 'IN',
      },
      {
        locality: 'Terre Haute',
        region: 'IN',
      },
    ],
  },
  {
    label: 'Iowa',
    region: 'IA',
    localities: [
      {
        locality: 'Bettendorf',
        region: 'IA',
      },
      {
        locality: 'Council Bluffs',
        region: 'IA',
      },
      {
        locality: 'Des Moines',
        region: 'IA',
      },
      {
        locality: 'Dubuque',
        region: 'IA',
      },
      {
        locality: 'Ft. Dodge',
        region: 'IA',
      },
      {
        locality: 'Ft. Madison',
        region: 'IA',
      },
      {
        locality: 'Iowa City',
        region: 'IA',
      },
      {
        locality: 'Lemars',
        region: 'IA',
      },
      {
        locality: 'Mason Cty/Clr.Lk.',
        region: 'IA',
      },
      {
        locality: 'Milford',
        region: 'IA',
      },
      {
        locality: 'Ottumwa',
        region: 'IA',
      },
      {
        locality: 'Rock Rapids',
        region: 'IA',
      },
      {
        locality: 'Sioux City',
        region: 'IA',
      },
      {
        locality: 'Waterloo',
        region: 'IA',
      },
      {
        locality: 'Denison',
        region: 'IA',
      },
    ],
  },
  {
    label: 'Kansas',
    region: 'KS',
    localities: [
      {
        locality: 'Coffeyville',
        region: 'KS',
      },
      {
        locality: 'Concordia',
        region: 'KS',
      },
      {
        locality: 'El Dorado',
        region: 'KS',
      },
      {
        locality: 'Great Bend',
        region: 'KS',
      },
      {
        locality: 'Hutchinson',
        region: 'KS',
      },
      {
        locality: 'Kansas City',
        region: 'KS',
      },
      {
        locality: 'McPherson',
        region: 'KS',
      },
      {
        locality: 'Olathe',
        region: 'KS',
      },
      {
        locality: 'Salina',
        region: 'KS',
      },
      {
        locality: 'Scott City',
        region: 'KS',
      },
      {
        locality: 'Topeka',
        region: 'KS',
      },
      {
        locality: 'Wathena',
        region: 'KS',
      },
      {
        locality: 'Wichita',
        region: 'KS',
      },
      {
        locality: 'Arkansas City',
        region: 'KS',
      },
      {
        locality: 'KC/Magellan',
        region: 'KS',
      },
      {
        locality: 'KC/Sugar Creek',
        region: 'KS',
      },
      {
        locality: 'KC/Sinclair',
        region: 'KS',
      },
      {
        locality: 'KC/Phillips',
        region: 'KS',
      },
      {
        locality: 'Phillipsburg',
        region: 'KS',
      },
      {
        locality: 'Wichita/Williams',
        region: 'KS',
      },
      {
        locality: 'Wichita/Conoco',
        region: 'KS',
      },
    ],
  },
  {
    label: 'Kentucky',
    region: 'KY',
    localities: [
      {
        locality: 'Ashland',
        region: 'KY',
      },
      {
        locality: 'Covington',
        region: 'KY',
      },
      {
        locality: 'Lexington',
        region: 'KY',
      },
      {
        locality: 'Louisville',
        region: 'KY',
      },
      {
        locality: 'Paducah',
        region: 'KY',
      },
      {
        locality: 'Henderson',
        region: 'KY',
      },
      {
        locality: 'Owensboro',
        region: 'KY',
      },
    ],
  },
  {
    label: 'Louisiana',
    region: 'LA',
    localities: [
      {
        locality: 'Arcadia',
        region: 'LA',
      },
      {
        locality: 'Archie',
        region: 'LA',
      },
      {
        locality: 'Baton Rouge',
        region: 'LA',
      },
      {
        locality: 'Chalmette',
        region: 'LA',
      },
      {
        locality: 'Convent/Garyville',
        region: 'LA',
      },
      {
        locality: 'Lake Charles',
        region: 'LA',
      },
      {
        locality: 'Monroe',
        region: 'LA',
      },
      {
        locality: 'New Orleans',
        region: 'LA',
      },
      {
        locality: 'Opelousas',
        region: 'LA',
      },
      {
        locality: 'Shreveport',
        region: 'LA',
      },
      {
        locality: 'Alexandria',
        region: 'LA',
      },
    ],
  },
  {
    label: 'Maine',
    region: 'ME',
    localities: [
      {
        locality: 'Bangor',
        region: 'ME',
      },
      {
        locality: 'Portland',
        region: 'ME',
      },
      {
        locality: 'Augusta',
        region: 'ME',
      },
      {
        locality: 'Searsport',
        region: 'ME',
      },
    ],
  },
  {
    label: 'Maryland',
    region: 'MD',
    localities: [
      {
        locality: 'Baltimore',
        region: 'MD',
      },
      {
        locality: 'Salisbury',
        region: 'MD',
      },
    ],
  },
  {
    label: 'Massachusetts',
    region: 'MA',
    localities: [
      {
        locality: 'Boston',
        region: 'MA',
      },
      {
        locality: 'Springfield',
        region: 'MA',
      },
      {
        locality: 'Braintree',
        region: 'MA',
      },
      {
        locality: 'Chelsea',
        region: 'MA',
      },
      {
        locality: 'E. Boston',
        region: 'MA',
      },
      {
        locality: 'Everett',
        region: 'MA',
      },
      {
        locality: 'Revere',
        region: 'MA',
      },
    ],
  },
  {
    label: 'Michigan',
    region: 'MI',
    localities: [
      {
        locality: 'Bay City',
        region: 'MI',
      },
      {
        locality: 'Cheboygan',
        region: 'MI',
      },
      {
        locality: 'Detroit',
        region: 'MI',
      },
      {
        locality: 'Detroit',
        region: 'MI',
      },
      {
        locality: 'Ferrysburg',
        region: 'MI',
      },
      {
        locality: 'Flint',
        region: 'MI',
      },
      {
        locality: 'Holland',
        region: 'MI',
      },
      {
        locality: 'Jackson',
        region: 'MI',
      },
      {
        locality: 'Lansing',
        region: 'MI',
      },
      {
        locality: 'Muskegon',
        region: 'MI',
      },
      {
        locality: 'Niles',
        region: 'MI',
      },
      {
        locality: 'Alma',
        region: 'MI',
      },
      {
        locality: 'Albion',
        region: 'MI',
      },
      {
        locality: 'Marshall',
        region: 'MI',
      },
      {
        locality: 'Novi',
        region: 'MI',
      },
      {
        locality: 'Romulus',
        region: 'MI',
      },
      {
        locality: 'River Rouge',
        region: 'MI',
      },
      {
        locality: 'Taylor',
        region: 'MI',
      },
      {
        locality: 'Traverse City',
        region: 'MI',
      },
    ],
  },
  {
    label: 'Minnesota',
    region: 'MN',
    localities: [
      {
        locality: 'Alexandria',
        region: 'MN',
      },
      {
        locality: 'Duluth',
        region: 'MN',
      },
      {
        locality: 'Mankato',
        region: 'MN',
      },
      {
        locality: 'Marshall',
        region: 'MN',
      },
      {
        locality: 'Minneapolis',
        region: 'MN',
      },
      {
        locality: 'Rochester',
        region: 'MN',
      },
      {
        locality: 'Sauk Centre',
        region: 'MN',
      },
      {
        locality: 'Duluth/Esko',
        region: 'MN',
      },
      {
        locality: 'Duluth/Proctor',
        region: 'MN',
      },
      {
        locality: 'Duluth/Wrenshall',
        region: 'MN',
      },
      {
        locality: 'Pn Bnd/FlntHlsRs',
        region: 'MN',
      },
      {
        locality: 'St. Paul/Marathon',
        region: 'MN',
      },
      {
        locality: 'Roseville/Magellan',
        region: 'MN',
      },
      {
        locality: 'Rsvile/FlntHlsRs',
        region: 'MN',
      },
      {
        locality: 'Moorhead',
        region: 'MN',
      },
    ],
  },
  {
    label: 'Mississippi',
    region: 'MS',
    localities: [
      {
        locality: 'Aberdeen',
        region: 'MS',
      },
      {
        locality: 'Collins',
        region: 'MS',
      },
      {
        locality: 'Greenville',
        region: 'MS',
      },
      {
        locality: 'Meridian',
        region: 'MS',
      },
      {
        locality: 'Pascagoula',
        region: 'MS',
      },
      {
        locality: 'Vicksburg',
        region: 'MS',
      },
      {
        locality: 'Biloxi',
        region: 'MS',
      },
    ],
  },
  {
    label: 'Missouri',
    region: 'MO',
    localities: [
      {
        locality: 'Cape Girardeau',
        region: 'MO',
      },
      {
        locality: 'Carrollton',
        region: 'MO',
      },
      {
        locality: 'Carthage',
        region: 'MO',
      },
      {
        locality: 'Columbia',
        region: 'MO',
      },
      {
        locality: 'Mt. Vernon',
        region: 'MO',
      },
      {
        locality: 'Palmyra',
        region: 'MO',
      },
      {
        locality: 'Springfield',
        region: 'MO',
      },
      {
        locality: 'St. Louis',
        region: 'MO',
      },
      {
        locality: 'Bethany',
        region: 'MO',
      },
      {
        locality: 'Jefferson',
        region: 'MO',
      },
      {
        locality: 'Mexico',
        region: 'MO',
      },
      {
        locality: 'Riverside',
        region: 'MO',
      },
      {
        locality: 'St. Charles',
        region: 'MO',
      },
    ],
  },
  {
    label: 'Montana',
    region: 'MT',
    localities: [
      {
        locality: 'Billings',
        region: 'MT',
      },
      {
        locality: 'Bozeman',
        region: 'MT',
      },
      {
        locality: 'Glendive',
        region: 'MT',
      },
      {
        locality: 'Great Falls',
        region: 'MT',
      },
      {
        locality: 'Helena',
        region: 'MT',
      },
      {
        locality: 'Missoula',
        region: 'MT',
      },
      {
        locality: 'Burley',
        region: 'MT',
      },
      {
        locality: 'Cutbank',
        region: 'MT',
      },
    ],
  },
  {
    label: 'Nebraska',
    region: 'NE',
    localities: [
      {
        locality: 'Columbus',
        region: 'NE',
      },
      {
        locality: 'Doniphan',
        region: 'NE',
      },
      {
        locality: 'Geneva',
        region: 'NE',
      },
      {
        locality: 'Lincoln',
        region: 'NE',
      },
      {
        locality: 'Norfolk',
        region: 'NE',
      },
      {
        locality: 'North Platte',
        region: 'NE',
      },
      {
        locality: 'Omaha',
        region: 'NE',
      },
      {
        locality: 'Osceola',
        region: 'NE',
      },
      {
        locality: 'Sidney',
        region: 'NE',
      },
      {
        locality: 'Superior',
        region: 'NE',
      },
    ],
  },
  {
    label: 'Nevada',
    region: 'NV',
    localities: [
      {
        locality: 'Las Vegas',
        region: 'NV',
      },
      {
        locality: 'Sparks/Reno',
        region: 'NV',
      },
      {
        locality: 'Carlin',
        region: 'NV',
      },
    ],
  },
  {
    label: 'New Hampshire',
    region: 'NH',
    localities: [
      {
        locality: 'Newington',
        region: 'NH',
      },
    ],
  },
  {
    label: 'New Jersey',
    region: 'NJ',
    localities: [
      {
        locality: 'Linden',
        region: 'NJ',
      },
      {
        locality: 'Newark',
        region: 'NJ',
      },
      {
        locality: 'Paulsboro',
        region: 'NJ',
      },
      {
        locality: 'Sewaren',
        region: 'NJ',
      },
      {
        locality: 'Trenton',
        region: 'NJ',
      },
      {
        locality: 'Bayonne',
        region: 'NJ',
      },
      {
        locality: 'Newark Proper',
        region: 'NJ',
      },
      {
        locality: 'Elizabeth',
        region: 'NJ',
      },
      {
        locality: 'Pennsauken',
        region: 'NJ',
      },
    ],
  },
  {
    label: 'New Mexico',
    region: 'NM',
    localities: [
      {
        locality: 'Albuquerque',
        region: 'NM',
      },
      {
        locality: 'Artesia',
        region: 'NM',
      },
      {
        locality: 'Bloomfield',
        region: 'NM',
      },
      {
        locality: 'Ciniza',
        region: 'NM',
      },
      {
        locality: 'Moriarty',
        region: 'NM',
      },
      {
        locality: 'Tucumcari',
        region: 'NM',
      },
      {
        locality: 'Four Corners Ref.',
        region: 'NM',
      },
    ],
  },
  {
    label: 'New York',
    region: 'NY',
    localities: [
      {
        locality: 'Albany',
        region: 'NY',
      },
      {
        locality: 'Big Flats',
        region: 'NY',
      },
      {
        locality: 'Binghamton/Vestal',
        region: 'NY',
      },
      {
        locality: 'Buffalo',
        region: 'NY',
      },
      {
        locality: 'Inwood',
        region: 'NY',
      },
      {
        locality: 'Lawrence',
        region: 'NY',
      },
      {
        locality: 'Long Island',
        region: 'NY',
      },
      {
        locality: 'Mt. Vernon/Westchest',
        region: 'NY',
      },
      {
        locality: 'New York',
        region: 'NY',
      },
      {
        locality: 'Newburgh',
        region: 'NY',
      },
      {
        locality: 'Rochester',
        region: 'NY',
      },
      {
        locality: 'Syracuse',
        region: 'NY',
      },
      {
        locality: 'Utica',
        region: 'NY',
      },
      {
        locality: 'Holtsville',
        region: 'NY',
      },
      {
        locality: 'Oceanside',
        region: 'NY',
      },
      {
        locality: 'Oyster Bay',
        region: 'NY',
      },
      {
        locality: 'Long Island City',
        region: 'NY',
      },
      {
        locality: 'Geneva',
        region: 'NY',
      },
      {
        locality: 'Bronx',
        region: 'NY',
      },
      {
        locality: 'NY Reseller',
        region: 'NY',
      },
    ],
  },
  {
    label: 'North Carolina',
    region: 'NC',
    localities: [
      {
        locality: 'Charlotte',
        region: 'NC',
      },
      {
        locality: 'Fayetteville',
        region: 'NC',
      },
      {
        locality: 'Greensboro',
        region: 'NC',
      },
      {
        locality: 'Greensboro',
        region: 'NC',
      },
      {
        locality: 'Raleigh/Apex',
        region: 'NC',
      },
      {
        locality: 'Selma',
        region: 'NC',
      },
      {
        locality: 'Wilmington',
        region: 'NC',
      },
    ],
  },
  {
    label: 'North Dakota',
    region: 'ND',
    localities: [
      {
        locality: 'Bismarck/Mandan',
        region: 'ND',
      },
      {
        locality: 'Fargo',
        region: 'ND',
      },
      {
        locality: 'Grand Forks',
        region: 'ND',
      },
      {
        locality: 'Jamestown',
        region: 'ND',
      },
      {
        locality: 'Minot',
        region: 'ND',
      },
      {
        locality: 'Dickinson',
        region: 'ND',
      },
    ],
  },
  {
    label: 'Ohio',
    region: 'OH',
    localities: [
      {
        locality: 'Akron/Canton',
        region: 'OH',
      },
      {
        locality: 'Cincinnati',
        region: 'OH',
      },
      {
        locality: 'Cleveland',
        region: 'OH',
      },
      {
        locality: 'Columbus',
        region: 'OH',
      },
      {
        locality: 'Dayton',
        region: 'OH',
      },
      {
        locality: 'Lebanon',
        region: 'OH',
      },
      {
        locality: 'Lima',
        region: 'OH',
      },
      {
        locality: 'Lorain',
        region: 'OH',
      },
      {
        locality: 'Marietta',
        region: 'OH',
      },
      {
        locality: 'Toledo',
        region: 'OH',
      },
      {
        locality: 'Youngstown',
        region: 'OH',
      },
      {
        locality: 'Greenville',
        region: 'OH',
      },
      {
        locality: 'Heath',
        region: 'OH',
      },
      {
        locality: 'Sciotoville',
        region: 'OH',
      },
      {
        locality: 'Steubenville',
        region: 'OH',
      },
      {
        locality: 'Tiffin',
        region: 'OH',
      },
      {
        locality: 'Toledo/Sun',
        region: 'OH',
      },
      {
        locality: 'Toledo/Citgo',
        region: 'OH',
      },
      {
        locality: 'Toledo/BP',
        region: 'OH',
      },
    ],
  },
  {
    label: 'Oklahoma',
    region: 'OK',
    localities: [
      {
        locality: 'Ardmore',
        region: 'OK',
      },
      {
        locality: 'Enid',
        region: 'OK',
      },
      {
        locality: 'Oklahoma City',
        region: 'OK',
      },
      {
        locality: 'Ponca City',
        region: 'OK',
      },
      {
        locality: 'Tulsa',
        region: 'OK',
      },
      {
        locality: 'Wynnewood',
        region: 'OK',
      },
      {
        locality: 'Laverne',
        region: 'OK',
      },
      {
        locality: 'Okl/Group 3 Ref.',
        region: 'OK',
      },
      {
        locality: 'Okla/Sun',
        region: 'OK',
      },
      {
        locality: 'Okla/Conoco',
        region: 'OK',
      },
      {
        locality: 'Okla/Magellan',
        region: 'OK',
      },
      {
        locality: 'Shawnee',
        region: 'OK',
      },
      {
        locality: 'Tulsa/Conoco',
        region: 'OK',
      },
      {
        locality: 'Tulsa/Sinclair',
        region: 'OK',
      },
      {
        locality: 'Tulsa/Magellan',
        region: 'OK',
      },
      {
        locality: 'Turpin',
        region: 'OK',
      },
    ],
  },
  {
    label: 'Oregon',
    region: 'OR',
    localities: [
      {
        locality: 'Eugene',
        region: 'OR',
      },
      {
        locality: 'Portland',
        region: 'OR',
      },
      {
        locality: 'Umatilla',
        region: 'OR',
      },
      {
        locality: 'Albany',
        region: 'OR',
      },
    ],
  },
  {
    label: 'Pennsylvania',
    region: 'PA',
    localities: [
      {
        locality: 'Altoona',
        region: 'PA',
      },
      {
        locality: 'Harrisburg',
        region: 'PA',
      },
      {
        locality: 'Macungie',
        region: 'PA',
      },
      {
        locality: 'Midland',
        region: 'PA',
      },
      {
        locality: 'Northumberland',
        region: 'PA',
      },
      {
        locality: 'Philadelphia',
        region: 'PA',
      },
      {
        locality: 'Pittsburgh',
        region: 'PA',
      },
      {
        locality: 'Scranton',
        region: 'PA',
      },
      {
        locality: 'Sinking Springs',
        region: 'PA',
      },
      {
        locality: 'Warren',
        region: 'PA',
      },
      {
        locality: 'Williamsport',
        region: 'PA',
      },
      {
        locality: 'Greensburg',
        region: 'PA',
      },
      {
        locality: 'Lancaster',
        region: 'PA',
      },
      {
        locality: 'Middletown',
        region: 'PA',
      },
      {
        locality: 'Oil City',
        region: 'PA',
      },
      {
        locality: 'Pittsburgh Proper',
        region: 'PA',
      },
      {
        locality: 'Indianola',
        region: 'PA',
      },
      {
        locality: 'Blawnox',
        region: 'PA',
      },
      {
        locality: 'Coraopolis',
        region: 'PA',
      },
      {
        locality: 'Delmont',
        region: 'PA',
      },
      {
        locality: 'Neville',
        region: 'PA',
      },
      {
        locality: 'Reading',
        region: 'PA',
      },
    ],
  },
  {
    label: 'Rhode Island',
    region: 'RI',
    localities: [
      {
        locality: 'Providence',
        region: 'RI',
      },
    ],
  },
  {
    label: 'South Carolina',
    region: 'SC',
    localities: [
      {
        locality: 'Belton',
        region: 'SC',
      },
      {
        locality: 'Charleston',
        region: 'SC',
      },
      {
        locality: 'North Augusta',
        region: 'SC',
      },
      {
        locality: 'Spartanburg',
        region: 'SC',
      },
      {
        locality: 'Anderson',
        region: 'SC',
      },
      {
        locality: 'Aiken',
        region: 'SC',
      },
    ],
  },
  {
    label: 'South Dakota',
    region: 'SD',
    localities: [
      {
        locality: 'Aberdeen',
        region: 'SD',
      },
      {
        locality: 'Mitchell',
        region: 'SD',
      },
      {
        locality: 'Rapid City',
        region: 'SD',
      },
      {
        locality: 'Sioux Falls',
        region: 'SD',
      },
      {
        locality: 'Watertown',
        region: 'SD',
      },
      {
        locality: 'Wolsey',
        region: 'SD',
      },
      {
        locality: 'Yankton',
        region: 'SD',
      },
      {
        locality: 'Pierre',
        region: 'SD',
      },
    ],
  },
  {
    label: 'Tennessee',
    region: 'TN',
    localities: [
      {
        locality: 'Chattanooga',
        region: 'TN',
      },
      {
        locality: 'Knoxville',
        region: 'TN',
      },
      {
        locality: 'Memphis',
        region: 'TN',
      },
      {
        locality: 'Nashville',
        region: 'TN',
      },
      {
        locality: 'Greenville',
        region: 'TN',
      },
    ],
  },
  {
    label: 'Texas',
    region: 'TX',
    localities: [
      {
        locality: 'Abilene',
        region: 'TX',
      },
      {
        locality: 'Amarillo',
        region: 'TX',
      },
      {
        locality: 'Austin',
        region: 'TX',
      },
      {
        locality: 'Beaumont',
        region: 'TX',
      },
      {
        locality: 'Big Spring',
        region: 'TX',
      },
      {
        locality: 'Brownsville',
        region: 'TX',
      },
      {
        locality: 'Caddo Mills',
        region: 'TX',
      },
      {
        locality: 'Center',
        region: 'TX',
      },
      {
        locality: 'Corpus Christi',
        region: 'TX',
      },
      {
        locality: 'Dallas Metro',
        region: 'TX',
      },
      {
        locality: 'El Paso',
        region: 'TX',
      },
      {
        locality: 'Frost',
        region: 'TX',
      },
      {
        locality: 'Harlingen',
        region: 'TX',
      },
      {
        locality: 'Hearne',
        region: 'TX',
      },
      {
        locality: 'Houston',
        region: 'TX',
      },
      {
        locality: 'Laredo',
        region: 'TX',
      },
      {
        locality: 'Lubbock',
        region: 'TX',
      },
      {
        locality: 'Midland/Odessa',
        region: 'TX',
      },
      {
        locality: 'Mt. Pleasant',
        region: 'TX',
      },
      {
        locality: 'San Angelo',
        region: 'TX',
      },
      {
        locality: 'San Antonio',
        region: 'TX',
      },
      {
        locality: 'Sheerin',
        region: 'TX',
      },
      {
        locality: 'Three Rivers',
        region: 'TX',
      },
      {
        locality: 'Tyler',
        region: 'TX',
      },
      {
        locality: 'Victoria/Placedo',
        region: 'TX',
      },
      {
        locality: 'Waco',
        region: 'TX',
      },
      {
        locality: 'Wichita Falls',
        region: 'TX',
      },
      {
        locality: 'Bay City',
        region: 'TX',
      },
      {
        locality: 'Bryan',
        region: 'TX',
      },
      {
        locality: 'Crane',
        region: 'TX',
      },
      {
        locality: 'Dallas Proper',
        region: 'TX',
      },
      {
        locality: 'Dallas/Arlington',
        region: 'TX',
      },
      {
        locality: 'Dallas/Grapevine',
        region: 'TX',
      },
      {
        locality: 'Eastland',
        region: 'TX',
      },
      {
        locality: 'Dallas/Southlake',
        region: 'TX',
      },
      {
        locality: 'Dallas/Euless',
        region: 'TX',
      },
      {
        locality: 'Dallas/Ft. Worth',
        region: 'TX',
      },
      {
        locality: 'Dallas/Aledo',
        region: 'TX',
      },
      {
        locality: 'Edinburg',
        region: 'TX',
      },
      {
        locality: 'Gulf Coast',
        region: 'TX',
      },
      {
        locality: 'Hidalgo',
        region: 'TX',
      },
      {
        locality: 'Waskom',
        region: 'TX',
      },
      {
        locality: 'Wilcox',
        region: 'TX',
      },
    ],
  },
  {
    label: 'Utah',
    region: 'UT',
    localities: [
      {
        locality: 'Cedar City',
        region: 'UT',
      },
      {
        locality: 'Salt Lake City',
        region: 'UT',
      },
    ],
  },
  {
    label: 'Vermont',
    region: 'VT',
    localities: [
      {
        locality: 'Burlington',
        region: 'VT',
      },
      {
        locality: 'Rutland',
        region: 'VT',
      },
    ],
  },
  {
    label: 'Virginia',
    region: 'VA',
    localities: [
      {
        locality: 'Fairfax',
        region: 'VA',
      },
      {
        locality: 'Norfolk',
        region: 'VA',
      },
      {
        locality: 'Richmond',
        region: 'VA',
      },
      {
        locality: 'Roanoke',
        region: 'VA',
      },
      {
        locality: 'Yorktown',
        region: 'VA',
      },
    ],
  },
  {
    label: 'Washington',
    region: 'WA',
    localities: [
      {
        locality: 'Anacortes',
        region: 'WA',
      },
      {
        locality: 'Moses Lake',
        region: 'WA',
      },
      {
        locality: 'Pasco',
        region: 'WA',
      },
      {
        locality: 'Seattle',
        region: 'WA',
      },
      {
        locality: 'Spokane',
        region: 'WA',
      },
      {
        locality: 'Tacoma',
        region: 'WA',
      },
      {
        locality: 'Wilma',
        region: 'WA',
      },
    ],
  },
  {
    label: 'Washington',
    region: 'DC',
    localities: [
      {
        locality: 'Washington',
        region: 'DC',
      },
    ],
  },
  {
    label: 'West Virginia',
    region: 'WV',
    localities: [
      {
        locality: 'Charleston',
        region: 'WV',
      },
    ],
  },
  {
    label: 'Wisconsin',
    region: 'WI',
    localities: [
      {
        locality: 'Chippewa Falls',
        region: 'WI',
      },
      {
        locality: 'Green Bay',
        region: 'WI',
      },
      {
        locality: 'Junction City',
        region: 'WI',
      },
      {
        locality: 'Madison',
        region: 'WI',
      },
      {
        locality: 'Milwaukee',
        region: 'WI',
      },
      {
        locality: 'Superior',
        region: 'WI',
      },
      {
        locality: 'Waupun',
        region: 'WI',
      },
      {
        locality: 'Oshkosh',
        region: 'WI',
      },
      {
        locality: 'Wausau',
        region: 'WI',
      },
    ],
  },
  {
    label: 'Wyoming',
    region: 'WY',
    localities: [
      {
        locality: 'Casper',
        region: 'WY',
      },
      {
        locality: 'Cheyenne',
        region: 'WY',
      },
      {
        locality: 'New Castle',
        region: 'WY',
      },
      {
        locality: 'Rock Springs',
        region: 'WY',
      },
      {
        locality: 'Sheridan',
        region: 'WY',
      },
      {
        locality: 'Sinclair',
        region: 'WY',
      },
    ],
  },
];

export const DIRECT_CONNECT_BRANDS = [
  'Shell',
  '76',
  'Chevron',
  'BP',
  'Conoco',
  'Phillips 66',
  'Valero',
  'Texaco',
  'Amoco',
  'Arco',
  "Huck's",
  'CountryMark',
  'MotoMart',
  'NomNom',
];
