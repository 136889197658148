/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, takeLatest } from 'typed-redux-saga';

import * as api from './api';
import { formatData } from './dataFormatting';
import { capacityFailed, capacityFetch, capacitySucceeded } from './slice';

/*****************************************************************************/
/******************************* WORKERS *************************************/
/*****************************************************************************/

export function* fetchCapacity(action: any) {
  try {
    const result = yield* call(api.fetchCapacity, action.payload);
    const refinedResult = formatData(result);

    yield* put(capacitySucceeded(refinedResult));
  } catch (error: any) {
    yield* put(capacityFailed(error.message));
  }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/
export function* watchCapacity() {
  yield* takeLatest(capacityFetch, fetchCapacity);
}
