import { Navigate, Route, Routes } from 'react-router-dom';

import { ColumnSelection } from './steps/ColumnSelection';
import { DownloadTypeSelector } from './steps/DownloadTypeSelector';
import { GranularitySelector } from './steps/GranularitySelector';

export function CustomReports() {
  return (
    <Routes>
      <Route index element={<Navigate to='granularity' />} />
      <Route path='granularity' element={<GranularitySelector />} />
      <Route path='fields' element={<ColumnSelection />} />
      <Route path='download' element={<DownloadTypeSelector />} />
    </Routes>
  );
}
