import { Box, Flex } from '@chakra-ui/react';

import { IDualMetricCardProps } from './types';

export const DualMetricCard = ({ leftMetricContent, rightMetricContent, ...boxProps }: IDualMetricCardProps) => (
  <Flex
    w='100%'
    bg='background.whitecap.light'
    px={5}
    py={4}
    borderRadius='2xl'
    gap='10'
    mt={8}
    data-testid='dualMetricCard'
  >
    <Box w='100%' alignContent='left' data-testid='dualMetricCard-leftContent' {...boxProps}>
      {leftMetricContent}
    </Box>
    <Box w='100%' alignContent='left' data-testid='dualMetricCard-rightContent' {...boxProps}>
      {rightMetricContent}
    </Box>
  </Flex>
);
