import { Navigate, useParams } from 'react-router-dom';

import { useAppSelector } from '@/app/hooks';
import { Verticals } from '@/common/interfaces';
import { selectActiveVertical } from '@/features/auth/userSlice';

import { GroceryRestaurantLocationSettings } from './components/forms/GroceryRestaurantLocationSettings';
import { ManageFuelLocation } from './components/forms/ManageFuelLocation';

export function ManageLocation() {
  const { id } = useParams<{ id: string }>();
  const activeVertical = useAppSelector(selectActiveVertical);

  const location = useAppSelector((state) =>
    state.locationsSettings.locations.find((location) => location.uuid === id),
  );

  if (!location) {
    return <Navigate to='/settings/locations' />;
  }

  if (activeVertical !== Verticals.Fuel) {
    return <GroceryRestaurantLocationSettings location={location} />;
  }

  return <ManageFuelLocation location={location} />;
}
