import { RootState } from '@app/store';
import { PaginationOptions } from '@components/pagination/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Status, Verticals } from '@/common/interfaces';
import { TemporalUnit } from '@/features/dashboard/slice';

import {
  IAllCustomersSummary,
  ICustomerSegmentationSummary,
  ICustomerSummary,
  IRefinedCustomersList,
  IRefinedCustomerTransactions,
} from './types';

export interface CustomerRequestPayload {
  authRequestPath: string;
  page: number;
  pageSize: number;
  query: string;
  startDate: string;
  endDate: string;
  networkNames: string[];
  activeVertical: Verticals;
}

export interface RecentTransactionsListRequestPayload {
  startDate: string;
  endDate: string;
  authRequestPath: string;
  activeVertical: Verticals;
  page: number;
  pageSize?: number;
}

export interface CustomerTransactionsRequestPayload {
  userUuid: string;
  authRequestPath: string;
  activeVertical: Verticals;
  page: number;
  pageSize?: number;
}

export interface AllCustomersSummaryRequestPayload {
  authRequestPath: string;
  startDate: string;
  endDate: string;
  activeVertical: Verticals;
}

export interface CustomerSegmentationRequestPayload {
  authRequestPath: string;
  startDate: string;
  endDate: string;
}

export interface CustomerSummaryRequestPayload {
  authRequestPath: string;
  userUuid: string;
  activeVertical: Verticals;
}

export interface CustomersListState {
  customers: IRefinedCustomersList;
  page: number;
  paginationOptions: PaginationOptions;
  status: Status.Idle | Status.Loading | Status.Failed;
  error?: string;
}

export interface RecentTransactionsListState {
  transactions: IRefinedCustomerTransactions;
  page: number;
  paginationOptions: PaginationOptions;
  status: Status.Idle | Status.Loading | Status.Failed;
  error?: string;
}

export interface CustomerTransactionsState {
  transactions: IRefinedCustomerTransactions;
  page: number;
  status: Status.Idle | Status.Loading | Status.Failed;
  error?: string;
}

export interface CustomerSummaryState {
  summary?: ICustomerSummary;
  status: Status.Idle | Status.Loading | Status.Failed;
  error?: string;
}

export interface AllCustomersSummaryState {
  summary?: IAllCustomersSummary;
  status: Status.Idle | Status.Loading | Status.Failed;
  error?: string;
}

export interface CustomerSegmentationSummaryState {
  summary?: ICustomerSegmentationSummary;
  status: Status.Idle | Status.Loading | Status.Failed;
  error?: string;
}

export interface CustomersState {
  customerSearchQuery?: string;
  allCustomersSummary: AllCustomersSummaryState;
  customersList: CustomersListState;
  customerTransactions: CustomerTransactionsState;
  customerSummary: CustomerSummaryState;
  customerSegmentationSummary: CustomerSegmentationSummaryState;
  recentTransactionsList: RecentTransactionsListState;
}

export const initialState: CustomersState = {
  allCustomersSummary: {
    status: Status.Idle,
  },
  customersList: {
    customers: {
      nextPage: null,
      prevPage: null,
      table: {
        rows: [],
      },
      count: 0,
    },
    page: 1,
    paginationOptions: {
      pageSize: 25,
      availablePageSizes: [5, 10, 50, 100, 250],
    },
    status: Status.Idle,
  },
  recentTransactionsList: {
    transactions: { table: { rows: [] }, nextPage: null, prevPage: null, count: 0 },
    page: 1,
    paginationOptions: {
      pageSize: 5,
      availablePageSizes: [5, 10, 50, 100, 250],
    },
    status: Status.Idle,
  },
  customerTransactions: {
    transactions: { table: { rows: [] }, nextPage: null, prevPage: null, count: 0 },
    page: 1,
    status: Status.Idle,
  },
  customerSummary: {
    status: Status.Idle,
  },
  customerSegmentationSummary: {
    status: Status.Idle,
  },
};

export const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    allCustomersSummaryFetch: (
      state,
      _action: PayloadAction<{
        startDate: string;
        endDate: string;
        temporalUnit: TemporalUnit;
        selectedNetworks: string[];
        selectedTransactionType: string;
        authRequestPath: string;
        activeVertical: string;
        networkNames: string[];
        locations: string[];
        transactionType: string;
      }>,
    ) => {
      state.allCustomersSummary.summary = undefined;
      state.allCustomersSummary.error = undefined;
      state.allCustomersSummary.status = Status.Loading;
    },
    allCustomersSummarySucceeded: (state, action: PayloadAction<IAllCustomersSummary>) => {
      state.allCustomersSummary.summary = action.payload;
      state.allCustomersSummary.status = Status.Idle;
    },
    allCustomersSummaryFailed: (state, action: PayloadAction<string>) => {
      state.allCustomersSummary.error = action.payload;
      state.allCustomersSummary.status = Status.Failed;
    },
    customerSegmentationSummaryFetch: (
      state,
      _action: PayloadAction<{
        startDate: string;
        endDate: string;
        temporalUnit: TemporalUnit;
        selectedNetworks: string[];
        selectedTransactionType: string;
        authRequestPath: string;
        activeVertical: string;
        networkNames: string[];
        locations: string[];
        transactionType: string;
      }>,
    ) => {
      state.customerSegmentationSummary.summary = undefined;
      state.customerSegmentationSummary.error = undefined;
      state.customerSegmentationSummary.status = Status.Loading;
    },
    customerSegmentationSummarySucceeded: (state, action: PayloadAction<ICustomerSegmentationSummary>) => {
      state.customerSegmentationSummary.summary = action.payload;
      state.customerSegmentationSummary.status = Status.Idle;
    },
    customerSegmentationSummaryFailed: (state, action: PayloadAction<string>) => {
      state.customerSegmentationSummary.error = action.payload;
      state.customerSegmentationSummary.status = Status.Failed;
    },
    customersFetch: (state) => {
      state.customersList.error = undefined;
      state.customersList.status = Status.Loading;
    },
    customersSucceeded: (state, action: PayloadAction<IRefinedCustomersList>) => {
      state.customersList.customers = action.payload;
      state.customersList.status = Status.Idle;
    },
    customersFailed: (state, action: PayloadAction<string>) => {
      state.customersList.error = action.payload;
      state.customersList.status = Status.Failed;
    },
    customerSummaryFetch: (state) => {
      state.customerSummary.error = undefined;
      state.customerSummary.status = Status.Loading;
    },
    customerSummarySucceeded: (state, action: PayloadAction<ICustomerSummary>) => {
      state.customerSummary.summary = action.payload;
      state.customerSummary.status = Status.Idle;
    },
    customerSummaryFailed: (state, action: PayloadAction<string>) => {
      state.customerSummary.error = action.payload;
      state.customerSummary.status = Status.Failed;
    },
    customerTransactionsFetch: (state, action: PayloadAction<CustomerTransactionsRequestPayload>) => {
      state.customerTransactions.page = action.payload.page;
      state.customerTransactions.error = undefined;
      state.customerTransactions.status = Status.Loading;
    },
    customerTransactionsSucceeded: (state, action: PayloadAction<IRefinedCustomerTransactions>) => {
      state.customerTransactions.transactions = action.payload;
      state.customerTransactions.status = Status.Idle;
    },
    customerTransactionsFailed: (state, action: PayloadAction<string>) => {
      state.customerTransactions.error = action.payload;
      state.customerTransactions.status = Status.Failed;
    },
    recentTransactionsListFetch: (state, action: PayloadAction<RecentTransactionsListRequestPayload>) => {
      state.recentTransactionsList.page = action.payload.page;
      state.recentTransactionsList.error = undefined;
      state.recentTransactionsList.status = Status.Loading;
    },
    recentTransactionsListSucceeded: (state, action: PayloadAction<IRefinedCustomerTransactions>) => {
      state.recentTransactionsList.transactions = action.payload;
      state.recentTransactionsList.status = Status.Idle;
    },
    recentTransactionsListFailed: (state, action: PayloadAction<string>) => {
      state.recentTransactionsList.error = action.payload;
      state.recentTransactionsList.status = Status.Failed;
    },
    setCustomerSearchQuery: (state, action: PayloadAction<string>) => {
      state.customerSearchQuery = action.payload;
    },
    setCustomerListPaginationOptions: (state, action: PayloadAction<PaginationOptions>) => {
      state.customersList.paginationOptions = action.payload;
    },
    setCustomerListPage: (state, action: PayloadAction<number>) => {
      state.customersList.page = action.payload;
    },
    setRecentTransactionsListPaginationOptions: (state, action: PayloadAction<PaginationOptions>) => {
      state.recentTransactionsList.paginationOptions = action.payload;
    },
    setRecentTransactionsListPage: (state, action: PayloadAction<number>) => {
      state.recentTransactionsList.page = action.payload;
    },
  },
});

export const {
  customersFetch,
  customersSucceeded,
  customersFailed,
  customerSummaryFetch,
  customerSummarySucceeded,
  customerSummaryFailed,
  allCustomersSummaryFetch,
  allCustomersSummarySucceeded,
  allCustomersSummaryFailed,
  customerTransactionsFetch,
  customerTransactionsSucceeded,
  customerTransactionsFailed,
  recentTransactionsListFetch,
  recentTransactionsListSucceeded,
  recentTransactionsListFailed,
  customerSegmentationSummaryFetch,
  customerSegmentationSummarySucceeded,
  customerSegmentationSummaryFailed,
  setCustomerSearchQuery,
  setCustomerListPaginationOptions,
  setCustomerListPage,
  setRecentTransactionsListPaginationOptions,
  setRecentTransactionsListPage,
} = customersSlice.actions;

export const selectCustomersListStatus = (state: RootState) => state.customers.customersList.status;
export const selectCustomersList = (state: RootState) => state.customers.customersList;

export const selectRecentTransactionsListStatus = (state: RootState) => state.customers.recentTransactionsList.status;
export const selectRecentTransactionsList = (state: RootState) => state.customers.recentTransactionsList;

export const selectCustomerSummary = (state: RootState) => state.customers.customerSummary;

export const selectAllCustomersSummary = (state: RootState) => state.customers.allCustomersSummary.summary;
export const selectAllCustomersSummaryStatus = (state: RootState) => state.customers.allCustomersSummary.status;

export const selectCustomersSegmentationSummary = (state: RootState) =>
  state.customers.customerSegmentationSummary.summary;
export const selectCustomersSegmentationSummaryStatus = (state: RootState) =>
  state.customers.customerSegmentationSummary.status;

export const selectCustomerTransactions = (state: RootState) => state.customers.customerTransactions;
export const selectCustomerSearchQuery = (state: RootState) => state.customers.customerSearchQuery || '';

export default customersSlice.reducer;
