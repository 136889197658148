import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Flex, FormControl, Heading, Input, Radio, RadioGroup, Text, VStack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { merge } from 'lodash';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { CustomFormLabel } from '@/common/components/form';
import { Verticals } from '@/common/interfaces';
import { AccountRegistrationFormData } from '@/features/registration/types';
import { accountRegistrationSchema } from '@/features/registration/validators';

import {
  selectIsDefaultWorkflow,
  selectRegistrationData,
  updateIsDefaultWorkflow,
  updateRegistrationFormData,
} from '../slice';

const useQuery = () => {
  return new URLSearchParams(useLocation().search.toLowerCase());
};

export function AccountRegistration() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const VERTICALS = Object.values(Verticals).filter(
    (value) => value !== Verticals.Unknown && value !== Verticals.CStore,
  );
  const isDefaultWorkflow = useAppSelector(selectIsDefaultWorkflow);
  const isDefaultWorkflowQuery = useQuery().get('isdefaultworkflow') === 'false' ? false : true;

  if (isDefaultWorkflow && isDefaultWorkflowQuery === false) dispatch(updateIsDefaultWorkflow(isDefaultWorkflowQuery));

  const registrationFormData = useAppSelector(selectRegistrationData);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<AccountRegistrationFormData>({
    resolver: zodResolver(accountRegistrationSchema),
    defaultValues: {
      ...registrationFormData,
    },
  });

  const onSubmit = (data: AccountRegistrationFormData) => {
    const updatedData = merge({ ...registrationFormData, ...data });
    dispatch(updateRegistrationFormData(updatedData));
    navigate('/registration/2');
  };

  return (
    <Box mt={4}>
      <Heading as='h2' size='md' mb={4}>
        {t('registrationPage.titles.accountRegistration')}
      </Heading>

      <FormControl as='form' onSubmit={handleSubmit(onSubmit)}>
        <Box mt='2'>
          <Text fontWeight='bold' mt='6'>
            {t('registrationPage.fields.verticalHelp')}
          </Text>
          <RadioGroup my={5} defaultValue={'fuel'}>
            <VStack alignItems='flex-start'>
              {VERTICALS.map((td) => (
                <Radio key={td} value={td} color='neutral.900' {...register('formData.vertical')}>
                  <Text fontSize='sm'>{t(`registrationPage.verticals.${td}`)}</Text>
                </Radio>
              ))}
            </VStack>
          </RadioGroup>
          {errors.formData?.vertical && <Text color='red.500'>{errors.formData.vertical.message}</Text>}
        </Box>
        <Flex mt={2} gap={2}>
          <Box w='full'>
            <Text fontWeight='bold' mt={6} mb={2}>
              {t('registrationPage.fields.merchantLegalNameHelp')}
            </Text>
            <Text>{t('registrationPage.fields.merchantLegalName')}</Text>
            <CustomFormLabel htmlFor='merchantLegalName'>
              <Input
                id='merchantLegalName'
                mt={2}
                {...register('formData.merchantLegalName')}
                isInvalid={!!errors.formData?.merchantLegalName}
              />
              {errors.formData?.merchantLegalName && (
                <Text variant='error'>{errors.formData.merchantLegalName.message}</Text>
              )}
            </CustomFormLabel>
          </Box>
        </Flex>
        <Button mt={5} type='submit' variant='brandPrimary' size='md' px={8}>
          {t('registrationPage.actions.continue')}
        </Button>
      </FormControl>
    </Box>
  );
}
