import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Box } from '@chakra-ui/react';
import { EmptyDataAlert } from '@components/errorAlert';
import { Pagination } from '@components/pagination';
import { AsyncPaginationContainer } from '@components/pagination/AsyncPaginationContainer';
import { PaginationProps } from '@components/pagination/types';
import { ITableData, Table } from '@components/table';
import { ITableCellValue } from '@components/table/Table';
import _ from 'lodash';

import { Status, TransactionTypes } from '@/common/interfaces';

import { selectActiveVertical, selectAuthRequestPath, selectUserOrg, selectUserRoles } from '../../auth/userSlice';
import {
  selectEndDate,
  selectSelectedLocations,
  selectSelectedNetworks,
  selectSelectedTransactionType,
  selectStartDate,
} from '../../dashboard/slice';
import {
  customersFetch,
  selectCustomersList,
  setCustomerListPage,
  setCustomerListPaginationOptions,
} from '../../explore/customerDeepDive/slice';

export const CustomersTable = () => {
  const activeVertical = useAppSelector(selectActiveVertical);
  const authRequestPath = useAppSelector(selectAuthRequestPath);
  const { customers, status } = useAppSelector(selectCustomersList);
  const customerList = useAppSelector(selectCustomersList);
  const endDate = useAppSelector(selectEndDate);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const orgId = useAppSelector(selectUserOrg);
  const roles = useAppSelector(selectUserRoles);
  const selectedLocations = useAppSelector(selectSelectedLocations);
  const selectedNetworks = useAppSelector(selectSelectedNetworks);
  const selectedTransactionType = useAppSelector(selectSelectedTransactionType);
  const startDate = useAppSelector(selectStartDate);

  const fetchCustomersList = useCallback(
    ({
      startDate,
      endDate,
      query = '',
      page = customerList.page,
      pageSize = customerList.paginationOptions.pageSize,
      networkNames,
      transactionType,
      locations,
    }: {
      startDate: string;
      endDate: string;
      query: string;
      page: number;
      pageSize: number;
      networkNames: string[];
      transactionType: TransactionTypes;
      locations: string[];
    }) => {
      if (
        !_.isEmpty(startDate) &&
        !_.isEmpty(endDate) &&
        !_.isEmpty(orgId) &&
        !_.isEmpty(activeVertical) &&
        !_.isEmpty(roles)
      ) {
        const payload = {
          startDate,
          endDate,
          query,
          page,
          pageSize,
          authRequestPath,
          networkNames,
          transactionType,
          activeVertical,
          locations,
        };
        dispatch(customersFetch(payload));
      }
    },
    [orgId, activeVertical, roles, authRequestPath, dispatch],
  );

  useEffect(() => {
    if (orgId !== '' && !_.isEmpty(roles) && !_.isEmpty(activeVertical) && startDate && endDate) {
      fetchCustomersList({
        startDate: startDate,
        endDate: endDate,
        query: '',
        page: customerList.page,
        pageSize: customerList.paginationOptions.pageSize,
        networkNames: selectedNetworks,
        transactionType: selectedTransactionType,
        locations: selectedLocations,
      });
    }
  }, [
    activeVertical,
    customerList.page,
    customerList.paginationOptions.pageSize,
    endDate,
    fetchCustomersList,
    orgId,
    roles,
    selectedLocations,
    selectedNetworks,
    selectedTransactionType,
    startDate,
  ]);

  const isEmpty = status === Status.Idle && customers.table?.rows.length === 0;

  function handlePageChange(page: number): void {
    dispatch(setCustomerListPage(page));
  }

  function handlePageSizeChange(pageSize: number): void {
    dispatch(
      setCustomerListPaginationOptions(Object.assign({}, customerList.paginationOptions, { pageSize: pageSize })),
    );
  }

  const handleClick = (row: Array<string | number | ITableCellValue>) => {
    if (typeof row[0] === 'object' && row[0].rawValue) {
      navigate(`/explore/customerDeepDive/customer?userUuid=${row[0].rawValue}`);
    }
  };

  return (
    <Box w={'100%'}>
      {isEmpty ? (
        <EmptyDataAlert />
      ) : (
        <Box>
          <AsyncPaginationContainer
            data={customerList.customers.table?.rows || []}
            page={customerList.page}
            total={customerList.customers?.count}
            prevPage={customerList.customers?.prevPage}
            nextPage={customerList.customers?.nextPage}
            isLoading={status === Status.Loading}
            options={customerList.paginationOptions}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          >
            {(props: PaginationProps) => {
              const { data } = props;
              const tableData: ITableData = Object.assign({}, customerList.customers?.table, {
                rows: data,
              });

              let content = <Box height='400px' />;
              if (data.length > 0) {
                content = (
                  <>
                    <Table data={tableData} onClick={handleClick} />
                    <Pagination {...props} />
                  </>
                );
              }

              return content;
            }}
          </AsyncPaginationContainer>
        </Box>
      )}
    </Box>
  );
};
