import { defineStyleConfig } from '@chakra-ui/react';

const FormLabelStyles = defineStyleConfig({
  baseStyle: {
    fontFamily: 'Gilmer',
    fontSize: 'md',
    fontWeight: 'bold',
    color: 'text.default',

    _isInvalid: {
      color: 'text.danger.default',
    },
    _isDisabled: {
      color: 'text.disabled',
    },
  },
});

export default FormLabelStyles;
