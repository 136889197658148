import { Trans, useTranslation } from 'react-i18next';
import { useAppSelector } from '@app/hooks';
import { Heading, Link, Text } from '@chakra-ui/react';
import { BannerStat } from '@components/bannerStat';
import { ExpansionPanel } from '@components/expansionPanel';

import { Verticals } from '@/common/interfaces';
import { selectActiveVertical } from '@/features/auth/userSlice';
import { selectSelectedTransactionType } from '@/features/dashboard/slice';

export const IncrementalRevenueView = ({
  totalUpsideRevenue,
  expectedRevenueFromControl,
  incrementalRevenue,
  totalGallons,
  nonIncrementalGallons,
  incrementalGallons,
}: {
  totalUpsideRevenue: string;
  expectedRevenueFromControl: string;
  incrementalRevenue: string;
  totalGallons: string;
  nonIncrementalGallons: string;
  incrementalGallons: string;
}) => {
  const { t } = useTranslation();
  const activeVertical = useAppSelector(selectActiveVertical);
  const selectedTransactionType = useAppSelector(selectSelectedTransactionType);

  return (
    <>
      <Heading size='md' mt={6}>
        {activeVertical === Verticals.Fuel && selectedTransactionType === 'CONVENIENCE_STORE'
          ? t(`profitabilityPage.incrementalSalesHeader.c-store`)
          : t(`profitabilityPage.incrementalSalesHeader.${activeVertical}`)}
      </Heading>

      {(activeVertical === Verticals.Fuel && selectedTransactionType === 'allTransactions') ||
      (activeVertical === Verticals.Fuel && selectedTransactionType === 'GAS') ? (
        <BannerStat
          label={t(`profitabilityPage.incrementalSalesStatLabel.${activeVertical}`)}
          value={incrementalGallons}
        />
      ) : activeVertical === Verticals.Fuel && selectedTransactionType === 'CONVENIENCE_STORE' ? (
        <BannerStat label={t(`profitabilityPage.incrementalSalesStatLabel.c-store`)} value={incrementalRevenue} />
      ) : (
        <BannerStat
          label={t(`profitabilityPage.incrementalSalesStatLabel.${activeVertical}`)}
          value={incrementalRevenue}
        />
      )}

      <Text>
        {activeVertical === Verticals.Fuel && selectedTransactionType === 'CONVENIENCE_STORE' ? (
          t('profitabilityPage.incrementalRevenueText1.c-store')
        ) : (
          <Trans
            i18nKey={`profitabilityPage.incrementalRevenueText1.${activeVertical}`}
            components={{ bold: <strong /> }}
          />
        )}
      </Text>

      <Text>
        {activeVertical === Verticals.Fuel && selectedTransactionType === 'CONVENIENCE_STORE' ? (
          <Trans i18nKey={'profitabilityPage.incrementalRevenueText2.c-store'} components={{ bold: <strong /> }} />
        ) : (
          <Trans
            i18nKey={`profitabilityPage.incrementalRevenueText2.${activeVertical}`}
            components={{ bold: <strong /> }}
          />
        )}
      </Text>

      <Text>
        {((activeVertical === Verticals.Fuel && selectedTransactionType === 'allTransactions') ||
          (activeVertical === Verticals.Fuel && selectedTransactionType === 'GAS')) && (
          <Trans
            i18nKey={`profitabilityPage.incrementalRevenueText3.${activeVertical}`}
            components={{ bold: <strong /> }}
            values={{ totalGallons, nonIncrementalGallons, incrementalGallons }}
          />
        )}
        {activeVertical === Verticals.Fuel && selectedTransactionType === 'CONVENIENCE_STORE' && (
          <Trans
            i18nKey={`profitabilityPage.incrementalRevenueText3.c-store`}
            components={{ bold: <strong /> }}
            values={{ incrementalRevenue, totalUpsideRevenue, expectedRevenueFromControl }}
          />
        )}
        {activeVertical === Verticals.Grocery && (
          <Trans
            i18nKey={`profitabilityPage.incrementalRevenueText3.${activeVertical}`}
            components={{ bold: <strong /> }}
            values={{ incrementalRevenue, totalUpsideRevenue, expectedRevenueFromControl }}
          />
        )}
        {activeVertical === Verticals.Restaurant && (
          <Trans i18nKey={`profitabilityPage.incrementalRevenueText3.${activeVertical}`} />
        )}
      </Text>
      {activeVertical === Verticals.Fuel &&
        (selectedTransactionType === 'allTransactions' ||
          selectedTransactionType === 'GAS' ||
          selectedTransactionType === 'CONVENIENCE_STORE') && (
          <ExpansionPanel header={t('profitabilityPage.incrementalRevenueFaq.question')}>
            <Text lineHeight={'24px'}>{t('profitabilityPage.incrementalRevenueFaq.answer')}</Text>{' '}
            <Text color='text.primary-inverse' lineHeight={'24px'}>
              <Link
                href='https://fuelknowledgecenter.upside.com/hc/en-us/articles/10715331807003-Intro-to-Upside-s-measurement-methodology'
                isExternal
              >
                {t('profitabilityPage.incrementalRevenueFaq.learnMoreLink')}
              </Link>
            </Text>
          </ExpansionPanel>
        )}
    </>
  );
};
