/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, takeLatest } from 'typed-redux-saga';

import * as api from './api';
import { buildPayoutsTable } from './dataFormatting';
import { payoutsFetchFailed, payoutsFetchRequest, payoutsFetchSucceeded } from './slice';
import { IPayoutsList, IPayoutsRawData } from './types';

/*****************************************************************************/
/******************************* WORKERS *************************************/
/*****************************************************************************/
export function* fetchPayouts(action: any) {
  try {
    const result: IPayoutsRawData = yield* call(api.fetchPayouts, action.payload);
    const table = buildPayoutsTable(result, action.payload.activeVertical);
    const billableEntities = result.account_names.filter((item) => item !== null) as string[];

    const data: IPayoutsList = {
      table,
      rawData: result.records,
      prevPage: result.prev_page,
      nextPage: result.next_page,
      count: result.count,
      billableEntities,
    };
    yield* put(payoutsFetchSucceeded(data));
  } catch (error: any) {
    yield* put(payoutsFetchFailed(error.message));
  }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/
export function* watchPayouts() {
  yield* takeLatest(payoutsFetchRequest, fetchPayouts);
}
