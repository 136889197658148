import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { Circle, Flex, Icon, Text, Tooltip, Wrap, WrapItem } from '@chakra-ui/react';
import { LoadingSpinner } from '@components/loadingSpinner/LoadingSpinner';
import { useDecision } from '@optimizely/react-sdk';
import { displayDate } from '@utils/dates';
import { format } from 'date-fns';

import { Status, Verticals } from '../../common/interfaces';
import { selectActiveVertical } from '../auth/userSlice';
import {
  selectCalculationDates,
  selectEndDate,
  selectHeaderStatus,
  selectStartDate,
  setBehaviorWalkthrough,
  setDateRange,
} from '../dashboard/slice';

import { RangeDatePicker } from './components/datePicker';
import { LocationsSelector } from './components/locationsFilter';
import { NetworkSelector } from './components/networkSelector';
import { TransactionTypeSelector } from './components/transactionTypeSelector';

export const Filter = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { minDate, maxDate } = useAppSelector(selectCalculationDates);
  const headerStatus = useAppSelector(selectHeaderStatus);
  const activeVertical = useAppSelector(selectActiveVertical);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);

  const [behaviorFlag] = useDecision('md-640', { autoUpdate: true });

  const datePickerMinDate = minDate ? new Date(`${minDate}T00:00:00`) : undefined;
  const datePickerMaxDate = maxDate ? new Date(`${maxDate}T00:00:00`) : undefined;
  const maxDateText = maxDate ? displayDate(`${maxDate}T00:00:00`) : '';
  const isCustomerDeepDive = location.pathname.includes('customerDeepDive');
  const path = t(location.pathname.split('/')[isCustomerDeepDive ? 2 : location.pathname.split('/').length - 1]);

  let selectedDates: Date[] = [];
  if (startDate && endDate) {
    // It is important to set time as 00:00:00 for the new date
    // to be created at the start of the day in a current time zone.
    selectedDates = [new Date(`${startDate}T00:00:00`), new Date(`${endDate}T00:00:00`)];
  }

  const handleHistoryDropdownChange = (dates: Date[]) => {
    const [start, end] = dates;
    // Formatting dates is required because splitting ISO string may
    // yield wrong day because of timezone difference.
    const startDate = format(start, 'yyyy-MM-dd');
    const endDate = format(end, 'yyyy-MM-dd');
    dispatch(setDateRange({ startDate, endDate }));
  };

  const clickHandler = () => {
    localStorage.setItem('behaviorWalkthrough', JSON.stringify({ initiated: true, step: 1 }));
    dispatch(setBehaviorWalkthrough({ initiated: true, step: 1 }));
  };

  const shouldRenderFilters =
    /invoices/.test(location.pathname) ||
    /payouts/.test(location.pathname) ||
    /statements/.test(location.pathname) ||
    /profile/.test(location.pathname) ||
    /profiles/.test(location.pathname);

  const shouldDisableSelector =
    /behavior/.test(location.pathname) || /customerDeepDive\/customer/.test(location.pathname);

  return (
    <>
      {headerStatus === Status.Loading ? (
        <LoadingSpinner />
      ) : (
        !shouldRenderFilters && (
          <Flex>
            <Wrap align='flex-start' justify='flex-end' flexDirection='row'>
              {activeVertical === Verticals.Grocery && (
                <WrapItem gap={2} padding={2}>
                  <NetworkSelector />
                </WrapItem>
              )}
              {(activeVertical === Verticals.Restaurant || activeVertical === Verticals.Fuel) && (
                <WrapItem gap={2} padding={2}>
                  <LocationsSelector />
                </WrapItem>
              )}
              {(activeVertical === Verticals.Restaurant || activeVertical === Verticals.Fuel) && (
                <WrapItem gap={2} padding={2}>
                  <TransactionTypeSelector />
                </WrapItem>
              )}
              <WrapItem flexDirection='column' gap={2} padding={2} alignItems='flex-end'>
                <Flex>
                  <Flex direction='column'>
                    <RangeDatePicker
                      selectedRange={selectedDates}
                      onDateChange={handleHistoryDropdownChange}
                      minDate={datePickerMinDate}
                      maxDate={datePickerMaxDate}
                      shouldDisableSelector={shouldDisableSelector}
                      minDaysNumber={6}
                      configs={{
                        tooltipsTexts: {
                          lessThanMin: t('components.dateRangePicker.priorToLaunch'),
                          moreThanMax: t('components.dateRangePicker.dataNotAvailable'),
                          minDaysNumber: t('components.dateRangePicker.minimumDaysLabel', { count: 7 }),
                        },
                      }}
                    />
                    {maxDate && (
                      <Text
                        data-testid='last-updated-date'
                        color='gray1'
                        textDecoration='underline dashed'
                        textDecorationColor='gray1'
                        textUnderlineOffset='4px'
                      >
                        {t('components.filter.dataLastUpdated', { maxDateText })}
                      </Text>
                    )}
                  </Flex>

                  {behaviorFlag && path === 'Behavior' && (
                    <Tooltip label={t(`components.filter.walkthroughTooltipText`)}>
                      <Circle
                        onClick={clickHandler}
                        ml={'10px'}
                        size='40px'
                        outlineColor={'blackAlpha.400'}
                        cursor='pointer'
                      >
                        <Icon as={QuestionOutlineIcon} alignSelf={'center'} />
                      </Circle>
                    </Tooltip>
                  )}
                </Flex>
              </WrapItem>
            </Wrap>
          </Flex>
        )
      )}
    </>
  );
};
