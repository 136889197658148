import { useTranslation } from 'react-i18next';
import {
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';

import { useAppSelector } from '@/app/hooks';
import { Verticals } from '@/common/interfaces';
import { selectActiveVertical } from '@/features/auth/userSlice';

export const BehaviorInfoLink = ({ ...popoverProps }) => {
  const activeVertical = useAppSelector(selectActiveVertical);
  const { t } = useTranslation();

  return (
    <Popover {...popoverProps} data-testid='behaviorInfo'>
      <PopoverTrigger>
        <Link>
          <h3>{t(`behaviorPage.behaviorInfo.anchorText`)}</h3>
        </Link>
      </PopoverTrigger>
      <PopoverContent width={'350px'}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>
          <Text color='text.default-inverse'>{t(`behaviorPage.behaviorInfo.title`)} </Text>
        </PopoverHeader>
        <PopoverBody color='text.default-inverse'>
          <Text color='text.default-inverse'>{t(`behaviorPage.behaviorInfo.body`)}</Text>
          {activeVertical === Verticals.Fuel && (
            <Text>
              <Link
                href='https://fuelknowledgecenter.upside.com/hc/en-us/articles/10715331807003-Intro-to-Upside-s-measurement-methodology'
                isExternal
                color='text.default-inverse'
              >
                {t(`behaviorPage.behaviorInfo.learnMore`)}
              </Link>
            </Text>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
