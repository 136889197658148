import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiDownload } from 'react-icons/fi';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Box, Button, Flex, Heading, Select, useToast } from '@chakra-ui/react';
import { DataLoadingErrorPanel, EmptyDataAlertPanel } from '@components/errorAlert';
import { LoadingSpinner } from '@components/loadingSpinner';
import { Pagination, PaginationContainer, PaginationOptions, PaginationProps } from '@components/pagination';
import { ITableData, Table, TableHeader } from '@components/table';
import { useDecision } from '@optimizely/react-sdk';

import { Card } from '@/common/components/card';
import { CustomLegends } from '@/common/components/customLegends';
import { LocationsMap } from '@/common/components/map';
import { Status, Verticals } from '@/common/interfaces';
import { convertArrayToCSV, downloadCSV } from '@/common/utils';
import i18n from '@/i18n';

import { selectActiveVertical } from '../../auth/userSlice';
import { selectContentStatus, selectIsDemo, selectSelectedTransactionType } from '../../dashboard/slice';

import { CompetitorsHelperText } from './CompetitorsHelperText';
import { competitorsLegend } from './constants';
import {
  selectCompetitors,
  selectSelectedCompetitorTypes,
  selectSiteLevelPerformance,
  setSelectedCompetitorTypes,
} from './slice';
import { MapVisualizations } from './types';

export const Locations = () => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation();

  const [competitorsFlag] = useDecision('md-570', { autoUpdate: true });

  const activeVertical = useAppSelector(selectActiveVertical);
  const isDemo = useAppSelector(selectIsDemo);
  const transactionType = useAppSelector(selectSelectedTransactionType);
  const contentStatus = useAppSelector(selectContentStatus);
  const siteLevelPerformance = useAppSelector(selectSiteLevelPerformance);
  const competitors = useAppSelector(selectCompetitors);
  const selectedCompetitorTypes = useAppSelector(selectSelectedCompetitorTypes);

  const [mapVisualization, setMapVisualization] = useState(MapVisualizations.HeatMap);

  const shouldShowCompetitors =
    competitorsFlag.enabled &&
    activeVertical === Verticals.Fuel &&
    mapVisualization === MapVisualizations.Competitors &&
    !isDemo;

  const paginationOptions: PaginationOptions = {
    pageSize: 25,
    availablePageSizes: [5, 10, 25, 50, 100],
  };

  const handleDownloadCSV = useCallback(() => {
    if (!siteLevelPerformance?.topSitesLocationCSVData) {
      toast({
        title: t('downloadCSV.error') as string,
        status: 'error',
        duration: 5000,
        isClosable: true,
        variant: 'subtle',
      });
      return;
    }

    const data = siteLevelPerformance?.topSitesLocationCSVData;
    const csvString = convertArrayToCSV(data);
    const startDate = data[0][i18n.t('dashboardPage.topPerformingSitesTbl.reportingStart')]
      .toString()
      .replace(/-/g, '');
    const endDate = data[0][i18n.t('dashboardPage.topPerformingSitesTbl.reportingEnd')].toString().replace(/-/g, '');
    const filename = `upside_location_performance_${startDate}_${endDate}.csv`;

    downloadCSV(csvString, filename);

    toast({
      title: t('downloadCSV.success') as string,
      status: 'success',
      variant: 'subtle',
      isClosable: true,
    });
  }, [siteLevelPerformance, toast, t]);

  if (contentStatus === Status.Idle && siteLevelPerformance?.topSitesLocationTable?.rows.length === 0) {
    return <EmptyDataAlertPanel />;
  }

  return (
    <Box padding={0} margin={0}>
      {contentStatus === Status.Loading && (
        <Flex w='100%' h='100px' justifyContent={'center'} alignItems='center'>
          <LoadingSpinner />
        </Flex>
      )}
      {contentStatus === Status.Failed && <DataLoadingErrorPanel />}
      {contentStatus === Status.Idle && (
        <>
          <Card variant='borderless'>
            <Flex alignItems='center' justifyContent='space-between'>
              <Heading width='full' size='lg'>
                {t(
                  `locationsPage.${activeVertical}${
                    competitorsFlag.enabled && activeVertical === Verticals.Fuel ? '.mapTitle_MD-570' : '.mapTitle'
                  }`,
                )}
              </Heading>
              {competitorsFlag.enabled && activeVertical === Verticals.Fuel && (
                <Select
                  maxW='20%'
                  value={mapVisualization}
                  onChange={(e) => setMapVisualization(e.target.value as MapVisualizations)}
                >
                  <option value={MapVisualizations.HeatMap}>Heatmap</option>
                  <option value={MapVisualizations.Competitors}>Competitors</option>
                </Select>
              )}
            </Flex>
            <Box mt={4}>
              {activeVertical && (
                <>
                  <LocationsMap
                    id={'topPerformanceMap'}
                    stats={siteLevelPerformance?.map}
                    vertical={activeVertical}
                    transactionType={transactionType}
                    visualization={mapVisualization}
                    competitors={competitors}
                  />
                  {shouldShowCompetitors && (
                    <Box mt={4}>
                      <CustomLegends
                        options={competitorsLegend}
                        defaultOptions={selectedCompetitorTypes}
                        onChange={(value) => {
                          dispatch(setSelectedCompetitorTypes(value.map((v) => v.toString())));
                        }}
                      />
                      <CompetitorsHelperText />
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Card>

          <Card variant='borderless'>
            <PaginationContainer data={siteLevelPerformance?.topSitesLocationTable.rows} options={paginationOptions}>
              {(props: PaginationProps) => {
                const { data } = props;
                const tableData: ITableData = Object.assign({}, siteLevelPerformance?.topSitesLocationTable, {
                  rows: data,
                });

                return (
                  <>
                    <TableHeader
                      title={`locationsPage.${activeVertical}.tableTitle`}
                      actionItems={() => (
                        <Button onClick={handleDownloadCSV} rounded='full' gap='2' fontWeight='500'>
                          <FiDownload /> {t('common.download')}
                        </Button>
                      )}
                    />
                    <Table id='locationsTbl' data={{ ...tableData }} />
                    <Pagination {...props} />
                  </>
                );
              }}
            </PaginationContainer>
          </Card>
        </>
      )}
    </Box>
  );
};
