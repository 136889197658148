import { useNavigate } from 'react-router-dom';
import { Flex, Td, Text } from '@chakra-ui/react';

type TableCellObj = {
  value: string;
  email: string;
};

type TableCell = {
  data: string | TableCellObj;
  index?: number;
};

export function NotificationsTableCell({ data }: TableCell) {
  const value = typeof data === 'object' ? data.value : data;
  const email = typeof data === 'object' ? data.email : data;
  const navigate = useNavigate();

  return (
    <Td>
      <Flex
        alignItems='center'
        gap={1}
        onClick={() => (value === 'Edit' ? navigate('/settings/notification/manage/' + email) : '')}
        _hover={{
          cursor: value === 'Edit' ? 'pointer' : 'default',
        }}
      >
        <Text>{value}</Text>
      </Flex>
    </Td>
  );
}
