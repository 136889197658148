import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoCard } from 'react-icons/io5';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Checkbox,
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  useToast,
} from '@chakra-ui/react';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { CustomFormLabel } from '@/common/components/form';
import { Section } from '@/common/components/section';
import { selectActiveMerchantUuid, selectAuthRequestPath, selectUserRoles } from '@/features/auth/userSlice';

import { updateBillingMethodRequest } from '../slice';
import { IUpdateBillingMethodDataItem } from '../types';

export const ManageCreditCard = () => {
  const { id: payment_method_id } = useParams<{ id: string }>();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const authRequestPath = useAppSelector(selectAuthRequestPath);
  const roles = useAppSelector(selectUserRoles);
  const merchantUUID = useAppSelector(selectActiveMerchantUuid);
  const billingPaymentMethods = useAppSelector((state) =>
    state.billing.methods.cards.find((item) => item.id === payment_method_id),
  );

  const [formDetails, setFormDetails] = useState({
    nickname: billingPaymentMethods?.metadata.nickname,
    siteId: billingPaymentMethods?.metadata.siteUuid,
    paymentDetails: {
      brand: billingPaymentMethods?.brand,
      complete: true,
      elementType: 'card',
      empty: false,
      error: undefined,
      value: { postalCode: '' },
    },
    default: billingPaymentMethods?.isDefault,
  });

  const [invalid, setInvalid] = useState({
    nickname: false,
    siteId: false,
    paymentDetails: false,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const isCheckbox = name === 'default';
    setFormDetails((prev) => {
      return {
        ...prev,
        [name]: isCheckbox ? !formDetails.default : value,
      };
    });

    setInvalid((prev) => {
      if (value !== '' || value !== null) {
        return {
          ...prev,
          [name]: false,
        };
      } else {
        return prev;
      }
    });
  };

  const onSuccess = useCallback(async () => {
    if (!payment_method_id) {
      toast({
        title: 'No payment method selected',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });

      return;
    }
    const data: IUpdateBillingMethodDataItem = {
      merchant_uuid: merchantUUID,
      nickname: formDetails.nickname as string,
      is_default: formDetails.default as boolean,
      site_uuid: formDetails.siteId as string,
    };
    toast({
      title: 'Credit card updating',
      status: 'info',
      duration: 2000,
      isClosable: true,
    });

    dispatch(updateBillingMethodRequest({ authRequestPath, roles, payment_method_id, data }));

    await new Promise((resolve) => setTimeout(resolve, 1500));

    toast({
      title: 'Credit card updated',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

    navigate('/settings/payments');
  }, [
    payment_method_id,
    merchantUUID,
    formDetails.nickname,
    formDetails.default,
    formDetails.siteId,
    toast,
    dispatch,
    authRequestPath,
    roles,
    navigate,
  ]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    for (const [key, value] of Object.entries(formDetails)) {
      if (value === '') {
        setInvalid((prev) => {
          return {
            ...prev,
            [key]: true,
          };
        });
      }
      if (key === 'paymentDetails' && Object.keys(formDetails.paymentDetails).length === 0) {
        setInvalid((prev) => {
          return {
            ...prev,
            [key]: true,
          };
        });
      }
    }

    onSuccess();
  };

  const formatedMonth =
    billingPaymentMethods && Number(billingPaymentMethods?.expirationMonth) < 10
      ? `0${billingPaymentMethods?.expirationMonth}`
      : billingPaymentMethods?.expirationMonth;

  const formatedYear = `${billingPaymentMethods?.expirationYear}`.slice(2);

  return (
    <>
      <Breadcrumb separator={<ChevronRightIcon color='gray.500' />}>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to='/settings/payments'>
            {t('payment.billingPaymentMethods.addCC.payments')}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink as={Link} isCurrentPage to='#'>
            {t('payment.billingPaymentMethods.addCC.addCC')}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Section name={t('payment.billingPaymentMethods.addCC.title')}>
        <Box mt={6} maxWidth={'600px'}>
          <FormControl as='form' mt={3}>
            <CustomFormLabel htmlFor='nickname' mt={4} pt={3}>
              {t('payment.billingPaymentMethods.addCC.nickname')}
              <Input
                type='text'
                name='nickname'
                onChange={handleChange}
                isInvalid={invalid.nickname}
                value={formDetails.nickname}
              />
            </CustomFormLabel>

            <CustomFormLabel htmlFor='site-id' mt={4} pt={3}>
              {t('payment.billingPaymentMethods.addCC.siteId')}
              <Input type='text' name='siteId' onChange={handleChange} value={formDetails.siteId} />
            </CustomFormLabel>

            <Box mt={4}>
              <CustomFormLabel htmlFor='site-id' mt={4} pt={3}>
                {t('payment.billingPaymentMethods.addCC.paymentDetails')}
                <Box id='paymentDetails'>
                  <InputGroup>
                    <InputLeftElement pointerEvents='none'>
                      <IoCard color={'#8A8A8B'} size={25} />
                    </InputLeftElement>
                    <Input
                      isDisabled={true}
                      value={`****${billingPaymentMethods?.last4}`}
                      isInvalid={invalid.paymentDetails}
                    />
                    <InputRightElement paddingRight={4}>
                      <Text color='text.disabled' fontWeight={'normal'}>{`${formatedMonth}/${formatedYear}`}</Text>
                    </InputRightElement>
                  </InputGroup>
                </Box>
              </CustomFormLabel>
            </Box>

            <Checkbox
              id='default'
              mt={3}
              alignItems={'baseline'}
              data-testid='default'
              name='default'
              onChange={handleChange}
              isChecked={formDetails.default}
              isDisabled={billingPaymentMethods?.isDefault}
            >
              {t('payment.billingPaymentMethods.default')}
            </Checkbox>

            <Flex mt={'24px'}>
              <Button as={Link} to='/settings/payments' variant='accentSecondary' size='md' px={8} mr={'12px'}>
                {t('payment.addPayout.back')}
              </Button>
              <Button type='submit' variant='brandPrimary' size='md' px={8} onClick={handleSubmit}>
                {t('payment.billingPaymentMethods.addCC.save')}
              </Button>
            </Flex>
          </FormControl>
        </Box>
      </Section>
    </>
  );
};
