import { useAppSelector } from '@app/hooks';
import { Box, Flex } from '@chakra-ui/react';
import { Card } from '@components/card';
import { Section } from '@components/section';

import { Status, TransactionTypes } from '@/common/interfaces';
import { selectActiveVertical } from '@/features/auth/userSlice';

import { selectContentStatus, selectPerformanceSummary, selectSelectedTransactionType } from '../../dashboard/slice';
import { IRefinedPerformanceSummary } from '../../dashboard/types';

import { CardViewContents } from './CardsContentGenerator';
import { selectPromotions, selectPromotionsStatus } from './slice';
import { IRefinedPromos } from './types';

export const Promotions = () => {
  const summaryData: IRefinedPerformanceSummary = useAppSelector(selectPerformanceSummary);
  const promoData: IRefinedPromos = useAppSelector(selectPromotions);
  const promotionsStatus = useAppSelector(selectPromotionsStatus);
  const contentStatus = useAppSelector(selectContentStatus);
  const activeVertical = useAppSelector(selectActiveVertical);
  const selectedTransactionType = useAppSelector(selectSelectedTransactionType);

  // Fixes MD-352. Possible candidate for refactoring later down the road
  const promotionStatus = () => {
    if (promotionsStatus === Status.Failed || contentStatus === Status.Failed) {
      return Status.Failed;
    }
    if (promotionsStatus === Status.Idle && contentStatus === Status.Idle) {
      return Status.Idle;
    }
    return Status.Loading;
  };

  const promotionsDistributionTitle =
    selectedTransactionType === TransactionTypes.CStore
      ? `promotionsPage.promotionDistByCustSeg.title.${activeVertical}-c-store`
      : `promotionsPage.promotionDistByCustSeg.title.${activeVertical}`;

  return (
    <>
      <Box maxW='100%'>
        <Flex justifyContent='center' gap={4}>
          <Card>
            <CardViewContents.Promotions
              summaryData={summaryData}
              promoData={promoData}
              loadingStatus={promotionStatus()}
              transactionType={selectedTransactionType}
            />
          </Card>
        </Flex>

        <Section name={promotionsDistributionTitle} description=''>
          <Card>
            <CardViewContents.PromotionDistribution
              data={promoData}
              loadingStatus={promotionsStatus}
              vertical={activeVertical}
              transactionType={selectedTransactionType}
            />
          </Card>
        </Section>
      </Box>
    </>
  );
};
