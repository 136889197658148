import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';
import { Status } from '@/common/interfaces';

import { NotificationPrefsFormattedData } from './types';

export interface NotificationSettingsState {
  notificationSettings: NotificationPrefsFormattedData;
  status: Status.Idle | Status.Loading | Status.Failed;
  error?: string;
}

export const initialState: NotificationSettingsState = {
  notificationSettings: {
    tableData: {
      rows: [],
    },
    rawData: { notificationPreferences: [] },
  },
  status: Status.Idle,
  error: '',
};

export const notificationSlice = createSlice({
  name: 'notificationSettings',
  initialState,
  reducers: {
    notificationSettingsFetchRequest: (state) => {
      state.status = Status.Loading;
      state.error = undefined;
    },
    notificationSettingsFetchSucceeded: (state, action: PayloadAction<NotificationPrefsFormattedData>) => {
      state.notificationSettings = action.payload;
      state.status = Status.Idle;
    },
    notificationSettingsFetchFailed: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.status = Status.Failed;
    },
    updateNotificationSettingsRequest: (state) => {
      state.error = undefined;
      state.status = Status.Loading;
    },
    updateNotificationSettingsSucceeded: (state) => {
      state.error = undefined;
    },
    updateNotificationSettingsFailed: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.status = Status.Failed;
    },
  },
});

export const {
  notificationSettingsFetchRequest,
  notificationSettingsFetchSucceeded,
  notificationSettingsFetchFailed,
  updateNotificationSettingsRequest,
  updateNotificationSettingsSucceeded,
  updateNotificationSettingsFailed,
} = notificationSlice.actions;

export const selectNotificationSettingsData = (state: RootState) => state.notification;
export const selectNotificationSettingsStatus = (state: RootState) => state.notification.status;

export default notificationSlice.reducer;
