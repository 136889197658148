/* eslint-disable @typescript-eslint/no-explicit-any */

import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Checkbox,
  Flex,
  FormControl,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { CustomFormLabel } from '@/common/components/form';
import { Section } from '@/common/components/section';
import { selectActiveMerchantUuid, selectAuthRequestPath, selectUserRoles } from '@/features/auth/userSlice';

import { addNewBillingMethodsRequest } from '../slice';
import { IAddBillingMethodDataItem } from '../types';

import { CardSection } from './CardSection';

export const AddCreditCard = () => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const dispatch = useAppDispatch();
  const elements = useElements();
  const toast = useToast();

  const roles = useAppSelector(selectUserRoles);
  const merchantUUID = useAppSelector(selectActiveMerchantUuid);
  const authRequestPath = useAppSelector(selectAuthRequestPath);

  const [formDetails, setFormDetails] = useState({
    nickname: '',
    siteId: '',
    paymentDetails: {},
    default: false,
  });
  const [invalid, setInvalid] = useState({
    nickname: false,
    siteId: false,
    paymentDetails: false,
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const isCheckbox = name === 'default';
    setFormDetails((prev) => {
      return {
        ...prev,
        [name]: isCheckbox ? !formDetails.default : value,
      };
    });

    setInvalid((prev) => {
      if (value !== '' || value !== null) {
        return {
          ...prev,
          [name]: false,
        };
      } else {
        return prev;
      }
    });
  };

  const onSuccess = useCallback(async () => {
    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);
    if (!card) return;
    const token = await stripe.createToken(card);

    if (token.error) {
      console.error(token.error.message);

      toast({
        title: 'Error adding billing method',
        description: token.error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      return;
    } else {
      if (formDetails.nickname !== '' && !token.error) {
        toast({
          title: 'Saving billing method...',
          status: 'info',
          duration: 2000,
          isClosable: true,
        });
      }
    }
    const data: IAddBillingMethodDataItem = {
      merchant_uuid: merchantUUID,
      is_default: formDetails.default,
      nickname: formDetails.nickname,
      plaid_link_account_id: '',
      plaid_link_public_token: '',
      source_token: token.token?.id,
      site_uuid: formDetails.siteId,
    };

    const isBank = false;
    dispatch(addNewBillingMethodsRequest({ authRequestPath, roles, data, isBank }));
  }, [
    authRequestPath,
    dispatch,
    elements,
    formDetails.default,
    formDetails.nickname,
    formDetails.siteId,
    merchantUUID,
    roles,
    stripe,
    toast,
  ]);

  const handleSubmit = async (e: any) => {
    for (const [key, value] of Object.entries(formDetails)) {
      if (value === '') {
        setInvalid((prev) => {
          return {
            ...prev,
            [key]: true,
          };
        });
      }
      if (key === 'paymentDetails' && Object.keys(formDetails.paymentDetails).length === 0) {
        setInvalid((prev) => {
          return {
            ...prev,
            [key]: true,
          };
        });
      }
    }

    e.preventDefault();

    onSuccess();
  };

  return (
    <>
      <Breadcrumb separator={<ChevronRightIcon color='gray.500' />}>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to='/settings/payments'>
            {t('payment.billingPaymentMethods.addCC.payments')}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink as={Link} isCurrentPage to='#'>
            {t('payment.billingPaymentMethods.addCC.addCC')}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Section name={t('payment.billingPaymentMethods.addCC.title')}>
        <Box mt={6} maxWidth={'600px'}>
          <FormControl as='form' mt={3}>
            <CustomFormLabel htmlFor='nickname' mt={4} pt={3}>
              {t('payment.billingPaymentMethods.addCC.nickname')}
              <Input type='text' name='nickname' onChange={handleChange} isInvalid={invalid.nickname} />
            </CustomFormLabel>
            {invalid.nickname && (
              <Text textStyle='body.xs' color='text.danger.default'>
                {t('payment.billingPaymentMethods.addCC.required')}
              </Text>
            )}

            <CustomFormLabel htmlFor='site-id' mt={4} pt={3}>
              {t('payment.billingPaymentMethods.addCC.siteId')}
              <Input type='text' name='siteId' onChange={handleChange} />
            </CustomFormLabel>

            <Box mt={4}>
              <CustomFormLabel htmlFor='site-id' mt={4} pt={3}>
                {t('payment.billingPaymentMethods.addCC.paymentDetails')}
                <Box id='paymentDetails'>
                  <CardSection invalid={invalid} setInvalid={setInvalid} setFormDetails={setFormDetails} />
                </Box>
              </CustomFormLabel>
              {invalid.paymentDetails && (
                <Text textStyle='body.xs' color='text.danger.default'>
                  {t('payment.billingPaymentMethods.addCC.required')}
                </Text>
              )}
            </Box>

            <Checkbox
              id='default'
              mt={3}
              alignItems={'baseline'}
              data-testid='default'
              name='default'
              onChange={handleChange}
            >
              {t('payment.billingPaymentMethods.default')}
            </Checkbox>

            <Flex mt={'24px'}>
              <Button as={Link} to='/settings/payments' variant='accentSecondary' size='md' px={8} mr={'12px'}>
                {t('payment.addPayout.back')}
              </Button>
              <Button variant='brandPrimary' size='md' px={8} onClick={handleSubmit} data-testid='save-credit-card'>
                {t('payment.billingPaymentMethods.addCC.save')}
              </Button>
            </Flex>
          </FormControl>
        </Box>
      </Section>
    </>
  );
};
