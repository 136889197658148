import { IStat } from '@components/stat';
import { Serie } from '@nivo/line';

import { Status, TransactionTypes, Verticals } from '@/common/interfaces';

import { IBarGraph, IRefinedPerformanceSummary } from '../../dashboard/types';

export enum CustomerSegments {
  All = 'All',
  New = 'New',
  Infrequent = 'Infrequent',
  Occasional = 'Occasional',
  Regular = 'Regular',
  Margin = 'Margin',
  Undetermined = 'Unsegmented',
}

export interface IRefinedPromos {
  promoAvgAcceptedLineGraph?: Array<Serie>;
  promoDistributionStats?: Array<IStat>;
  promoDistBarGraph?: Array<IBarGraph>;
}

export interface IPromosRawData {
  promo_stats?: {
    new_customers: number;
    infrequent_customers: number;
    occasional_customers: number;
    regular_customers: number;
  };
  promo_dist_bar_graph?: {
    Total: Array<IPromoDatapoint>;
    New: Array<IPromoDatapoint>;
    Infrequent: Array<IPromoDatapoint>;
    Occasional: Array<IPromoDatapoint>;
    Regular: Array<IPromoDatapoint>;
    Undetermined?: Array<IPromoDatapoint>;
  };
  min_max_offers?: {
    min_offer: number;
    max_offer: number;
  };
  promo_avg_accepted_line_graph?: {
    Margin?: Array<IWeekOf>;
    Undetermined?: Array<IWeekOf>;
    Total: Array<IWeekOf>;
    New: Array<IWeekOf>;
    Infrequent: Array<IWeekOf>;
    Occasional: Array<IWeekOf>;
    Regular: Array<IWeekOf>;
  };
}

export interface IWeekOf {
  period_start: string;
  value: string | number;
}

export interface IPromoDatapoint {
  amount_spent: number;
  cash_back_to_user: number;
  num_offers: number;
  offer_percent: number;
  total_incremental_profit: number;
  total_profit: number;
  total_gallons_bought?: number;
  total_revenue: number;
  total_promotion_cost: number;
  upside_fee_cost: number;
}

export type IPromoDatapointBar = {
  label: string;
  range: number[];
  segments: {
    [key: string]: {
      amountSpent: number;
      cashBack: number;
      numOffers: number;
      totalGallonsBought: number;
      totalIncrementalProfit: number;
      totalProfit: number;
      offerPercent: number;
      totalRevenue: number;
      totalPromotionCost: number;
      totalUpsideFeeCost: number;
    };
  };
};

export interface IAverageAcceptedPromoDistributions {
  average_accepted_offer: string | number;
  average_margin: string | number;
  period_start: string | number;
}

export interface IPromoDistribution {
  average_gross_margin: string | number;
  average_accepted_offer: string | number;
  avg_accepted_offers_over_time: Array<IAverageAcceptedPromoDistributions>;
  upside_funded_boosts: string | number;
}

export type TCardContentPromotionsProps = {
  summaryData: IRefinedPerformanceSummary;
  promoData: IRefinedPromos;
  loadingStatus: Status;
  transactionType: TransactionTypes;
};

export type TCardContentDistributionsProps = {
  data: IRefinedPromos;
  loadingStatus: Status;
  vertical: Verticals;
  transactionType: string;
};

export type TTotalsPromotionsProps = {
  Total: TTooltipTotalsPromotionsProps;
  New: TTooltipTotalsPromotionsProps;
  Infrequent: TTooltipTotalsPromotionsProps;
  Occasional: TTooltipTotalsPromotionsProps;
  Regular: TTooltipTotalsPromotionsProps;
};

export type TTooltipTotalsPromotionsProps = {
  numOffers: number;
  cashBack: number;
  totalIncrementalProfit: number;
  totalProfit: number;
  totalGallonsBought: number;
  totalRevenue: number;
  offerPercent: number;
  totalUpsideFeeCost: number;
};

export type TTooltipData = {
  [key: string]: TTotalsPromotionsProps;
};

export type PromotionTopMetrics = {
  avgPromo: string;
  avgMargin: string;
  upsideFundedBoost: string;
};
