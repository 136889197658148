import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '@/app/hooks';
import { IStat } from '@/common/components/stat';
import { Stats } from '@/common/components/stats';
import { Verticals } from '@/common/interfaces';
import { display2Decimals } from '@/common/utils';
import { selectActiveVertical } from '@/features/auth/userSlice';
import { selectPerformanceSummary } from '@/features/dashboard/slice';
import { IRefinedPerformanceSummary } from '@/features/dashboard/types';

import { selectBehavior } from './slice';

export const ControlChartIncrementalityStats = () => {
  const { t } = useTranslation();

  const { prePostChart } = useAppSelector(selectBehavior);
  const summaryGraphData: IRefinedPerformanceSummary = useAppSelector(selectPerformanceSummary);
  const activeVertical = useAppSelector(selectActiveVertical);

  const stats = useMemo(() => {
    if (!prePostChart) {
      return [];
    }

    if (activeVertical === Verticals.Fuel) {
      const statsData: Array<IStat> = [
        {
          label: t('behaviorPage.stats.fuel.incrementalGallons'),
          value: summaryGraphData.profitabilityWalkthroughData?.totalIncrementalGallons,
          help: t('behaviorPage.stats.fuel.incrementalTooltip'),
        },
        {
          label: t('behaviorPage.stats.fuel.purchaseFrequency'),
          value: `${display2Decimals(prePostChart.metrics.sales_increase_ratio, 1)}x`,
          help: t('behaviorPage.stats.fuel.purchaseTooltip'),
        },
      ];

      return statsData;
    }

    const incrementalSales = summaryGraphData.homeMetricsData?.incrementalSales.value;

    const statsData: Array<IStat> = [
      {
        label: t('behaviorPage.stats.mv.incrementalGallons'),
        value: incrementalSales,
        help: t('behaviorPage.stats.mv.incrementalTooltip'),
      },
      {
        label: t('behaviorPage.stats.mv.purchaseFrequency'),
        value: `${display2Decimals(prePostChart.metrics.sales_increase_ratio, 1)}x`,
        help: t('behaviorPage.stats.mv.purchaseTooltip'),
      },
    ];

    return statsData;
  }, [
    t,
    prePostChart,
    activeVertical,
    summaryGraphData.homeMetricsData?.incrementalSales.value,
    summaryGraphData.profitabilityWalkthroughData?.totalIncrementalGallons,
  ]);

  return <Stats stats={stats} />;
};
