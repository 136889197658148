import { Box } from '@chakra-ui/react';

import { AddPayoutAccount } from './components/AddPayoutAccount';
// import { DelayedVerificationInfo } from './components/DelayedVerificationInfo';
// import { PayoutPeriodSettings } from './components/PayoutPeriodSettings';

export const AddPayout = () => {
  return (
    <Box ml={3} maxWidth={'600px'}>
      <AddPayoutAccount />
      {/* {page === 'periodSettings' && <PayoutPeriodSettings value={value} setPage={setPage} />} */}
      {/* {page === 'delayedVerificationInfo' && <DelayedVerificationInfo />} */}
    </Box>
  );
};
