import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Box } from '@chakra-ui/react';
import { EmptyDataAlert } from '@components/errorAlert';
import { Pagination } from '@components/pagination';
import { AsyncPaginationContainer } from '@components/pagination/AsyncPaginationContainer';
import { PaginationProps } from '@components/pagination/types';
import { ITableData, Table } from '@components/table';
import _ from 'lodash';

import { ITableCellValue } from '@/common/components/table/Table';
import { Status } from '@/common/interfaces';

import { selectActiveVertical, selectAuthRequestPath, selectUserOrg, selectUserRoles } from '../../auth/userSlice';
import {
  selectEndDate,
  selectSelectedLocations,
  selectSelectedNetworks,
  selectSelectedTransactionType,
  selectStartDate,
} from '../../dashboard/slice';
import {
  recentTransactionsListFetch,
  selectRecentTransactionsList,
  selectRecentTransactionsListStatus,
  setRecentTransactionsListPage,
  setRecentTransactionsListPaginationOptions,
} from '../../explore/customerDeepDive/slice';

export const RecentTransactionsTable = () => {
  const activeVertical = useAppSelector(selectActiveVertical);
  const authRequestPath = useAppSelector(selectAuthRequestPath);
  const status = useAppSelector(selectRecentTransactionsListStatus);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const orgId = useAppSelector(selectUserOrg);
  const roles = useAppSelector(selectUserRoles);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const selectedNetworks = useAppSelector(selectSelectedNetworks);
  const recentTransactionList = useAppSelector(selectRecentTransactionsList);
  const selectedTransactionType = useAppSelector(selectSelectedTransactionType);
  const selectedLocations = useAppSelector(selectSelectedLocations);

  const fetchRecentTransactions = useCallback(
    ({
      startDate,
      endDate,
      page = recentTransactionList.page,
      pageSize = recentTransactionList.paginationOptions.pageSize,
      networkNames,
      transactionType,
      locations,
    }: {
      startDate: string;
      endDate: string;
      page?: number;
      pageSize?: number;
      networkNames: string[];
      transactionType: string;
      locations: string[];
    }) => {
      if (
        !_.isEmpty(startDate) &&
        !_.isEmpty(endDate) &&
        !_.isEmpty(orgId) &&
        !_.isEmpty(activeVertical) &&
        !_.isEmpty(roles)
      ) {
        const payload = {
          startDate,
          endDate,
          page,
          pageSize,
          query: '',
          authRequestPath,
          networkNames,
          activeVertical,
          transactionType,
          locations,
        };
        dispatch(recentTransactionsListFetch(payload));
      }
    },
    [orgId, activeVertical, roles, authRequestPath, dispatch],
  );

  useEffect(() => {
    if (orgId !== '' && !_.isEmpty(roles) && !_.isEmpty(activeVertical) && startDate && endDate) {
      fetchRecentTransactions({
        startDate: startDate,
        endDate: endDate,
        page: recentTransactionList.page,
        pageSize: recentTransactionList.paginationOptions.pageSize,
        networkNames: selectedNetworks,
        transactionType: selectedTransactionType,
        locations: selectedLocations,
      });
    }
  }, [
    activeVertical,
    endDate,
    fetchRecentTransactions,
    orgId,
    recentTransactionList.page,
    recentTransactionList.paginationOptions.pageSize,
    selectedNetworks,
    selectedLocations,
    selectedTransactionType,
    roles,
    startDate,
  ]);

  const isEmpty = status === Status.Idle && recentTransactionList.transactions.table?.rows.length === 0;

  function handlePageChange(page: number): void {
    dispatch(setRecentTransactionsListPage(page));
  }

  function handlePageSizeChange(pageSize: number): void {
    dispatch(
      setRecentTransactionsListPaginationOptions(
        Object.assign({}, recentTransactionList.paginationOptions, { pageSize: pageSize }),
      ),
    );
  }

  const handleClick = (row: Array<string | number | ITableCellValue>) => {
    if (typeof row[2] === 'object' && row[2].rawValue) {
      navigate(`/explore/customerDeepDive/customer?userUuid=${row[2].rawValue}`);
    }
  };

  return (
    <Box w={'100%'}>
      {isEmpty ? (
        <EmptyDataAlert />
      ) : (
        <Box overflowY='auto'>
          <AsyncPaginationContainer
            data={recentTransactionList.transactions.table?.rows || []}
            page={recentTransactionList.page}
            total={recentTransactionList.transactions?.count}
            prevPage={recentTransactionList.transactions?.prevPage}
            nextPage={recentTransactionList.transactions?.nextPage}
            isLoading={status === Status.Loading}
            options={recentTransactionList.paginationOptions}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          >
            {(props: PaginationProps) => {
              const { data } = props;
              const tableData: ITableData = Object.assign({}, recentTransactionList.transactions?.table, {
                rows: data,
              });

              let content = <Box height='400px' />;
              if (data.length > 0) {
                content = (
                  <>
                    <Table data={tableData} onClick={handleClick} />
                    <Pagination {...props} />
                  </>
                );
              }

              return content;
            }}
          </AsyncPaginationContainer>
        </Box>
      )}
    </Box>
  );
};
