import React, { useEffect, useState } from 'react';
import FocusLock from 'react-focus-lock';
import { FiCalendar } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  useBreakpointValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';

import { displayDate, displayDayOfMonth, isSameMonth } from '@/common/utils/dates';

import { DateRangePresetMenu } from './components/DateRangePresetMenu';
import { RangeCalendarPanel } from './components/RangeCalendarPanel';
import { buildPresets } from './utils/utils';
import { CalendarConfigs, DatePickerConfigs, DatePickerProps, OnDateSelected } from './types';
/**
 * Render human readable date range.
 *
 * @param dates Exactly two dates
 * @returns String that represents a date range
 */
function displayRange(dates: Date[]): string {
  if (dates.length !== 2) {
    return 'Select a range';
  }

  const start =
    dates[0] && dates[1] && isSameMonth(dates[0], dates[1])
      ? displayDayOfMonth(dates[0].toISOString())
      : displayDate(dates[0].toISOString());
  const end = dates[1] && displayDate(dates[1].toISOString());
  return `${start} - ${end}`;
}

export interface RangeDatePickerProps extends DatePickerProps {
  selectedRange: Date[];
  configs?: DatePickerConfigs;
  minDaysNumber?: number;
  disabled?: boolean;
  defaultIsOpen?: boolean;
  shouldDisableSelector?: boolean;
  onDateChange: (date: Date[]) => void;
  id?: string;
  name?: string;
  usePortal?: boolean;
}

const DefaultConfigs: CalendarConfigs = {
  firstDayOfWeek: 0,
  tooltipsTexts: { moreThanMax: '', lessThanMin: '', minDaysNumber: '' },
};

export const RangeDatePicker: React.FC<RangeDatePickerProps> = ({
  configs,
  propsConfigs = {},
  usePortal,
  defaultIsOpen = false,
  shouldDisableSelector = false,
  ...props
}) => {
  const { selectedRange, minDate, maxDate, minDaysNumber, onDateChange } = props;

  const [selectedDates, setSelectedDates] = useState<Date[]>([new Date(), new Date()]);
  const [dateInView, setDateInView] = useState(new Date());
  const [offset, setOffset] = useState(0);
  const { onOpen, onClose, onToggle, isOpen } = useDisclosure({ defaultIsOpen });
  const location = useLocation();

  const calendarConfigs: CalendarConfigs = {
    ...DefaultConfigs,
    ...configs,
    ...{ minDaysNumber },
  };

  useEffect(() => {
    setSelectedDates(selectedRange);
  }, [selectedRange]);

  const handleDateChange = (dates: Date[]) => {
    setSelectedDates(dates);
    if (dates.length === 2) {
      onDateChange(dates);
    }
  };

  const handleOnDateSelected: OnDateSelected = ({ selectable, date }) => {
    if (!selectable) {
      return;
    }

    if (selectedDates.length === 1 && selectedDates.some((d) => d.toISOString() === date.toISOString())) {
      return;
    }

    const newDates = [...selectedDates];
    if (selectedDates.length) {
      if (selectedDates.length === 1) {
        const firstTime = selectedDates[0];
        if (firstTime < date) {
          newDates.push(date);
        } else {
          newDates.unshift(date);
        }
        handleDateChange(newDates);
      } else if (newDates.length === 2) {
        handleDateChange([date]);
      }
    } else {
      newDates.push(date);
      handleDateChange(newDates);
    }
  };

  const onPopoverClose = () => {
    onClose();
    setDateInView(selectedDates[0] || new Date());
    setOffset(0);
  };

  const PopoverContentWrapper = usePortal ? Portal : React.Fragment;

  const [selectedPreset, setSelectedPreset] = useState<string | undefined>('last_6_months');
  const presets = buildPresets();

  const variant = useBreakpointValue({
    base: 0,
    lg: 1,
  });
  const monthsToDisplay = variant && variant > 0 ? 2 : 1;

  const invoicesPath =
    /invoices/.test(location.pathname) || /payouts/.test(location.pathname) || /statements/.test(location.pathname);

  return (
    <Popover
      placement='bottom-start'
      variant='responsive'
      isOpen={shouldDisableSelector ? false : isOpen}
      onOpen={onOpen}
      onClose={onPopoverClose}
      isLazy
    >
      <PopoverTrigger>
        <Button
          onClick={onToggle}
          variant='accentSecondary'
          borderColor='neutral.400'
          borderRadius='lg'
          data-testid='date-selector'
          aria-disabled={shouldDisableSelector}
          size='md'
          _disabled={{ cursor: 'not-allowed', opacity: 0.4 }}
        >
          <Icon as={FiCalendar} color='text.default' />
          {!invoicesPath && (
            <Text data-testid='date-range' ml='10px' mr='10px' color='text.default' fontWeight={400} lineHeight={0}>
              {displayRange(selectedRange)}
            </Text>
          )}
          {invoicesPath && (
            <Text data-testid='date-range' ml='10px' mr='10px' color='text.default' fontWeight={400} lineHeight={0}>
              {presets.invoices.find((preset) => preset.id === selectedPreset)?.label}
            </Text>
          )}
          {isOpen ? (
            <Icon as={ChevronUpIcon} color='icon.default' fontWeight={500} />
          ) : (
            <Icon as={ChevronDownIcon} color='icon.default' fontWeight={500} />
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContentWrapper>
        <PopoverContent
          width='100%'
          boxShadow='base'
          borderRadius='xl'
          bg='background.default'
          {...propsConfigs?.popoverCompProps?.popoverContentProps}
        >
          <PopoverBody padding='8px' {...propsConfigs.popoverCompProps?.popoverBodyProps}>
            <FocusLock>
              <Flex flexDirection='row'>
                <VStack>
                  <DateRangePresetMenu
                    maxDate={maxDate}
                    presets={invoicesPath ? presets.invoices : presets.default}
                    selectedPresetId={selectedPreset}
                    wide={selectedPreset !== 'custom'}
                    onPresetSelect={(id: string, start: Date, end: Date, isCustom: boolean) => {
                      if (start && end) {
                        handleDateChange([start, end]);
                      }
                      setSelectedPreset(id);
                      if (isCustom) {
                        setDateInView(new Date());
                      } else {
                        onPopoverClose();
                      }
                    }}
                  ></DateRangePresetMenu>
                </VStack>
                {!invoicesPath && selectedPreset === 'custom' ? (
                  <Box flex={1}>
                    <RangeCalendarPanel
                      dayzedHookProps={{
                        onDateSelected: handleOnDateSelected,
                        selected: selectedDates,
                        monthsToDisplay,
                        date: dateInView,
                        minDate,
                        maxDate,
                        offset,
                        onOffsetChanged: setOffset,
                        firstDayOfWeek: calendarConfigs.firstDayOfWeek,
                      }}
                      configs={calendarConfigs}
                      propsConfigs={propsConfigs}
                      selected={selectedDates}
                    />
                  </Box>
                ) : null}
              </Flex>
            </FocusLock>
          </PopoverBody>
        </PopoverContent>
      </PopoverContentWrapper>
    </Popover>
  );
};
