import { useTranslation } from 'react-i18next';
import { Spinner, Stack, Text } from '@chakra-ui/react';

export const LoadingSpinner = () => {
  const { t } = useTranslation();
  return (
    <Stack data-testid='loading-spinner' align='center' direction='row' spacing='3'>
      <Text textStyle='body.md'>{t('components.loadingSpinner.loading')}</Text>
      <Spinner color='blue.400' size='md' thickness='2px' />
    </Stack>
  );
};
