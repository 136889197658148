import { Trans, useTranslation } from 'react-i18next';
import { useAppSelector } from '@app/hooks';
import { Heading, Text } from '@chakra-ui/react';
import { BannerStat } from '@components/bannerStat';

import { TransactionTypes, Verticals } from '@/common/interfaces';
import { selectActiveVertical } from '@/features/auth/userSlice';
import { selectSelectedTransactionType } from '@/features/dashboard/slice';

export const TotalUpsideRevenueView = ({
  totalUpsideRevenue,
  totalGallons,
  totalTransactions,
  totalCustomers,
}: {
  totalUpsideRevenue: string;
  totalGallons: string;
  totalTransactions: string;
  totalCustomers: string;
}) => {
  const { t } = useTranslation();
  const activeVertical = useAppSelector(selectActiveVertical);
  const selectedTransactionType = useAppSelector(selectSelectedTransactionType);
  const isFuelVerticalButCSToreData =
    activeVertical === Verticals.Fuel && selectedTransactionType === TransactionTypes.CStore;

  return (
    <>
      <Heading size='md' mt={6}>
        {activeVertical === Verticals.Fuel
          ? t(`profitabilityPage.salesOnUpsideHeader.${activeVertical}.${selectedTransactionType}`)
          : t(`profitabilityPage.salesOnUpsideHeader.${activeVertical}`)}
      </Heading>

      {activeVertical === Verticals.Fuel ? (
        selectedTransactionType === TransactionTypes.CStore ? (
          <BannerStat
            label={t(`profitabilityPage.statLabel.${activeVertical}.${selectedTransactionType}`)}
            value={totalUpsideRevenue}
          />
        ) : (
          <BannerStat
            label={t(`profitabilityPage.statLabel.${activeVertical}.${selectedTransactionType}`)}
            value={totalGallons}
          />
        )
      ) : (
        <BannerStat label={t(`profitabilityPage.statLabel.${activeVertical}`)} value={totalUpsideRevenue} />
      )}

      {isFuelVerticalButCSToreData ? (
        <Text fontSize='16px' fontWeight={500}>
          <Trans
            i18nKey={`profitabilityPage.totalUpsideRevenueText1.c-store`}
            values={{ totalUpsideRevenue, totalGallons }}
          />
        </Text>
      ) : (
        <Text fontSize='16px' fontWeight={500}>
          <Trans
            i18nKey={`profitabilityPage.totalUpsideRevenueText1.${activeVertical}`}
            values={{ totalUpsideRevenue, totalGallons }}
          />
        </Text>
      )}
      {activeVertical === Verticals.Restaurant && (
        <Text fontSize='16px' fontWeight={500}>
          <Trans
            i18nKey={`profitabilityPage.totalUpsideRevenueText2.${activeVertical}`}
            values={{ totalTransactions, totalCustomers }}
          ></Trans>
        </Text>
      )}
      {/* <ExpansionPanel
        header={
          <Trans i18nKey='profitabilityPage.totalUpsideRevenueFaq1'>
            How does Upside know that {{ totalUpsideRevenue }} in revenue came from Upside offers?
          </Trans>
        }
      >
        <Trans i18nKey='profitabilityPage.totalUpsideRevenueFaqAnswer1'>
          When a user claims an Upside offer and completes their transition, we make sure there is a matching
          transaction with the user’s card around the same time with your data. Once we verify that the transaction is
          real, we issue cash back to the user and count this transaction as part of
          <Text as='span' color={'background.primary.active'} fontWeight='500'>
            Total Upside Revenue
          </Text>
        </Trans>
      </ExpansionPanel> */}
    </>
  );
};
