import { Trans, useTranslation } from 'react-i18next';
import { useAppSelector } from '@app/hooks';
import { Heading, Text } from '@chakra-ui/react';
import { BannerStat } from '@components/bannerStat';

import { Verticals } from '@/common/interfaces';
import { selectActiveVertical } from '@/features/auth/userSlice';
import { selectSelectedTransactionType } from '@/features/dashboard/slice';

export const IncrementalNetProfitView = ({
  incrementalGrossProfit,
  cashBackProvidedToCustomers,
  upsideFundedCustomerPromotions,
  upsideProfitShare,
  incrementalNetProfitFromUpside,
}: {
  incrementalGrossProfit: string;
  cashBackProvidedToCustomers: string;
  upsideFundedCustomerPromotions: string;
  upsideProfitShare: string;
  incrementalNetProfitFromUpside: string;
}) => {
  const { t } = useTranslation();
  const activeVertical = useAppSelector(selectActiveVertical);
  const selectedTransactionType = useAppSelector(selectSelectedTransactionType);

  return (
    <>
      <Heading size='md' mt={6}>
        <Trans i18nKey='profitabilityPage.incNetProfitHeader'>Incremental net profit</Trans>
      </Heading>
      <BannerStat label={t('profitabilityPage.incNetProfitStatLabel')} value={incrementalNetProfitFromUpside} />
      <Text>
        {activeVertical === Verticals.Fuel ? (
          t(`profitabilityPage.incNetProfitText1.${activeVertical}.${selectedTransactionType}`)
        ) : (
          <Trans i18nKey={`profitabilityPage.incNetProfitText1.${activeVertical}`} components={{ bold: <strong /> }} />
        )}
      </Text>
      <Text>
        {activeVertical === Verticals.Fuel ? (
          <Trans
            i18nKey={`profitabilityPage.incNetProfitText2.${activeVertical}.${selectedTransactionType}`}
            components={{ bold: <strong /> }}
            values={{ incrementalGrossProfit, cashBackProvidedToCustomers, upsideFundedCustomerPromotions }}
          />
        ) : (
          <Trans
            i18nKey={`profitabilityPage.incNetProfitText2.${activeVertical}`}
            components={{ bold: <strong /> }}
            values={{
              incrementalGrossProfit,
              cashBackProvidedToCustomers,
              upsideFundedCustomerPromotions,
              upsideProfitShare,
            }}
          />
        )}
      </Text>
      <Text>
        {activeVertical === Verticals.Fuel ? (
          <Trans
            i18nKey={`profitabilityPage.incNetProfitText3.${activeVertical}.${selectedTransactionType}`}
            components={{ bold: <strong /> }}
            values={{ upsideProfitShare }}
          />
        ) : (
          <Trans
            i18nKey={`profitabilityPage.incNetProfitText3.${activeVertical}`}
            components={{ bold: <strong /> }}
            values={{ upsideProfitShare, incrementalNetProfitFromUpside }}
          />
        )}
      </Text>
      <Text>
        {activeVertical === Verticals.Fuel ? (
          <Trans
            i18nKey={`profitabilityPage.incNetProfitText4.${activeVertical}.${selectedTransactionType}`}
            components={{ bold: <strong /> }}
            values={{ incrementalNetProfitFromUpside }}
          />
        ) : (
          <Trans
            i18nKey={`profitabilityPage.incNetProfitText4.${activeVertical}`}
            components={{ bold: <strong /> }}
            values={{ incrementalNetProfitFromUpside }}
          />
        )}
      </Text>
      {/* <ExpansionPanel header={t('profitabilityPage.incNetProfitFaq1')}>
        {t('profitabilityPage.incNetProfitFaqAnswer1')}
      </ExpansionPanel>*/}
    </>
  );
};
