import * as Sentry from '@sentry/react';

import { TransactionTypes, Verticals } from '@/common/interfaces';
import { addSearchParamIfValueNotNull, transactionTypeForApiCall } from '@/common/utils';
import { axiosInstance } from '@/common/utils';
import { ENV_CONFIG } from '@/config';

import { TemporalUnit } from '../../dashboard/slice';

import { mockGraphData } from './mockGraphData';

// A mock function to mimic making an async request for data
export const mockFetchPromotionsData = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return new Promise<{ data: any }>((resolve) => setTimeout(() => resolve({ data: mockGraphData }), 500));
};

export const fetchPromotionsData = async ({
  startDate,
  endDate,
  temporalUnit,
  authRequestPath,
  networkNames,
  activeVertical,
  transactionType,
  locations,
}: {
  startDate: string;
  endDate: string;
  temporalUnit: TemporalUnit;
  authRequestPath: string;
  networkNames: string[];
  activeVertical: string;
  transactionType: TransactionTypes;
  locations: string[];
  isPromotionPage?: boolean;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let result: any = '';

  const url = new URL(`${ENV_CONFIG.API_URL}/promotions/${authRequestPath}`);
  url.searchParams.append('startDate', startDate);
  url.searchParams.append('endDate', endDate);
  url.searchParams.append('temporalUnit', temporalUnit);

  addSearchParamIfValueNotNull(url, 'transactionType', transactionTypeForApiCall(transactionType));

  if (activeVertical === Verticals.Fuel && transactionType === 'allTransactions') {
    transactionType = TransactionTypes.Gas;
    url.searchParams.append('transactionType', transactionType);
  }

  try {
    result = await axiosInstance.post(url.toString(), { networkNames, locations, transactionType });
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
    throw error;
  }

  return result.data;
};
