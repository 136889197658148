import { useLocation } from 'react-router-dom';

import { ManageCreditCard } from '../billingPaymentMethods/creditCard/ManageCreditCard';

import { ManageBankAccount } from './ManageBankAccount';

export const ManagePayment = () => {
  const location = useLocation();

  const fromSection = location.pathname.split('/')[5];
  const isCard = fromSection.includes('card');

  return isCard ? <ManageCreditCard /> : <ManageBankAccount />;
};
