import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@app/hooks';
import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import { Button } from '@chakra-ui/react';

import { setDateRange } from '@/features/dashboard/slice';

import { merchantOrgMap } from '../../config';

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  const { t } = useTranslation();
  const url = window.location.href;
  const inviteMatches = url.match(/invitation=([^&]+)/);
  const orgMatches = url.match(/organization=([^&]+)/);
  const merchantName = url.substring(url.lastIndexOf('/') + 1);
  const dispatch = useAppDispatch();

  // Handle invites from email and/or links
  // This will auto-redirect the user to complete sign up
  // with Auth0's embedded workflow
  if (inviteMatches && orgMatches) {
    const options: RedirectLoginOptions = {
      authorizationParams: {
        organization: orgMatches[1],
        invitation: inviteMatches[1],
      },
    };
    loginWithRedirect(options);
  }

  // handle `/login/<MERCHANT_NAME>` login shortcuts
  // this removes 1 step from the login flow
  // but requires merchantNames mapped to their org_ids
  // eventually this will be API driven versus hardcoded
  const onClick = (merchantName: string) => {
    if (merchantName) {
      const options: RedirectLoginOptions = {
        authorizationParams: {
          organization: merchantOrgMap[merchantName],
        },
      };
      loginWithRedirect(options);
    } else {
      loginWithRedirect();
    }

    localStorage.removeItem('startDate');
    localStorage.removeItem('endDate');
    dispatch(setDateRange({ startDate: '', endDate: '' }));
  };

  return (
    <Button size='default' variant={'brandPrimary'} onClick={() => onClick(merchantName)} data-testid='login-btn'>
      {t('loginPage.form.button')}
    </Button>
  );
};
