import { Trans, useTranslation } from 'react-i18next';
import { Box, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import { useDecision } from '@optimizely/react-sdk';
import _ from 'lodash';

import { useAppSelector } from '@/app/hooks';
import { Card } from '@/common/components/card';
import { DataLoadingErrorPanel, EmptyDataAlertPanel } from '@/common/components/errorAlert';
import { LoadingSpinner } from '@/common/components/loadingSpinner';
import { RadialBarChart } from '@/common/components/radialBarChart';
import { Section } from '@/common/components/section';
import { Stats } from '@/common/components/stats';
import { CapacityByLocationDropdown, Status, Verticals } from '@/common/interfaces';
import { ENV_CONFIG } from '@/config';
import { selectActiveVertical } from '@/features/auth/userSlice';

import { ByLocationPaginatedBarChart } from './ByLocationPaginatedBarChart';
import { CapacityInfoLink } from './CapacityInfo';
import { CapacityByHour } from './CardsContentGenerator';
import { CapacityFilter } from './Filter';
import { selectCapacity, selectCapacityLocationFilter, selectCapacityStatus } from './slice';

export const Capacity = () => {
  const { t } = useTranslation();
  const selectedFilterOption = useAppSelector(selectCapacityLocationFilter);
  const capacityData = useAppSelector(selectCapacity);
  const capacityStatus = useAppSelector(selectCapacityStatus);
  const activeVertical = useAppSelector(selectActiveVertical);
  const [capacityFlag] = useDecision('md-569', { autoUpdate: true });

  const isEmptyData = capacityStatus === Status.Idle && _.isEmpty(capacityData);

  if (capacityStatus === Status.Failed) {
    return <DataLoadingErrorPanel />;
  }

  if (isEmptyData) {
    return <EmptyDataAlertPanel />;
  }

  if (capacityStatus === Status.Loading) {
    return (
      <Flex height='300px' justifyContent='center' alignItems='center'>
        <LoadingSpinner />
      </Flex>
    );
  }

  const renderCapacity = () => {
    if (activeVertical === Verticals.Fuel || (ENV_CONFIG.TIER !== 'prod' && capacityFlag.enabled)) return true;
    return false;
  };

  const renderByLocationDataGauge = () => {
    if (selectedFilterOption === CapacityByLocationDropdown.OperatingHours) {
      return capacityData.capacityByLocation?.operating.gauge;
    } else if (selectedFilterOption === CapacityByLocationDropdown.PeakHours) {
      return capacityData.capacityByLocation?.peak.gauge;
    }
  };

  const renderByLocationDataBarGraph = () => {
    if (selectedFilterOption === CapacityByLocationDropdown.OperatingHours) {
      return capacityData.capacityByLocation?.operating.barChart;
    } else if (selectedFilterOption === CapacityByLocationDropdown.PeakHours) {
      return capacityData.capacityByLocation?.peak.barChart;
    }
  };

  return (
    <>
      {renderCapacity() && (
        <Box maxW='100%'>
          <Section>
            <Stats stats={capacityData.statCards} />
          </Section>

          <Section name='capacityPage.byLocationSection.label'>
            <Card>
              <Grid
                templateAreas={{ xl: '"left right"', base: '"left" "right"' }}
                gridTemplateColumns={{ base: '1fr', xl: '30% 1fr' }}
                gap='1'
              >
                <GridItem pl='2' area={'left'}>
                  <Flex direction='column' gap={4}>
                    <Flex align='center' gap={2}>
                      <Text>{t('capacityPage.byLocationSection.filterBy.label')}</Text>

                      <CapacityFilter />
                    </Flex>
                    <Text>{t('capacityPage.byLocationSection.filterBy.desc')}</Text>

                    <Flex alignItems={'center'} flexDirection={'column'} flexWrap={'wrap'} mt={4}>
                      <Text textAlign='center'>
                        {selectedFilterOption === t('capacityPage.byLocationSection.filter.operatingHrs') ? (
                          <Trans
                            i18nKey={`capacityPage.byLocationSection.gauge.operatingHoursHeader`}
                            components={{ bold: <strong /> }}
                          />
                        ) : (
                          <Trans
                            i18nKey={`capacityPage.byLocationSection.gauge.peakHoursHeader`}
                            components={{ bold: <strong /> }}
                          />
                        )}
                      </Text>

                      <Flex
                        direction='column'
                        align='center'
                        width='100%'
                        height={360}
                        style={{ fontFeatureSettings: `'ss01'` }}
                      >
                        <RadialBarChart data={renderByLocationDataGauge()} loadingStatus={capacityStatus} />

                        <Box position={'relative'} top={-150}>
                          <CapacityInfoLink />
                        </Box>
                      </Flex>
                    </Flex>
                  </Flex>
                </GridItem>
                <GridItem pl='2' area={'right'}>
                  <Box>
                    <ByLocationPaginatedBarChart
                      byLocationData={renderByLocationDataBarGraph()}
                      loadingStatus={capacityStatus}
                    />
                  </Box>
                </GridItem>
              </Grid>
            </Card>
          </Section>

          <Section name='capacityPage.byHourSection.label'>
            <Card variant='topBorder'>
              <CapacityByHour capacityHourList={capacityData.capacityByHourBarChart} capacityStatus={capacityStatus} />
            </Card>
          </Section>
        </Box>
      )}
    </>
  );
};
