/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'typed-redux-saga';

import * as api from './api';
import { buildStatementsTable } from './dataFormatting';
import { statementsFetchFailed, statementsFetchRequest, statementsFetchSucceeded } from './slice';
import { IStatementsData, IStatementsList, StatementsRequestPayload } from './types';

/*****************************************************************************/
/******************************* WORKERS *************************************/
/*****************************************************************************/
export function* fetchStatements({ payload }: PayloadAction<StatementsRequestPayload>) {
  try {
    const result: IStatementsData[] = yield* call(api.fetchStatements, {
      authRequestPath: payload.authRequestPath,
      roles: payload.roles,
    });
    const table = buildStatementsTable({
      data: result,
      activeVertical: payload.activeVertical,
      startDate: payload.startDate,
      endDate: payload.endDate,
    });

    const data: IStatementsList = {
      table,
      rawData: result,
    };
    yield* put(statementsFetchSucceeded(data));
  } catch (error: any) {
    yield* put(statementsFetchFailed(error.message));
  }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/
export function* watchStatements() {
  yield* takeLatest(statementsFetchRequest, fetchStatements);
}
