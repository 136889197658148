export enum PlaidVerificationStatus {
  AUTOMATICALLY_VERIFIED = 'automatically_verified',
  PENDING_AUTOMATIC_VERIFICATION = 'pending_automatic_verification',
  PENDING_MANUAL_VERIFICATION = 'pending_manual_verification',
  MANUALLY_VERIFIED = 'manually_verified',
  VERIFICATION_EXPIRED = 'verification_expired',
  VERIFICATION_FAILED = 'verification_failed',
}

export interface IPlaidToken {
  link_token: string | null;
  expiration: number | null;
}

export interface IPlaidMetadata {
  id: string;
  name: string;
  mask: string;
  type: string;
  subtype: string;
  verification_status?: PlaidVerificationStatus;
  class_type?: string;
  bank: {
    name: string;
    institution_id: string;
  };
}
