import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiLock } from 'react-icons/fi';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Icon,
  Input,
  Radio,
  RadioGroup,
  Text,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { merge } from 'lodash';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { ExpansionPanel } from '@/common/components/expansionPanel';
import { CustomFormLabel } from '@/common/components/form';
import { TRANSACTION_DATA_MAPPING } from '@/features/settings/locations/constants';
import { selectFuelLocationFormData, updateFuelLocationFormData } from '@/features/settings/locations/slice';
import {
  AddFuelLocationLoginCredentialsSchema,
  AddFuelLocationTransactionDataFormData,
  DataSources,
} from '@/features/settings/locations/types';
import {
  addFuelLocationLoginCredentialsSchema,
  addFuelLocationTransactionDataSchema,
} from '@/features/settings/locations/validators';

export function TransactionDataForm() {
  const { t } = useTranslation();

  const fuelLocationFormData = useAppSelector(selectFuelLocationFormData);

  return (
    <Flex mt={4} gap={2} direction={['column', 'row']}>
      <Box mt='8' w={['100%', '70%']}>
        <Heading as='h2' size='lg' mb={4}>
          {t('locationsSettingsPage.fuelForm.titles.transactionData')}
        </Heading>

        <Heading as='h3' size='sm' mb={4}>
          {t('locationsSettingsPage.fuelForm.fields.transactionData.helper')}
        </Heading>

        <Text>
          {t('locationsSettingsPage.fuelForm.forSite', {
            siteName: fuelLocationFormData?.name,
            siteAddress: fuelLocationFormData?.address.address1,
          })}
        </Text>
        <Routes>
          <Route index element={<SelectTransactionDataForm />} />
          <Route path='processor-data' element={<ProcessorDataForm />} />
          <Route path='credentials' element={<ProcessorCredentialsForm />} />
        </Routes>
      </Box>

      <Box w={['100%', '30%']}>
        <ExpansionPanel header={t('locationsSettingsPage.fuelForm.fields.transactionData.help.title')}>
          <Text>{t('locationsSettingsPage.fuelForm.fields.transactionData.help.content')}</Text>
        </ExpansionPanel>
      </Box>
    </Flex>
  );
}

function ProcessorDataForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { handleSubmit, register } = useForm<{ addCrendentials: 'yes' | 'no' }>({
    defaultValues: {
      addCrendentials: 'yes',
    },
  });

  const onSubmit = (data: { addCrendentials: 'yes' | 'no' }) => {
    if (data.addCrendentials === 'no') {
      navigate('/settings/locations/new/5');
      return;
    }

    if (data.addCrendentials === 'yes') {
      navigate('/settings/locations/new/4/credentials');
    }
  };

  return (
    <Box>
      <FormControl as='form' onSubmit={handleSubmit(onSubmit)}>
        <RadioGroup my={5} defaultValue='yes'>
          <VStack alignItems='flex-start'>
            <Radio value='yes' color='neutral.900' {...register('addCrendentials')}>
              <Text fontWeight='bold'>{t(`locationsSettingsPage.fuelForm.fields.processorData.options.yes`)}</Text>
            </Radio>
            <Radio value='no' color='neutral.900' {...register('addCrendentials')}>
              <Text fontWeight='bold'>{t(`locationsSettingsPage.fuelForm.fields.processorData.options.no`)}</Text>
            </Radio>
          </VStack>
        </RadioGroup>

        <Flex mt={3} gap={5}>
          <Button as={Link} to='/settings/locations/new/4' variant='accentSecondary' size='md' px={8}>
            {t('payment.addPayout.back')}
          </Button>
          <Button type='submit' variant='brandPrimary' size='md' px={8}>
            {t('locationsSettingsPage.fuelForm.actions.continue')}
          </Button>
        </Flex>
      </FormControl>
    </Box>
  );
}

function SelectTransactionDataForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<AddFuelLocationTransactionDataFormData>({
    resolver: zodResolver(addFuelLocationTransactionDataSchema),
  });

  const fuelLocationFormData = useAppSelector(selectFuelLocationFormData);

  const onSubmit = (data: AddFuelLocationTransactionDataFormData) => {
    const formattedData = merge({}, fuelLocationFormData, data);

    if (data.additionalProperties.dataSources.includes(DataSources.PROCESSOR)) {
      navigate('processor-data');
      dispatch(updateFuelLocationFormData(formattedData));

      return;
    }

    formattedData.additionalProperties.processorCredentials = undefined;
    dispatch(updateFuelLocationFormData(formattedData));

    navigate('/settings/locations/new/5');
  };

  return (
    <FormControl as='form' onSubmit={handleSubmit(onSubmit)}>
      <RadioGroup
        defaultValue={
          fuelLocationFormData?.additionalProperties.dataSources &&
          (fuelLocationFormData?.additionalProperties.dataSources[0] as string)
        }
      >
        <VStack alignItems='flex-start'>
          {TRANSACTION_DATA_MAPPING.map((td) => (
            <Radio key={td} value={td} color='neutral.900' {...register('additionalProperties.dataSources.0')}>
              <Text fontWeight='bold'>{t(`locationsSettingsPage.fuelForm.fields.transactionData.options.${td}`)}</Text>
              <Text fontSize='sm'>{t(`locationsSettingsPage.fuelForm.fields.transactionData.optionHelper.${td}`)}</Text>
            </Radio>
          ))}
        </VStack>
      </RadioGroup>
      {errors.additionalProperties?.dataSources && (
        <Text variant='error'>{errors.additionalProperties?.dataSources[0]?.message}</Text>
      )}

      <Button type='submit' variant='brandPrimary' size='md' px={8} mt={5}>
        {t('locationsSettingsPage.fuelForm.actions.continue')}
      </Button>
    </FormControl>
  );
}

function ProcessorCredentialsForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const fuelLocationFormData = useAppSelector(selectFuelLocationFormData);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<AddFuelLocationLoginCredentialsSchema>({
    resolver: zodResolver(addFuelLocationLoginCredentialsSchema),
  });

  const onSubmit = (data: AddFuelLocationLoginCredentialsSchema) => {
    const formattedData = merge({}, fuelLocationFormData, data);
    dispatch(updateFuelLocationFormData(formattedData));

    navigate('/settings/locations/new/5');
  };

  return (
    <Box>
      <FormControl as='form' onSubmit={handleSubmit(onSubmit)}>
        <CustomFormLabel htmlFor='username' mt={5}>
          {t('locationsSettingsPage.fuelForm.fields.loginCredentials.username')}
          <Input
            type='text'
            id='username'
            placeholder='Enter your username'
            mt={2}
            required={fuelLocationFormData?.additionalProperties?.dataSources?.includes(DataSources.PROCESSOR)}
            {...register('additionalProperties.processorCredentials.username')}
            isInvalid={
              errors.additionalProperties?.processorCredentials?.username &&
              errors.additionalProperties.processorCredentials.username?.message !== ''
            }
          />
        </CustomFormLabel>
        {errors.additionalProperties?.processorCredentials?.username && (
          <Text variant='error'>{errors.additionalProperties?.processorCredentials.username.message}</Text>
        )}

        <CustomFormLabel htmlFor='password' mt={5}>
          {t('locationsSettingsPage.fuelForm.fields.loginCredentials.password')}
          <Input
            type='password'
            id='password'
            placeholder='Enter your password'
            mt={2}
            required={fuelLocationFormData?.additionalProperties?.dataSources?.includes(DataSources.PROCESSOR)}
            {...register('additionalProperties.processorCredentials.password')}
            isInvalid={
              errors.additionalProperties?.processorCredentials?.password &&
              errors.additionalProperties.processorCredentials.password?.message !== ''
            }
          />
        </CustomFormLabel>
        {errors.additionalProperties?.processorCredentials?.password && (
          <Text variant='error'>{errors.additionalProperties?.processorCredentials.password.message}</Text>
        )}

        <CustomFormLabel htmlFor='website' mt={5}>
          {t('locationsSettingsPage.fuelForm.fields.loginCredentials.website')}
          <Input
            type='text'
            id='website'
            placeholder='https://'
            mt={2}
            required={fuelLocationFormData?.additionalProperties?.dataSources?.includes(DataSources.PROCESSOR)}
            {...register('additionalProperties.processorCredentials.website')}
            isInvalid={
              errors.additionalProperties?.processorCredentials?.website &&
              errors.additionalProperties.processorCredentials.website?.message !== ''
            }
          />
        </CustomFormLabel>
        {errors.additionalProperties?.processorCredentials?.website && (
          <Text variant='error'>{errors.additionalProperties?.processorCredentials.website.message}</Text>
        )}

        <Flex gap={2} alignItems='center' my={3}>
          <Icon color='text.success.default'>
            <FiLock />
          </Icon>
          <Text color='text.success.default'>
            {t('locationsSettingsPage.fuelForm.fields.loginCredentials.secureConnection')}
          </Text>
        </Flex>

        <Flex mt={3} gap={5}>
          <Button as={Link} to='/settings/locations/new/4/processor-data' variant='accentSecondary' size='md' px={8}>
            {t('payment.addPayout.back')}
          </Button>
          <Button type='submit' variant='brandPrimary' size='md' px={8}>
            {t('locationsSettingsPage.fuelForm.actions.continue')}
          </Button>
        </Flex>
      </FormControl>
    </Box>
  );
}
