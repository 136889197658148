import { useTranslation } from 'react-i18next';
import { Flex, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';

import { Logo } from '@/features/auth';

export function LoadingScreen() {
  const { t } = useTranslation();

  return (
    <Flex flexDirection='column' alignItems='center' justifyContent='center' bg='background.dark' height='100vh'>
      <motion.div
        initial={{ opacity: 0.1 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0.1 }}
        transition={{
          duration: 2.5,
          repeat: Infinity,
          delay: 0.5,
          repeatType: 'reverse',
        }}
      >
        <Logo height={90} />
      </motion.div>
      <Text color='text.default-inverse' fontWeight='bold' mt='8'>
        {t('loadingScreen')}
      </Text>
    </Flex>
  );
}
