import { useAppSelector } from '@app/hooks';
import { Box } from '@chakra-ui/react';
import { LoadingSpinner } from '@components/loadingSpinner';

import { Status } from '@/common/interfaces';

import { selectContentStatus } from '../../slice';

/*
This page is intending to be the "template". 
By copy-pasting this entire folder under the `./features` dir, then renaming said folder to your feature's name.
Now an Upside Engineer has a quick-start access to building a new page for the Merchant Dashboard with all the common states accessible
and ready to dispatch a new event to fetch data from an API endpoint.
*/
export const Default = () => {
  const contentStatus = useAppSelector(selectContentStatus);

  return (
    <>
      {contentStatus === Status.Loading ? (
        <LoadingSpinner />
      ) : (
        <Box maxW='100%'>
          {/* Beginning of Content Page*/}

          {/* Implement components here */}

          {/* End of Content Page */}
        </Box>
      )}
    </>
  );
};
