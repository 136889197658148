import { ITableCellValue, ITableData } from '@components/table/Table';

import { display2Decimals, displayCurrency, displayPercent } from '@/common/utils';
import i18n from '@/i18n';

import { IProfitabilityFlowthrough, IProfitabilityWalkthroughData } from './types';

export const buildProfitabilityTable = (data: IProfitabilityFlowthrough): ITableData => {
  const rows: Array<string | number>[] = [
    ['Total Sales', `${displayCurrency(data.total_upside_revenue)}`],
    ['- Expected Sales', `${displayCurrency(data.expected_revenue_from_control)}`],
    ['= Incremental Revenue', `${displayCurrency(data.incremental_revenue)}`],
    ['X Average Margin Percentage', `${displayPercent(data.average_gross_margin)}`],
    ['= Incremental Profit', `${displayCurrency(data.incremental_gross_profit)}`],
    ['- Cash Back on Upside', `${displayCurrency(data.cash_back_provided_to_customers)}`],
    ['+ Upside Promotion Credit', `${displayCurrency(data.upside_funded_customer_promotions)}`],
    ['= Incremental Profit Net of Promotions', `${displayCurrency(data.incremental_profit_net_of_promotions)}`],
    ['- Upside Profit Share', `${displayCurrency(data.upside_profit_share)}`],
    ['= Net Profit from Upside', `${displayCurrency(data.incremental_net_profit_from_upside)}`],
    ['Net Profit from Upside', `${displayCurrency(data.incremental_net_profit)}`],
    ['/ Total Promotions & Profit Share', `${displayCurrency(data.total_promotions_and_profit_share)}`],
    ['= Return on Investment', `${displayPercent(data.return_on_investment)}`],
  ];

  const result: ITableData = {
    title: 'Upside invests your funds in promotions and earns you profit',
    headers: ['Upside Incremental Profit Flowthrough'],
    rows: rows,
  };

  return result;
};

export const buildRoiCalculationTable = (data: IProfitabilityFlowthrough): ITableData => {
  const rows: Array<string | number | ITableCellValue>[] = [
    [
      i18n.t('profitabilityPage.roiCalculationTbl.incrementalNetProfit') as string,
      '',
      {
        value: displayCurrency(data.incremental_net_profit),
        isNumeric: true,
        tableCellId: 'incrementalNetProfit',
      },
    ],
    [
      i18n.t('profitabilityPage.roiCalculationTbl.totalPromo') as string,
      '\u00F7',
      {
        value: displayCurrency(data.total_promotions_and_profit_share),
        isNumeric: true,
        tableCellId: 'totalProgramCosts',
      },
    ],
    [
      i18n.t('profitabilityPage.roiCalculationTbl.roi') as string,
      '=',
      {
        value: displayPercent(data.return_on_investment),
        isNumeric: true,
        tableCellId: 'roi',
      },
    ],
  ];

  const result: ITableData = {
    title: i18n.t('profitabilityPage.roiCalculationTbl.title'),
    columnWidths: ['', '30px', '150px'],
    headers: [],
    rows: rows,
  };

  return result;
};

export const buildRoiFlowthroughTable = (data: IProfitabilityFlowthrough): ITableData => {
  let rows: Array<string | number | ITableCellValue>[] = [];
  const isEmptyData = Boolean(
    !data.return_on_investment && !data.total_promotions_and_profit_share && !data.incremental_net_profit,
  );

  if (!isEmptyData) {
    rows = [
      [
        i18n.t('incrementalNetProfit') as string,
        '',
        { value: `${displayCurrency(data.incremental_net_profit, 0)}`, isNumeric: true },
      ],
      [
        i18n.t('customerDeepDivePage.customerVsControlChart.roiFlowthroughTbl.totalPromotionsAndProfitShare') as string,
        '\u00F7',
        { value: `${displayCurrency(data.total_promotions_and_profit_share, 0)} `, isNumeric: true },
      ],
      [i18n.t('roi') as string, '=', { value: `${displayPercent(data.return_on_investment)}`, isNumeric: true }],
    ];
  }

  const result: ITableData = {
    title: i18n.t('customerDeepDivePage.customerVsControlChart.roiFlowthroughTbl.title') as string,
    headers: [],
    rows: rows,
  };

  return result;
};

export const buildWalkthroughDialogData = (data: IProfitabilityFlowthrough): IProfitabilityWalkthroughData => {
  return {
    // Non-fuel
    totalUpsideRevenue: displayCurrency(data.total_upside_revenue),
    expectedRevenueFromControl: displayCurrency(data.expected_revenue_from_control),
    incrementalRevenue: displayCurrency(data.incremental_revenue),
    averageGrossMargin: data.average_gross_margin as string,
    // Fuel
    gallonsOnUpside: display2Decimals(data.total_gallons_bought, 0),
    nonIncrementalGallons: display2Decimals(data.non_incremental_gallons, 0),
    totalIncrementalGallons: display2Decimals(data.total_incremental_gallons, 0),
    blendedAvgMarginPerGallon: displayCurrency(data.blended_average_margin_gallon, 4),

    incrementalGrossProfit: displayCurrency(data.incremental_gross_profit),
    cashBackProvidedToCustomers: displayCurrency(data.cash_back_provided_to_customers),
    upsideFundedCustomerPromotions: displayCurrency(data.upside_funded_customer_promotions),
    upsideProfitShare: displayCurrency(data.upside_profit_share),
    incrementalNetProfitFromUpside: displayCurrency(data.incremental_net_profit_from_upside),
    returnOnInvestment: data.return_on_investment as string,
    totalPromotionsAndProfitShare: displayCurrency(data.total_promotions_and_profit_share),
    roiAmount: displayCurrency(1 + parseFloat(data.return_on_investment as string)),
  };
};
