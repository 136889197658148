import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Flex, FormControl, Grid, GridItem, Heading, Text, useBreakpointValue } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { LoadingSpinner } from '@/common/components/loadingSpinner';
import { Status } from '@/common/interfaces';
import { selectAuthRequestPath } from '@/features/auth/userSlice';
import { selectSelectedTransactionType } from '@/features/dashboard/slice';

import {
  columnsListFetchRequested,
  selectColumnsList,
  selectCustomReportsStatus,
  selectReportOptions,
  updateReportOptions,
} from '../slice';
import { GranularityType } from '../type';
import { ColumnSelectionForm, columnSelectionSchema } from '../validators';

export function ColumnSelection() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const numColumns = useBreakpointValue({ base: 1, md: 2, lg: 3 });

  const reportOptions = useAppSelector(selectReportOptions);
  const columnList = useAppSelector(selectColumnsList);
  const status = useAppSelector(selectCustomReportsStatus);
  const authRequestPath = useAppSelector(selectAuthRequestPath);
  const transactionType = useAppSelector(selectSelectedTransactionType);

  const { handleSubmit, setValue } = useForm<ColumnSelectionForm>({
    resolver: zodResolver(columnSelectionSchema),
    defaultValues: reportOptions,
  });

  useEffect(() => {
    dispatch(columnsListFetchRequested({ authRequestPath, transactionType }));
  }, [dispatch, authRequestPath]);

  useEffect(() => {
    setValue('includedFields', reportOptions.includedFields);
  }, [reportOptions, setValue]);

  function onSubmit(data: ColumnSelectionForm) {
    dispatch(updateReportOptions({ includedFields: data.includedFields }));
    navigate('/reports/customReports/download');
  }

  const fieldOptions =
    reportOptions.granularity === GranularityType.Transaction ? columnList.transaction : columnList.summary;

  // const watchedFields = watch('includedFields'); // Disabled due to MD-1656

  return (
    <Flex direction='column' gap='2'>
      <Heading as='h3' size='md'>
        {t('customReportsPage.columnSelection2')}
      </Heading>
      {/* <Text>{t('customReportsPage.columnSelectionDescription')}</Text> // Disabled due to MD-1656 */}
      {status === Status.Loading && <LoadingSpinner />}
      {status === Status.Idle && (
        <FormControl as='form' onSubmit={handleSubmit(onSubmit)}>
          {/* Disabled due to MD-1656 */}
          {/* <CheckboxGroup
            defaultValue={watchedFields}
            value={watchedFields}
            onChange={(items: string[]) => setValue('includedFields', items)}
          >
            <Grid templateColumns={`repeat(${numColumns}, 1fr)`} gap={3} my={5}>
              {fieldOptions.map((field) => (
                <GridItem key={field.column_name}>
                  <Checkbox value={field.name} color='neutral.900' {...register('includedFields')}>
                    <Text>{field.display_name}</Text>
                  </Checkbox>
                </GridItem>
              ))}
            </Grid>
          </CheckboxGroup> */}
          {/* Show bulleted list due to MD-1656 */}
          <Grid templateColumns={`repeat(${numColumns}, 1fr)`} gap={3} my={5}>
            {fieldOptions.map((field) => (
              <GridItem key={field.column_name}>
                <Text as='li'>{field.display_name}</Text>
              </GridItem>
            ))}
          </Grid>
          <Flex gap='4'>
            <Button
              as={Link}
              to='/reports/customReports/granularity'
              type='submit'
              variant='brandSecondary'
              size='md'
              px={8}
            >
              {t('customReportsPage.actions.back')}
            </Button>
            <Button type='submit' variant='brandPrimary' size='md' px={8}>
              {t('customReportsPage.actions.continue')}
            </Button>
          </Flex>
        </FormControl>
      )}
    </Flex>
  );
}
