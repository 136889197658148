/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction, useMemo } from 'react';
import { FiDownload } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import { store } from '@app/store';
import { Box, Button, Center, Circle, createStandaloneToast, Flex, Heading, Stack, Text } from '@chakra-ui/react';
import { CustomerSegmentsInfo } from '@components/customerSegmentsInfo';
import { CustomLegends } from '@components/customLegends';
import { MetricContent } from '@components/dualMetricCard/MetricContent';
import { EmptyDataAlert } from '@components/errorAlert';
import { LineGraph } from '@components/lineGraph';
import { LoadingSpinner } from '@components/loadingSpinner';
import { IStat, Stat } from '@components/stat';
import { Table, TableHeader } from '@components/table';
import { ITableData } from '@components/table/Table';
import { BarDatum, BarSvgProps } from '@nivo/bar';
import { RadialBarDatum, RadialBarSerie } from '@nivo/radial-bar';
import { useDecision } from '@optimizely/react-sdk';
import { t } from 'i18next';
import _ from 'lodash';

import { useAppSelector } from '@/app/hooks';
import { BarGraph } from '@/common/components/barGraph';
import { Card } from '@/common/components/card';
import { LocationsMap } from '@/common/components/map';
import { RadialBarChart } from '@/common/components/radialBarChart';
import { Stats } from '@/common/components/stats';
import { Status, TransactionTypes, Verticals } from '@/common/interfaces';
import { convertArrayToCSV, displayPercent, downloadCSV, getAmountOfTicksToRender } from '@/common/utils/utils';
import { selectSelectedTransactionType, TemporalUnit } from '@/features/dashboard/slice';
import { RecentTransactionsTable } from '@/features/reports/customers/RecentTransactionsTable';
import colors from '@/theme/foundations/colors';

import i18n from '../../i18n';
import { theme } from '../../theme';
import { IRefinedPerformanceSummary } from '../dashboard/types';
import { paginateData } from '../explore/capacity/ByLocationPaginatedBarChart';
import { buildByLocationsBarChartData } from '../explore/capacity/dataFormatting';
import { CapacityByLocationPopover } from '../explore/capacity/Popover';
import { ICapacityByLocationRaw } from '../explore/capacity/types';
import { ICustomerSegmentationSummary } from '../explore/customerDeepDive/types';
import { ISiteLevelPerformance, MapVisualizations } from '../explore/locations/types';
import { CustomerAcquisitionChart } from '../reports/customers/CustomerAcquisitionChart';
import { CustomerSegmentBreakdownChart } from '../reports/customers/CustomerSegmentBreakdownChart';
import { CustomersTable } from '../reports/customers/CustomersTable';

import { ROICalculator } from './ROICalculator';

const { toast } = createStandaloneToast({ theme });

export const AvgAcceptedOffer = ({
  homeGraphData,
  loadingStatus,
  temporalUnit = 'week',
  activeVertical,
}: {
  homeGraphData: IRefinedPerformanceSummary;
  loadingStatus: Status;
  temporalUnit: TemporalUnit;
  activeVertical: Verticals;
}) => {
  const activeTransactionType = useAppSelector(selectSelectedTransactionType);
  const isLoading = loadingStatus === Status.Loading;
  const isEmpty =
    loadingStatus === Status.Idle && _.get(homeGraphData, 'offerDistribution.lineGraph[0].data', []).length === 0;
  const tooltipLabel = i18n.t(`homePage.promotionsGraph.${activeVertical}.tooltipLabel`);

  const { offerDistribution } = homeGraphData;

  const filteredData = useMemo(() => {
    if (offerDistribution && offerDistribution.lineGraph.length) {
      if (activeVertical === Verticals.Fuel && activeTransactionType === TransactionTypes.CStore) {
        return offerDistribution.lineGraph.filter((item: { key: string }) => item.key !== 'avgMargin');
      }

      return offerDistribution.lineGraph;
    }
  }, [activeVertical, offerDistribution, activeTransactionType]);

  const legend = useMemo(() => {
    if (offerDistribution && offerDistribution.lineGraph.length) {
      const data = offerDistribution.lineGraph.map((line: { key: string; color: string }) => ({
        label: i18n.t(`homePage.promotionsGraph.tooltipSegmentNames.${line.key}`),
        color: line.color,
      }));

      if (activeVertical !== Verticals.Fuel || activeTransactionType === TransactionTypes.CStore) {
        data.shift();
      }

      return data;
    }

    return [];
  }, [offerDistribution, activeTransactionType, activeVertical]);

  const getStatLabel = (vertical: Verticals, transactionType: string) => {
    if (vertical === Verticals.Fuel && transactionType === TransactionTypes.CStore) {
      return i18n.t(`homePage.promotionsGraph.${Verticals.Grocery}.statLabel`);
    }

    return i18n.t(`homePage.promotionsGraph.${vertical}.statLabel`);
  };

  const statLabel = getStatLabel(activeVertical, activeTransactionType);

  const colors = useMemo(() => {
    if (offerDistribution && offerDistribution.lineGraph.length) {
      if (activeVertical === Verticals.Fuel && activeTransactionType === TransactionTypes.CStore) {
        return offerDistribution.lineGraph
          .filter((item: { key: string; color: string }) => item.key !== 'avgMargin')
          .map((item: { color: string }) => item.color);
      }

      return offerDistribution.lineGraph.map((line: { color: string }) => line.color);
    }

    return [];
  }, [offerDistribution, activeVertical, activeTransactionType]);

  return (
    <Box minH={'560px'} w={'100%'}>
      {isEmpty ? (
        <EmptyDataAlert />
      ) : (
        <>
          {isLoading ? (
            <Stack h='100%' w='100%' pt={10} align={'center'} justify={'center'}>
              <LoadingSpinner />
            </Stack>
          ) : (
            <>
              <Stack>
                <Box w={'100%'} h={'100%'}>
                  <Stat value={homeGraphData.offerDistribution?.stats.avgPromo} label={statLabel} help={tooltipLabel} />
                </Box>
                <Box w={'100%'} h={'100%'} pt={4}>
                  <LineGraph
                    title='avgPromoOverTime'
                    data={filteredData}
                    loadingStatus={loadingStatus}
                    colors={colors}
                    isCurrency={activeVertical === Verticals.Fuel && activeTransactionType !== TransactionTypes.CStore}
                    isPercentage={
                      activeVertical !== Verticals.Fuel || activeTransactionType === TransactionTypes.CStore
                    }
                    temporalUnit={temporalUnit}
                    customProps={{
                      legends: [],
                      axisBottom: {
                        renderTick: (tick: any) => {
                          const dataLength = filteredData[0].data.length;

                          const MULTIPLY_VALUE = getAmountOfTicksToRender(dataLength);
                          const shouldRender = tick.tickIndex % MULTIPLY_VALUE <= 0;

                          if (dataLength < 12 || (dataLength > 12 && shouldRender)) {
                            return (
                              <g transform={`translate(${tick.x},${tick.y + 15})`}>
                                <text
                                  x={0}
                                  y={0}
                                  textAnchor='middle'
                                  dominantBaseline='middle'
                                  style={{
                                    fill: 'rgb(51, 51, 51)',
                                    fontSize: 12,
                                    fontFamily: 'sans-serif',
                                  }}
                                >
                                  {tick.value}
                                </text>
                              </g>
                            );
                          }

                          return <></>;
                        },
                      },
                    }}
                  />
                  {!!legend.length && (
                    <Flex align='center'>
                      <CustomLegends
                        disabled
                        options={legend}
                        defaultOptions={[]}
                        onChange={() => {
                          return false;
                        }}
                      />
                    </Flex>
                  )}
                </Box>
              </Stack>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export const RoiFlowthrough = ({
  homeGraphData,
  loadingStatus,
}: {
  homeGraphData: IRefinedPerformanceSummary;
  loadingStatus?: Status;
}) => {
  const tableData: ITableData = Object.assign({}, homeGraphData.roiFlowthroughTable, {
    rows: homeGraphData.roiFlowthroughTable?.rows,
  });
  const isEmpty = loadingStatus === Status.Idle && _.get(homeGraphData, 'roiFlowthroughTable.rows', []).length === 0;
  const isLoading = loadingStatus === Status.Loading;

  return (
    <Box w={'100%'} minH={'560px'}>
      {isEmpty ? (
        <EmptyDataAlert />
      ) : (
        <Stack>
          {isLoading ? (
            <Stack h='100%' w='100%' pt={10} align={'center'} justify={'center'}>
              <LoadingSpinner />
            </Stack>
          ) : (
            <>
              <Box h={'100%'} w={'100%'}>
                <Stat
                  value={displayPercent(homeGraphData.profitabilityWalkthroughData?.returnOnInvestment)}
                  label={i18n.t('roi')}
                  help={'Incremental net profit divided by total program costs'}
                />
              </Box>
              <Box w={'100%'} pt={4}>
                <>
                  <Table data={tableData} />
                  <ROICalculator
                    incrementalNetProfit={homeGraphData?.profitabilityWalkthroughData?.incrementalNetProfitFromUpside}
                    totalPromotions={homeGraphData?.profitabilityWalkthroughData?.totalPromotionsAndProfitShare}
                    returnOnInvestment={displayPercent(homeGraphData.profitabilityWalkthroughData?.returnOnInvestment)}
                  />
                </>
              </Box>
            </>
          )}
        </Stack>
      )}
    </Box>
  );
};

export const YourLocations = ({
  siteLevelPerformance,
  loadingStatus,
  mapAspectRatio,
}: {
  siteLevelPerformance: ISiteLevelPerformance;
  loadingStatus: Status;
  mapAspectRatio?: string;
}) => {
  const activeVertical = store.getState().user.activeVertical;
  const transactionType = store.getState().dashboard.selectedTransactionType;

  const tableData: ITableData = Object.assign({}, siteLevelPerformance?.topSitesTable, {
    rows: siteLevelPerformance?.topSitesTable?.rows.slice(0, 5),
  });
  const isLoading = loadingStatus === Status.Loading;
  const isEmpty = loadingStatus === Status.Idle && _.get(siteLevelPerformance, 'topSitesTable.rows', []).length === 0;

  const handleDownloadCSV = () => {
    if (!siteLevelPerformance?.topSitesLocationCSVData) {
      toast({
        title: t('downloadCSV.error') as string,
        status: 'error',
        duration: 5000,
        isClosable: true,
        variant: 'subtle',
      });
      return;
    }

    const data = siteLevelPerformance?.topSitesLocationCSVData;

    const csvString = convertArrayToCSV(data);

    const startDate = data[0][i18n.t('dashboardPage.topPerformingSitesTbl.reportingStart')]
      .toString()
      .replace(/-/g, '');
    const endDate = data[0][i18n.t('dashboardPage.topPerformingSitesTbl.reportingEnd')].toString().replace(/-/g, '');
    const filename = `upside_location_performance_${startDate}_${endDate}.csv`;

    downloadCSV(csvString, filename);

    toast({
      title: t('downloadCSV.success') as string,
      status: 'success',
      variant: 'subtle',
      isClosable: true,
    });
  };

  const actionItems =
    activeVertical === Verticals.Grocery
      ? () => (
          <Button onClick={handleDownloadCSV} gap='2' variant='accentSecondary' size='md'>
            <FiDownload /> {t('common.download') as string}
          </Button>
        )
      : undefined;

  return (
    <Flex flexDirection={{ base: 'column', xl: 'row' }} width={'100%'} gap={{ base: 6, xl: 4 }}>
      <Box flexGrow={1} flexShrink={0} flexBasis={{ base: 'auto', xl: '50%' }}>
        <Heading as='h2' size='sm' mb='6'>
          {t('homePage.exploreMore.yourLocations') as string}
        </Heading>
        <Stack align={isLoading ? 'center' : 'default'} justify={isLoading ? 'center' : 'default'}>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            activeVertical && (
              <LocationsMap
                id={'topPerformanceMap'}
                aspectRatio={mapAspectRatio}
                stats={siteLevelPerformance?.map}
                vertical={activeVertical}
                transactionType={transactionType}
                visualization={MapVisualizations.HeatMap}
              />
            )
          )}
        </Stack>
      </Box>
      <Box flexGrow={1} flexShrink={0} flexBasis={{ base: 'auto', xl: '50%' }}>
        <Stack align={isLoading || isEmpty ? 'center' : 'default'} justify={isLoading ? 'center' : 'default'}>
          {isLoading && <LoadingSpinner />}
          {isEmpty && <EmptyDataAlert />}
          {!isLoading && !isEmpty && (
            <>
              <TableHeader title={tableData.title ?? 'homePage.exploreMore.topLocations'} actionItems={actionItems} />
              <Table data={tableData} />
            </>
          )}
        </Stack>
      </Box>
    </Flex>
  );
};

export const CustomerList = ({ loadingStatus }: { loadingStatus: Status }) => {
  const isLoading = loadingStatus === Status.Loading;

  return (
    <>
      <Box width={'100%'}>
        <Heading as='h2' size='sm' opacity={isLoading ? '20%' : '100%'}>
          {t('homePage.recentCustomers.label') as string}
        </Heading>
        <Stack
          h={'100%'}
          w={'100%'}
          align={isLoading ? 'center' : 'default'}
          justify={isLoading ? 'center' : 'default'}
        >
          {isLoading ? <LoadingSpinner /> : <CustomersTable />}
        </Stack>
      </Box>
    </>
  );
};

export const RecentTransactionsList = ({ loadingStatus }: { loadingStatus: Status }) => {
  const isLoading = loadingStatus === Status.Loading;

  const [customReportsFlag] = useDecision('md-1345', { autoUpdate: true });

  return (
    <>
      <Box width={'100%'}>
        <Flex justifyContent='space-between'>
          <Heading as='h2' size='sm' opacity={isLoading ? '20%' : '100%'}>
            {t('homePage.recentTransactions.label') as string}
          </Heading>
          {customReportsFlag.enabled && (
            <Button
              as={NavLink}
              to={'/reports/customReports/granularity?selected=transaction'}
              size='sm'
              variant='accentSecondary'
            >
              {t('homePage.recentTransactions.exportReport') as string}
            </Button>
          )}
        </Flex>

        <Stack
          h={'100%'}
          w={'100%'}
          align={isLoading ? 'center' : 'default'}
          justify={isLoading ? 'center' : 'default'}
        >
          {isLoading ? <LoadingSpinner /> : <RecentTransactionsTable />}
        </Stack>
      </Box>
    </>
  );
};

export const salesOnUpside = (homeGraphData: IRefinedPerformanceSummary, loadingStatus: Status) => {
  const activeVertical = store.getState().user.activeVertical;

  return (
    <MetricContent
      id={'metricSalesOnUpside'}
      vertical={activeVertical}
      metricLabel={'salesOnUpside'}
      metricData={homeGraphData?.homeMetricsData?.salesOnUpside}
      loadingStatus={loadingStatus}
    />
  );
};

export const incrementalSales = (homeGraphData: IRefinedPerformanceSummary, loadingStatus: Status) => {
  const activeVertical = store.getState().user.activeVertical;

  return (
    <MetricContent
      id={'metricIncrementalSales'}
      vertical={activeVertical}
      metricLabel={'incrementalSales'}
      metricData={homeGraphData?.homeMetricsData?.incrementalSales}
      loadingStatus={loadingStatus}
    />
  );
};

export const gallonsOnUpside = (homeGraphData: IRefinedPerformanceSummary, loadingStatus: Status) => {
  const activeVertical = store.getState().user.activeVertical;

  return (
    <MetricContent
      id={'metricGallonsOnUpside'}
      vertical={activeVertical}
      metricLabel={'gallonsOnUpside'}
      metricData={{ value: homeGraphData?.profitabilityWalkthroughData?.gallonsOnUpside }}
      loadingStatus={loadingStatus}
    />
  );
};

export const incrementalGallons = (homeGraphData: IRefinedPerformanceSummary, loadingStatus: Status) => {
  const activeVertical = store.getState().user.activeVertical;

  return (
    <MetricContent
      id={'metricIncrementalGallons'}
      vertical={activeVertical}
      metricLabel={'incrementalGallons'}
      metricData={{ value: homeGraphData?.profitabilityWalkthroughData?.totalIncrementalGallons }}
      loadingStatus={loadingStatus}
    />
  );
};

export const totalProgramCosts = (homeGraphData: IRefinedPerformanceSummary, loadingStatus: Status) => {
  const activeVertical = store.getState().user.activeVertical;

  return (
    <MetricContent
      id={'metricTotalProgramCosts'}
      vertical={activeVertical}
      metricLabel={'totalProgramCosts'}
      metricData={homeGraphData?.homeMetricsData?.customerProgramCosts}
      loadingStatus={loadingStatus}
    />
  );
};

export const incrementalNetProfit = (homeGraphData: IRefinedPerformanceSummary, loadingStatus: Status) => {
  const activeVertical = store.getState().user.activeVertical;

  return (
    <MetricContent
      id={'metricRoi'}
      vertical={activeVertical}
      metricLabel={'roi'}
      metricData={homeGraphData?.homeMetricsData?.roi}
      loadingStatus={loadingStatus}
    />
  );
};

export const CustomerAnalyticsCharts = ({
  selectedMetric,
  setSelectedMetric,
  loadingStatus,
  customersSegmentationSummary,
}: {
  selectedMetric: string;
  setSelectedMetric: Dispatch<SetStateAction<string>>;
  loadingStatus: Status;
  customersSegmentationSummary?: ICustomerSegmentationSummary;
}) => {
  const isEmptyData =
    loadingStatus === Status.Idle && customersSegmentationSummary?.segmentBreakdownChart?.length === 0;

  return (
    <Card
      loadingStatus={loadingStatus}
      button={{
        text: 'View more insights',
        to: '/reports/customers',
      }}
      variant='topBorder'
    >
      {!isEmptyData && (
        <Flex flexDirection='row' flexWrap='wrap' w='100%'>
          <Box flex={1} minWidth='450px'>
            <CustomerSegmentBreakdownChart
              title={'homePage.customerSegmentationChart.title'}
              data={customersSegmentationSummary?.segmentBreakdownChart}
              loadingStatus={loadingStatus}
            />

            <CustomerSegmentsInfo />
          </Box>
          <Box flex={1} minWidth='450px'>
            <CustomerAcquisitionChart
              title={'homePage.customerAcquisitionChart.title'}
              dropdownMenuProps={[
                {
                  selectedItem: selectedMetric,
                  itemSelector: (value) => setSelectedMetric(value),
                },
              ]}
              data={customersSegmentationSummary?.acquisition?.charts[selectedMetric]}
              loadingStatus={loadingStatus}
              displayLegend
            />
          </Box>
        </Flex>
      )}
    </Card>
  );
};

export const CapacityByLocation = ({
  capacityStats,
  capacityLocationsList,
  capacityGauge,
  capacityStatus,
}: {
  capacityStats: IStat[] | undefined;
  capacityLocationsList: ICapacityByLocationRaw[] | undefined;
  capacityGauge: RadialBarSerie<RadialBarDatum>[] | undefined;
  capacityStatus: Status;
}) => {
  const start = 0;
  const end = 4;
  const isEmpty = capacityStatus === Status.Idle && capacityLocationsList?.length === 0;
  const isLoading = capacityStatus === Status.Loading;

  const orderedData = paginateData(capacityLocationsList, start, end);

  const chartProps: Partial<BarSvgProps<BarDatum>> = {
    axisBottom: {
      format: (value: any) => displayPercent(value, 0),
      legend: t('capacityPage.byLocationSection.graph.axisBottomLegendLabel') as string,
      legendOffset: 30,
      legendPosition: 'middle',
      tickPadding: 5,
      tickRotation: 0,
      tickSize: 0,
    },
    axisLeft: {
      legend: t('capacityPage.byLocationSection.graph.axisLeftLegendLabel') as string,
      legendOffset: -180,
      legendPosition: 'middle',
      tickSize: 0,
      tickPadding: 5,
      tickRotation: 0,
    },
    colors: [colors.blue[800], colors.green[400]],
    enableLabel: false,
    enableGridY: false,
    enableGridX: true,
    groupMode: 'stacked',
    layers: ['grid', 'axes', 'bars', 'markers', 'annotations'],
    layout: 'horizontal',
    legends: [
      {
        dataFrom: 'keys',
        anchor: 'bottom-left',
        direction: 'row',
        justify: false,
        translateX: -175,
        translateY: 70,
        itemsSpacing: 130,
        itemWidth: 50,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 12,
        symbolShape: 'circle',
      },
    ],
    margin: { top: 60, right: 40, bottom: 70, left: 200 },
    maxValue: 1,
    padding: 0.33,
  };

  chartProps.tooltip = (point) => {
    return <CapacityByLocationPopover point={point} data={orderedData} />;
  };

  const radialCustomProps = {
    width: 400,
    height: 400,
    margin: { bottom: 0 },
  };

  return (
    <>
      {isLoading ? (
        <Center>
          <LoadingSpinner />
        </Center>
      ) : (
        <Stats stats={capacityStats} />
      )}
      <Flex flexDirection={{ base: 'column', xl: 'row' }} w={'100%'} gap={{ base: 6, xl: 4 }}>
        <Box flexGrow={1} flexShrink={1} flexBasis={{ base: 'auto', xl: '50%' }}>
          <Stack w={'100%'}>
            <Box alignSelf={'center'}>
              <Text pt={'24px'} pb={'24px'} fontSize={'xl'} textAlign={'left'}>
                {t('capacityPage.byLocationSection.gauge.homeHeader') as string}
              </Text>
              <RadialBarChart data={capacityGauge} loadingStatus={capacityStatus} customProps={radialCustomProps} />
            </Box>
            <Flex w='100%'>
              <Flex mr={'40px'} alignSelf={'center'}>
                <Circle size='12px' bg='data.blue' alignSelf={'center'} mr={'8px'} />
                <Text>{t('capacityPage.byHourSection.graph.legend.avgTrxnsNoUpside') as string}</Text>
              </Flex>
              <Flex alignSelf={'center'}>
                <Circle size='12px' bg='data.green' alignSelf={'center'} mr={'8px'} />
                <Text>{t('capacityPage.byHourSection.graph.legend.avgTrxnsWithUpside') as string}</Text>
              </Flex>
            </Flex>
          </Stack>
        </Box>

        <Box flexGrow={1} flexShrink={1} flexBasis={{ base: 'auto', xl: '50%' }} minWidth={'500px'}>
          <Stack w={'100%'}>
            <BarGraph
              data={isEmpty ? [] : buildByLocationsBarChartData(orderedData)}
              loadingStatus={capacityStatus}
              title=''
              width={'100%'}
              customProps={chartProps}
            />

            <Flex justify={'right'} width={'100%'} opacity={isLoading ? '20%' : '100%'}>
              <Button as={NavLink} to={'/explore/capacity'} size='md' variant='accentSecondary'>
                {t('homePage.exploreMore.learnMoreBtn') as string}
              </Button>
            </Flex>
          </Stack>
        </Box>
      </Flex>
    </>
  );
};

export const CardViewContents = {
  YourLocations,
  AvgAcceptedOffer,
  RoiFlowthrough,
  salesOnUpside,
  incrementalSales,
  totalProgramCosts,
  gallonsOnUpside,
  incrementalGallons,
  incrementalNetProfit,
  CustomerAnalyticsCharts,
  CustomerList,
  RecentTransactionsList,
  CapacityByLocation,
};
