import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { format } from 'date-fns';

import { Status, Verticals } from '@/common/interfaces';
import {
  selectActiveMerchantUuid,
  selectActiveUserUuid,
  selectActiveVertical,
  selectAuthRequestPath,
  selectIsUpsideManager,
  selectUserOrg,
  selectUserRoles,
} from '@/features/auth/userSlice';
import {
  performanceSummaryFetch,
  selectDateRange,
  selectHeaderStatus,
  selectSelectedLocations,
  selectSelectedNetworks,
  selectSelectedTransactionType,
} from '@/features/dashboard/slice';
import { behaviorFetchRequest } from '@/features/explore/behavior/slice';
import { capacityFetch } from '@/features/explore/capacity/slice';
import { allCustomersSummaryFetch, customerSegmentationSummaryFetch } from '@/features/explore/customerDeepDive/slice';
import {
  competitorsFetch,
  locationsFetch,
  selectSelectedCompetitorTypes,
  selectSiteLevelPerformance,
} from '@/features/explore/locations/slice';
import { MapVisualizations } from '@/features/explore/locations/types';
import {
  invoicesFetch,
  selectHasInvDataOnFirstLoad,
  selectInvEndDate,
  selectInvoicesList,
  selectInvStartDate,
  selectSelectedAccounts,
} from '@/features/reports/financialReports/invoices/slice';
import {
  payoutsFetchRequest,
  selectPayoutsEndDate,
  selectPayoutsPagination,
  selectPayoutsStartDate,
} from '@/features/reports/financialReports/payouts/slice';
import { promotionsFetch } from '@/features/reports/promotions/slice';
import { getLocationProcessorCredentials } from '@/features/settings/locations/api';
import { locationsSettingsFetchRequest } from '@/features/settings/locations/slice';
import { ProcessorData } from '@/features/settings/locations/types';
import { notificationSettingsFetchRequest } from '@/features/settings/notification/slice';
import { billingPaymentMethodsFetchRequest } from '@/features/settings/payment/billingPaymentMethods/slice';
import { paymentsFetchRequest } from '@/features/settings/payment/payoutMethod/slice';

import { usePermissions } from './permissions';
import { useFetchDataCheck } from './utils';

export const useFetchDashboardData = () => {
  const dispatch = useAppDispatch();
  const headerStatus = useAppSelector(selectHeaderStatus);
  const canFetchData = useFetchDataCheck('main data sets', true, true);
  const selectedNetworks = useAppSelector(selectSelectedNetworks);
  const selectedTransactionType = useAppSelector(selectSelectedTransactionType);
  const selectedLocations = useAppSelector(selectSelectedLocations);
  const authRequestPath = useAppSelector(selectAuthRequestPath);
  const { startDate, endDate, temporalUnit } = useAppSelector(selectDateRange);
  const activeMerchantUuid = useAppSelector(selectActiveMerchantUuid);
  const activeUserUuid = useAppSelector(selectActiveUserUuid);
  const activeVertical = useAppSelector(selectActiveVertical);

  useEffect(() => {
    if (canFetchData && headerStatus === Status.Idle) {
      if (startDate && endDate) {
        const payload = {
          startDate,
          endDate,
          temporalUnit,
          selectedNetworks,
          selectedTransactionType,
          authRequestPath,
          activeVertical,
          networkNames: selectedNetworks,
          locations: selectedLocations,
          transactionType: selectedTransactionType,
        };

        console.debug('fetch dashboard data');

        dispatch(performanceSummaryFetch(payload));
        dispatch(capacityFetch(payload));
        dispatch(customerSegmentationSummaryFetch(payload));
        dispatch(allCustomersSummaryFetch(payload));
        dispatch(promotionsFetch(payload));
        dispatch(locationsFetch(payload));

        console.debug('completed fetching dashboard data');
      }
    }
  }, [
    dispatch,
    startDate,
    endDate,
    temporalUnit,
    selectedNetworks,
    selectedTransactionType,
    selectedLocations,
    activeMerchantUuid,
    activeUserUuid,
    activeVertical,
    canFetchData,
    headerStatus,
    authRequestPath,
  ]);
};

export const useFetchBehavior = () => {
  const dispatch = useAppDispatch();
  const authRequestPath = useAppSelector(selectAuthRequestPath);
  const selectedNetworks = useAppSelector(selectSelectedNetworks);
  const selectedLocations = useAppSelector(selectSelectedLocations);
  const headerStatus = useAppSelector(selectHeaderStatus);
  const canFetchData = useFetchDataCheck('behavior', true, true);

  useEffect(() => {
    if (canFetchData && headerStatus === Status.Idle) {
      const payload = {
        authRequestPath,
        networkNames: selectedNetworks,
        locations: selectedLocations,
      };
      dispatch(behaviorFetchRequest(payload));
    }
  }, [dispatch, authRequestPath, canFetchData, selectedNetworks, selectedLocations, headerStatus]);
};

export const useFetchInvoices = () => {
  const dispatch = useAppDispatch();
  const headerStatus = useAppSelector(selectHeaderStatus);
  const canFetchData = useFetchDataCheck('invoices', true, true);
  const authRequestPath = useAppSelector(selectAuthRequestPath);
  const isUpsideManager = useAppSelector(selectIsUpsideManager);
  const activeVertical = useAppSelector(selectActiveVertical);
  const roles = useAppSelector(selectUserRoles);
  const invoicesList = useAppSelector(selectInvoicesList);
  const startInvDate = useAppSelector(selectInvStartDate);
  const endInvDate = useAppSelector(selectInvEndDate);
  const selectedAccounts = useAppSelector(selectSelectedAccounts);
  const hasDataOnFirstLoad = useAppSelector(selectHasInvDataOnFirstLoad);

  const isFirstLoad = typeof hasDataOnFirstLoad !== 'boolean';
  const shouldNotFetchData = typeof hasDataOnFirstLoad === 'boolean' && !hasDataOnFirstLoad;

  const startDate = isFirstLoad ? format(new Date(2016, 0), 'yyyy-MM-dd') : startInvDate.split('T')[0];
  const endDate = isFirstLoad ? format(new Date(), 'yyyy-MM-dd') : endInvDate.split('T')[0];

  const { hasBillingManagerAccess, hasPaymentsManagerAccess, hasStatementsInvoicesReadOnlyAccess } = usePermissions();

  useEffect(() => {
    if (
      canFetchData &&
      headerStatus === Status.Idle &&
      (isUpsideManager || hasBillingManagerAccess || hasPaymentsManagerAccess || hasStatementsInvoicesReadOnlyAccess)
    ) {
      if (shouldNotFetchData) {
        return;
      }

      dispatch(
        invoicesFetch({
          startDate,
          endDate,
          page: invoicesList.page,
          pageSize: invoicesList.paginationOptions.pageSize,
          accountType: selectedAccounts,
          activeVertical,
          authRequestPath,
          roles,
          isFirstLoad,
        }),
      );
    }
  }, [
    shouldNotFetchData,
    activeVertical,
    authRequestPath,
    canFetchData,
    dispatch,
    hasBillingManagerAccess,
    hasPaymentsManagerAccess,
    hasStatementsInvoicesReadOnlyAccess,
    headerStatus,
    roles,
    startDate,
    endDate,
    selectedAccounts,
    invoicesList.page,
    invoicesList.paginationOptions.pageSize,
    isFirstLoad,
    isUpsideManager,
  ]);
};

export const useFetchPayouts = () => {
  const dispatch = useAppDispatch();
  const canFetchData = useFetchDataCheck('payouts', false, false);
  const orgId = useAppSelector(selectUserOrg);
  const roles = useAppSelector(selectUserRoles);
  const activeVertical = useAppSelector(selectActiveVertical);
  const isUpsideManager = useAppSelector(selectIsUpsideManager);
  const authRequestPath = useAppSelector(selectAuthRequestPath);
  const startPayoutsDate = useAppSelector(selectPayoutsStartDate);
  const endPayoutsDate = useAppSelector(selectPayoutsEndDate);
  const selectedAccounts = useAppSelector(selectSelectedAccounts);
  const payoutsPagination = useAppSelector(selectPayoutsPagination);

  const { hasPayoutManagerAccess, hasPaymentsManagerAccess, hasPayoutsReadOnlyAccess } = usePermissions();

  const [dateRange] = useState({
    startPayoutsDate,
    endPayoutsDate,
  });

  useEffect(() => {
    if (isUpsideManager || hasPaymentsManagerAccess || hasPayoutManagerAccess || hasPayoutsReadOnlyAccess) {
      if (dateRange && canFetchData) {
        const payload = {
          startDate: startPayoutsDate.split('T')[0],
          endDate: endPayoutsDate.split('T')[0],
          page: payoutsPagination.page,
          pageSize: payoutsPagination.options.pageSize,
          authRequestPath,
          activeVertical,
          account_type: selectedAccounts,
          roles: roles,
        };
        dispatch(payoutsFetchRequest(payload));
      }
    }
  }, [
    orgId,
    roles,
    dateRange,
    payoutsPagination.page,
    payoutsPagination.options.pageSize,
    selectedAccounts,
    startPayoutsDate,
    endPayoutsDate,
    authRequestPath,
    activeVertical,
    hasPaymentsManagerAccess,
    hasPayoutManagerAccess,
    hasPayoutsReadOnlyAccess,
    isUpsideManager,
    canFetchData,
    dispatch,
  ]);
};

export const useFetchProcessorCredentials = (locationId: string) => {
  const [data, setData] = useState<ProcessorData | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const authRequestPath = useAppSelector(selectAuthRequestPath);
  const roles = useAppSelector(selectUserRoles);

  const { hasLocationsSettingsManagerAccess } = usePermissions();

  const fetchProcessorCredentials = useCallback(
    async (id: string) => {
      try {
        setIsLoading(true);
        const response = await getLocationProcessorCredentials({
          roles,
          locationId: id,
          authRequestPath,
        });

        setData(response[0]);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [authRequestPath, roles],
  );

  useEffect(() => {
    if (hasLocationsSettingsManagerAccess && locationId) {
      fetchProcessorCredentials(locationId);
    }
  }, [hasLocationsSettingsManagerAccess, locationId, fetchProcessorCredentials]);

  return { data, isLoading };
};

export const useFetchPayoutsMethods = () => {
  const dispatch = useAppDispatch();
  const headerStatus = useAppSelector(selectHeaderStatus);
  const canFetchData = useFetchDataCheck('payout methods', false, false);
  const orgId = useAppSelector(selectUserOrg);
  const vertical = useAppSelector(selectActiveVertical);
  const isUpsideManager = useAppSelector(selectIsUpsideManager);
  const { hasPaymentsManagerAccess, hasPayoutManagerAccess } = usePermissions();

  useEffect(() => {
    const whichPaymentRole = () => {
      let role = '';
      if (hasPaymentsManagerAccess) {
        role = 'PaymentsManager';
      }
      if (hasPayoutManagerAccess) {
        role = 'PayoutManager';
      }
      if (isUpsideManager) {
        return 'UpsideManager';
      }
      return role;
    };

    const payoutRole = whichPaymentRole();

    console.log('preparing to fetch payout methods');

    if (
      canFetchData &&
      headerStatus === Status.Idle &&
      (isUpsideManager || hasPaymentsManagerAccess || hasPayoutManagerAccess) &&
      payoutRole !== ''
    ) {
      console.log('fetching payout methods');
      dispatch(paymentsFetchRequest({ orgId, role: payoutRole, vertical }));
    }
  }, [
    orgId,
    vertical,
    hasPaymentsManagerAccess,
    hasPayoutManagerAccess,
    dispatch,
    isUpsideManager,
    canFetchData,
    headerStatus,
  ]);
};

export const useFetchCompetitors = ({
  mapVisualization,
  zoomLevel,
  visibleBounds,
}: {
  mapVisualization?: MapVisualizations;
  zoomLevel?: number;
  visibleBounds?: google.maps.LatLngBounds | null;
}) => {
  const dispatch = useAppDispatch();
  const headerStatus = useAppSelector(selectHeaderStatus);
  const canFetchData = useFetchDataCheck('competitors', false, false);
  const activeVertical = useAppSelector(selectActiveVertical);
  const authRequestPath = useAppSelector(selectAuthRequestPath);
  const selectedLocations = useAppSelector(selectSelectedLocations);
  const siteLevelPerformance = useAppSelector(selectSiteLevelPerformance);
  const selectedCompetitorTypes = useAppSelector(selectSelectedCompetitorTypes);

  const selectedLocationsInBounds = useMemo(() => {
    if (!visibleBounds) {
      return [];
    }

    if (!zoomLevel || zoomLevel < 9) {
      return [];
    }

    const locations = siteLevelPerformance.map.sites.filter((location) => selectedLocations.includes(location.uuid));
    return locations
      .filter((location) => {
        const locationLatLng = new google.maps.LatLng(location.latitude, location.longitude);
        return visibleBounds.contains(locationLatLng);
      })
      .map((location) => location.uuid);
  }, [selectedLocations, siteLevelPerformance.map.sites, visibleBounds, zoomLevel]);

  useEffect(() => {
    if (
      canFetchData &&
      headerStatus === Status.Idle &&
      activeVertical === Verticals.Fuel &&
      mapVisualization === MapVisualizations.Competitors &&
      !!selectedLocationsInBounds.length &&
      zoomLevel &&
      zoomLevel > 9 &&
      visibleBounds
    ) {
      const payload = {
        authRequestPath,
        locations: selectedLocationsInBounds,
        competitorType: selectedCompetitorTypes,
      };
      dispatch(competitorsFetch(payload));
    }
  }, [
    activeVertical,
    authRequestPath,
    canFetchData,
    dispatch,
    headerStatus,
    mapVisualization,
    selectedCompetitorTypes,
    selectedLocationsInBounds,
    zoomLevel,
    visibleBounds,
  ]);
};

export const useFetchNotificationSettings = () => {
  const dispatch = useAppDispatch();
  const authRequestPath = useAppSelector(selectAuthRequestPath);
  const headerStatus = useAppSelector(selectHeaderStatus);
  const canFetchData = useFetchDataCheck('notificationSettings', false, false);

  useEffect(() => {
    if (canFetchData && headerStatus === Status.Idle) {
      dispatch(notificationSettingsFetchRequest({ authRequestPath }));
    }
  }, [authRequestPath, canFetchData, dispatch, headerStatus]);
};

export const useFetchLocationSettings = () => {
  const dispatch = useAppDispatch();
  const headerStatus = useAppSelector(selectHeaderStatus);
  const canFetchData = useFetchDataCheck('locationSettings', false, false);
  const roles = useAppSelector(selectUserRoles);
  const orgId = useAppSelector(selectUserOrg);
  const activeVertical = useAppSelector(selectActiveVertical);

  useEffect(() => {
    if (canFetchData && headerStatus === Status.Idle) {
      dispatch(locationsSettingsFetchRequest({ roles, orgId, activeVertical }));
    }
  }, [activeVertical, canFetchData, dispatch, headerStatus, orgId, roles]);
};

export const useFetchBillingMethods = () => {
  const dispatch = useAppDispatch();
  const headerStatus = useAppSelector(selectHeaderStatus);
  const canFetchData = useFetchDataCheck('billingMethods', false, false);
  const authRequestPath = useAppSelector(selectAuthRequestPath);
  const roles = useAppSelector(selectUserRoles);

  useEffect(() => {
    if (canFetchData && headerStatus === Status.Idle) {
      dispatch(billingPaymentMethodsFetchRequest({ authRequestPath, roles }));
    }
  }, [authRequestPath, canFetchData, dispatch, headerStatus, roles]);
};
