import { ChangeEvent, KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Box, Flex, Text } from '@chakra-ui/react';
import { DataLoadingErrorPanel } from '@components/errorAlert';
import { EmptyDataAlertPanel } from '@components/errorAlert';
import { Pagination } from '@components/pagination';
import { AsyncPaginationContainer } from '@components/pagination/AsyncPaginationContainer';
import { PaginationProps } from '@components/pagination/types';
import { IStat } from '@components/stat';
import { Table } from '@components/table';
import { ITableCellValue, ITableData } from '@components/table/Table';
import _ from 'lodash';

import { SearchInput } from '@/common/components/searchInput/SearchInput';
import { Stats } from '@/common/components/stats';
import { Status, Verticals } from '@/common/interfaces';

import { selectActiveVertical, selectAuthRequestPath, selectUserOrg, selectUserRoles } from '../../auth/userSlice';
import {
  selectContentStatus,
  selectDashboardStatus,
  selectEndDate,
  selectPerformanceSummary,
  selectSelectedLocations,
  selectSelectedNetworks,
  selectSelectedTransactionType,
  selectStartDate,
} from '../../dashboard/slice';

import { EmptySearchResults } from './EmptySearchResults';
import {
  allCustomersSummaryFetch,
  customersFetch,
  selectAllCustomersSummary,
  selectAllCustomersSummaryStatus,
  selectCustomerSearchQuery,
  selectCustomersList,
  selectCustomersListStatus,
  setCustomerListPage,
  setCustomerListPaginationOptions,
  setCustomerSearchQuery,
} from './slice';

export const CustomerDeepDive = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dashboardStatus = useAppSelector(selectDashboardStatus);
  const contentStatus = useAppSelector(selectContentStatus);
  const performanceSummary = useAppSelector(selectPerformanceSummary);
  const dispatch = useAppDispatch();
  const [searchInput, setSearchInput] = useState('');
  const customerSearchQuery = useAppSelector(selectCustomerSearchQuery);
  const customerList = useAppSelector(selectCustomersList);
  const customerListStatus = useAppSelector(selectCustomersListStatus);
  const orgId = useAppSelector(selectUserOrg);
  const roles = useAppSelector(selectUserRoles);
  const activeVertical = useAppSelector(selectActiveVertical);
  const authRequestPath = useAppSelector(selectAuthRequestPath);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const selectedNetworks = useAppSelector(selectSelectedNetworks);
  const selectedTransactionType = useAppSelector(selectSelectedTransactionType);
  const selectedLocations = useAppSelector(selectSelectedLocations);
  const allCustomersSummary = useAppSelector(selectAllCustomersSummary);
  const allCustomersSummaryStatus = useAppSelector(selectAllCustomersSummaryStatus);

  const fetchAllCustomersSummary = useCallback(
    (startDate: string, endDate: string, networkNames: string[], transactionType: string, locations: string[]) => {
      if (
        !_.isEmpty(startDate) &&
        !_.isEmpty(endDate) &&
        !_.isEmpty(orgId) &&
        !_.isEmpty(activeVertical) &&
        !_.isEmpty(roles)
      ) {
        const payload = {
          startDate,
          endDate,
          networkNames,
          transactionType,
          authRequestPath,
          activeVertical,
          locations,
        };
        dispatch(allCustomersSummaryFetch(payload));
      }
    },
    [orgId, activeVertical, roles, dispatch, authRequestPath],
  );

  const fetchCustomersList = useCallback(
    (
      startDate: string,
      endDate: string,
      query = '',
      page: number = customerList.page,
      pageSize: number = customerList.paginationOptions.pageSize,
      networkNames: string[],
      transactionType: string,
      locations: string[],
    ) => {
      if (
        !_.isEmpty(startDate) &&
        !_.isEmpty(endDate) &&
        !_.isEmpty(orgId) &&
        !_.isEmpty(activeVertical) &&
        !_.isEmpty(roles)
      ) {
        const payload = {
          startDate,
          endDate,
          query,
          page,
          pageSize,
          networkNames,
          transactionType,
          authRequestPath,
          activeVertical,
          locations,
        };
        dispatch(customersFetch(payload));
      }
    },
    [orgId, activeVertical, roles, authRequestPath, dispatch],
  );

  useEffect(() => {
    setSearchInput(customerSearchQuery);
  }, []);

  useEffect(() => {
    if (orgId !== '' && !_.isEmpty(roles) && !_.isEmpty(activeVertical) && startDate && endDate) {
      fetchAllCustomersSummary(startDate, endDate, selectedNetworks, selectedTransactionType, selectedLocations);
    }
  }, [
    orgId,
    roles,
    startDate,
    endDate,
    fetchAllCustomersSummary,
    selectedNetworks,
    activeVertical,
    selectedTransactionType,
    selectedLocations,
  ]);

  useEffect(() => {
    if (orgId !== '' && !_.isEmpty(roles) && !_.isEmpty(activeVertical) && startDate && endDate) {
      fetchCustomersList(
        startDate,
        endDate,
        customerSearchQuery,
        customerList.page,
        customerList.paginationOptions.pageSize,
        selectedNetworks,
        selectedTransactionType,
        selectedLocations,
      );
    }
  }, [
    orgId,
    roles,
    startDate,
    endDate,
    customerSearchQuery,
    customerList.page,
    customerList.paginationOptions.pageSize,
    fetchCustomersList,
    selectedNetworks,
    selectedTransactionType,
    activeVertical,
    selectedLocations,
  ]);

  function handlePageChange(page: number): void {
    dispatch(setCustomerListPage(page));
  }

  function handlePageSizeChange(pageSize: number): void {
    dispatch(
      setCustomerListPaginationOptions(Object.assign({}, customerList.paginationOptions, { pageSize: pageSize })),
    );
  }

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };

  const handleSearchInputKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      dispatch(setCustomerSearchQuery(searchInput));
    }
  };

  const handleClearSearch = () => {
    setSearchInput('');
    dispatch(setCustomerSearchQuery(''));
  };

  const displayCodeLabels = activeVertical === Verticals.Grocery;

  let stats: IStat[] | null = null;
  const customerStatsLoading =
    allCustomersSummaryStatus === Status.Loading || contentStatus === Status.Loading ? Status.Loading : Status.Idle;
  if (allCustomersSummary && performanceSummary) {
    stats = [
      {
        label: t('customerDeepDivePage.home.totalUpsideCustomers'),
        value: allCustomersSummary.totalUpsideCustomers || '',
        help: t('customerDeepDivePage.home.tooltips.totalUpsideCustomers'),
      },
      {
        label: t('customerDeepDivePage.home.totalUpsideCustomerTxns'),
        value: performanceSummary.reachTransIncremTabs?.transactionsStats?.totalUpsideTransactions || '',
        help: t('customerDeepDivePage.home.tooltips.totalUpsideCustomerTxns'),
      },
    ];
  }

  if (dashboardStatus === Status.Loading) {
    return null;
  }

  const handleClick = (row: Array<string | number | ITableCellValue>) => {
    if (typeof row[0] === 'object' && row[0].rawValue) {
      navigate(`/explore/customerDeepDive/customer?userUuid=${row[0].rawValue}`);
    }
  };

  if (customerListStatus === Status.Idle && !searchInput && customerList.customers.table?.rows.length === 0) {
    return <EmptyDataAlertPanel />;
  }

  return (
    <Box padding={4}>
      {customerListStatus === Status.Failed ? (
        <DataLoadingErrorPanel />
      ) : (
        <>
          <Stats stats={stats} status={customerStatsLoading} />

          <Flex flexDirection='row' width='100%' justifyContent={'flex-start'} gap={4}>
            <Box as='label' flexDir={'row'} gap={4}>
              <Text mb={2}>
                {t(`customerDeepDivePage.home.${displayCodeLabels ? 'codeSearchLabel' : 'searchLabel'}`)}
              </Text>
              <SearchInput
                searchTerm={searchInput}
                handleSearch={handleSearchInputChange}
                setSearchTerm={setSearchInput}
                handleClosePopover={handleClearSearch}
                handleSearchInputKeyPress={handleSearchInputKeyPress}
                placeholder={t(
                  `customerDeepDivePage.home.${displayCodeLabels ? 'searchExampleWithRefCode' : 'searchExampleWithId'}`,
                )}
              />
            </Box>
          </Flex>
          <Box>
            <AsyncPaginationContainer
              data={customerList.customers.table?.rows || []}
              page={customerList.page}
              total={customerList.customers?.count}
              prevPage={customerList.customers?.prevPage}
              nextPage={customerList.customers?.nextPage}
              isLoading={customerListStatus === Status.Loading}
              options={customerList.paginationOptions}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            >
              {(props: PaginationProps) => {
                const { data } = props;
                const tableData: ITableData = Object.assign({}, customerList.customers?.table, {
                  rows: data,
                });

                let content = <Box height='400px' />;
                if (customerListStatus === Status.Idle && data.length === 0 && customerSearchQuery) {
                  content = <EmptySearchResults query={customerSearchQuery} />;
                } else if (data.length > 0) {
                  content = (
                    <>
                      <Table data={tableData} onClick={handleClick} />
                      <Pagination {...props} />
                    </>
                  );
                }

                return content;
              }}
            </AsyncPaginationContainer>
          </Box>
        </>
      )}
    </Box>
  );
};
