import { useTranslation } from 'react-i18next';
import { Heading, Stack, StackProps, useBreakpointValue } from '@chakra-ui/react';

import { LoginButton } from './LoginButton';
import { LogoBlue } from './UpsideLogo';

export const SignInForm = (props: StackProps) => {
  const { t } = useTranslation();
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Stack spacing='8' {...props}>
      {isMobile && <LogoBlue />}
      <Stack textAlign='center'>
        <Heading size='xl'>{t('loginPage.form.title')}</Heading>
      </Stack>
      <Stack spacing='6'>
        <Stack spacing='4'>
          <LoginButton />
        </Stack>
      </Stack>
    </Stack>
  );
};
