import { ChangeEvent } from 'react';
import { Box, Select } from '@chakra-ui/react';
import _ from 'lodash';

import { IDropdownMenuProps } from './types';

export const ChartDropdownMenu = (menuProps: IDropdownMenuProps) => {
  const { selectedItem, items, itemSelector, size = 'sm', disabled = false } = menuProps;

  const menuItems = items
    ? items.map((item) => (
        <option key={item.value} value={item.value} data-testid={`option-${item.value}`}>
          {item.label}
        </option>
      ))
    : [];

  return !_.isEmpty(menuItems) ? (
    <Box data-testid='chartdropdown-menu-wrapper' borderColor={'border.default'}>
      <Select
        size={size}
        width='auto'
        borderRadius={'lg'}
        value={selectedItem}
        onChange={(event: ChangeEvent<HTMLSelectElement>) => {
          if (itemSelector) {
            itemSelector(event.target.value);
          }
        }}
        data-testid='chartdropdown-menu'
        _active={{ borderColor: 'border.primary' }}
        _disabled={{ bgColor: 'background.disabled.default', borderColor: 'border.disabled', color: 'text.disabled' }}
        disabled={disabled}
      >
        {menuItems}
      </Select>
    </Box>
  ) : null;
};
