import { z } from 'zod';

export const addBillingMethodFormSchema = z.object({
  site_uuid: z.optional(z.string()),
  account_nickname: z.string().min(1, 'Account nickname is required '),
  account_holder_name: z.string().min(1, 'Account holder name is required '),
  account_holder_type: z.string().min(1, 'Please select account holder type'),
  user_accept_terms: z.literal(true, {
    errorMap: () => ({ message: 'You must authorize Upside access to your account in order to proceed ' }),
  }),
  default: z.optional(z.boolean()),
});

export const manageBillingBankAccountSchema = z.object({
  account_nickname: z.string().min(1, 'Nickname cannot be empty'),
  account_holder_name: z.string().min(1, 'Account holder name cannot be empty'),
  account_holder_type: z.string().min(1, 'Account holder type cannot be empty'),
  site_uuid: z.optional(z.string()),
  default: z.optional(z.boolean()),
});
