import React from 'react';
import ReactDOM from 'react-dom';
import { Translation } from 'react-i18next';
import { Box, Flex, Grid, GridItem, Heading } from '@chakra-ui/react';

import { Verticals } from '@/common/interfaces';

import { displayCurrency, displayNumber } from '../../utils';

type MapLegendProps = {
  map: google.maps.Map;
  min: number;
  max: number;
  colorMax: string;
  colorMiddle: string;
  colorMin: string;
  activeVertical: string;
  transactionType: string;
};

class MapLegend extends React.Component<MapLegendProps> {
  el: HTMLElement;

  constructor(props: MapLegendProps) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    this.props.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(this.el);
  }

  componentWillUnmount() {
    const controls = this.props.map.controls[google.maps.ControlPosition.LEFT_BOTTOM];
    let foundAt = -1;
    controls.forEach((control, index: number) => {
      if (control === this.el) {
        foundAt = index;
      }
    });
    if (foundAt > -1) {
      controls.removeAt(foundAt);
    }
  }

  render() {
    const { colorMin, colorMiddle, colorMax, min, max } = this.props;
    return ReactDOM.createPortal(
      <Box p='2' bg='rgba(255, 255, 255, 0.5)' marginLeft={2} borderRadius={'base'}>
        <Heading fontSize={'18px'} fontWeight={400} lineHeight={'28px'} marginBottom={'8px'}>
          <Translation>
            {(t) =>
              this.props.activeVertical === Verticals.Fuel && this.props.transactionType !== 'CONVENIENCE_STORE'
                ? t('components.map.legendGallons')
                : t('components.map.legend')
            }
          </Translation>
        </Heading>
        <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(4, 1fr)' gap={2}>
          <GridItem rowSpan={2} colSpan={1}>
            <Box
              bgGradient={`linear(to-b, ${colorMin}, ${colorMiddle}, ${colorMax})`}
              height={115}
              width={3}
              borderRadius={'base'}
            ></Box>
          </GridItem>
          <GridItem colSpan={3}>
            <Flex h={50} alignItems={'flex-start'}>
              {this.props.activeVertical === Verticals.Fuel && this.props.transactionType !== 'CONVENIENCE_STORE'
                ? displayNumber(max)
                : displayCurrency(max, 0)}
            </Flex>
          </GridItem>
          <GridItem colSpan={3}>
            <Flex h={50} alignItems={'flex-end'}>
              {this.props.activeVertical === Verticals.Fuel && this.props.transactionType !== 'CONVENIENCE_STORE'
                ? displayNumber(min)
                : displayCurrency(min, 0)}
            </Flex>
          </GridItem>
        </Grid>
      </Box>,
      this.el,
    );
  }
}

export { MapLegend };
