import { FiArrowDownRight, FiArrowUpRight } from 'react-icons/fi';
import { InfoIcon } from '@chakra-ui/icons';
import { Badge, Flex, Heading, HStack, Icon, Stack, Text, Tooltip, useBreakpointValue } from '@chakra-ui/react';
import _ from 'lodash';

import { IStat } from './Stat';

export const StatContent = ({ label, value, help, delta, ...boxProps }: IStat) => {
  return (
    <Stack {...boxProps} flex='1'>
      <Flex gap={3}>
        <Text fontSize='16px' fontWeight={500} lineHeight='24px'>
          {label}
        </Text>
        {_.isEmpty(help) ? null : (
          <Tooltip label={help} placement='right' data-testid='tooltip-container'>
            <InfoIcon color={'neutral.400'} w={4} h={4} mt={1} data-testid='stat-content-tooltip-info-icon' />
          </Tooltip>
        )}
      </Flex>

      <HStack mt={0} justify='space-between'>
        <Heading
          color={'neutral.1200'}
          fontSize='24px'
          fontWeight={600}
          style={{ fontFeatureSettings: `'ss01'` }}
          size={useBreakpointValue({ base: 'md', md: 'lg' })}
        >
          {value ? value.toLocaleString('en-US') : null}
        </Heading>

        {_.isEmpty(delta) ? null : (
          <Badge borderRadius='md' colorScheme={delta.isUpwardsTrend ? 'green' : 'red'}>
            <HStack spacing='1'>
              {/* TODO: we could possibly make this automated based on positive/negative values, then have the prop become optional */}
              <Icon as={delta.isUpwardsTrend ? FiArrowUpRight : FiArrowDownRight} />
              <Text>{delta.value}</Text>
            </HStack>
          </Badge>
        )}
      </HStack>
    </Stack>
  );
};
