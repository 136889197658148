import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@app/hooks';
import { ChakraStyledOptions, Flex, Heading, Text } from '@chakra-ui/react';

import { selectEndDate, selectStartDate } from '@/features/dashboard/slice';

import { displayDate } from '../../utils';

import { AltMessage } from './EmptyDataAlertPanel';

export interface EmptyDataAlertProps {
  boxProps?: ChakraStyledOptions;
  altMessage?: AltMessage | null;
}

export const EmptyDataAlert: React.FC<EmptyDataAlertProps> = ({ boxProps, altMessage }) => {
  const { t } = useTranslation();
  const start = useAppSelector(selectStartDate);
  const end = useAppSelector(selectEndDate);

  if (!start || !end) {
    return null;
  }

  const startDate = displayDate(start);
  const endDate = displayDate(end);

  const title = altMessage ? t(altMessage.title) : t('components.emptyDataAlert.title');
  const message = altMessage ? t(altMessage.message) : t('components.emptyDataAlert.info', { startDate, endDate });

  return (
    <Flex flexDirection='column' justifyContent='center' alignItems='center' height='400px' {...boxProps}>
      <Heading fontSize='18px' lineHeight='28px'>
        {title}
      </Heading>
      <Text fontSize='14px' lineHeight='17px' width='25rem' textAlign='center'>
        {message}
      </Text>
    </Flex>
  );
};
