import { Box, Flex, useCheckbox } from '@chakra-ui/react';

import { TCheckboxCardProps } from './types';

export function CheckboxCard({ color, ...props }: TCheckboxCardProps) {
  const { getInputProps, getCheckboxProps } = useCheckbox(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as='label'>
      <input {...input} />
      <Flex
        {...checkbox}
        align='center'
        justify='center'
        gap={2}
        cursor='pointer'
        borderRadius='full'
        px={3}
        py={1}
        fontSize='sm'
        _checked={{ backgroundColor: 'neutral.100' }}
        data-testid='checkboxCard'
        _disabled={{
          cursor: 'default',
          borderColor: 'neutral.300',
          borderWidth: '1px',
        }}
      >
        <Box h={3} w={3} bg={color} borderRadius='full' data-testid='checkboxCard-box' />
        {props.children}
      </Flex>
    </Box>
  );
}
