import { CSSProperties, useCallback, useMemo, useRef, useState } from 'react';
import { FiInfo } from 'react-icons/fi';
import { FixedSizeList } from 'react-window';
import { ChevronDownIcon, ChevronUpIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Button,
  CheckboxGroup,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { t } from 'i18next';

import { LocationsCheckbox } from '@/features/filter/components/locationsFilter/LocationsCheckbox';

export function AccountDropdown({
  availableAccounts,
  handleFilter,
}: {
  availableAccounts: string[];
  handleFilter: (accounts: string[]) => void;
}) {
  const listRef = useRef<FixedSizeList>(null);
  const { onOpen, onClose, onToggle, isOpen } = useDisclosure();

  const [searchTerm, setSearchTerm] = useState('');
  const [localSelection, setLocalSelection] = useState<string[]>(() => {
    if (availableAccounts.length) return [];
    return availableAccounts;
  });

  const filteredAccounts = useMemo(() => {
    if (!availableAccounts.length) {
      return {
        list: [],
        count: 0,
      };
    }
    const filtered = availableAccounts
      .filter((a) => a !== null) // Drop null values
      .filter((account: string) => {
        return !!account.toLowerCase().includes(searchTerm.toLowerCase());
      });

    return { list: filtered, count: filtered.length };
  }, [availableAccounts, searchTerm]);

  const selectAllCount = useMemo(() => {
    return !!filteredAccounts.count && !!searchTerm ? filteredAccounts.count : 0;
  }, [filteredAccounts.count, searchTerm]);

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }, []);

  const handleSelectAccount = useCallback((account: string[]) => {
    setLocalSelection(account);
  }, []);

  const handleToggleSelectAll = useCallback(() => {
    setLocalSelection((previousSelection) => {
      if (previousSelection.length === filteredAccounts.count) {
        return [];
      }

      const allAccounts = filteredAccounts.list.map((account) => account);
      return allAccounts;
    });
  }, [filteredAccounts]);

  const onFilter = useCallback(() => {
    handleFilter(localSelection);

    onClose();
  }, [localSelection, onClose, handleFilter]);

  const maxAccounts = useMemo(() => {
    if (availableAccounts.length - 1 === filteredAccounts.list.length) {
      return 'max';
    }
    return 'other';
  }, [filteredAccounts, availableAccounts]);

  const handleClosePopover = useCallback(() => {
    setSearchTerm('');

    onClose();
  }, [onClose]);

  return (
    <Popover
      placement='bottom-start'
      variant='responsive'
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={handleClosePopover}
      isLazy
    >
      <PopoverTrigger>
        <Button onClick={onToggle} variant='accentSecondary' size='md' borderRadius='lg'>
          <Text data-testid='location-selector-title' ml='10px' mr={1} lineHeight={0} color='black' fontWeight='400'>
            {t(`common.accountsSelector.title.${maxAccounts}`) as string}{' '}
          </Text>
          {isOpen ? <Icon as={ChevronUpIcon} fontWeight={500} /> : <Icon as={ChevronDownIcon} fontWeight={500} />}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        minW='300px'
        maxW='310px'
        width='100%'
        border='1px solid'
        borderColor='border.subtle'
        borderRadius='lg'
        boxShadow='none'
        bg='background.default'
        px={3}
        py={2}
      >
        <PopoverHeader border='none' mb='4'>
          <InputGroup mb='5'>
            <InputLeftElement pointerEvents='none'>
              <SearchIcon color='black' />
            </InputLeftElement>
            <Input
              borderRadius='md'
              borderColor='gray.500'
              type='text'
              placeholder={t('common.accountsSelector.placeholder')}
              _placeholder={{
                color: 'gray.400',
              }}
              value={searchTerm}
              onChange={handleSearch}
            />
          </InputGroup>
          <Flex fontSize='sm' align='center' justify='space-between'>
            <Text color='gray.400'>
              {!searchTerm
                ? (t('common.accountsSelector.defaultSearchText') as string)
                : (t('common.accountsSelector.searchText', {
                    count: filteredAccounts.count,
                    searchTerm,
                  }) as string)}
            </Text>
            {!!filteredAccounts.count && (
              <Link fontSize='sm' fontWeight={500} onClick={handleToggleSelectAll}>
                {filteredAccounts.count === localSelection.length
                  ? (t('common.accountsSelector.deselectAll', { count: selectAllCount }) as string)
                  : (t('common.accountsSelector.selectAll', {
                      count: selectAllCount,
                    }) as string)}
              </Link>
            )}
          </Flex>
        </PopoverHeader>
        <PopoverBody maxH='260px' mb='4'>
          <CheckboxGroup value={localSelection} onChange={handleSelectAccount}>
            {!!filteredAccounts.list.length && (
              <FixedSizeList ref={listRef} height={254} width={315} itemCount={filteredAccounts.count} itemSize={52}>
                {({ index, style }: { index: number; style: CSSProperties }) => {
                  const account = filteredAccounts.list[index];
                  return (
                    <LocationsCheckbox
                      key={account}
                      style={style}
                      value={account}
                      label={account}
                      highlight={searchTerm}
                    />
                  );
                }}
              </FixedSizeList>
            )}
          </CheckboxGroup>
          {!filteredAccounts.list.length && !!searchTerm && (
            <Flex gap={2} align='flex-start' justify='center' data-testid='no-result'>
              <Icon as={FiInfo} />
              <Text color='gray.700' fontSize='sm'>
                {t('common.accountsSelector.noResults', { searchTerm }) as string}
              </Text>
            </Flex>
          )}
        </PopoverBody>
        <PopoverFooter>
          <Button fontWeight={400} w='full' variant='accentSecondary' size='md' onClick={onFilter}>
            {t('common.accountsSelector.filterButton', { count: localSelection.length }) as string}
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}
