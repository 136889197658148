import { ITableCellValue, ITableData } from '@components/table/Table';
import { endOfMonth, format, isBefore, isWithinInterval, parse } from 'date-fns';
import { filter, orderBy } from 'lodash';

import { Verticals } from '@/common/interfaces';
import { display2Decimals, displayCurrency } from '@/common/utils';
import i18n from '@/i18n';

import { IStatementsData } from './types';

const filterDataByDate = (data: IStatementsData[], startDate: string, endDate: string): IStatementsData[] => {
  const start = parse(startDate, 'yyyy-MM-dd', new Date());
  const end = endOfMonth(parse(endDate, 'yyyy-MM-dd', new Date()));

  const interval = { start, end };

  return filter(data, (item) => {
    const itemDate = parse(`${item.period}-01`, 'yyyy-MM-dd', new Date());
    return isWithinInterval(itemDate, interval);
  });
};

export const buildStatementsTable = ({
  data,
  activeVertical,
  startDate,
  endDate,
}: {
  data: IStatementsData[];
  activeVertical: Verticals;
  startDate: string;
  endDate: string;
}): ITableData => {
  const filteredData = filterDataByDate(data, startDate, endDate);
  const sortedRecords = orderBy(filteredData, ['period'], 'desc');
  const isFuel = activeVertical === Verticals.Fuel;

  const historicalCutOff = new Date(2020, 0);

  const rows: Array<string | number | ITableCellValue>[] = sortedRecords.map((item: IStatementsData) => {
    const period = format(parse(item.period, 'yyyy-MM', new Date()), 'MMM yyyy');

    const isBeforeHistoricalCutOff = isBefore(new Date(item.period), historicalCutOff);

    return [
      { value: period },
      ...(isFuel
        ? [{ value: isBeforeHistoricalCutOff ? '-' : display2Decimals(item.incrementalGallons, 2), isNumeric: true }]
        : []),
      { value: isBeforeHistoricalCutOff ? '-' : displayCurrency(item.incrementalProfit), isNumeric: true },
      { value: displayCurrency(item.payment), isNumeric: true },
      {
        value:
          item.totalCharges < 0
            ? `(${displayCurrency(Math.abs(item.totalCharges))})`
            : `${displayCurrency(item.totalCharges)}`,
        isNumeric: true,
      },
      {
        value: '',
        invoiceUuid: item.period,
        popoverLink: true,
      },
    ];
  });

  const result: ITableData = {
    headers: [
      { columnName: i18n.t('statementsPage.statementsTbl.statementPeriod') },
      ...(isFuel
        ? [{ columnName: i18n.t('statementsPage.statementsTbl.statementIncrementalGallons'), isNumeric: true }]
        : []),
      { columnName: i18n.t('statementsPage.statementsTbl.statementIncrementalProfit'), isNumeric: true },
      { columnName: i18n.t('statementsPage.statementsTbl.statementPayments'), isNumeric: true },
      { columnName: i18n.t('statementsPage.statementsTbl.statementTotalCharges'), isNumeric: true },
      { columnName: i18n.t('statementsPage.statementsTbl.statementActions') },
    ],
    rows: rows,
  };

  return result;
};
