import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Box, Button, Text } from '@chakra-ui/react';

import { AccountRegistration, ContactInfo, LoginDetails } from './steps';

export function RegistrationStart({ step }: { step: number }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (isNaN(Number(step)) || Number(step) <= 1 || !step) {
      navigate('/login');
      return;
    }

    navigate(`/registration/${Number(step) - 1}`);
  };

  return (
    <Box>
      <Button
        leftIcon={<ChevronLeftIcon fontSize={23} />}
        mb={2}
        px={0}
        variant='accentTertiary'
        size='md'
        onClick={handleGoBack}
      >
        {t('registrationPage.actions.previous')}
      </Button>

      <Text color='text.primary.default'>{t('registrationPage.stepper', { step })}</Text>
      <Routes>
        <Route path='1' element={<AccountRegistration />} />
        <Route path='2' element={<ContactInfo />} />
        <Route path='3' element={<LoginDetails />} />
      </Routes>
    </Box>
  );
}
