/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import { Box, Flex } from '@chakra-ui/react';

import { ExpansionPanel } from '@/common/components/expansionPanel';

export const ROICalculator = ({ incrementalNetProfit, totalPromotions, returnOnInvestment }: any) => {
  const { t } = useTranslation();
  return (
    <Box w={'100%'} p={4} mb={4}>
      <ExpansionPanel header={`${t('homePage.ROICalculator.label')} ${returnOnInvestment} ${t('roi')}?`}>
        <Box mb={4}>{t('homePage.ROICalculator.text')}</Box>
        <Flex alignItems={'center'} justifyContent={'space-around'} mb={4}>
          <Box w={'40px'}>
            <strong>{t('roi')}</strong>
          </Box>
          <Box w={'30px'}>=</Box>
          <Box w={'200px'} textAlign={'center'}>
            {t('homePage.ROICalculator.incrementalProfit')}
            <hr />
            {t('homePage.ROICalculator.totalCosts')}
          </Box>
          <Box w={'30px'}>x</Box>
          <Box w={'50px'}>100%</Box>
        </Flex>
        <Flex alignItems={'center'} justifyContent={'space-around'} mb={4}>
          <Box w={'40px'}></Box>
          <Box w={'30px'}>=</Box>
          <Box w={'200px'} textAlign={'center'}>
            {incrementalNetProfit}
            <hr />
            {totalPromotions}
          </Box>
          <Box w={'30px'}>x</Box>
          <Box w={'50px'}>100%</Box>
        </Flex>
        <Flex alignItems={'center'} justifyContent={'space-around'}>
          <Box w={'40px'}></Box>
          <Box w={'30px'}>=</Box>
          <Box w={'200px'} textAlign={'center'}>
            {returnOnInvestment}
          </Box>
          <Box w={'30px'}></Box>
          <Box w={'50px'}></Box>
        </Flex>
      </ExpansionPanel>
    </Box>
  );
};
