import { Flex } from '@chakra-ui/react';

import { Status } from '../../interfaces';
import { LoadingSpinner } from '../loadingSpinner/LoadingSpinner';
import { IStat } from '../stat';
import { StatContent } from '../stat/StatContent';

import { IStats } from './Stats';

export const StatsGrid = ({ stats, status, ...boxProps }: IStats) => {
  let content = null;

  if (status === Status.Loading) {
    content = (
      <Flex
        flex={1}
        bgColor='white'
        padding={4}
        h={'100px'}
        minWidth='400px'
        justifyContent='center'
        alignItems='center'
      >
        <LoadingSpinner />
      </Flex>
    );
  } else if (stats) {
    content = stats.map((stat: IStat, id) => (
      <Flex key={id} flex={1} bgColor='background.whitecap.light' padding={4} minWidth='400px'>
        <StatContent {...stat} />
      </Flex>
    ));
  }

  // Color between cells can be set by the gap and bgColor properties
  // of the parent Flex element: `gap='1px' bgColor='neutral.200'`
  return (
    <Flex flexDirection='row' flexWrap='wrap' gap='1px' borderRadius='2xl' overflow='hidden' {...boxProps}>
      {content}
    </Flex>
  );
};
