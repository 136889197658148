import colors from '@/theme/foundations/colors';

export const MIN_MARKER_SCALE = 5;
export const MAX_MARKER_SCALE = 15;

export const LEGEND_COLORS = [
  { pct: 1.0, color: { r: 17, g: 117, b: 18 } },
  { pct: 0.5, color: { r: 218, g: 197, b: 31 } },
  { pct: 0.0, color: { r: 251, g: 61, b: 10 } },
];

export const ZOOM_LEVEL = 10.5;

export const COMPETITOR_COLORS = {
  BLOCKED_COMPETITORS: colors.orange['400'],
  UPSIDE: colors.cyan['400'],
  COMPETITORS: colors.yellow['200'],
};

export const MAP_STYLES = `
#topPerformanceMap .chakra-heading {
  font-size: 18px;
  font-weight: bold;
  padding: 8px 16px;
}
#topPerformanceMap .css-0 {
  font-size: 14px;
  background: ${colors.neutral[50]};
}
#topPerformanceMap .css-0:nth-child(odd) {
  background:  ${colors.neutral[100]};
}
#topPerformanceMap td {
  padding: 8px 16px;
  text-align: left;
}
#topPerformanceMap td:nth-child(even) {
  text-align: right;
}
`;
