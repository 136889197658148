import { Box, Text } from '@chakra-ui/react';
import { Section } from '@components/section';

export const Support = () => {
  return (
    <>
      <Box maxW='100%' mt='-10'>
        <Section name='supportPage.needHelp' />
        <Text mt={4}>
          Reach out to{' '}
          <Text as='span' color='blue.800'>
            businesshelp@upside.com
          </Text>{' '}
          if you&apos;re having trouble accessing your Dashboard
        </Text>
      </Box>
    </>
  );
};
