import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Checkbox,
  Flex,
  FormControl,
  Input,
  Select,
  Text,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import axios, { AxiosError } from 'axios';
import { z } from 'zod';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { DataLoadingErrorPanel } from '@/common/components/errorAlert';
import { CustomFormLabel } from '@/common/components/form';
import { LoadingSpinner } from '@/common/components/loadingSpinner';
import { Section } from '@/common/components/section';
import { Status } from '@/common/interfaces';
import { selectActiveMerchantUuid, selectAuthRequestPath, selectUserRoles } from '@/features/auth/userSlice';

import { selectBillingLoadingStatus, updateBillingMethodRequest } from '../slice';
import { manageBillingBankAccountSchema } from '../validators';

type FormSchemaType = z.infer<typeof manageBillingBankAccountSchema>;

export const ManageBillingBankAccount = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const toast = useToast();

  const roles = useAppSelector(selectUserRoles);
  const merchant_uuid = useAppSelector(selectActiveMerchantUuid);
  const authRequestPath = useAppSelector(selectAuthRequestPath);
  const { id: payment_method_id } = useParams<{ id: string }>();
  const loadingStatus = useAppSelector(selectBillingLoadingStatus);

  const paymentMethod = useAppSelector((state) => state.billing.methods.bank_accounts.find((item) => item.id === id));

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormSchemaType>({
    resolver: zodResolver(manageBillingBankAccountSchema),
    defaultValues: {
      account_nickname: paymentMethod?.metadata.nickname,
      account_holder_name: paymentMethod?.holderName,
      account_holder_type: paymentMethod?.holderType,
      site_uuid: paymentMethod?.metadata.siteUuid,
      default: paymentMethod?.isDefault,
    },
  });

  const onSubmit: SubmitHandler<FormSchemaType> = async (formData) => {
    try {
      if (!payment_method_id) {
        toast({
          title: 'No payment method selected',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
        return;
      }
      if (paymentMethod) {
        const data = {
          merchant_uuid: merchant_uuid,
          nickname: formData.account_nickname,
          is_default: formData.default as boolean,
          site_uuid: formData.site_uuid,
          account_holder_name: formData.account_holder_name,
          account_holder_type: formData.account_holder_type,
        };
        dispatch(updateBillingMethodRequest({ authRequestPath, roles, payment_method_id, data }));
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<{ message?: string }>;
        toast({
          title: 'Error',
          description: axiosError.response?.data?.message ?? 'Something went wrong',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <>
      <Breadcrumb separator={<ChevronRightIcon color='gray.500' />}>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to='/settings/payments'>
            {t('payment.billingPaymentMethods.addBankAccount.breadcrumbs.payments')}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink as={Link} isCurrentPage to='#'>
            {t('payment.billingPaymentMethods.addBankAccount.breadcrumbs.manage')}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Section name='Manage account'>
        {loadingStatus === Status.Loading && (
          <Flex alignItems='center' justifyContent='center' mt={10}>
            <LoadingSpinner />
          </Flex>
        )}

        {loadingStatus === Status.Failed && (
          <Flex alignItems='center' justifyContent='center'>
            <DataLoadingErrorPanel />
          </Flex>
        )}

        {loadingStatus === Status.Idle && (
          <FormControl as='form' onSubmit={handleSubmit(onSubmit)} mt={6} maxWidth={'600px'}>
            <Flex direction='column' gap={3}>
              <CustomFormLabel htmlFor='account_nickname'>
                {t('payment.managePayment.nickname')}
                <Input id='account_nickname' type='text' {...register('account_nickname')} />
              </CustomFormLabel>
              {errors.account_nickname && <Text variant={'error'}>{errors.account_nickname.message}</Text>}

              <CustomFormLabel htmlFor='account_holder_name'>
                {t('payment.managePayment.holderName')}
                <Input id='account_holder_name' type='text' {...register('account_holder_name')} />
              </CustomFormLabel>
              {errors.account_holder_name && <Text variant={'error'}>{errors.account_holder_name.message}</Text>}

              <CustomFormLabel htmlFor='account_holder_type'>
                {t('payment.managePayment.holderType')}
                <Select
                  id='account_holder_type'
                  placeholder='Choose account holder type'
                  {...register('account_holder_type', { required: true })}
                  isInvalid={errors.account_holder_type && errors.account_holder_type?.message !== ''}
                >
                  <option value='company'>{t('payment.addBankForm.company')}</option>
                  <option value='individual'>Individual</option>
                </Select>
              </CustomFormLabel>
              {errors.account_holder_type && <Text variant={'error'}>{errors.account_holder_type.message}</Text>}

              <CustomFormLabel htmlFor='site_uuid'>
                {t('payment.managePayment.site_uuid')}
                <Input id='site_uuid' type='text' {...register('site_uuid', { required: true })} />
              </CustomFormLabel>
              {errors.site_uuid && <Text variant={'error'}>{errors.site_uuid.message}</Text>}
            </Flex>

            <Checkbox
              id='default'
              mt={3}
              alignItems={'baseline'}
              {...register('default', { required: true })}
              data-testid='acceptTerms'
              isDisabled={paymentMethod?.isDefault}
            >
              {t('payment.billingPaymentMethods.default')}
            </Checkbox>

            <Flex mt={6} gap={3}>
              <Button as={Link} to='/settings/payments' variant='accentSecondary' size='md' px={12}>
                {t('payment.addPayout.back')}
              </Button>
              <Button variant='brandPrimary' size='md' type='submit' isDisabled={isSubmitting} px={12}>
                {t('payment.addPayout.save')}
              </Button>
            </Flex>
          </FormControl>
        )}
      </Section>
    </>
  );
};
