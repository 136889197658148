import { useTranslation } from 'react-i18next';
import { Box, Heading, Stack } from '@chakra-ui/react';
import { ResponsivePie } from '@nivo/pie';
import _ from 'lodash';

import { Status } from '../../interfaces';
import { getTooltipFormatter } from '../../utils';
import { EmptyDataAlert } from '../errorAlert';
import { LoadingSpinner } from '../loadingSpinner/LoadingSpinner';

import { IPieProps } from './types';

export const PieChart = ({
  data,
  loadingStatus,
  title,
  height = '450px',
  width = '100%',
  isCurrency = false,
  isPercentage = false,
  customProps,
}: IPieProps) => {
  const { t } = useTranslation();
  const tooltipFormatter = getTooltipFormatter(isCurrency, isPercentage);
  const isLoading = loadingStatus === Status.Loading;
  const isEmptyData = loadingStatus === Status.Idle && _.isEmpty(data);

  const commonProperties = {
    margin: { top: 40, bottom: 40, left: 0, right: 180 },
    data,
    animate: true,
    activeOuterRadiusOffset: 8,
    valueFormat: (rawValue: string | number) => tooltipFormatter(Number(rawValue)),
    ...customProps,
  };

  return (
    <Box data-testid='pie-chart' h={height} w={width} maxW={'100%'}>
      <Heading size='md' opacity={isLoading ? '20%' : '100%'}>
        {t(`${title}`)}
      </Heading>
      <Stack
        data-testid='pie-chart-container'
        w={'100%'}
        h={'100%'}
        align={isLoading || isEmptyData ? 'center' : 'default'}
        justify={isLoading || isEmptyData ? 'center' : 'default'}
      >
        {isLoading && <LoadingSpinner />}
        {isEmptyData && (
          <Box data-testid='empty-data-alert'>
            <EmptyDataAlert />
          </Box>
        )}
        {!isEmptyData && !isLoading && (
          <Box data-testid='responsive-pie' w='full' h='full'>
            <ResponsivePie {...commonProperties} />{' '}
          </Box>
        )}
      </Stack>
    </Box>
  );
};
