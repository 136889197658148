import { CSSProperties, Fragment, useMemo } from 'react';
import { Checkbox, Flex, Text } from '@chakra-ui/react';

type TLocationsCheckboxProps = {
  value: string;
  highlight: string;
  label: string;
  style?: CSSProperties;
};

export function LocationsCheckbox({ value, highlight, label, style }: TLocationsCheckboxProps) {
  const highlightedText = useMemo(() => {
    const parts = label.split(new RegExp(`(${highlight})`, 'gi'));
    return parts.map((part: string, index: number) => (
      <Fragment key={index}>{part.toLowerCase() === highlight?.toLowerCase() ? <b>{part}</b> : part}</Fragment>
    ));
  }, [label, highlight]);

  return (
    <Flex style={style} w='full' align='center' justify='space-between' data-testid='location-checkbox'>
      <Checkbox alignItems='start' name={value} value={value} lineHeight='shorter'>
        <Text lineHeight='5'>{highlightedText}</Text>
      </Checkbox>
    </Flex>
  );
}
