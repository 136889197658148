import i18n from '@/i18n';

import { IBehavior, IBehaviorRawData } from './types';

export function formatData(data: IBehaviorRawData): IBehavior {
  const { pre_post_vs_control, pre_post_on_off, incremental_impact } = data;

  const formattedData = {
    prePostChart: {
      metrics: pre_post_vs_control.metrics,
      sales: {},
      transactions: {},
    },
    onOffChart: {
      metrics: pre_post_on_off.metrics,
      sales: {},
    },
    incrementalImpactChart: {
      sales: {},
      transactions: {},
      walkthrough: {},
    },
  } as IBehavior;

  Object.entries(pre_post_vs_control.sales).forEach(([key, value]) => {
    const beforeUpside = {
      'Similar Customer': value.control.pre,
      'Upside Customer': value.customer.pre,
      label: `${i18n.t('behaviorPage.dataLabels.beforeUpside')}`,
    };

    const afterUpside = {
      'Similar Customer': value.control.post,
      'Upside Customer': value.customer.post,
      label: `${i18n.t('behaviorPage.dataLabels.afterUpside')}`,
    };

    Object.assign(formattedData.prePostChart.sales, {
      [key]: {
        id: 'prePostChart',
        data: [beforeUpside, afterUpside],
      },
    });
  });

  Object.entries(pre_post_vs_control.transactions).forEach(([key, value]) => {
    const beforeUpside = {
      'Similar Customer': value.control.pre,
      'Upside Customer': value.customer.pre,
      label: `${i18n.t('behaviorPage.dataLabels.beforeUpside')}`,
    };

    const afterUpside = {
      'Similar Customer': value.control.post,
      'Upside Customer': value.customer.post,
      label: `${i18n.t('behaviorPage.dataLabels.afterUpside')}`,
    };

    Object.assign(formattedData.prePostChart.transactions, {
      [key]: {
        id: 'prePostChart',
        data: [beforeUpside, afterUpside],
      },
    });
  });

  Object.entries(pre_post_on_off.sales).forEach(([key, value]) => {
    const beforeUpside = {
      'Non-Upside': value.non_upside_sales.pre,
      'Upside sales': value.upside_sales.pre,
      label: `${i18n.t('behaviorPage.dataLabels.beforeUpside')}`,
    };

    const afterUpside = {
      'Non-Upside': value.non_upside_sales.post,
      'Upside sales': value.upside_sales.post,
      label: `${i18n.t('behaviorPage.dataLabels.afterUpside')}`,
    };

    Object.assign(formattedData.onOffChart.sales, {
      [key]: {
        id: 'onOffChart',
        data: [beforeUpside, afterUpside],
      },
    });
  });

  Object.entries(incremental_impact.sales).forEach(([key, value]) => {
    if (key === 'relative_months') {
      const controlValues = value.control.map((item) => ({ x: item.index, y: item.value, key: item.key }));

      const control = {
        id: 'relativeMonthsControl',
        data: controlValues,
      };

      const customerValues = value.customer.map((item) => ({ x: item.index, y: item.value, key: item.key }));

      const customer = {
        id: 'relativeMonthsCustomer',
        data: customerValues,
      };

      Object.assign(formattedData.incrementalImpactChart.sales, {
        relativeMonths: [customer, control],
      });
    }

    if (key === 'calendar_months') {
      const controlValues = value.control.map((item) => ({ x: item.index, y: item.value, key: item.key }));

      const control = {
        id: 'calendarMonthsControl',
        data: controlValues,
      };

      const customerValues = value.customer.map((item) => ({ x: item.index, y: item.value, key: item.key }));

      const customer = {
        id: 'calendarMonthsCustomer',
        data: customerValues,
      };

      Object.assign(formattedData.incrementalImpactChart.sales, {
        calendarMonths: [customer, control],
      });
    }
  });

  Object.entries(incremental_impact.transactions).forEach(([key, value]) => {
    if (key === 'relative_months') {
      const controlValues = value.control.map((item) => ({ x: item.index, y: item.value, key: item.key }));

      const control = {
        id: 'relativeMonthsControl',
        data: controlValues,
      };

      const customerValues = value.customer.map((item) => ({ x: item.index, y: item.value, key: item.key }));

      const customer = {
        id: 'relativeMonthsCustomer',
        data: customerValues,
      };

      Object.assign(formattedData.incrementalImpactChart.transactions, {
        relativeMonths: [customer, control],
      });
    }

    if (key === 'calendar_months') {
      const controlValues = value.control.map((item) => ({ x: item.index, y: item.value, key: item.key }));

      const control = {
        id: 'calendarMonthsControl',
        data: controlValues,
      };

      const customerValues = value.customer.map((item) => ({ x: item.index, y: item.value, key: item.key }));

      const customer = {
        id: 'calendarMonthsCustomer',
        data: customerValues,
      };

      Object.assign(formattedData.incrementalImpactChart.transactions, {
        calendarMonths: [customer, control],
      });
    }
  });

  Object.entries(incremental_impact.sales).forEach(([key, value]) => {
    if (key === 'relative_months') {
      const controlLeftValues = value.control.map((item) => {
        if (item.index <= -1) return { x: item.index, y: item.value, key: item.key };
        else return { x: item.index, y: null, key: item.key };
      });

      const customerLeftValues = value.customer.map((item) => {
        if (item.index <= -1) return { x: item.index, y: item.value, key: item.key };
        else return { x: item.index, y: null, key: item.key };
      });

      const controlRightValues = value.control.map((item) => {
        if (item.index < -1) return { x: item.index, y: null, key: item.key };
        else return { x: item.index, y: item.value, key: item.key };
      });

      const customerRightValues = value.customer.map((item) => {
        if (item.index < -1) return { x: item.index, y: null, key: item.key };
        else return { x: item.index, y: item.value, key: item.key };
      });

      const controlLeft = {
        id: 'controlLeft',
        data: controlLeftValues,
      };

      const controlRight = {
        id: 'controlRight',
        data: controlRightValues,
      };

      const customerLeft = {
        id: 'customerLeft',
        data: customerLeftValues,
      };

      const customerRight = {
        id: 'customerRight',
        data: customerRightValues,
      };

      Object.assign(formattedData.incrementalImpactChart.walkthrough, {
        relativeMonths: [customerLeft, controlLeft, customerRight, controlRight],
      });
    }
  });

  return formattedData;
}
