import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import { ENV } from '../config/env';

import { resources } from './i18n.translations';

export const defaultNS = 'translation';

i18n.use(initReactI18next).init({
  lng: 'en',
  ns: defaultNS,
  defaultNS: defaultNS,
  resources: resources,
  debug: ENV === 'prod' ? false : false, // leave as is, if actively developing i18 content, switch to `false : true` during local dev
});

export default i18n;
