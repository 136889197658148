const textStyles = {
  body: {
    md: {
      fontSize: 'md',
      letterSpacing: 'sm',
      lineHeight: '11xl',
    },
    sm: {
      fontSize: 'sm',
      letterSpacing: 'sm',
      lineHeight: '12xl',
    },
    xs: {
      fontSize: 'xs',
      letterSpacing: 'sm',
      lineHeight: '12xl',
    },
  },
  heading: {
    jumbo: {
      fontFamily: 'heading',
      fontSize: '6xl',
      fontWeight: 'bold',
      letterSpacing: '2xs',
      lineHeight: '4xl',
    },
    xxl: {
      fontFamily: 'heading',
      fontSize: '5xl',
      fontWeight: 'bold',
      letterSpacing: '2xs',
      lineHeight: '5xl',
    },
    xl: {
      fontFamily: 'heading',
      fontSize: '4xl',
      fontWeight: 'bold',
      letterSpacing: '2xs',
      lineHeight: '4xl',
    },
    lg: {
      fontFamily: 'heading',
      fontSize: '2xl',
      fontWeight: 'bold',
      letterSpacing: '2xs',
      lineHeight: '5xl',
    },
    md: {
      fontFamily: 'heading',
      fontSize: 'xl',
      fontWeight: 'bold',
      letterSpacing: '2xs',
      lineHeight: '6xl',
    },
    sm: {
      fontFamily: 'heading',
      fontSize: 'lg',
      fontWeight: 'bold',
      letterSpacing: '2xs',
      lineHeight: '5xl',
    },
  },
  subheader: {
    jumbo: {
      fontSize: '4xl',
      letterSpacing: '2xs',
      lineHeight: '5xl',
    },
    xxl: {
      fontSize: '3xl',
      letterSpacing: 'xs',
      lineHeight: '6xl',
    },
    xl: {
      fontSize: '2xl',
      letterSpacing: 'xs',
      lineHeight: '7xl',
    },
    lg: {
      fontSize: 'xsxl',
      letterSpacing: 'xs',
      lineHeight: '7xl',
    },
    md: {
      fontSize: 'lg',
      letterSpacing: 'xs',
      lineHeight: '8xl',
    },
    sm: {
      fontSize: 'md',
      letterSpacing: 'xs',
      lineHeight: '8xl',
    },
  },
  overline: {
    lg: {
      fontSize: 'md',
      letterSpacing: 'sm',
      lineHeight: '11xl',
    },
    md: {
      fontSize: 'sm',
      letterSpacing: 'sm',
      lineHeight: '12xl',
    },
    sm: {
      fontSize: 'xs',
      letterSpacing: 'sm',
      lineHeight: '12xl',
    },
  },
};

export default textStyles;
