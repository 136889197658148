import { Box } from '@chakra-ui/react';
import { t } from 'i18next';

import { displayNumber } from '../../utils';

import { PaginationProps } from './types';

export const PaginationInfo = (props: PaginationProps) => {
  const { pageStartIdx, pageEndIdx, totalItems: totalItemsNum } = props;
  let totalItems = '';
  if (totalItemsNum) {
    totalItems = displayNumber(totalItemsNum);
  }

  return !totalItemsNum ? null : (
    <Box data-testid='pagination-info' fontWeight={500} whiteSpace={'nowrap'}>
      {t('locationsPage.paginationInfo', { pageStartIdx, pageEndIdx, totalItems }) as string}
    </Box>
  );
};
