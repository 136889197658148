import React from 'react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Button, ButtonProps } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';

import { DatePickerBackBtnProps } from '../types';

const DefaultBtnStyle: ButtonProps = {
  variant: 'ghost',
  size: 'sm',
};

export const DatePickerBackBtn: React.FC<DatePickerBackBtnProps> = (props) => {
  const { calendars, getBackProps } = props;
  const customBtnProps = props.propsConfigs?.dateNavBtnProps;
  return (
    <Button {...getBackProps({ calendars })} {...DefaultBtnStyle} {...customBtnProps}>
      <Icon as={ChevronLeftIcon} height='24px' width='24px' />
    </Button>
  );
};
