import _ from 'lodash';

export const formatCurrencyUSD = (options: Intl.NumberFormatOptions = {}) =>
  Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'USD',
    ...options,
  });

export const displayCurrencyFuel = (number: number | string): string => {
  let value = _.round(typeof number == 'string' ? parseFloat(number) : number, 2);
  if (1 / value === -Infinity) {
    value = 0;
  }
  return `${(value * 100).toFixed()}¢`;
};

export const displayCurrency = (number: number | string, maximumFractionDigits = 2): string => {
  let value = typeof number == 'string' ? parseFloat(number) : number;
  if (1 / value === -Infinity) {
    value = 0;
  }
  const options = { maximumFractionDigits };
  return formatCurrencyUSD(options).format(value);
};
