import { cssVar, defineStyleConfig } from '@chakra-ui/system';

const $arrowBg = cssVar('popper-arrow-bg');
const $arrowShadowColor = cssVar('popper-arrow-shadow-color');

const PopoverStyles = defineStyleConfig({
  baseStyle: {
    content: {
      bg: 'background.whitecap.light',
      border: 'none',
    },
    header: {
      textStyle: 'heading.md',
      fontSize: 'md',
      border: 'none',
    },
    body: {
      textStyle: 'body.sm',
    },
    footer: {
      border: 'none',
      textStyle: 'body.sm',
      px: 0,
    },
  },
  variants: {
    default: {
      content: {
        px: 6,
        py: 4,
        borderRadius: 'lg',
      },
      closeButton: {
        top: 4,
        right: 4,
        fontWeight: 'bold',
      },
    },
    dark: {
      content: {
        px: 6,
        py: 4,
        borderRadius: 'lg',
        bg: 'background.overlay',
        color: 'text.default-inverse',
        borderColor: 'border.dark',
        [$arrowBg.variable]: '#181819',
        [$arrowShadowColor.variable]: '1px 1px #181819',
      },
      closeButton: {
        top: 4,
        right: 4,
        fontWeight: 'bold',
      },
    },
  },
  defaultProps: {
    variant: 'dark',
  },
});

export default PopoverStyles;
