import { Trans, useTranslation } from 'react-i18next';
import { Flex, Heading, Link, Text } from '@chakra-ui/react';

import { Card } from '../card';

export const PermissionErrorPanel = () => {
  const { t } = useTranslation();
  return (
    <Card height='347px' margin='0 1rem' padding={{ base: 0, lg: '0 106px' }}>
      <Flex flexDirection='column' justifyContent='center' alignItems='center' height='100%'>
        <Heading fontSize='18px' lineHeight='28px'>
          {t('components.permissionError.title')}
        </Heading>
        <Text
          fontSize='14px'
          lineHeight='17px'
          width='25rem'
          textAlign='center'
          data-testid='permissionErrorPanel-text'
        >
          <Trans i18nKey='components.permissionError.info'>
            This could be a temporary issue, try reaching out to
            <Link href='mailto:businesshelp@upside.com' target='_blank' color={'blue.400'}>
              businesshelp@upside.com
            </Link>
            if you believe this is a mistake
          </Trans>
        </Text>
      </Flex>
    </Card>
  );
};
