import { RootState } from '@app/store';
import { PaginationOptions } from '@components/pagination/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format } from 'date-fns';

import { Status } from '@/common/interfaces';
import { startOfMonth } from '@/common/utils/dates';

import { IPayoutsList } from './types';

const today = new Date();
const currMonthStart = startOfMonth(today);
const prevMonthStart = new Date(currMonthStart);
prevMonthStart.setMonth(prevMonthStart.getMonth() - 1);
const last6MonthsStart = new Date(prevMonthStart);
last6MonthsStart.setMonth(last6MonthsStart.getMonth() - 5);
const last6MonthsEnd = today;

export interface PayoutsState {
  payouts: IPayoutsList;
  pagination: {
    page: number;
    options: PaginationOptions;
  };
  status: Status.Idle | Status.Loading | Status.Failed;
  error?: string;
  startDate: string;
  endDate: string;
  selectedEntities: string[];
}

export const initialState: PayoutsState = {
  payouts: {
    nextPage: null,
    prevPage: null,
    table: {
      rows: [],
    },
    rawData: [],
    count: 0,
    billableEntities: [],
  },
  pagination: {
    page: 1,
    options: {
      pageSize: 5,
      availablePageSizes: [5, 10, 50, 100, 250],
    },
  },
  status: Status.Idle,
  selectedEntities: [],
  startDate: format(last6MonthsStart, 'yyyy-MM-dd'),
  endDate: format(last6MonthsEnd, 'yyyy-MM-dd'),
};

export const payoutsSlice = createSlice({
  name: 'payouts',
  initialState,
  reducers: {
    payoutsFetchRequest: (state) => {
      state.error = undefined;
      state.status = Status.Loading;
    },
    payoutsFetchSucceeded: (state, action: PayloadAction<IPayoutsList>) => {
      state.payouts = action.payload;
      state.status = Status.Idle;
    },
    payoutsFetchFailed: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.status = Status.Failed;
    },
    setPayoutsListPaginationOptions: (state, action: PayloadAction<PaginationOptions>) => {
      state.pagination.options = action.payload;
    },
    setPayoutsListPage: (state, action: PayloadAction<number>) => {
      state.pagination.page = action.payload;
    },
    setPayoutsDateRange: (state, action: PayloadAction<{ startDate: string; endDate: string }>) => {
      const { startDate, endDate } = action.payload;
      state.startDate = startDate;
      state.endDate = endDate;
    },
    setSelectedAccounts: (state, action: PayloadAction<string[]>) => {
      state.selectedEntities = action.payload;
    },
  },
});

export const {
  payoutsFetchFailed,
  payoutsFetchRequest,
  payoutsFetchSucceeded,
  setPayoutsListPaginationOptions,
  setPayoutsListPage,
  setPayoutsDateRange,
  setSelectedAccounts,
} = payoutsSlice.actions;

export const selectPayoutsList = (state: RootState) => state.payouts.payouts;
export const selectPayoutsListStatus = (state: RootState) => state.payouts.status;
export const selectPayoutsStartDate = (state: RootState) => state.payouts.startDate;
export const selectPayoutsEndDate = (state: RootState) => state.payouts.endDate;
export const selectAvailableAccounts = (state: RootState) => state.payouts.payouts.billableEntities;
export const selectSelectedAccounts = (state: RootState) => state.payouts.selectedEntities;
export const selectPayoutsPagination = (state: RootState) => state.payouts.pagination;

export default payoutsSlice.reducer;
