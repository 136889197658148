/* eslint-disable @typescript-eslint/no-explicit-any */
import { store } from '@app/store';
import { ITableCellValue, ITableData } from '@components/table/Table';
import { round } from 'lodash';

import { Verticals } from '@/common/interfaces';
import { display2Decimals, displayCurrency, displayNumber, displayPercent, divideIfNotZero } from '@/common/utils';
import i18n from '@/i18n';

import { ICSVData, ISitePerformanceStats, ISiteSummariesRawData } from './types';

export const formatData = (site_summaries: Array<ISiteSummariesRawData>) => {
  return {
    table: buildSitePerformanceTable(site_summaries),
    topSitesTable: buildTopPerformingSitesTable(site_summaries),
    topSitesLocationTable: buildTopPerformingSitesLocationsTable(site_summaries),
    topSitesLocationCSVData: buildTopPerformingSitesCSVData(site_summaries),
    map: buildSitePerformanceMap(site_summaries),
  };
};

export const buildSitePerformanceTable = (data: Array<ISiteSummariesRawData>): ITableData => {
  const rows: Array<string | number>[] = data.map((item: ISiteSummariesRawData) => {
    return [
      item.name,
      item.address,
      item.total_upside_tx,
      display2Decimals(item.total_upside_revenue, 2),
      display2Decimals(item.total_customer_promotion_cost, 2),
    ];
  });

  const result: ITableData = {
    title: 'Transactions, Revenue, and Cash Back by Store',
    headers: [
      'Site Name',
      'Site Address',
      'Total Upside Transactions',
      'Total Upside Revenue',
      'Cash Back Provided to Customers',
    ],
    rows: rows,
  };

  return result;
};

// siteLevelPerformance.topSitesLocationTable = buildTopPerformingSitesLocationsTable()
export const buildTopPerformingSitesLocationsTable = (data: Array<ISiteSummariesRawData>): ITableData => {
  const activeVertical = store.getState().user.activeVertical;
  const transactionType = store.getState().dashboard.selectedTransactionType;

  let result: ITableData = {
    headers: [],
    rows: [],
  };

  if (activeVertical) {
    data.sort(
      (a: ISiteSummariesRawData, b: ISiteSummariesRawData) => +b.total_upside_revenue - +a.total_upside_revenue,
    );

    const rows: Array<string | number | ITableCellValue>[] = data.map((item: ISiteSummariesRawData) => {
      const revenueFromUpside = parseFloat('' + item.total_upside_revenue);
      const totalSiteRevenue = parseFloat('' + item.total_site_revenue);
      const revenueShare = divideIfNotZero(revenueFromUpside, totalSiteRevenue);

      if (activeVertical === Verticals.Fuel) {
        return [
          item.address,
          item.name,
          // { value: displayNumber(item.exclusivity_zone / MILES_TO_METERS), isNumeric: true },
          { value: displayNumber(+item.total_upside_tx), isNumeric: true },
          ...(transactionType !== 'CONVENIENCE_STORE'
            ? [{ value: display2Decimals(+item.total_gallons_bought, 0), isNumeric: true }]
            : []),
          { value: display2Decimals(+item.incremental_gallons, 0), isNumeric: true },
          { value: displayCurrency(item.total_upside_revenue, 0), isNumeric: true },
          { value: displayPercent(revenueShare), isNumeric: true },
        ];
      } else if (activeVertical === Verticals.Restaurant) {
        return [
          item.address,
          item.name,
          { value: displayNumber(+item.total_upside_tx), isNumeric: true },
          { value: displayCurrency(item.total_upside_revenue, 0), isNumeric: true },
          { value: displayCurrency(item.incremental_revenue, 0), isNumeric: true },
          { value: displayCurrency(item.total_customer_promotion_cost, 0), isNumeric: true },
          { value: displayPercent(revenueShare), isNumeric: true },
        ];
      } else {
        // assume grocery as default case for now
        return [
          item.address,
          item.network_display_name ?? '',
          item.name,
          { value: displayNumber(+item.total_upside_tx), isNumeric: true },
          { value: displayCurrency(item.total_upside_revenue, 0), isNumeric: true },
          { value: displayCurrency(item.total_customer_promotion_cost, 0), isNumeric: true },
          { value: displayPercent(revenueShare), isNumeric: true },
        ];
      }
    });

    result = {
      headers: buildLocationsPageTableRowLabels(activeVertical, transactionType),
      rows: rows,
    };
  }

  return result;
};

export const buildTopPerformingSitesTable = (data: Array<ISiteSummariesRawData>): ITableData => {
  const activeVertical = store.getState().user.activeVertical;
  data.sort((a: ISiteSummariesRawData, b: ISiteSummariesRawData) => +b.total_upside_revenue - +a.total_upside_revenue);

  const rows: Array<string | number | ITableCellValue>[] = data.map((item: ISiteSummariesRawData) => {
    const revenueFromUpside = parseFloat('' + item.total_upside_revenue);
    const totalSiteRevenue = parseFloat('' + item.total_site_revenue);
    const revenueShare = revenueFromUpside / totalSiteRevenue;

    return [
      item.address,
      { value: displayNumber(+item.total_upside_tx), isNumeric: true },
      { value: displayCurrency(item.total_upside_revenue, 0), isNumeric: true },
      { value: totalSiteRevenue === 0 ? '-%' : displayPercent(revenueShare), isNumeric: true },
    ];
  });

  const result: ITableData = {
    headers: [
      i18n.t('dashboardPage.topPerformingSitesTbl.siteAddress') as string,
      i18n.t('dashboardPage.topPerformingSitesTbl.totalUpsideTxns') as string,
      i18n.t('dashboardPage.topPerformingSitesTbl.totalUpsideRevenue') as string,
      i18n.t(`dashboardPage.topPerformingSitesTbl.${activeVertical}.salesPercent`) as string,
    ],
    rows: rows,
  };

  return result;
};

export const buildTopPerformingSitesCSVData = (data: Array<ISiteSummariesRawData>): Array<ICSVData> => {
  const startDate = store.getState().dashboard.datePicker.startDate;
  const endDate = store.getState().dashboard.datePicker.endDate;
  const activeVertical = store.getState().user.activeVertical;
  const transactionType = store.getState().dashboard.selectedTransactionType;

  const sortedData = data.sort(
    (a: ISiteSummariesRawData, b: ISiteSummariesRawData) => +b.total_upside_revenue - +a.total_upside_revenue,
  );

  const csvData = sortedData.map((item: ISiteSummariesRawData) => {
    const revenueFromUpside = parseFloat('' + item.total_upside_revenue);
    const totalSiteRevenue = parseFloat('' + item.total_site_revenue);
    const revenueShare = displayPercent(parseFloat(divideIfNotZero(revenueFromUpside, totalSiteRevenue).toFixed(4)));

    const csvItem = {
      [i18n.t('dashboardPage.topPerformingSitesTbl.reportingStart') as string]: String(startDate),
      [i18n.t('dashboardPage.topPerformingSitesTbl.reportingEnd') as string]: String(endDate),
      [i18n.t('dashboardPage.topPerformingSitesTbl.siteAddress') as string]: item.address,

      ...(activeVertical === Verticals.Grocery && {
        [i18n.t('dashboardPage.topPerformingSitesTbl.networkName') as string]: item.network_display_name ?? '',
      }),

      [i18n.t('dashboardPage.topPerformingSitesTbl.siteName') as string]: item.name,
      [i18n.t('dashboardPage.topPerformingSitesTbl.totalUpsideTxns') as string]: Number(+item.total_upside_tx),

      ...(activeVertical === Verticals.Fuel &&
        transactionType !== 'CONVENIENCE_STORE' && {
          [i18n.t('dashboardPage.topPerformingSitesTbl.totalGallonsBought') as string]: Number(
            +item.total_gallons_bought,
          ),
          [i18n.t('dashboardPage.topPerformingSitesTbl.incrementalGallons') as string]: Number(
            +item.incremental_gallons,
          ),
        }),

      [i18n.t('dashboardPage.topPerformingSitesTbl.totalUpsideRevenue') as string]: parseFloat(
        String(item.total_upside_revenue),
      ).toFixed(2),
      ...(activeVertical === Verticals.Restaurant && {
        [i18n.t('dashboardPage.topPerformingSitesTbl.incrementalRevenue') as string]: parseFloat(
          String(item.incremental_revenue),
        ).toFixed(2),
      }),
      ...(activeVertical !== Verticals.Fuel && {
        [i18n.t('dashboardPage.topPerformingSitesTbl.totalCustomerPromotions') as string]: parseFloat(
          String(item.total_customer_promotion_cost),
        ).toFixed(2),
      }),
      [i18n.t('dashboardPage.topPerformingSitesTbl.totalSiteRevenue') as string]: parseFloat(
        String(item.total_site_revenue),
      ).toFixed(2),
      [i18n.t(`dashboardPage.topPerformingSitesTbl.${activeVertical}.salesPercent`) as string]: revenueShare,
    };

    return csvItem;
  });

  return csvData;
};

export const buildSitePerformanceMap = (data: Array<ISiteSummariesRawData>): ISitePerformanceStats => {
  const activeVertical = store.getState().user.activeVertical;

  return {
    sites: data.map((entry: ISiteSummariesRawData) => {
      const totalUpsideRevenue = parseFloat(String(entry.total_upside_revenue));
      const totalSiteRevenue = parseFloat(String(entry.total_site_revenue));

      if (activeVertical === Verticals.Fuel) {
        const totalGallonsBought = round(parseFloat(String(entry.total_gallons_bought)));
        return {
          uuid: entry.uuid,
          name: entry.name,
          address: entry.address,
          latitude: parseFloat(String(entry.geolocation.latitude)),
          longitude: parseFloat(String(entry.geolocation.longitude)),
          totalRevenue: totalUpsideRevenue,
          totalSiteRevenue,
          totalGallonsBought,
          percentOfLocationSales: divideIfNotZero(totalUpsideRevenue, totalSiteRevenue),
          totalTransactions: Number(entry.total_upside_tx),
          totalCustomerPromotions: Number(entry.total_customer_promotion_cost),
          incrementalGallons: +entry.incremental_gallons,
          exclusivityZone: entry.exclusivity_zone,
        };
      } else if (activeVertical === Verticals.Restaurant) {
        return {
          uuid: entry.uuid,
          name: entry.name,
          address: entry.address,
          latitude: parseFloat(String(entry.geolocation.latitude)),
          longitude: parseFloat(String(entry.geolocation.longitude)),
          totalRevenue: totalUpsideRevenue,
          totalSiteRevenue,
          percentOfCardSales: divideIfNotZero(totalUpsideRevenue, totalSiteRevenue), // Double check this
          totalTransactions: Number(entry.total_upside_tx),
          totalCustomerPromotions: Number(entry.total_customer_promotion_cost),
          incrementalRevenue: entry.incremental_revenue,
        };
      } else {
        // assume grocery as default case for now
        return {
          uuid: entry.uuid,
          name: entry.name,
          address: entry.address,
          latitude: parseFloat(String(entry.geolocation.latitude)),
          longitude: parseFloat(String(entry.geolocation.longitude)),
          totalRevenue: totalUpsideRevenue,
          totalSiteRevenue,
          percentOfLocationSales: divideIfNotZero(totalUpsideRevenue, totalSiteRevenue),
          totalTransactions: Number(entry.total_upside_tx),
          totalCustomerPromotions: Number(entry.total_customer_promotion_cost),
        };
      }
    }),
  };
};

const buildLocationsPageTableRowLabels = (activeVertical: Verticals, transactionType: string) => {
  if (activeVertical === Verticals.Fuel) {
    return [
      i18n.t(`locationsPage.${activeVertical}.rowLabels.0`) as string,
      i18n.t(`locationsPage.${activeVertical}.rowLabels.1`) as string,
      // i18n.t(`locationsPage.${activeVertical}.rowLabels.7`) as string,
      i18n.t(`locationsPage.${activeVertical}.rowLabels.2`) as string,
      ...(transactionType !== 'CONVENIENCE_STORE'
        ? [i18n.t(`locationsPage.${activeVertical}.rowLabels.5`) as string]
        : []),
      i18n.t(`locationsPage.${activeVertical}.rowLabels.3`) as string,
      i18n.t(`locationsPage.${activeVertical}.rowLabels.4`) as string,
      i18n.t(`locationsPage.${activeVertical}.rowLabels.6`) as string,
    ];
  }

  return [
    i18n.t(`locationsPage.${activeVertical}.rowLabels.0`) as string,
    i18n.t(`locationsPage.${activeVertical}.rowLabels.1`) as string,
    i18n.t(`locationsPage.${activeVertical}.rowLabels.2`) as string,
    i18n.t(`locationsPage.${activeVertical}.rowLabels.3`) as string,
    i18n.t(`locationsPage.${activeVertical}.rowLabels.4`) as string,
    i18n.t(`locationsPage.${activeVertical}.rowLabels.5`) as string,
    i18n.t(`locationsPage.${activeVertical}.rowLabels.6`) as string,
  ];
};
