import { RootState } from '@app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Status } from '@/common/interfaces';

import { IBehavior } from './types';

export interface IBehaviorState {
  data: IBehavior;
  status: Status.Idle | Status.Loading | Status.Failed;
  error?: string;
}

export const initialState: IBehaviorState = {
  data: {} as IBehavior,
  status: Status.Loading,
};

export const behaviorSlice = createSlice({
  name: 'behavior',
  initialState,
  reducers: {
    behaviorFetchRequest: (
      state,
      _action: PayloadAction<{
        authRequestPath: string;
        networkNames: string[];
        locations: string[];
      }>,
    ) => {
      state.error = undefined;
      state.status = Status.Loading;
    },
    behaviorSucceeded: (state, action: PayloadAction<IBehavior>) => {
      state.data = action.payload;
      state.status = Status.Idle;
    },
    behaviorFailed: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.status = Status.Failed;
    },
  },
});

export const { behaviorFetchRequest, behaviorSucceeded, behaviorFailed } = behaviorSlice.actions;

export const selectBehavior = (state: RootState) => state.behavior.data;
export const selectBehaviorStatus = (state: RootState) => state.behavior.status;

export default behaviorSlice.reducer;
