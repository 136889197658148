import { IStat } from '@components/stat';
import { Datum, Serie } from '@nivo/line';
import * as Sentry from '@sentry/react';
import { formatDateByTemporalUnit } from '@utils/dates';
import { buildDatumFromArray } from '@utils/graphing';
import { t } from 'i18next';
import _ from 'lodash';

import { TransactionTypes, Verticals } from '@/common/interfaces';
import { displayCurrency, displayNumber, displayPercent } from '@/common/utils';
import { displayCurrencyFuel } from '@/common/utils/currency';
import { TemporalUnit } from '@/features/dashboard/slice';
import colors from '@/theme/foundations/colors';

import { IBarGraph } from '../../dashboard/types';

import {
  CustomerSegments,
  IAverageAcceptedPromoDistributions,
  IPromoDatapointBar,
  IPromoDistribution,
  IPromosRawData,
  IRefinedPromos,
  PromotionTopMetrics,
  TTotalsPromotionsProps,
} from './types';

export const formatData = (
  rawData: IPromosRawData,
  temporalUnit: TemporalUnit,
  activeVertical: string,
  selectedTransactionType: string,
): IRefinedPromos => {
  let refinedData: IRefinedPromos = {};

  if (rawData) {
    try {
      refinedData = {
        promoDistributionStats: buildPromoStats(rawData.promo_stats, activeVertical, selectedTransactionType),
      };

      refinedData.promoDistBarGraph = buildOffersBar(
        rawData.promo_dist_bar_graph,
        rawData.min_max_offers,
        activeVertical,
        selectedTransactionType,
      );
      refinedData.promoAvgAcceptedLineGraph = buildOffersLine(rawData.promo_avg_accepted_line_graph, temporalUnit);
    } catch (error) {
      Sentry.captureException(error);
      console.error('Error: ', error);
    }
  } else {
    throw Error('empty result');
  }
  return refinedData;
};

// PROMOTIONS PAGE NEW

const buildPromoStats = (
  data: IPromosRawData['promo_stats'],
  activeVertical: string,
  selectedTransactionType: string,
) => {
  let statCards: Array<IStat> = [];

  const display = (value: number | string) => {
    if (activeVertical === Verticals.Fuel && selectedTransactionType !== TransactionTypes.CStore) {
      return displayCurrency(value);
    } else {
      return displayPercent(value);
    }
  };

  if (data) {
    const isEmptyData = _.every(data, (value) => _.isEmpty(value.toString()) || value === 0);
    statCards = [
      {
        label: 'New customers',
        value: isEmptyData ? '-' : display(data.new_customers),
        help: t(`promotionsPage.tooltip.${activeVertical}.newCustomers`),
      },
      {
        label: 'Infrequent customers',
        value: isEmptyData ? '-' : display(data.infrequent_customers),
        help: t(`promotionsPage.tooltip.${activeVertical}.infrequentCustomers`),
      },
      {
        label: 'Occasional customers',
        value: isEmptyData ? '-' : display(data.occasional_customers),
        help: t(`promotionsPage.tooltip.${activeVertical}.occasionalCustomers`),
      },
      {
        label: 'Regular customers',
        value: isEmptyData ? '-' : display(data.regular_customers),
        help: t(`promotionsPage.tooltip.${activeVertical}.regularCustomers`),
      },
    ];
  }
  return statCards;
};

const buildOffersLine = (
  data: IPromosRawData['promo_avg_accepted_line_graph'],
  temporalUnit: TemporalUnit,
): Array<Serie> => {
  const result: Array<Serie> = [];

  if (data) {
    result.push(
      {
        id: CustomerSegments.All,
        data: buildDatumFromArray(data.Total, temporalUnit),
      },
      {
        id: CustomerSegments.New,
        data: buildDatumFromArray(data.New, temporalUnit),
      },
      {
        id: CustomerSegments.Infrequent,
        data: buildDatumFromArray(data.Infrequent, temporalUnit),
      },
      {
        id: CustomerSegments.Occasional,
        data: buildDatumFromArray(data.Occasional, temporalUnit),
      },
      {
        id: CustomerSegments.Regular,
        data: buildDatumFromArray(data.Regular, temporalUnit),
      },
    );

    if (data.Margin) {
      result.push({
        id: CustomerSegments.Margin,
        data: buildDatumFromArray(data.Margin, temporalUnit),
        customProps: { strokeDasharray: '4, 6', strokeWidth: 2 },
      });
    }
  }

  return result;
};

const calculateBucketSize = (max: number, numOfBuckets: number) => {
  const toWholeNum = max * 100;
  const remainder = toWholeNum % numOfBuckets;
  let bucketSize = Math.floor(toWholeNum / numOfBuckets);

  if (remainder > 0) {
    bucketSize++;
  }
  return bucketSize;
};

// TODO: refactor me
const constructRangedDataSetBar = (
  rawDataset: IPromosRawData['promo_dist_bar_graph'],
  minMaxOffers: IPromosRawData['min_max_offers'],
  activeVertical: string,
  selectedTransactionType: string,
) => {
  if ((_.isEmpty(rawDataset) && !minMaxOffers?.min_offer && !minMaxOffers?.max_offer) || !rawDataset) {
    return [];
  }

  const filteredSegments = _.omit(rawDataset, ['Undetermined']);

  const defaultSegments = Object.keys(filteredSegments).reduce((acc, curr) => {
    return { ...acc, [curr]: { numOffers: 0 } };
  }, {});

  const DEFAULT_BUCKETS: IPromoDatapointBar[] = [
    {
      label: '0%',
      range: [0, 0],
      segments: { ...defaultSegments },
    },
    {
      label: '1-5%',
      range: [0.01, 0.05],
      segments: { ...defaultSegments },
    },
    {
      label: '6-10%',
      range: [0.06, 0.1],
      segments: { ...defaultSegments },
    },
    {
      label: '11-15%',
      range: [0.11, 0.15],
      segments: { ...defaultSegments },
    },
    {
      label: '16-20%',
      range: [0.16, 0.2],
      segments: { ...defaultSegments },
    },
  ];

  const DEFAULT_FUEL_BUCKETS: IPromoDatapointBar[] = [
    {
      label: '0¢',
      range: [0, 0.005],
      segments: { ...defaultSegments },
    },
    {
      label: '1¢ - 5¢',
      range: [0.005, 0.06],
      segments: { ...defaultSegments },
    },
    {
      label: '6¢ - 10¢',
      range: [0.06, 0.11],
      segments: { ...defaultSegments },
    },
    {
      label: '11¢ - 15¢',
      range: [0.11, 0.16],
      segments: { ...defaultSegments },
    },
    {
      label: '16¢ - 20¢',
      range: [0.16, 0.21],
      segments: { ...defaultSegments },
    },
    {
      label: '> 20¢',
      range: [0.21, Number.POSITIVE_INFINITY],
      segments: { ...defaultSegments },
    },
  ];

  let organizedDataset: IPromoDatapointBar[] = [];

  if (activeVertical === Verticals.Fuel && selectedTransactionType !== TransactionTypes.CStore) {
    organizedDataset = DEFAULT_FUEL_BUCKETS;
  } else {
    if ((minMaxOffers?.max_offer as number) > 0.12) {
      const NUM_BUCKETS = 6;
      const bucketSize = calculateBucketSize(minMaxOffers?.max_offer as number, NUM_BUCKETS);

      let start = 0;

      // build buckets
      for (let i = 0; i < NUM_BUCKETS; i++) {
        const end = start + bucketSize - 1;

        organizedDataset.push({
          label: start === 0 ? `0%` : `${start}-${end}%`,
          range: start === 0 ? [0, 0] : [start / 100, end / 100],
          segments: { ...defaultSegments },
        });

        start += start === 0 ? 1 : bucketSize;
      }
    } else {
      organizedDataset = DEFAULT_BUCKETS;
    }
  }

  if (activeVertical === Verticals.Grocery) {
    organizedDataset = _.filter(organizedDataset, (bucket) => bucket.label !== '0%');
  }
  const totals: TTotalsPromotionsProps = Object.entries(filteredSegments).reduce(
    (acc, [segment, values]) => ({
      ...acc,
      [segment]: {
        numOffers: values.reduce((total, curr) => total + Number(curr.num_offers), 0),
      },
    }),
    {} as TTotalsPromotionsProps,
  );

  organizedDataset.forEach((bucket) => {
    const filteredItems = Object.entries(filteredSegments).reduce((acc, [key, items]) => {
      const filteredItems = items.filter((item) => {
        if (activeVertical === Verticals.Fuel && selectedTransactionType !== TransactionTypes.CStore) {
          if (
            Number((item.total_promotion_cost / Number(item.total_gallons_bought)).toFixed(5)) >= bucket.range[0] &&
            Number((item.total_promotion_cost / Number(item.total_gallons_bought)).toFixed(5)) < bucket.range[1]
          ) {
            return true;
          }
        }

        if (activeVertical !== Verticals.Fuel || selectedTransactionType === TransactionTypes.CStore) {
          if (item.offer_percent >= bucket.range[0] && item.offer_percent <= bucket.range[1]) {
            return true;
          }
        }

        return false;
      });

      let amountSpent = 0;
      let cashBack = 0;
      let numOffers = 0;
      let totalGallonsBought = 0;
      let totalIncrementalProfit = 0;
      let totalProfit = 0;
      let offerPercent = 0;
      let totalRevenue = 0;
      let totalPromotionCost = 0;
      let totalUpsideFeeCost = 0;

      filteredItems.forEach((item) => {
        amountSpent += item.amount_spent;
        cashBack += item.cash_back_to_user;
        numOffers += item.num_offers;
        totalGallonsBought += item.total_gallons_bought || 0;
        totalIncrementalProfit += item.total_incremental_profit;
        totalProfit += item.total_profit;
        offerPercent += item.offer_percent;
        totalRevenue += item.total_revenue;
        totalPromotionCost += item.total_promotion_cost;
        totalUpsideFeeCost += item.upside_fee_cost;
      });

      return {
        ...acc,
        [key]: {
          amountSpent,
          cashBack,
          numOffers,
          totalGallonsBought,
          totalIncrementalProfit,
          totalProfit,
          offerPercent,
          totalRevenue,
          totalPromotionCost,
          totalUpsideFeeCost,
        },
      };
    }, {} as { [key: string]: { amountSpent: number; cashBack: number; numOffers: number; totalGallonsBought: number; totalIncrementalProfit: number; totalProfit: number; offerPercent: number; totalRevenue: number; totalPromotionCost: number; totalUpsideFeeCost: number } });

    bucket.segments = filteredItems;
  });

  const finalDataset = organizedDataset.map((bucket) => {
    const { label } = bucket;

    const finalSegments = Object.entries(bucket.segments).reduce(
      (
        acc,
        [
          key,
          {
            cashBack,
            amountSpent,
            numOffers,
            totalGallonsBought,
            totalIncrementalProfit,
            totalProfit,
            offerPercent,
            totalRevenue,
            totalPromotionCost,
            totalUpsideFeeCost,
          },
        ],
      ) => {
        let segmentName = key;

        if (key === 'Total') {
          segmentName = 'All';
        }

        if (key === 'Undetermined') {
          segmentName = 'Unsegmented';
        }

        const segmentKey = key as keyof TTotalsPromotionsProps;

        if (numOffers <= 0 || totals[segmentKey].numOffers <= 0) {
          return { ...acc, [segmentName]: 0 };
        }

        let avgMargin = '';
        let avgPromotion = '';

        const computeAvgPromotion = () => {
          if (activeVertical === Verticals.Grocery) return `${Number((cashBack / amountSpent) * 100).toFixed()}%`;
          if (activeVertical === Verticals.Fuel && selectedTransactionType !== TransactionTypes.CStore)
            return displayCurrencyFuel(Number((totalPromotionCost / totalGallonsBought).toFixed(4)));
          else return `${Number((totalPromotionCost / totalRevenue) * 100).toFixed()}%`;
        };

        const totalGalIsEmpty =
          totalGallonsBought === 0 &&
          activeVertical === Verticals.Fuel &&
          selectedTransactionType === TransactionTypes.Gas;
        if (totalRevenue === 0 || totalGalIsEmpty) {
          avgMargin = '0';
          avgPromotion = '0';
        } else {
          avgMargin =
            activeVertical === Verticals.Fuel && selectedTransactionType !== TransactionTypes.CStore
              ? displayCurrencyFuel(Number((totalProfit / totalGallonsBought).toFixed(4)))
              : `${Number((totalProfit / totalRevenue) * 100).toFixed()}%`;

          avgPromotion = computeAvgPromotion();
        }

        const netProfit = totalIncrementalProfit - totalPromotionCost - totalUpsideFeeCost;

        return {
          ...acc,
          [segmentName]: {
            numOffers: displayNumber(Number((numOffers / totals[segmentKey].numOffers).toFixed(4))),
            numOffersPerBucket: displayNumber(numOffers),
            avgMargin: avgMargin,
            avgPromotion: avgPromotion,
            avgROI: displayPercent(netProfit / (totalPromotionCost + totalUpsideFeeCost)),
            upsideFundedBoost: displayCurrency(99.99, 0),
            offerPercent: displayPercent(offerPercent),
            numGallons: displayNumber(_.round(Number(totalGallonsBought.toFixed(4)))),
          },
        };
      },
      {},
    );

    return { label, ...finalSegments };
  });

  return finalDataset;
};

// TODO: awaiting future ticket to build out other segments
// and enable the user to select up to 2 segments to display on the chart
// post P0 launch
const buildOffersBar = (
  data: IPromosRawData['promo_dist_bar_graph'],
  minMaxOffers: IPromosRawData['min_max_offers'],
  activeVertical: string,
  selectedTransactionType: string,
): IBarGraph[] => {
  let result: Array<IBarGraph> = [];

  if (data) {
    result = [
      {
        id: 'promotionDistribution',
        data: constructRangedDataSetBar(data, minMaxOffers, activeVertical, selectedTransactionType),
      },
    ];
  }

  return result;
};

// OFFERS OLD

export const buildOfferDistributionStats = (
  data: IPromoDistribution,
  activeVertical: string | Verticals,
  selectedTransactionType: string,
): PromotionTopMetrics => {
  const avgPromo =
    activeVertical !== Verticals.Fuel || selectedTransactionType === TransactionTypes.CStore
      ? displayPercent(data.average_accepted_offer)
      : displayCurrency(data.average_accepted_offer);

  const avgMargin =
    activeVertical !== Verticals.Fuel || selectedTransactionType === TransactionTypes.CStore
      ? displayPercent(data.average_gross_margin)
      : displayCurrency(data.average_gross_margin);

  const upsideFundedBoost = displayCurrency(data.upside_funded_boosts, 0);

  const result: PromotionTopMetrics = {
    avgMargin: `${avgMargin}`,
    avgPromo: `${avgPromo}`,
    upsideFundedBoost: `${upsideFundedBoost}`,
  };
  return result;
};

export const buildOfferDistributionLine = (
  data: Array<IAverageAcceptedPromoDistributions>,
  temporalUnit: TemporalUnit,
  activeVertical: string | Verticals,
): Array<Serie> => {
  const refinedDataOffers: Array<Datum> = data.map((item: IAverageAcceptedPromoDistributions) => {
    return {
      x: formatDateByTemporalUnit(item.period_start as string, temporalUnit),
      y: item.average_accepted_offer,
      key: 'average promotion',
    };
  });

  const result: Array<Serie> = [
    {
      key: 'avgPromotion',
      id: 'Average promotion',
      color: colors.blue[800],
      data: [...refinedDataOffers],
    },
  ];

  if (activeVertical === Verticals.Fuel) {
    const refinedDataMargin: Array<Datum> = data.map((item: IAverageAcceptedPromoDistributions) => {
      return {
        x: formatDateByTemporalUnit(item.period_start as string, temporalUnit),
        y: item.average_margin,
      };
    });

    result.push({
      key: 'avgMargin',
      id: 'Average margin',
      color: colors.orange[300],
      data: [...refinedDataMargin],
      customProps: { strokeDasharray: '4, 6', strokeWidth: 2 },
    });
  }

  return result;
};
