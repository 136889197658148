import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Center, Container, Divider, Flex, Grid, GridItem, Stack, Text, useBreakpointValue } from '@chakra-ui/react';

import { generateCopyrightYear } from '@/common/utils/utils';
import { Logo, LogoBlue } from '@/features/auth';

import { RegistrationStart } from './RegistrationStart';
export const Registration = () => {
  const { step } = useParams<{ step: string }>();
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { t } = useTranslation();

  const year = generateCopyrightYear();

  return (
    <>
      <Flex as='section' minW={'350px'} direction={{ base: 'column', lg: 'row' }} height='100vh' overflowY='auto'>
        {isDesktop ? (
          <Flex
            flex='1'
            bg={{ base: 'background.default', md: 'green.1000' }}
            borderRight={{ base: 'none', md: '1px solid' }}
            borderColor='neutral.200'
            justifyContent='space-between'
            color='on-accent'
            overflowY='auto'
            maxW={'320px'}
            w={{ md: '100%' }}
          >
            <Stack
              justify='space-between'
              flex='1'
              spacing='1'
              w={'100%'}
              py={{ base: '6', sm: '8' }}
              px={{ base: '2', sm: '2' }}
            >
              {isDesktop && <Logo width='100px' ml={4} />}
            </Stack>
          </Flex>
        ) : (
          <Flex justifyContent='space-between' w={{ md: '100%' }}>
            <Stack
              justify='space-between'
              flex='1'
              spacing='1'
              w={'100%'}
              py={{ base: '6', sm: '8' }}
              px={{ base: '2', sm: '2' }}
            >
              <LogoBlue width='100px' ml={4} />
            </Stack>
          </Flex>
        )}
        <Grid templateAreas={`"header" "content"`} gridTemplateRows={'auto 1fr'} flex={1}>
          <GridItem bg='neutral.50' area={'header'} pt={4}>
            <Flex w='full' justify='space-between' px={{ base: 4, lg: 8 }} flexWrap='wrap' py={4} gap={4}>
              <Flex alignItems={'center'} gap={2} />
            </Flex>
            <Center>
              <Divider w='95%' />
            </Center>
          </GridItem>
          <GridItem
            overflowY='auto'
            overflowX={{ base: 'hidden', md: 'auto' }}
            bg='neutral.50'
            area={'content'}
            py='4'
            px='8'
            flex='1'
          >
            <Container maxW='container.xl' px={0}>
              <RegistrationStart step={Number(step)} />
            </Container>
          </GridItem>
          <GridItem>
            <Flex alignItems='center' justifyContent='center' h='24' data-testid='lt-footer'>
              <Text color='text.default' textStyle='body.sm'>
                {t('loginPage.header.footer', { year })}
              </Text>
            </Flex>
          </GridItem>
        </Grid>
      </Flex>
    </>
  );
};
