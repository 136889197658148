import { format, isAfter, parse, subDays } from 'date-fns';

import { TemporalUnit } from '@/features/dashboard/slice';

import { Verticals } from '../interfaces';

const now = new Date();
const DEFAULT_DAY_DIFF_GROCERY = 30;
const DEFAULT_DAY_DIFF = 90;
const oneDayInMs = 1000 * 60 * 60 * 24;

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const WEEK = 7 * DAY;
const MONTH = 30 * DAY;
const YEAR = 365 * DAY;

const DATE_FORMAT = 'yyyy-MM-dd';

const RELATIVE_TIME_INTERVALS: {
  ge: number;
  divisor: number;
  unit: Intl.RelativeTimeFormatUnit;
}[] = [
  { ge: YEAR, divisor: YEAR, unit: 'year' },
  { ge: MONTH, divisor: MONTH, unit: 'month' },
  { ge: WEEK * 2, divisor: WEEK, unit: 'week' },
  { ge: DAY, divisor: DAY, unit: 'day' },
  { ge: HOUR, divisor: HOUR, unit: 'hour' },
  { ge: MINUTE, divisor: MINUTE, unit: 'minute' },
  { ge: 0 * SECOND, divisor: SECOND, unit: 'seconds' },
];

/*
String dates are expected to be in the format of yyyy-mm-dd
Example: 2022-10-23 (October 23rd, 2022)
*/

export const today = format(now, DATE_FORMAT);

export const AGGREGATE_WEEKLY_MAX_DAYS = 56;

export function subtractMonths(_endDate: string, numOfMonths: number) {
  const date = new Date(_endDate);
  date.setMonth(date.getMonth() - numOfMonths);

  return format(date, DATE_FORMAT);
}

export function differenceInDays(startDate: string, endDate: string): number {
  const start = new Date(startDate);
  const end = new Date(endDate);
  return Math.abs(start.getTime() - end.getTime()) / oneDayInMs;
}

export const dayDifference = (_endDate: string, _daysToSubtract: string | number) => {
  const endDate = new Date(_endDate);

  return format(new Date(endDate.setDate(endDate.getDate() - Number(_daysToSubtract))), DATE_FORMAT);
};

/**
 * Checks if two dates are in the same month.
 * @param one
 * @param another
 * @returns
 */
export const isSameMonth = (one: Date, another: Date): boolean => {
  return one.getFullYear() === another.getFullYear() && one.getMonth() === another.getMonth();
};

export const isSameDay = (one: Date, another: Date): boolean => {
  return (
    one.getFullYear() === another.getFullYear() &&
    one.getMonth() === another.getMonth() &&
    one.getDate() === another.getDate()
  );
};

/**
 * Calculates the month's first date for the date provided as an argument.
 *
 * @param date
 * @returns Date
 */
export const startOfMonth = (date: Date) => {
  const result = new Date(date.toISOString());
  result.setDate(1);
  result.setHours(0);
  result.setMinutes(0);
  result.setSeconds(0);
  result.setMilliseconds(0);
  return result;
};

/**
 * Calculates the month's end date for the date provided as an argument.
 *
 * @param date
 * @returns Date
 */
export const endOfMonth = (date: Date) => {
  const result = startOfMonth(date);
  result.setMonth(result.getMonth() + 1);
  result.setMinutes(result.getMinutes() - 1);
  return result;
};

export const defaultStartDate = (_maxDate: string, minDate: string, vertical: Verticals | string) => {
  const maxDate = _maxDate ? new Date(_maxDate) : now;
  const startDayDifferent = vertical === Verticals.Grocery ? DEFAULT_DAY_DIFF_GROCERY : DEFAULT_DAY_DIFF;

  let startDate = subDays(maxDate, startDayDifferent);

  //for non-grocery, the start date is set to the beginning of the month
  if (vertical !== Verticals.Grocery) {
    startDate = startOfMonth(startDate);
  }
  // if the network join date is after the start date, use the network join date
  if (isAfter(new Date(minDate), startDate)) {
    startDate = new Date(minDate);
  }

  return format(startDate, DATE_FORMAT);
};

export const displayDate = (
  strDate: string,
  type: 'short' | 'medium' | 'numeric' | 'numericShort' = 'numeric',
): string => {
  let formatter = dateFormatterMedium;

  switch (type) {
    case 'medium':
      formatter = dateFormatterMedium;
      break;
    case 'short':
      formatter = dateFormatterShort;
      break;
    case 'numericShort':
      formatter = dateFormatterNumericShort;
      break;
    default:
      formatter = dateFormatterMedium;
      break;
  }

  return strDate ? formatter.format(parseDate(strDate)) : '';
};

export const displayUTCDate = (
  strDate: string,
  type: 'short' | 'medium' | 'numeric' | 'numericShort' = 'numeric',
): string => {
  let formatter = dateFormatterMedium;

  switch (type) {
    case 'medium':
      formatter = dateFormatterMedium;
      break;
    case 'short':
      formatter = dateFormatterShort;
      break;
    case 'numericShort':
      formatter = dateFormatterNumericShort;
      break;
    default:
      formatter = dateFormatterMedium;
      break;
  }

  return strDate ? formatter.format(parseUTCDate(strDate)) : '';
};

// Format: 11/29/2022
export const dateFormatterNumeric = new Intl.DateTimeFormat('en-US');

// Format: 11/29
export const dateFormatterNumericShort = new Intl.DateTimeFormat('en-US', {
  day: '2-digit',
  month: '2-digit',
});

// Format: Nov 29, 2022
export const dateFormatterMedium = new Intl.DateTimeFormat('en-US', {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
});

// Format: Nov 29
export const dateFormatterShort = new Intl.DateTimeFormat('en-US', {
  day: 'numeric',
  month: 'short',
});

export const weekFormatter = new Intl.DateTimeFormat('en-US', { month: 'numeric', day: 'numeric' });
export const weekdayFormatter = new Intl.DateTimeFormat('en-US', { weekday: 'narrow' });
export const relativeTimeFormatter = new Intl.RelativeTimeFormat('en-US', { numeric: 'auto' });

export const dayOfMonthFormatter = new Intl.DateTimeFormat('en-US', {
  day: 'numeric',
  month: 'short',
});

export const monthFormatter = new Intl.DateTimeFormat('en-US', { month: 'long', year: 'numeric' });
export const monthShortFormatter = new Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' });

/**
 * Human readable elapsed or remaining time (example: 3 minutes ago).
 *
 * @param date {Date|Number|String} date A Date object, timestamp or string parsable with Date.parse()
 * @see https://stackoverflow.com/a/67338038/938822
 * @returns string
 */
export const displayRelativeTime = (date: string): string => {
  const now = new Date().getTime();
  const diff = now - (typeof date === 'object' ? date : new Date(date)).getTime();
  const diffAbs = Math.abs(diff);
  for (const interval of RELATIVE_TIME_INTERVALS) {
    if (diffAbs >= interval.ge) {
      const x = Math.round(Math.abs(diff) / interval.divisor);
      const isFuture = diff < 0;
      return relativeTimeFormatter.format(isFuture ? x : -x, interval.unit);
    }
  }
  return '';
};

export const displayDayOfMonth = (strDate: string): string => {
  return dayOfMonthFormatter.format(parseDate(strDate));
};

export const displayMonthAndYear = (strDate: string, type: 'long' | 'short' = 'long'): string => {
  if (type === 'short') {
    return monthShortFormatter.format(parseDate(strDate));
  } else {
    return monthFormatter.format(parseDate(strDate));
  }
};

export const formatDateByTemporalUnit = (date: string, temporalUnit: TemporalUnit): string => {
  switch (temporalUnit) {
    case 'week':
      return displayDayOfMonth(date);
    case 'month':
      return displayMonthAndYear(date, 'short');
    default:
      return displayDate(date, 'short');
  }
};

/**
 * Parse the date time string to a Date object.
 * It adds the 00:00 time if needed to avoid issues with timezones.
 *
 * @param strDate Date string
 * @returns
 */
const parseDate = (strDate: string): Date => {
  const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;
  if (strDate && strDate.match(isoDateRegex)) {
    return new Date(`${strDate}T00:00:00`);
  } else {
    return new Date(strDate);
  }
};

export const parseUTCDate = (strDate: string): Date => {
  const isoDateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?([+-]\d{2}:\d{2}|Z)$/;
  if (strDate && strDate.match(isoDateTimeRegex)) {
    const parsedDate = parse(strDate.slice(0, 10), 'yyyy-MM-dd', new Date());
    return new Date(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate());
  } else {
    return new Date(strDate);
  }
};

export const calculateTemporalUnit = (startDate: string, endDate: string): TemporalUnit => {
  const diff = differenceInDays(startDate, endDate);
  if (diff > AGGREGATE_WEEKLY_MAX_DAYS) {
    return 'month';
  } else {
    return 'week';
  }
};
