const colors = {
  blue: {
    50: '#EBF1FC',
    75: '#D9E7FB',
    100: '#C6DCFC',
    200: '#A7CAFD',
    300: '#89B8FF',
    400: '#6FA6FF',
    500: '#4C8BFF',
    600: '#3672FF',
    700: '#2161FF',
    800: '#0047FF',
    900: '#013CDD',
    1000: '#002CAA',
    1100: '#09248A',
    1200: '#0C185F',
  },
  purple: {
    50: '#F3EFFA',
    75: '#E7E3F7',
    100: '#DCD7F6',
    200: '#CFBFF4',
    300: '#BFAAF2',
    400: '#AE97EF',
    500: '#967BEB',
    600: '#8065E7',
    700: '#7056E5',
    800: '#5943E2',
    900: '#3C30DF',
    1000: '#2E26BC',
    1100: '#241C8D',
    1200: '#1C1468',
  },
  green: {
    50: '#D1FAE5',
    75: '#9FF9CF',
    100: '#67F3B9',
    200: '#52DEA6',
    300: '#44CB96',
    400: '#3DB888',
    500: '#339E74',
    600: '#2B8863',
    700: '#257A59',
    800: '#1F694C',
    900: '#195844',
    1000: '#1E4B42',
    1100: '#113C33',
    1200: '#092419',
  },
  cyan: {
    50: '#C2FFFC',
    75: '#9EF4FF',
    100: '#5FEEFF',
    200: '#05DCEF',
    300: '#02C9DA',
    400: '#01B6C6',
    500: '#039CAA',
    600: '#008692',
    700: '#017883',
    800: '#016871',
    900: '#005860',
    1000: '#00474E',
    1100: '#01353B',
    1200: '#002428',
  },
  yellow: {
    50: '#FDEFD5',
    75: '#FDE1A8',
    100: '#FFC800',
    200: '#F2BE01',
    300: '#DDAD00',
    400: '#C89C01',
    500: '#AC8600',
    600: '#947300',
    700: '#816400',
    800: '#6F5500',
    900: '#5E4800',
    1000: '#533F00',
    1100: '#423200',
    1200: '#352800',
  },
  orange: {
    50: '#FCEEE4',
    75: '#FBE0CB',
    100: '#FCD1AF',
    200: '#FEB679',
    300: '#FF8E12',
    400: '#F38500',
    500: '#D17100',
    600: '#B56100',
    700: '#A64E00',
    800: '#884800',
    900: '#743C00',
    1000: '#743C00',
    1100: '#663400',
    1200: '#402203',
  },
  red: {
    50: '#F9EEEC',
    75: '#F7E0DC',
    100: '#F5D2CC',
    200: '#F3B7AF',
    300: '#F29E93',
    400: '#EF8478',
    500: '#EB5951',
    600: '#D93C3C',
    700: '#BD3333',
    800: '#A72C2C',
    900: '#8B2323',
    1000: '#7B1E1E',
    1100: '#641717',
    1200: '#501312',
  },
  neutral: {
    50: '#FFFFFF',
    75: '#FEFEFE',
    100: '#F8F9F8',
    200: '#E9EAE9',
    300: '#D9DAD9',
    400: '#C9C9C9',
    500: '#B6B6B6',
    600: '#A0A0A1',
    700: '#8A8A8B',
    800: '#737374',
    900: '#5B5B5B',
    1000: '#424243',
    1200: '#181819',
  },
  neutralwarm: {
    50: '#FAF9F7',
    75: '#F6F5F2',
    100: '#F2F0ED',
    200: '#EDEBE6',
    300: '#E5E2DD',
    400: '#DDDAD3',
    500: '#D0CBC1',
    600: '#BCB8B0',
    700: '#A7A39A',
    800: '#8F8B82',
    900: '#7B766E',
    1000: '#605D57',
    1100: '#4A4844',
    1200: '#363532',
  },
};

export default colors;
