import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@chakra-ui/react';
import { BarDatum, BarSvgProps, ResponsiveBar } from '@nivo/bar';
import _ from 'lodash';

import { Status } from '../../interfaces';
import { formatterSwitch } from '../../utils/utils';
import { EmptyDataAlert } from '../errorAlert';
import { LoadingSpinner } from '../loadingSpinner/LoadingSpinner';

import { BarGraphHeader } from './BarGraphHeader';
import { IBarProps } from './types';

const extractKeys = (data: Array<{ data: Array<Record<string, string | number>> }>) => {
  if (!_.isEmpty(data) && !_.isEmpty(data[0].data)) {
    const result = Object.keys(data[0].data[0]);
    const index = result.indexOf('label');
    result.splice(index, 1);
    return result;
  }

  return ['no keys'];
};

export const BarGraph = ({
  data,
  loadingStatus,
  title,
  height = '450px',
  width = '100%',
  isCurrency = false,
  isPercentage = false,
  yAxisLabel = null,
  customProps,
  dropdownMenuProps,
}: IBarProps) => {
  const { t } = useTranslation();
  const formatter = formatterSwitch({ isCurrency, isPercentage });
  const isLoading = loadingStatus === Status.Loading;
  const isEmptyData = loadingStatus === Status.Idle && (_.isEmpty(data) || _.isEmpty(data[0].data));

  const commonProps: Omit<BarSvgProps<BarDatum>, 'height' | 'width'> = {
    data: [],
  };

  if (!_.isEmpty(data)) {
    const keys = extractKeys(data);

    const axisLeftSwitch = () => {
      let result = undefined;

      if (yAxisLabel) {
        result = {
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: t(yAxisLabel),
          legendPosition: 'middle',
          legendOffset: -50,
        };
      }

      if (isPercentage || isCurrency) {
        return { ...result, format: (value: string | number) => formatter.format(Number(value)) };
      }

      return result;
    };

    Object.assign(commonProps, {
      margin: { top: 40, right: 40, bottom: 70, left: 60 },
      data: data[0].data,
      keys,
      indexBy: 'label',
      groupMode: 'grouped',
      enableGridY: true,
      enableLabel: false,
      axisLeft: axisLeftSwitch(),
      colors: { scheme: 'category10' },
      valueFormat: function (y: string | number) {
        return formatter.format(Number(y));
      },
      legends: [
        {
          dataFrom: 'keys',
          anchor: 'bottom-left',
          direction: 'row',
          justify: false,
          translateY: 60,
          itemsSpacing: 100,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 12,
          symbolShape: 'circle',
          effects: [],
          toggleSerie: true,
        },
      ],
      ...customProps,
    });
  }

  return (
    <>
      <Box h={height} w={width} maxW='100%' data-testid='bar-graph'>
        <BarGraphHeader
          graphLabel={title ? t(`${title}`) : ''}
          dropdownMenuProps={dropdownMenuProps}
          opacity={isLoading ? '20%' : '100%'}
        />
        <Stack
          h={'100%'}
          w={'100%'}
          align={isLoading || isEmptyData ? 'center' : 'default'}
          justify={isLoading || isEmptyData ? 'center' : 'default'}
          data-testid='bar-graph-container'
        >
          {isLoading && <LoadingSpinner />}
          {isEmptyData && (
            <Box data-testid='empty-data-alert' h='full' w='full'>
              <EmptyDataAlert />
            </Box>
          )}
          {!isLoading && !isEmptyData && (
            <Box data-testid='responsive-bar-graph' h='full' w='full'>
              <ResponsiveBar {...commonProps} />
            </Box>
          )}
        </Stack>
      </Box>
    </>
  );
};
