import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
  thumb: {
    bg: 'background.default',
  },
  track: {
    bg: 'surface.dark',
    _checked: {
      bg: 'background.primary.default',
    },
    _disabled: {
      bg: 'background.disabled.dark',
    },
  },
});

export default defineMultiStyleConfig({ baseStyle });
