import { Action, configureStore, createListenerMiddleware, ThunkAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import createSagaMiddleware from 'redux-saga';

import userReducer from '@/features/auth/userSlice';
import dashboardReducer from '@/features/dashboard/slice';
import { setDateRange } from '@/features/dashboard/slice';
import behaviorReducer from '@/features/explore/behavior/slice';
import capacityReducer from '@/features/explore/capacity/slice';
import customersReducer from '@/features/explore/customerDeepDive/slice';
import locationsReducer from '@/features/explore/locations/slice';
import registrationReducer from '@/features/registration/slice';
import customReportsReducer from '@/features/reports/customReports/slice';
import invoicesReducer from '@/features/reports/financialReports/invoices/slice';
import payoutsReducer from '@/features/reports/financialReports/payouts/slice';
import statementsReducer from '@/features/reports/financialReports/statements/slice';
import promotionsReducer from '@/features/reports/promotions/slice';
import locationsSettingsReducer from '@/features/settings/locations/slice';
import notificationReducer from '@/features/settings/notification/slice';
import billingPaymentMethodsReducer from '@/features/settings/payment/billingPaymentMethods/slice';
import paymentReducer from '@/features/settings/payment/payoutMethod/slice';
import profileReducer from '@/features/settings/profile/slice';

import rootSaga from './sagas';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
  // read me here: https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
});

const sagaMiddleware = createSagaMiddleware();
export const listenerMiddleware = createListenerMiddleware();

/*
This is the global state which will enable us to store (cache) data in between views to minimize API calls and 
create a fast, fluid-like UX
Things that should trigger new API fetches:
1. new dates selections
2. new DMA selections
3. navigating to a new page that hasn't been accessed before with the set of current dates & DMA
  IF that data hasn't already been fetched before.

Note: this is a living comment and subject to change as the Dashboard matures

Currently the performance_summary endpoint returns the majority of the data. Which as be lifted to be fetched
at the dashboard level instead of the home page level. Soon new endpoints will be created with specific pages 
being the consumers. At that points, it makes sense to create a new set of slice/saga files for that page to 
interact with that endpoint and store the results in the global store.
*/
export const store = configureStore({
  reducer: {
    router: routerReducer,
    user: userReducer,
    dashboard: dashboardReducer,
    customers: customersReducer,
    locations: locationsReducer,
    promotions: promotionsReducer,
    capacity: capacityReducer,
    behavior: behaviorReducer,
    payment: paymentReducer,
    invoices: invoicesReducer,
    payouts: payoutsReducer,
    statements: statementsReducer,
    billing: billingPaymentMethodsReducer,
    profile: profileReducer,
    locationsSettings: locationsSettingsReducer,
    notification: notificationReducer,
    customReports: customReportsReducer,
    registration: registrationReducer,
    // home: homeReducer,
    // reports: reportsReducer,
    // explore: exploreReducer,
  },
  enhancers: [sentryReduxEnhancer],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware).concat([sagaMiddleware, routerMiddleware]),
});

listenerMiddleware.startListening({
  actionCreator: setDateRange,
  effect: (_action, listenerApi) => {
    const state = listenerApi.getState() as RootState;
    if (state) {
      const { startDate, endDate } = state.dashboard.datePicker;
      const { behaviorWalkthrough, hasUserClosedSunocoBanner } = state.dashboard;
      startDate && localStorage.setItem('startDate', startDate);
      endDate && localStorage.setItem('endDate', endDate);
      behaviorWalkthrough && localStorage.setItem('behaviorWalkthrough', JSON.stringify(behaviorWalkthrough));
      hasUserClosedSunocoBanner &&
        localStorage.setItem('@upside:v0.1.0:hasUserClosedSunocoBanner', JSON.stringify(hasUserClosedSunocoBanner));
    }
  },
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export const history = createReduxHistory(store);
