import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';
import { Status } from '@/common/interfaces';
import { TemporalUnit } from '@/features/dashboard/slice';

import { ICompetitor, ISiteLevelPerformance } from './types';

interface ILocationsState {
  siteLevelPerformance: ISiteLevelPerformance;
  competitors: {
    data: ICompetitor[];
    selectedTypes: string[];
    status?: Status;
  };
  error: string | null;
  status: Status;
}

const initialState: ILocationsState = {
  siteLevelPerformance: {
    table: {
      rows: [],
      headers: [],
    },
    topSitesTable: {
      rows: [],
      headers: [],
    },
    topSitesLocationTable: {
      rows: [],
      headers: [],
    },
    topSitesLocationCSVData: [],
    map: {
      sites: [],
    },
  },
  competitors: {
    data: [],
    selectedTypes: ['LOCATIONS', 'EXCLUSIVITY_ZONE', 'BLOCKED_COMPETITORS'],
  },
  error: null,
  status: Status.Idle,
};

export const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    locationsFetch: (
      state,
      _action: PayloadAction<{
        startDate: string;
        endDate: string;
        temporalUnit: TemporalUnit;
        selectedNetworks: string[];
        selectedTransactionType: string;
        authRequestPath: string;
        activeVertical: string;
        networkNames: string[];
        locations: string[];
        transactionType: string;
      }>,
    ) => {
      state.status = Status.Loading;
      state.error = null;
    },
    locationsSucceeded: (state, action: PayloadAction<ISiteLevelPerformance>) => {
      state.status = Status.Idle;
      state.siteLevelPerformance = action.payload;
    },
    locationsFailed: (state, action: PayloadAction<string>) => {
      state.status = Status.Failed;
      state.error = action.payload;
    },
    competitorsFetch: (state) => {
      state.competitors.status = Status.Loading;
      state.error = null;
    },
    competitorsSucceeded: (state, action: PayloadAction<ICompetitor[]>) => {
      state.competitors.status = Status.Idle;
      state.competitors.data = action.payload;
    },
    competitorsFailed: (state, action: PayloadAction<string>) => {
      state.competitors.status = Status.Failed;
      state.error = action.payload;
    },
    setSelectedCompetitorTypes: (state, action: PayloadAction<string[]>) => {
      state.competitors.selectedTypes = action.payload;
    },
  },
});

export const {
  locationsFailed,
  locationsFetch,
  locationsSucceeded,
  competitorsFailed,
  competitorsFetch,
  competitorsSucceeded,
  setSelectedCompetitorTypes,
} = locationsSlice.actions;

export const selectSiteLevelPerformance = (state: RootState) => state.locations.siteLevelPerformance;
export const selectSiteLevelStatus = (state: RootState) => state.locations.status;
export const selectSiteLevelError = (state: RootState) => state.locations.error;
export const selectCompetitors = (state: RootState) => state.locations.competitors.data;
export const selectSelectedCompetitorTypes = (state: RootState) => state.locations.competitors.selectedTypes;
export const selectCompetitorsStatus = (state: RootState) => state.locations.competitors.status;

export default locationsSlice.reducer;
