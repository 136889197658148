import { useTranslation } from 'react-i18next';
import { Link, Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Flex, Heading, Stack, Text, useBreakpointValue, useColorModeValue as mode } from '@chakra-ui/react';
import { useDecision } from '@optimizely/react-sdk';

import { generateCopyrightYear } from '@/common/utils/utils';

import { SignInForm } from './SignInForm';
import { Logo } from './UpsideLogo';

export const Login = () => {
  const { isAuthenticated } = useAuth0();
  const [merchantSelfRegistrationFlag] = useDecision('merchant-self-registration', { autoUpdate: true });

  const { t } = useTranslation();
  const gradient = useBreakpointValue({
    md: mode('linear(to-r, background.dark 50%, white 50%)', 'linear(to-r, background.dark 50%, neutral.1000 50%)'),
  });

  if (isAuthenticated) {
    return <Navigate to='/home' />;
  }

  const year = generateCopyrightYear();

  return (
    <Flex
      id='main-flex'
      minH={{ base: 'auto', md: '100vh' }}
      bgGradient={gradient}
      direction={{ base: 'column', md: 'row' }}
      align='center'
    >
      <Flex id='inner-flex' maxW='8xl' mx='auto' width='full' direction={{ base: 'column-reverse', md: 'row' }}>
        <Flex
          id='left-flex'
          flex='1'
          width={{ base: 'full' }}
          maxW={{ base: 'md', md: '3xl' }}
          alignSelf={{ base: 'center', md: 'auto' }}
        >
          <Flex
            id='lt-content-flex'
            direction='column'
            px={{ base: '4', md: '8' }}
            height={{ base: 'full', md: '100vh' }}
            color='white'
          >
            <Flex align='center' h='24' data-testid='logo'>
              <Logo />
            </Flex>
            <Flex flex='1' align='center' alignSelf='center' id='registration-form'>
              <Stack spacing='8'>
                <Stack spacing='6' textAlign={{ base: 'center', md: 'left' }}>
                  <Heading size='xl' color={{ base: 'text.default', md: 'text.default-inverse' }}>
                    {t('loginPage.header.title')}
                  </Heading>
                  <Text
                    textAlign={{ base: 'center', md: 'start' }}
                    textStyle='body.md'
                    maxW='md'
                    color={{ base: 'text.default', md: 'text.default-inverse' }}
                    alignSelf={{ base: 'center', md: 'start' }}
                  >
                    {t('loginPage.header.label')}
                  </Text>
                  {merchantSelfRegistrationFlag.enabled && (
                    <Button
                      as={Link}
                      to={'/registration/1'}
                      size='default'
                      variant={'brandPrimary'}
                      data-testid='registration-btn'
                      maxW={{ base: 'md', md: 'sm' }}
                      width='full'
                      alignSelf={{ base: 'center', md: 'flex-start' }}
                    >
                      {t('loginPage.signUp.button')}
                    </Button>
                  )}
                </Stack>
              </Stack>
            </Flex>
            <Flex
              alignItems={{ base: 'flex-end', md: 'center' }}
              alignSelf={{ base: 'center', md: 'start' }}
              h='24'
              id='footer'
            >
              <Text color={{ base: 'text.default', md: 'text.default-inverse' }} textStyle='body.sm'>
                {t('loginPage.header.footer', { year })}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          id='right-flex'
          alignSelf={{ base: 'center', md: 'auto' }}
          flex='1'
          data-testid='signin-form'
          height={{ base: 'full', md: '100vh' }}
          width={{ base: 'full', md: '100vw' }}
          justifyContent='space-around'
          alignItems={{ base: 'center', md: 'center' }}
        >
          <SignInForm px={{ base: '4', md: '8' }} py={{ base: '12', md: '48' }} width='full' maxW='md' />
        </Flex>
      </Flex>
    </Flex>
  );
};
