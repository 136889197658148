import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { DataLoadingErrorAlert } from '@components/errorAlert';
import { LoadingSpinner } from '@components/loadingSpinner';
import { Pagination } from '@components/pagination';
import { AsyncPaginationContainer } from '@components/pagination/AsyncPaginationContainer';
import { PaginationOptions, PaginationProps } from '@components/pagination/types';
import { Table } from '@components/table';
import { ITableData } from '@components/table/Table';
import _ from 'lodash';

import { Card } from '@/common/components/card';
import { Status, UserRoles, Verticals } from '@/common/interfaces';
import { displayUuid } from '@/common/utils/utils';

import { selectActiveVertical, selectAuthRequestPath, selectUserOrg, selectUserRoles } from '../../auth/userSlice';
import {
  selectDashboardStatus,
  selectEndDate,
  selectSelectedLocations,
  selectSelectedNetworks,
  selectSelectedTransactionType,
  selectStartDate,
} from '../../dashboard/slice';

import { ComingSoon } from './ComingSoon';
import { CustomerVsControlChart } from './CustomerVsControlChart';
import {
  customerSummaryFetch,
  customerTransactionsFetch,
  selectCustomerSummary,
  selectCustomerTransactions,
} from './slice';

export const CustomerSummary = () => {
  const [paginationOptions, setPaginationOptions] = useState<PaginationOptions>({
    pageSize: 50,
    availablePageSizes: [10, 50, 100, 250],
  });
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const userUuid: string = searchParams.get('userUuid') as string;
  const dispatch = useAppDispatch();
  const authRequestPath = useAppSelector(selectAuthRequestPath);
  const activeVertical = useAppSelector(selectActiveVertical);
  const selectedTransactionType = useAppSelector(selectSelectedTransactionType);
  const selectedLocations = useAppSelector(selectSelectedLocations);
  const selectedNetworks = useAppSelector(selectSelectedNetworks);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const roles = useAppSelector(selectUserRoles);
  const userOrg = useAppSelector(selectUserOrg);

  const upsideOrgIds = ['org_zGpBx0PRd9knLzLs', 'org_BJQReYkeb8bUVMZD', 'org_lexe5Wkh4jZI57kM', 'org_j6bTIRqaiAbbhCME'];
  const isUpside = upsideOrgIds.includes(userOrg);

  const {
    transactions,
    page: txnListPageNumber,
    status: transactionsStatus,
    error: transactionsError,
  } = useAppSelector(selectCustomerTransactions);

  const { summary: customerSummary, status: customerSummaryStatus } = useAppSelector(selectCustomerSummary);
  const dashboardStatus = useAppSelector(selectDashboardStatus);

  const fetchCustomerSummary = useCallback(
    ({
      userUuid,
      activeVertical,
      authRequestPath,
      networkNames,
      transactionType,
      locations,
    }: {
      userUuid: string;
      activeVertical: Verticals;
      authRequestPath: string;
      networkNames: string[];
      transactionType: string | null;
      locations: string[];
    }) => {
      if (userUuid && authRequestPath && activeVertical !== Verticals.Unknown) {
        const payload = {
          userUuid,
          activeVertical,
          authRequestPath,
          networkNames,
          transactionType,
          locations,
        };
        dispatch(customerSummaryFetch(payload));
      }
    },
    [dispatch],
  );

  const fetchCustomerTransactions = useCallback(
    ({
      page = 1,
      pageSize,
      locations = [],
      transactionType = null,
      userUuid,
      authRequestPath,
      networkNames,
      activeVertical,
    }: {
      page: number;
      pageSize: number;
      locations: string[];
      transactionType: string | null;
      userUuid: string;
      authRequestPath: string;
      networkNames: string[];
      activeVertical: Verticals;
    }) => {
      if (!_.isEmpty(userUuid) && !_.isEmpty(authRequestPath) && !_.isEmpty(activeVertical)) {
        const payload = {
          userUuid,
          page,
          pageSize,
          authRequestPath,
          networkNames,
          activeVertical,
          locations,
          transactionType,
        };
        dispatch(customerTransactionsFetch(payload));
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (!!userUuid && authRequestPath !== '' && activeVertical !== Verticals.Unknown) {
      fetchCustomerSummary({
        userUuid,
        activeVertical,
        authRequestPath,
        networkNames: selectedNetworks,
        transactionType: selectedTransactionType,
        locations: selectedLocations,
      });
    }
  }, [
    authRequestPath,
    userUuid,
    fetchCustomerSummary,
    activeVertical,
    selectedNetworks,
    selectedTransactionType,
    selectedLocations,
  ]);

  useEffect(() => {
    if (userUuid && authRequestPath !== '') {
      fetchCustomerTransactions({
        page: 1,
        pageSize: paginationOptions.pageSize,
        locations: selectedLocations,
        transactionType: selectedTransactionType,
        userUuid,
        authRequestPath,
        networkNames: selectedNetworks,
        activeVertical,
      });
    }
  }, [
    authRequestPath,
    userUuid,
    selectedLocations,
    selectedTransactionType,
    fetchCustomerTransactions,
    paginationOptions.pageSize,
    selectedNetworks,
    activeVertical,
  ]);

  function handlePageChange(page: number): void {
    if (startDate && endDate) {
      fetchCustomerTransactions({
        page,
        pageSize: paginationOptions.pageSize,
        locations: selectedLocations,
        transactionType: selectedTransactionType,
        userUuid,
        authRequestPath,
        networkNames: selectedNetworks,
        activeVertical,
      });
    }
  }

  function handlePageSizeChange(pageSize: number): void {
    setPaginationOptions((state) => ({ ...state, pageSize }));
  }

  if (dashboardStatus === Status.Loading) {
    return null;
  }

  const hasEnoughData = customerSummary?.individualUserUsage.has_txns_over_45_days;
  const hasCustomerVsControlData =
    hasEnoughData &&
    _.some(_.get(customerSummary, 'customerVsControlChart.data'), (series) => {
      return series.data && series.data.length > 0;
    });
  const isOutlierAtAllSites = !!customerSummary?.individualUserUsage.allSitesOutlier;
  const expectedPopulationDays = customerSummary?.customerVsControlChart.expectedPopulationDays;

  const hasGreenGreyAccess = roles.includes('GreenGreyAccess' as UserRoles);

  return (
    <Box padding={4}>
      {customerSummaryStatus === Status.Loading && (
        <Flex height='300px' justifyContent='center' alignItems='center'>
          <LoadingSpinner />
        </Flex>
      )}
      {customerSummaryStatus !== Status.Loading && (
        <>
          <Card variant='borderless' bg='background.whitecap.light' borderRadius='lg' px={4}>
            <Flex justifyContent='space-between'>
              <Text fontWeight={500} fontSize={'18px'} lineHeight={'28px'}>
                {t('customerDeepDivePage.individualCustomerPage.results')}
              </Text>
              <Link as={RouterLink} to='/explore/customerDeepDive'>
                {t('customerDeepDivePage.individualCustomerPage.returnToSearchLink')}
              </Link>
            </Flex>
            <Flex mt={3} flexDirection='row' width='100%' justifyContent='space-between' alignItems='center' gap={4}>
              <Text fontWeight={500} fontSize={'18px'} lineHeight={'28px'}>
                {userUuid ? displayUuid(userUuid) : ''}
              </Text>
            </Flex>
          </Card>
          {customerSummary && (
            <>
              <Card variant='borderless'>
                {customerSummaryStatus === Status.Idle && (
                  <Flex flexDirection='row' flexWrap='wrap' width='100%' alignItems='flex-start' gap={4}>
                    <Box flex={1} minWidth='450px'>
                      <Table data={customerSummary.individualUserUsageTable} />
                    </Box>
                    <Box flex={1} minWidth='450px'>
                      <Table data={customerSummary.customerWalletTable} />
                    </Box>
                  </Flex>
                )}

                {customerSummaryStatus === Status.Failed && (
                  <Flex direction='column' width='100%' alignItems='center' justify='center' gap={2} py={12}>
                    <Text fontWeight={600} fontSize='lg' lineHeight={'28px'}>
                      {t('customerDeepDivePage.individualCustomerPage.noDataTitle')}
                    </Text>
                    <Text fontWeight='400' fontSize='md'>
                      {t('customerDeepDivePage.individualCustomerPage.noDataInfo')}
                    </Text>
                  </Flex>
                )}
              </Card>

              <Card variant='borderless'>
                {customerSummaryStatus === Status.Idle && (
                  <>
                    <Box position={'relative'}>
                      {(hasGreenGreyAccess || isUpside) && (
                        <Box flex={1} minWidth='450px' mb={4} position={'relative'}>
                          {!hasCustomerVsControlData && (
                            <ComingSoon
                              expectedPoulationDays={expectedPopulationDays}
                              isOutlier={isOutlierAtAllSites}
                            />
                          )}
                          <CustomerVsControlChart
                            id='customerVsControlChart'
                            {...customerSummary.customerVsControlChart}
                          />
                          <Flex flexDirection='row' flexWrap='wrap' justifyContent='center' gap={4} mt={4} p={'0.5em'}>
                            <Flex as='span' flexDirection='row' alignItems='center' gap={2} fontSize='12px'>
                              <Box bgColor='data.green' width='12px' height='12px' borderRadius='6px'></Box>
                              {t('customerDeepDivePage.customerVsControlChart.totalCustomerSales')}
                            </Flex>
                            <Flex as='span' flexDirection='row' alignItems='center' gap={2} fontSize='12px'>
                              <Box bgColor='data.neutral' width='12px' height='12px' borderRadius='6px'></Box>
                              {t('customerDeepDivePage.customerVsControlChart.totalControlSales')}
                            </Flex>
                            <Flex as='span' flexDirection='row' alignItems='center' gap={2} fontSize='12px'>
                              <Box bgColor='data.blue' width='12px' height='12px' borderRadius='6px'></Box>
                              {t('customerDeepDivePage.customerVsControlChart.firstUpsideTxn')}
                            </Flex>
                          </Flex>
                        </Box>
                      )}
                    </Box>

                    <Flex flexDirection='row' flexWrap='wrap' width='100%' gap={4}>
                      <Box flex={1}>
                        <Table id='profitStatementTbl' data={customerSummary.profitStatementTable} />
                      </Box>
                      <Box flex={1}>
                        <Table data={customerSummary.roiFlowthroughTable} />
                      </Box>
                    </Flex>
                  </>
                )}

                {customerSummaryStatus === Status.Failed && (
                  <Flex direction='column' width='100%' alignItems='center' justify='center' gap={2} py={12}>
                    <Text fontWeight={600} fontSize='lg' lineHeight={'28px'}>
                      {t('customerDeepDivePage.individualCustomerPage.noDataTitle')}
                    </Text>
                    <Text fontWeight='400' fontSize='md'>
                      {t('customerDeepDivePage.individualCustomerPage.noDataInfo')}
                    </Text>
                  </Flex>
                )}
              </Card>
            </>
          )}
          <Card variant='borderless'>
            {customerSummaryStatus === Status.Idle && (
              <AsyncPaginationContainer
                data={transactions.table?.rows || []}
                page={txnListPageNumber}
                total={transactions.count}
                prevPage={transactions?.prevPage}
                nextPage={transactions?.nextPage}
                isLoading={transactionsStatus === Status.Loading}
                options={paginationOptions}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
              >
                {(props: PaginationProps) => {
                  const { data } = props;
                  const tableData: ITableData = Object.assign({}, transactions?.table, {
                    rows: data,
                  });

                  return (
                    <>
                      {transactionsError ? (
                        <DataLoadingErrorAlert />
                      ) : (
                        <>
                          <Table data={tableData} />
                          <Pagination {...props} />
                        </>
                      )}
                    </>
                  );
                }}
              </AsyncPaginationContainer>
            )}

            {customerSummaryStatus === Status.Failed && (
              <Flex direction='column' width='100%' alignItems='center' justify='center' gap={2} py={12}>
                <Text fontWeight={600} fontSize='lg' lineHeight={'28px'}>
                  {t('customerDeepDivePage.individualCustomerPage.noDataTitle')}
                </Text>
                <Text fontWeight='400' fontSize='md'>
                  {t('customerDeepDivePage.individualCustomerPage.noDataInfo')}
                </Text>
              </Flex>
            )}
          </Card>
        </>
      )}
    </Box>
  );
};
