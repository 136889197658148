import { RootState } from '@app/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { INITIAL_TRANSACTION_TYPE, Status, TransactionTypes } from '../../common/interfaces';
import { calculateTemporalUnit, defaultStartDate, today } from '../../common/utils';

import {
  TMasqueradingResponse,
  TMerchantUuid,
  TParentOrgConfigResponse,
  TUserUuid,
} from './components/merchantSelector/types';
import { IRefinedPerformanceSummary, TGeneralConfigs, THeaderData, TLocation, TNetwork } from './types';

export interface ContentState {
  performanceSummary: IRefinedPerformanceSummary;
  status: Status.Idle | Status.Loading | Status.Failed;
  error?: string;
}

export type TemporalUnit = 'week' | 'month' | 'year';
export interface DatePickerState {
  maxDate: string;
  temporalUnit: TemporalUnit;
  status: Status.Idle | Status.Loading | Status.Failed;
  minDate?: string;
  startDate?: string;
  endDate?: string;
  error?: string;
}

export interface NetworksState {
  list: TNetwork[];
  status: Status.Idle | Status.Loading | Status.Failed;
  error?: string;
}

export interface LocationsState {
  list: TLocation[];
  status: Status.Idle | Status.Loading | Status.Failed;
  error?: string;
}

export interface TransactionTypesState {
  list: string[];
  status: Status.Idle | Status.Loading | Status.Failed;
  error?: string;
}

export interface MasqueradingListsState {
  merchantUuids: TMerchantUuid[];
  userUuids: TUserUuid[];
  status: Status.Idle | Status.Loading | Status.Failed;
  error?: string;
}

export interface BehaviorWalkthroughState {
  initiated: boolean;
  step: number;
}

export interface GeneralConfigs {
  sunocoSites: string[];
  status: Status;
  error?: string;
}

export interface DashboardState {
  datePicker: DatePickerState;
  behaviorWalkthrough: BehaviorWalkthroughState;
  content: ContentState;
  networks: NetworksState;
  locations: LocationsState;
  selectedNetworks: string[];
  selectedLocations: string[];
  transactionTypes: TransactionTypesState;
  selectedTransactionType: TransactionTypes;
  selectedProgramBasicsTab: number;
  hasUserClosedSunocoBanner: boolean;
  masqueradingLists: MasqueradingListsState;
  generalConfigs: GeneralConfigs;
  isDemo: boolean;
  error?: string;
}

export const initialState: DashboardState = {
  datePicker: {
    maxDate: today,
    temporalUnit: 'week',
    status: Status.Loading,
  },
  selectedLocations: [],
  selectedNetworks: [],
  selectedTransactionType: INITIAL_TRANSACTION_TYPE,
  locations: {
    list: [],
    status: Status.Idle,
  },
  networks: {
    list: [],
    status: Status.Idle,
  },
  transactionTypes: {
    list: [],
    status: Status.Idle,
  },
  content: {
    performanceSummary: {},
    status: Status.Loading,
  },
  selectedProgramBasicsTab: 0,
  behaviorWalkthrough: {
    initiated: false,
    step: 0, //0 means default, 1 means first step of walkthrough
  },
  masqueradingLists: {
    merchantUuids: [],
    userUuids: [],
    status: Status.Idle,
  },
  generalConfigs: {
    sunocoSites: [],
    status: Status.Idle,
  },
  hasUserClosedSunocoBanner: false,
  isDemo: false,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: () => {
    const datePicker: DatePickerState = {
      startDate: localStorage.getItem('startDate') || undefined,
      endDate: localStorage.getItem('endDate') || undefined,
      status: Status.Idle,
      error: undefined,
      minDate: undefined,
      maxDate: today,
      temporalUnit: 'week',
    };
    const behaviorWalkthroughStr = localStorage.getItem('behaviorWalkthrough');
    const behaviorWalkthrough =
      behaviorWalkthroughStr && (JSON.parse(behaviorWalkthroughStr) as BehaviorWalkthroughState);
    const hasUserClosedSunocoBannerStr = localStorage.getItem('@upside:v0.1.0:hasUserClosedSunocoBanner');
    const hasUserClosedSunocoBanner = hasUserClosedSunocoBannerStr
      ? (JSON.parse(hasUserClosedSunocoBannerStr) as boolean)
      : false;

    return datePicker.startDate && datePicker.endDate
      ? { ...initialState, datePicker, behaviorWalkthrough, hasUserClosedSunocoBanner }
      : initialState;
  },
  reducers: {
    setDateRange: (state, action: PayloadAction<{ startDate: string; endDate: string }>) => {
      const { startDate, endDate } = action.payload;
      state.datePicker.startDate = startDate;
      state.datePicker.endDate = endDate;
      state.datePicker.temporalUnit = calculateTemporalUnit(startDate, endDate);
    },
    headerDataFetchRequest: (state) => {
      state.datePicker.error = undefined;
      state.networks.error = undefined;
      state.locations.error = undefined;
      state.transactionTypes.error = undefined;

      state.datePicker.status = Status.Loading;
      state.locations.status = Status.Loading;
      state.networks.status = Status.Loading;
      state.transactionTypes.status = Status.Loading;
    },
    headerDataFetchSuccess: (state, action: PayloadAction<THeaderData>) => {
      state.locations.list = action.payload.locations;
      state.locations.status = Status.Idle;

      state.networks.list = action.payload.networks;
      state.networks.status = Status.Idle;

      state.transactionTypes.list = action.payload.transactionTypes;
      state.transactionTypes.status = Status.Idle;

      state.datePicker.minDate = action.payload.calculationDates.min_site_join_date;
      state.datePicker.maxDate = action.payload.calculationDates.max_calculation_date;
      if (state.datePicker.startDate === undefined || state.datePicker.endDate === undefined) {
        state.datePicker.startDate = defaultStartDate(
          action.payload.calculationDates.max_calculation_date,
          action.payload.calculationDates.min_site_join_date,
          action.payload.activeVertical,
        );
        state.datePicker.endDate = action.payload.calculationDates.max_calculation_date || today;
        state.datePicker.temporalUnit = calculateTemporalUnit(state.datePicker.startDate, state.datePicker.endDate);
      }
      state.datePicker.status = Status.Idle;
    },
    headerDataFetchFailed: (state) => {
      state.datePicker.error = undefined;
      state.networks.error = undefined;
      state.locations.error = undefined;
      state.transactionTypes.error = undefined;

      state.datePicker.status = Status.Failed;
      state.locations.status = Status.Failed;
      state.networks.status = Status.Failed;
      state.transactionTypes.status = Status.Failed;
    },
    setSelectedNetworks: (state, action: PayloadAction<string[]>) => {
      state.selectedNetworks = action.payload;
    },
    setSelectedLocations: (state, action: PayloadAction<string[]>) => {
      state.selectedLocations = action.payload;
    },
    setSelectedTransactionType(state, action: PayloadAction<TransactionTypes>) {
      state.selectedTransactionType = action.payload;
    },
    masqueradingListsFetchRequest(state) {
      state.masqueradingLists.error = undefined;
      state.masqueradingLists.status = Status.Loading;
    },
    masqueradingListsFetchSuccess(state, action: PayloadAction<TMasqueradingResponse>) {
      state.masqueradingLists.merchantUuids = action.payload.merchantUuids;
      state.masqueradingLists.userUuids = action.payload.userUuids;
      state.masqueradingLists.status = Status.Idle;
    },
    masqueradingListsFetchFailure(state, action: PayloadAction<string>) {
      state.masqueradingLists.error = action.payload;
      state.masqueradingLists.status = Status.Failed;
    },
    parentOrgConfigFetchRequest(state) {
      state.masqueradingLists.error = undefined;
      state.masqueradingLists.status = Status.Loading;
    },
    parentOrgConfigFetchSuccess(state, action: PayloadAction<TParentOrgConfigResponse>) {
      state.masqueradingLists.merchantUuids = action.payload.parentOrgConfig;
      state.masqueradingLists.status = Status.Idle;
    },
    parentOrgConfigFetchFailure(state, action: PayloadAction<string>) {
      state.masqueradingLists.error = action.payload;
      state.masqueradingLists.status = Status.Failed;
    },
    performanceSummaryFetch: (
      state,
      _action: PayloadAction<{
        startDate: string;
        endDate: string;
        temporalUnit: TemporalUnit;
        selectedNetworks: string[];
        selectedTransactionType: string;
        authRequestPath: string;
        activeVertical: string;
        networkNames: string[];
        locations: string[];
        transactionType: string;
      }>,
    ) => {
      state.content.error = undefined;
      state.content.status = Status.Loading;
    },
    performanceSummarySucceeded: (state, action: PayloadAction<IRefinedPerformanceSummary>) => {
      state.content.performanceSummary = action.payload;
      state.content.status = Status.Idle;
    },
    performanceSummaryFailed: (state, action: PayloadAction<string>) => {
      state.content.error = action.payload;
      state.content.status = Status.Failed;
    },
    setProgramBasicsTab: (state, action: PayloadAction<number>) => {
      state.selectedProgramBasicsTab = action.payload;
    },
    setBehaviorWalkthrough: (state, action: PayloadAction<BehaviorWalkthroughState>) => {
      state.behaviorWalkthrough = action.payload;
    },
    closeSunocoBanner: (state) => {
      state.hasUserClosedSunocoBanner = true;
    },
    generalConfigsFetchRequest: (state) => {
      state.generalConfigs.status = Status.Loading;
      state.generalConfigs.error = undefined;
    },
    generalConfigsFetchSuccess: (state, action: PayloadAction<TGeneralConfigs>) => {
      state.generalConfigs.status = Status.Idle;
      state.generalConfigs.sunocoSites = action.payload.sunocoSites;
    },
    generalConfigsFetchFailed: (state, action: PayloadAction<string>) => {
      state.generalConfigs.error = action.payload;
      state.generalConfigs.status = Status.Failed;
    },
    setIsDemo: (state, action: PayloadAction<boolean>) => {
      state.isDemo = action.payload;
    },
  },
});

export const {
  headerDataFetchRequest,
  headerDataFetchSuccess,
  headerDataFetchFailed,
  performanceSummaryFetch,
  performanceSummarySucceeded,
  performanceSummaryFailed,
  setSelectedNetworks,
  setSelectedLocations,
  masqueradingListsFetchRequest,
  masqueradingListsFetchSuccess,
  masqueradingListsFetchFailure,
  parentOrgConfigFetchRequest,
  parentOrgConfigFetchSuccess,
  parentOrgConfigFetchFailure,
  setSelectedTransactionType,
  setBehaviorWalkthrough,
  setDateRange,
  setProgramBasicsTab,
  closeSunocoBanner,
  generalConfigsFetchRequest,
  generalConfigsFetchSuccess,
  generalConfigsFetchFailed,
  setIsDemo,
} = dashboardSlice.actions;

export const selectDashboardStatus = (state: RootState) => {
  const headerStatus = selectHeaderStatus(state);
  const contentStatus = selectContentStatus(state);

  if (headerStatus === Status.Idle && contentStatus === Status.Idle) {
    return Status.Idle;
  }

  if (headerStatus === Status.Failed || contentStatus === Status.Failed) {
    return Status.Failed;
  }

  if (headerStatus === Status.Loading || contentStatus === Status.Loading) {
    return Status.Loading;
  }
};

export const selectHeaderStatus = (state: RootState) => {
  if (
    state.dashboard.datePicker.status === Status.Idle &&
    state.dashboard.locations.status === Status.Idle &&
    state.dashboard.networks.status === Status.Idle &&
    state.dashboard.transactionTypes.status === Status.Idle
  ) {
    return Status.Idle;
  }

  if (
    state.dashboard.datePicker.status === Status.Failed ||
    state.dashboard.locations.status === Status.Failed ||
    state.dashboard.networks.status === Status.Failed ||
    state.dashboard.transactionTypes.status === Status.Failed
  ) {
    return Status.Failed;
  }

  if (
    state.dashboard.datePicker.status === Status.Loading ||
    state.dashboard.locations.status === Status.Loading ||
    state.dashboard.networks.status === Status.Loading ||
    state.dashboard.transactionTypes.status === Status.Loading
  ) {
    return Status.Loading;
  }
};
export const selectContentStatus = (state: RootState) => state.dashboard.content.status;

export const selectMinDate = (state: RootState) => state.dashboard.datePicker.minDate;
export const selectMaxDate = (state: RootState) => state.dashboard.datePicker.maxDate;

export const selectCalculationDates = createSelector([selectMinDate, selectMaxDate], (minDate, maxDate) => ({
  minDate,
  maxDate,
}));

export const selectStartDate = (state: RootState) => state.dashboard.datePicker.startDate;
export const selectEndDate = (state: RootState) => state.dashboard.datePicker.endDate;
export const selectTemporalUnit = (state: RootState) => state.dashboard.datePicker.temporalUnit;

export const selectDateRange = createSelector(
  [selectStartDate, selectEndDate, selectTemporalUnit],
  (startDate, endDate, temporalUnit) => ({
    startDate,
    endDate,
    temporalUnit,
  }),
);

export const selectNetworksStatus = (state: RootState) => state.dashboard.networks.status;
export const selectNetworks = (state: RootState) => state.dashboard.networks.list;
export const selectSelectedNetworks = (state: RootState) => state.dashboard.selectedNetworks;

export const selectLocationsFilterStatus = (state: RootState) => state.dashboard.locations.status;
export const selectLocationsFilter = (state: RootState) => state.dashboard.locations.list;
export const selectSelectedLocations = (state: RootState) => state.dashboard.selectedLocations;

export const selectTransactionTypesStatus = (state: RootState) => state.dashboard.transactionTypes.status;
export const selectTransactionTypes = (state: RootState) => state.dashboard.transactionTypes.list;
export const selectSelectedTransactionType = (state: RootState) => state.dashboard.selectedTransactionType;

export const selectPerformanceSummary = (state: RootState) => state.dashboard.content.performanceSummary;

export const selectActiveProgramBasicsTab = (state: RootState) => state.dashboard.selectedProgramBasicsTab;

export const selectBehaviorWalkthrough = (state: RootState) => state.dashboard.behaviorWalkthrough;
export const selectHasUserClosedSunocoBanner = (state: RootState) => state.dashboard.hasUserClosedSunocoBanner;

export const selectMasqueradeLists = (state: RootState) => state.dashboard.masqueradingLists;

export const selectSunocoSites = (state: RootState) => state.dashboard.generalConfigs.sunocoSites;

export const selectIsDemo = (state: RootState) => state.dashboard.isDemo;

export default dashboardSlice.reducer;
