import axios from 'axios';
import { call, put, takeLatest } from 'typed-redux-saga';

import * as api from './api';
import { behaviorFailed, behaviorFetchRequest, behaviorSucceeded } from './slice';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* fetchBehavior(action: any) {
  try {
    const result = yield* call(api.fetchBehavior, action.payload);

    yield* put(behaviorSucceeded(result));
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield* put(behaviorFailed(error.message));
    }
  }
}

export function* watchBehavior() {
  yield* takeLatest(behaviorFetchRequest, fetchBehavior);
}
