/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Button, Circle, Flex, Link, Text } from '@chakra-ui/react';
import _ from 'lodash';

import { useAppSelector } from '@/app/hooks';
import { Verticals } from '@/common/interfaces';
import { displayCurrency } from '@/common/utils';
import { compactLargeNumber } from '@/common/utils/utils';
import { selectActiveVertical } from '@/features/auth/userSlice';
import { selectBehaviorWalkthrough } from '@/features/dashboard/slice';

import { BehaviorInfoLink } from './BehaviorInfo';
import { isDisabled } from './helper';

export const PrePostTextHandler = (props: any) => {
  const { t } = useTranslation();
  const behaviorWalkthroughState = useAppSelector(selectBehaviorWalkthrough);
  const { walkthroughStartHandler, selectedData, dropdownItems, increasedValue } = props;

  if (!selectedData?.[0]) return null;

  const upsideAfterUpside3 = displayCurrency(selectedData[0].data[1]['Upside Customer']);
  const similarAfterUpside3 = displayCurrency(selectedData[0].data[1]['Similar Customer']);

  return (
    <>
      {behaviorWalkthroughState && behaviorWalkthroughState.step === 0 && (
        <>
          <Text textStyle='body.md'>
            {t(`behaviorPage.prePostVsControl.information.${dropdownItems[0].selectedItem}`, { value: increasedValue })}
          </Text>
          <Box>
            <Button size='md' variant='brandSecondary' onClick={() => walkthroughStartHandler(1)}>
              {t('behaviorPage.walkthroughGeneral.showExplanation')}
            </Button>
          </Box>
        </>
      )}
      {behaviorWalkthroughState && behaviorWalkthroughState.step === 1 && (
        <Text textStyle='body.md'>{t('behaviorPage.prePostWalkthrough.step1')}</Text>
      )}
      {behaviorWalkthroughState && behaviorWalkthroughState.step === 2 && (
        <Box>
          <Text mb={'16px'} textStyle='body.md'>
            {t('behaviorPage.prePostWalkthrough.step2')}
          </Text>
          <BehaviorInfoLink />
        </Box>
      )}
      {behaviorWalkthroughState && behaviorWalkthroughState.step === 3 && (
        <Box textStyle='body.md'>
          <Trans
            i18nKey={t('behaviorPage.prePostWalkthrough.step3')}
            components={{ bold: <strong /> }}
            values={{ upsideAfterUpside3, similarAfterUpside3 }}
          />
        </Box>
      )}
      {behaviorWalkthroughState && behaviorWalkthroughState.step >= 4 && (
        <Text textStyle='body.md'>{t('behaviorPage.prePostWalkthrough.step4')}</Text>
      )}
    </>
  );
};

export const OnOffTextHandler = (props: any) => {
  const { t } = useTranslation();
  const behaviorWalkthroughState = useAppSelector(selectBehaviorWalkthrough);
  const activeVertical = useAppSelector(selectActiveVertical);
  const { newAndExistingSalesTotal, upsideLaunchDate, nonPromoSales, walkthroughStartHandler, selectedData } = props;

  if (!selectedData?.[0]) return null;

  const nonUpsideBefore5 = selectedData[0] && compactLargeNumber(selectedData[0].data[0]['Non-Upside'] as number);
  const nonUpsideAfter6 =
    selectedData[0] &&
    compactLargeNumber(
      (selectedData[0].data[1]['Non-Upside'] as number) + (selectedData[0].data[1]['Upside sales'] as number),
    );
  const nonUpsideDiff6 =
    selectedData[0] &&
    compactLargeNumber(
      (selectedData[0].data[1]['Non-Upside'] as number) +
        (selectedData[0].data[1]['Upside sales'] as number) -
        (selectedData[0].data[0]['Non-Upside'] as number),
    );

  const upsideAfter = selectedData[0] && compactLargeNumber(selectedData[0].data[1]['Upside sales'] as number);
  const nonUpsideAfter = selectedData[0] && compactLargeNumber(selectedData[0].data[1]['Non-Upside'] as number);

  return (
    <>
      {behaviorWalkthroughState && behaviorWalkthroughState.step < 5 && (
        <>
          <Box textStyle='body.md'>
            <Text mb={'24px'}>
              {t('behaviorPage.onOffGraph.blurb2', { newAndExistingSalesTotal, upsideLaunchDate })}
            </Text>
            <Text>{t('behaviorPage.onOffGraph.blurb3', { nonPromoSales })}</Text>
          </Box>
          <Box>
            <Button size='md' variant='brandSecondary' onClick={() => walkthroughStartHandler(5)}>
              {t('behaviorPage.walkthroughGeneral.showExplanation')}
            </Button>
          </Box>
        </>
      )}
      {behaviorWalkthroughState && behaviorWalkthroughState.step === 5 && (
        <>
          <Box>
            <Text textStyle='body.md'>{t('behaviorPage.onOffWalkthrough.step1.blurb1', { nonUpsideBefore5 })}</Text>
            <Text mt={4} textStyle={'body.md'}>
              {t('behaviorPage.onOffWalkthrough.step1.blurb2')}
            </Text>
          </Box>
        </>
      )}
      {behaviorWalkthroughState && behaviorWalkthroughState.step === 6 && (
        <Flex direction={'column'} justifyContent={'space-between'} textStyle='body.md'>
          <Text>{t(`behaviorPage.onOffWalkthrough.step2.blurb1`, { nonUpsideAfter6, nonUpsideDiff6 })}</Text>
          <Text mt={'16px'} mb={'16px'}>
            {t(`behaviorPage.onOffWalkthrough.step2.blurb2`)}
          </Text>
          {activeVertical === Verticals.Fuel && (
            <Link
              href='https://fuelknowledgecenter.upside.com/hc/en-us/articles/10714659644571-The-difference-between-total-and-attributable-sales'
              isExternal
            >
              <Text>{t(`behaviorPage.onOffWalkthrough.step2.blurb3`)}</Text>
            </Link>
          )}
        </Flex>
      )}
      {behaviorWalkthroughState && behaviorWalkthroughState.step === 7 && (
        <Box textStyle='body.md'>
          <Text mb={'16px'}>
            <Trans
              i18nKey={t('behaviorPage.onOffWalkthrough.step3.blurb1')}
              components={{ bold: <strong /> }}
              values={{ upsideAfter }}
            />
          </Text>
          <Text>
            <Trans
              i18nKey={t('behaviorPage.onOffWalkthrough.step3.blurb2')}
              components={{ bold: <strong /> }}
              values={{ nonUpsideAfter }}
            />
          </Text>
        </Box>
      )}
      {behaviorWalkthroughState && behaviorWalkthroughState.step >= 8 && (
        <Box textStyle='body.md'>
          <Trans
            i18nKey={t('behaviorPage.onOffWalkthrough.step4')}
            components={{ bold: <strong /> }}
            values={{ upsideAfter }}
          />
        </Box>
      )}
    </>
  );
};

export const IncrementalTextHandler = ({ walkthroughStartHandler }: any) => {
  const { t } = useTranslation();
  const behaviorWalkthroughState = useAppSelector(selectBehaviorWalkthrough);
  return (
    <>
      {behaviorWalkthroughState && behaviorWalkthroughState.step < 9 && (
        <>
          <Box textStyle='body.md'>
            <Text mb={6}>{t('behaviorPage.incrementalImpactGraph.information')}</Text>
            <Text>{t('behaviorPage.incrementalImpactGraph.information2')}</Text>
          </Box>
          <Box>
            <Button size='md' variant='brandSecondary' onClick={() => walkthroughStartHandler(9)}>
              {t('behaviorPage.walkthroughGeneral.showExplanation')}
            </Button>
          </Box>
        </>
      )}
      {behaviorWalkthroughState && behaviorWalkthroughState.step === 9 && (
        <Box textStyle='body.md'>
          <Text mb={6}>{t('behaviorPage.incrementalImpactGraph.information')}</Text>
          <Text>{t('behaviorPage.incrementalImpactGraph.information2')}</Text>
        </Box>
      )}
      {behaviorWalkthroughState && behaviorWalkthroughState.step === 10 && (
        <Text textStyle='body.md'>{t('behaviorPage.incrementalImpactWalkthrough.blurb2')}</Text>
      )}
      {behaviorWalkthroughState && behaviorWalkthroughState.step === 11 && (
        <Text textStyle='body.md'>{t('behaviorPage.incrementalImpactWalkthrough.blurb3')}</Text>
      )}
      {behaviorWalkthroughState && behaviorWalkthroughState.step === 12 && (
        <Text textStyle='body.md'>{t('behaviorPage.incrementalImpactWalkthrough.blurb4')}</Text>
      )}
      {behaviorWalkthroughState && behaviorWalkthroughState.step === 13 && (
        <Text textStyle='body.md'>{t('behaviorPage.incrementalImpactWalkthrough.blurb5')}</Text>
      )}
    </>
  );
};

type StepHandlerProps = {
  walkthroughIncreaseClickHandler: () => void;
  walkthroughExitHandler: () => void;
  walkthroughDecreaseClickHandler: () => void;
  startStep: number;
  endStep: number;
  stepAmount: number;
};

export const StepHandler = ({
  walkthroughIncreaseClickHandler,
  walkthroughExitHandler,
  walkthroughDecreaseClickHandler,
  startStep,
  endStep,
  stepAmount,
}: StepHandlerProps) => {
  const { t } = useTranslation();
  const behaviorWalkthroughState = useAppSelector(selectBehaviorWalkthrough);

  const lastStepLabel = useMemo(() => {
    if (!behaviorWalkthroughState) {
      return 0;
    }

    if (behaviorWalkthroughState.step >= endStep) {
      return stepAmount;
    }
    return behaviorWalkthroughState.step;
  }, [behaviorWalkthroughState, endStep, stepAmount]);

  const stepLabel = useMemo(() => {
    if (!behaviorWalkthroughState) {
      return 0;
    }

    const step = behaviorWalkthroughState.step % 4;

    if (step !== 0) {
      return step;
    }
    return behaviorWalkthroughState.step - 8;
  }, [behaviorWalkthroughState]);

  return (
    <>
      {behaviorWalkthroughState && behaviorWalkthroughState.step === startStep && (
        <Flex justifyContent={'space-between'} alignContent={'end'}>
          <Flex gap={4}>
            <Button
              size='md'
              rightIcon={<ChevronRightIcon boxSize={5} />}
              onClick={walkthroughIncreaseClickHandler}
              variant='brandPrimary'
            >
              {t('behaviorPage.walkthroughGeneral.startSection')}
            </Button>
            <Text alignSelf={'center'}>
              {behaviorWalkthroughState.step && 1} / {stepAmount}
            </Text>
          </Flex>
          <Button size='md' onClick={walkthroughExitHandler} variant='brandTertiary'>
            {t('behaviorPage.walkthroughGeneral.exit')}
          </Button>
        </Flex>
      )}
      {behaviorWalkthroughState &&
        behaviorWalkthroughState.step > startStep &&
        behaviorWalkthroughState.step < endStep && (
          <Flex justifyContent={'space-between'}>
            <Flex>
              <Circle
                as={Button}
                size='40px'
                borderRadius='full'
                variant='brandPrimary'
                onClick={walkthroughDecreaseClickHandler}
              >
                <ChevronLeftIcon boxSize={5} />
              </Circle>
              <Text alignSelf={'center'} ml={'24px'} mr={'24px'}>
                {stepLabel} / {stepAmount}
              </Text>
              <Circle as={Button} size='40px' variant='brandPrimary' onClick={walkthroughIncreaseClickHandler}>
                <ChevronRightIcon boxSize={5} />
              </Circle>
            </Flex>
            <Button size='md' onClick={walkthroughExitHandler} variant='brandTertiary'>
              {t('behaviorPage.walkthroughGeneral.exit')}
            </Button>
          </Flex>
        )}
      {behaviorWalkthroughState && behaviorWalkthroughState.step >= endStep && (
        <Flex justifyContent={'space-between'} alignContent={'end'}>
          <Flex>
            <Circle
              as={Button}
              size='40px'
              variant='brandPrimary'
              onClick={walkthroughDecreaseClickHandler}
              mr={'24px'}
            >
              <ChevronLeftIcon boxSize={5} />
            </Circle>
            <Text alignSelf={'center'}>
              {lastStepLabel} / {stepAmount}
            </Text>
          </Flex>
          {endStep !== 13 ? (
            <Button
              size='md'
              onClick={walkthroughIncreaseClickHandler}
              rightIcon={<ChevronRightIcon boxSize={5} />}
              variant='brandPrimary'
              isDisabled={isDisabled(endStep + 1, behaviorWalkthroughState)}
            >
              {t('behaviorPage.walkthroughGeneral.nextSection')}
            </Button>
          ) : (
            <Button size='md' onClick={walkthroughExitHandler} variant='brandPrimary'>
              {t('behaviorPage.walkthroughGeneral.finish')}
            </Button>
          )}
        </Flex>
      )}
    </>
  );
};

export const TotalLabels = (props: any) => {
  const { bars, yScale, formatter } = props;
  const labelMargin = 20;
  const groupedBars = _.groupBy(_.orderBy(bars, 'x'), 'x');

  return _.map(_.values(groupedBars), (values: any) => {
    const firstBar = _.first(values) as any;
    const total = _.sumBy(values, (innerProps: any) => innerProps.data.value) as number;
    const {
      data: { indexValue, id },
      x,
      width,
    } = firstBar;

    return total ? (
      <g transform={`translate(${x}, ${yScale(total) - labelMargin})`} key={`${indexValue}-${id}`}>
        <text
          x={width / 2}
          y={labelMargin / 2}
          textAnchor='middle'
          alignmentBaseline='central'
          style={{
            fill: 'rgb(51, 51, 51)',
            fontSize: '12px',
          }}
        >
          {formatter ? formatter(total) : total}
        </text>
      </g>
    ) : (
      <></>
    );
  });
};
