import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const secondaryStyles = defineStyle({
  backgroundColor: 'transparent',
  borderWidth: '1px',
});

const tertiaryStyles = defineStyle({
  backgroundColor: 'transparent',
  borderWidth: '0',
});

const brandPrimary = defineStyle({
  backgroundColor: 'background.primary.default',
  color: 'text.on-primary',

  _hover: {
    backgroundColor: 'background.primary.active',
  },
  _active: {
    backgroundColor: 'background.primary.active',
  },
  _disabled: {
    color: 'text.disabled',
    backgroundColor: 'background.disabled.default',
    opacity: '1',
  },
});

const brandSecondary = defineStyle({
  ...secondaryStyles,
  borderColor: 'border.primary',
  color: 'text.primary.default',

  _hover: {
    backgroundColor: 'background.nav.hover',
  },
  _active: {
    backgroundColor: 'background.nav.active',
  },
  _disabled: {
    backgroundColor: 'transparent',
    borderColor: 'border.disabled',
    color: 'text.on-disabled',
    opacity: '1',
  },
});

const brandTertiary = defineStyle({
  ...tertiaryStyles,
  color: 'text.primary.default',

  _hover: {
    color: 'text.primary.hover',
  },
  _active: {
    color: 'text.primary.active',
  },
  _disabled: {
    color: 'text.on-disabled',
    opacity: '1',
  },
});

const accentPrimary = defineStyle({
  backgroundColor: 'background.secondary.default',
  color: 'text.on-secondary',

  _hover: {
    backgroundColor: 'background.secondary.hover',
  },
  _active: {
    backgroundColor: 'background.secondary.active',
  },
  _disabled: {
    color: 'text.disabled',
    backgroundColor: 'background.disabled.default',
    opacity: '1',
  },
});

const accentSecondary = defineStyle({
  ...secondaryStyles,
  borderColor: 'border.dark',
  color: 'text.secondary.default',

  _hover: {
    backgroundColor: 'background.nav.active',
  },
  _active: {
    backgroundColor: 'background.nav.active',
  },
  _disabled: {
    backgroundColor: 'transparent',
    borderColor: 'border.disabled',
    color: 'text.on-disabled',
    opacity: '1',
  },
});

const accentTertiary = defineStyle({
  ...tertiaryStyles,
  color: 'text.secondary.default',

  _hover: {
    color: 'text.secondary.hover',
  },
  _active: {
    color: 'text.secondary.active',
  },
  _disabled: {
    color: 'text.on-disabled',
    opacity: '1',
  },
});

const successPrimary = defineStyle({
  backgroundColor: 'background.success.default',
  color: 'text.on-success',

  _hover: {
    backgroundColor: 'background.success.hover',
  },
  _active: {
    backgroundColor: 'background.success.active',
  },
  _disabled: {
    color: 'text.disabled',
    backgroundColor: 'background.disabled.default',
    opacity: '1',
  },
});

const successSecondary = defineStyle({
  ...secondaryStyles,
  borderColor: 'border.success',
  color: 'text.success.default',

  _hover: {
    backgroundColor: 'background.nav.hover',
  },
  _active: {
    backgroundColor: 'background.nav.active',
  },
  _disabled: {
    backgroundColor: 'transparent',
    borderColor: 'border.disabled',
    color: 'text.on-disabled',
    opacity: '1',
  },
});

const successTertiary = defineStyle({
  ...tertiaryStyles,
  color: 'text.success.default',

  _hover: {
    color: 'text.success.hover',
  },
  _active: {
    color: 'text.success.active',
  },
  _disabled: {
    color: 'text.on-disabled',
    opacity: '1',
  },
});

const destructivePrimary = defineStyle({
  backgroundColor: 'background.danger.default',
  color: 'text.on-danger',

  _hover: {
    backgroundColor: 'background.danger.hover',
  },
  _active: {
    backgroundColor: 'background.danger.active',
  },
  _disabled: {
    color: 'text.disabled',
    backgroundColor: 'background.disabled.default',
    opacity: '1',
  },
});

const destructiveSecondary = defineStyle({
  ...secondaryStyles,
  borderColor: 'border.error',
  color: 'text.danger.default',

  _hover: {
    backgroundColor: 'background.nav.hover',
  },
  _active: {
    backgroundColor: 'background.nav.active',
  },
  _disabled: {
    backgroundColor: 'transparent',
    borderColor: 'border.disabled',
    color: 'text.on-disabled',
    opacity: '1',
  },
});

const destructiveTertiary = defineStyle({
  ...tertiaryStyles,
  color: 'text.danger.default',

  _hover: {
    color: 'text.danger.hover',
  },
  _active: {
    color: 'text.danger.active',
  },
  _disabled: {
    color: 'text.on-disabled',
    opacity: '1',
  },
});

const onDarkPrimary = defineStyle({
  backgroundColor: 'background.default',
  color: 'text.default',

  _hover: {
    backgroundColor: 'text.subdued-inverse',
  },
  _active: {
    backgroundColor: 'text.subdued-inverse',
  },
  _disabled: {
    color: 'text.disabled',
    backgroundColor: 'background.disabled.default',
    opacity: '1',
  },
});

const onDarkSecondary = defineStyle({
  ...secondaryStyles,
  borderColor: 'border.default-inverse',
  color: 'text.default-inverse',

  _hover: {
    backgroundColor: 'background.secondary.hover',
  },
  _active: {
    backgroundColor: 'background.secondary.active',
  },
  _disabled: {
    backgroundColor: 'transparent',
    borderColor: 'border.disabled',
    color: 'text.on-disabled',
    opacity: '1',
  },
});

const onDarkTertiary = defineStyle({
  ...tertiaryStyles,
  color: 'text.default-inverse',

  _hover: {
    color: 'text.default-inverse',
  },
  _active: {
    color: 'text.default-inverse',
  },
  _disabled: {
    color: 'text.on-disabled',
    opacity: '1',
  },
});

const defaultSize = defineStyle({
  h: '56px',
});

const medium = defineStyle({
  h: '48px',
  fontSize: 'sm',
});

const small = defineStyle({
  h: '32px',
  fontSize: 'sm',
});

export const ButtonStyles = defineStyleConfig({
  baseStyle: {
    borderRadius: 'full',
    fontFamily: 'heading',
    fontSize: 'md',
    fontWeight: 'semibold',
    letterSpacing: '2xs',
    minWidth: '80px',
    px: '3',
  },
  sizes: {
    default: defaultSize,
    md: medium,
    sm: small,
  },
  variants: {
    brandPrimary,
    brandSecondary,
    brandTertiary,
    accentPrimary,
    accentSecondary,
    accentTertiary,
    successPrimary,
    successSecondary,
    successTertiary,
    destructivePrimary,
    destructiveSecondary,
    destructiveTertiary,
    onDarkPrimary,
    onDarkSecondary,
    onDarkTertiary,
  },
  defaultProps: {
    size: 'default',
  },
});

export default ButtonStyles;
