import { useParams } from 'react-router-dom';

import { useAppSelector } from '@/app/hooks';
import { Verticals } from '@/common/interfaces';
import { selectActiveVertical } from '@/features/auth/userSlice';

import { AddFuelLocation } from './components/forms/addFuelLocationForm';
import { GroceryRestaurantLocationSettings } from './components/forms/GroceryRestaurantLocationSettings';

export function AddLocation() {
  const activeVertical = useAppSelector(selectActiveVertical);
  const { step } = useParams<{ step: string }>();

  if (activeVertical !== Verticals.Fuel) {
    return <GroceryRestaurantLocationSettings />;
  }

  return <AddFuelLocation step={Number(step)} />;
}
