/* eslint-disable @typescript-eslint/no-explicit-any */
import { ElementType } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Flex,
  Icon,
  Stack,
  TabList,
  TabPanel,
  TabPanels,
  TabProps,
  Tabs,
  Text,
  useMultiStyleConfig,
  useTab,
} from '@chakra-ui/react';
import { BoxProps } from '@chakra-ui/react';
import _ from 'lodash';

import { Status } from '../../interfaces';
import { DataLoadingErrorAlert, EmptyDataAlert } from '../errorAlert';
import { LoadingSpinner } from '../loadingSpinner/LoadingSpinner';

interface UpsideTabProps extends TabProps {
  icon?: React.ElementType<any> | undefined;
}

const UpsideTab = ({ icon, children, ...props }: UpsideTabProps) => {
  const tabProps = useTab(props);
  const isSelected = !!tabProps['aria-selected'];
  const styles = useMultiStyleConfig('Tabs', tabProps);

  return (
    <Button __css={styles.tab} {...tabProps}>
      <Flex
        align='center'
        p='2'
        role='group'
        _hover={{
          color: 'text.secondary.default',
        }}
        color={isSelected ? 'text.default' : 'text.subdued'}
      >
        {icon && <Icon as={icon} color={isSelected ? 'icon.dark' : 'neutral.900'} boxSize='20px' mr='2' />}
        <Text fontSize='18px' fontWeight={isSelected ? 600 : 400}>
          {children}
        </Text>
      </Flex>
    </Button>
  );
};

UpsideTab.displayName = 'UpsideTab';

interface ITabContent {
  label: string;
  icon?: ElementType<any>;
  tabPanelContent: any;
}

interface ITabView extends BoxProps {
  tabViewContent: ITabContent[];
  data?: any;
  segmentData?: any;
  loadingStatus: Status;
  onChangeTab?: (tabIndex: number) => void;
  activeTab?: number;
}

export const TabView = ({ tabViewContent, data, segmentData, loadingStatus, onChangeTab, activeTab }: ITabView) => {
  const { t } = useTranslation();
  const isLoading = loadingStatus === Status.Loading;
  const isEmptyData = loadingStatus === Status.Idle && _.isEmpty(tabViewContent);
  if (loadingStatus === Status.Failed) {
    return <DataLoadingErrorAlert />;
  }

  return (
    <Stack
      align={isLoading || isEmptyData ? 'center' : 'default'}
      justify={isLoading || isEmptyData ? 'center' : 'default'}
      minH='40'
    >
      {isLoading && <LoadingSpinner />}
      {isEmptyData && <EmptyDataAlert />}
      {!isLoading && !isEmptyData && (
        <Tabs
          bg='white'
          borderRadius={'24'}
          p={'0px 16px 0px 16px'}
          index={activeTab ? activeTab : undefined}
          onChange={onChangeTab}
        >
          <TabList>
            {tabViewContent
              ? tabViewContent.map((tab: ITabContent, index: number) => (
                  <UpsideTab
                    key={tab.label}
                    icon={tab.icon}
                    textStyle={'body.md'}
                    borderColor={'border.subtler'}
                    backgroundColor='transparent'
                    _selected={{
                      borderColor: 'border.dark',
                      textStyle: 'body-bold.md',
                    }}
                    data-testid={`tab-${index + 1}`}
                  >
                    {t(tab.label)}
                  </UpsideTab>
                ))
              : null}
          </TabList>

          <TabPanels>
            {tabViewContent
              ? tabViewContent.map((tab: ITabContent, index: number) => (
                  <TabPanel key={tab.label} data-testid={`tab-panel-${index + 1}`}>
                    {' '}
                    {tab.tabPanelContent(data, segmentData)}{' '}
                  </TabPanel>
                ))
              : null}
          </TabPanels>
        </Tabs>
      )}
    </Stack>
  );
};
