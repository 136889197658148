import { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaPercent } from 'react-icons/fa';
import { FiLock } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightAddon,
  Radio,
  RadioGroup,
  Select,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { forEach, merge, round } from 'lodash';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { CustomFormLabel } from '@/common/components/form';
import { LoadingSpinner } from '@/common/components/loadingSpinner';
import { usePlacesAutocomplete } from '@/common/hooks';
import { useFetchProcessorCredentials } from '@/common/hooks/fetchDashboardData';
import { Status } from '@/common/interfaces';
import states from '@/common/utils/states.json';
import { selectActiveVertical, selectUserOrg, selectUserRoles } from '@/features/auth/userSlice';

import { TRANSACTION_DATA_MAPPING } from '../../constants';
import { addNewLocationRequest, selectLocationsSettingsStatus, updateLocationRequest } from '../../slice';
import { AddLocationSettingsFormData, Address, DefaultOfferSetting, LocationSettingsData } from '../../types';
import { addLocationSettingsSchema } from '../../validators';

/**
 * Calculates the margin percent for grocery and restaurant locations.
 * The default margin is stored in the location's additional properties. The real value is calculated
 * based on the `defaultOfferSettings`.
 */
function getMarginPercent(location: LocationSettingsData | undefined): number {
  let marginPercent = Number(location?.additionalProperties?.margins?.GLOBAL?.percent ?? 0.3) * 100;
  forEach(location?.settings?.defaultOfferSettings, (offerSetting: DefaultOfferSetting) => {
    if (
      location?.offerCategory &&
      offerSetting.type === `default_offer_${location.offerCategory.toLowerCase()}` &&
      offerSetting.marginPercent
    ) {
      marginPercent = offerSetting.marginPercent * 100;
    }
  });

  return round(marginPercent, 2);
}

export function GroceryRestaurantLocationSettings({ location }: { location?: LocationSettingsData }) {
  const inputRef = useRef<HTMLInputElement>();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const loadingStatus = useAppSelector(selectLocationsSettingsStatus);
  const roles = useAppSelector(selectUserRoles);
  const orgId = useAppSelector(selectUserOrg);
  const activeVertical = useAppSelector(selectActiveVertical);

  const { data: processorData, isLoading: isProcessorLoading } = useFetchProcessorCredentials(location?.uuid ?? '');

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
    control,
    setValue,
  } = useForm<AddLocationSettingsFormData>({
    resolver: zodResolver(addLocationSettingsSchema),
    defaultValues: {
      name: location?.name,
      address: {
        address1: location?.address.address1,
        address2: location?.address.address2,
        locality: location?.address.locality,
        region: location?.address.region,
        postCode: location?.address.postCode,
      },
      additionalProperties: {
        ...location?.additionalProperties,
        margins: {
          GLOBAL: {
            percent: Number(location?.additionalProperties?.margins?.GLOBAL?.percent ?? 0.3) * 100,
          },
        },
        processorCredentials: {
          username: processorData?.username,
          website: processorData?.url,
        },
      },
    },
  });

  useEffect(() => {
    if (processorData) {
      setValue('additionalProperties.processorCredentials.username', processorData.username);
      setValue('additionalProperties.processorCredentials.website', processorData.url);
    }
  }, [processorData, setValue]);

  const handlePlaceAutocomplete = (address: Address) => {
    setValue('address.address1', address.address1);
    setValue('address.postCode', address.postCode);
    setValue('address.locality', address.locality);
    setValue('address.region', address.region);
  };

  usePlacesAutocomplete(inputRef, handlePlaceAutocomplete);

  const transactionData = watch('additionalProperties.dataSources.0');
  const username = watch('additionalProperties.processorCredentials.username');

  const onSubmit = (data: AddLocationSettingsFormData) => {
    if (location) {
      const updatedLocation = merge({}, location, data) as LocationSettingsData;

      if (transactionData !== 'PROCESSOR' && updatedLocation?.additionalProperties?.processorCredentials?.username) {
        updatedLocation.additionalProperties.processorCredentials = {};
      }

      if (transactionData === 'PROCESSOR' && !updatedLocation?.additionalProperties?.processorCredentials?.password) {
        updatedLocation.additionalProperties.processorCredentials = {
          username: data.additionalProperties?.processorCredentials?.username,
          password: processorData?.password,
          website: data.additionalProperties?.processorCredentials?.website,
        };
      }

      dispatch(
        updateLocationRequest({
          location: updatedLocation,
          orgId,
          activeVertical,
          roles,
          locationId: location.uuid,
        }),
      );
      return;
    }

    dispatch(
      addNewLocationRequest({
        location: data as LocationSettingsData,
        orgId,
        activeVertical,
        roles,
      }),
    );
  };

  return (
    <Box mt='4'>
      <Heading as='h2' size='md' mb={4}>
        {t('locationsSettingsPage.forms.title')}
      </Heading>

      <FormControl as='form' onSubmit={handleSubmit(onSubmit)}>
        <CustomFormLabel htmlFor='name' mt={5}>
          {t('locationsSettingsPage.forms.locationName')}
          <Input
            type='text'
            id='name'
            placeholder='Enter a location name'
            mt={2}
            {...register('name', { required: true })}
            isInvalid={errors.name && errors.name?.message !== ''}
          />
        </CustomFormLabel>
        {errors.name && <Text variant='error'>{errors.name?.message}</Text>}

        <CustomFormLabel htmlFor='address1' mt={5}>
          {t('locationsSettingsPage.forms.locationAddress1')}
          <Controller
            name='address.address1'
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <Input
                type='text'
                id='address1'
                placeholder='Enter a street address'
                mt={2}
                {...rest}
                ref={(e) => {
                  if (!e) return;
                  ref(e);
                  inputRef.current = e;
                }}
                isInvalid={errors.address?.address1 && errors.address?.address1.message !== ''}
              />
            )}
          />
        </CustomFormLabel>
        {errors.address?.address1 && <Text variant='error'>{errors.address.address1?.message}</Text>}

        <CustomFormLabel htmlFor='address2' mt={5}>
          {t('locationsSettingsPage.forms.locationAddress2')}
          <Input type='text' id='street_2' placeholder='Address complement' mt={2} {...register('address.address2')} />
        </CustomFormLabel>

        <Flex mt={5} mb={8} gap={2}>
          <Box w='full'>
            <CustomFormLabel htmlFor='locality'>
              {t('locationsSettingsPage.forms.locationCity')}
              <Input
                type='text'
                id='locality'
                placeholder='Enter a city'
                mt={2}
                {...register('address.locality', { required: true })}
                isInvalid={errors.address?.locality && errors.address?.locality.message !== ''}
              />
            </CustomFormLabel>
            {errors.address?.locality && <Text variant='error'>{errors.address.locality?.message}</Text>}
          </Box>

          <Box w='full'>
            <CustomFormLabel htmlFor='region'>
              {t('payment.addPayout.state')}
              <Select
                id='region'
                placeholder='Select your state'
                mt={2}
                {...register('address.region', { required: true })}
                isInvalid={errors.address?.region && errors.address?.region.message !== ''}
              >
                {states.states.map((state: { name: string; code: string }) => (
                  <option key={state.code} value={state.code}>
                    {state.name}
                  </option>
                ))}
              </Select>
            </CustomFormLabel>
            {errors.address?.region && <Text variant='error'>{errors.address.region?.message}</Text>}
          </Box>
          <Box w='full'>
            <CustomFormLabel htmlFor='postCode'>
              {t('locationsSettingsPage.forms.locationZip')}

              <Input
                id='postCode'
                placeholder='Enter a zip code'
                mt={2}
                {...register('address.postCode', { required: true })}
                isInvalid={errors.address?.postCode && errors.address?.postCode.message !== ''}
              />
            </CustomFormLabel>
            {errors.address?.postCode && <Text variant='error'>{errors.address.postCode?.message}</Text>}
          </Box>
        </Flex>
        {location && (
          <>
            <Heading as='h2' size='md' mb={4}>
              {t('locationsSettingsPage.forms.globalMargin')}
            </Heading>
            <Box mb={5}>
              <CustomFormLabel htmlFor='globalMargin'>
                {t('locationsSettingsPage.forms.globalMarginField')}
                <InputGroup borderWidth='thin' borderRadius='lg' borderColor='border.default' maxW='250px' mt={2}>
                  <Input type='number' id='globalMargin' border='none' value={getMarginPercent(location)} isReadOnly />
                  <InputRightAddon bg='none' border='none'>
                    <FaPercent />
                  </InputRightAddon>
                </InputGroup>
                <Text fontWeight='normal' mt={2}>
                  {t('locationsSettingsPage.forms.globalMarginHelper')}
                </Text>
              </CustomFormLabel>
              {errors.additionalProperties?.margins?.GLOBAL && (
                <Text variant='error'>{errors.additionalProperties?.margins?.GLOBAL.message}</Text>
              )}
            </Box>
          </>
        )}
        <Heading as='h2' size='md' mb={4}>
          {t('locationsSettingsPage.forms.locationDataConnection')}
        </Heading>
        <Text>{t('locationsSettingsPage.forms.locationDataConnectionInfo')}</Text>
        <RadioGroup
          my={5}
          defaultValue={
            (location?.additionalProperties?.dataSources && location?.additionalProperties.dataSources[0]) ??
            'PROCESSOR'
          }
        >
          <VStack alignItems='flex-start'>
            {TRANSACTION_DATA_MAPPING.map((transactionData) => (
              <Radio
                key={transactionData}
                color='neutral.900'
                value={transactionData}
                {...register('additionalProperties.dataSources.0')}
              >
                <Text>{t(`locationsSettingsPage.forms.dataConnection.${transactionData}`)}</Text>
              </Radio>
            ))}
          </VStack>
        </RadioGroup>

        {transactionData === 'PROCESSOR' && (
          <>
            {!!location && (
              <Alert status='warning' color='white'>
                <AlertIcon color='white' />
                Do not type in this processor section unless you wish to submit a change to previously submitted
                processor info. Else, leave blank
              </Alert>
            )}
            {isProcessorLoading && (
              <Flex alignItems='center' justifyContent='center' my={6}>
                <LoadingSpinner />
              </Flex>
            )}
            {!isProcessorLoading && (
              <>
                <CustomFormLabel htmlFor='username' mt={5}>
                  {t('locationsSettingsPage.fuelForm.fields.loginCredentials.username')}
                  <Input
                    type='text'
                    id='username'
                    placeholder='Enter your username'
                    mt={2}
                    {...register('additionalProperties.processorCredentials.username')}
                    isInvalid={
                      errors.additionalProperties?.processorCredentials?.username &&
                      errors.additionalProperties.processorCredentials.username?.message !== ''
                    }
                  />
                </CustomFormLabel>
                {errors.additionalProperties?.processorCredentials?.username && (
                  <Text variant='error'>{errors.additionalProperties?.processorCredentials.username.message}</Text>
                )}

                <CustomFormLabel htmlFor='password' mt={5}>
                  {t('locationsSettingsPage.fuelForm.fields.loginCredentials.password')}
                  <Input
                    type='password'
                    id='password'
                    placeholder='Enter your password'
                    mt={2}
                    {...register('additionalProperties.processorCredentials.password')}
                    isInvalid={
                      errors.additionalProperties?.processorCredentials?.password &&
                      errors.additionalProperties.processorCredentials.password?.message !== ''
                    }
                  />
                </CustomFormLabel>
                {errors.additionalProperties?.processorCredentials?.password && (
                  <Text variant='error'>{errors.additionalProperties?.processorCredentials.password.message}</Text>
                )}

                <CustomFormLabel htmlFor='website' mt={5}>
                  {t('locationsSettingsPage.fuelForm.fields.loginCredentials.website')}
                  <Input
                    type='text'
                    id='website'
                    placeholder='https://'
                    mt={2}
                    required={transactionData === 'PROCESSOR' && !!username}
                    {...register('additionalProperties.processorCredentials.website', {
                      required: transactionData === 'PROCESSOR' && !!username,
                    })}
                    isInvalid={
                      errors.additionalProperties?.processorCredentials?.website &&
                      errors.additionalProperties.processorCredentials.website?.message !== ''
                    }
                  />
                </CustomFormLabel>
                {errors.additionalProperties?.processorCredentials?.website && (
                  <Text variant='error'>{errors.additionalProperties?.processorCredentials.website.message}</Text>
                )}

                <Flex gap={2} alignItems='center' my={3}>
                  <Icon color='text.success.default'>
                    <FiLock />
                  </Icon>
                  <Text color='text.success.default'>
                    {t('locationsSettingsPage.fuelForm.fields.loginCredentials.secureConnection')}
                  </Text>
                </Flex>
              </>
            )}
          </>
        )}

        <Text>{t('locationsSettingsPage.forms.locationConfirmationText')}</Text>

        <Flex mt={3} gap={5}>
          <Button as={Link} to='/settings/locations' variant='accentSecondary' size='md' px={8}>
            {t('payment.addPayout.back')}
          </Button>
          <Button
            type='submit'
            variant='brandPrimary'
            size='md'
            px={8}
            isDisabled={!isDirty || loadingStatus === Status.Loading}
            isLoading={loadingStatus === Status.Loading}
            leftIcon={loadingStatus === Status.Loading ? <Spinner /> : <></>}
          >
            {t('payment.addPayout.continue')}
          </Button>
        </Flex>
      </FormControl>
    </Box>
  );
}
