import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button, FormControl, Heading, Radio, RadioGroup, Text, VStack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { merge } from 'lodash';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { CLASS_OF_TRADE_MAPPING } from '@/features/settings/locations/constants';
import { selectFuelLocationFormData, updateFuelLocationFormData } from '@/features/settings/locations/slice';
import { AddFuelLocationClassOfTradeFormData } from '@/features/settings/locations/types';
import { addFuelLocationClassOfTradeSchema } from '@/features/settings/locations/validators';

export function ClassOfTradeForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const fuelLocationFormData = useAppSelector(selectFuelLocationFormData);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<AddFuelLocationClassOfTradeFormData>({
    resolver: zodResolver(addFuelLocationClassOfTradeSchema),
    defaultValues: {
      ...fuelLocationFormData,
    },
  });

  const onSubmit = (data: AddFuelLocationClassOfTradeFormData) => {
    const updatedData = merge({}, fuelLocationFormData, data);
    dispatch(updateFuelLocationFormData(updatedData));

    navigate('/settings/locations/new/4');
  };

  return (
    <Box mt='8'>
      <Heading as='h2' size='lg' mb={4}>
        {t('locationsSettingsPage.fuelForm.titles.classOfTrade')}
      </Heading>

      <Heading as='h3' size='sm' mb={4}>
        {t('locationsSettingsPage.fuelForm.fields.classOfTrade.helper')}
      </Heading>

      <Text>
        {t('locationsSettingsPage.fuelForm.forSite', {
          siteName: fuelLocationFormData?.name,
          siteAddress: fuelLocationFormData?.address.address1,
        })}
      </Text>

      <FormControl as='form' onSubmit={handleSubmit(onSubmit)}>
        <RadioGroup my={5} defaultValue={fuelLocationFormData?.additionalProperties.classOfTrade}>
          <VStack alignItems='flex-start'>
            {CLASS_OF_TRADE_MAPPING.map((cot) => (
              <Radio
                key={cot.value}
                color='neutral.900'
                value={cot.value}
                {...register('additionalProperties.classOfTrade')}
              >
                <Text>{cot.label}</Text>
              </Radio>
            ))}
          </VStack>
        </RadioGroup>
        {errors.additionalProperties?.classOfTrade && (
          <Text color='red.500'>{errors.additionalProperties.classOfTrade.message}</Text>
        )}

        <Button type='submit' variant='brandPrimary' size='md' px={8}>
          {t('locationsSettingsPage.fuelForm.actions.continue')}
        </Button>
      </FormControl>
    </Box>
  );
}
