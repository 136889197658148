import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Td, Text, Tr } from '@chakra-ui/react';

import { useAppSelector } from '@/app/hooks';
import { usePermissions } from '@/common/hooks';
import { selectIsUpsideManager } from '@/features/auth/userSlice';

import { LocationSettingsData, LocationStatus } from '../types';

export const LocationsTableCell = ({ data }: { data: LocationSettingsData }) => {
  const { t } = useTranslation();
  const isUpsideManager = useAppSelector(selectIsUpsideManager);

  const { hasLocationsSettingsManagerAccess } = usePermissions();

  const status = useMemo(() => {
    if (data.relationshipType === LocationStatus.HISTORICAL) {
      return {
        color: 'red.500',
        label: t('locationsSettingsPage.locationsTbl.status.inactive'),
      };
    }

    if (data.visibility === LocationStatus.DEFAULT) {
      return {
        color: 'green.500',
        label: t('locationsSettingsPage.locationsTbl.status.live'),
      };
    }

    if (data.visibility === LocationStatus.UNLISTED) {
      return {
        color: 'red.500',
        label: t('locationsSettingsPage.locationsTbl.status.inactive'),
      };
    }

    return {
      color: 'gray.400',
      label: t('locationsSettingsPage.locationsTbl.status.notLive'),
    };
  }, [data.visibility, t, data.relationshipType]);

  const formattedAddress = useMemo(() => {
    const address2 = data.address.address2 ? data.address.address2 : '';
    return `${data.address.address1} ${address2}, ${data.address.locality}, ${data.address.region}`;
  }, [data.address]);

  return (
    <Tr>
      <Td width={isUpsideManager || hasLocationsSettingsManagerAccess ? '80%' : '90%'}>
        <Text>{data.name}</Text>
        <Text fontSize='xs' color='text.subdued'>
          {formattedAddress}
        </Text>
      </Td>
      <Td>
        <Text color={status.color}>{status.label}</Text>
      </Td>
      {(isUpsideManager || hasLocationsSettingsManagerAccess) && (
        <Td textAlign='right'>
          {data.relationshipType !== 'HISTORICAL' && (
            <Link as={RouterLink} to={`manage/${data.uuid}`}>
              {t('locationsSettingsPage.locationsTbl.actions.edit')}
            </Link>
          )}
        </Td>
      )}
    </Tr>
  );
};
