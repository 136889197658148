import { Center, Heading, Stack, Text, useBreakpointValue, VStack } from '@chakra-ui/react';

import { IStat } from './BannerStat';

export const BannerStatContent = ({ label = '0', value = '', ...boxProps }: IStat) => {
  return (
    <Stack {...boxProps}>
      <Center>
        <VStack justify='space-between'>
          <Heading size={useBreakpointValue({ base: 'md', md: 'lg' })}>{value.toLocaleString()}</Heading>
          <Text textStyle='subheader.lg'>{label}</Text>
        </VStack>
      </Center>
    </Stack>
  );
};
