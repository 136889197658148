import { defineStyleConfig, Tooltip as ChakraTooltip } from '@chakra-ui/react';

const Tooltip = defineStyleConfig({
  baseStyle: {
    bg: 'background.secondary.default',
    color: 'text.default-inverse',
    textStyle: 'body.sm',
    borderRadius: 'lg',
    px: 4,
    py: 1,
  },
});

ChakraTooltip.defaultProps = {
  ...ChakraTooltip.defaultProps,
  bg: 'background.secondary.default',
  hasArrow: true,
};

export default Tooltip;
