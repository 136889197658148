import { Alert, AlertDescription, AlertIcon, Flex, Link } from '@chakra-ui/react';
import { useDecision } from '@optimizely/react-sdk';
import { AnimatePresence, motion } from 'framer-motion';

export function MaintenanceBanner() {
  const [maintenanceBannerFlag] = useDecision('md-1100');

  if (!maintenanceBannerFlag.enabled) {
    return null;
  }

  return (
    <AnimatePresence>
      <Alert
        as={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        status='info'
        alignItems='flex-start'
        mb={10}
        bgColor='background.primary.default'
      >
        <AlertIcon color='white' />
        <Flex direction='column' color='white'>
          <AlertDescription>
            The Upside Dashboard is currently experiencing data availability issues. Our team is actively working to
            resolve them. If you have questions or need support, please reach out to your account manager or contact{' '}
            <Link color='text.default-inverse' href='mailto:businesshelp@upside.com' _hover={{ color: 'blue.200' }}>
              businesshelp@upside.com
            </Link>
            .
          </AlertDescription>
        </Flex>
      </Alert>
    </AnimatePresence>
  );
}
