import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty, mergeWith } from 'lodash';

import { Status } from '@/common/interfaces';

import { ColumnsList, CustomReportOptions, DeliveryMethodType, FileFormatType, GranularityType } from './type';

export interface ICustomReportsState {
  data: string[];
  columnsList: ColumnsList;
  reportOptions: CustomReportOptions;
  error: string | null;
  status: Status;
}

const initialState: ICustomReportsState = {
  data: [],
  columnsList: {
    transaction: [],
    summary: [],
  },
  reportOptions: {
    granularity: GranularityType.Monthly,
    startDate: '',
    endDate: '',
    includedFields: [],
    deliveryMethod: DeliveryMethodType.Direct,
    fileFormat: FileFormatType.CSV,
  },
  error: null,
  status: Status.Idle,
};

export const customReportsSlice = createSlice({
  name: 'customReports',
  initialState,
  reducers: {
    customReportsFetch: (
      state,
      _action: PayloadAction<{
        authRequestPath: string;
        options: CustomReportOptions;
        locations: string[];
        networkNames: string[];
        transactionType: string;
      }>,
    ) => {
      state.status = Status.Loading;
      state.error = null;
    },
    customReportsSucceeded: (state) => {
      state.status = Status.Idle;
    },
    customReportsFailed: (state, action: PayloadAction<string>) => {
      state.status = Status.Failed;
      state.error = action.payload;
    },
    updateReportOptions: (state, action: PayloadAction<Partial<ICustomReportsState['reportOptions']>>) => {
      state.reportOptions = mergeWith({}, state.reportOptions, action.payload, (_, srcValue) => {
        if (isEmpty(srcValue)) {
          return srcValue;
        }
        return undefined;
      });
    },
    cleanReportOptions: (state) => {
      state.reportOptions = initialState.reportOptions;
    },
    columnsListFetchRequested: (
      state,
      _action: PayloadAction<{ authRequestPath: string; transactionType: string }>,
    ) => {
      state.status = Status.Loading;
      state.error = null;
    },
    columnsListFetchSucceed: (state, action: PayloadAction<ColumnsList>) => {
      state.columnsList = action.payload;
      state.status = Status.Idle;

      if (state.reportOptions.granularity === 'transaction' && !state.reportOptions.includedFields.length) {
        state.reportOptions.includedFields = action.payload.transaction.map((column) => column.name);
      }

      if (state.reportOptions.granularity !== 'transaction' && !state.reportOptions.includedFields.length) {
        state.reportOptions.includedFields = action.payload.summary.map((column) => column.name);
      }
    },
    columnsListFetchFailed: (state, action: PayloadAction<string>) => {
      state.status = Status.Failed;
      state.error = action.payload;
    },
  },
});

export const {
  customReportsFetch,
  customReportsSucceeded,
  customReportsFailed,
  updateReportOptions,
  cleanReportOptions,
  columnsListFetchFailed,
  columnsListFetchSucceed,
  columnsListFetchRequested,
} = customReportsSlice.actions;

export const selectReportOptions = (state: { customReports: ICustomReportsState }) => state.customReports.reportOptions;
export const selectColumnsList = (state: { customReports: ICustomReportsState }) => state.customReports.columnsList;
export const selectCustomReportsStatus = (state: { customReports: ICustomReportsState }) => state.customReports.status;

export default customReportsSlice.reducer;
