import { useMemo, useState } from 'react';
import { FiInfo, FiMapPin } from 'react-icons/fi';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { ChevronDownIcon, ChevronUpIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Badge,
  Button,
  CheckboxGroup,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { LoadingSpinner } from '@components/loadingSpinner';
import { t } from 'i18next';

import { Status } from '@/common/interfaces';
import {
  selectNetworks,
  selectNetworksStatus,
  selectSelectedNetworks,
  setSelectedNetworks,
} from '@/features/dashboard/slice';

import { NetworkCheckbox } from './NetworkCheckbox';

export function NetworkSelector() {
  const dispatch = useAppDispatch();
  const networksStatus = useAppSelector(selectNetworksStatus);
  const networks = useAppSelector(selectNetworks);
  const selectedNetworks = useAppSelector(selectSelectedNetworks);

  const { onOpen, onClose, onToggle, isOpen } = useDisclosure();

  const [searchTerm, setSearchTerm] = useState('');
  const [localSelection, setLocalSelection] = useState<string[]>(() => {
    if (selectedNetworks.length) {
      return [];
    }
    return selectedNetworks;
  });

  const filteredNetworks = useMemo(() => {
    if (!networks.length) {
      return {
        list: [],
        count: 0,
      };
    }

    const filtered = networks.filter((network) => {
      return network.network_display_name.toLowerCase().includes(searchTerm.toLowerCase());
    });

    return {
      list: filtered,
      count: filtered.length,
    };
  }, [networks, searchTerm]);

  const selectAllCount = useMemo(() => {
    return !!filteredNetworks.count && !!searchTerm ? filteredNetworks.count : 0;
  }, [filteredNetworks.count, searchTerm]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectNetworks = (networks: string[]) => {
    setLocalSelection(networks);
  };

  const handleToggleSelectAll = () => {
    setLocalSelection((previousSelection) => {
      if (previousSelection.length === filteredNetworks.count) {
        return [];
      }

      const allNetworks = filteredNetworks.list.map((network) => network.network_name);
      return allNetworks;
    });
  };

  const handleFilterByNetwork = () => {
    dispatch(setSelectedNetworks(localSelection));
    onClose();
  };

  return (
    <Popover placement='bottom-start' variant='responsive' isOpen={isOpen} onOpen={onOpen} onClose={onClose} isLazy>
      <PopoverTrigger>
        <Button
          onClick={onToggle}
          variant='accentSecondary'
          borderColor='neutral.400'
          borderRadius='lg'
          data-testid='network-filter'
          size='md'
        >
          <Icon as={FiMapPin} />
          <Text ml='10px' mr='10px' lineHeight={0} fontWeight='400'>
            {t('common.networkSelector.title', { count: selectedNetworks.length }) as string}{' '}
            {!!selectedNetworks.length && (
              <Badge
                fontSize='xs'
                borderRadius='full'
                px='2'
                bg='background.information.default'
                color='text.default'
                data-testid='networks-badge'
                lineHeight='base'
              >
                {selectedNetworks.length}
              </Badge>
            )}
          </Text>
          {isOpen ? <Icon as={ChevronUpIcon} fontWeight={500} /> : <Icon as={ChevronDownIcon} fontWeight={500} />}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        minW='330px'
        maxW='340px'
        width='100%'
        boxShadow='base'
        borderRadius='lg'
        py='3'
        px='2'
        bg='background.default'
      >
        <PopoverHeader border='none' mb='4'>
          <InputGroup mb='5'>
            <InputLeftElement pointerEvents='none'>
              <SearchIcon />
            </InputLeftElement>
            <Input
              borderRadius='md'
              borderColor='neutral.500'
              type='text'
              name='search-network'
              placeholder={t('common.networkSelector.placeholder')}
              _placeholder={{
                color: 'neutral.400',
              }}
              value={searchTerm}
              onChange={handleSearch}
              isDisabled={networksStatus === Status.Loading || !networks.length}
            />
          </InputGroup>
          <Flex fontSize='sm' align='center' justify='space-between'>
            <Text textStyle='body.md'>
              {!searchTerm
                ? (t('common.networkSelector.defaultSearchText') as string)
                : (t('common.networkSelector.searchText', {
                    count: filteredNetworks.count,
                    searchTerm,
                  }) as string)}
            </Text>
            {!!filteredNetworks.count && (
              <Link fontSize='sm' fontWeight={500} onClick={handleToggleSelectAll} data-testid='select-all-networks'>
                {filteredNetworks.count === localSelection.length
                  ? (t('common.networkSelector.deselectAll', { count: selectAllCount }) as string)
                  : (t('common.networkSelector.selectAll', {
                      count: selectAllCount,
                    }) as string)}
              </Link>
            )}
          </Flex>
        </PopoverHeader>
        <PopoverBody maxH='260px' overflowY='auto' mb='4'>
          <CheckboxGroup value={localSelection} onChange={handleSelectNetworks}>
            <VStack w='full' align='start' gap='3'>
              {networksStatus === Status.Loading && (
                <Flex w='full' justify='center'>
                  <LoadingSpinner />
                </Flex>
              )}

              {networksStatus !== Status.Loading &&
                filteredNetworks.list.map((network) => (
                  <NetworkCheckbox
                    key={network.network_name}
                    value={network.network_name}
                    label={network.network_display_name}
                    count={network.live_sites}
                    highlight={searchTerm}
                  />
                ))}

              {networksStatus !== Status.Loading && !filteredNetworks.list.length && networks.length && (
                <Flex gap={2} align='flex-start' justify='center'>
                  <Icon as={FiInfo} />
                  <Text color='neutral.900' fontSize='sm' data-testid='no-result'>
                    {t('common.networkSelector.noResults') as string}
                  </Text>
                </Flex>
              )}

              {networksStatus !== Status.Loading && !networks.length && (
                <Flex gap={2} align='flex-start' justify='center' w='full'>
                  <Text color='neutral.900' fontSize='sm' textAlign='center'>
                    No networks found
                  </Text>
                </Flex>
              )}
            </VStack>
          </CheckboxGroup>
        </PopoverBody>
        <PopoverFooter border='none'>
          <Button
            fontWeight={400}
            w='full'
            variant='accentSecondary'
            size='md'
            onClick={handleFilterByNetwork}
            data-testid='select-networks'
          >
            {t('common.networkSelector.filterButton', { count: localSelection.length }) as string}
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}
