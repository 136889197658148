import { useTranslation } from 'react-i18next';
import { InfoIcon } from '@chakra-ui/icons';
import { Flex, Heading, Stack, Text, Tooltip } from '@chakra-ui/react';
import _ from 'lodash';

import { Status } from '../../interfaces';
import { LoadingSpinner } from '../loadingSpinner/LoadingSpinner';

import { IMetricCardContent } from './types';

const DEFAULT_EMPTY_CONTENT = '-';
const CONTENT_ROOT = 'homePage.metricCards';

export const MetricContent = ({
  metricLabel,
  metricData,
  loadingStatus,
  vertical,
  ...boxProps
}: IMetricCardContent) => {
  const { t } = useTranslation();
  const cardTitle = t(`${CONTENT_ROOT}.${metricLabel}.title`);
  const tooltipContent = t(`${CONTENT_ROOT}.${metricLabel}.tooltip`);
  const lowerLabel = t(`${CONTENT_ROOT}.${metricLabel}.${vertical}.lowerLabel`);
  const isLoading = loadingStatus === Status.Loading;
  let data = metricData;
  if (loadingStatus === Status.Failed) {
    data = undefined;
  }

  return (
    <Stack {...boxProps} w='100%' fontSize='14px' style={{ fontFeatureSettings: `'ss01'` }}>
      <Flex alignItems='center' gap={2} mb={1} opacity={isLoading ? '20%' : '100%'}>
        <Text textStyle='body.md' lineHeight={0}>
          {cardTitle}
        </Text>
        {_.isEmpty(tooltipContent) ? null : (
          <Tooltip label={tooltipContent} placement='right'>
            <InfoIcon color='icon.subtle' w={4} h={4} />
          </Tooltip>
        )}
      </Flex>
      <Stack w='100%' align={isLoading ? 'center' : 'initial'} justify={isLoading ? 'center' : 'initial'}>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <Heading data-testid='stats-header' size='lg'>
              {data?.value ?? DEFAULT_EMPTY_CONTENT}
            </Heading>
            {data?.altValue && (
              <Text textStyle='body.xs'>{`${data?.altValue ?? DEFAULT_EMPTY_CONTENT + '%'} ${lowerLabel}`}</Text>
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
};
