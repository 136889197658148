import { Outlet } from 'react-router-dom';
import { Container, Flex, Grid, GridItem, useBreakpointValue } from '@chakra-ui/react';
import { PermissionErrorPanel } from '@components/errorAlert';
import _ from 'lodash';

import { useAppSelector } from '@/app/hooks';
import {
  useFetchBehavior,
  useFetchDashboardData,
  useFetchHeaderData,
  useFetchInvoices,
  useFetchPayouts,
} from '@/common/hooks';
import { useFetchUserProfile } from '@/common/hooks/fetchUserSettings';

import { selectUserOrg, selectUserRoles } from '../auth/userSlice';

import { ContentHeader } from './components/contentHeader';
import { NavBar } from './components/nav';
import { SideBar } from './components/nav';

export const Dashboard = () => {
  const orgId = useAppSelector(selectUserOrg);
  const roles = useAppSelector(selectUserRoles);

  useFetchHeaderData();
  useFetchDashboardData();
  useFetchBehavior();
  useFetchPayouts();
  useFetchInvoices();
  useFetchUserProfile();

  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const isRoleMissing = Boolean(orgId && _.isEmpty(roles));

  return (
    <Flex as='section' minW={'350px'} direction={{ base: 'column', lg: 'row' }} height='100vh' overflowY='auto'>
      {isDesktop ? <SideBar hideMainNav={isRoleMissing} /> : <NavBar hideMainNav={isRoleMissing} />}

      {isRoleMissing ? (
        <Flex flexDirection='column' flex={1} background='neutral.100' justifyContent='center' alignItems='center'>
          <PermissionErrorPanel />
        </Flex>
      ) : (
        <Grid templateAreas={`"header" "content"`} gridTemplateRows={'auto 1fr'} flex={1}>
          <GridItem bg='neutral.50' area={'header'} pt={4}>
            <ContentHeader />
          </GridItem>
          <GridItem
            overflowY='auto'
            overflowX={{ base: 'hidden', md: 'auto' }}
            bg='neutral.50'
            area={'content'}
            py='4'
            px='8'
            flex='1'
          >
            <Container maxW='container.xl' px={0}>
              <Outlet />
            </Container>
          </GridItem>
        </Grid>
      )}
    </Flex>
  );
};
