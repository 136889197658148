import { Flex, Heading } from '@chakra-ui/react';

import { ChartDropdownMenu } from '../chartdropdownMenu';

import { IBarGraphHeaderProps } from './types';

export const BarGraphHeader = ({ graphLabel, dropdownMenuProps, opacity }: IBarGraphHeaderProps) => {
  return (
    <Flex opacity={opacity} direction='row' justify='space-between'>
      <Heading size='md'>{graphLabel}</Heading>

      <Flex gap={3}>
        {dropdownMenuProps &&
          !!dropdownMenuProps.length &&
          dropdownMenuProps.map((props) => <ChartDropdownMenu key={props.selectedItem} {...props} />)}
      </Flex>
    </Flex>
  );
};
