const semanticTokens = {
  colors: {
    text: {
      default: 'neutral.1200',
    },
    'text.default': {
      default: 'neutral.1200',
    },
    'text.subdued': {
      default: 'neutral.900',
    },
    'text.subdued-inverse': {
      default: 'neutral.700',
    },
    'text.placeholder': {
      default: 'neutral.800',
    },
    'text.disabled': {
      default: 'neutral.700',
    },
    'text.disabled-inverse': {
      default: 'neutral.400',
    },
    'text.on-disabled': {
      default: 'neutral.700',
    },
    'text.default-inverse': {
      default: 'neutral.50',
    },
    'text.default-inverse-active': {
      default: 'neutral.500',
    },
    'text.primary-inverse': {
      default: 'blue.100',
    },
    'text.on-warning': {
      default: 'neutral.50',
    },
    'text.on-primary': {
      default: 'neutral.50',
    },
    'text.on-secondary': {
      default: 'neutral.50',
    },
    'text.warning': {
      default: 'orange.600',
    },
    'text.on-success': {
      default: 'neutral.50',
    },
    'text.on-danger': {
      default: 'neutral.50',
    },

    'text.primary.default': {
      default: 'blue.800',
    },
    'text.primary.hover': {
      default: 'blue.900',
    },
    'text.primary.active': {
      default: 'blue.1000',
    },
    'text.secondary.default': {
      default: 'neutral.1200',
    },
    'text.secondary.hover': {
      default: 'neutral.1000',
    },
    'text.secondary.active': {
      default: 'neutral.900',
    },
    'text.success.default': {
      default: 'green.600',
    },
    'text.success.hover': {
      default: 'green.700',
    },
    'text.danger.default': {
      default: 'red.700',
    },
    'text.danger.hover': {
      default: 'red.800',
    },
    'text.danger.active': {
      default: 'red.900',
    },

    'icon.default': {
      default: 'neutral.1000',
    },
    'icon.default-inverse': {
      default: 'neutral.50',
    },
    'icon.primary-inverse': {
      default: 'blue.100',
    },
    'icon.on-primary': {
      default: 'neutral.50',
    },
    'icon.on-secondary': {
      default: 'neutral.50',
    },
    'icon.on-success': {
      default: 'neutral.50',
    },
    'icon.success': {
      default: 'green.700',
    },
    'icon.warning': {
      default: 'orange.600',
    },
    'icon.danger': {
      default: 'red.700',
    },
    'icon.subtle': {
      default: 'neutral.400',
    },
    'icon.subtler': {
      default: 'neutral.200',
    },
    'icon.disabled': {
      default: 'neutral.700',
    },
    'icon.on-danger': {
      default: 'neutral.50',
    },
    'icon.on-disabled': {
      default: 'neutral.700',
    },
    'icon.on-warning': {
      default: 'neutral.50',
    },
    'icon.dark': {
      default: 'neutral.1200',
    },
    'icon.primary.default': {
      default: 'blue.800',
    },
    'icon.primary.hover': {
      default: 'blue.900',
    },
    'icon.primary.active': {
      default: 'blue.1000',
    },

    'background.default': {
      default: 'neutral.50',
    },
    'background.dark': {
      default: 'green.1000',
    },
    'background.overlay': {
      default: 'neutral.1200',
    },
    'background.primary.default': {
      default: 'blue.800',
    },
    'background.primary.hover': {
      default: 'blue.900',
    },
    'background.primary.active': {
      default: 'blue.1000',
    },
    'background.secondary.default': {
      default: 'neutral.1200',
    },
    'background.secondary.hover': {
      default: 'neutral.1000',
    },
    'background.secondary.active': {
      default: 'neutral.900',
    },
    'background.success.default': {
      default: 'green.600',
    },
    'background.success.hover': {
      default: 'green.700',
    },
    'background.success.active': {
      default: 'green.800',
    },
    'background.warning.default': {
      default: 'orange.500',
    },
    'background.warning.hover': {
      default: 'orange.600',
    },
    'background.warning.active': {
      default: 'orange.700',
    },
    'background.danger.default': {
      default: 'red.700',
    },
    'background.danger.hover': {
      default: 'red.800',
    },
    'background.danger.active': {
      default: 'red.900',
    },
    'background.disabled.subtle': {
      default: 'neutral.100',
    },
    'background.disabled.default': {
      default: 'neutral.200',
    },
    'background.disabled.dark': {
      default: 'neutral.300',
    },
    'background.decorative.blue': {
      default: 'blue.100',
    },
    'background.decorative.purple': {
      default: 'purple.100',
    },
    'background.decorative.green': {
      default: 'green.100',
    },
    'background.decorative.teal': {
      default: 'cyan.100',
    },
    'background.decorative.yellow': {
      default: 'yellow.50',
    },
    'background.decorative.orange': {
      default: 'orange.75',
    },
    'background.decorative.red': {
      default: 'red.100',
    },
    'background.nav.hover': {
      default: 'neutral.100',
    },
    'background.nav.hover-ondark': {
      default: 'neutral.100',
    },
    'background.nav.active': {
      default: 'neutral.200',
    },
    'background.whitecap.default': {
      default: 'neutralwarm.75',
    },
    'background.whitecap.light': {
      default: 'neutralwarm.50',
    },
    'background.information.default': {
      default: 'blue.50',
    },
    'background.information.hover': {
      default: 'blue.75',
    },
    'background.information.active': {
      default: 'blue.100',
    },

    'surface.default': {
      default: 'neutral.100',
    },
    'surface.raised': {
      default: 'neutral.200',
    },
    'surface.dark': {
      default: 'neutral.600',
    },
    'surface.on-dark': {
      default: 'green.1100',
    },

    'border.default': {
      default: 'neutral.700',
    },
    'border.dark': {
      default: 'neutral.1200',
    },
    'border.primary': {
      default: 'blue.800',
    },
    'border.disabled': {
      default: 'neutral.400',
    },
    'border.success': {
      default: 'green.600',
    },
    'border.error': {
      default: 'red.700',
    },
    'border.subtle': {
      default: 'neutral.400',
    },
    'border.subtler': {
      default: 'neutral.200',
    },
    'border.default-inverse': {
      default: 'neutral.50',
    },

    'brand.electricblue': {
      default: 'blue.800',
    },
    'brand.pulse': {
      default: 'green.100',
    },
    'brand.swell': {
      default: 'purple.100',
    },
    'brand.undercurrant': {
      default: 'purple.1200',
    },
    'brand.ridge': {
      default: 'cyan.50',
    },
    'brand.surge': {
      default: 'green.1000',
    },
    'brand.ether': {
      default: 'neutral.1200',
    },
    'brand.whitecap': {
      default: 'neutralwarm.75',
    },

    'data.blue': {
      default: 'blue.800',
    },
    'data.green': {
      default: 'green.400',
    },
    'data.neutral': {
      default: 'neutral.800',
    },
    'data.purple': {
      default: 'purple.500',
    },
    'data.red': {
      default: 'red.600',
    },
    'data.yellow': {
      default: 'yellow.100',
    },
    'data.orange': {
      default: 'orange.300',
    },
    'data.blue-subtle': {
      default: 'blue.200',
    },
    'data.green-subtle': {
      default: 'green.75',
    },
    'data.neutral-subtle': {
      default: 'neutral.400',
    },
    'data.purple-subtle': {
      default: 'purple.100',
    },
    'data.red-subtle': {
      default: 'red.200',
    },
    'data.yellow-subtle': {
      default: 'yellow.50',
    },
    'data.orange-subtle': {
      default: 'orange.75',
    },
  },
};

export default semanticTokens;
