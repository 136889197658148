import { addSearchParamIfValueNotNull, transactionTypeForApiCall } from '@/common/utils';
import { axiosInstance } from '@/common/utils';
import { ENV_CONFIG } from '@/config';

import { ICompetitorsRequestPayload, ILocationsRequestPayload } from './types';

const LOCATIONS_URL = `${ENV_CONFIG.API_URL}/locations`;

export async function fetchLocations({
  startDate,
  endDate,
  temporalUnit,
  authRequestPath,
  locations,
  networkNames,
  transactionType,
}: ILocationsRequestPayload) {
  try {
    const url = new URL(`${LOCATIONS_URL}/${authRequestPath}`);
    url.searchParams.append('startDate', startDate);
    url.searchParams.append('endDate', endDate);
    url.searchParams.append('temporalUnit', temporalUnit);
    addSearchParamIfValueNotNull(url, 'transactionType', transactionTypeForApiCall(transactionType));

    const response = await axiosInstance.post(url.toString(), {
      networkNames,
      locations,
    });

    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch locations');
  }
}

export async function fetchCompetitors({ authRequestPath, locations, competitorType }: ICompetitorsRequestPayload) {
  try {
    const response = await axiosInstance.post(`${LOCATIONS_URL}/competitors/${authRequestPath}`, {
      locations,
      competitors_type: competitorType,
    });

    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch competitors');
  }
}
