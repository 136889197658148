const sizes = {
  jumbo: {
    fontSize: '6xl',
    fontWeight: 'bold',
    letterSpacing: '2xs',
    lineHeight: '4xl',
  },
  xxl: {
    fontSize: '5xl',
    fontWeight: 'bold',
    letterSpacing: '2xs',
    lineHeight: '5xl',
  },
  xl: {
    fontSize: '4xl',
    fontWeight: 'bold',
    letterSpacing: '2xs',
    lineHeight: '4xl',
  },
  lg: {
    fontSize: '2xl',
    fontWeight: 'bold',
    letterSpacing: '2xs',
    lineHeight: '5xl',
  },
  md: {
    fontSize: 'xl',
    fontWeight: 'bold',
    letterSpacing: '2xs',
    lineHeight: '6xl',
  },
  sm: {
    fontSize: 'lg',
    fontWeight: 'bold',
    letterSpacing: '2xs',
    lineHeight: '5xl',
  },
};

const HeadingStyles = {
  sizes,
  baseStyle: {
    fontFamily: 'heading',
    fontWeight: 'bold',
    letterSpacing: '2xs',
    color: 'text.default',
  },
};

export default HeadingStyles;
