import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes as ReactRoutes, useLocation } from 'react-router-dom';

import { DefaultLayout } from '@/common/components/layouts/defaultLayout';
import { Login } from '@/features/auth';
import { AuthGuard, UnAuthGuard } from '@/features/auth/components/authGuard/index';
import { FeatureGuard } from '@/features/auth/components/featureGuard';
import { Default } from '@/features/dashboard/components/default';
import { Dashboard } from '@/features/dashboard/Dashboard';
import { Explore } from '@/features/explore';
import { Behavior } from '@/features/explore/behavior';
import { Capacity } from '@/features/explore/capacity';
import { CustomerDeepDive, CustomerSummary } from '@/features/explore/customerDeepDive';
import { Locations } from '@/features/explore/locations';
import { Home } from '@/features/home';
import { Registration } from '@/features/registration';
import { RegistrationStart } from '@/features/registration/RegistrationStart';
import { Reports } from '@/features/reports';
import { Customers } from '@/features/reports/customers';
import { CustomReports } from '@/features/reports/customReports/CustomReports';
import { Invoices } from '@/features/reports/financialReports/invoices';
import { Payouts } from '@/features/reports/financialReports/payouts';
import { Statements } from '@/features/reports/financialReports/statements';
import { Profitability } from '@/features/reports/profitability';
import { Promotions } from '@/features/reports/promotions';
import { AddLocation, LocationsSettings, ManageLocation } from '@/features/settings/locations';
import { Notifications } from '@/features/settings/notification';
import { ManageNotifications } from '@/features/settings/notification/ManageNotifications';
import { AddPayout, ManagePayment, Payment } from '@/features/settings/payment';
import { AddBankAccount } from '@/features/settings/payment/billingPaymentMethods/bankAccount/AddBankAccount';
import { AddCreditCard } from '@/features/settings/payment/billingPaymentMethods/creditCard/AddCreditCard';
import { Stripe } from '@/features/settings/payment/billingPaymentMethods/Stripe';
import { ManagePayoutBankAccount } from '@/features/settings/payment/payoutMethod/ManagePayoutBankAccount';
import { Profile } from '@/features/settings/profile';
import { Profiles } from '@/features/settings/profile';
import { Support } from '@/features/support';

/*
This is the main routing (navigation) for the Merchant Dashboard
*/
export const Routes = () => {
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    const parsedUrl = location.pathname.split('/');
    let title = '';
    for (let i = 1; i < parsedUrl.length; i++) {
      title += parsedUrl[i] + ' ';
    }
    document.title = `${title} | Upside`;
  }, [location.pathname, t]);

  // Read more here:
  // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
  // As what Dima reported, switching back to regular Routes fixes the page re-rendering and calling APIs upon hitting resize breakpoints.
  // Going to follow up with a future backlog ticket to revisit this.
  //const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <>
      <ReactRoutes>
        {/* Unauthenticated Access */}
        <Route element={<DefaultLayout />}>
          <Route element={<UnAuthGuard Component={<FeatureGuard Component={DefaultLayout} />} />}>
            {/* Login */}
            <Route path='/' element={<Navigate to='/login' />} />
            <Route path='/login' element={<Login />} />

            {/* Registration */}
            <Route path='/registration/*' element={<Registration />}>
              <Route path=':step/*' element={<RegistrationStart />} />
            </Route>
          </Route>

          {/* Currently not in use, but is advised by Auth0. Will take a follow up effort to determine whether or not to proceed */}
          {/* https://developer.auth0.com/resources/guides/spa/react/basic-authentication#quick-react-setup  */}
          {/* <Route path='/callback' element={<CallbackPage />} /> */}

          {/* Authenticated Access */}
          {/* AuthGuard will redirect to /login for any routes below & isn't authenticated */}
          {/* FeatureGuard will protect the user from any unauthorized routes their role don't permit */}
          <Route element={<AuthGuard Component={<FeatureGuard Component={Dashboard} />} />}>
            <Route path='/home' element={<Home />} />
            <Route path='/businessImpact' element={<Default />} />
            <Route path='/support' element={<Support />} />

            {/* Reports */}
            <Route path='/reports'>
              <Route index element={<Reports />} />
              <Route path='profitability' element={<Profitability />} />
              <Route path='customers' element={<Customers />} />
              <Route path='promotions' element={<Promotions />} />
              {/* Financial Reports */}
              <Route path='invoices' element={<Invoices />} />
              <Route path='statements' element={<Statements />} />
              <Route path='payouts' element={<Payouts />} />
              <Route path='customReports/*' element={<CustomReports />} />
            </Route>

            {/* Explore */}
            <Route path='/explore'>
              <Route index element={<Explore />} />

              <Route path='behavior' element={<Behavior />} />

              <Route path='customerDeepDive'>
                <Route index element={<CustomerDeepDive />} />
                <Route path='customer' element={<CustomerSummary />} />
              </Route>

              <Route path='locations' element={<Locations />} />
              <Route path='capacity' element={<Capacity />} />
            </Route>

            {/* Settings */}
            {/* Makes the default redirect path was "/settings/payments", but this doesn't fit all possible combinations of roles */}
            {/* Need to add in some dynamic logic to determine the "default" pending on user context */}
            {/* For now, a safe bet is going back to "home" */}
            <Route path='/settings' element={<Navigate to='/home' />} />
            <Route path='/settings'>
              <Route path='payments'>
                <Route index element={<Payment />} />

                <Route path='payout'>
                  <Route path='manage/:id' element={<ManagePayoutBankAccount />} />
                  <Route path='add-bank-account' element={<AddPayout />} />
                </Route>

                <Route path='billing' element={<Stripe />}>
                  <Route path='manage/:id' element={<ManagePayment />} />
                  <Route path='add-credit-card' element={<AddCreditCard />} />
                  <Route path='add-bank-account' element={<AddBankAccount />} />
                </Route>
              </Route>

              <Route path='locations'>
                <Route index element={<LocationsSettings />} />
                <Route path='new' element={<AddLocation />}>
                  <Route path=':step/*' element={<AddLocation />} />
                </Route>
                <Route path='manage/:id' element={<ManageLocation />} />
              </Route>

              <Route path='notification'>
                <Route index element={<Notifications />} />
                <Route path='manage/:email' element={<ManageNotifications />} />
              </Route>
            </Route>

            {/* Profile */}
            <Route path='/profile' element={<Profile />} />
            <Route path='/profiles' element={<Profiles />} />
          </Route>
        </Route>
        <Route path='*' element={<Navigate to='/' />} />
      </ReactRoutes>
    </>
  );
};
