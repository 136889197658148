export { PaymentMethodTypes } from './payments';
export type { UserRoles } from './permissions';
export { ManagerRoles, OtherRoles, ReadOnlyRoles, VerticalBasedRoles, Verticals } from './permissions';
export type { BasePayload, BaseRequest, IAuthRequestPath } from './requests';
export { INITIAL_TRANSACTION_TYPE, TransactionTypes } from './transactions';

export enum Status {
  Idle = 'idle',
  Loading = 'loading',
  Failed = 'failed',
}

export enum CapacityByLocationDropdown {
  OperatingHours = 'Operating hours',
  PeakHours = 'Peak hours',
}

export interface RequestPayload {
  authRequestPath: string;
}
