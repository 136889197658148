import * as Sentry from '@sentry/react';

import { axiosInstance } from '@/common/utils';

import { formatData } from './dataFormatting';
import { IBehavior } from './types';

export const fetchBehavior = async ({
  authRequestPath,
  networkNames,
  locations,
}: {
  startDate: string;
  endDate: string;
  authRequestPath: string;
  networkNames: string[];
  locations: string[];
}): Promise<IBehavior> => {
  try {
    const result = await axiosInstance.post(`/behavior/${authRequestPath}`, {
      networkNames,
      locations,
    });

    return formatData(result.data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

// A mock function to mimic making an async request for data
export const mockFetchBehavior = () => {
  return new Promise<IBehavior>((resolve) => setTimeout(() => resolve({} as IBehavior), 500));
};
