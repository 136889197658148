import { NavLink } from 'react-router-dom';
import { ArrowForwardIcon, InfoIcon } from '@chakra-ui/icons';
import { Box, Button, Container, Flex, SimpleGrid, Text, Tooltip } from '@chakra-ui/react';
import { FunnelGraph } from '@components/funnelGraph';
import { LineGraph } from '@components/lineGraph';
import { Stats } from '@components/stats';
import { Table } from '@components/table';

import { displayNumber, getAmountOfTicksToRender } from '@/common/utils/utils';
import { TemporalUnit } from '@/features/dashboard/slice';
import colors from '@/theme/foundations/colors';

import i18n from '../../i18n';
import { IRefinedPerformanceSummary } from '../dashboard/types';
import { CardsViewContent } from '../explore/behavior/CardsContentGenerator';
import { ControlChartIncrementalityStats } from '../explore/behavior/IncrementalityStats';

// doesn't like theme tokens "data.yellow" here, hence colors
export const FUNNEL_SEGMENT_COLORS = [colors.cyan[400], colors.cyan[200], colors.cyan[75]];
export const LINE_GRAPH_COLORS: string[] = [colors.blue[800], colors.blue[200]];

const funnelLabels = [
  [
    'homePage.programBasics.reachTab.funnel.uniqueUsersSeeingOffers',
    'homePage.programBasics.reachTab.funnel.tooltipSeeing',
  ],
  [
    'homePage.programBasics.reachTab.funnel.uniqueUsersClaimingOffers',
    'homePage.programBasics.reachTab.funnel.tooltipClaiming',
  ],
  [
    'homePage.programBasics.reachTab.funnel.uniqueUsersTransacting',
    'homePage.programBasics.reachTab.funnel.tooltipTransacting',
  ],
];

export const funnelButtonLabel = (label: Array<string>, id: string | number) => (
  <Flex key={id}>
    <Text pr={2}>{i18n.t(label[0]) as string}</Text>
    <Tooltip label={i18n.t(label[1]) as string} placement='right'>
      <InfoIcon color={'neutral.200'} w={4} h={4} mt={1} />
    </Tooltip>
  </Flex>
);

export const reachTabPanelContent = (summaryGraphData: IRefinedPerformanceSummary) => (
  <>
    <Stats stats={summaryGraphData.reachTransIncremTabs?.reachStats?.statCards} />
    <FunnelGraph
      data={summaryGraphData.reachTransIncremTabs?.reachStats?.funnelGraph}
      title={'homePage.programBasics.reachTab.funnel.label'}
      colors={FUNNEL_SEGMENT_COLORS}
    />
    <Box as='section' pl={6}>
      <Container maxW='100%'>
        <SimpleGrid columns={3} gap={{ base: '2', md: '4' }}>
          {funnelLabels ? funnelLabels.map((label: Array<string>, id) => funnelButtonLabel(label, id)) : null}
        </SimpleGrid>
      </Container>
    </Box>
  </>
);

export const transactionTabPanelContent = (
  summaryGraphData: IRefinedPerformanceSummary,
  temporalUnit: TemporalUnit = 'week',
) => {
  const axisLeftFormat = () => {
    return { format: (value: number) => displayNumber(value) };
  };

  return (
    <>
      <Stats stats={summaryGraphData.reachTransIncremTabs?.transactionsStats?.statCards} />
      <LineGraph
        title='homePage.programBasics.transactionsTab.chartTitle'
        data={summaryGraphData.reachTransIncremTabs?.transactionsStats?.txUsersGraph}
        customProps={{
          colors: LINE_GRAPH_COLORS,

          axisBottom: {
            renderTick: (tick) => {
              const data = summaryGraphData.reachTransIncremTabs?.transactionsStats?.txUsersGraph[0];

              if (data) {
                const dataLength = data.data.length;

                const MULTIPLY_VALUE = getAmountOfTicksToRender(dataLength);
                const shouldRender = tick.tickIndex % MULTIPLY_VALUE <= 0;

                if (dataLength < 12 || (dataLength > 12 && shouldRender)) {
                  return (
                    <g transform={`translate(${tick.x},${tick.y + 15})`}>
                      <text
                        x={0}
                        y={0}
                        textAnchor='middle'
                        dominantBaseline='middle'
                        style={{
                          fill: 'rgb(51, 51, 51)',
                          fontSize: 12,
                          fontFamily: 'sans-serif',
                        }}
                      >
                        {tick.value}
                      </text>
                    </g>
                  );
                }

                return <></>;
              }

              return <></>;
            },
          },
          axisLeft: axisLeftFormat(),
        }}
        temporalUnit={temporalUnit}
      />
    </>
  );
};

export const incrementalityTabPanelContent = (
  summaryGraphData: IRefinedPerformanceSummary,
  temporalUnit: TemporalUnit = 'week',
) => {
  const formatNum = (value: number) => {
    if (value === 0) return '$0';
    const formatter = Intl.NumberFormat('en', {
      notation: 'compact',
      maximumFractionDigits: 1,
      minimumFractionDigits: 1,
    });
    const formatNum = `$${formatter.format(value)}`;
    return formatNum;
  };
  const axisLeftFormat = () => {
    return { format: (value: number) => formatNum(value) };
  };
  return (
    <>
      <Stats stats={summaryGraphData.reachTransIncremTabs?.incrementalityStats?.statCards} />

      <LineGraph
        title='increAndNonincreSales'
        data={summaryGraphData.reachTransIncremTabs?.incrementalityStats?.incrementalGraph}
        isCurrency
        isArea
        yScaleStacked
        customProps={{ colors: LINE_GRAPH_COLORS, axisLeft: axisLeftFormat() }}
        temporalUnit={temporalUnit}
      />
    </>
  );
};

export const prePostChartTabPanelContent = () => (
  <>
    <ControlChartIncrementalityStats />
    <CardsViewContent.PrePostControlGraph showLeftText={false} showDropdownItems={false} />
    <Flex width='100%' justify='right' pt={4}>
      <Button as={NavLink} to='/explore/behavior' borderRadius='3xl' rightIcon={<ArrowForwardIcon boxSize={5} />}>
        {i18n.t('homePage.programBasics.incrementalityTab.button') as string}
      </Button>
    </Flex>
  </>
);

export const profitabilityTabPanelContent = (summaryGraphData: IRefinedPerformanceSummary) => {
  return (
    <>
      <Stats stats={summaryGraphData.profitabilityStats?.statCards} />
      <Table data={summaryGraphData.profitStatementTable} />
      <Flex width={'100%'} justify={'right'} pt={4}>
        <Button
          as={NavLink}
          to={'/reports/profitability'}
          rightIcon={<ArrowForwardIcon boxSize={5} />}
          variant='accentSecondary'
          size='md'
        >
          {i18n.t('homePage.programBasics.profitTab.button') as string}
        </Button>
      </Flex>
    </>
  );
};

export const tabViewContentGrocery = (reachTabFlag: boolean) => {
  const tabs = [
    reachTabFlag
      ? {
          label: 'reach',
          icon: undefined,
          tabPanelContent: reachTabPanelContent,
        }
      : null,
    {
      label: 'transactions',
      icon: undefined,
      tabPanelContent: transactionTabPanelContent,
    },
    {
      label: 'incrementality',
      icon: undefined,
      tabPanelContent: incrementalityTabPanelContent,
    },
    {
      label: 'profitability',
      icon: undefined,
      tabPanelContent: profitabilityTabPanelContent,
    },
  ];

  return tabs.filter((tab) => tab !== null);
};

export const tabViewContentMV = (isBehaviorActive: boolean, reachTabFlag: boolean) => {
  const tabs = [
    reachTabFlag
      ? {
          label: 'reach',
          icon: undefined,
          tabPanelContent: reachTabPanelContent,
        }
      : null,
    {
      label: 'transactions',
      icon: undefined,
      tabPanelContent: transactionTabPanelContent,
    },
    isBehaviorActive
      ? {
          label: 'incrementality',
          icon: undefined,
          tabPanelContent: prePostChartTabPanelContent,
        }
      : null,
    {
      label: 'profitability',
      icon: undefined,
      tabPanelContent: profitabilityTabPanelContent,
    },
  ];

  return tabs.filter((tab) => tab !== null);
};
