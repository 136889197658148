import { Trans, useTranslation } from 'react-i18next';
import { Flex, Heading, Text } from '@chakra-ui/react';

export const EmptySearchResults = ({ query }: { query: string }) => {
  const { t } = useTranslation();
  return (
    <Flex flexDirection='column' justifyContent='center' alignItems='center' height='400px'>
      <Heading fontSize='18px' lineHeight='28px'>
        <Trans i18nKey='customerDeepDivePage.home.noSearchResultsTitle'>
          No search results for
          <Text as='strong'>
            <>&quot;{{ query }}&quot;</>
          </Text>
        </Trans>
      </Heading>
      <Text fontSize='14px' lineHeight='17px'>
        {t('customerDeepDivePage.home.noSearchResultsInfo')}
      </Text>
    </Flex>
  );
};
