import * as Sentry from '@sentry/react';
import _ from 'lodash';

import { UserRoles, VerticalBasedRoles, Verticals } from '@/common/interfaces';
import { UpsideManager } from '@/features/auth/permissions';

const { CStore, Fuel, Grocery, Restaurant, Unknown } = Verticals;

export const roleToVerticalRO: { [key in VerticalBasedRoles]: Verticals } = {
  CStoreReadOnly: CStore,
  FuelReadOnly: Fuel,
  GroceryReadOnly: Grocery,
  RestaurantReadOnly: Restaurant,
  UpsideReadOnly: Unknown,
  UpsideManager: Unknown,
};
export const verticalToRoleRO: Map<Verticals, VerticalBasedRoles> = new Map(
  Object.entries(_.invert(roleToVerticalRO)).map(([key, value]) => [key, value] as [Verticals, VerticalBasedRoles]),
);
export const normalizeVertical = (vertical: Verticals): string => vertical.toUpperCase().replace(/-_/g, '');
export const allVerticals = Object.values(Verticals).map((vertical) => normalizeVertical(vertical));

const RO = 'ReadOnly';

function isBaseVerticalRORole(role: string): role is VerticalBasedRoles {
  return Object.values(VerticalBasedRoles).includes(role as VerticalBasedRoles);
}

/**
 * Returns the RO role that's valid for the given active vertical AND that the user has assigned to themselves
 * via the auth0 ID token
 *
 * @param roles: UserRoles[] - list of roles assigned to the current user
 * @param activeVertical: Verticals - active vertical set in the context of the dashboard
 * @returns Verticals | null: if a valid, assigned role is found return it, else return null
 * @example
 * getVerticalReadRole( [FuelReadOnly, GroceryReadOnly], grocery) // returns GroceryReadOnly
 */
export const getVerticalReadRole = (
  roles: UserRoles[],
  activeVertical: Verticals = Verticals.Unknown,
): VerticalBasedRoles | typeof UpsideManager | null => {
  let verticalBasedRole: VerticalBasedRoles | null = null;

  if (!_.isEmpty(roles)) {
    if (roles.includes(UpsideManager)) return UpsideManager;
    const allRORoles: VerticalBasedRoles[] = roles.filter(
      (role) => role.includes(RO) || (role.includes('Upside') && isBaseVerticalRORole(role)),
    ) as unknown as VerticalBasedRoles[];

    const foundRole = allRORoles.find((role) => role.toUpperCase().includes(activeVertical.toUpperCase())) || null;

    if (foundRole && activeVertical !== Verticals.Unknown) {
      const mappedRole = verticalToRoleRO.get(activeVertical);
      verticalBasedRole = mappedRole === foundRole ? mappedRole : null;
    }
  }

  if (verticalBasedRole === null) {
    Sentry.captureException('No valid vertical-based role found for the user');
    console.error('No valid vertical-based role found for the user');
  }

  return verticalBasedRole;
};

/**
 * Returns the vertical that's valid for the given list of roles passed in from the
 * current user's auth0 ID token. Defaults to the first match if multiple verticals present
 *
 * @param roles: VerticalBasedRoles[] - list of roles assigned to the current user
 * @returns Verticals | null: return a vertical
 * @example
 * whichVerticalFromRoles( [BillingManager, GroceryReadOnly]) // returns grocery
 * whichVerticalFromRoles( [FuelReadOnly, GroceryReadOnly]) // returns fuel
 * whichVerticalFromRoles( [LocationSettingsReadOnly, GroceryReadOnly]) // returns grocery
 */
export const whichVerticalFromRoles = (roles: UserRoles[]): Verticals => {
  let activeVertical = Unknown;

  if (!_.isEmpty(roles)) {
    // Account for other non-vertical "ReadOnly" roles like "LocationSettingsReadOnly"
    const filteredRoles = roles.filter((role) =>
      ['Grocery', 'Fuel', 'Restaurant', 'Upside'].some((keyword) => role.includes(keyword)),
    );
    const readRoles = filteredRoles.filter((role) => role.includes(RO) || role.includes('UpsideManager')) || [];

    if (readRoles) {
      activeVertical = roleToVerticalRO[readRoles[0] as unknown as VerticalBasedRoles];
    }
  }

  return activeVertical;
};
