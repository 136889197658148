import { endOfMonth, startOfMonth } from '@/common/utils/dates';

import { DatePresets } from '../types';

export const buildPresets = (): DatePresets => {
  const today = new Date();

  const currMonthStart = startOfMonth(today);
  const currMonthEnd = endOfMonth(currMonthStart);

  const prevMonthStart = new Date(currMonthStart);
  prevMonthStart.setMonth(prevMonthStart.getMonth() - 1);
  const prevMonthEnd = endOfMonth(prevMonthStart);

  const MONTHS_PER_QUARTER = 3;
  const currQuarterIdx = Math.floor(today.getMonth() / 3);
  const currentQuarterStart = new Date(currMonthStart);
  currentQuarterStart.setMonth(currQuarterIdx * MONTHS_PER_QUARTER);
  const prevQuarterStart = new Date(currentQuarterStart);
  prevQuarterStart.setMonth(prevQuarterStart.getMonth() - MONTHS_PER_QUARTER);
  const prevQuarterEnd = new Date(currentQuarterStart);
  prevQuarterEnd.setMinutes(currentQuarterStart.getMinutes() - 1);
  const last6MonthsStart = new Date(prevMonthStart);
  last6MonthsStart.setMonth(last6MonthsStart.getMonth() - 5);
  const last6MonthsEnd = new Date(prevMonthEnd);
  const last13MonthsStart = new Date(prevMonthStart);
  last13MonthsStart.setMonth(prevMonthStart.getMonth() - 1);
  last13MonthsStart.setFullYear(last6MonthsStart.getFullYear() - 1);
  const last13MonthsEnd = new Date(prevMonthEnd);
  const thisYearStart = new Date(currMonthStart);
  thisYearStart.setFullYear(today.getFullYear(), 0, 1);
  const thisYearEnd = new Date(currMonthStart);
  thisYearEnd.setFullYear(today.getFullYear(), 11, 31);
  const prevYearsStart = new Date(currMonthStart);
  prevYearsStart.setFullYear(2016, 0, 1); // Todo: AM => Hardcoded to 01/01/16. Need to get the merchants start date from the back end
  const prevYearsEnd = new Date(currMonthStart);
  prevYearsEnd.setFullYear(today.getFullYear() - 1, 11, 31);

  const presets: DatePresets = {
    default: [
      {
        id: 'current_month',
        start: currMonthStart,
        end: currMonthEnd,
        label: 'This month',
        disabled: false,
        selected: false,
      },
      {
        id: 'previous_month',
        start: prevMonthStart,
        end: prevMonthEnd,
        label: 'Last month',
        disabled: false,
        selected: false,
      },
      {
        id: 'last_quarter',
        start: prevQuarterStart,
        end: prevQuarterEnd,
        label: 'Last quarter',
        disabled: false,
        selected: false,
      },
      {
        id: 'last_6_month',
        start: last6MonthsStart,
        end: last6MonthsEnd,
        label: 'Last 6 months',
        disabled: false,
        selected: false,
      },
      {
        id: 'custom',
        start: undefined,
        end: undefined,
        label: 'Custom dates',
        disabled: false,
        selected: false,
        isCustom: true,
      },
    ],
    invoices: [
      {
        id: 'last_6_months',
        start: last6MonthsStart,
        end: new Date(),
        label: 'Last 6 months',
        disabled: false,
        selected: false,
      },
      {
        id: 'this_year',
        start: thisYearStart,
        end: thisYearEnd,
        label: 'This year',
        disabled: false,
        selected: false,
      },
      {
        id: 'last_13_months',
        start: last13MonthsStart,
        end: last13MonthsEnd,
        label: 'Last 13 months',
        disabled: false,
        selected: false,
      },
      {
        id: 'allPreviousYears',
        start: prevYearsStart,
        end: prevYearsEnd,
        label: 'All previous years',
        disabled: false,
        selected: false,
      },
    ],
  };

  return presets;
};
