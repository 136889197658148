import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Box, HStack, Text } from '@chakra-ui/react';

export const UserProfile = () => {
  const { user } = useAuth0();

  return (
    <HStack spacing='3' ps='4'>
      <Avatar size={'sm'} name={user?.name} src={user ? user.picture : undefined} />
      <Box>
        <Text color='text.default-inverse' fontWeight='medium' fontSize='sm'>
          {user?.name}
        </Text>
        <Text color='text.default-inverse' fontSize='xs' fontWeight={500}>
          {user?.email}
        </Text>
      </Box>
    </HStack>
  );
};
