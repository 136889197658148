/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex, Link } from '@chakra-ui/react';

import { IProfitabilityWalkthroughData } from '../types';

import { IncrementalGrossProfitView } from './views/IncrementalGrossProfitView';
import { IncrementalNetProfitView } from './views/IncrementalNetProfitView';
import { IncrementalRevenueView } from './views/IncrementalRevenueView';
import { ROIView } from './views/ROIView';
import { TotalUpsideRevenueView } from './views/TotalUpsideRevenueView';
import { WalkthroughInitialView } from './views/WalkthroughInitialView';

const MAX_STEP = 5;

export const ProfitabilityWalkthrough = (props: {
  data: IProfitabilityWalkthroughData;
  customerSummaryData: any;
  onStepChange: (step: number) => void;
  step: number;
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const data = props.data;
  const customerSummaryData = props.customerSummaryData;

  const goForward = () => {
    const newStep = Math.min(props.step + 1, MAX_STEP);
    props.onStepChange(newStep);
  };
  const goBackward = () => {
    const newStep = Math.max(props.step - 1, 0);
    props.onStepChange(newStep);
  };
  const restartWalkthrough = () => {
    props.onStepChange(0);
  };

  useEffect(() => {
    if (ref.current && props.step > 0) {
      (ref.current as HTMLElement).scrollIntoView({ behavior: 'smooth' });
    }
  }, [props.step]);

  let view: ReactElement | null = null;
  if (props.step === 1) {
    view = (
      <TotalUpsideRevenueView
        totalUpsideRevenue={data.totalUpsideRevenue}
        totalGallons={data.gallonsOnUpside}
        totalTransactions={customerSummaryData.totalUpsideCustomerTxns}
        totalCustomers={customerSummaryData.totalUpsideCustomers}
      />
    );
  } else if (props.step === 2) {
    view = (
      <IncrementalRevenueView
        totalUpsideRevenue={data.totalUpsideRevenue}
        expectedRevenueFromControl={data.expectedRevenueFromControl}
        incrementalRevenue={data.incrementalRevenue}
        totalGallons={data.gallonsOnUpside}
        nonIncrementalGallons={data.nonIncrementalGallons}
        incrementalGallons={data.totalIncrementalGallons}
      />
    );
  } else if (props.step === 3) {
    view = (
      <IncrementalGrossProfitView
        incrementalRevenue={data.incrementalRevenue}
        incrementalGrossProfit={data.incrementalGrossProfit}
        averageGrossMargin={data.averageGrossMargin}
        incrementalGallons={data.totalIncrementalGallons}
        blendedAvgMarginPerGallon={data.blendedAvgMarginPerGallon}
      />
    );
  } else if (props.step === 4) {
    view = (
      <IncrementalNetProfitView
        incrementalGrossProfit={data.incrementalGrossProfit}
        cashBackProvidedToCustomers={data.cashBackProvidedToCustomers}
        upsideFundedCustomerPromotions={data.upsideFundedCustomerPromotions}
        upsideProfitShare={data.upsideProfitShare}
        incrementalNetProfitFromUpside={data.incrementalNetProfitFromUpside}
      />
    );
  } else if (props.step === 5) {
    view = (
      <ROIView
        returnOnInvestment={data.returnOnInvestment}
        incrementalNetProfitFromUpside={data.incrementalNetProfitFromUpside}
        totalPromotionsAndProfitShare={data.totalPromotionsAndProfitShare}
        roiAmount={data.roiAmount}
        cashBackProvidedToCustomers={data.cashBackProvidedToCustomers}
        upsideFundedCustomerPromotions={data.upsideFundedCustomerPromotions}
        upsideProfitShare={data.upsideProfitShare}
      />
    );
  }

  const navbar = (
    <Flex
      flexDirection={'row'}
      justifyContent={props.step === 0 ? 'center' : 'space-between'}
      alignItems={'center'}
      marginTop={4}
      w={'100%'}
      borderTop={'1px solid #d1d5db'}
      paddingTop={'56px'}
      paddingBottom={'8px'}
    >
      <Button size='md' px={8} borderRadius='full' variant='accentSecondary' onClick={goBackward}>
        {t('profitabilityPage.prevBtn')}
      </Button>
      {props.step < 5 ? (
        <Button size='md' px={8} borderRadius='full' variant='brandPrimary' onClick={goForward}>
          {t('profitabilityPage.nextBtn')}
        </Button>
      ) : (
        <Link onClick={restartWalkthrough} textDecoration='none'>
          {t('profitabilityPage.restartWalkthrough')}
        </Link>
      )}
    </Flex>
  );

  return (
    <Flex flexDirection={'column'} gap={5} flex={1} ref={ref}>
      {props.step === 0 ? (
        <Flex flexDirection={'column'} flex={1} gap={4}>
          <WalkthroughInitialView />
          <Flex justifyContent={'center'} alignItems={'center'}>
            <Button size='lg' px={8} variant='brandPrimary' onClick={goForward}>
              {t('profitabilityPage.startBtn')}
            </Button>
          </Flex>
        </Flex>
      ) : (
        <>
          <Flex flex={1} flexDirection={'column'} gap={5}>
            {view}
          </Flex>
          {navbar}
        </>
      )}
    </Flex>
  );
};
