import * as Sentry from '@sentry/react';

import { IPaymentsRawData } from './types';

export const formatData = (rawData: IPaymentsRawData[] | undefined) => {
  try {
    if (!rawData) {
      throw Error('empty result');
    }

    return rawData;
  } catch (error) {
    Sentry.captureException(error);
    console.error('Error: ', error);
    return [];
  }
};
