import { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaDollarSign } from 'react-icons/fa';
import { FiLock } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  Radio,
  RadioGroup,
  Select,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { isEmpty, merge } from 'lodash';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { CustomFormLabel } from '@/common/components/form';
import { LoadingSpinner } from '@/common/components/loadingSpinner';
import { usePlacesAutocomplete } from '@/common/hooks';
import { useFetchProcessorCredentials } from '@/common/hooks/fetchDashboardData';
import { Status } from '@/common/interfaces';
import states from '@/common/utils/states.json';
import { selectActiveVertical, selectUserOrg, selectUserRoles } from '@/features/auth/userSlice';
import {
  BRANDS,
  CLASS_OF_TRADE_MAPPING,
  DEFAULT_STEPS,
  LEGACY_STEPS,
  NEIGHBOR_STATES,
  OPIS_RACK_MAPPING,
  TRANSACTION_DATA_MAPPING,
} from '@/features/settings/locations/constants';
import {
  Address,
  EditFuelLocationInputFormData,
  EditFuelLocationOutputFormData,
  LocationSettingsData,
} from '@/features/settings/locations/types';
import { editFuelLocationSchema } from '@/features/settings/locations/validators';

import { selectLocationsSettingsStatus, updateLocationRequest } from '../../slice';

export function ManageFuelLocation({ location }: { location: LocationSettingsData }) {
  const inputRef = useRef<HTMLInputElement>();

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const loadingStatus = useAppSelector(selectLocationsSettingsStatus);
  const roles = useAppSelector(selectUserRoles);
  const orgId = useAppSelector(selectUserOrg);
  const activeVertical = useAppSelector(selectActiveVertical);

  const { data: processorData, isLoading: isProcessorLoading } = useFetchProcessorCredentials(location.uuid ?? '');

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
    control,
  } = useForm<EditFuelLocationInputFormData>({
    resolver: zodResolver(editFuelLocationSchema),
    defaultValues: {
      ...location,
      additionalProperties: {
        processorCredentials: {
          username: processorData?.username,
          website: processorData?.url,
        },
        classOfTrade: location.additionalProperties.classOfTrade,
        dataSources: location.additionalProperties.dataSources,
        gasBrand: location.additionalProperties.gasBrand,
        ...(!isEmpty(location.additionalProperties.margins) && { margins: location.additionalProperties.margins }),
        ...(!isEmpty(location.settings.marginSettings) && {
          marginSettings: location.settings.marginSettings,
        }),
        ...(!isEmpty(location.additionalProperties.opisRackTerminal) && {
          opisRackTerminal: JSON.stringify(location.additionalProperties.opisRackTerminal),
        }),
      },
    },
  });

  const watchedMarginSettings = watch('additionalProperties.margins');
  const watchedOpisRackMargin = watch('settings.marginSettings.outsideGasFee.amount');

  useEffect(() => {
    if (processorData) {
      setValue('additionalProperties.processorCredentials.username', processorData.username);
      setValue('additionalProperties.processorCredentials.website', processorData.url);
    }
  }, [processorData, setValue]);

  const handlePlaceAutocomplete = (address: Address) => {
    setValue('address.address1', address.address1);
    setValue('address.postCode', address.postCode);
    setValue('address.locality', address.locality);
    setValue('address.region', address.region);
  };

  usePlacesAutocomplete(inputRef, handlePlaceAutocomplete);

  const transactionData = watch('additionalProperties.dataSources.0');
  const username = watch('additionalProperties.processorCredentials.username');

  const [agreementType, setAgreementType] = useState<'opisRack' | 'tankWagon'>(() => {
    if (!location?.additionalProperties?.opisRackTerminal?.locality) return 'tankWagon';

    return 'opisRack';
  });

  const terminals = useMemo(() => {
    const state = location.address.region;

    if (!state) return [];

    const neighborStates = NEIGHBOR_STATES[state as keyof typeof NEIGHBOR_STATES];

    if (neighborStates) {
      const searchStates = [...neighborStates, state];

      return OPIS_RACK_MAPPING.filter((terminal) => searchStates.includes(terminal.region))
        .map((terminal) => terminal.localities)
        .flat();
    }

    return OPIS_RACK_MAPPING.filter((terminal) => [state].includes(terminal.region))
      .map((terminal) => terminal.localities)
      .flat();
  }, [location.address.region]);

  const onSubmit = (data: unknown) => {
    const dataToSubmit = data as EditFuelLocationOutputFormData;
    const updatedData = merge({}, location, dataToSubmit) as LocationSettingsData;

    if (agreementType === 'tankWagon') {
      if (updatedData?.additionalProperties?.opisRackTerminal) {
        updatedData.additionalProperties.opisRackTerminal = {};
      }

      if (updatedData?.settings?.marginSettings) {
        updatedData.settings.marginSettings = undefined;
      }
    }

    if (agreementType === 'opisRack') {
      if (updatedData?.additionalProperties?.margins) {
        updatedData.additionalProperties.margins = {};
      }
    }

    if (transactionData !== 'PROCESSOR' && updatedData?.additionalProperties?.processorCredentials?.username) {
      updatedData.additionalProperties.processorCredentials = {};
    }

    if (transactionData === 'PROCESSOR' && !updatedData?.additionalProperties?.processorCredentials?.password) {
      updatedData.additionalProperties.processorCredentials = {
        username: dataToSubmit.additionalProperties?.processorCredentials?.username,
        password: processorData?.password,
        website: dataToSubmit.additionalProperties?.processorCredentials?.website,
      };
    }

    dispatch(
      updateLocationRequest({
        location: updatedData,
        orgId,
        activeVertical,
        roles,
        locationId: location.uuid,
      }),
    );
  };

  return (
    <Box mt='4'>
      <Heading as='h2' size='md' mb={4}>
        {location.name}
      </Heading>

      <FormControl as='form' onSubmit={handleSubmit(onSubmit)}>
        <CustomFormLabel htmlFor='name' mt={5}>
          {t('locationsSettingsPage.fuelForm.fields.basicInformation.name')}
          <Input
            type='text'
            id='name'
            placeholder='Enter a location name'
            mt={2}
            {...register('name', { required: true })}
            isInvalid={errors.name && errors.name?.message !== ''}
          />
        </CustomFormLabel>
        {errors.name && <Text variant='error'>{errors.name?.message}</Text>}

        <CustomFormLabel htmlFor='gasBrand' mt={5} maxW={['100%', '50%', '35%']}>
          {t('locationsSettingsPage.fuelForm.fields.basicInformation.brand')}
          <Select
            id='gasBrand'
            placeholder='Select your gas brand'
            mt={2}
            {...register('additionalProperties.gasBrand', { required: true })}
            isInvalid={errors.additionalProperties?.gasBrand && errors.additionalProperties.gasBrand.message !== ''}
          >
            {BRANDS.map((brand) => (
              <option key={brand.match} value={brand.value}>
                {brand.value}
              </option>
            ))}
          </Select>
        </CustomFormLabel>
        {errors.additionalProperties?.gasBrand && (
          <Text variant='error'>{errors.additionalProperties.gasBrand?.message}</Text>
        )}

        <CustomFormLabel htmlFor='address1' mt={5}>
          {t('locationsSettingsPage.fuelForm.fields.basicInformation.address1')}
          <Controller
            name='address.address1'
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <Input
                type='text'
                id='address1'
                placeholder='Enter a street address'
                mt={2}
                {...rest}
                ref={(e) => {
                  if (!e) return;
                  ref(e);
                  inputRef.current = e;
                }}
                isInvalid={errors.address?.address1 && errors.address?.address1.message !== ''}
              />
            )}
          />
        </CustomFormLabel>
        {errors.address?.address1 && <Text variant='error'>{errors.address.address1?.message}</Text>}

        <CustomFormLabel htmlFor='address2' mt={5}>
          {t('locationsSettingsPage.fuelForm.fields.basicInformation.address2')}
          <Input type='text' id='street_2' placeholder='Address complement' mt={2} {...register('address.address2')} />
        </CustomFormLabel>

        <Flex mt={5} mb={8} gap={2}>
          <Box w='full'>
            <CustomFormLabel htmlFor='locality'>
              {t('locationsSettingsPage.fuelForm.fields.basicInformation.city')}
              <Input
                type='text'
                id='locality'
                placeholder='Enter a city'
                mt={2}
                {...register('address.locality', { required: true })}
                isInvalid={errors.address?.locality && errors.address?.locality.message !== ''}
              />
            </CustomFormLabel>
            {errors.address?.locality && <Text variant='error'>{errors.address.locality?.message}</Text>}
          </Box>

          <Box w='full'>
            <CustomFormLabel htmlFor='region'>
              {t('locationsSettingsPage.fuelForm.fields.basicInformation.state')}
              <Select
                id='region'
                placeholder='Select your state'
                mt={2}
                {...register('address.region', { required: true })}
                isInvalid={errors.address?.region && errors.address?.region.message !== ''}
              >
                {states.states.map((state: { name: string; code: string }) => (
                  <option key={state.code} value={state.code}>
                    {state.name}
                  </option>
                ))}
              </Select>
            </CustomFormLabel>
            {errors.address?.region && <Text variant='error'>{errors.address.region?.message}</Text>}
          </Box>
          <Box w='full'>
            <CustomFormLabel htmlFor='postCode'>
              {t('locationsSettingsPage.fuelForm.fields.basicInformation.zip')}

              <Input
                id='postCode'
                placeholder='Enter a zip code'
                mt={2}
                {...register('address.postCode', { required: true })}
                isInvalid={errors.address?.postCode && errors.address?.postCode.message !== ''}
              />
            </CustomFormLabel>
            {errors.address?.postCode && <Text variant='error'>{errors.address.postCode?.message}</Text>}
          </Box>
        </Flex>
        <Heading as='h2' size='lg' mb={4}>
          {t('locationsSettingsPage.fuelForm.edit.margins')}
        </Heading>

        <Heading as='h3' size='sm' mb={4}>
          {t('locationsSettingsPage.fuelForm.edit.marginsHelper')}
        </Heading>

        <Box mt='4'>
          <RadioGroup
            my={5}
            defaultValue={agreementType}
            onChange={(type) => setAgreementType(type as 'opisRack' | 'tankWagon')}
          >
            <VStack alignItems='flex-start'>
              <Radio color='neutral.900' value='opisRack'>
                <Text>{t('locationsSettingsPage.fuelForm.fields.margins.supplyAgreement.options.opisRack')}</Text>
              </Radio>
              <Radio color='neutral.900' value='tankWagon'>
                <Text>{t('locationsSettingsPage.fuelForm.fields.margins.supplyAgreement.options.tankWagon')}</Text>
              </Radio>
            </VStack>
          </RadioGroup>
        </Box>

        {agreementType === 'opisRack' && (
          <>
            <Text fontWeight='bold'>{t('locationsSettingsPage.fuelForm.edit.transportationCost')}</Text>
            <Text>{t('locationsSettingsPage.fuelForm.edit.transportationCostHelper')}</Text>
            <CustomFormLabel htmlFor='additionalCosts' mt='4'>
              <InputGroup borderWidth='thin' borderRadius='lg' borderColor='border.default' maxW='250px'>
                <InputLeftAddon bg='none' border='none' pr={0}>
                  <FaDollarSign />
                </InputLeftAddon>
                <Input
                  id='additionalCosts'
                  type='number'
                  border='none'
                  placeholder='0.01'
                  step={
                    Number(watchedOpisRackMargin) !== Number(location.settings.marginSettings?.outsideGasFee?.amount)
                      ? DEFAULT_STEPS
                      : LEGACY_STEPS
                  }
                  min='0'
                  max='0.15'
                  required={agreementType === 'opisRack'}
                  {...register('settings.marginSettings.outsideGasFee.amount')}
                />
              </InputGroup>
            </CustomFormLabel>
            {errors.settings?.marginSettings?.outsideGasFee?.amount && (
              <Text variant='error'>{errors.settings?.marginSettings?.outsideGasFee?.amount.message}</Text>
            )}

            <Text fontWeight='bold' mt='6'>
              {t('locationsSettingsPage.fuelForm.edit.fuelPricingInfo')}
            </Text>
            <Text>{t('locationsSettingsPage.fuelForm.edit.fuelPricingInfoHelper')}</Text>

            <CustomFormLabel htmlFor='opisRackTerminal' mt='4'>
              <Select
                id='opisRackTerminal'
                placeholder='Select a terminal'
                mt={2}
                maxW='250px'
                required={agreementType === 'opisRack'}
                {...register('additionalProperties.opisRackTerminal')}
              >
                {terminals.map((terminal) => (
                  <option key={terminal.locality} value={JSON.stringify(terminal)}>
                    {terminal.locality}
                  </option>
                ))}
              </Select>
            </CustomFormLabel>
          </>
        )}

        {agreementType === 'tankWagon' && (
          <>
            <Text fontWeight='bold'>{t('locationsSettingsPage.fuelForm.edit.fixedMargins')}</Text>
            <Text>{t('locationsSettingsPage.fuelForm.edit.fixedMarginsHelper')}</Text>

            <Flex mt={5} mb={8} gap={1}>
              <Box>
                <CustomFormLabel htmlFor='regularGrade'>
                  {t('locationsSettingsPage.fuelForm.fields.margins.grades.regular')}
                  <InputGroup borderWidth='thin' borderRadius='lg' borderColor='border.default' maxW='250px' mt={2}>
                    <InputLeftAddon bg='none' border='none' pr={0}>
                      <FaDollarSign />
                    </InputLeftAddon>

                    <Input
                      type='number'
                      id='regularGrade'
                      border='none'
                      placeholder='0.01'
                      step={
                        Number(watchedMarginSettings?.REGULAR?.amount) !==
                        location.additionalProperties.margins?.REGULAR?.amount
                          ? DEFAULT_STEPS
                          : LEGACY_STEPS
                      }
                      min='0'
                      required={agreementType === 'tankWagon'}
                      defaultValue='0.01'
                      {...register('additionalProperties.margins.REGULAR.amount')}
                      isInvalid={
                        errors.additionalProperties?.margins?.REGULAR &&
                        errors.additionalProperties?.margins?.REGULAR.message !== ''
                      }
                    />
                  </InputGroup>
                </CustomFormLabel>
              </Box>
              {errors.additionalProperties?.margins?.REGULAR && (
                <Text variant='error'>{errors.additionalProperties?.margins?.REGULAR.message}</Text>
              )}
              <Box>
                <CustomFormLabel htmlFor='midGrade'>
                  {t('locationsSettingsPage.fuelForm.fields.margins.grades.midgrade')}
                  <InputGroup borderWidth='thin' borderRadius='lg' borderColor='border.default' maxW='250px' mt={2}>
                    <InputLeftAddon bg='none' border='none' pr={0}>
                      <FaDollarSign />
                    </InputLeftAddon>

                    <Input
                      type='number'
                      id='midGrade'
                      border='none'
                      placeholder='0.01'
                      step={
                        Number(watchedMarginSettings?.MIDGRADE?.amount) !==
                        location.additionalProperties.margins?.MIDGRADE?.amount
                          ? DEFAULT_STEPS
                          : LEGACY_STEPS
                      }
                      min='0'
                      required={agreementType === 'tankWagon'}
                      defaultValue='0.01'
                      {...register('additionalProperties.margins.MIDGRADE.amount')}
                      isInvalid={
                        errors.additionalProperties?.margins?.MIDGRADE &&
                        errors.additionalProperties?.margins?.MIDGRADE.message !== ''
                      }
                    />
                  </InputGroup>
                </CustomFormLabel>
                {errors.additionalProperties?.margins?.MIDGRADE && (
                  <Text variant='error'>{errors.additionalProperties?.margins?.MIDGRADE.message}</Text>
                )}
              </Box>
              <Box>
                <CustomFormLabel htmlFor='premiumGrade'>
                  {t('locationsSettingsPage.fuelForm.fields.margins.grades.premium')}
                  <InputGroup borderWidth='thin' borderRadius='lg' borderColor='border.default' maxW='250px' mt={2}>
                    <InputLeftAddon bg='none' border='none' pr={0}>
                      <FaDollarSign />
                    </InputLeftAddon>

                    <Input
                      type='number'
                      id='premiumGrade'
                      border='none'
                      placeholder='0.01'
                      step={
                        Number(watchedMarginSettings?.PREMIUM?.amount) !==
                        location.additionalProperties.margins?.PREMIUM?.amount
                          ? DEFAULT_STEPS
                          : LEGACY_STEPS
                      }
                      min='0'
                      required={agreementType === 'tankWagon'}
                      defaultValue='0.01'
                      {...register('additionalProperties.margins.PREMIUM.amount')}
                      isInvalid={
                        errors.additionalProperties?.margins?.PREMIUM &&
                        errors.additionalProperties?.margins?.PREMIUM.message !== ''
                      }
                    />
                  </InputGroup>
                </CustomFormLabel>
                {errors.additionalProperties?.margins?.PREMIUM && (
                  <Text variant='error'>{errors.additionalProperties?.margins?.PREMIUM.message}</Text>
                )}
              </Box>
              <Box>
                <CustomFormLabel htmlFor='dieselGrade'>
                  {t('locationsSettingsPage.fuelForm.fields.margins.grades.diesel')}
                  <InputGroup borderWidth='thin' borderRadius='lg' borderColor='border.default' maxW='250px' mt={2}>
                    <InputLeftAddon bg='none' border='none' pr={0}>
                      <FaDollarSign />
                    </InputLeftAddon>

                    <Input
                      type='number'
                      id='diesel'
                      border='none'
                      placeholder='0.01'
                      step={
                        Number(watchedMarginSettings?.DIESEL?.amount) !==
                        location.additionalProperties.margins?.DIESEL?.amount
                          ? DEFAULT_STEPS
                          : LEGACY_STEPS
                      }
                      min='0'
                      required={agreementType === 'tankWagon'}
                      defaultValue='0.01'
                      {...register('additionalProperties.margins.DIESEL.amount')}
                      isInvalid={
                        errors.additionalProperties?.margins?.DIESEL &&
                        errors.additionalProperties?.margins?.DIESEL.message !== ''
                      }
                    />
                  </InputGroup>
                </CustomFormLabel>
                {errors.additionalProperties?.margins?.DIESEL && (
                  <Text variant='error'>{errors.additionalProperties?.margins?.DIESEL.message}</Text>
                )}
              </Box>
            </Flex>
          </>
        )}

        <Heading as='h2' size='lg' mt={6} mb={2}>
          {t('locationsSettingsPage.fuelForm.edit.classOfTrade')}
        </Heading>

        <Text>{t('locationsSettingsPage.fuelForm.edit.classOfTradeHelper')}</Text>

        <CustomFormLabel htmlFor='classOfTrade' mt='4'>
          {t('locationsSettingsPage.fuelForm.edit.classOfTradeSelector')}
          <Select
            id='classOfTrade'
            placeholder='Select a class of trade'
            mt={2}
            maxW='400px'
            {...register('additionalProperties.classOfTrade')}
          >
            {CLASS_OF_TRADE_MAPPING.map((cot) => (
              <option key={cot.value} value={cot.value}>
                {cot.label}
              </option>
            ))}
          </Select>
          {errors.additionalProperties?.classOfTrade && (
            <Text color='red.500'>{errors.additionalProperties.classOfTrade.message}</Text>
          )}
        </CustomFormLabel>

        <Heading as='h2' size='lg' mt={6} mb={2}>
          {t('locationsSettingsPage.fuelForm.edit.transactionData')}
        </Heading>

        <Text>{t('locationsSettingsPage.fuelForm.edit.transactionDataHelper')}</Text>

        <RadioGroup
          defaultValue={
            location.additionalProperties.dataSources && (location.additionalProperties.dataSources[0] as string)
          }
        >
          <VStack alignItems='flex-start' my={4}>
            {TRANSACTION_DATA_MAPPING.map((td) => (
              <Radio key={td} value={td} color='neutral.900' {...register('additionalProperties.dataSources.0')}>
                <Text fontWeight='bold'>
                  {t(`locationsSettingsPage.fuelForm.fields.transactionData.options.${td}`)}
                </Text>
              </Radio>
            ))}
          </VStack>
        </RadioGroup>
        {errors.additionalProperties?.dataSources && (
          <Text variant='error'>{errors.additionalProperties?.dataSources.message}</Text>
        )}

        {transactionData === 'PROCESSOR' && (
          <>
            {isProcessorLoading && (
              <Flex alignItems='center' justifyContent='center' my={6}>
                <LoadingSpinner />
              </Flex>
            )}
            {!isProcessorLoading && (
              <>
                <Alert status='warning' color='white'>
                  <AlertIcon color='white' />
                  Do not type in this processor section unless you wish to submit a change to previously submitted
                  processor info. Else, leave blank
                </Alert>
                <CustomFormLabel htmlFor='username' mt={5}>
                  {t('locationsSettingsPage.fuelForm.fields.loginCredentials.username')}
                  <Input
                    type='text'
                    id='username'
                    placeholder='Enter your username'
                    mt={2}
                    {...register('additionalProperties.processorCredentials.username')}
                    isInvalid={
                      errors.additionalProperties?.processorCredentials?.username &&
                      errors.additionalProperties.processorCredentials.username?.message !== ''
                    }
                  />
                </CustomFormLabel>
                {errors.additionalProperties?.processorCredentials?.username && (
                  <Text variant='error'>{errors.additionalProperties?.processorCredentials.username.message}</Text>
                )}

                <CustomFormLabel htmlFor='password' mt={5}>
                  {t('locationsSettingsPage.fuelForm.fields.loginCredentials.password')}
                  <Input
                    type='password'
                    id='password'
                    placeholder='Enter your password'
                    mt={2}
                    {...register('additionalProperties.processorCredentials.password')}
                    isInvalid={
                      errors.additionalProperties?.processorCredentials?.password &&
                      errors.additionalProperties.processorCredentials.password?.message !== ''
                    }
                  />
                </CustomFormLabel>
                {errors.additionalProperties?.processorCredentials?.password && (
                  <Text variant='error'>{errors.additionalProperties?.processorCredentials.password.message}</Text>
                )}

                <CustomFormLabel htmlFor='website' mt={5}>
                  {t('locationsSettingsPage.fuelForm.fields.loginCredentials.website')}
                  <Input
                    type='text'
                    id='website'
                    placeholder='https://'
                    mt={2}
                    required={transactionData === 'PROCESSOR' && !!username}
                    {...register('additionalProperties.processorCredentials.website')}
                    isInvalid={
                      errors.additionalProperties?.processorCredentials?.website &&
                      errors.additionalProperties.processorCredentials.website?.message !== ''
                    }
                  />
                </CustomFormLabel>
                {errors.additionalProperties?.processorCredentials?.website && (
                  <Text variant='error'>{errors.additionalProperties?.processorCredentials.website.message}</Text>
                )}

                <Flex gap={2} alignItems='center' my={3}>
                  <Icon color='text.success.default'>
                    <FiLock />
                  </Icon>
                  <Text color='text.success.default'>
                    {t('locationsSettingsPage.fuelForm.fields.loginCredentials.secureConnection')}
                  </Text>
                </Flex>
              </>
            )}
          </>
        )}

        <Flex mt={3} gap={5}>
          <Button as={Link} to='/settings/locations' variant='accentSecondary' size='md' px={8}>
            {t('payment.addPayout.back')}
          </Button>

          <Button
            type='submit'
            variant='brandPrimary'
            size='md'
            px={8}
            isLoading={loadingStatus === Status.Loading}
            isDisabled={loadingStatus === Status.Loading}
            leftIcon={loadingStatus === Status.Loading ? <Spinner /> : <></>}
          >
            {t('locationsSettingsPage.fuelForm.actions.continue')}
          </Button>
        </Flex>
      </FormControl>
    </Box>
  );
}
