import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { authInterceptor } from './authInterceptor';

export function AuthInjector() {
  const { getAccessTokenSilently, logout } = useAuth0();

  useEffect(() => {
    authInterceptor.setAuthGetter(getAccessTokenSilently);
    authInterceptor.setLogout(() => logout({ logoutParams: { returnTo: window.location.origin } }));
  }, [getAccessTokenSilently, logout]);

  return null;
}
