/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Card, Flex, Table as ChakraTable, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { useAppSelector } from '@/app/hooks';
import { DataLoadingErrorPanel, EmptyDataAlertPanel } from '@/common/components/errorAlert';
import { LoadingSpinner } from '@/common/components/loadingSpinner';
import { useFetchNotificationSettings } from '@/common/hooks/fetchDashboardData';
import { Status } from '@/common/interfaces';

import { NotificationsTableCell } from './components/NotificationsTableCell';
import { selectNotificationSettingsData, selectNotificationSettingsStatus } from './slice';

export function Notifications() {
  const { t } = useTranslation();
  useFetchNotificationSettings();
  const loadingStatus = useAppSelector(selectNotificationSettingsStatus);
  const notificationSettingsData = useAppSelector(selectNotificationSettingsData);
  const navigate = useNavigate();

  const isEmpty =
    loadingStatus === Status.Idle &&
    _.get(notificationSettingsData.notificationSettings.tableData, 'rows', []).length === 0;

  const handleAddEmailClick = () => {
    navigate('/settings/notification/manage/new');
  };

  return (
    <Box padding={4}>
      {loadingStatus === Status.Loading && (
        <Flex alignItems='center' justifyContent='center' mt={10}>
          <LoadingSpinner />
        </Flex>
      )}
      {loadingStatus === Status.Failed && <DataLoadingErrorPanel />}
      {loadingStatus === Status.Idle && (
        <>
          <Card variant='borderless'>
            {isEmpty ? (
              <EmptyDataAlertPanel />
            ) : (
              <>
                <Flex mt={'24px'} justifyContent={'flex-end'}>
                  <Button
                    type='submit'
                    variant='brandPrimary'
                    size='md'
                    px={8}
                    leftIcon={<FaPlus />}
                    onClick={handleAddEmailClick}
                  >
                    {t('notificationPage.manageForm.button.add')}
                  </Button>
                </Flex>
                <TableContainer width={'100%'} overflowX={'hidden'} mt={5}>
                  <ChakraTable style={{ borderCollapse: 'separate', borderSpacing: '0' }}>
                    <Thead>
                      <Tr>
                        {notificationSettingsData.notificationSettings.tableData.headers
                          ? notificationSettingsData.notificationSettings.tableData.headers.map((header: string) => {
                              return <Th key={header}>{header} </Th>;
                            })
                          : null}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {notificationSettingsData.notificationSettings.tableData.rows.map((row: []) => {
                        return (
                          <Tr key={uuidv4()}>
                            {row
                              ? row.map((cell: string, colId: number) => {
                                  return <NotificationsTableCell key={colId} data={cell} />;
                                })
                              : null}
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </ChakraTable>
                </TableContainer>
              </>
            )}
          </Card>
        </>
      )}
    </Box>
  );
}
