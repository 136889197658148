import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Flex, FormControl, Heading, Radio, RadioGroup, Text, useToast, VStack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { differenceInDays } from 'date-fns';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { Verticals } from '@/common/interfaces';
import { selectActiveVertical } from '@/features/auth/userSlice';
import { selectEndDate, selectMaxDate, selectStartDate, setDateRange } from '@/features/dashboard/slice';

import { CUSTOM_REPORTS_TYPES, LAST_1_YEAR, LAST_90_DAYS } from '../constants';
import { selectReportOptions, updateReportOptions } from '../slice';
import { GranularityType } from '../type';
import { GranularityForm, granularitySchema } from '../validators';

export function GranularitySelector() {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const granularity = searchParams.get('selected') as GranularityForm['granularity'] | null;

  const toast = useToast();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const maxDate = useAppSelector(selectMaxDate);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const activeVertical = useAppSelector(selectActiveVertical);
  const reportOptions = useAppSelector(selectReportOptions);

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm<GranularityForm>({
    resolver: zodResolver(granularitySchema),
    defaultValues: {
      ...reportOptions,
      granularity: granularity || reportOptions.granularity,
    },
  });

  const activeGranularity = watch('granularity');

  useEffect(() => {
    const diffInDays = differenceInDays(new Date(`${endDate}T00:00:00`), new Date(`${startDate}T00:00:00`));
    const newStartDate = maxDate ? new Date(maxDate) : new Date();
    const newEndDate = maxDate ? new Date(maxDate) : new Date();

    if (activeGranularity === GranularityType.Transaction && diffInDays > LAST_90_DAYS) {
      newStartDate.setDate(newStartDate.getDate() - LAST_90_DAYS);
      const newStartDateString = newStartDate.toISOString().split('T')[0];
      const newEndDateString = newEndDate.toISOString().split('T')[0];

      if (newStartDateString !== startDate || newEndDateString !== endDate) {
        dispatch(setDateRange({ startDate: newStartDateString, endDate: newEndDateString }));
        toast({
          title: t('customReportsPage.info.transaction.dateRange.title'),
          description: t('customReportsPage.info.transaction.dateRange.description'),
          status: 'info',
          duration: 10000,
          isClosable: true,
        });
      }
      return;
    }

    if (activeGranularity === GranularityType.Daily && diffInDays > LAST_1_YEAR) {
      // start date should be LAST_1_YEAR days before today, and end date should be today
      newStartDate.setDate(newStartDate.getDate() - LAST_1_YEAR);
      const newStartDateString = newStartDate.toISOString().split('T')[0];
      const newEndDateString = newEndDate.toISOString().split('T')[0];

      if (newStartDateString !== startDate || newEndDateString !== endDate) {
        dispatch(setDateRange({ startDate: newStartDateString, endDate: newEndDateString }));
        toast({
          title: t('customReportsPage.info.transaction.dateRange.title'),
          description: t('customReportsPage.info.transaction.dateRange.description'),
          status: 'info',
          duration: 10000,
          isClosable: true,
        });
      }
      return;
    }
  }, [activeGranularity, startDate, endDate, dispatch, maxDate, toast, t]);

  const granularityOptions = useMemo(() => {
    if (activeVertical === Verticals.Grocery) {
      return CUSTOM_REPORTS_TYPES.filter((type) => type !== GranularityType.Transaction);
    }

    return CUSTOM_REPORTS_TYPES;
  }, [activeVertical]);

  function onSubmit(data: GranularityForm) {
    const diffInDays = differenceInDays(new Date(`${endDate}T00:00:00`), new Date(`${startDate}T00:00:00`));
    if (diffInDays > LAST_90_DAYS && data.granularity === GranularityType.Transaction) {
      toast({
        title: t('customReportsPage.errors.transactionGranularity'),
        description: t('customReportsPage.errors.transactionGranularityDescription'),
        status: 'error',
        duration: 10000,
        isClosable: true,
      });
      return;
    }

    if (diffInDays > LAST_1_YEAR && data.granularity === GranularityType.Daily) {
      toast({
        title: t('customReportsPage.errors.dailyGranularity'),
        description: t('customReportsPage.errors.dailyGranularityDescription'),
        status: 'error',
        duration: 10000,
        isClosable: true,
      });
      return;
    }

    dispatch(updateReportOptions({ granularity: data.granularity, startDate, endDate, includedFields: [] }));

    navigate('/reports/customReports/fields');
  }

  return (
    <Flex direction='column' gap='2'>
      <Heading as='h3' size='md'>
        {t('customReportsPage.granularity')}
      </Heading>
      <Text>{t(`customReportsPage.${activeVertical}.granularityDescription`)}</Text>
      <FormControl as='form' onSubmit={handleSubmit(onSubmit)}>
        <RadioGroup my={4} defaultValue={granularity ?? reportOptions.granularity}>
          <VStack alignItems='flex-start'>
            {granularityOptions.map((type) => (
              <Radio key={type} value={type} {...register('granularity')}>
                {t(`customReportsPage.granularityOptions.${type}`)}
              </Radio>
            ))}
          </VStack>
        </RadioGroup>
        {errors.granularity && <Text color='red.500'>{errors.granularity.message}</Text>}

        <Button type='submit' variant='brandPrimary' size='md' px={8}>
          {t('customReportsPage.actions.continue')}
        </Button>
      </FormControl>
    </Flex>
  );
}
