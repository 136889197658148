import { z } from 'zod';

export const addLocationSettingsSchema = z.object({
  name: z
    .string({
      required_error: 'Please enter a location name',
    })
    .min(3, 'Location name must be at least 3 characters'),
  address: z.object({
    address1: z.string({ required_error: 'Please enter an address' }).min(3, 'Address is required'),
    address2: z.string().optional(),
    locality: z.string({ required_error: 'Please enter a city' }).min(3, 'City is required'),
    region: z.string({ required_error: 'Please enter a state' }).min(2, 'State is required'),
    postCode: z
      .string()
      .min(5, 'Please enter valid zip code')
      .regex(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'Invalid zip code'),
  }),
  additionalProperties: z
    .object({
      margins: z.object({
        GLOBAL: z.object({
          percent: z
            .number()
            .optional()
            .transform((val) => (val ? val / 100 : 0.3)),
        }),
      }),
      dataSources: z
        .array(
          z.enum(['PROCESSOR', 'LINKED_PROCESSOR', 'DIRECT'], {
            required_error: 'Please select a connection source',
            invalid_type_error: 'Please select a connection source',
          }),
        )
        .optional(),
      processorCredentials: z
        .object({
          username: z.string({ required_error: 'Please enter a username' }).optional(),
          password: z.string().optional(),
          website: z.string({ required_error: 'Please enter a website' }).optional(),
        })
        .optional(),
    })
    .optional(),
});

export const addFuelLocationBasicInfoSchema = z
  .object({
    name: z
      .string({
        required_error: 'Please enter a location name',
      })
      .min(3, 'Location name must be at least 3 characters'),
    address: z.object({
      address1: z.string({ required_error: 'Please enter an address' }).min(3, 'Address is required'),
      address2: z.string().optional(),
      locality: z.string({ required_error: 'Please enter a city' }).min(3, 'City is required'),
      region: z.string({ required_error: 'Please enter a state' }).min(2, 'State is required'),
      postCode: z
        .string()
        .min(5, 'Please enter valid zip code')
        .regex(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'Invalid zip code'),
    }),
    additionalProperties: z.object({
      gasBrand: z.string({ required_error: 'Please enter a gas brand' }).min(1, 'Gas brand is required'),
      displayName: z.string().optional(),
    }),
  })
  .transform((data) => {
    if (data.additionalProperties.gasBrand === 'Other' || data.additionalProperties.gasBrand === 'Unbranded') {
      return data;
    }

    return {
      ...data,
      additionalProperties: {
        ...data.additionalProperties,
        displayName: data.additionalProperties.gasBrand,
      },
    };
  });

const GasMarginSchema = z
  .object({
    amount: z.preprocess(
      (val) => (typeof val === 'string' ? parseFloat(val) : val),
      z.union([z.number().min(0.01, { message: 'Value must be greater than 0.01' }), z.literal(0)]),
    ),
    currency: z.string().default('USD').optional(),
  })
  .default({
    amount: 0,
    currency: 'USD',
  });

const opisRackSchema = z.object({
  locality: z.string().optional(),
  region: z.string().optional(),
});

export const addFuelLocationOpisRackMarginsSchema = z.object({
  additionalProperties: z.object({
    opisRackTerminal: z
      .string()
      .optional()
      .transform((data) => {
        if (!data) {
          return undefined;
        }

        return JSON.parse(data) as z.infer<typeof opisRackSchema>;
      }),
  }),
  settings: z.object({
    marginSettings: z.object({
      outsideGasFee: z
        .object({
          amount: z.preprocess(
            (val) => (typeof val === 'string' ? parseFloat(val) : val),
            z.union([
              z
                .number()
                .min(0.01, { message: 'Value must be greater than 0.01' })
                .max(0.15, { message: 'Value must be less than 0.15' }),
              z.literal(0),
            ]),
          ),
          currency: z.string().default('USD'),
        })
        .optional(),
    }),
  }),
});

export const addFuelLocationFixedMarginsSchema = z.object({
  additionalProperties: z.object({
    margins: z
      .object({
        REGULAR: GasMarginSchema,
        MIDGRADE: GasMarginSchema,
        PREMIUM: GasMarginSchema,
        DIESEL: GasMarginSchema,
      })
      .optional(),
  }),
});
export const addFuelLocationClassOfTradeSchema = z.object({
  additionalProperties: z.object({
    classOfTrade: z.enum(['COCA', 'COCO', 'CODO', 'DODO', 'DTW'], {
      errorMap: () => {
        return { message: 'Please select a class of trade' };
      },
    }),
  }),
});

export const addFuelLocationTransactionDataSchema = z.object({
  additionalProperties: z.object({
    dataSources: z.array(
      z.enum(['PROCESSOR', 'LINKED_PROCESSOR', 'DIRECT'], {
        errorMap: () => {
          return { message: 'Please select a connection source' };
        },
      }),
      {
        required_error: 'Please select a connection source',
        invalid_type_error: 'Please select a connection source',
      },
    ),
  }),
});

export const addFuelLocationAdditionalOfferSchema = z
  .object({
    hasConvenienceStore: z.preprocess((val) => (val === 'true' ? true : false), z.boolean().default(false)),
  })
  .transform((data) => {
    if (data.hasConvenienceStore === false) {
      return {};
    }

    return {
      additionalProperties: {
        margins: {
          CONVENIENCE_STORE: {
            percent: 0.5,
          },
        },
      },
    };
  });

export const addFuelLocationLoginCredentialsSchema = z.object({
  additionalProperties: z.object({
    processorCredentials: z.object({
      username: z
        .string({ required_error: 'Please enter a username' })
        .min(3, 'Username must be at least 3 characters'),
      password: z
        .string({ required_error: 'Please enter a password' })
        .min(2, 'Password must be at least 2 characters'),
      website: z
        .string({ required_error: 'Please enter a website' })
        .url()
        .min(3, 'Website must be at least 3 characters'),
    }),
  }),
});

export const editFuelLocationSchema = z
  .object({
    name: z
      .string({
        required_error: 'Please enter a location name',
      })
      .min(3, 'Location name must be at least 3 characters'),
    address: z.object({
      address1: z.string({ required_error: 'Please enter an address' }).min(3, 'Address is required'),
      address2: z.string().optional(),
      locality: z.string({ required_error: 'Please enter a city' }).min(3, 'City is required'),
      region: z.string({ required_error: 'Please enter a state' }).min(2, 'State is required'),
      postCode: z
        .string()
        .min(5, 'Please enter valid zip code')
        .regex(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'Invalid zip code'),
    }),
    additionalProperties: z.object({
      gasBrand: z.string({ required_error: 'Please enter a gas brand' }).min(1, 'Gas brand is required'),
      displayName: z.string().optional(),
      opisRackTerminal: z
        .string()
        .optional()
        .transform((data) => {
          if (!data) {
            return undefined;
          }

          return JSON.parse(data) as z.infer<typeof opisRackSchema>;
        }),
      margins: z
        .object({
          REGULAR: GasMarginSchema.optional(),
          MIDGRADE: GasMarginSchema.optional(),
          PREMIUM: GasMarginSchema.optional(),
          DIESEL: GasMarginSchema.optional(),
        })
        .default({
          REGULAR: { amount: 0, currency: 'USD' },
          MIDGRADE: { amount: 0, currency: 'USD' },
          PREMIUM: { amount: 0, currency: 'USD' },
          DIESEL: { amount: 0, currency: 'USD' },
        })
        .optional(),
      dataSources: z.array(
        z.enum(['PROCESSOR', 'LINKED_PROCESSOR', 'DIRECT'], {
          errorMap: () => {
            return { message: 'Please select a connection source' };
          },
        }),
      ),
      classOfTrade: z.enum(['COCA', 'COCO', 'CODO', 'DODO', 'DTW'], {
        errorMap: () => {
          return { message: 'Please select a class of trade' };
        },
      }),
      processorCredentials: z
        .object({
          username: z.string({ required_error: 'Please enter a username' }).optional(),
          password: z.string().optional(),
          website: z.string({ required_error: 'Please enter a website' }).optional(),
        })
        .optional(),
    }),
    settings: z
      .object({
        marginSettings: z
          .object({
            outsideGasFee: z.object({
              amount: z.preprocess(
                (val) => (typeof val === 'string' ? parseFloat(val) : val),
                z.union([
                  z
                    .number()
                    .min(0.01, { message: 'Value must be greater than 0.01' })
                    .max(0.15, { message: 'Value must be less than 0.15' }),
                  z.literal(0),
                ]),
              ),
              currency: z.string().default('USD'),
            }),
          })
          .optional(),
      })
      .optional(),
  })
  .transform((data) => {
    if (!data?.additionalProperties?.margins || Object.keys(data.additionalProperties.margins).length === 0) {
      return { ...data, additionalProperties: { ...data.additionalProperties, margins: {} } }; // Set an empty object for margins
    }

    if (data.additionalProperties.gasBrand === 'Other' || data.additionalProperties.gasBrand === 'Unbranded') {
      return data;
    }

    return {
      ...data,
      additionalProperties: {
        ...data.additionalProperties,
        displayName: data.additionalProperties.gasBrand,
      },
    };
  });
