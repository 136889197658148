import React, { useCallback } from 'react';
import { Box, ChakraStyledOptions, Flex, Heading, HStack, SimpleGrid, Stack, VStack } from '@chakra-ui/react';
import { DateObj, Props as DayzedHookProps, useDayzed } from 'dayzed';

import { displayMonthAndYear, weekdayFormatter } from '@/common/utils/dates';

import { CalendarConfigs, DatePickerProps } from '../types';
import { DatePositionInRange } from '../types';
import { ArrowKeysReact } from '../utils/reactKeysArrow';

import { DatePickerBackBtn } from './DatePickerBackBtn';
import { DatePickerForwardBtn } from './DatePickerForwardBtn';
import { DayOfMonth } from './DayOfMonth';

interface CalendarPanelProps extends DatePickerProps {
  dayzedHookProps: Omit<DayzedHookProps, 'children' | 'render'>;
  configs: CalendarConfigs;
  onMouseEnterHighlight?: (date: Date) => void;
  getDateOfMonthState?: (date: Date) => DatePositionInRange;
}

export const CalendarPanel: React.FC<CalendarPanelProps> = ({
  dayzedHookProps,
  configs,
  propsConfigs,
  onMouseEnterHighlight,
  getDateOfMonthState,
}) => {
  const { minDate, maxDate } = dayzedHookProps;
  const renderProps = useDayzed(dayzedHookProps);
  const { calendars, getBackProps, getForwardProps } = renderProps;

  const getKeyOffset = useCallback((num: number) => {
    const e = document.activeElement;
    const buttons = document.querySelectorAll('button');
    buttons.forEach((el, i) => {
      const newNodeKey = i + num;
      if (el === e) {
        if (newNodeKey <= buttons.length - 1 && newNodeKey >= 0) {
          buttons[newNodeKey].focus();
        } else {
          buttons[0].focus();
        }
      }
    });
  }, []);

  const arrowKeysReact = new ArrowKeysReact({
    left: () => {
      getKeyOffset(-1);
    },
    right: () => {
      getKeyOffset(1);
    },
    up: () => {
      getKeyOffset(-7);
    },
    down: () => {
      getKeyOffset(7);
    },
  });

  if (calendars.length <= 0) {
    return null;
  }

  const isFirstCalendar = (calendarIdx: number) => calendarIdx === 0;
  const isLastCalendar = (calendarIdx: number) => calendarIdx === calendars.length - 1;

  return (
    <Stack className='datepicker-calendar' direction={['column', 'column', 'row']} {...arrowKeysReact.getEvents()}>
      {calendars.map((calendar, calendarIdx, allCalendars) => {
        const month = new Date(calendar.year, calendar.month, 1);

        const btnStyle: ChakraStyledOptions = {};
        btnStyle.visibility = {
          base: 'visible',
          lg: 'hidden',
        };

        let prevDateObj: DateObj | null = null;

        return (
          <VStack key={calendarIdx} height='100%' padding='0.5rem 0.75rem'>
            <HStack flexDirection='row' width='100%'>
              <Box width='40px'>
                {isFirstCalendar(calendarIdx) ? (
                  <DatePickerBackBtn calendars={calendars} getBackProps={getBackProps} propsConfigs={propsConfigs} />
                ) : null}
              </Box>
              <Heading size='sm' fontWeight={500} flex={1} textAlign='center'>
                {displayMonthAndYear(month.toISOString())}
              </Heading>
              <Box width='40px'>
                {isLastCalendar(calendarIdx) ? (
                  <DatePickerForwardBtn
                    calendars={calendars}
                    getForwardProps={getForwardProps}
                    propsConfigs={propsConfigs}
                  />
                ) : null}
              </Box>
            </HStack>
            <SimpleGrid columns={7} textAlign='center' rowGap={1}>
              {calendar.weeks[1].map((week, dayIdx) => {
                if (typeof week === 'string') {
                  return null;
                }
                return (
                  <Flex
                    height='48px'
                    fontSize='14px'
                    lineHeight='20px'
                    justifyContent='center'
                    alignItems='center'
                    fontWeight='400'
                    color='neutral.800'
                    key={dayIdx}
                  >
                    {weekdayFormatter.format(week.date)}
                  </Flex>
                );
              })}
              {calendar.weeks.map((week, weekIdx, allWeekObjs) => {
                return week.map((dateObj, index, allDateObjs) => {
                  const key = `${calendar.month}-${calendar.year}-${weekIdx}-${index}`;
                  if (!dateObj) return <Box key={key} />;

                  let nextDateObj;
                  if (dateObj) {
                    if (allDateObjs[index + 1]) {
                      nextDateObj = allDateObjs[index + 1];
                    } else {
                      const nextWeek = allWeekObjs[weekIdx + 1];
                      if (nextWeek) {
                        nextDateObj = nextWeek.find((obj) => !!obj);
                      } else {
                        const nextCalendar = allCalendars[calendarIdx + 1];
                        if (nextCalendar) {
                          nextDateObj = nextCalendar.weeks[0].find((obj) => !!obj);
                        }
                      }
                    }
                  }

                  const { date } = dateObj;
                  const { isInRange, isFirst, isLast, disabled } =
                    typeof getDateOfMonthState === 'function'
                      ? getDateOfMonthState(date)
                      : {
                          isInRange: false,
                          isFirst: false,
                          isLast: false,
                          disabled: false,
                        };

                  const isFirstNonSelectable = Boolean(
                    dateObj.selectable === false &&
                      prevDateObj &&
                      (prevDateObj.selectable === true || prevDateObj.today === true),
                  );

                  const isLastNonSelectable = Boolean(
                    dateObj.selectable === false &&
                      ((nextDateObj && nextDateObj?.today === true) ||
                        (nextDateObj && nextDateObj.selectable === true)),
                  );

                  prevDateObj = dateObj;

                  return (
                    <DayOfMonth
                      key={key}
                      minDate={minDate}
                      maxDate={maxDate}
                      dateObj={dateObj}
                      propsConfigs={propsConfigs}
                      renderProps={renderProps}
                      isInRange={isInRange}
                      isFirst={isFirst}
                      isLast={isLast}
                      disabled={disabled}
                      isFirstNonSelectable={isFirstNonSelectable}
                      isLastNonSelectable={isLastNonSelectable}
                      configs={configs}
                      onMouseEnter={() => {
                        if (onMouseEnterHighlight) onMouseEnterHighlight(date);
                      }}
                    />
                  );
                });
              })}
            </SimpleGrid>
          </VStack>
        );
      })}
    </Stack>
  );
};
