import { Datum, Serie } from '@nivo/line';
import _ from 'lodash';

import { TemporalUnit } from '@/features/dashboard/slice';
import { IWeekOf } from '@/features/dashboard/types';
import colors from '@/theme/foundations/colors';

import { displayCurrency } from './currency';
import { formatDateByTemporalUnit } from './dates';
import { displayNumber, displayPercent } from './utils';

interface ISerieResult {
  serie: Serie;
  total: number;
}

export const SEGMENT_COLORS: Record<string, string> = {
  Regular: colors.blue[800],
  New: colors.green[400],
  All: colors.neutral[800],
  Infrequent: colors.yellow[100],
  Occasional: colors.purple[500],
  Margin: colors.orange[300],
};

export const getSegmentColor = (value: { id: string | number }): string => SEGMENT_COLORS[value.id] || colors.red[600];

export const subtractIWeekOfPairs = (data1: IWeekOf[] = [], data2: IWeekOf[] = []): IWeekOf[] =>
  data1.map((item, index) => ({
    value: Number(item.value) - Number(data2[index]?.value ?? 0),
    period_start: item.period_start,
  }));

export const buildSerieFromArray = (
  id: number | string = 0,
  data: Array<IWeekOf> = [],
  temporalUnit: TemporalUnit = 'week',
): ISerieResult => {
  const sortedData: Array<IWeekOf> = _.orderBy(data, ['period_start'], ['asc']);
  const total = sortedData.reduce((acc, item) => acc + Number(item.value), 0);

  const serie = sortedData.map((item: IWeekOf) => ({
    x: formatDateByTemporalUnit(item.period_start, temporalUnit),
    y: item.value,
  }));

  return {
    serie: {
      id,
      data: serie,
    },
    total,
  };
};

export const buildFunnelDatumFromArray = (data: Array<IWeekOf> | undefined): FunnelDatum[] => {
  let result: FunnelDatum[] = [];

  if (data) {
    result = data.map(() => {
      return {
        id: '100%',
        value: 162899,
        label: 'Unique users seeing offers',
      };
    });
  }

  return result;
};

/**
 * Calculates the brightness of color. This value can be used
 * to determine what font color to use for the background.
 *
 * @param hexColor
 * @returns Brightness from 0 to 160
 */
export const getColorBrightness = (hexColor: string): number => {
  const color = hexColor.substring(1);
  const rgb = parseInt(color, 16);
  const red = (rgb >> 16) & 0xff;
  const green = (rgb >> 8) & 0xff;
  const blue = (rgb >> 0) & 0xff;

  return 0.2126 * red + 0.7152 * green + 0.0722 * blue;
};

export const buildDatumFromArray = (data: Array<IWeekOf> = [], temporalUnit: TemporalUnit = 'week'): Datum[] =>
  data.map((item: IWeekOf) => ({
    x: formatDateByTemporalUnit(item.period_start, temporalUnit),
    y: item.value,
  }));

export const flattenSeriesValues = (data: Serie[], xOrY: string): number[] =>
  data.flatMap(({ data }) => data.map((d) => Number(d[xOrY])));

export const findMinMaxValuesFromSeries = (data: Serie[], xOrY: string) => {
  const flatValues = flattenSeriesValues(data, xOrY);
  return { min: Math.min(...flatValues), max: Math.max(...flatValues) };
};

export const getTooltipFormatter = (isCurrency: boolean, isPercentage: boolean) => {
  if (!isCurrency && !isPercentage) {
    return displayNumber;
  }
  if (isCurrency) {
    return displayCurrency;
  }

  return displayPercent;
};
