/* eslint-disable quote-props */

import { ENV_CONFIG } from '.';
// Note: This file will eventually exist as an API endpoint
// See MD-117

// Upside Auth0 Org IDs
const PROD_UPSIDE = 'org_lexe5Wkh4jZI57kM';
const ALPHA_UPSIDE = 'org_zGpBx0PRd9knLzLs';

// PROD
const PROD_ACME_FRESH_MARKET_ORG_ID = 'org_QNWDKoFvy9aNI6a6';
const PROD_CARDENAS_ORG_ID = 'org_8oaQWjqK8pv5wGXy';
const PROD_CARLIE_CS_ORG_ID = 'org_roWI46fflOUSAaLR';
const PROD_COBORNS_ORG_ID = 'org_QNVnvJ9SJsuAfJOp';
const PROD_FRESH_FOODS_ORG_ID = 'org_QWmeS7yMJZ4sxt3U';
const PROD_GEISSLERS_ORG_ID = 'org_C6wzrubS7Q6KP4i4';
const PROD_GELSONS_ORG_ID = 'org_JXwYeKpLFHNXCWiJ';
const PROD_MIDTOWNE_MARKET_ORG_ID = 'org_DuvtIGLO5oFaarsD';
const PROD_MOTHERS_MARKET_KITCHEN_ORG_ID = 'org_tIOIOMFRBd23Qoxb';
const PROD_PRICE_CHOPPER_ORG_ID = 'org_8ZONNAXBrRM47kuh';
const PROD_PRICE_CHOPPER_STL_ORG_ID = 'org_swM7vbTntZoDvAaF';
const PROD_RIESBECK_FOOD_MARKETS_ORG_ID = 'org_PwJyzvfFuRvBJAWQ';
const PROD_ROSAUERS_ORG_ID = 'org_9oC2GZqb4DTj6Q6p';
const PROD_SAVE_A_LOT_ORG_ID = 'org_kwse2g7MoFtyFBFq';
const PROD_SCHNUCKS_ORG_ID = 'org_NFOZYsf5BNehpVYS';
const PROD_SEG_ORG_ID = 'org_ZKJ0Tonjt6wY3kxZ';
const PROD_SUNSET_FOODS_ORG_ID = 'org_5X8rgu01f9WKuPZu';
const PROD_2988_GILLSVILLE_HWY_GAINESVILLE_GA_ORG_ID = 'org_WZujbxwtE4aCvb9L';
const PROD_BREEZE_THRU_ORG_ID = 'org_ScbUWogdOjB1RNZX';
const PROD_BUNTYN_SINGH_ORG_ID = 'org_gHIZzQWCNNetaRri';
const PROD_COYNE_OIL_ORG_ID = 'org_aJoEjraj5fZiY5WS';
const PROD_DANNY_HAN_ORG_ID = 'org_5fZpaVJFmBC6A1GR';
const PROD_2604_PA_115_EFFORT_PA_ORG_ID = 'org_5X8k2ZihvFpcoNmV';
const PROD_FARAZ_DATOO_ORG_ID = 'org_eaOAU45VijOdvHaS';
const PROD_FLEET_MORRIS_PETROLEUM_ORG_ID = 'org_WH8y7jwu9IIzJczd';
const PROD_JBT_PETROLEUM_ORG_ID = 'org_fsCr9ophsZ1UjUIe';
const PROD_QISMAT_ORG_ID = 'org_WdjZICXpiEk1jhUe';
const PROD_SASSYS_TRUCK_STOP_LIMESTONE_NY_ORG_ID = 'org_qbvlVA87wxgmgsXt';
const PROD_SOUTHSIDE_EXPRESS_ORG_ID = 'org_HwDo8RTuSc24hVrw';
const PROD_TEJINDER_TOOR_ORG_ID = 'org_mUXCJWzn9Ug8qCix';
const PROD_THE_DUGOUT_ORG_ID = 'org_XekDaeHqXCBodqf5';
const PROD_TUSTIN_AUTO_GAS_WORKS_ORG_ID = 'org_C3BY37oqJo81InBK';
const PROD_CASCADE_DINING_ORG_ID = 'org_CKyJ8ck51AKDWffH';
const PROD_CERIOUS_ROLLS_ORG_ID = 'org_4uSsGZKAFRQQHKq0';
const PROD_PRIMANTI_BROTHERS_ORG_ID = 'org_ANQD5DCzfHacdQLZ';
const PROD_SAINT_AND_SINNER_ORG_ID = 'org_5aRtGlhJ4J7Dx3DK';
const PROD_TASTY_RESTAURANT_GROUP_ORG_ID = 'org_JzdvWMDNAOiJa8uZ';
const PROD_ZALAT_PIZZA_ORG_ID = 'org_wIZucG91V0ttvden';
const PROD_4053_HEMPHILL_STREET_FORT_WORTH_TX_ORG_ID = 'org_vhnAnv3piyPsGlg4';
const PROD_9263_AL_79_PINSON_AL_ORG_ID = 'org_JFBNmUbpjNAOr5c7';
const PROD_DAYBREAK_MARKET_ORG_ID = 'org_LGJC09rZlTCgJ0DQ';
const PROD_DICKERSON_PETROLEUM_ORG_ID = 'org_fxjDbO9mGKsdm92L';
const PROD_HI_FI_EXPRESS_ORG_ID = 'org_WuwUo8N8wAlEYrQA';
const PROD_TRI_STATE_PETROLEUM_ORG_ID = 'org_8Wsp4nFntttTUSLg';
const PROD_ALBO_PIZZA_ORG_ID = 'org_suagzIh1DOCAAcaw';
const PROD_DENNYS_ORG_ID = 'org_LZbDd3lGOJtkNeiI';
const PROD_DOMINICAN_CAKE_BY_G_ORG_ID = 'org_07JeCN3bZRJE3Vbp';
const PROD_JONNY_BS_SPORTS_BAR_ORG_ID = 'org_XwWIG2Ab4ZWa59Pe';
const PROD_LPG_ORG_ID = 'org_6Vog32rKPn4diCXI';
const PROD_LAKEWOOD_LOCAL_ORG_ID = 'org_3nQcE8HMvZxyXjth';
const PROD_MBR_MANAGEMENT_ORG_ID = 'org_QgQrS7CqfSC78bZZ';
const PROD_PAPA_MURPHYS_ORG_ID = 'org_taZOUUh066JwSvau';
const PROD_POPS_ORG_ID = 'org_ksqZM6cPVtucBFRN';
const PROD_THE_REC_PIZZA_AND_DETROIT_EATS_ORG_ID = 'org_DsZZQyMrCpW722QC';
const PROD_UPFRESH_KITCHEN_HIGHLAND_CREEK_ORG_ID = 'org_cuMpOaIpZeg6i3B6';
const PROD_URBAN_BRICKS_MARBLE_SLAB_ORG_ID = 'org_kaSRdRTpUjAzTGq6';
const PROD_WOOSTERS_ORG_ID = 'org_7NRVRbNnxGK3wZei';
const PROD_CHRIS_SALEMS_MERCHANT_ACCOUNT_ORG_ID = 'org_Fqtky4Hv3UtoxEZx';
const PROD_CITGO_7720_STEUBENVILLE_PIKE_OAKDALE_PA_15071_ORG_ID = 'org_NstcORo9B1EftMjI';
const PROD_GILLIGAN_OIL_DEALERS_WARP_ORG_ID = 'org_bA7Wq9mo99yAlGfG';
const PROD_JEREMIAHS_ITALIAN_ICE_JOSEPH_PINA_FRANCHISE_ORG_ID = 'org_HF0lzHbRgNlmbFvj';
const PROD_RKA_PETROLEUM_COMPANIES_ORG_ID = 'org_QopwVGypxhWwJUXY';
const PROD_ROAD_RANGER_USA_ORG_ID = 'org_afxqwwCYayhJk7I6';
const PROD_SUMMIT_DISTRIBUTING_ORG_ID = 'org_KBf4ui3s3BkbYxFz';
const PROD_CHECKERS_AND_RALLYS_ORG_ID = 'org_NuEIPoy2MASgzjKj';
const PROD_CHPAN_KABOB_ORG_ID = 'org_6vkVanm2xggfxE2H';
const PROD_HOUSE_OF_CHIMNEY_CAKES_ORG_ID = 'org_rzPwo3y8rIlIpKVb';
const PROD_HUT_DIGGITY_ORG_ID = 'org_5REsNaSGwxlJQf8J';
const PROD_JS_FORT_GROUP_ORG_ID = 'org_Dv6Xdcs7YfCmAQpB';
const PROD_OFF_SQUARE_BRET_ORG_ID = 'org_fmxR65cSuuP0HIQR';
const PROD_PIZZA_HUT_OF_FORT_WAYNE_ORG_ID = 'org_0wEZQI84BMtvqi0C';
const PROD_ROCKFORD_PIZZA_ORG_ID = 'org_bAcSdVF5E6Iqk3PN';
const PROD_TEAM_DAKS_ORG_ID = 'org_TfOSFe9S8V7tLb8T';
const PROD_ASIF_LAKHANIS_MERCHANT_ACCOUNT_ORG_ID = 'org_eSwoRrM5IF47rRae';
const PROD_CHOICE_MARKET_COLFAX_ORG_ID = 'org_133vcr58KznehFm8';
const PROD_DANDY_MINI_MART_ORG_ID = 'org_MTeT6cJfITO4XGtq';
const PROD_DIVINE_OIL_CO_ORG_ID = 'org_ksAAfs2hNCeA10pW';
const PROD_KWIK_TRIP_ORG_ID = 'org_S0Y0nXL2UOcOLKKY';
const PROD_P66_4829_WEST_BLVD_POPLAR_BLUFF_MO_63901_ORG_ID = 'org_Y7OrwzYviZvtchwy';
const PROD_SEASIDE_CHEVRON_ORG_ID = 'org_wyg636EoAJV0Py5m';
const PROD_BMPP_ORG_ID = 'org_OZKdvDdnDTWZz7lq';
const PROD_BABYLON_PIZZA_ORG_ID = 'org_EuQL4EfzTFq0QcOU';
const PROD_BLUEGRASS_PIZZA_ORG_ID = 'org_wqCaS3CgcTr1JnVZ';
const PROD_DUBEST_PIZZA_ORG_ID = 'org_YSlp90eLJxQzSvDD';
const PROD_FRESHII_ORG_ID = 'org_FkdBmneNWjobCnBs';
const PROD_MACQUES_BBQ_ORG_ID = 'org_GdmnRAYtcbrZ6Du7';
const PROD_ROMANS_ROAD_PIZZA_ORG_ID = 'org_ornCDb8aDQfMU6Lq';
const PROD_SELINA_ORG_ID = 'org_GI9B3uT6qMPi5yML';
const PROD_PAQ_ORG_ID = 'org_2Jpdo1O9Q1rh7uqi';
const PROD_PIGGLYWIGGLY_ORG_ID = 'org_S9aOlqbsFfPrFHfb';
const PROD_ADIRONDACK_FOOD_AND_FUEL_ORG_ID = 'org_ynyE785BIfpId8Lp';
const PROD_APRO_DISTRIBUTION_LLC_ORG_ID = 'org_Xc10pkG9owIZ8Sfa';
const PROD_BP_1811_INDIAN_CREEK_RD_DANDRIGE_TN_ORG_ID = 'org_fbkGtELFLUvicmIY';
const PROD_BP_2576_190TH_ST_NORTH_WELTON_IA_ORG_ID = 'org_ugPGrXUIx1H3bhFB';
const PROD_CASEYS_ORG_ID = 'org_Cxxeg63By2YSLCGO';
const PROD_CHEVRON_USA_ORG_ID = 'org_RlrOqmn1LTBsydTr';
const PROD_GYT_ENTERPRISES_ORG_ID = 'org_lmwvjGpQIhrU6s8u';
const PROD_H_AND_S_ENERGY_PRODUCTS_ORG_ID = 'org_8S6mBMcK78G8Tr3S';
const PROD_MAN_DHAWANS_MERCHANT_ACCOUNT_ORG_ID = 'org_vbsXnt0lsoz1u876';
const PROD_NORTHDALE_OIL_ORG_ID = 'org_VzEyFYsnKpkRVsVC';
const PROD_SMO_INC_ORG_ID = 'org_MrKyPfG3KoAM87ZA';
const PROD_SHELL_2749_BARDON_CHAPEL_RD_AUGUSTA_GA_ORG_ID = 'org_YHRlXD8qytYiabp7';
const PROD_STINKER_STORES_ORG_ID = 'org_ciduWwVxKrJGroCm';
const PROD_AND_PIZZA_ORG_ID = 'org_wZck2FkDPKNexF67';
const PROD_1000_DEGREES_PIZZA_SALAD_WINGS_ORG_ID = 'org_mKtB7y8WQGHPGY5T';
const PROD_BURGER_KING_ORG_ID = 'org_eUNXryK8zkyfddRX';
const PROD_JRP_RESTAURANT_GROUP_ORG_ID = 'org_hN2swwW86ZMO6pOi';
const PROD_LIONS_CHOICE_ORG_ID = 'org_uQWaXaZrcouWEafb';
const PROD_PIZZA_BRAKE_ORG_ID = 'org_cDO5X5ArgHyce0bx';
const PROD_PRAIRIE_PIZZA_ORG_ID = 'org_ub2dr9TAJ8oveg0a';
const PROD_TEAM_COOKSTON_ORG_ID = 'org_8YTpQH5xvonxZHcd';
const PROD_TEAM_WASHINGTON_ORG_ID = 'org_hi26cihWmht7QJfk';
const PROD_GLOBAL_PARTNERS_ORG_ID = 'org_TQQmdER6qNYGTTf6';
const PROD_KUM_AND_GO_ORG_ID = 'org_KllHWomGQlCeRUS2';
const PROD_HI_NOON_PETROLEUM_ORG_ID = 'org_84B68DXMaHiW7vQc';
const PROD_UNITED_ENERGY_DISTRIBUTORS_ORG_ID = 'org_MsBdWAptoEFjoRyf';
const PROD_CHAITOS_LATIN_SOCIAL_RESTAURANT_ORG_ID = 'org_cypvJhstbDdUtxz2';
const PROD_FORT_GROUP_FL_ORG_ID = 'org_hhrtwUwU1Hl9iqh1';
const PROD_GROUP_120_ORG_ID = 'org_PhBWZQtlXT7be4dh';
const PROD_QFRM_HOLDINGS_ORG_ID = 'org_umb2rm5OyuBKeeux';
const PROD_NORTHGATE_ORG_ID = 'org_PmkVwsH3jTEjAWhX';
const PROD_RHODES_ORG_ID = 'org_Jp2dyjaZaJFU7kKt';
const PROD_ANKUR_PURANI_MERCHANTACCOUNT_ORG_ID = 'org_TPLkMTOac8Wuufzv';
const PROD_BOSSELMAN_ENTERPRISES_ORG_ID = 'org_y1BDF4nWQZsKekfM';
const PROD_CORRIGAN_OIL_COMPANY_ORG_ID = 'org_v8ENIak8SUAdnZZM';
const PROD_ED_STAUB_SONS_ORG_ID = 'org_RaNTN5eSQYfate0f';
const PROD_FOLK_OIL_ORG_ID = 'org_mEAFjH7hcK4IXleQ';
const PROD_MERLE_BOES_ORG_ID = 'org_B4juBhr3QXmOk0ip';
const PROD_RED_TRIANGLE_OIL_CO_ORG_ID = 'org_pvD4zdMOD28JThpX';
const PROD_REFUEL_OPERATING_COMPANY_ORG_ID = 'org_r4YANTWCwPp2IC4D';
const PROD_THE_CONVENIENCE_GROUP_ORG_ID = 'org_URlVh02fIrBZqmTY';
const PROD_WESTCO_OIL_DBA_CHAMPLAIN_FARMS_ORG_ID = 'org_lw9svV7AC3pzTbvX';
const PROD_WESTCLIFFE_PETROLEUM_LLC_ORG_ID = 'org_WASeEbSTAE9UM99C';
const PROD_WHITEHEAD_OIL_CO_ORG_ID = 'org_oUPXdQsnSymnOlfM';
const PROD_BOBA_SHMOBA_ORG_ID = 'org_XRTfOq2vva1sa6Nr';
const PROD_COMMONWEALTH_PIZZA_ORG_ID = 'org_l7hR9Z5G7X38Ya9x';
const PROD_CURRY_IN_A_HURRY_ORG_ID = 'org_c2DXivewrzyI5foJ';
const PROD_JGS_OLD_FASHIONED_HAMBURGERS_ORG_ID = 'org_eHaN3BYUeJ6CU1Er';
const PROD_PCF_RESTAURANT_GROUP_ORG_ID = 'org_rqT3DljbOe0ppR8B';
const PROD_PIZZA_RANCH_ORG_ID = 'org_827SdY0Go501HnqJ';
const PROD_RPM_PIZZA_CENTRAL_MS_ORG_ID = 'org_BW8UhKYSR2rYCZAn';
const PROD_STEVEN_WALDOCKS_MERCHANT_ACCOUNT_ORG_ID = 'org_I46pLAiYEFRTZfdR';
const PROD_TOSSITUP_INC_ORG_ID = 'org_nKAtMs83tESqKotn';
const PROD_TRIGO_HOSPITALITY_ORG_ID = 'org_88AYeywnAcpmPcu5';
const PROD_76_952_SIMMERHORN_RD_ORG_ID = 'org_yO45G0k6aMxFLNo3';
const PROD_ARCO_4095_RAILROAD_AVE_ORG_ID = 'org_Abys4NseZBV03sPZ';
const PROD_COUNTYWIDE_PETROLEUM_ORG_ID = 'org_eTLbJRYRnlvVPABX';
const PROD_MCINTOSH_ENERGY_ORG_ID = 'org_MID3wJqObt5DBSty';
const PROD_MICHAEL_OCONNOR_ORG_ID = 'org_K7yzLlxU8EPDXdtl';
const PROD_OMRAN_TANK_LINE_ORG_ID = 'org_YaVmNWqbKKyLIZrL';
const PROD_ONE_ENERGY_INC_ORG_ID = 'org_9Hk4Nta7OWDSAilS';
const PROD_QUALITY_PETROLEUM_CORPORATION_ORG_ID = 'org_zoKvixo0mCRNwzR8';
const PROD_SCHATZ_CROSSROADS_ORG_ID = 'org_xv17dQpbaG3zNsxG';
const PROD_TA_OPERATING_CORP_ORG_ID = 'org_Hc7G2a9b0BfJ8ivy';
const PROD_WILLOUGHBY_HILLS_DEVELOPMENT_DISTRIBUTION_ORG_ID = 'org_5qnMw7tk2XOVcPXA';
const PROD_HOMETOWN_FOODS_ORG_ID = 'org_3sbtKUgPaE7aSciH';
const PROD_DOMINOS_LUCINDA_ORG_ID = 'org_IGCEVdkqkIOkJR5j';
const PROD_DRIZL_ORG_ID = 'org_qXqF9EuWIP2WEXuK';
const PROD_FOUR_PALATES_GELATO_ORG_ID = 'org_TvI3QozTjEnRTsEs';
const PROD_HANDCRAFT_BURGERS_AND_BREW_ORG_ID = 'org_5MM3vzwxusE6Es8l';
const PROD_INTERMEZZO_WOOD_FIRED_PIZZERIA_AND_RISTORANTE_ORG_ID = 'org_qrxjJ9Vilt1roT0E';
const PROD_MAMBO_SEAFOOD_ORG_ID = 'org_8ibB5qiREDk9ixUs';
const PROD_THE_MAIN_COURSE_ORG_ID = 'org_psojwm8FaypyI38L';
const PROD_ANZ_PETROLEUM_ORG_ID = 'org_mxwB9FFqaE1GaZc4';
const PROD_BUCKEYE_ENERGY_SERVICES_ORG_ID = 'org_R2NCUseBOcaeKeEX';
const PROD_ENMARKET_ORG_ID = 'org_LLrsg1qvxhl30OOD';
const PROD_GAS_LAND_PETROLEUM_ORG_ID = 'org_oyMtkmz22RakAQNY';
const PROD_GEORGE_ZACHARAKIS_MERCHANT_ACCOUNT_ORG_ID = 'org_tAJWMPeECHWEwa2m';
const PROD_SANDRI_LLC_ORG_ID = 'org_hfPTDo7uwMFD3LLB';
const PROD_GO_GROCER_ORG_ID = 'org_Z6jX4kO0AjaJcJKy';
const PROD_AJD_PIZZA_MANAGEMENT_ORG_ID = 'org_8xu4ZLui3kv7wcLn';
const PROD_CAVE_ENTERPRISES_ORG_ID = 'org_Us0KqjCSQ5CpdoHy';
const PROD_G_MADE_ORG_ID = 'org_Rv5ENluxy99KcSMf';
const PROD_HOSPITALITY_MEMPHIS_ORG_ID = 'org_jZISupBxJcbSMA0f';
const PROD_HOSPITALITY_TENNESSEE_ORG_ID = 'org_N4okKXJpJhZw36cm';
const PROD_NZO_LLC_ORG_ID = 'org_85cExAcwI98liHeZ';
const PROD_ROYAL_GRILL_ORG_ID = 'org_qjXHY8U2U8hlFKop';
const PROD_TEAM_LYDERS_ORG_ID = 'org_9Lt1q3sBWShSN1nW';
const PROD_ARANOSIAN_OIL_CO_ORG_ID = 'org_8UHFsVJUyyjNfYKC';
const PROD_CHEVRON_10736_SPENCER_HIGHWAY_ORG_ID = 'org_bEj26RUoKmlWFy7l';
const PROD_HIGH_POINTE_OIL_COMPANY_ORG_ID = 'org_yXbWooItEj8HFI9E';
const PROD_JERICHO_WHOLESALE_LLC_ORG_ID = 'org_A7Y5QeHJB5rfcaRS';
const PROD_MARATHON_FLINT_OIL_COMPANY_ORG_ID = 'org_GhqBSb3G2gdo5KUn';
const PROD_RAHIM_METALWALAS_MERCHANT_ACCOUNT_ORG_ID = 'org_woDXswMuNgldf1FA';
const PROD_RICHARDS_AVE_LLC_ORG_ID = 'org_7CBnNzoFFuVZ0g6n';
const PROD_YATOOMA_OIL_ORG_ID = 'org_nuqzH3GZTIFVnPbc';
const PROD_ARTHURS_CUP_LLC_ORG_ID = 'org_JAtvMVr2ppu2zoXQ';
const PROD_CATO_GROUP_ORG_ID = 'org_FZ3jtRcMGUJj1s7y';
const PROD_CBBC_LLC_ORG_ID = 'org_Gs2usl7oC8Eqce2a';
const PROD_PJ_WHELIHANS_ORG_ID = 'org_7BDL1BpKfNzy2IL4';
const PROD_CIRCLE_K_COASTAL_CAROLINA_ORG_ID = 'org_0UWY4dHVI4TeeHKU';
const PROD_CIRCLE_K_FLORIDA_ORG_ID = 'org_B91esCqtZfoLRPc9';
const PROD_CIRCLE_K_GREAT_LAKES_ORG_ID = 'org_EDbl4fLKHJXxi6RC';
const PROD_CIRCLE_K_GULF_COAST_ORG_ID = 'org_AqWPW7k5lrazC61h';
const PROD_CIRCLE_K_MIDWEST_ORG_ID = 'org_1l5JBN28OStvURUv';
const PROD_CIRCLE_K_ROCKY_MOUNTAIN_ORG_ID = 'org_hUijRaCS6Y48emC8';
const PROD_CIRCLE_K_SOUTH_ATLANTIC_ORG_ID = 'org_Vl03fq5qeZZAgfYv';
const PROD_CIRCLE_K_SOUTHEAST_ORG_ID = 'org_rgzIzXVdBwKXjbDK';
const PROD_CIRCLE_K_TEXAS_ORG_ID = 'org_Mlbm8ZUDgkA9q0QC';
const PROD_CIRCLE_K_WEST_COAST_ORG_ID = 'org_Z9EsN4SZEGxO7tgr';
const PROD_CIRCLE_K_GRAND_CANYON_ORG_ID = 'org_2mLrWrK2PUTwxvwU';
const PROD_CIRCLE_K_HEARTLAND_ORG_ID = 'org_aTw2kdnJbc27Woye';
const PROD_CIRCLE_K_NORTHERN_TIER_ORG_ID = 'org_Hm96usrl2b8RfXc2';
const PROD_CARALUZZIS_MARKET_ORG_ID = 'org_nxrngZVVA4WQzYl2';
const PROD_UPISDE_PAYOUTS_ORG_ID = 'org_j6bTIRqaiAbbhCME';
const PROD_ALOHA_PETROLEUM_ORG_ID = 'org_gCBIWsuGObRyhqzF';
const PROD_APPLEGREEN_LLC_ORG_ID = 'org_Xy7aGVJrraLUhDtW';
const PROD_DANBY_GASOLINE_MARKETERS_ORG_ID = 'org_QSGJCEalRFDfMU0b';
const PROD_MANDM_SERVICE_CO_ORG_ID = 'org_8iSu9lPrUhrVRKN0';
const PROD_PALISADES_FUEL_ORG_ID = 'org_T89vE1CFLfqclL81';
const PROD_REID_PETROLEUM_CORP_ORG_ID = 'org_6gGATFzoWZuboUy0';
const PROD_STEINHAGEN_OIL_CO_ORG_ID = 'org_jv2Rr8mkaqrLY1nR';
const PROD_GIANTEAGLE_ORG_ID = 'org_XD9CTXtW6KilHS0u';
const PROD_TONYS_FRESH_MARKET_ORG_ID = 'org_jJyxgqWZabvjgxAg';
const PROD_BOSTON_PIE_ORG_ID = 'org_jBFzjGZkBHZbPHsG';
const PROD_DTID_PIZZA_ORG_ID = 'org_UPftaLBBSSe0tyrR';
const PROD_DRY_RIDGE_CHILI_ORG_ID = 'org_iTCRX5YJiQPEJGqn';
const PROD_JOHN_WAYNE_PIZZA_CO_ORG_ID = 'org_Y0MfF7IsZfiIzdtv';
const PROD_LITTLE_CAESAR_ENTERPRISES_ORG_ID = 'org_2VQXXdSd3Ee5YWaE';
const PROD_MAPS_PH_MANAGEMENT_ORG_ID = 'org_L7yozi9zaJPYik8X';
const PROD_NORTH_TEXAS_WINGS_ORG_ID = 'org_y7MKHqdCMIl2SNts';
const PROD_PAQ_INC_RESTAURANT_ORG_ID = 'org_8PwC59hzd9LFk4Fs';
const PROD_PACIFIC_BELLS_ORG_ID = 'org_7SkToduR7zO4PVup';
const PROD_SUN_HOLDINGS_ORG_ID = 'org_XoGFt6OpUTXaP2yV';
const PROD_ALEX_WILLIAMS_ORG_ID = 'org_uiNKlrHW01ZQHhua';
const PROD_CHRISTENSEN_ORG_ID = 'org_YpzkmIF43aAMKV6q';
const PROD_FIRST_COAST_ENERGY_ORG_ID = 'org_QZx0L2LEYYsglpFn';
const PROD_P66_CONSIGNMENT_SITES_ORG_ID = 'org_QPv6tuNw2cv0kLAN';
const PROD_RL_JORDAN_OIL_ORG_ID = 'org_P8dWN4GJuTnwApct';
const PROD_RACETRAC_ORG_ID = 'org_8xU31tzUcvd7c7K2';
const PROD_SGD_ORG_ID = 'org_FbQImm47AKikE4dV';
const PROD_THRIFTWAY_SUPER_STOPS_ORG_ID = 'org_PCdek4x44M8k1d3H';
const PROD_TRI_STAR_ENERGY_ORG_ID = 'org_MtF72hlT9Fsjgv93';
const PROD_STRAUBS_ORG_ID = 'org_CxuKRBlndlBibzu7';
const PROD_PJ_RESTAURANTS_DBA_PAPA_JOHNS_ORG_ID = 'org_jPhtFACi2sbr22HW';
const PROD_GARY_COLLOTIA_ORG_ID = 'org_lqzEUazYckqcZFEJ';
const PROD_SUKHPAL_DHALIWAL_ORG_ID = 'org_cFzejCpviUw7tpRC';
const PROD_SUNSHINE_OIL_ORG_ID = 'org_mOQLvspkcP882Zdv';
const PROD_GOGROCER_ORG_ID = 'org_yWn3uVMigzcTj0gc';
const PROD_84802_OR_11_ORG_ID = 'org_Uy8K9ULZcfe7zvmf';
const PROD_APRIL_BENNETT_ORG_ID = 'org_eIL19Wio1PkTISV1';
const PROD_ARANCO_OIL_ORG_ID = 'org_LqQm1wGEwCbU36xq';
const PROD_BRUCETON_PETROLEUM_ORG_ID = 'org_T3wpF2moYnWFSZTD';
const PROD_EASY_STORE_IBC_ORG_ID = 'org_xUohVuFad5uaSvN1';
const PROD_HINSON_OIL_COMPANY_ORG_ID = 'org_4EWosAzDmQGsZaNo';
const PROD_PAR_PETROLEUM_ORG_ID = 'org_QqQpYDhywEjtqLSl';
const PROD_ROTHEL_BULLOCK_ORG_ID = 'org_OffD60Rnii3ohwdT';
const PROD_STAR_STOP_ORG_ID = 'org_AsnCdJ0ZXWZkmBds';
const PROD_SUGARLAND_PETROLEUM_ORG_ID = 'org_fjZ1sLIWHeHgReeK';
const PROD_TERRIBLE_HERBST_ORG_ID = 'org_8Y7rwm7XESASkTWe';
const PROD_ALTA_CONVENIENCE_ORG_ID = 'org_yqNaVz2979x2iF3R';
const PROD_BUSY_BEE_STORES_ORG_ID = 'org_17tqsWrWirx1RRuJ';
const PROD_ALLSTAR_PETROLEUM_ORG_ID = 'org_j4OACdv7mhIsPQqM';
const PROD_SUPER_C_STORES_ORG_ID = 'org_ONPZWXgKk23ZDTQl';
const PROD_AUBUCHON_COMPANY_ORG_ID = 'org_y6mqz6b6Y0iB2Ruk';
const PROD_MAXIMOS_GYRO_AND_KABOB_ORG_ID = 'org_x9WyN1SrbDzhp5LN';
const PROD_PDQ_CHICKEN_ORG_ID = 'org_0k9dFm7I02E8Wsrd';
const PROD_PM_HOLDINGS_ORG_ID = 'org_i9cKt9Kd1SCF9aKf';
const PROD_TEAM_MURPH_ORG_ID = 'org_jJYTXNcXlZ3YZmZE';
const PROD_TEAM_SPRINGFIELD_PIE_ORG_ID = 'org_zv120PhWKA9YWSKP';
const PROD_BLARNEY_CASTLE_OIL_ORG_ID = 'org_AMgnqat0FI1tlk7v';
const PROD_BUCKS_C_STORES_ORG_ID = 'org_sWInq32Z2UJ1X5I4';
const PROD_DMG_CORPORATION_ORG_ID = 'org_oy2y8Jme97tGAod1';
const PROD_ENERGY_NORTH_COMPANY_OPERATED_ACCOUNT_ORG_ID = 'org_nvUqpZs0BVuBCj13';
const PROD_HALLMARK_PETROLEUM_LLC_ORG_ID = 'org_6Ch3Je7dk5ItMNL0';
const PROD_JENKINS_OIL_CO_ORG_ID = 'org_bBtZXzRU31exWJUg';
const PROD_LONE_STAR_MARKET_ORG_ID = 'org_fsd0CB5dYDApEa0Q';
const PROD_SHORT_STOP_ORG_ID = 'org_sZBdQo9MeuSQdqbj';
const PROD_SIMONSON_STATION_STORE_ORG_ID = 'org_HvZAWjuhUejMHnBV';
const PROD_TAJ_ALI_ORG_ID = 'org_gwRStUSKGZq5ziZI';
const PROD_TRUE_NORTH_ORG_ID = 'org_h2lPscDgLnY23Atk';
const PROD_TWIN_STAR_ORG_ID = 'org_UcjWpFY5tAkDq6kE';
const PROD_UNBRANDED_901_S_BROADWAY_ORG_ID = 'org_IMVbeycFvD9oZSU1';
const PROD_WAGUESPACK_OIL_CO_ORG_ID = 'org_4c1SPnJ1Z6grf0bj';
const PROD_BLAZE_PIZZA_FRANCHISEE_ORG_ID = 'org_rkCpnezHDgUkosml';
const PROD_DEJURAS_GROUP_ORG_ID = 'org_l43ACyRQaedCu3fA';
const PROD_LONERIDER_BREWING_COMPANY_ORG_ID = 'org_UZHtiI26FfPM7DHj';
const PROD_PIEOLOGY_CORPORATE_ORG_ID = 'org_kP9Li0esO7VSUiiX';
const PROD_QUAIN_ENTERPRISES_ORG_ID = 'org_90AIvaYfZ0q7Usq4';
const PROD_SHAWARMA_PRESS_ORG_ID = 'org_Sr8QmF6Al3ZaYzCg';
const PROD_TASTY_CHICKN_ORG_ID = 'org_aBI3bL4kXmJB9ICI';
const PROD_TASTY_KING_ORG_ID = 'org_YbXsqPdLrIYsS0hN';
const PROD_VERMA_FOOD_INC_JSER_FOOD_INC_ORG_ID = 'org_W2kaQI7k5IRus3Ae';
const PROD_WESTSHORE_PIZZA_ORG_ID = 'org_mrhGIHEQZPffUg30';
const PROD_RICKER_OIL = 'org_oQdIO41cSmnnbN6b';
const PROD_SHOP_N_SAVE = 'org_2ob74b1ITIR1ymoH';
const PROD_RPM_PIZZA = 'org_6uIEcITnYtIaZURo';
const PROD_DAVENPORT_ENERGY = 'org_YsvB2OxjAeWHVYhr';
const PROD_SUNOCO_LP = 'org_e5lI6OXEfF600uZn';
const PROD_TASTY_DLITES = 'org_CYIkNYfzoCG1MzGv';
const PROD_DELEK_RETAILS = 'org_yNoAwd2h7TaFyK12';
const PROD_SUN_STATE_OIL = 'org_TW654CViyD4rMSPw';
const PROD_CROSSROADS_FUEL = 'org_cY2lcugDzE1rrhHD';
const PROD_PETRO_NATIONAL = 'org_3uznpNHUDyyIAwZU';
const PROD_MITZI_HOWARDS = 'org_BgUq5hR1FuT1AEJn';
const PROD_JAMES_RIVER_PETRO = 'org_cR5gAg2Gem28aqWX';
const PROD_BATZ_PIZZA = 'org_HpVbUyEQ1Y7lOIKt';
const PROD_BERWICK_COOP_1111 = 'org_JciniGKVRvUXqa0V';
const PROD_SOUTHERN_COUNTIES_OIL = 'org_vztHjlbEjd2dCTBx';
const PROD_GRJH = 'org_faf8u2nDOkoMHnYA';
const PROD_DOWNEY_OIL = 'org_PpSkRiDiXIRwp8w9';
const PROD_SAVON = 'org_UJbcOVejFdmFdgPL';
const PROD_GIANT_EAGLE_C_DIV = 'org_224bzA3NHl59pip6';
const PROD_GIANT_EAGLE_TOTAL = 'org_9vthZHzVhOmklkxo';
const PROD_VAN_DE_POL = 'org_E1cbLLjpGyJBjN26';
const PROD_ALON_DELEK = 'org_O2KmY8dCL5cbBIsb';
const PROD_THE_REAL_ESTATE_GROUP = 'org_ZuLqtEIVZuIg3tWk';

// Prod Parent Orgs
const PROD_CIRCLE_K_ALL_ORG_ID = 'org_RyQq8RXC4eAz87eZ';
const PROD_HERITAGE_GROCERY_GROUP = 'org_t4tE5pu1Z3NSFHMI';

// ALPHA
const ALPHA_ACME_FRESH_MARKET_ORG_ID = 'org_NNkcAncMObiexAOD';
const ALPHA_CARDENAS_ORG_ID = 'org_I1X35naL7c0fPSnz';
const ALPHA_CARLIE_CS_ORG_ID = 'org_byw7ueEkzv7StZAj';
const ALPHA_COBORNS_ORG_ID = 'org_EMjr0UuN6mKJeLCr';
const ALPHA_FRESH_FOODS_ORG_ID = 'org_DhCZfPFjpwIXU7IW';
const ALPHA_GEISSLERS_ORG_ID = 'org_KSf5vekf66ktpQi9';
const ALPHA_GELSONS_ORG_ID = 'org_omCTx4nhU9KEfVw6';
const ALPHA_MIDTOWNE_MARKET_ORG_ID = 'org_Qlty30PiiohNxH1z';
const ALPHA_MOTHERS_MARKET_KITCHEN_ORG_ID = 'org_A6bJAedWzGcgD7id';
const ALPHA_PRICE_CHOPPER_ORG_ID = 'org_9nvX1IwDHbBv1WbF';
const ALPHA_PRICE_CHOPPER_STL_ORG_ID = 'org_Y4xGoabfZmzSyCVl';
const ALPHA_RIESBECK_FOOD_MARKETS_ORG_ID = 'org_FKctTC9yEyTS90SD';
const ALPHA_ROSAUERS_ORG_ID = 'org_neM2VfS6qjzbIQ52';
const ALPHA_SAVE_A_LOT_ORG_ID = 'org_6L7ytK3AIxdlLe4L';
const ALPHA_SCHNUCKS_ORG_ID = 'org_YrBMCdpNhkyNxEVX';
const ALPHA_SEG_ORG_ID = 'org_NYUyyiktybEaXYRC';
const ALPHA_SUNSET_FOODS_ORG_ID = 'org_ExykX1JvV2i3hUFD';
const ALPHA_APRO_ORG_ID = 'org_gxYqgysm3lJ33zd8';
const ALPHA_GPM_ORG_ID = 'org_1vZgao9jpvCyQSLL';
const ALPHA_HARMS_OIL_ORG_ID = 'org_BHFIfWF8n6MMN9U7';
const ALPHA_2988_GILLSVILLE_HWY_GAINESVILLE_GA_ORG_ID = 'org_U9XZpvVUl0pctLuQ';
const ALPHA_BREEZE_THRU_ORG_ID = 'org_3pTNyDOBjzq5un0B';
const ALPHA_BUNTYN_SINGH_ORG_ID = 'org_omZnhUGuYIGUCbDo';
const ALPHA_COYNE_OIL_ORG_ID = 'org_oyR3QnLi8SBQoQaG';
const ALPHA_DANNY_HAN_ORG_ID = 'org_oiO2CDcWKshyHhFn';
const ALPHA_2604_PA_115_EFFORT_PA_ORG_ID = 'org_CQKP3qnWiI1w9XOv';
const ALPHA_FARAZ_DATOO_ORG_ID = 'org_N7GTBlPoL60nvYGj';
const ALPHA_FLEET_MORRIS_PETROLEUM_ORG_ID = 'org_4aJgACMXDE5rXnVp';
const ALPHA_JBT_PETROLEUM_ORG_ID = 'org_2FB0R5JB0JPJrnlm';
const ALPHA_QISMAT_ORG_ID = 'org_ZEWnfEEmQrWmPPL3';
const ALPHA_SASSYS_TRUCK_STOP_LIMESTONE_NY_ORG_ID = 'org_2VDLgpgyPj3fceQ8';
const ALPHA_SOUTHSIDE_EXPRESS_ORG_ID = 'org_C9azvMKJ360Lamcp';
const ALPHA_TEJINDER_TOOR_ORG_ID = 'org_kGHP0LV2W73CGjQG';
const ALPHA_THE_DUGOUT_ORG_ID = 'org_O70wUCwbyl9DaRSV';
const ALPHA_TUSTIN_AUTO_GAS_WORKS_ORG_ID = 'org_TEruaLZ4VmZSvpED';
const ALPHA_CASCADE_DINING_ORG_ID = 'org_Gc6ULmbNJfTiS107';
const ALPHA_CERIOUS_ROLLS_ORG_ID = 'org_R0X4qa2DOpXFJIdG';
const ALPHA_PRIMANTI_BROTHERS_ORG_ID = 'org_Gw1U0VlPQ0HTtvAx';
const ALPHA_SAINT_AND_SINNER_ORG_ID = 'org_k4WHscF12T7QuPYI';
const ALPHA_TASTY_RESTAURANT_GROUP_ORG_ID = 'org_i81TjMlBsExSF3UY';
const ALPHA_ZALAT_PIZZA_ORG_ID = 'org_KOZE4bItKArM8Iau';
const ALPHA_4053_HEMPHILL_STREET_FORT_WORTH_TX_ORG_ID = 'org_WkvYvsqZnqqVcfdj';
const ALPHA_9263_AL_79_PINSON_AL_ORG_ID = 'org_K5wfcv7qnLNviN5z';
const ALPHA_DAYBREAK_MARKET_ORG_ID = 'org_HZD6Ja5luakx1zle';
const ALPHA_DICKERSON_PETROLEUM_ORG_ID = 'org_pQezCLlfcwaYdOgp';
const ALPHA_HI_FI_EXPRESS_ORG_ID = 'org_jHSpF5NcsbJWpm4n';
const ALPHA_TRI_STATE_PETROLEUM_ORG_ID = 'org_9Y2C0ASKNs8aoNJR';
const ALPHA_ALBO_PIZZA_ORG_ID = 'org_iIB5XlVF7we8HuBe';
const ALPHA_DENNYS_ORG_ID = 'org_DuiDOqhsboyAVUs2';
const ALPHA_DOMINICAN_CAKE_BY_G_ORG_ID = 'org_rxXFzHAS1uNyqueH';
const ALPHA_JONNY_BS_SPORTS_BAR_ORG_ID = 'org_figKo6ymoBTPtPr6';
const ALPHA_LPG_ORG_ID = 'org_FCkU9gAHUpqOJLCl';
const ALPHA_LAKEWOOD_LOCAL_ORG_ID = 'org_zmsU37YYYKGdrVpz';
const ALPHA_MBR_MANAGEMENT_ORG_ID = 'org_BvTj8Wscp0xFBLUK';
const ALPHA_PAPA_MURPHYS_ORG_ID = 'org_TBUPW8qklB2qI2Is';
const ALPHA_POPS_ORG_ID = 'org_Ozv89OdhGKKphubu';
const ALPHA_THE_REC_PIZZA_AND_DETROIT_EATS_ORG_ID = 'org_GqLeoLKOkbvmbKm9';
const ALPHA_UPFRESH_KITCHEN_HIGHLAND_CREEK_ORG_ID = 'org_sfh0AJ23XMMGRme7';
const ALPHA_URBAN_BRICKS_MARBLE_SLAB_ORG_ID = 'org_h0LIuXRew9qjgb9w';
const ALPHA_WOOSTERS_ORG_ID = 'org_UKLFHmy4WBzGJdBz';
const ALPHA_CHRIS_SALEMS_MERCHANT_ACCOUNT_ORG_ID = 'org_xvLkjJt0arjzjDU7';
const ALPHA_CITGO_7720_STEUBENVILLE_PIKE_OAKDALE_PA_15071_ORG_ID = 'org_yBFXLKPpznqjgknI';
const ALPHA_GILLIGAN_OIL_DEALERS_WARP_ORG_ID = 'org_9wH3jelJqgsB3lTG';
const ALPHA_JEREMIAHS_ITALIAN_ICE_JOSEPH_PINA_FRANCHISE_ORG_ID = 'org_Bk98gZ8LGhBKHFOL';
const ALPHA_RKA_PETROLEUM_COMPANIES_ORG_ID = 'org_uQWpr4LCRbmx06f4';
const ALPHA_ROAD_RANGER_USA_ORG_ID = 'org_rOv5wpc32Vbt34T2';
const ALPHA_SUMMIT_DISTRIBUTING_ORG_ID = 'org_G8xaf3bpuRIdtdvX';
const ALPHA_CHECKERS_AND_RALLYS_ORG_ID = 'org_JdstY83bjiAcneWN';
const ALPHA_CHPAN_KABOB_ORG_ID = 'org_cnrJUepdtFQloVE2';
const ALPHA_HOUSE_OF_CHIMNEY_CAKES_ORG_ID = 'org_zJbSBfhpuICY5kNz';
const ALPHA_HUT_DIGGITY_ORG_ID = 'org_biGhtXjHeBSBMDom';
const ALPHA_JS_FORT_GROUP_ORG_ID = 'org_5EAyGZaW0wXNXAke';
const ALPHA_OFF_SQUARE_BRET_ORG_ID = 'org_L1Lk9EhNaxd4wIdq';
const ALPHA_PIZZA_HUT_OF_FORT_WAYNE_ORG_ID = 'org_GOuUBtBhJvmqnlDA';
const ALPHA_ROCKFORD_PIZZA_ORG_ID = 'org_dEFDr0Rpecsua9j8';
const ALPHA_TEAM_DAKS_ORG_ID = 'org_OWStV9I9CCwftpBQ';
const ALPHA_ASIF_LAKHANIS_MERCHANT_ACCOUNT_ORG_ID = 'org_WgaOJgl3w8V6V5L9';
const ALPHA_CHOICE_MARKET_COLFAX_ORG_ID = 'org_SPS0KfOKTRJKAgH2';
const ALPHA_DANDY_MINI_MART_ORG_ID = 'org_iE6vDbnbESbh0QvC';
const ALPHA_DIVINE_OIL_CO_ORG_ID = 'org_UfqTmjNYub8UVQKP';
const ALPHA_KWIK_TRIP_ORG_ID = 'org_sRF1RgIrTapYFeJ8';
const ALPHA_P66_4829_WEST_BLVD_POPLAR_BLUFF_MO_63901_ORG_ID = 'org_y5TrSfZnhTKOjK8f';
const ALPHA_SEASIDE_CHEVRON_ORG_ID = 'org_iZcrXqBeEWUhN0ZI';
const ALPHA_BMPP_ORG_ID = 'org_uNvakjoivcAfgOIE';
const ALPHA_BABYLON_PIZZA_ORG_ID = 'org_gNYFPQdWle6xluSH';
const ALPHA_BLUEGRASS_PIZZA_ORG_ID = 'org_QyGp92Be2dE7iMOb';
const ALPHA_DUBEST_PIZZA_ORG_ID = 'org_Kpvro0OShE9j82iU';
const ALPHA_FRESHII_ORG_ID = 'org_MSXOIqjJf3MGe0ma';
const ALPHA_MACQUES_BBQ_ORG_ID = 'org_UBM4iRERLmaAIp8a';
const ALPHA_ROMANS_ROAD_PIZZA_ORG_ID = 'org_KyDfiTpiBobzA0dy';
const ALPHA_SELINA_ORG_ID = 'org_nKO0S2Vu31UNghcw';
const ALPHA_PAQ_ORG_ID = 'org_qdKOQMsfPu9jwKDs';
const ALPHA_PIGGLYWIGGLY_ORG_ID = 'org_icMWfSaiDo4v8QiV';
const ALPHA_ADIRONDACK_FOOD_AND_FUEL_ORG_ID = 'org_XzGpHMx9N64D6LdS';
const ALPHA_APRO_DISTRIBUTION_LLC_ORG_ID = 'org_3kkmPrPHPpkxPr1P';
const ALPHA_BP_1811_INDIAN_CREEK_RD_DANDRIGE_TN_ORG_ID = 'org_FxgIgDVYDkTrmZR8';
const ALPHA_BP_2576_190TH_ST_NORTH_WELTON_IA_ORG_ID = 'org_WyqEAL5tsbHhtPYI';
const ALPHA_CASEYS_ORG_ID = 'org_tlyuXhv7xtSRAMla';
const ALPHA_CHEVRON_USA_ORG_ID = 'org_uqRWrlDZj4lYlAeP';
const ALPHA_GYT_ENTERPRISES_ORG_ID = 'org_omdCh68fiZOb6tgd';
const ALPHA_H_AND_S_ENERGY_PRODUCTS_ORG_ID = 'org_N38NF3Hr7cTVXUOY';
const ALPHA_MAN_DHAWANS_MERCHANT_ACCOUNT_ORG_ID = 'org_dl93Z8FnH76KsfTS';
const ALPHA_NORTHDALE_OIL_ORG_ID = 'org_nO4CivrX5jhQKjcz';
const ALPHA_SMO_INC_ORG_ID = 'org_mhx6hfBhIT9OFfzt';
const ALPHA_SHELL_2749_BARDON_CHAPEL_RD_AUGUSTA_GA_ORG_ID = 'org_of0h1Z2qdO5or1Au';
const ALPHA_STINKER_STORES_ORG_ID = 'org_eKvYhgW020iFvr9j';
const ALPHA_AND_PIZZA_ORG_ID = 'org_fCoCmOKajx01vtYG';
const ALPHA_1000_DEGREES_PIZZA_SALAD_WINGS_ORG_ID = 'org_teh9ntzADM7zslM6';
const ALPHA_BURGER_KING_ORG_ID = 'org_t00Webo4IP8Ae2pp';
const ALPHA_JRP_RESTAURANT_GROUP_ORG_ID = 'org_nugg1sIA4dGaTEaP';
const ALPHA_LIONS_CHOICE_ORG_ID = 'org_KmKpwp9DtrwTIjTu';
const ALPHA_PIZZA_BRAKE_ORG_ID = 'org_2xUNRUZo83TaFwIp';
const ALPHA_PRAIRIE_PIZZA_ORG_ID = 'org_NntfaSbggVmhEffo';
const ALPHA_TEAM_COOKSTON_ORG_ID = 'org_XwvbYu5H1gdl30bk';
const ALPHA_TEAM_WASHINGTON_ORG_ID = 'org_H6xlg6hEmQNf9q9d';
const ALPHA_GLOBAL_PARTNERS_ORG_ID = 'org_tEXUGvAl0RyyqP3d';
const ALPHA_KUM_AND_GO_ORG_ID = 'org_1Ob14HybCr73Uunn';
const ALPHA_HI_NOON_PETROLEUM_ORG_ID = 'org_dGmAheTFt1cSSgh4';
const ALPHA_UNITED_ENERGY_DISTRIBUTORS_ORG_ID = 'org_3MXUxuV0EWnXq0e1';
const ALPHA_CHAITOS_LATIN_SOCIAL_RESTAURANT_ORG_ID = 'org_iqOQZiY7UAdlDjL3';
const ALPHA_FORT_GROUP_FL_ORG_ID = 'org_JaB5TgpJ9oBsGXmg';
const ALPHA_GROUP_120_ORG_ID = 'org_O77bq2s7McqQR42c';
const ALPHA_QFRM_HOLDINGS_ORG_ID = 'org_24mUi1DFvx52TxsP';
const ALPHA_NORTHGATE_ORG_ID = 'org_31LNKJhvDbLrDyEd';
const ALPHA_RHODES_ORG_ID = 'org_Uj2Zo6JxgHCY1ptO';
const ALPHA_ANKUR_PURANI_MERCHANTACCOUNT_ORG_ID = 'org_U4lmoRWp9Knw9L3M';
const ALPHA_BOSSELMAN_ENTERPRISES_ORG_ID = 'org_Rbs9FCQ4G6WGH3SJ';
const ALPHA_CORRIGAN_OIL_COMPANY_ORG_ID = 'org_lnpaGWBFQQmV6UOo';
const ALPHA_ED_STAUB_SONS_ORG_ID = 'org_Tx4RajL1c2yibYYp';
const ALPHA_FOLK_OIL_ORG_ID = 'org_qAqGOSpR3x4eEN9T';
const ALPHA_MERLE_BOES_ORG_ID = 'org_MpdQQoDiKOYON164';
const ALPHA_RED_TRIANGLE_OIL_CO_ORG_ID = 'org_fS4DpGMjAccZN2j1';
const ALPHA_REFUEL_OPERATING_COMPANY_ORG_ID = 'org_bYNUfErELOHPim7Z';
const ALPHA_THE_CONVENIENCE_GROUP_ORG_ID = 'org_pNcbio1NpqG8IgVs';
const ALPHA_WESTCO_OIL_DBA_CHAMPLAIN_FARMS_ORG_ID = 'org_tSppC2VjmX5YAsCS';
const ALPHA_WESTCLIFFE_PETROLEUM_LLC_ORG_ID = 'org_mHwdmhQvcz2IV6gS';
const ALPHA_WHITEHEAD_OIL_CO_ORG_ID = 'org_yqsfryt9QMoNfl3k';
const ALPHA_BOBA_SHMOBA_ORG_ID = 'org_frLWFypr8XVAnJZU';
const ALPHA_COMMONWEALTH_PIZZA_ORG_ID = 'org_lLapxDtWKgtUwWfD';
const ALPHA_CURRY_IN_A_HURRY_ORG_ID = 'org_B0tbJgr0LLKdkHXw';
const ALPHA_JGS_OLD_FASHIONED_HAMBURGERS_ORG_ID = 'org_hzkG2q9FietkXOvn';
const ALPHA_PCF_RESTAURANT_GROUP_ORG_ID = 'org_IFyG9tKomlmTP67u';
const ALPHA_PIZZA_RANCH_ORG_ID = 'org_KHh9o2oXV7Z4jHQh';
const ALPHA_RPM_PIZZA_CENTRAL_MS_ORG_ID = 'org_RcHec0O8YPejLxH3';
const ALPHA_STEVEN_WALDOCKS_MERCHANT_ACCOUNT_ORG_ID = 'org_Juhts81EX5mWpmnd';
const ALPHA_TOSSITUP_INC_ORG_ID = 'org_D8dS3iJn5b38pXM8';
const ALPHA_TRIGO_HOSPITALITY_ORG_ID = 'org_bx1vc4nemEnUIIDD';
const ALPHA_76_952_SIMMERHORN_RD_ORG_ID = 'org_MdKXMjspZB39bumJ';
const ALPHA_ARCO_4095_RAILROAD_AVE_ORG_ID = 'org_60vdXHlaaUve442Z';
const ALPHA_COUNTYWIDE_PETROLEUM_ORG_ID = 'org_eiR5QvfeARCu7OIv';
const ALPHA_MCINTOSH_ENERGY_ORG_ID = 'org_Orqg2PcQjIpfRlSZ';
const ALPHA_MICHAEL_OCONNOR_ORG_ID = 'org_bpqEcWCJ3mXuWwT0';
const ALPHA_OMRAN_TANK_LINE_ORG_ID = 'org_TIWIXM7VUHfr2rT6';
const ALPHA_ONE_ENERGY_INC_ORG_ID = 'org_oMaXo5EdabS1Y3mV';
const ALPHA_QUALITY_PETROLEUM_CORPORATION_ORG_ID = 'org_DRU4xBMQ4PUzQZs0';
const ALPHA_SCHATZ_CROSSROADS_ORG_ID = 'org_sVhgRN560ZRcIlhb';
const ALPHA_TA_OPERATING_CORP_ORG_ID = 'org_abEO2syvY1VoM3Q4';
const ALPHA_WILLOUGHBY_HILLS_DEVELOPMENT_DISTRIBUTION_ORG_ID = 'org_kKoIZL5iKszPT9v3';
const ALPHA_HOMETOWN_FOODS_ORG_ID = 'org_8cIcfYbMZAGIk5Wz';
const ALPHA_DOMINOS_LUCINDA_ORG_ID = 'org_ven1o0JFnT4YY4ex';
const ALPHA_DRIZL_ORG_ID = 'org_hQGURQdnKDMTzUYM';
const ALPHA_FOUR_PALATES_GELATO_ORG_ID = 'org_P7hmDcy6DW672wEZ';
const ALPHA_HANDCRAFT_BURGERS_AND_BREW_ORG_ID = 'org_RpsVa6K54AExoiA6';
const ALPHA_INTERMEZZO_WOOD_FIRED_PIZZERIA_AND_RISTORANTE_ORG_ID = 'org_EkZzX7IDHkCgJ0Ao';
const ALPHA_MAMBO_SEAFOOD_ORG_ID = 'org_NUCBkbrCFlyk2L3a';
const ALPHA_THE_MAIN_COURSE_ORG_ID = 'org_gQvThZBzoB2BiAFD';
const ALPHA_ANZ_PETROLEUM_ORG_ID = 'org_tfKMANSwDopwH6Ap';
const ALPHA_BUCKEYE_ENERGY_SERVICES_ORG_ID = 'org_SU0stcZcC8vLPYQm';
const ALPHA_ENMARKET_ORG_ID = 'org_4c7dSvmcZq8saDAQ';
const ALPHA_GAS_LAND_PETROLEUM_ORG_ID = 'org_6Ymf3BshRrZu7TJx';
const ALPHA_GEORGE_ZACHARAKIS_MERCHANT_ACCOUNT_ORG_ID = 'org_6oHRz1RUmE9HjRXp';
const ALPHA_SANDRI_LLC_ORG_ID = 'org_iSd0QPzfudRVEv00';
const ALPHA_GO_GROCER_ORG_ID = 'org_qdB1uKu6hL3TMIU8';
const ALPHA_AJD_PIZZA_MANAGEMENT_ORG_ID = 'org_lJOocHIt87BMF2EQ';
const ALPHA_CAVE_ENTERPRISES_ORG_ID = 'org_RLqJeZKKnuIqYxpO';
const ALPHA_G_MADE_ORG_ID = 'org_XUqCXRxBKPrTT5a9';
const ALPHA_HOSPITALITY_MEMPHIS_ORG_ID = 'org_SxySjAvoSupxOS0m';
const ALPHA_HOSPITALITY_TENNESSEE_ORG_ID = 'org_Idls8CuKdqtP1rlR';
const ALPHA_NZO_LLC_ORG_ID = 'org_6rvk5lRfEdaIgZyI';
const ALPHA_ROYAL_GRILL_ORG_ID = 'org_ietFeH7uWnIyclLa';
const ALPHA_TEAM_LYDERS_ORG_ID = 'org_mK1LBbVeRmTXZQS3';
const ALPHA_ARANOSIAN_OIL_CO_ORG_ID = 'org_gzGEABXurkXHS13M';
const ALPHA_CHEVRON_10736_SPENCER_HIGHWAY_ORG_ID = 'org_95ZxJvgPkfLS9sEE';
const ALPHA_HIGH_POINTE_OIL_COMPANY_ORG_ID = 'org_lSmGxJICflsgjirC';
const ALPHA_JERICHO_WHOLESALE_LLC_ORG_ID = 'org_dF6prLlIKWensHzm';
const ALPHA_MARATHON_FLINT_OIL_COMPANY_ORG_ID = 'org_dZuLw56sx6GbCczh';
const ALPHA_RAHIM_METALWALAS_MERCHANT_ACCOUNT_ORG_ID = 'org_yYR9qeygBuZEORIN';
const ALPHA_RICHARDS_AVE_LLC_ORG_ID = 'org_AxNMpTAe3IiraDGr';
const ALPHA_YATOOMA_OIL_ORG_ID = 'org_LJhyLjolsks2ZrCc';
const ALPHA_ARTHURS_CUP_LLC_ORG_ID = 'org_DyaURFKtqIWeJFNB';
const ALPHA_CATO_GROUP_ORG_ID = 'org_KVUOx0QCYr0LQNnU';
const ALPHA_CBBC_LLC_ORG_ID = 'org_5P51E97ftwIMEcBZ';
const ALPHA_PJ_WHELIHANS_ORG_ID = 'org_C9chstcQPK2XXdyC';
const ALPHA_CIRCLE_K_COASTAL_CAROLINA_ORG_ID = 'org_EzNcUJ0PdVBBbPfD';
const ALPHA_CIRCLE_K_FLORIDA_ORG_ID = 'org_HpDy5r7VgTQ5Ctgi';
const ALPHA_CIRCLE_K_GREAT_LAKES_ORG_ID = 'org_1MU0yFguf9yB0Nu9';
const ALPHA_CIRCLE_K_GULF_COAST_ORG_ID = 'org_bYEaJrSlxFEGjpZk';
const ALPHA_CIRCLE_K_MIDWEST_ORG_ID = 'org_vFg7FmoRcnCpPkJ9';
const ALPHA_CIRCLE_K_ROCKY_MOUNTAIN_ORG_ID = 'org_sxzOoybNjkmpQwIg';
const ALPHA_CIRCLE_K_SOUTH_ATLANTIC_ORG_ID = 'org_9xuR1cp23ELeVBSQ';
const ALPHA_CIRCLE_K_SOUTHEAST_ORG_ID = 'org_Ykw9NYy8oWvwOWwn';
const ALPHA_CIRCLE_K_TEXAS_ORG_ID = 'org_70aPbGQTwTYR4dr0';
const ALPHA_CIRCLE_K_WEST_COAST_ORG_ID = 'org_yzuUT6ugnrdlPKrD';
const ALPHA_CIRCLE_K_GRAND_CANYON_ORG_ID = 'org_DPGJ3qlK36J8IrwX';
const ALPHA_CIRCLE_K_HEARTLAND_ORG_ID = 'org_t36HVWNOC5qcZKjk';
const ALPHA_CIRCLE_K_NORTHERN_TIER_ORG_ID = 'org_LB4uPRhxMuntg8wA';
const ALPHA_CARALUZZIS_MARKET_ORG_ID = 'org_uXjbL67KJnz8o3jr';
const ALPHA_UPISDE_PAYOUTS_ORG_ID = 'org_BJQReYkeb8bUVMZD';
const ALPHA_ALOHA_PETROLEUM_ORG_ID = 'org_YLC8zYEZAimpcXBJ';
const ALPHA_APPLEGREEN_LLC_ORG_ID = 'org_ZJ4devrjujq8dT6d';
const ALPHA_DANBY_GASOLINE_MARKETERS_ORG_ID = 'org_zUd8ZCsaNCcLmUAE';
const ALPHA_MANDM_SERVICE_CO_ORG_ID = 'org_T6pziWtbUBNZZ3pq';
const ALPHA_PALISADES_FUEL_ORG_ID = 'org_lmlkiyaKdAVK0xTW';
const ALPHA_REID_PETROLEUM_CORP_ORG_ID = 'org_P1kNnM0jBAxp0nt9';
const ALPHA_STEINHAGEN_OIL_CO_ORG_ID = 'org_zq9Dbq3h8upUB1o3';
const ALPHA_GIANTEAGLE_ORG_ID = 'org_gKWzVNVBWVii9SgH';
const ALPHA_TONYS_FRESH_MARKET_ORG_ID = 'org_IgAQ3poZ201IwNry';
const ALPHA_BOSTON_PIE_ORG_ID = 'org_t649SfWGF6Qa47Mg';
const ALPHA_DTID_PIZZA_ORG_ID = 'org_2RD4NaOO5LfL3X3Q';
const ALPHA_DRY_RIDGE_CHILI_ORG_ID = 'org_gnHWui5Eq97FoDcM';
const ALPHA_JOHN_WAYNE_PIZZA_CO_ORG_ID = 'org_yBDQC26REiGtyxrt';
const ALPHA_LITTLE_CAESAR_ENTERPRISES_ORG_ID = 'org_LdUxCflOix0FfKzu';
const ALPHA_MAPS_PH_MANAGEMENT_ORG_ID = 'org_EYIMH3s6pyqzllQW';
const ALPHA_NORTH_TEXAS_WINGS_ORG_ID = 'org_2WKbzKEB7X9ne5Aw';
const ALPHA_PAQ_INC_RESTAURANT_ORG_ID = 'org_s61WCwwsm6ik3pa0';
const ALPHA_PACIFIC_BELLS_ORG_ID = 'org_JGcqP9P2EnKkBD4r';
const ALPHA_SUN_HOLDINGS_ORG_ID = 'org_luVKKLaKY1OrnzYb';
const ALPHA_ALEX_WILLIAMS_ORG_ID = 'org_yfkvNavl9y2acrqq';
const ALPHA_CHRISTENSEN_ORG_ID = 'org_i2hQ3iGe9KX9d2QY';
const ALPHA_FIRST_COAST_ENERGY_ORG_ID = 'org_Vft0mApmZhEFtR76';
const ALPHA_P66_CONSIGNMENT_SITES_ORG_ID = 'org_UqhuuanIZAj7B49C';
const ALPHA_RL_JORDAN_OIL_ORG_ID = 'org_iq3ADVgYgXTtNGCw';
const ALPHA_RACETRAC_ORG_ID = 'org_PA6jm4DZ6iBDOiFQ';
const ALPHA_SGD_ORG_ID = 'org_7uSdPhNPD5NwHNLe';
const ALPHA_THRIFTWAY_SUPER_STOPS_ORG_ID = 'org_T1e9zdXDXX5YUUkQ';
const ALPHA_TRI_STAR_ENERGY_ORG_ID = 'org_9bolTMP0HvdJxDiM';
const ALPHA_STRAUBS_ORG_ID = 'org_7miRlnXxHz58KFts';
const ALPHA_PJ_RESTAURANTS_DBA_PAPA_JOHNS_ORG_ID = 'org_yGYYpbSBdXUJ20tZ';
const ALPHA_GARY_COLLOTIA_ORG_ID = 'org_wP8bhyJhSeZN2jJg';
const ALPHA_SUKHPAL_DHALIWAL_ORG_ID = 'org_IhW2qbNhXGAb2aoS';
const ALPHA_SUNSHINE_OIL_ORG_ID = 'org_Nh0yHIF57RhJbtpl';
const ALPHA_GOGROCER_ORG_ID = 'org_6utZZmkt0t9ds3FW';
const ALPHA_84802_OR_11_ORG_ID = 'org_J1LQY70LuEQB2nvs';
const ALPHA_APRIL_BENNETT_ORG_ID = 'org_mk5PNsKK1Mq7dtoa';
const ALPHA_ARANCO_OIL_ORG_ID = 'org_oCCCLujXabbf8iu1';
const ALPHA_BRUCETON_PETROLEUM_ORG_ID = 'org_ZzK83W3gld5OcNYZ';
const ALPHA_EASY_STORE_IBC_ORG_ID = 'org_6sNYFuEOtcJF0tpj';
const ALPHA_HINSON_OIL_COMPANY_ORG_ID = 'org_Z1M0XQZ4fmqswyf4';
const ALPHA_PAR_PETROLEUM_ORG_ID = 'org_h7eU5REzQc9CmjKH';
const ALPHA_ROTHEL_BULLOCK_ORG_ID = 'org_gIEHbj6bwclZ0jEx';
const ALPHA_STAR_STOP_ORG_ID = 'org_mvimU8kfRnaCtPGm';
const ALPHA_SUGARLAND_PETROLEUM_ORG_ID = 'org_c0uMHsDekWPzCQgO';
const ALPHA_TERRIBLE_HERBST_ORG_ID = 'org_ShkKkZewtltMgygn';
const ALPHA_ALTA_CONVENIENCE_ORG_ID = 'org_hcbVAe41toojoeCr';
const ALPHA_BUSY_BEE_STORES_ORG_ID = 'org_2B63caNR7fCtrJ42';
const ALPHA_ALLSTAR_PETROLEUM_ORG_ID = 'org_jNWGjjApJMtAGpNR';
const ALPHA_SUPER_C_STORES_ORG_ID = 'org_7YCe3OoNzCwoYuro';
const ALPHA_AUBUCHON_COMPANY_ORG_ID = 'org_MucvPzPdIkDxC2Ei';
const ALPHA_MAXIMOS_GYRO_AND_KABOB_ORG_ID = 'org_09HnFSCcObC5DVAz';
const ALPHA_PDQ_CHICKEN_ORG_ID = 'org_7M8OSKzTwwG8gtrJ';
const ALPHA_PM_HOLDINGS_ORG_ID = 'org_Xkhnw9I51xjk0OZb';
const ALPHA_TEAM_MURPH_ORG_ID = 'org_cNxwoBj84P0Q4p6H';
const ALPHA_TEAM_SPRINGFIELD_PIE_ORG_ID = 'org_ZhZ7gNCGxy8vuMVS';
const ALPHA_BLARNEY_CASTLE_OIL_ORG_ID = 'org_SRxfo4VTQpFlx4C9';
const ALPHA_BUCKS_C_STORES_ORG_ID = 'org_9qmm7yHxSUM7Xjtc';
const ALPHA_DMG_CORPORATION_ORG_ID = 'org_EzPVDcR2B8sJTQVi';
const ALPHA_ENERGY_NORTH_COMPANY_OPERATED_ACCOUNT_ORG_ID = 'org_ZBJcYjm5KyFE2u5j';
const ALPHA_HALLMARK_PETROLEUM_LLC_ORG_ID = 'org_wlcLU84UVgTWGPIV';
const ALPHA_JENKINS_OIL_CO_ORG_ID = 'org_1kHUhIZNblPyUfGZ';
const ALPHA_LONE_STAR_MARKET_ORG_ID = 'org_TKMR0qUHF3lOWYrT';
const ALPHA_SHORT_STOP_ORG_ID = 'org_tXTdC52ATrYrKA38';
const ALPHA_SIMONSON_STATION_STORE_ORG_ID = 'org_VWu1R3W2dYmSZMwi';
const ALPHA_TAJ_ALI_ORG_ID = 'org_XeLIFY1PYCGLQZ4V';
const ALPHA_TRUE_NORTH_ORG_ID = 'org_9T8MCrlkjmEJypgd';
const ALPHA_TWIN_STAR_ORG_ID = 'org_rZcr6cQNTzmuFNXY';
const ALPHA_UNBRANDED_901_S_BROADWAY_ORG_ID = 'org_e13eTp81nNSNYNz6';
const ALPHA_WAGUESPACK_OIL_CO_ORG_ID = 'org_QgSP0KZtQsgMsTjL';
const ALPHA_BLAZE_PIZZA_FRANCHISEE_ORG_ID = 'org_F1HibTgF9do20qQX';
const ALPHA_DEJURAS_GROUP_ORG_ID = 'org_AH0E88cLEH4TR0UY';
const ALPHA_LONERIDER_BREWING_COMPANY_ORG_ID = 'org_Gwrq4CY3MDbVGZiV';
const ALPHA_PIEOLOGY_CORPORATE_ORG_ID = 'org_my8RlWnLOO4Jh8w6';
const ALPHA_QUAIN_ENTERPRISES_ORG_ID = 'org_a5hkiyV5ZJ8nUqKl';
const ALPHA_SHAWARMA_PRESS_ORG_ID = 'org_62MlPaQXNn54TaTB';
const ALPHA_TASTY_CHICKN_ORG_ID = 'org_6pbtnBKiKtVKmLWV';
const ALPHA_TASTY_KING_ORG_ID = 'org_N6fruMYEJHZsRhsq';
const ALPHA_VERMA_FOOD_INC_JSER_FOOD_INC_ORG_ID = 'org_9zpcJyiWC6rNPfjL';
const ALPHA_WESTSHORE_PIZZA_ORG_ID = 'org_YZ8jTZljojDafUvb';
const ALPHA_RICKER_OIL = 'org_KDQJrUSEVRVWEj83';
const ALPHA_SHOP_N_SAVE = 'org_B22u2iD5ffPaJD2q';
const ALPHA_RPM_PIZZA = 'org_if51W4wqaqCXxfoC';
const ALPHA_DAVENPORT_ENERGY = 'org_kgP0LCNWsadZAxdg';
const ALPHA_SUNOCO_LP = 'org_ov4IMqsJu8D2D3Wy';
const ALPHA_TASTY_DLITES = 'org_puGUrigDh3MVmTER';
const ALPHA_DELEK_RETAILS = 'org_kRMlVFLM2XPUwtfC';
const ALPHA_SUN_STATE_OIL = 'org_HzgL9XUe8pw0cY3m';
const ALPHA_CROSSROADS_FUEL = 'org_nZHfbcbyyYHFPaYe';
const ALPHA_PETRO_NATIONAL = 'org_hCa6eriyCdLTveIE';
const ALPHA_MITZI_HOWARDS = 'org_IM8I0uxN2uPeEcqn';
const ALPHA_JAMES_RIVER_PETRO = 'org_OAWisU6dGy24ion7';
const ALPHA_BATZ_PIZZA = 'org_vDMGyjC1P0DDog9Y';
const ALPHA_BERWICK_COOP_1111 = 'org_RW4ZP8bBRDQjYzYI';
const ALPHA_SOUTHERN_COUNTIES_OIL = 'org_MvFcX8E8PnsvOnd2';
const ALPHA_GRJH = 'org_zYI7pQIJty43iq5B';
const ALPHA_DOWNEY_OIL = 'org_dajwIF4tUZwReOvO';
const ALPHA_SAVON = 'org_adm3aBCHtLSMYHYr';
const ALPHA_GIANT_EAGLE_C_DIV = 'org_ptOJsh6nN7lTzqCn';
const ALPHA_GIANT_EAGLE_TOTAL = 'org_G36sC04WS7xztpF1';
const ALPHA_VAN_DE_POL = 'org_VHm38LMjbss3GMHW';
const ALPHA_ALON_DELEK = 'org_wHVAENGx2YM978C0';
const ALPHA_THE_REAL_ESTATE_GROUP = 'org_Z8FBQSuPyVm4cfgC';

// Alpha Parent Orgs
const ALPHA_CIRCLE_K_ALL_ORG_ID = 'org_FDKzllu8jiXgw1Ml';
const ALPHA_HERITAGE_GROCERY_GROUP = 'org_rNMhMCZXXnGlgP6l';

const isAlpha = ENV_CONFIG.TIER === 'alpha' || ENV_CONFIG.TIER === 'dev' ? true : false;

// TODO: build API endpoint to fetch org_id by merchant name
export const merchantOrgMap: { [key: string]: string } = {
  acme: isAlpha ? ALPHA_ACME_FRESH_MARKET_ORG_ID : PROD_ACME_FRESH_MARKET_ORG_ID,
  apro: isAlpha ? ALPHA_APRO_ORG_ID : '',
  cardenas: isAlpha ? ALPHA_CARDENAS_ORG_ID : PROD_CARDENAS_ORG_ID,
  carliecs: isAlpha ? ALPHA_CARLIE_CS_ORG_ID : PROD_CARLIE_CS_ORG_ID,
  coborns: isAlpha ? ALPHA_COBORNS_ORG_ID : PROD_COBORNS_ORG_ID,
  freshfoods: isAlpha ? ALPHA_FRESH_FOODS_ORG_ID : PROD_FRESH_FOODS_ORG_ID,
  geisslers: isAlpha ? ALPHA_GEISSLERS_ORG_ID : PROD_GEISSLERS_ORG_ID,
  gelsons: isAlpha ? ALPHA_GELSONS_ORG_ID : PROD_GELSONS_ORG_ID,
  gpm: isAlpha ? ALPHA_GPM_ORG_ID : '',
  harmsoil: isAlpha ? ALPHA_HARMS_OIL_ORG_ID : '',
  midtownemarket: isAlpha ? ALPHA_MIDTOWNE_MARKET_ORG_ID : PROD_MIDTOWNE_MARKET_ORG_ID,
  mothersmarket: isAlpha ? ALPHA_MOTHERS_MARKET_KITCHEN_ORG_ID : PROD_MOTHERS_MARKET_KITCHEN_ORG_ID,
  pricechopper: isAlpha ? ALPHA_PRICE_CHOPPER_ORG_ID : PROD_PRICE_CHOPPER_ORG_ID,
  pricechopperstl: isAlpha ? ALPHA_PRICE_CHOPPER_STL_ORG_ID : PROD_PRICE_CHOPPER_STL_ORG_ID,
  riesbeck: isAlpha ? ALPHA_RIESBECK_FOOD_MARKETS_ORG_ID : PROD_RIESBECK_FOOD_MARKETS_ORG_ID,
  rosauers: isAlpha ? ALPHA_ROSAUERS_ORG_ID : PROD_ROSAUERS_ORG_ID,
  savealot: isAlpha ? ALPHA_SAVE_A_LOT_ORG_ID : PROD_SAVE_A_LOT_ORG_ID,
  schnucks: isAlpha ? ALPHA_SCHNUCKS_ORG_ID : PROD_SCHNUCKS_ORG_ID,
  seg: isAlpha ? ALPHA_SEG_ORG_ID : PROD_SEG_ORG_ID,
  sunsetfoods: isAlpha ? ALPHA_SUNSET_FOODS_ORG_ID : PROD_SUNSET_FOODS_ORG_ID,
  upside: isAlpha ? ALPHA_UPSIDE : PROD_UPSIDE,
  '2988-gillsville-hwy-gainesville-ga': isAlpha
    ? ALPHA_2988_GILLSVILLE_HWY_GAINESVILLE_GA_ORG_ID
    : PROD_2988_GILLSVILLE_HWY_GAINESVILLE_GA_ORG_ID,
  'breeze-thru': isAlpha ? ALPHA_BREEZE_THRU_ORG_ID : PROD_BREEZE_THRU_ORG_ID,
  'buntyn-singh': isAlpha ? ALPHA_BUNTYN_SINGH_ORG_ID : PROD_BUNTYN_SINGH_ORG_ID,
  'coyne-oil': isAlpha ? ALPHA_COYNE_OIL_ORG_ID : PROD_COYNE_OIL_ORG_ID,
  'danny-han': isAlpha ? ALPHA_DANNY_HAN_ORG_ID : PROD_DANNY_HAN_ORG_ID,
  '2604-pa-115-effort-pa': isAlpha ? ALPHA_2604_PA_115_EFFORT_PA_ORG_ID : PROD_2604_PA_115_EFFORT_PA_ORG_ID,
  'faraz-datoo': isAlpha ? ALPHA_FARAZ_DATOO_ORG_ID : PROD_FARAZ_DATOO_ORG_ID,
  'fleet-morris-petroleum': isAlpha ? ALPHA_FLEET_MORRIS_PETROLEUM_ORG_ID : PROD_FLEET_MORRIS_PETROLEUM_ORG_ID,
  'jbt-petroleum': isAlpha ? ALPHA_JBT_PETROLEUM_ORG_ID : PROD_JBT_PETROLEUM_ORG_ID,
  qismat: isAlpha ? ALPHA_QISMAT_ORG_ID : PROD_QISMAT_ORG_ID,
  'sassys-truck-stop-limestone-ny': isAlpha
    ? ALPHA_SASSYS_TRUCK_STOP_LIMESTONE_NY_ORG_ID
    : PROD_SASSYS_TRUCK_STOP_LIMESTONE_NY_ORG_ID,
  'southside-express': isAlpha ? ALPHA_SOUTHSIDE_EXPRESS_ORG_ID : PROD_SOUTHSIDE_EXPRESS_ORG_ID,
  'tejinder-toor': isAlpha ? ALPHA_TEJINDER_TOOR_ORG_ID : PROD_TEJINDER_TOOR_ORG_ID,
  'the-dugout': isAlpha ? ALPHA_THE_DUGOUT_ORG_ID : PROD_THE_DUGOUT_ORG_ID,
  'tustin-auto-gas-works': isAlpha ? ALPHA_TUSTIN_AUTO_GAS_WORKS_ORG_ID : PROD_TUSTIN_AUTO_GAS_WORKS_ORG_ID,
  'cascade-dining': isAlpha ? ALPHA_CASCADE_DINING_ORG_ID : PROD_CASCADE_DINING_ORG_ID,
  'cerious-rolls': isAlpha ? ALPHA_CERIOUS_ROLLS_ORG_ID : PROD_CERIOUS_ROLLS_ORG_ID,
  'primanti-brothers': isAlpha ? ALPHA_PRIMANTI_BROTHERS_ORG_ID : PROD_PRIMANTI_BROTHERS_ORG_ID,
  'saint-and-sinner': isAlpha ? ALPHA_SAINT_AND_SINNER_ORG_ID : PROD_SAINT_AND_SINNER_ORG_ID,
  'tasty-restaurant-group': isAlpha ? ALPHA_TASTY_RESTAURANT_GROUP_ORG_ID : PROD_TASTY_RESTAURANT_GROUP_ORG_ID,
  'zalat-pizza': isAlpha ? ALPHA_ZALAT_PIZZA_ORG_ID : PROD_ZALAT_PIZZA_ORG_ID,
  '4053-hemphill-street-fort-worth-tx': isAlpha
    ? ALPHA_4053_HEMPHILL_STREET_FORT_WORTH_TX_ORG_ID
    : PROD_4053_HEMPHILL_STREET_FORT_WORTH_TX_ORG_ID,
  '9263-al-79-pinson-al': isAlpha ? ALPHA_9263_AL_79_PINSON_AL_ORG_ID : PROD_9263_AL_79_PINSON_AL_ORG_ID,
  'daybreak-market': isAlpha ? ALPHA_DAYBREAK_MARKET_ORG_ID : PROD_DAYBREAK_MARKET_ORG_ID,
  'dickerson-petroleum': isAlpha ? ALPHA_DICKERSON_PETROLEUM_ORG_ID : PROD_DICKERSON_PETROLEUM_ORG_ID,
  'hi-fi-express': isAlpha ? ALPHA_HI_FI_EXPRESS_ORG_ID : PROD_HI_FI_EXPRESS_ORG_ID,
  'tri-state-petroleum': isAlpha ? ALPHA_TRI_STATE_PETROLEUM_ORG_ID : PROD_TRI_STATE_PETROLEUM_ORG_ID,
  'albo-pizza': isAlpha ? ALPHA_ALBO_PIZZA_ORG_ID : PROD_ALBO_PIZZA_ORG_ID,
  dennys: isAlpha ? ALPHA_DENNYS_ORG_ID : PROD_DENNYS_ORG_ID,
  'dominican-cake-by-g': isAlpha ? ALPHA_DOMINICAN_CAKE_BY_G_ORG_ID : PROD_DOMINICAN_CAKE_BY_G_ORG_ID,
  'jonny-bs-sports-bar': isAlpha ? ALPHA_JONNY_BS_SPORTS_BAR_ORG_ID : PROD_JONNY_BS_SPORTS_BAR_ORG_ID,
  lpg: isAlpha ? ALPHA_LPG_ORG_ID : PROD_LPG_ORG_ID,
  'lakewood-local': isAlpha ? ALPHA_LAKEWOOD_LOCAL_ORG_ID : PROD_LAKEWOOD_LOCAL_ORG_ID,
  'mbr-management': isAlpha ? ALPHA_MBR_MANAGEMENT_ORG_ID : PROD_MBR_MANAGEMENT_ORG_ID,
  'papa-murphys': isAlpha ? ALPHA_PAPA_MURPHYS_ORG_ID : PROD_PAPA_MURPHYS_ORG_ID,
  pops: isAlpha ? ALPHA_POPS_ORG_ID : PROD_POPS_ORG_ID,
  'the-rec-pizza-and-detroit-eats': isAlpha
    ? ALPHA_THE_REC_PIZZA_AND_DETROIT_EATS_ORG_ID
    : PROD_THE_REC_PIZZA_AND_DETROIT_EATS_ORG_ID,
  'upfresh-kitchen-highland-creek': isAlpha
    ? ALPHA_UPFRESH_KITCHEN_HIGHLAND_CREEK_ORG_ID
    : PROD_UPFRESH_KITCHEN_HIGHLAND_CREEK_ORG_ID,
  'urban-bricks-marble-slab': isAlpha ? ALPHA_URBAN_BRICKS_MARBLE_SLAB_ORG_ID : PROD_URBAN_BRICKS_MARBLE_SLAB_ORG_ID,
  woosters: isAlpha ? ALPHA_WOOSTERS_ORG_ID : PROD_WOOSTERS_ORG_ID,
  'chris-salems-merchant-account': isAlpha
    ? ALPHA_CHRIS_SALEMS_MERCHANT_ACCOUNT_ORG_ID
    : PROD_CHRIS_SALEMS_MERCHANT_ACCOUNT_ORG_ID,
  'citgo-7720-steubenville-pike-oakdale-pa-15071': isAlpha
    ? ALPHA_CITGO_7720_STEUBENVILLE_PIKE_OAKDALE_PA_15071_ORG_ID
    : PROD_CITGO_7720_STEUBENVILLE_PIKE_OAKDALE_PA_15071_ORG_ID,
  'gilligan-oil-dealers-warp': isAlpha ? ALPHA_GILLIGAN_OIL_DEALERS_WARP_ORG_ID : PROD_GILLIGAN_OIL_DEALERS_WARP_ORG_ID,
  'jeremiahs-italian-ice-joseph-pina-franchise': isAlpha
    ? ALPHA_JEREMIAHS_ITALIAN_ICE_JOSEPH_PINA_FRANCHISE_ORG_ID
    : PROD_JEREMIAHS_ITALIAN_ICE_JOSEPH_PINA_FRANCHISE_ORG_ID,
  'rka-petroleum-companies': isAlpha ? ALPHA_RKA_PETROLEUM_COMPANIES_ORG_ID : PROD_RKA_PETROLEUM_COMPANIES_ORG_ID,
  'road-ranger-usa': isAlpha ? ALPHA_ROAD_RANGER_USA_ORG_ID : PROD_ROAD_RANGER_USA_ORG_ID,
  'summit-distributing': isAlpha ? ALPHA_SUMMIT_DISTRIBUTING_ORG_ID : PROD_SUMMIT_DISTRIBUTING_ORG_ID,
  'checkers-and-rallys': isAlpha ? ALPHA_CHECKERS_AND_RALLYS_ORG_ID : PROD_CHECKERS_AND_RALLYS_ORG_ID,
  'chpan-kabob': isAlpha ? ALPHA_CHPAN_KABOB_ORG_ID : PROD_CHPAN_KABOB_ORG_ID,
  'house-of-chimney-cakes': isAlpha ? ALPHA_HOUSE_OF_CHIMNEY_CAKES_ORG_ID : PROD_HOUSE_OF_CHIMNEY_CAKES_ORG_ID,
  'hut-diggity': isAlpha ? ALPHA_HUT_DIGGITY_ORG_ID : PROD_HUT_DIGGITY_ORG_ID,
  'js-fort-group': isAlpha ? ALPHA_JS_FORT_GROUP_ORG_ID : PROD_JS_FORT_GROUP_ORG_ID,
  'off-square-bret': isAlpha ? ALPHA_OFF_SQUARE_BRET_ORG_ID : PROD_OFF_SQUARE_BRET_ORG_ID,
  'pizza-hut-of-fort-wayne': isAlpha ? ALPHA_PIZZA_HUT_OF_FORT_WAYNE_ORG_ID : PROD_PIZZA_HUT_OF_FORT_WAYNE_ORG_ID,
  'rockford-pizza': isAlpha ? ALPHA_ROCKFORD_PIZZA_ORG_ID : PROD_ROCKFORD_PIZZA_ORG_ID,
  'team-daks': isAlpha ? ALPHA_TEAM_DAKS_ORG_ID : PROD_TEAM_DAKS_ORG_ID,
  'asif-lakhanis-merchant-account': isAlpha
    ? ALPHA_ASIF_LAKHANIS_MERCHANT_ACCOUNT_ORG_ID
    : PROD_ASIF_LAKHANIS_MERCHANT_ACCOUNT_ORG_ID,
  'choice-market-colfax': isAlpha ? ALPHA_CHOICE_MARKET_COLFAX_ORG_ID : PROD_CHOICE_MARKET_COLFAX_ORG_ID,
  'dandy-mini-mart': isAlpha ? ALPHA_DANDY_MINI_MART_ORG_ID : PROD_DANDY_MINI_MART_ORG_ID,
  'divine-oil-co': isAlpha ? ALPHA_DIVINE_OIL_CO_ORG_ID : PROD_DIVINE_OIL_CO_ORG_ID,
  'kwik-trip': isAlpha ? ALPHA_KWIK_TRIP_ORG_ID : PROD_KWIK_TRIP_ORG_ID,
  'p66-4829-west-blvd-poplar-bluff-mo-63901': isAlpha
    ? ALPHA_P66_4829_WEST_BLVD_POPLAR_BLUFF_MO_63901_ORG_ID
    : PROD_P66_4829_WEST_BLVD_POPLAR_BLUFF_MO_63901_ORG_ID,
  'seaside-chevron': isAlpha ? ALPHA_SEASIDE_CHEVRON_ORG_ID : PROD_SEASIDE_CHEVRON_ORG_ID,
  bmpp: isAlpha ? ALPHA_BMPP_ORG_ID : PROD_BMPP_ORG_ID,
  'babylon-pizza': isAlpha ? ALPHA_BABYLON_PIZZA_ORG_ID : PROD_BABYLON_PIZZA_ORG_ID,
  'bluegrass-pizza': isAlpha ? ALPHA_BLUEGRASS_PIZZA_ORG_ID : PROD_BLUEGRASS_PIZZA_ORG_ID,
  'dubest-pizza': isAlpha ? ALPHA_DUBEST_PIZZA_ORG_ID : PROD_DUBEST_PIZZA_ORG_ID,
  freshii: isAlpha ? ALPHA_FRESHII_ORG_ID : PROD_FRESHII_ORG_ID,
  'macques-bbq': isAlpha ? ALPHA_MACQUES_BBQ_ORG_ID : PROD_MACQUES_BBQ_ORG_ID,
  'romans-road-pizza': isAlpha ? ALPHA_ROMANS_ROAD_PIZZA_ORG_ID : PROD_ROMANS_ROAD_PIZZA_ORG_ID,
  selina: isAlpha ? ALPHA_SELINA_ORG_ID : PROD_SELINA_ORG_ID,
  paq: isAlpha ? ALPHA_PAQ_ORG_ID : PROD_PAQ_ORG_ID,
  pigglywiggly: isAlpha ? ALPHA_PIGGLYWIGGLY_ORG_ID : PROD_PIGGLYWIGGLY_ORG_ID,
  'adirondack-food-and-fuel': isAlpha ? ALPHA_ADIRONDACK_FOOD_AND_FUEL_ORG_ID : PROD_ADIRONDACK_FOOD_AND_FUEL_ORG_ID,
  'apro-distribution-llc': isAlpha ? ALPHA_APRO_DISTRIBUTION_LLC_ORG_ID : PROD_APRO_DISTRIBUTION_LLC_ORG_ID,
  'bp-1811-indian-creek-rd-dandrige-tn': isAlpha
    ? ALPHA_BP_1811_INDIAN_CREEK_RD_DANDRIGE_TN_ORG_ID
    : PROD_BP_1811_INDIAN_CREEK_RD_DANDRIGE_TN_ORG_ID,
  'bp-2576-190th-st-north-welton-ia': isAlpha
    ? ALPHA_BP_2576_190TH_ST_NORTH_WELTON_IA_ORG_ID
    : PROD_BP_2576_190TH_ST_NORTH_WELTON_IA_ORG_ID,
  caseys: isAlpha ? ALPHA_CASEYS_ORG_ID : PROD_CASEYS_ORG_ID,
  'chevron-usa': isAlpha ? ALPHA_CHEVRON_USA_ORG_ID : PROD_CHEVRON_USA_ORG_ID,
  'gyt-enterprises': isAlpha ? ALPHA_GYT_ENTERPRISES_ORG_ID : PROD_GYT_ENTERPRISES_ORG_ID,
  'h-and-s-energy-products': isAlpha ? ALPHA_H_AND_S_ENERGY_PRODUCTS_ORG_ID : PROD_H_AND_S_ENERGY_PRODUCTS_ORG_ID,
  'man-dhawans-merchant-account': isAlpha
    ? ALPHA_MAN_DHAWANS_MERCHANT_ACCOUNT_ORG_ID
    : PROD_MAN_DHAWANS_MERCHANT_ACCOUNT_ORG_ID,
  'northdale-oil': isAlpha ? ALPHA_NORTHDALE_OIL_ORG_ID : PROD_NORTHDALE_OIL_ORG_ID,
  'smo-inc': isAlpha ? ALPHA_SMO_INC_ORG_ID : PROD_SMO_INC_ORG_ID,
  'shell-2749-bardon-chapel-rd-augusta-ga': isAlpha
    ? ALPHA_SHELL_2749_BARDON_CHAPEL_RD_AUGUSTA_GA_ORG_ID
    : PROD_SHELL_2749_BARDON_CHAPEL_RD_AUGUSTA_GA_ORG_ID,
  'stinker-stores': isAlpha ? ALPHA_STINKER_STORES_ORG_ID : PROD_STINKER_STORES_ORG_ID,
  'and-pizza': isAlpha ? ALPHA_AND_PIZZA_ORG_ID : PROD_AND_PIZZA_ORG_ID,
  '1000-degrees-pizza-salad-wings': isAlpha
    ? ALPHA_1000_DEGREES_PIZZA_SALAD_WINGS_ORG_ID
    : PROD_1000_DEGREES_PIZZA_SALAD_WINGS_ORG_ID,
  'burger-king': isAlpha ? ALPHA_BURGER_KING_ORG_ID : PROD_BURGER_KING_ORG_ID,
  'jrp-restaurant-group': isAlpha ? ALPHA_JRP_RESTAURANT_GROUP_ORG_ID : PROD_JRP_RESTAURANT_GROUP_ORG_ID,
  'lions-choice': isAlpha ? ALPHA_LIONS_CHOICE_ORG_ID : PROD_LIONS_CHOICE_ORG_ID,
  'pizza-brake': isAlpha ? ALPHA_PIZZA_BRAKE_ORG_ID : PROD_PIZZA_BRAKE_ORG_ID,
  'prairie-pizza': isAlpha ? ALPHA_PRAIRIE_PIZZA_ORG_ID : PROD_PRAIRIE_PIZZA_ORG_ID,
  'team-cookston': isAlpha ? ALPHA_TEAM_COOKSTON_ORG_ID : PROD_TEAM_COOKSTON_ORG_ID,
  'team-washington': isAlpha ? ALPHA_TEAM_WASHINGTON_ORG_ID : PROD_TEAM_WASHINGTON_ORG_ID,
  'global-partners': isAlpha ? ALPHA_GLOBAL_PARTNERS_ORG_ID : PROD_GLOBAL_PARTNERS_ORG_ID,
  'kum-and-go': isAlpha ? ALPHA_KUM_AND_GO_ORG_ID : PROD_KUM_AND_GO_ORG_ID,
  'hi-noon-petroleum': isAlpha ? ALPHA_HI_NOON_PETROLEUM_ORG_ID : PROD_HI_NOON_PETROLEUM_ORG_ID,
  'united-energy-distributors': isAlpha
    ? ALPHA_UNITED_ENERGY_DISTRIBUTORS_ORG_ID
    : PROD_UNITED_ENERGY_DISTRIBUTORS_ORG_ID,
  'chaitos-latin-social-restaurant': isAlpha
    ? ALPHA_CHAITOS_LATIN_SOCIAL_RESTAURANT_ORG_ID
    : PROD_CHAITOS_LATIN_SOCIAL_RESTAURANT_ORG_ID,
  'fort-group-fl': isAlpha ? ALPHA_FORT_GROUP_FL_ORG_ID : PROD_FORT_GROUP_FL_ORG_ID,
  'group-120': isAlpha ? ALPHA_GROUP_120_ORG_ID : PROD_GROUP_120_ORG_ID,
  'qfrm-holdings': isAlpha ? ALPHA_QFRM_HOLDINGS_ORG_ID : PROD_QFRM_HOLDINGS_ORG_ID,
  northgate: isAlpha ? ALPHA_NORTHGATE_ORG_ID : PROD_NORTHGATE_ORG_ID,
  rhodes: isAlpha ? ALPHA_RHODES_ORG_ID : PROD_RHODES_ORG_ID,
  'ankur-purani-merchantaccount': isAlpha
    ? ALPHA_ANKUR_PURANI_MERCHANTACCOUNT_ORG_ID
    : PROD_ANKUR_PURANI_MERCHANTACCOUNT_ORG_ID,
  'bosselman-enterprises': isAlpha ? ALPHA_BOSSELMAN_ENTERPRISES_ORG_ID : PROD_BOSSELMAN_ENTERPRISES_ORG_ID,
  'corrigan-oil-company': isAlpha ? ALPHA_CORRIGAN_OIL_COMPANY_ORG_ID : PROD_CORRIGAN_OIL_COMPANY_ORG_ID,
  'ed-staub-sons': isAlpha ? ALPHA_ED_STAUB_SONS_ORG_ID : PROD_ED_STAUB_SONS_ORG_ID,
  'folk-oil': isAlpha ? ALPHA_FOLK_OIL_ORG_ID : PROD_FOLK_OIL_ORG_ID,
  'merle-boes': isAlpha ? ALPHA_MERLE_BOES_ORG_ID : PROD_MERLE_BOES_ORG_ID,
  'red-triangle-oil-co': isAlpha ? ALPHA_RED_TRIANGLE_OIL_CO_ORG_ID : PROD_RED_TRIANGLE_OIL_CO_ORG_ID,
  'refuel-operating-company': isAlpha ? ALPHA_REFUEL_OPERATING_COMPANY_ORG_ID : PROD_REFUEL_OPERATING_COMPANY_ORG_ID,
  'the-convenience-group': isAlpha ? ALPHA_THE_CONVENIENCE_GROUP_ORG_ID : PROD_THE_CONVENIENCE_GROUP_ORG_ID,
  'westco-oil-dba-champlain-farms': isAlpha
    ? ALPHA_WESTCO_OIL_DBA_CHAMPLAIN_FARMS_ORG_ID
    : PROD_WESTCO_OIL_DBA_CHAMPLAIN_FARMS_ORG_ID,
  'westcliffe-petroleum-llc': isAlpha ? ALPHA_WESTCLIFFE_PETROLEUM_LLC_ORG_ID : PROD_WESTCLIFFE_PETROLEUM_LLC_ORG_ID,
  'whitehead-oil-co': isAlpha ? ALPHA_WHITEHEAD_OIL_CO_ORG_ID : PROD_WHITEHEAD_OIL_CO_ORG_ID,
  'boba-shmoba': isAlpha ? ALPHA_BOBA_SHMOBA_ORG_ID : PROD_BOBA_SHMOBA_ORG_ID,
  'commonwealth-pizza': isAlpha ? ALPHA_COMMONWEALTH_PIZZA_ORG_ID : PROD_COMMONWEALTH_PIZZA_ORG_ID,
  'curry-in-a-hurry': isAlpha ? ALPHA_CURRY_IN_A_HURRY_ORG_ID : PROD_CURRY_IN_A_HURRY_ORG_ID,
  'jgs-old-fashioned-hamburgers': isAlpha
    ? ALPHA_JGS_OLD_FASHIONED_HAMBURGERS_ORG_ID
    : PROD_JGS_OLD_FASHIONED_HAMBURGERS_ORG_ID,
  'pcf-restaurant-group': isAlpha ? ALPHA_PCF_RESTAURANT_GROUP_ORG_ID : PROD_PCF_RESTAURANT_GROUP_ORG_ID,
  'pizza-ranch': isAlpha ? ALPHA_PIZZA_RANCH_ORG_ID : PROD_PIZZA_RANCH_ORG_ID,
  'rpm-pizza-central-ms': isAlpha ? ALPHA_RPM_PIZZA_CENTRAL_MS_ORG_ID : PROD_RPM_PIZZA_CENTRAL_MS_ORG_ID,
  'steven-waldocks-merchant-account': isAlpha
    ? ALPHA_STEVEN_WALDOCKS_MERCHANT_ACCOUNT_ORG_ID
    : PROD_STEVEN_WALDOCKS_MERCHANT_ACCOUNT_ORG_ID,
  'tossitup-inc': isAlpha ? ALPHA_TOSSITUP_INC_ORG_ID : PROD_TOSSITUP_INC_ORG_ID,
  'trigo-hospitality': isAlpha ? ALPHA_TRIGO_HOSPITALITY_ORG_ID : PROD_TRIGO_HOSPITALITY_ORG_ID,
  '76-952-simmerhorn-rd': isAlpha ? ALPHA_76_952_SIMMERHORN_RD_ORG_ID : PROD_76_952_SIMMERHORN_RD_ORG_ID,
  'arco-4095-railroad-ave': isAlpha ? ALPHA_ARCO_4095_RAILROAD_AVE_ORG_ID : PROD_ARCO_4095_RAILROAD_AVE_ORG_ID,
  'countywide-petroleum': isAlpha ? ALPHA_COUNTYWIDE_PETROLEUM_ORG_ID : PROD_COUNTYWIDE_PETROLEUM_ORG_ID,
  'mcintosh-energy': isAlpha ? ALPHA_MCINTOSH_ENERGY_ORG_ID : PROD_MCINTOSH_ENERGY_ORG_ID,
  'michael-oconnor': isAlpha ? ALPHA_MICHAEL_OCONNOR_ORG_ID : PROD_MICHAEL_OCONNOR_ORG_ID,
  'omran-tank-line': isAlpha ? ALPHA_OMRAN_TANK_LINE_ORG_ID : PROD_OMRAN_TANK_LINE_ORG_ID,
  'one-energy-inc': isAlpha ? ALPHA_ONE_ENERGY_INC_ORG_ID : PROD_ONE_ENERGY_INC_ORG_ID,
  'quality-petroleum-corporation': isAlpha
    ? ALPHA_QUALITY_PETROLEUM_CORPORATION_ORG_ID
    : PROD_QUALITY_PETROLEUM_CORPORATION_ORG_ID,
  'schatz-crossroads': isAlpha ? ALPHA_SCHATZ_CROSSROADS_ORG_ID : PROD_SCHATZ_CROSSROADS_ORG_ID,
  'ta-operating-corp': isAlpha ? ALPHA_TA_OPERATING_CORP_ORG_ID : PROD_TA_OPERATING_CORP_ORG_ID,
  'willoughby-hills-development-distribution': isAlpha
    ? ALPHA_WILLOUGHBY_HILLS_DEVELOPMENT_DISTRIBUTION_ORG_ID
    : PROD_WILLOUGHBY_HILLS_DEVELOPMENT_DISTRIBUTION_ORG_ID,
  'hometown-foods': isAlpha ? ALPHA_HOMETOWN_FOODS_ORG_ID : PROD_HOMETOWN_FOODS_ORG_ID,
  'dominos-lucinda': isAlpha ? ALPHA_DOMINOS_LUCINDA_ORG_ID : PROD_DOMINOS_LUCINDA_ORG_ID,
  drizl: isAlpha ? ALPHA_DRIZL_ORG_ID : PROD_DRIZL_ORG_ID,
  'four-palates-gelato': isAlpha ? ALPHA_FOUR_PALATES_GELATO_ORG_ID : PROD_FOUR_PALATES_GELATO_ORG_ID,
  'handcraft-burgers-and-brew': isAlpha
    ? ALPHA_HANDCRAFT_BURGERS_AND_BREW_ORG_ID
    : PROD_HANDCRAFT_BURGERS_AND_BREW_ORG_ID,
  'intermezzo-wood-fired-pizzeria-and-ristorante': isAlpha
    ? ALPHA_INTERMEZZO_WOOD_FIRED_PIZZERIA_AND_RISTORANTE_ORG_ID
    : PROD_INTERMEZZO_WOOD_FIRED_PIZZERIA_AND_RISTORANTE_ORG_ID,
  'mambo-seafood': isAlpha ? ALPHA_MAMBO_SEAFOOD_ORG_ID : PROD_MAMBO_SEAFOOD_ORG_ID,
  'the-main-course': isAlpha ? ALPHA_THE_MAIN_COURSE_ORG_ID : PROD_THE_MAIN_COURSE_ORG_ID,
  'anz-petroleum': isAlpha ? ALPHA_ANZ_PETROLEUM_ORG_ID : PROD_ANZ_PETROLEUM_ORG_ID,
  'buckeye-energy-services': isAlpha ? ALPHA_BUCKEYE_ENERGY_SERVICES_ORG_ID : PROD_BUCKEYE_ENERGY_SERVICES_ORG_ID,
  enmarket: isAlpha ? ALPHA_ENMARKET_ORG_ID : PROD_ENMARKET_ORG_ID,
  'gas-land-petroleum': isAlpha ? ALPHA_GAS_LAND_PETROLEUM_ORG_ID : PROD_GAS_LAND_PETROLEUM_ORG_ID,
  'george-zacharakis-merchant-account': isAlpha
    ? ALPHA_GEORGE_ZACHARAKIS_MERCHANT_ACCOUNT_ORG_ID
    : PROD_GEORGE_ZACHARAKIS_MERCHANT_ACCOUNT_ORG_ID,
  'sandri-llc': isAlpha ? ALPHA_SANDRI_LLC_ORG_ID : PROD_SANDRI_LLC_ORG_ID,
  'go-grocer': isAlpha ? ALPHA_GO_GROCER_ORG_ID : PROD_GO_GROCER_ORG_ID,
  'ajd-pizza-management': isAlpha ? ALPHA_AJD_PIZZA_MANAGEMENT_ORG_ID : PROD_AJD_PIZZA_MANAGEMENT_ORG_ID,
  'cave-enterprises': isAlpha ? ALPHA_CAVE_ENTERPRISES_ORG_ID : PROD_CAVE_ENTERPRISES_ORG_ID,
  'g-made': isAlpha ? ALPHA_G_MADE_ORG_ID : PROD_G_MADE_ORG_ID,
  'hospitality-memphis': isAlpha ? ALPHA_HOSPITALITY_MEMPHIS_ORG_ID : PROD_HOSPITALITY_MEMPHIS_ORG_ID,
  'hospitality-tennessee': isAlpha ? ALPHA_HOSPITALITY_TENNESSEE_ORG_ID : PROD_HOSPITALITY_TENNESSEE_ORG_ID,
  'nzo-llc': isAlpha ? ALPHA_NZO_LLC_ORG_ID : PROD_NZO_LLC_ORG_ID,
  'royal-grill': isAlpha ? ALPHA_ROYAL_GRILL_ORG_ID : PROD_ROYAL_GRILL_ORG_ID,
  'team-lyders': isAlpha ? ALPHA_TEAM_LYDERS_ORG_ID : PROD_TEAM_LYDERS_ORG_ID,
  'aranosian-oil-co': isAlpha ? ALPHA_ARANOSIAN_OIL_CO_ORG_ID : PROD_ARANOSIAN_OIL_CO_ORG_ID,
  'chevron-10736-spencer-highway': isAlpha
    ? ALPHA_CHEVRON_10736_SPENCER_HIGHWAY_ORG_ID
    : PROD_CHEVRON_10736_SPENCER_HIGHWAY_ORG_ID,
  'high-pointe-oil-company': isAlpha ? ALPHA_HIGH_POINTE_OIL_COMPANY_ORG_ID : PROD_HIGH_POINTE_OIL_COMPANY_ORG_ID,
  'jericho-wholesale-llc': isAlpha ? ALPHA_JERICHO_WHOLESALE_LLC_ORG_ID : PROD_JERICHO_WHOLESALE_LLC_ORG_ID,
  'marathon-flint-oil-company': isAlpha
    ? ALPHA_MARATHON_FLINT_OIL_COMPANY_ORG_ID
    : PROD_MARATHON_FLINT_OIL_COMPANY_ORG_ID,
  'rahim-metalwalas-merchant-account': isAlpha
    ? ALPHA_RAHIM_METALWALAS_MERCHANT_ACCOUNT_ORG_ID
    : PROD_RAHIM_METALWALAS_MERCHANT_ACCOUNT_ORG_ID,
  'richards-ave-llc': isAlpha ? ALPHA_RICHARDS_AVE_LLC_ORG_ID : PROD_RICHARDS_AVE_LLC_ORG_ID,
  'yatooma-oil': isAlpha ? ALPHA_YATOOMA_OIL_ORG_ID : PROD_YATOOMA_OIL_ORG_ID,
  'arthurs-cup-llc': isAlpha ? ALPHA_ARTHURS_CUP_LLC_ORG_ID : PROD_ARTHURS_CUP_LLC_ORG_ID,
  'cato-group': isAlpha ? ALPHA_CATO_GROUP_ORG_ID : PROD_CATO_GROUP_ORG_ID,
  'cbbc-llc': isAlpha ? ALPHA_CBBC_LLC_ORG_ID : PROD_CBBC_LLC_ORG_ID,
  'pj-whelihans': isAlpha ? ALPHA_PJ_WHELIHANS_ORG_ID : PROD_PJ_WHELIHANS_ORG_ID,
  'circle-k-coastal-carolina': isAlpha ? ALPHA_CIRCLE_K_COASTAL_CAROLINA_ORG_ID : PROD_CIRCLE_K_COASTAL_CAROLINA_ORG_ID,
  'circle-k-florida': isAlpha ? ALPHA_CIRCLE_K_FLORIDA_ORG_ID : PROD_CIRCLE_K_FLORIDA_ORG_ID,
  'circle-k-great-lakes': isAlpha ? ALPHA_CIRCLE_K_GREAT_LAKES_ORG_ID : PROD_CIRCLE_K_GREAT_LAKES_ORG_ID,
  'circle-k-gulf-coast': isAlpha ? ALPHA_CIRCLE_K_GULF_COAST_ORG_ID : PROD_CIRCLE_K_GULF_COAST_ORG_ID,
  'circle-k-midwest': isAlpha ? ALPHA_CIRCLE_K_MIDWEST_ORG_ID : PROD_CIRCLE_K_MIDWEST_ORG_ID,
  'circle-k-rocky-mountain': isAlpha ? ALPHA_CIRCLE_K_ROCKY_MOUNTAIN_ORG_ID : PROD_CIRCLE_K_ROCKY_MOUNTAIN_ORG_ID,
  'circle-k-south-atlantic': isAlpha ? ALPHA_CIRCLE_K_SOUTH_ATLANTIC_ORG_ID : PROD_CIRCLE_K_SOUTH_ATLANTIC_ORG_ID,
  'circle-k-southeast': isAlpha ? ALPHA_CIRCLE_K_SOUTHEAST_ORG_ID : PROD_CIRCLE_K_SOUTHEAST_ORG_ID,
  'circle-k-texas': isAlpha ? ALPHA_CIRCLE_K_TEXAS_ORG_ID : PROD_CIRCLE_K_TEXAS_ORG_ID,
  'circle-k-west-coast': isAlpha ? ALPHA_CIRCLE_K_WEST_COAST_ORG_ID : PROD_CIRCLE_K_WEST_COAST_ORG_ID,
  'circle-k-grand-canyon': isAlpha ? ALPHA_CIRCLE_K_GRAND_CANYON_ORG_ID : PROD_CIRCLE_K_GRAND_CANYON_ORG_ID,
  'circle-k-heartland': isAlpha ? ALPHA_CIRCLE_K_HEARTLAND_ORG_ID : PROD_CIRCLE_K_HEARTLAND_ORG_ID,
  'circle-k-northern-tier': isAlpha ? ALPHA_CIRCLE_K_NORTHERN_TIER_ORG_ID : PROD_CIRCLE_K_NORTHERN_TIER_ORG_ID,
  'caraluzzis-market': isAlpha ? ALPHA_CARALUZZIS_MARKET_ORG_ID : PROD_CARALUZZIS_MARKET_ORG_ID,
  'upside-payouts': isAlpha ? ALPHA_UPISDE_PAYOUTS_ORG_ID : PROD_UPISDE_PAYOUTS_ORG_ID,
  'aloha-petroleum': isAlpha ? ALPHA_ALOHA_PETROLEUM_ORG_ID : PROD_ALOHA_PETROLEUM_ORG_ID,
  'applegreen-llc': isAlpha ? ALPHA_APPLEGREEN_LLC_ORG_ID : PROD_APPLEGREEN_LLC_ORG_ID,
  'danby-gasoline-marketers': isAlpha ? ALPHA_DANBY_GASOLINE_MARKETERS_ORG_ID : PROD_DANBY_GASOLINE_MARKETERS_ORG_ID,
  'mandm-service-co': isAlpha ? ALPHA_MANDM_SERVICE_CO_ORG_ID : PROD_MANDM_SERVICE_CO_ORG_ID,
  'palisades-fuel': isAlpha ? ALPHA_PALISADES_FUEL_ORG_ID : PROD_PALISADES_FUEL_ORG_ID,
  'reid-petroleum-corp': isAlpha ? ALPHA_REID_PETROLEUM_CORP_ORG_ID : PROD_REID_PETROLEUM_CORP_ORG_ID,
  'steinhagen-oil-co': isAlpha ? ALPHA_STEINHAGEN_OIL_CO_ORG_ID : PROD_STEINHAGEN_OIL_CO_ORG_ID,
  gianteagle: isAlpha ? ALPHA_GIANTEAGLE_ORG_ID : PROD_GIANTEAGLE_ORG_ID,
  'tonys-fresh-market': isAlpha ? ALPHA_TONYS_FRESH_MARKET_ORG_ID : PROD_TONYS_FRESH_MARKET_ORG_ID,
  'boston-pie': isAlpha ? ALPHA_BOSTON_PIE_ORG_ID : PROD_BOSTON_PIE_ORG_ID,
  'dtid-pizza': isAlpha ? ALPHA_DTID_PIZZA_ORG_ID : PROD_DTID_PIZZA_ORG_ID,
  'dry-ridge-chili': isAlpha ? ALPHA_DRY_RIDGE_CHILI_ORG_ID : PROD_DRY_RIDGE_CHILI_ORG_ID,
  'john-wayne-pizza-co': isAlpha ? ALPHA_JOHN_WAYNE_PIZZA_CO_ORG_ID : PROD_JOHN_WAYNE_PIZZA_CO_ORG_ID,
  'little-caesar-enterprises': isAlpha ? ALPHA_LITTLE_CAESAR_ENTERPRISES_ORG_ID : PROD_LITTLE_CAESAR_ENTERPRISES_ORG_ID,
  'maps-ph-management': isAlpha ? ALPHA_MAPS_PH_MANAGEMENT_ORG_ID : PROD_MAPS_PH_MANAGEMENT_ORG_ID,
  'north-texas-wings': isAlpha ? ALPHA_NORTH_TEXAS_WINGS_ORG_ID : PROD_NORTH_TEXAS_WINGS_ORG_ID,
  'paq-inc-restaurant': isAlpha ? ALPHA_PAQ_INC_RESTAURANT_ORG_ID : PROD_PAQ_INC_RESTAURANT_ORG_ID,
  'pacific-bells': isAlpha ? ALPHA_PACIFIC_BELLS_ORG_ID : PROD_PACIFIC_BELLS_ORG_ID,
  'sun-holdings': isAlpha ? ALPHA_SUN_HOLDINGS_ORG_ID : PROD_SUN_HOLDINGS_ORG_ID,
  'alex-williams': isAlpha ? ALPHA_ALEX_WILLIAMS_ORG_ID : PROD_ALEX_WILLIAMS_ORG_ID,
  christensen: isAlpha ? ALPHA_CHRISTENSEN_ORG_ID : PROD_CHRISTENSEN_ORG_ID,
  'first-coast-energy': isAlpha ? ALPHA_FIRST_COAST_ENERGY_ORG_ID : PROD_FIRST_COAST_ENERGY_ORG_ID,
  'p66-consignment-sites': isAlpha ? ALPHA_P66_CONSIGNMENT_SITES_ORG_ID : PROD_P66_CONSIGNMENT_SITES_ORG_ID,
  'rl-jordan-oil': isAlpha ? ALPHA_RL_JORDAN_OIL_ORG_ID : PROD_RL_JORDAN_OIL_ORG_ID,
  racetrac: isAlpha ? ALPHA_RACETRAC_ORG_ID : PROD_RACETRAC_ORG_ID,
  sgd: isAlpha ? ALPHA_SGD_ORG_ID : PROD_SGD_ORG_ID,
  'thriftway-super-stops': isAlpha ? ALPHA_THRIFTWAY_SUPER_STOPS_ORG_ID : PROD_THRIFTWAY_SUPER_STOPS_ORG_ID,
  'tri-star-energy': isAlpha ? ALPHA_TRI_STAR_ENERGY_ORG_ID : PROD_TRI_STAR_ENERGY_ORG_ID,
  straubs: isAlpha ? ALPHA_STRAUBS_ORG_ID : PROD_STRAUBS_ORG_ID,
  'pj-restaurants-dba-papa-johns': isAlpha
    ? ALPHA_PJ_RESTAURANTS_DBA_PAPA_JOHNS_ORG_ID
    : PROD_PJ_RESTAURANTS_DBA_PAPA_JOHNS_ORG_ID,
  'gary-collotia': isAlpha ? ALPHA_GARY_COLLOTIA_ORG_ID : PROD_GARY_COLLOTIA_ORG_ID,
  'sukhpal-dhaliwal': isAlpha ? ALPHA_SUKHPAL_DHALIWAL_ORG_ID : PROD_SUKHPAL_DHALIWAL_ORG_ID,
  'sunshine-oil': isAlpha ? ALPHA_SUNSHINE_OIL_ORG_ID : PROD_SUNSHINE_OIL_ORG_ID,
  gogrocer: isAlpha ? ALPHA_GOGROCER_ORG_ID : PROD_GOGROCER_ORG_ID,
  '84802-or-11': isAlpha ? ALPHA_84802_OR_11_ORG_ID : PROD_84802_OR_11_ORG_ID,
  'april-bennett': isAlpha ? ALPHA_APRIL_BENNETT_ORG_ID : PROD_APRIL_BENNETT_ORG_ID,
  'aranco-oil': isAlpha ? ALPHA_ARANCO_OIL_ORG_ID : PROD_ARANCO_OIL_ORG_ID,
  'bruceton-petroleum': isAlpha ? ALPHA_BRUCETON_PETROLEUM_ORG_ID : PROD_BRUCETON_PETROLEUM_ORG_ID,
  'easy-store-ibc': isAlpha ? ALPHA_EASY_STORE_IBC_ORG_ID : PROD_EASY_STORE_IBC_ORG_ID,
  'hinson-oil-company': isAlpha ? ALPHA_HINSON_OIL_COMPANY_ORG_ID : PROD_HINSON_OIL_COMPANY_ORG_ID,
  'par-petroleum': isAlpha ? ALPHA_PAR_PETROLEUM_ORG_ID : PROD_PAR_PETROLEUM_ORG_ID,
  'rothel-bullock': isAlpha ? ALPHA_ROTHEL_BULLOCK_ORG_ID : PROD_ROTHEL_BULLOCK_ORG_ID,
  'star-stop': isAlpha ? ALPHA_STAR_STOP_ORG_ID : PROD_STAR_STOP_ORG_ID,
  'sugarland-petroleum': isAlpha ? ALPHA_SUGARLAND_PETROLEUM_ORG_ID : PROD_SUGARLAND_PETROLEUM_ORG_ID,
  'terrible-herbst': isAlpha ? ALPHA_TERRIBLE_HERBST_ORG_ID : PROD_TERRIBLE_HERBST_ORG_ID,
  'alta-convenience': isAlpha ? ALPHA_ALTA_CONVENIENCE_ORG_ID : PROD_ALTA_CONVENIENCE_ORG_ID,
  'busy-bee-stores': isAlpha ? ALPHA_BUSY_BEE_STORES_ORG_ID : PROD_BUSY_BEE_STORES_ORG_ID,
  'allstar-petroleum': isAlpha ? ALPHA_ALLSTAR_PETROLEUM_ORG_ID : PROD_ALLSTAR_PETROLEUM_ORG_ID,
  'super-c-stores': isAlpha ? ALPHA_SUPER_C_STORES_ORG_ID : PROD_SUPER_C_STORES_ORG_ID,
  'aubuchon-company': isAlpha ? ALPHA_AUBUCHON_COMPANY_ORG_ID : PROD_AUBUCHON_COMPANY_ORG_ID,
  'maximos-gyro-and-kabob': isAlpha ? ALPHA_MAXIMOS_GYRO_AND_KABOB_ORG_ID : PROD_MAXIMOS_GYRO_AND_KABOB_ORG_ID,
  'pdq-chicken': isAlpha ? ALPHA_PDQ_CHICKEN_ORG_ID : PROD_PDQ_CHICKEN_ORG_ID,
  'pm-holdings': isAlpha ? ALPHA_PM_HOLDINGS_ORG_ID : PROD_PM_HOLDINGS_ORG_ID,
  'team-murph': isAlpha ? ALPHA_TEAM_MURPH_ORG_ID : PROD_TEAM_MURPH_ORG_ID,
  'team-springfield-pie': isAlpha ? ALPHA_TEAM_SPRINGFIELD_PIE_ORG_ID : PROD_TEAM_SPRINGFIELD_PIE_ORG_ID,
  'blarney-castle-oil': isAlpha ? ALPHA_BLARNEY_CASTLE_OIL_ORG_ID : PROD_BLARNEY_CASTLE_OIL_ORG_ID,
  'bucks-c-stores': isAlpha ? ALPHA_BUCKS_C_STORES_ORG_ID : PROD_BUCKS_C_STORES_ORG_ID,
  'dmg-corporation': isAlpha ? ALPHA_DMG_CORPORATION_ORG_ID : PROD_DMG_CORPORATION_ORG_ID,
  'energy-north-company-operated-account': isAlpha
    ? ALPHA_ENERGY_NORTH_COMPANY_OPERATED_ACCOUNT_ORG_ID
    : PROD_ENERGY_NORTH_COMPANY_OPERATED_ACCOUNT_ORG_ID,
  'hallmark-petroleum-llc': isAlpha ? ALPHA_HALLMARK_PETROLEUM_LLC_ORG_ID : PROD_HALLMARK_PETROLEUM_LLC_ORG_ID,
  'jenkins-oil-co': isAlpha ? ALPHA_JENKINS_OIL_CO_ORG_ID : PROD_JENKINS_OIL_CO_ORG_ID,
  'lone-star-market': isAlpha ? ALPHA_LONE_STAR_MARKET_ORG_ID : PROD_LONE_STAR_MARKET_ORG_ID,
  'short-stop': isAlpha ? ALPHA_SHORT_STOP_ORG_ID : PROD_SHORT_STOP_ORG_ID,
  'simonson-station-store': isAlpha ? ALPHA_SIMONSON_STATION_STORE_ORG_ID : PROD_SIMONSON_STATION_STORE_ORG_ID,
  'taj-ali': isAlpha ? ALPHA_TAJ_ALI_ORG_ID : PROD_TAJ_ALI_ORG_ID,
  'true-north': isAlpha ? ALPHA_TRUE_NORTH_ORG_ID : PROD_TRUE_NORTH_ORG_ID,
  'twin-star': isAlpha ? ALPHA_TWIN_STAR_ORG_ID : PROD_TWIN_STAR_ORG_ID,
  'unbranded-901-s-broadway': isAlpha ? ALPHA_UNBRANDED_901_S_BROADWAY_ORG_ID : PROD_UNBRANDED_901_S_BROADWAY_ORG_ID,
  'waguespack-oil-co': isAlpha ? ALPHA_WAGUESPACK_OIL_CO_ORG_ID : PROD_WAGUESPACK_OIL_CO_ORG_ID,
  'blaze-pizza-franchisee': isAlpha ? ALPHA_BLAZE_PIZZA_FRANCHISEE_ORG_ID : PROD_BLAZE_PIZZA_FRANCHISEE_ORG_ID,
  'dejuras-group': isAlpha ? ALPHA_DEJURAS_GROUP_ORG_ID : PROD_DEJURAS_GROUP_ORG_ID,
  'lonerider-brewing-company': isAlpha ? ALPHA_LONERIDER_BREWING_COMPANY_ORG_ID : PROD_LONERIDER_BREWING_COMPANY_ORG_ID,
  'pieology-corporate': isAlpha ? ALPHA_PIEOLOGY_CORPORATE_ORG_ID : PROD_PIEOLOGY_CORPORATE_ORG_ID,
  'quain-enterprises': isAlpha ? ALPHA_QUAIN_ENTERPRISES_ORG_ID : PROD_QUAIN_ENTERPRISES_ORG_ID,
  'shawarma-press': isAlpha ? ALPHA_SHAWARMA_PRESS_ORG_ID : PROD_SHAWARMA_PRESS_ORG_ID,
  'tasty-chickn': isAlpha ? ALPHA_TASTY_CHICKN_ORG_ID : PROD_TASTY_CHICKN_ORG_ID,
  'tasty-king': isAlpha ? ALPHA_TASTY_KING_ORG_ID : PROD_TASTY_KING_ORG_ID,
  'verma-food-inc-jser-food-inc': isAlpha
    ? ALPHA_VERMA_FOOD_INC_JSER_FOOD_INC_ORG_ID
    : PROD_VERMA_FOOD_INC_JSER_FOOD_INC_ORG_ID,
  'westshore-pizza': isAlpha ? ALPHA_WESTSHORE_PIZZA_ORG_ID : PROD_WESTSHORE_PIZZA_ORG_ID,
  'ricker-oil-co': isAlpha ? ALPHA_RICKER_OIL : PROD_RICKER_OIL,
  'shop-n-save': isAlpha ? ALPHA_SHOP_N_SAVE : PROD_SHOP_N_SAVE,
  'rpm-pizza': isAlpha ? ALPHA_RPM_PIZZA : PROD_RPM_PIZZA,
  'davenport-energy': isAlpha ? ALPHA_DAVENPORT_ENERGY : PROD_DAVENPORT_ENERGY,
  'sunoco-lp': isAlpha ? ALPHA_SUNOCO_LP : PROD_SUNOCO_LP,
  'tasty-dlites': isAlpha ? ALPHA_TASTY_DLITES : PROD_TASTY_DLITES,
  'delek-retails-merchant-account': isAlpha ? ALPHA_DELEK_RETAILS : PROD_DELEK_RETAILS,
  'sun-state-oil': isAlpha ? ALPHA_SUN_STATE_OIL : PROD_SUN_STATE_OIL,
  'crossroads-fuel': isAlpha ? ALPHA_CROSSROADS_FUEL : PROD_CROSSROADS_FUEL,
  'petro-national': isAlpha ? ALPHA_PETRO_NATIONAL : PROD_PETRO_NATIONAL,
  'mitzi-howards-merchant-account': isAlpha ? ALPHA_MITZI_HOWARDS : PROD_MITZI_HOWARDS,
  'james-river-petroleum': isAlpha ? ALPHA_JAMES_RIVER_PETRO : PROD_JAMES_RIVER_PETRO,
  'batz-pizza': isAlpha ? ALPHA_BATZ_PIZZA : PROD_BATZ_PIZZA,
  'berwick-cooperative-oil-co-1111': isAlpha ? ALPHA_BERWICK_COOP_1111 : PROD_BERWICK_COOP_1111,
  'southern-counties-oil': isAlpha ? ALPHA_SOUTHERN_COUNTIES_OIL : PROD_SOUTHERN_COUNTIES_OIL,
  grjh: isAlpha ? ALPHA_GRJH : PROD_GRJH,
  'downey-oil-co': isAlpha ? ALPHA_DOWNEY_OIL : PROD_DOWNEY_OIL,
  savon: isAlpha ? ALPHA_SAVON : PROD_SAVON,
  'giant-eagle-convenience-division': isAlpha ? ALPHA_GIANT_EAGLE_C_DIV : PROD_GIANT_EAGLE_C_DIV,
  'van-de-pol': isAlpha ? ALPHA_VAN_DE_POL : PROD_VAN_DE_POL,
  'alon-delek': isAlpha ? ALPHA_ALON_DELEK : PROD_ALON_DELEK,
  // ~~~~~~~~~~~~~ parents orgs below here ~~~~~~~~~~~~~
  'circle-k-all-business-units': isAlpha ? ALPHA_CIRCLE_K_ALL_ORG_ID : PROD_CIRCLE_K_ALL_ORG_ID,
  hgg: isAlpha ? ALPHA_HERITAGE_GROCERY_GROUP : PROD_HERITAGE_GROCERY_GROUP,
  'giant-eagle-total': isAlpha ? ALPHA_GIANT_EAGLE_TOTAL : PROD_GIANT_EAGLE_TOTAL,
  'the-real-estate-group-dba-cold-stone-creamery': isAlpha ? ALPHA_THE_REAL_ESTATE_GROUP : PROD_THE_REAL_ESTATE_GROUP,
};
