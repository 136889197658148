import { Flex } from '@chakra-ui/react';

import { PageSizeSelector } from './PageSizeSelector';
import { PaginationButton } from './PaginationButton';
import { PaginationInfo } from './PaginationInfo';
import { PaginationProps } from './types';

export const Pagination = (props: PaginationProps) => {
  return (
    <Flex
      flexDirection={{ base: 'column', md: 'row' }}
      flexWrap='wrap'
      justifyContent='space-between'
      alignItems='center'
      borderTop='1px solid'
      borderColor='neutral.200'
      mt={'24px'}
      padding={'12px 16px'}
    >
      <Flex flexDirection={'row'} alignItems='center' gap={4}>
        <PageSizeSelector {...props} />
        <PaginationInfo {...props} />
      </Flex>
      <Flex
        data-testid='pagination-controls'
        flexDirection={'row'}
        gap={4}
        justifyContent='space-between'
        marginTop={{ base: '20px', md: 'initial' }}
      >
        <PaginationButton type='previous' {...props} />
        <PaginationButton type='next' {...props} />
      </Flex>
    </Flex>
  );
};
