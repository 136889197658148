import { Outlet } from 'react-router';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { ENV_CONFIG } from '@/config';

export const Stripe = () => {
  const stripePromise = loadStripe(ENV_CONFIG.STRIPE.key);

  return (
    <Elements stripe={stripePromise}>
      <Outlet />
    </Elements>
  );
};
