import { Trans, useTranslation } from 'react-i18next';
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import {
  Flex,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';

export function CompetitorsHelperText() {
  const { t } = useTranslation();
  return (
    <Flex alignItems='center' justifyContent='center' gap={6} py={4}>
      <Popover variant='default' data-testid='customerSegmentInfo'>
        <PopoverTrigger>
          <Link ml='4' textAlign='center'>
            <h3>
              <QuestionOutlineIcon boxSize={4} mr='1.5' />
              {t(`locationsPage.competitors.tooltips.upsideOnly.link`)}
            </h3>
          </Link>
        </PopoverTrigger>
        <PopoverContent width={'400px'}>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>
            <Text>{t(`locationsPage.competitors.tooltips.upsideOnly.title`)}</Text>
          </PopoverHeader>
          <PopoverBody>
            <Trans i18nKey='locationsPage.competitors.tooltips.upsideOnly.description'>
              “Other Upside locations” include all fuel sites on the platform that are not part of your network. Under
              certain circumstances, multiple sites may be live within an exclusivity zone due to Upside’s second
              position policy. Second position allows a limited number of sites to launch in existing exclusivity zones,
              with lower offers. Second position is critical for enabling growth of Upside’s network and maximizing
              incremental impact for all merchants. Our data generally shows that networks with second position sites
              perform better. To learn more about second position, visit the
              <Link href='https://knowledgecenter.upside.com/hc/en-us' color={'blue.400'}>
                Upside Knowledge Center
              </Link>
              .
            </Trans>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <Popover variant='default' data-testid='customerSegmentInfo'>
        <PopoverTrigger>
          <Link ml='4' textAlign='center'>
            <h3>
              <QuestionOutlineIcon boxSize={4} mr='1.5' />
              {t(`locationsPage.competitors.tooltips.exclusivityZone.link`)}
            </h3>
          </Link>
        </PopoverTrigger>
        <PopoverContent width={'400px'}>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>
            <Text>{t(`locationsPage.competitors.tooltips.exclusivityZone.title`)}</Text>
          </PopoverHeader>
          <PopoverBody>
            <Text mb={4}>{t(`locationsPage.competitors.tooltips.exclusivityZone.description`)}</Text>

            <Link href='https://fuelknowledgecenter.upside.com/hc/en-us/articles/10746051591579-Exclusivity-zones-market-density-caps'>
              {t('locationsPage.competitors.tooltips.exclusivityZone.learnMore')}
            </Link>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  );
}
