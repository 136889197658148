import { useLocation } from 'react-router-dom';

import { ManageBillingBankAccount } from '../billingPaymentMethods/bankAccount/ManageBillingBankAccount';
import { ManagePayoutBankAccount } from '../payoutMethod/ManagePayoutBankAccount';

export const ManageBankAccount = () => {
  const location = useLocation();
  const fromSection = location.pathname.split('/')[3];

  return fromSection === 'billing' ? <ManageBillingBankAccount /> : <ManagePayoutBankAccount />;
};
