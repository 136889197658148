import { RootState } from '@app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Status } from '@/common/interfaces';
import { TemporalUnit } from '@/features/dashboard/slice';

import { IRefinedPromos } from './types';

export interface IPromotionsState {
  promotions: IRefinedPromos;
  status: Status.Idle | Status.Loading | Status.Failed;
  error?: string;
}

export const initialState: IPromotionsState = {
  promotions: {},
  status: Status.Loading,
};

export const promotionsSlice = createSlice({
  name: 'promotions',
  initialState,
  reducers: {
    promotionsFetch: (
      state,
      _action: PayloadAction<{
        startDate: string;
        endDate: string;
        temporalUnit: TemporalUnit;
        selectedNetworks: string[];
        selectedTransactionType: string;
        authRequestPath: string;
        activeVertical: string;
        networkNames: string[];
        locations: string[];
        transactionType: string;
      }>,
    ) => {
      state.error = undefined;
      state.status = Status.Loading;
    },
    promotionsSucceeded: (state, action: PayloadAction<IRefinedPromos>) => {
      state.promotions = action.payload;
      state.status = Status.Idle;
    },
    promotionsFailed: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.status = Status.Failed;
    },
  },
});

export const { promotionsFetch, promotionsSucceeded, promotionsFailed } = promotionsSlice.actions;

export const selectPromotionsStatus = (state: RootState) => state.promotions.status;
export const selectPromotions = (state: RootState) => state.promotions.promotions;

export default promotionsSlice.reducer;
