import { useTranslation } from 'react-i18next';
import { Box, Heading, Text } from '@chakra-ui/react';
import { FunnelDatum, FunnelDirection, ResponsiveFunnel } from '@nivo/funnel';
import _ from 'lodash';

import { displayNumber } from '../../utils';
import { LoadingSpinner } from '../loadingSpinner/LoadingSpinner';

interface IFunnelGraphProps {
  title?: string;
  data: Array<FunnelDatum> | undefined;
  colors?: Array<string> | null;
  isCurrency?: boolean;
  isPercentage?: boolean;
  width?: string | number;
}

export const FunnelGraph = ({ title, data, colors = null, width = '100%' }: IFunnelGraphProps) => {
  const { t } = useTranslation();

  const calculatePercentage = (value: number): string => {
    let percentage = '';
    data?.forEach((datum) => {
      if (datum.value === value) {
        percentage = datum.id.toString();
      }
    });
    return percentage;
  };

  const customLabelContent = (value: number): string => {
    return `${displayNumber(value)} (${calculatePercentage(value)})`;
  };

  const defaultProperties = {
    data: data ? data : [],
    valueFormat: customLabelContent,
    margin: { top: 40, right: 40, bottom: 60, left: 40 },
    direction: 'horizontal' as FunnelDirection,
    interpolation: 'smooth' as 'linear' | 'smooth',
    borderWidth: 20,
    shapeBlending: 1,
    beforeSeparatorLength: 20,
    afterSeparatorLength: 20,
  };

  return (
    <>
      {!_.isEmpty(data) ? (
        <Box h={'400px'} w={width} pb={8} data-testid='funnel-graph'>
          <Heading size='md'>{t(title as string)}</Heading>
          <ResponsiveFunnel
            {...defaultProperties}
            theme={{ fontSize: 20 }}
            colors={colors ? colors : { scheme: 'category10' }}
            labelColor={'color.text.default'}
            tooltip={({ part }) => (
              <Box as='div' padding={3} bg='background.overlay'>
                <Text color='text.default-inverse'>
                  <strong>{part.data.label}: </strong> {part.formattedValue}
                </Text>
              </Box>
            )}
          />
        </Box>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};
