import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const InputStyles = defineMultiStyleConfig({
  baseStyle: definePartsStyle({
    field: {
      borderRadius: 'lg',
      borderWidth: '1px',
      borderColor: 'border.default',
      _placeholder: { color: 'text.placeholder' },
      _focusBorderColor: { color: 'border.primary' },
      _invalid: {
        borderColor: 'border.error',
      },
      _disabled: {
        bg: 'background.disabled.subtle',
        borderColor: 'border.disabled',
        color: 'text.disabled',
      },
      _readOnly: {
        bg: 'background.disabled.subtle',
        borderColor: 'border.disabled',
        color: 'text.disabled',
      },
    },
  }),
});

export default InputStyles;
